<template>
  <div>
    <div class="innerWrapper">
      <div class="signInWrapper">
        <h2>{{ text.signIn }}</h2>
        <form class="ui form" @submit.prevent="signIn()">
          <div class="field">
            <label>{{ text.commonEmailAddress }}</label>
            <input type="email" name="email" v-model="email" :placeholder="text.commonEmailAddressPlaceholder" @input="errorVisible = false">
          </div>
          <div class="field">
            <label>{{ text.commonPassword }}</label>
            <input type="password" name="password" v-model="password" :placeholder="text.commonPasswordPlaceholder" @input="errorVisible = false">
          </div>
          <div class="ui red message" v-show="errorVisible">
            <p>{{ text.signInError }}</p>
          </div>
          <div class="ui red message" v-show="unknownError">
            <p>{{ text.signInUnknownError }}</p>
          </div>
          <div class="ui small active inline loader" v-show="checking"></div>
          <button type="submit" class="ui primary button" :class="{disabled: !email || !emailValid || !password || errorVisible}" v-show="!checking">{{ text.signIn }}</button>
        </form>
        <div class="footer">
          <p v-show="!checking">{{ text.signInNoAccount }} <router-link to="/register">{{ text.register }}</router-link></p>
          <p v-show="!checking"><router-link to="recover">{{ text.signInCantSignin }}</router-link></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const firebase = window.firebase

function logError (errorCode, error) {
  console.log(errorCode)
  console.log(error)
}

export default {
  name: 'signIn',
  props: ['mobile', 'text', 'touch', 'user', 'window'],
  data () {
    return {
      checking: false,
      email: '',
      errorVisible: false,
      password: '',
      unknownError: false
    }
  },
  created: function () {
    // TEMP - for debugging
    window.vmSignIn = this
    // Scroll to top
    window.scrollTo(0, 0)
  },
  computed: {
    emailValid: function () {
      // Function from https://stackoverflow.com/a/9204568
      let regex = /\S+@\S+\.\S+/
      return regex.test(this.email)
    }
  },
  methods: {
    signIn () {
      this.checking = true
      firebase
        .auth()
        .signInWithEmailAndPassword(this.email, this.password)
        .then(() => {
          this.checking = false
          this.$router.push('/')
        })
        .catch(error => {
          if (error.code === 'auth/user-not-found' || error.code === 'auth/wrong-password') {
            this.errorVisible = true
          } else {
            this.unknownError = true
            logError('SignIn_signIn', error)
          }
          this.checking = false
        })
    }
  }
}
</script>

<style scoped>
  form {
    margin-top: 2rem;
  }
  .signInWrapper {
    max-width: 450px;
    margin: 3rem auto 3rem auto;
  }
  button, div.loader {
    margin-top: 1rem !important;
  }
  .footer {
    margin-top: 3rem;
    padding-bottom: 2rem;
  }
</style>
