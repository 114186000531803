<script>
const firebase = window.firebase
const db = firebase.firestore()
const memoSetsPerDoc = 1000

export default {
  methods: {
    createMemoSetSlug: function (title) {
      // Note: a dot in the slug causes a "Cannot GET ..." error if a memo set page is refreshed
      let slug
      // If the title is all dots
      if (title.replace(/\./g, '') === '') {
        // Replace dots with hyphens
        slug = title.replace(/\./g, '-')
      } else {
        // Remove dots, replace whitespace, slash and initial hash with -, and encode the rest
        // Note that browsers generally show the decoded characters in the URL as per https://stackoverflow.com/a/2742985
        slug = encodeURI(title.toLowerCase().replace(/\./g, '').replace(/\s+/g, '-').replace(/\//g, '-').replace(/^#/, '-'))
      }
      return slug
    },
    firstAvailableDocId: function () {
      // Returns the first available doc ID for a new memo set
      let docId, docIds, found
      // Create an array of all docIds used so far
      docIds = [...new Set(this.memoSets.map(memoSet => memoSet.docId))]
      for (let i = 0; i < docIds.length; i++) {
        docId = docIds[i]
        if (this.memoSets.filter(memoSet => memoSet.docId === docId).length < memoSetsPerDoc) {
          found = true
          break
        }
      }
      if (!found) {
        // Get a new random doc ID
        docId = this.randomId()
      }
      return docId
    },
    nextFolderId: function () {
      let folderId, folderIndex
      // Get next available folder ID
      folderIndex = 0
      while (true) {
        folderIndex++
        folderId = 'f' + folderIndex.toString()
        if (!this.user.folders.filter(folder => folder.folderId === folderId).length) break
      }
      return folderId
    },
    newMemoSetTitle: function (title) {
      let titleWithVersion, version
      // Start with default title if not provided
      if (!title) {
        title = this.text.memoSetsDefaultTitle
      }
      // Check whether there is a memo set with the desired title
      if (this.memoSets.filter(memoSet => memoSet.title === title).length) {
        // Append the next unused version number (starting at 2) in parentheses to construct the title
        version = 1
        while (true) {
          version++
          titleWithVersion = title + ' (' + version + ')'
          if (!this.memoSets.filter(memoSet => memoSet.title === titleWithVersion).length) {
            title = titleWithVersion
            break
          }
        }
      }
      return title
    },
    randomId: function () {
      return db.collection('dummy').doc().id
    }
  }
}
</script>
