<template>
  <div>
    <breadcrumbs :breadcrumbs="breadcrumbs" :connected="connected" :text="text"></breadcrumbs>
    <div class="innerWrapper" :class="{'mobile': mobile}">
      <div class="ui fluid search container">
        <div class="ui fluid icon input">
          <input class="prompt" type="text" :placeholder="text.commonSearchPlaceholder">
          <i class="search icon"></i>
        </div>
        <div class="results"></div>
      </div>
      <div class="ui small active inline loader readyLoader" v-show="!ready"></div>
      <div class="accordionWrapper" v-show="ready">
        <div class="ui styled accordion" :class="{'mobile': mobile}" @contextmenu="disableMenuForTouch($event)">
          <template v-for="(folder, folderIndex) in folders">
            <div
              class="title"
              :class="{
                insertAbove:
                  (draggingFolder || touchMovingFolder) &&
                  folderIndex === toFolderIndex &&
                  folderIndex <= fromFolderIndex,
                insertBelow:
                    (draggingFolder || touchMovingFolder) &&
                    folderIndex === toFolderIndex &&
                    folderIndex > fromFolderIndex
              }"
              :id="'folder_' + folder.seq"
              :key="'folderName_' + folder.seq"
              :draggable="folders.length > 1"
              @drag="scrollPage($event.clientY)"
              @dragend="folderDragEnd()"
              @dragenter="folderDragEnter(folderIndex)"
              @dragover.prevent
              @dragleave="folderDragLeave()"
              @dragstart="folderDragStart(folderIndex, $event)"
              @drop="folderDrop()"
              @touchend="folderTouchEnd()"
              @touchmove="folderTouchMove($event)"
              @touchstart="folderTouchStart(folderIndex, $event)"
            >
              <div class="accordionTitleInnerWrapper"
                :class="{
                  pendingFolder:
                    (draggingMemoSet || touchMovingMemoSet) &&
                    folderIndex === pendingFolder &&
                    !accordionState[folder.folderId]
                }"
                @click="folderHeaderClick($event, folderIndex)"
              >
                <i class="large grey folder icon folderIcon"></i>
                <i class="large grey folder open icon folderIcon"></i>
                <div
                  class="folderTitle"
                  :id="'folder' + folderIndex"
                  :contenteditable="renamingFolder === folderIndex"
                  @blur="folderNameBlur($event)"
                  @keydown.enter.prevent="$event.target.blur()"
                  @keydown.esc="$event.target.blur()"
                >
                  {{ folder.folderName }}
                </div>
                <button
                  class="ui compact large icon button folderButton"
                  v-show="folders.length >= 2 && (!folder.memoSets || folder.memoSets.length === 0)"
                  @click.stop="deleteFolderClick(folderIndex)"
                >
                  <i class="trash alternate icon"></i>
                </button>
                <button
                  class="ui compact large icon button folderButton"
                  :id="'renameFolder' + folderIndex"
                  @click.stop="renameFolderClick(folderIndex)"
                >
                  <i class="edit icon"></i>
                </button>
              </div>
            </div>
            <div class="content folderContent" :key="'folderContent' + folderIndex">
              <table
                class="ui selectable unstackable table"
                :class="{compact: mobile}"
                v-if="folder.memoSets && folder.memoSets.length && !(folder.memoSets.length === 1 && folder.memoSets[0].memoSetId === addingMemoSetId)"
              >
                <thead>
                  <tr
                    @dragenter="tableHeadDragEnter(folderIndex)"
                    @dragleave="tableHeadDragLeave()"
                    @dragover.prevent
                    @drop="tableHeadDrop()"
                  >
                    <th class="two wide">{{ text.memoSetsColumnImage }}</th>
                    <th class="six wide">{{ text.memoSetsColumnMemoSet }}</th>
                    <th class="two wide">{{ text.memoSetsColumnRows }}</th>
                    <th class="four wide">{{ text.commonStatus }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(memoSet, memoSetIndex) in folder.memoSets"
                    v-show="memoSet.memoSetId !== addingMemoSetId"
                    :id="memoSet.memoSetId"
                    :key="memoSet.memoSetId"
                    :draggable="folders.length > 1 || memoSets.length > 1"
                    @drag="scrollPage($event.clientY)"
                    @dragend="memoSetDragEnd()"
                    @dragenter="memoSetDragEnter(folderIndex, memoSetIndex)"
                    @dragleave="memoSetDragLeave()"
                    @dragover.prevent
                    @dragstart="memoSetDragStart(folderIndex, memoSetIndex, $event)"
                    @drop="memoSetDrop()"
                    @touchend="memoSetTouchEnd()"
                    @touchmove="memoSetTouchMove($event)"
                    @touchstart="memoSetTouchStart(folderIndex, memoSetIndex, $event)"
                  >
                    <td
                      class="imgCell"
                      :class="{
                        insertAbove:
                          /* Dragging a memo set up within the same folder or to a different folder */
                          (draggingMemoSet || touchMovingMemoSet) &&
                          folderIndex === toFolderIndex && (
                            (
                              folderIndex === fromFolderIndex &&
                              memoSetIndex === toMemoSetIndex &&
                              memoSetIndex <= fromMemoSetIndex
                            ) || (
                              folderIndex !== fromFolderIndex &&
                              memoSetIndex === toMemoSetIndex
                            )
                          ),
                        insertBelow:
                          /* Dragging a memo set down within the same folder */
                          (draggingMemoSet || touchMovingMemoSet) &&
                          folderIndex === toFolderIndex &&
                          folderIndex === fromFolderIndex &&
                          memoSetIndex === toMemoSetIndex &&
                          memoSetIndex > fromMemoSetIndex
                      }"
                    >
                      <router-link :to="'memo-sets/' + memoSet.memoSetId + '/' + memoSet.slug" draggable="false">
                        <div class="coverImageWrapper">
                          <img class="ui centered rounded image" :src="memoSet.coverImage" draggable="false">
                        </div>
                      </router-link>
                    </td>
                    <td
                      :class="{
                        insertAbove:
                          /* Dragging a memo set up within the same folder or to a different folder */
                          (draggingMemoSet || touchMovingMemoSet) &&
                          folderIndex === toFolderIndex && (
                            (
                              folderIndex === fromFolderIndex &&
                              memoSetIndex === toMemoSetIndex &&
                              memoSetIndex <= fromMemoSetIndex
                            ) || (
                              folderIndex !== fromFolderIndex &&
                              memoSetIndex === toMemoSetIndex
                            )
                          ),
                        insertBelow:
                          /* Dragging a memo set down within the same folder */
                          (draggingMemoSet || touchMovingMemoSet) &&
                          folderIndex === toFolderIndex &&
                          folderIndex === fromFolderIndex &&
                          memoSetIndex === toMemoSetIndex &&
                          memoSetIndex > fromMemoSetIndex
                      }"
                    >
                      <router-link :to="'memo-sets/' + memoSet.memoSetId + '/' + memoSet.slug" draggable="false">
                        <div
                          class="ui small green label rightMargin1"
                          v-show="memoSet.isPublic"
                        >
                          {{ text.memoSetPublic }}
                        </div>
                        {{ memoSet.title }}
                      </router-link>
                    </td>
                    <td
                      class="locationNum"
                      :class="{
                        insertAbove:
                          /* Dragging a memo set up within the same folder or to a different folder */
                          (draggingMemoSet || touchMovingMemoSet) &&
                          folderIndex === toFolderIndex && (
                            (
                              folderIndex === fromFolderIndex &&
                              memoSetIndex === toMemoSetIndex &&
                              memoSetIndex <= fromMemoSetIndex
                            ) || (
                              folderIndex !== fromFolderIndex &&
                              memoSetIndex === toMemoSetIndex
                            )
                          ),
                        insertBelow:
                          /* Dragging a memo set down within the same folder */
                          (draggingMemoSet || touchMovingMemoSet) &&
                          folderIndex === toFolderIndex &&
                          folderIndex === fromFolderIndex &&
                          memoSetIndex === toMemoSetIndex &&
                          memoSetIndex > fromMemoSetIndex
                      }"
                    >
                      <router-link :to="'memo-sets/' + memoSet.memoSetId + '/' + memoSet.slug" draggable="false">
                        <span :class="{'indent1': memoSet.rowCount < 1000, 'indent2': memoSet.rowCount < 100, 'indent3': memoSet.rowCount < 10}">{{ memoSet.rowCount }}</span>
                      </router-link>
                    </td>
                    <td
                      :class="{
                        insertAbove:
                          /* Dragging a memo set up within the same folder or to a different folder */
                          (draggingMemoSet || touchMovingMemoSet) &&
                          folderIndex === toFolderIndex && (
                            (
                              folderIndex === fromFolderIndex &&
                              memoSetIndex === toMemoSetIndex &&
                              memoSetIndex <= fromMemoSetIndex
                            ) || (
                              folderIndex !== fromFolderIndex &&
                              memoSetIndex === toMemoSetIndex
                            )
                          ),
                        insertBelow:
                          /* Dragging a memo set down within the same folder */
                          (draggingMemoSet || touchMovingMemoSet) &&
                          folderIndex === toFolderIndex &&
                          folderIndex === fromFolderIndex &&
                          memoSetIndex === toMemoSetIndex &&
                          memoSetIndex > fromMemoSetIndex
                      }"
                    >
                      <router-link :to="'memo-sets/' + memoSet.memoSetId + '/' + memoSet.slug" draggable="false">
                        <status-bar
                          v-if="statuses && statuses[memoSet.memoSetId]"
                          :mobile="mobile"
                          page="MemoSets"
                          :statuses="statusesWithSpeed[memoSet.memoSetId]"
                          :window="window"
                        ></status-bar>
                      </router-link>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div
                class="addMemoSetWrapper"
                :class="{
                  insertAbove:
                      draggingMemoSet &&
                      folderIndex === toFolderIndex &&
                      toMemoSetIndex === (folder.memoSets ? folder.memoSets.length : 0)
                    ||
                      touchMovingMemoSet &&
                      folderIndex === toFolderIndex &&
                      toMemoSetIndex === (folder.memoSets ? folder.memoSets.length : 0)
                }"
                @dragenter="addMemoSetWrapperDragEnter(folderIndex)"
                @dragover.prevent
                @dragleave="addMemoSetWrapperDragLeave()"
                @drop="memoSetDrop()"
              >
                <div class="ui small active inline loader" v-show="addingMemoSetId && folderIndex === addingFolderIndex"></div>
                <span class="addMemoSetLink" v-show="!(addingMemoSetId && folderIndex === addingFolderIndex)" @click="addMemoSetClick(folderIndex)"><i class="plus icon"></i>{{ text.memoSetsAddAMemoSet }}</span>
              </div>
            </div>
          </template>
        </div>
        <div class="addFolderWrapper">
          <span class="addFolderLink" @click="addFolderClick($event)"><i class="plus icon"></i>{{ text.memoSetsAddAFolder }}</span>
        </div>
      </div>
    </div>
    <br>
  </div>
</template>

<script>
import Breadcrumbs from '@/components/Breadcrumbs'
import StatusBar from '@/components/StatusBar'
import MemoSetMethods from '@/mixins/MemoSetMethods'
const $ = window.$
const firebase = window.firebase
const db = firebase.firestore()

// Patch for Firefox drag bug - see https://bugzilla.mozilla.org/show_bug.cgi?id=505521#c80
if(/Firefox\/\d+[\d\.]*/.test(navigator.userAgent)
		&& typeof window.DragEvent === 'function'
		&& typeof window.addEventListener === 'function') (function(){
	// patch for Firefox bug https://bugzilla.mozilla.org/show_bug.cgi?id=505521
	var cx, cy, px, py, ox, oy, sx, sy, lx, ly
	function update(e) {
		cx = e.clientX
    cy = e.clientY
		px = e.pageX
    py = e.pageY
		ox = e.offsetX
    oy = e.offsetY
		sx = e.screenX
    sy = e.screenY
		lx = e.layerX
    ly = e.layerY
	}
	function assign(e) {
		e._ffix_cx = cx
    e._ffix_cy = cy
		e._ffix_px = px
    e._ffix_py = py
		e._ffix_ox = ox
    e._ffix_oy = oy
		e._ffix_sx = sx
    e._ffix_sy = sy
		e._ffix_lx = lx
    e._ffix_ly = ly
	}
  window.addEventListener('mousemove', update, true)
	window.addEventListener('dragover', update, true)
	// bug #505521 identifies these three listeners as problematic:
	// (although tests show 'dragstart' seems to work now, keep to be compatible)
	window.addEventListener('dragstart', assign, true)
	window.addEventListener('drag', assign, true)
	window.addEventListener('dragend', assign, true)

	var me = Object.getOwnPropertyDescriptors(window.MouseEvent.prototype),
		ue = Object.getOwnPropertyDescriptors(window.UIEvent.prototype)
	function getter(prop,repl) {
		return function() {return me[prop] && me[prop].get.call(this) || Number(this[repl]) || 0}
	}
	function layerGetter(prop,repl) {
		return function() {return this.type === 'dragover' && ue[prop] ? ue[prop].get.call(this) : (Number(this[repl]) || 0)}
	}
	Object.defineProperties(window.DragEvent.prototype,{
		clientX: {get: getter('clientX', '_ffix_cx')},
		clientY: {get: getter('clientY', '_ffix_cy')},
		pageX:   {get: getter('pageX', '_ffix_px')},
		pageY:   {get: getter('pageY', '_ffix_py')},
		offsetX: {get: getter('offsetX', '_ffix_ox')},
		offsetY: {get: getter('offsetY', '_ffix_oy')},
		screenX: {get: getter('screenX', '_ffix_sx')},
		screenY: {get: getter('screenY', '_ffix_sy')},
		x:       {get: getter('x', '_ffix_cx')},
		y:       {get: getter('y', '_ffix_cy')},
		layerX:  {get: layerGetter('layerX', '_ffix_lx')},
		layerY:  {get: layerGetter('layerY', '_ffix_ly')}
	})
})()

function logError (errorCode, error) {
  console.log(errorCode)
  console.log(error)
}

// Function from https://stackoverflow.com/questions/6139107/programmatically-select-text-in-a-contenteditable-html-element
function selectElementContents (el) {
  var range = document.createRange()
  range.selectNodeContents(el)
  var sel = window.getSelection()
  sel.removeAllRanges()
  sel.addRange(range)
}

export default {
  name: 'memo-sets',
  components: {
    Breadcrumbs,
    StatusBar
  },
  mixins: [MemoSetMethods],
  data () {
    return {
      accordionState: {},
      addingFolderIndex: -1,
      addingMemoSetId: '',
      draggingFolder: false,
      draggingMemoSet: false,
      folderDragEnters: 0,
      folderTouchInitialTimeout: null,
      fromFolderIndex: -1,
      fromMemoSetIndex: -1,
      memoSetDragFolderTimeout: null,
      memoSetDragEnters: 0,
      memoSetTouchFolderTimeout: null,
      memoSetTouchInitialTimeout: null,
      moveShown: false,
      newFolder: false,
      pendingFolder: -1,
      ready: false,
      renamingFolder: -1,
      toFolderIndex: -1,
      toMemoSetIndex: -1,
      touchMovingFolder: false,
      touchMovingMemoSet: false,
      touchScrollTimeout: null,
      touchY: 0,
      undoMoveData: {}
    }
  },
  computed: {
    breadcrumbs: function () {
      return [
        {label: this.text.navHome, to: '/'},
        {label: this.text.navMemoSets}
      ]
    },
    folders: function () {
      let folders, memoSetsForFolder
      if (!this.user.folders || !this.memoSets) return []
      // Create array of folders with memo sets
      folders = []
      this.user.folders.forEach((folder, folderIndex) => {
        memoSetsForFolder = this.memoSets.filter(memoSet => memoSet.folderId === folder.folderId)
        // Sort by sequence
        memoSetsForFolder.sort((a, b) => {
          if (a.seq < b.seq) return -1
          return 1
        })
        folders.push({
          folderId: folder.folderId,
          folderName: folder.folderName,
          memoSets: memoSetsForFolder,
          seq: folderIndex
        })
      })
      return folders
    },
    statusesWithSpeed: function () {
      let statusesWithSpeed = Object.assign({}, this.statuses)
      Object.keys(statusesWithSpeed).forEach(memoSetId => {
        statusesWithSpeed[memoSetId].speed = 'slow'
      })
      return statusesWithSpeed
    }
  },
  created: function () {
    // TEMP - for debugging
    window.vm = this
    // Set memo set source - used by the MemoSet component
    window.memq = window.memq || {}
    window.memq.memoSetSource = 'MemoSets'
    this.checkReady()
    this.setPageTitle()
  },
  mounted: function () {
    let accordionContents, accordionTitles, i, self
    self = this
    // Initialize accordion
    $('.ui.accordion').accordion({
      exclusive: false,
      onClosing: function () {
        const folderIndex = this.index('.folderContent')
        self.$delete(self.accordionState, self.folders[folderIndex].folderId)
        window.memq = window.memq || {}
        window.memq.accordionState = Object.assign({}, self.accordionState)
      },
      onOpen: function () {
        if (self.newFolder) {
          self.newFolder = false
          window.scrollTo({
            left: 0,
            top: document.body.scrollHeight,
            behavior: 'smooth'
          })
        }
      },
      onOpening: function () {
        const folderIndex = this.index('.folderContent')
        if (folderIndex === -1) return
        self.accordionState[self.folders[folderIndex].folderId] = true
        window.memq = window.memq || {}
        window.memq.accordionState = Object.assign({}, self.accordionState)
      }
    })
    // If we have a saved accordion state, open sections appropriately
    if (window.memq && window.memq.accordionState) {
      accordionContents = document.querySelectorAll('.ui.accordion .content')
      accordionTitles = document.querySelectorAll('.ui.accordion .title')
      for (i = 0; i < this.folders.length; i++) {
        if (window.memq.accordionState[this.folders[i].folderId]) {
          accordionContents[i].classList.add('active')
          accordionTitles[i].classList.add('active')
        }
      }
    }
    // Initialize search
    this.initSearch()
  },
  methods: {
    addFolderClick: function (event) {
      this.folders.push({
        folderId: this.nextFolderId(),
        folderName: this.text.memoSetsNewFolder,
        memoSets: [],
        seq: this.folders.length
      })
      this.writeFolders()
      this.renamingFolder = this.folders.length - 1
      // Open the new folder
      this.$nextTick(() => {
        this.$nextTick(() => {
          this.newFolder = true
          $('.ui.accordion').accordion('open', this.folders.length - 1)
          this.$nextTick(() => {
            let folderName
            folderName = document.getElementById('folder' + this.renamingFolder)
            folderName.focus()
            selectElementContents(folderName)
          })
        })
      })
    },
    addMemoSetClick: function (folderIndex) {
      let blankRows, updateObj
      const docId = this.firstAvailableDocId()
      const folder = this.folders[folderIndex]
      const folderId = folder.folderId
      // Set seq to 1 more than the highest seq in the folder
      const seq = Math.max(...folder.memoSets.map(memoSet => memoSet.seq), -1) + 1
      const memoSetRef = db.collection('memoSets').doc()
      const memoSetId = memoSetRef.id
      const title = this.newMemoSetTitle()
      const slug = this.createMemoSetSlug(title)
      // Start batched write
      const batch = db.batch()
      batch.set(memoSetRef, {
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        description: '',
        fields: [
          {
            fieldId: 'f1',
            heading: this.text.memoSetDefaultColumn1
          },
          {
            fieldId: 'f2',
            heading: this.text.memoSetDefaultColumn2
          }
        ],
        options: {
          useBackgroundPhotos: false,
          useReviewGroups: false,
          useSummaryImages: false
        },
        photos: [],
        sharing: {},
        questions: [],
        userId: this.user.id
      })
      updateObj = {}
      updateObj[memoSetId] = {
        coverImage: '/dodec.png',
        folderId: folderId,
        rowCount: 3,
        seq: seq,
        slug: slug,
        title: title
      }
      batch.set(db.doc('users/' + this.user.id + '/memoSets/' + docId), updateObj, { merge: true })
      // Write 3 blank rows, plus userId for security rules
      blankRows = { userId: this.user.id }
      for (let i = 0; i < 3; i++) {
        blankRows[this.randomId()] = {
          seq: i
        }
      }
      batch.set(db.doc('memoSets/' + memoSetId + '/rows/' + this.randomId()), blankRows)
      this.addingMemoSetId = memoSetId
      this.addingFolderIndex = folderIndex
      batch.commit()
        .then(() => {
          // Route to the new memo set
          this.$router.push('memo-sets/' + memoSetId + '/' + slug)
        })
        .catch(error => {
          this.addingMemoSetId = ''
          logError('MemoSets_addMemoSetClick', error)
        })
    },
    addMemoSetWrapperDragEnter: function (folderIndex) {
      if (this.draggingMemoSet) {
        this.memoSetDragEnters++
        this.toFolderIndex = folderIndex
        if (this.folders[folderIndex].memoSets) {
          this.toMemoSetIndex = this.folders[folderIndex].memoSets.length
        } else {
          this.toMemoSetIndex = 0
        }
      }
    },
    addMemoSetWrapperDragLeave: function () {
      if (this.draggingMemoSet) {
        this.memoSetDragEnters--
        if (!this.memoSetDragEnters) {
          this.toMemoSetIndex = -1
        }
      }
    },
    checkReady: function () {
      // Redirect to Home page if the user data has loaded but the user isn't signed in
      if (this.user.uiLanguage && !this.user.folders) {
        this.$router.push('/')
      }
      if (this.user.folders && this.memoSets) {
        // If we have a saved accordion state
        if (window.memq && window.memq.accordionState) {
          this.accordionState = Object.assign({}, window.memq.accordionState)
        } else {
          // If there's one folder, open it
          if (this.user.folders.length === 1) {
            $('.ui.accordion').accordion('open', 0)
            this.accordionState[this.user.folders[0].folderId] = true
          } else {
            // Close all folders
            this.closeAll()
            this.accordionState = {}
          }
          window.memq = window.memq || {}
          window.memq.accordionState = Object.assign({}, this.accordionState)
        }
        // Sync open folders with accordionState
        for (let i = 0; i < this.folders.length; i++) {
          if (this.accordionState[this.folders[i].folderId]) {
            $('.ui.accordion').accordion('open', i)
          } else {
            $('.ui.accordion').accordion('close', i)
          }
        }
        // Initialize search
        this.initSearch()
        this.ready = true
      }
    },
    closeAll: function () {
      let i
      // Close all accordion sections
      for (i = 0; i < this.folders.length; i++) {
        if (this.accordionState[this.folders[i].folderId]) {
          $('.ui.accordion').accordion('close', i)
        }
      }
    },
    deleteFolderClick: function (index) {
      $('.ui.accordion').accordion('close', index)
      this.folders.splice(index, 1)
      this.writeFolders()
    },
    disableMenuForTouch: function (event) {
      if (this.touch) {
        event.preventDefault()
        event.stopPropagation()
        return false
      }
    },
    folderDragEnd: function () {
      this.draggingFolder = false
    },
    folderDragEnter: function (folderIndex) {
      if (this.draggingFolder) {
        this.folderDragEnters++
        this.toFolderIndex = folderIndex
      }
      if (this.draggingMemoSet) {
        this.memoSetDragEnters++
        if (this.pendingFolder !== -1 && folderIndex !== this.pendingFolder) {
          // Cancel timeout if there is one already
          if (this.memoSetDragFolderTimeout !== null) {
            clearTimeout(this.memoSetDragFolderTimeout)
            this.memoSetDragFolderTimeout = null
            this.pendingFolder = -1
          }
        }
        // If the folder is closed and not pending open
        if (!this.accordionState[this.folders[folderIndex].folderId] && folderIndex !== this.pendingFolder) {
          this.memoSetDragFolderTimeout = setTimeout(() => {
            this.memoSetDragFolderTimeout = null
            $('.ui.accordion').accordion('open', folderIndex)
            this.pendingFolder = -1
          }, 500)
          this.pendingFolder = folderIndex
        }
        this.toFolderIndex = folderIndex
        this.toMemoSetIndex = 0
      }
    },
    folderDragLeave: function () {
      if (this.draggingFolder) {
        this.folderDragEnters--
        // If we haven't entered another folder, we've left all folders
        if (!this.folderDragEnters) {
          this.toFolderIndex = -1
        }
      }
      if (this.draggingMemoSet) {
        this.memoSetDragEnters--
        // If we haven't entered another folder, we've left all folders
        if (!this.memoSetDragEnters) {
          // Cancel timeout if there is one
          if (this.memoSetDragFolderTimeout !== null) {
            clearTimeout(this.memoSetDragFolderTimeout)
            this.memoSetDragFolderTimeout = null
            this.pendingFolder = -1
          }
        }
      }
    },
    folderDragStart: function (folderIndex, event) {
      // Ignore dragstart if touching the screen
      if (this.folderTouchInitialTimeout || this.touchMovingFolder) return
      // Close all accordion sections
      this.closeAll()
      this.draggingFolder = true
      this.fromFolderIndex = folderIndex
      this.folderDragEnters = 0
      event.dataTransfer.effectAllowed = 'move'
    },
    folderDrop: function () {
      if (this.draggingFolder) {
        this.moveFolder()
      }
      if (this.draggingMemoSet) {
        // Move memo set to the start of the folder
        this.moveMemoSet()
      }
    },
    folderHeaderClick: function (event, folderIndex) {
      if (folderIndex === this.renamingFolder) {
        event.stopPropagation()
      }
    },
    folderNameBlur: function (event) {
      if (this.renamingFolder === -1) return
      // If no folder name, revert to previous value
      if (!event.target.textContent.trim()) {
        event.target.textContent = this.folders[this.renamingFolder].folderName
        // Delay resetting renamingFolder to prevent section collapse if folder title bar has been clicked
        setTimeout(() => {
          this.renamingFolder = -1
        }, 200)
        return
      }
      // If folder name has changed
      if (event.target.textContent.trim() !== this.folders[this.renamingFolder].folderName) {
        this.folders[this.renamingFolder].folderName = event.target.textContent.trim()
        this.writeFolders()
        // Update search data
        this.initSearch()
      }
      // Delay resetting renamingFolder to prevent section collapse if folder title bar has been clicked
      setTimeout(() => {
        this.renamingFolder = -1
      }, 200)
    },
    folderTouchEnd: function () {
      if (this.folderTouchInitialTimeout !== null) {
        clearTimeout(this.folderTouchInitialTimeout)
        this.folderTouchInitialTimeout = null
      }
      if (this.touchMovingFolder) {
        this.moveFolder()
      }
      this.touchMovingFolder = false
    },
    folderTouchMove: function (event) {
      let folders, i, touchLocation, touchTarget
      if (this.folderTouchInitialTimeout !== null) {
        clearTimeout(this.folderTouchInitialTimeout)
        this.folderTouchInitialTimeout = null
      }
      if (this.touchMovingFolder) {
        // Determine touch target
        touchLocation = event.changedTouches[0]
        touchTarget = document.elementFromPoint(touchLocation.clientX, touchLocation.clientY)
        // Handle page scrolling
        this.touchY = touchLocation.clientY
        if (!this.touchScrollTimeout) {
          this.touchScroll()
        }
        // Check whether the touch is over a folder
        folders = document.querySelectorAll('.ui.accordion .title')
        for (i = 0; i < folders.length; i++) {
          if (folders[i].contains(touchTarget)) {
            this.toFolderIndex = i
            break
          }
        }
        event.preventDefault()
      }
    },
    folderTouchStart: function (index, event) {
      this.touchMovingFolder = false
      if (this.folders.length > 1) {
        this.folderTouchInitialTimeout = setTimeout(() => {
          this.folderTouchInitialTimeout = null
          this.touchMovingFolder = true
          // Close all accordion sections
          this.closeAll()
          this.fromFolderIndex = index
          this.toFolderIndex = index
          this.touchY = event.clientY
        }, 500)
      }
    },
    initSearch: function () {
      let searchData
      // Exit if search box not mounted yet
      if (!$('.ui.search').length) return
      searchData = []
      this.folders.forEach((folder, folderIndex) => {
        folder.memoSets.forEach(memoSet => {
          searchData.push({
            category: folder.folderName,
            folderIndex: folderIndex,
            memoSetId: memoSet.memoSetId,
            slug: memoSet.slug,
            title: memoSet.title
          })
        })
      })
      $('.ui.search')
        .search({
          cache: false,
          onSelect: (result, response) => {
            // Save the accordion state with only the selected folder open
            window.memq.accordionState = {}
            window.memq.accordionState[this.folders[result.folderIndex].folderId] = true
            this.$router.push('memo-sets/' + result.memoSetId + '/' + result.slug)
          },
          showNoResults: false,
          source: searchData,
          type: 'category'
        })
    },
    memoSetDragEnd: function () {
      this.draggingMemoSet = false
    },
    memoSetDragEnter: function (folderIndex, memoSetIndex) {
      this.memoSetDragEnters++
      this.toFolderIndex = folderIndex
      this.toMemoSetIndex = memoSetIndex
    },
    memoSetDragLeave: function () {
      this.memoSetDragEnters--
      if (!this.memoSetDragEnters) {
        this.toMemoSetIndex = -1
      }
    },
    memoSetDragStart: function (folderIndex, memoSetIndex, event) {
      // Ignore dragstart if touching the screen
      if (this.memoSetTouchInitialTimeout || this.touchMovingMemoSet) return
      this.draggingMemoSet = true
      this.fromFolderIndex = folderIndex
      this.fromMemoSetIndex = memoSetIndex
      this.toFolderIndex = folderIndex
      this.toMemoSetIndex = memoSetIndex
      this.memoSetDragEnters = 0
      event.dataTransfer.effectAllowed = 'move'
    },
    memoSetDrop: function () {
      this.moveMemoSet()
    },
    memoSetTouchEnd: function () {
      if (this.memoSetTouchInitialTimeout !== null) {
        clearTimeout(this.memoSetTouchInitialTimeout)
        this.memoSetTouchInitialTimeout = null
      }
      if (this.touchMovingMemoSet) {
        // Move memo set
        this.moveMemoSet()
      }
      this.touchMovingMemoSet = false
    },
    memoSetTouchMove: function (event) {
      let addMemoSetWrappers, folders, i, memoSetNum, memoSets, touchingFolder, touchingMemoSet, touchLocation, touchTarget
      // Clear initial timeout if there is one
      if (this.memoSetTouchInitialTimeout !== null) {
        clearTimeout(this.memoSetTouchInitialTimeout)
        this.memoSetTouchInitialTimeout = null
      }
      if (this.touchMovingMemoSet) {
        touchLocation = event.changedTouches[0]
        touchTarget = document.elementFromPoint(touchLocation.clientX, touchLocation.clientY)
        // Handle page scrolling
        this.touchY = touchLocation.clientY
        if (!this.touchScrollTimeout) {
          this.touchScroll()
        }
        // Check whether the touch is over a memo set
        memoSets = document.querySelectorAll('.ui.accordion tbody tr')
        for (i = 0; i < memoSets.length; i++) {
          if (memoSets[i].contains(touchTarget)) {
            touchingMemoSet = true
            memoSetNum = i
            break
          }
        }
        if (touchingMemoSet) {
          // Figure out which folder and memo set we are on
          for (i = 0; i < this.folders.length; i++) {
            if (memoSetNum < (this.folders[i].memoSets || []).length) {
              this.toFolderIndex = i
              this.toMemoSetIndex = memoSetNum
              break
            }
            memoSetNum -= (this.folders[i].memoSets || []).length
          }
        }
        if (!touchingMemoSet) {
          // Check whether the touch is over the add memo set wrapper
          addMemoSetWrappers = document.querySelectorAll('.addMemoSetWrapper')
          for (i = 0; i < addMemoSetWrappers.length; i++) {
            if (addMemoSetWrappers[i].contains(touchTarget)) {
              touchingMemoSet = true
              this.toFolderIndex = i
              this.toMemoSetIndex = (this.folders[i].memoSets || []).length
            }
          }
        }
        if (!touchingMemoSet) {
          // Check whether the touch is over a folder
          folders = document.querySelectorAll('.ui.accordion .title')
          for (i = 0; i < folders.length; i++) {
            if (folders[i].contains(touchTarget)) {
              touchingFolder = true
              // If this is a new folder, cancel the previous timeout
              if (this.memoSetTouchFolderTimeout && i !== this.pendingFolder) {
                clearTimeout(this.memoSetTouchFolderTimeout)
                this.memoSetTouchFolderTimeout = null
                this.pendingFolder = -1
              }
              // If the folder is closed
              if (!this.accordionState[this.folders[i].folderId]) {
                // Set timeout if not already set
                if (!this.memoSetTouchFolderTimeout) {
                  this.memoSetTouchFolderTimeout = setTimeout(() => {
                    this.memoSetTouchFolderTimeout = null
                    $('.ui.accordion').accordion('open', i)
                    this.pendingFolder = -1
                  }, 500)
                  this.pendingFolder = i
                }
              }
              this.toFolderIndex = i
              this.toMemoSetIndex = 0
              break
            }
          }
        }
        if (!touchingFolder && !touchingMemoSet && this.memoSetTouchFolderTimeout) {
          clearTimeout(this.memoSetTouchFolderTimeout)
          this.memoSetTouchFolderTimeout = null
          this.pendingFolder = -1
        }
        event.preventDefault()
      }
    },
    memoSetTouchStart: function (folderIndex, memoSetIndex, event) {
      this.touchMovingMemoSet = false
      if (this.folders.length > 1 || this.memoSets.length > 1) {
        this.memoSetTouchInitialTimeout = setTimeout(() => {
          this.memoSetTouchInitialTimeout = null
          this.touchMovingMemoSet = true
          this.fromFolderIndex = folderIndex
          this.fromMemoSetIndex = memoSetIndex
          this.toFolderIndex = folderIndex
          this.toMemoSetIndex = memoSetIndex
          this.touchY = event.clientY
        }, 500)
      }
    },
    moveFolder: function () {
      let folder
      if (this.fromFolderIndex !== this.toFolderIndex) {
        folder = this.folders.splice(this.fromFolderIndex, 1)[0]
        this.folders.splice(this.toFolderIndex, 0, folder)
        this.writeFolders()
        this.undoMoveData = {
          moveType: 'folder',
          fromFolderIndex: this.fromFolderIndex,
          toFolderIndex: this.toFolderIndex
        }
        // Need nested $nextTicks for the highlight
        this.$nextTick(() => {
          this.$nextTick(() => {
            // Highlight the moved memo set
            $('#folder_' + this.toFolderIndex).eq(0)
              .transition('stop all')
              .transition('folderGlow')
          })
        })
      }
    },
    moveMemoSet: function () {
      let fromFolder, memoSet, toFolder
      if (this.fromFolderIndex !== this.toFolderIndex || this.fromMemoSetIndex !== this.toMemoSetIndex) {
        fromFolder = this.folders[this.fromFolderIndex]
        toFolder = this.folders[this.toFolderIndex]
        // Cut memo set from orginal position
        memoSet = fromFolder.memoSets.splice([this.fromMemoSetIndex], 1)[0]
        // Create memoSets array for toFolder if none
        toFolder.memoSets = toFolder.memoSets || []
        // Paste memo set into new position
        toFolder.memoSets.splice(this.toMemoSetIndex, 0, memoSet)
        this.writeMemoSets()
        this.initSearch()
        this.undoMoveData = {
          moveType: 'memoSet',
          fromFolderIndex: this.fromFolderIndex,
          fromMemoSetIndex: this.fromMemoSetIndex,
          toFolderIndex: this.toFolderIndex,
          toMemoSetIndex: this.toMemoSetIndex
        }
        // Need nested $nextTicks for highlight when memo set has changed folders
        this.$nextTick(() => {
          this.$nextTick(() => {
            // Highlight the moved memo set
            $('#' + memoSet.memoSetId).eq(0)
              .transition('stop all')
              .transition('glow')
          })
        })
      }
    },
    renameFolderClick: function (index) {
      // If already renaming the folder
      if (this.renamingFolder === index) {
        // Blur the button
        document.getElementById('renameFolder' + index).blur()
      } else {
        // Start renaming the folder
        this.renamingFolder = index
        this.$nextTick(() => {
          let folderTitle = document.getElementById('folder' + index)
          folderTitle.focus()
          selectElementContents(folderTitle)
        })
      }
    },
    scrollPage: function (position) {
      if (position < 150) {
        window.scrollBy({
          left: 0,
          top: position - 150,
          behavior: 'smooth'
        })
      }
      if (position > this.window.height - 100) {
        window.scrollBy({
          left: 0,
          top: position - (this.window.height - 100),
          behavior: 'smooth'
        })
      }
    },
    setPageTitle: function () {
      if (this.text && this.text.navMemoSets) {
        document.title = this.text.navMemoSets + ' - MemQ'
      }
    },
    tableHeadDragEnter: function (folderIndex) {
      if (this.draggingMemoSet) {
        this.memoSetDragEnters++
        this.toFolderIndex = folderIndex
        this.toMemoSetIndex = 0
      }
    },
    tableHeadDragLeave: function () {
      if (this.draggingMemoSet) {
        this.memoSetDragEnters--
        if (!this.memoSetDragEnters) {
          this.toMemoSetIndex = -1
        }
      }
    },
    tableHeadDrop: function () {
      if (this.draggingMemoSet) {
        // Move memo set to the start of the folder
        this.moveMemoSet()
      }
    },
    touchScroll: function () {
      this.touchScrollTimeout = null
      if (this.touchMovingFolder || this.touchMovingMemoSet) {
        if (this.touchY < 150) {
          window.scrollBy({
            left: 0,
            top: (this.touchY - 150) / 8
          })
          // For some mobile browsers, the scrollbar is on the pusher instead of the window
          document.querySelector('.pusher').scrollTop += this.touchY - 150
        }
        if (this.touchY > this.window.height - 100) {
          window.scrollBy({
            left: 0,
            top: (this.touchY - (this.window.height - 100)) / 8
          })
          // For some mobile browsers, the scrollbar is on the pusher instead of the window
          document.querySelector('.pusher').scrollTop += this.touchY - (this.window.height - 100)
        }
        this.touchScrollTimeout = setTimeout(this.touchScroll, 15)
      }
    },
    undoMove: function () {
      if (this.undoMoveData.moveType === 'folder') {
        this.fromFolderIndex = this.undoMoveData.toFolderIndex
        this.toFolderIndex = this.undoMoveData.fromFolderIndex
        this.moveFolder()
      }
      if (this.undoMoveData.moveType === 'memoSet') {
        this.fromFolderIndex = this.undoMoveData.toFolderIndex
        this.fromMemoSetIndex = this.undoMoveData.toMemoSetIndex
        this.toFolderIndex = this.undoMoveData.fromFolderIndex
        this.toMemoSetIndex = this.undoMoveData.fromMemoSetIndex
        this.moveMemoSet()
      }
    },
    writeFolders: function () {
      let folders
      // Prepare array of folders with no memo sets
      folders = []
      this.folders.forEach(folder => {
        folders.push({
          folderId: folder.folderId,
          folderName: folder.folderName
        })
      })
      db.doc('users/' + this.user.id)
        .update({
          folders: folders
        })
        .catch(error => { logError('MemoSets_writeFolders', error) })
    },
    writeMemoSets: function () {
      let docIds, updateObj
      updateObj = {}
      this.folders.forEach(folder => {
        folder.memoSets.forEach((memoSet, memoSetIndex) => {
          if (!updateObj[memoSet.docId]) updateObj[memoSet.docId] = {}
          updateObj[memoSet.docId][memoSet.memoSetId + '.folderId'] = folder.folderId
          updateObj[memoSet.docId][memoSet.memoSetId + '.seq'] = memoSetIndex
        })
      })
      docIds = Object.keys(updateObj)
      docIds.forEach(docId => {
        db.doc('users/' + this.user.id + '/memoSets/' + docId)
          .update(updateObj[docId])
          .catch(error => { logError('MemoSets_writeMemoSets', error) })
      })
    }
  },
  props: ['connected', 'memoSets', 'mobile', 'text', 'statuses', 'touch', 'user', 'window'],
  watch: {
    memoSets: function (newMemoSets) {
      this.checkReady()
    },
    text: function (newText) {
      this.setPageTitle()
    },
    user: function (newUser) {
      this.checkReady()
    }
  }
}
</script>

<style>
  /* folderGlow animation is similar to glow but with background #d6dadf */
  .transition.folderGlow {
    animation-duration: 1.2s;
    animation-timing-function: ease-out;
  }
  .transition.folderGlow {
    animation-name: folderGlow;
  }
  @keyframes folderGlow {
    0% {background-color: #d6dadf}
    10% {background-color: #fff6cd}
    50% {background-color: #fff6cd}
    100% {background-color: #d6dadf}
  }
</style>
<style scoped>
  /* Remove vertical space around grid */
  .ui.grid {
    margin-bottom: 0;
    margin-top: 0;
  }
  /* Increase styled accordion width */
  .ui.styled.accordion {
    width: 100%;
  }
  /* Set folder heading style */
  .ui.accordion .title:not(.ui) {
    font-size: 120%;
    font-weight: bold;
  }
  /* Hide closed folder icon when active */
  .ui.accordion .title.active .folder:not(.open) {
    display: none;
  }
  /* Hide open folder icon when not active */
  .ui.accordion .title:not(.active) .folder.open {
    display: none;
  }
  /* Inactive accordion items */
  .ui.styled.accordion .title {
    color: rgba(0,0,0,.6);
    font-weight: bold;
    background-color: #d6dadf;
  }
  /* Keep standard color on hover or active */
  .ui.styled.accordion .active.title,
  .ui.styled.accordion .title:hover {
    color: rgba(0,0,0,.87);
    background-color: whitesmoke;
  }
  .content {
    background-color: whitesmoke;
  }
  .ui.styled.accordion .content {
    /* Add horizontal scrollbar to a folder if needed */
    overflow-x: auto;
    padding-bottom: 0;
    padding-left: 1.6em;
    padding-right: 1.6em;
  }
  /* Move loading indictor down a little */
  .readyLoader {
     margin-top: 0.6rem !important;
  }
  .folderIcon {
    padding-top: 0.3rem;
    vertical-align: top !important;
  }
  .addFolderWrapper {
    margin-top: 1.6rem;
    padding-bottom: 2rem;
  }
  .addFolderLink {
    border-radius: 6px;
    color: gray;
    cursor: pointer;
    padding: 0.6rem 1rem;
  }
  .addFolderLink:hover {
    background-color: #dbe6e6;
    color: rgba(0,0,0,.87);
  }
  .addMemoSetLink {
    border-radius: 6px;
    color: gray;
    cursor: pointer;
    padding: 0.6rem 1rem;
  }
  .addMemoSetLink:hover {
    background-color: #cededd;
    color: rgba(0,0,0,.87);
  }
  /* Style folder buttons */
  .folderButton {
    background-color: transparent;
    float: right;
  }
  .folderButton:hover {
    background-color: #e0e1e2;
  }
  /* Hide folder buttons for inactive sections */
  .accordion .title:not(.active) .folderButton {
    display: none;
  }
  .folderTitle {
    border: 2px solid transparent;
    border-radius: 4px;
    display: inline-block;
    overflow-wrap: break-word;
    padding: 5px 8px;
    width: calc(100% - 10rem);
  }
  .folderTitle:focus {
    border: 2px solid lightsteelblue !important;
    cursor: auto !important;
  }
  /* Hide outline around contenteditable */
  div[contenteditable] {
    outline: none;
  }
  .insertAbove {
    border-top: 3px solid black !important;
  }
  .insertBelow {
    border-bottom: 3px solid black !important;
  }
  /* Remove space at bottom of table - need next div directly below it */
  .ui.table {
    margin-bottom: 0;
  }
  .addMemoSetWrapper {
    padding-bottom: 2rem;
    padding-top: 1.4rem;
  }
  .pendingFolder {
    background-color: rgba(220, 220, 220, 0.8) !important;
  }
  .accordionTitleInnerWrapper {
    padding: .75em 1em
  }
  .title {
    padding: 0 !important;
  }
  .coverImageWrapper {
    max-width: 160px;
  }
  img {
    max-height: 70px !important;
    max-width: 160px !important;
  }
  tbody tr {
    cursor: pointer;
  }
  /* Make table rows a minimum height */
  .imgCell {
    height: 80px;
  }
  .ui.table thead tr th {
    background-color: #395f7f;
    color: rgba(255, 255, 255, 0.87);
  }
  .noMargin {
    margin: 0 !important;
  }
  .ui.search {
    margin-bottom: 1rem;
  }
  /* Move padding from td to td > a to allow anchor to work in entire cell */
  td {
    padding: 0 !important;
    overflow: hidden;
  }
  td > a {
    color: rgba(0,0,0,.87);
  }
  /* Make <a> within <td> fill the entire cell, as per https://stackoverflow.com/a/15801081 */
  td {
    overflow: hidden;
  }
  td > a {
    display: block;
    margin: -5em;
    padding: 5.4em;
  }
  .accordion.mobile {
    border-radius: 0 !important;
  }
  /* Reduce accordion content padding */
  .accordion.mobile .content {
    padding-left: 1em !important;
    padding-right: 1em !important;
  }
  /* Reduce size of images */
  .accordion.mobile img {
    max-height: 60px !important;
    max-width: 80px !important;
  }
  .accordion.mobile .imgCell {
    /* Reduce minimum height of table rows */
    height: 74px;
  }
  .indent1 {
    margin-left: 0.5em;
  }
  .indent2 {
    margin-left: 1em;
  }
  .indent3 {
    margin-left: 1.5em;
  }
  .rightMargin1 {
    margin-right: 1em !important;
  }
  .ui.table tbody tr td.selectable, .ui.ui.selectable.table>tbody>tr {
    background-color: #ffffff;
  }
  .ui.table tbody tr td.selectable:hover, .ui.ui.selectable.table>tbody>tr:hover {
    background-color: #e6e9ef;
  }
</style>
