<template>
  <div id="app" class="tex2jax_ignore">
    <!-- Sidebar menu -->
    <div class="ui vertical inverted sidebar menu">
      <a class="item" @click="hideSidebar()">&nbsp;<i class="close icon"></i></a>
      <a class="item" :class="{active: $route && $route.path === '/'}" v-show="ready" @click="sidebarGo('/')">{{ text.navHome }}</a>
      <a class="item" :class="{active: $route && $route.path.substring(0, 10) === '/memo-sets'}" v-show="ready && user.id" @click="sidebarGo('/memo-sets')">{{ text.navMemoSets }}</a>
      <a class="item" :class="{active: $route && $route.path.substring(0, 17) === '/shared-memo-sets'}" v-show="ready && user.id && showSharedWithMe" @click="sidebarGo('/shared-memo-sets')">{{ text.navSharedMemoSets }}</a>
      <a class="item" :class="{active: $route && $route.path.substring(0, 17) === '/public-memo-sets'}" v-show="ready && user.id" @click="sidebarGo('/public-memo-sets')">{{ text.navPublicMemoSets }}</a>
      <a class="item" href="https://forum.artofmemory.com" target="_blank" rel="noopener" v-show="ready">{{ text.navForum }}<i class="external icon"></i></a>
      <a class="item" href="https://memoryleague.com" target="_blank" rel="noopener" v-show="ready">Memory League<i class="external icon"></i></a>
      <a class="item" href="https://artofmemory.com/app" target="_blank" rel="noopener" v-show="ready">{{ text.navTutorials }}<i class="external icon"></i></a>
      <a class="item" href="https://artofmemory.com/wiki" target="_blank" rel="noopener" v-show="ready">Wiki<i class="external icon"></i></a>
    </div>
    <div class="pusher">
      <!-- Main navbar -->
      <div class="ui fixed inverted menu noPrint">
        <div class="ui fluid container" :class="{minHeightForUnregistered: ready && !user.id}">
          <router-link class="header item memq" :class="{'mobile': mobile}" to="/" active-class="none" exact><img :src="'/memq.png'" /></router-link>
          <a class="item" v-show="mobile && user.id" @click="showSidebar"><i class="sidebar icon"></i></a>
          <router-link v-show="ready && !mobile && user.id" class="item main" to="/" exact>{{ text.navHome }}</router-link>
          <router-link v-show="ready && !mobile && user.id" class="item main" to="/memo-sets">{{ text.navMemoSets }}</router-link>
          <router-link v-show="ready && !mobile && user.id && showSharedWithMe" class="item main" to="/shared-memo-sets">{{ text.navSharedMemoSets }}</router-link>
          <router-link v-show="ready && !mobile && user.id" class="item main" to="/public-memo-sets">{{ text.navPublicMemoSets }}</router-link>
          <div v-show="ready && !mobile && user.id" class="ui simple dropdown item">
            <i class="ellipsis vertical icon"></i>
            <div class="menu">
              <a v-show="ready && !mobile" class="item" href="https://forum.artofmemory.com" target="_blank" rel="noopener">
                <span class="moreOptionsItem">{{ text.navForum }}</span>
                <i class="external icon"></i>
              </a>
              <a v-show="ready && !mobile" class="item" href="https://memoryleague.com" target="_blank" rel="noopener">
                <span class="moreOptionsItem">Memory League</span>
                <i class="external icon"></i></a>
              <a v-show="ready && !mobile" class="item" href="https://artofmemory.com/app" target="_blank" rel="noopener">
                <span class="moreOptionsItem">{{ text.navTutorials }}</span>
                <i class="external icon"></i>
              </a>
              <a v-show="ready && !mobile" class="item" href="https://artofmemory.com/wiki" target="_blank" rel="noopener">
                <span class="moreOptionsItem">Wiki</span>
                <i class="external icon"></i>
              </a>
            </div>
          </div>
          <div class="right menu" v-show="ready">
            <!-- Sign in button -->
            <router-link v-show="ready && !user.id && $route.name !== 'Register' && $route.name !== 'SignIn' && $route.name !== 'Recover'" class="item notActive navSignIn" to="/sign-in">{{ text.signIn }}</router-link>
            <!-- Register button -->
            <router-link v-show="ready && !user.id && $route.name !== 'Register' && $route.name !== 'SignIn' && $route.name !== 'Recover'" class="item notActive navRegister" to="/register">{{ text.register }}</router-link>
            <!-- Info menu -->
            <div class="ui dropdown item narrow" v-show="user.id">
              <i class="info circle icon"></i>
              <div class="ui menu">
                <div class="header">{{ text.navInfoTitle }}</div>
                <div class="divider"></div>
                <router-link class="item notActive" to="/guide" target="_blank" rel="noopener">{{ text.navInfoGuide }}</router-link>
                <router-link class="item" to="/contact-us">{{ text.contactUs }}</router-link>
                <a class="item notActive" href="https://artofmemory.com/legal/terms" target="_blank" rel="noopener">{{ text.navInfoTerms }}</a>
                <a class="item notActive" href="https://artofmemory.com/legal/privacy" target="_blank" rel="noopener">{{ text.navInfoPrivacy }}</a>
              </div>
            </div>
            <!-- Language menu -->
            <div class="ui dropdown item narrow" v-show="user.id || languages.length > 2">
              <i :class="{ large: !user.id }" class="globe americas icon noRightMargin"></i>
              <div class="ui menu">
                <div class="header">{{ text.navLanguageTitle }}</div>
                <div class="divider"></div>
                <div class="item" :class="{'active selected': language.countryCode === user.uiLanguage}" v-for="language in languages" :key="language.countryCode" @click="setLanguage(language.countryCode)">
                  <i :class="language.countryCode" class="flag"></i> {{ language.name }}
                </div>
                <div class="item" key="add" @click.stop="addLanguageClick()" v-if="user.id">
                  <i class="plus icon"></i> {{ text.appRequestLanguage }}
                </div>
              </div>
            </div>
            <!-- User menu -->
            <div class="ui dropdown item narrow" :class="{'desktopMenuRightMargin': !mobile}" v-show="user.id">
              <i class="user icon"></i>
              <div class="ui menu userMenu">
                <div class="header">{{ user.displayName }}</div>
                <div class="divider"></div>
                <router-link v-if="user.admin" class="item notActive" to="/admin">Admin</router-link>
                <router-link class="item notActive" to="/account">{{ text.navUserMyAccount }}</router-link>
                <div class="item notActive" @click="signOutClick()">{{ text.navUserSignOut }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="ui fluid wrapper" @touchstart="touch = true">
        <div v-if="!ready" class="ui active inline loader"></div>
        <router-view
          v-if="ready"
          :connected="connected"
          :history="history"
          :key="$route.fullPath"
          :memo-sets="memoSets"
          :mobile="mobile"
          :shared-memo-sets="sharedMemoSets"
          :statuses="statuses"
          :text="text"
          :touch="touch"
          :user="user"
          :version="version"
          :window="window"
          @check-history="checkHistory()"
        >
        </router-view>
      </div>
    </div>
    <div class="ui modal addLanguage">
      <div class="ui header">
        {{ text.appRequestLanguage }}
      </div>
      <div class="content">
        <div class="ui grid" :class="{'mobile': mobile}">
          <!-- Language requested -->
          <div class="modalLabel" :class="{'three wide column': !mobile, 'sixteen wide column': mobile}">
            <span>{{ text.appLanguageRequested }}</span>
          </div>
          <div class="noTopPadding" :class="{'thirteen wide column': !mobile, 'sixteen wide column': mobile}">
            <div class="ui fluid input">
              <input type="text" v-model="languageRequested" autocomplete="off" :placeholder="text.appLanguageRequestedPlaceholder">
            </div>
          </div>
        </div>
        <div class="ui form" style="margin-bottom: 3rem" onsubmit="return false">
          <div class="field">
            <div class="ui checkbox" id="canTranslate" @click="canTranslate = !canTranslate">
              <input type="checkbox" v-model="canTranslate" tabindex="0" class="hidden">
              <label>{{ text.appLanguageCanHelp }}</label>
            </div>
          </div>
        </div>
      </div>
      <div class="actions">
        <div class="ui primary ok button" :class="{disabled: !languageRequested}">{{ text.commonOK }}</div>
        <div class="ui cancel button">{{ text.commonCancel }}</div>
      </div>
    </div>
    <div class="ui modal setDisplayName">
      <div class="ui header">
        {{ text.setDisplayName }}
      </div>
      <div class="content setDisplayNameContent">
        <p>{{ text.setDisplayNameInstructions }}</p>
        <div class="ui fluid input">
          <input type="text" v-model="displayName" :placeholder="text.setDisplayNamePlaceholder" :disabled="saving" @input="displayNameUnavailable = false">
        </div>
        <div class="ui red message" v-show="displayNameUnavailable">
          <p>{{ text.registerDisplayNameNotAvailable }}</p>
        </div>
      </div>
      <div class="actions">
        <div class="ui primary ok button" :class="{disabled: !displayName || displayNameUnavailable || saving, loading: saving}">{{ text.commonOK }}</div>
      </div>
    </div>
  </div>
</template>

<script>
const $ = window.$
const firebase = window.firebase
const db = firebase.firestore()
let checkHistoryCount = 0

function logError (errorCode, error) {
  console.log(errorCode)
  console.log(error)
}

export default {
  name: 'app',
  components: {},
  data () {
    return {
      canTranslate: false,
      connected: true,
      detachKnownDataListener: null,
      detachMemoSetsListener: null,
      detachSharedMemoSetsListener: null,
      detachTextListener: null,
      detachUpdateUserListener: null,
      detachUsersListener: null,
      displayName: '',
      displayNameUnavailable: false,
      history: null,
      historyDb: null,
      historyLoaded: false,
      memoSets: null,
      knownData: null,
      languageRequested: '',
      languages: [
        // { countryCode: 'cn', name: '中文' },
        // { countryCode: 'de', name: 'Deutsch' },
        { countryCode: 'gb', name: 'English (UK)' },
        { countryCode: 'us', name: 'English (US)' }
        // { countryCode: 'es', name: 'Español' },
        // { countryCode: 'fr', name: 'Français' }
      ],
      ready: false,
      saving: false,
      sharedMemoSets: [],
      statuses: {},
      statusesTimeout: null,
      text: {},
      textEnglish: {
        accountSignInError: 'An unexpected error has occurred. Please sign in again using your new email address (<email>).',
        appLanguageCanHelp: 'I can help with translating text from English to this language.',
        appLanguageRequested: 'Language',
        appLanguageRequestedError: 'Sorry, an error occurred. Please try again later.',
        appLanguageRequestedPlaceholder: 'Enter the language you would like to request...',
        appLanguageRequestedSuccess: 'Thank you - your request has been submitted.',
        appRequestLanguage: 'Request Language',
        commonAdd: 'Add',
        commonCancel: 'Cancel',
        commonChange: 'Change',
        commonClose: 'Close',
        commonCopy: 'Copy',
        commonDelete: 'Delete',
        commonDone: 'Done',
        commonEmailAddress: 'Email Address',
        commonEmailAddressPlaceholder: 'Email address...',
        commonError: 'Error',
        commonNo: 'No',
        commonOK: 'OK',
        commonOpen: 'Open',
        commonOr: 'Or',
        commonPassword: 'Password',
        commonPasswordPlaceholder: 'Password...',
        commonReconnecting: 'Reconnecting...',
        commonRedo: 'Redo',
        commonReset: 'Reset',
        commonRows: 'Rows',
        commonSearchPlaceholder: 'Search...',
        commonStatus: 'Status',
        commonUndo: 'Undo',
        commonYes: 'Yes',
        contactError: 'An error has occurred. Please email support@artofmemory.com.',
        contactInfo: 'Please email support@artofmemory.com, or use the form below.',
        contactMessage: 'Message',
        contactMessagePlaceholder: 'Message...',
        contactSend: 'Send message',
        contactSent: 'Your message has been sent.',
        contactSubject: 'Subject',
        contactSubjectPlaceholder: 'Subject...',
        contactUs: 'Contact Us',
        contactYourName: 'Your Name',
        contactYourNamePlaceholder: 'Name...',
        homeActiveMemoSets: 'Active memo sets',
        homeChartDue: 'Due',
        homeChartKnown: 'Known',
        homeChartLabel: 'Last <days> days',
        homeChartUnknown: 'Unknown',
        homeDue: 'Due',
        homeItemsDueForReview: 'Items due for review',
        homeItemsKnown: 'Items known',
        homeItemsUnknown: 'Items unknown',
        homeNoActiveMemoSets1: 'You have no active memo sets.',
        homeNoActiveMemoSets2: 'To activate a memo set, ensure that the memo set has data and at least one question, then select <strong>Active</strong> on the <strong>Info</strong> tab.',
        homeNoMemoSets1: 'You have no memo sets.<br><br>You can create a memo set on the ',
        homeNoMemoSets2: ' page, or add one from the ',
        homeNoMemoSets3: ' page.',
        homeReadyToLearn: 'Ready to Learn',
        homeReadyToLearnInfo1: 'Active memo sets with due or unknown items appear here.',
        homeReadyToLearnInfo2: 'To remove a memo set that you don’t want to learn, open the memo set, go to the <strong>Info</strong> tab, and deselect <strong>Active</strong>.',
        homeSummary: 'Summary',
        homeUpdateHeader: 'Update Available',
        homeUpdateMessage: 'An updated version of MemQ is available. Please close and reopen the application for the latest version.',
        homeUpToDate: 'Congratulations, you’re up to date!',
        memoSetActions: 'Actions',
        memoSetAddAfter: 'Add After',
        memoSetAddAudio: 'Add Audio',
        memoSetAddAudioTooltip: 'Add audio',
        memoSetAddColumn: 'Add Column',
        memoSetAddImage: 'Add Image',
        memoSetAddImageTooltip: 'Add an image',
        memoSetAddPhoto: 'Add Photo',
        memoSetAddQuestion: 'Add Question',
        memoSetAddRows: 'Add Rows',
        memoSetAddText: 'Add Text',
        memoSetAddToMyMemoSets: 'Add to My Memo Sets',
        memoSetAll: 'All',
        memoSetAlreadyPublic: 'This memo set is already shared publicly.',
        memoSetAnswer: 'Answer',
        memoSetApplyToSubsequentRowsLeft: 'Apply to',
        memoSetApplyToSubsequentRowsRight: 'subsequent rows',
        memoSetAppropriate: 'Please ensure that any content shared publicly is appropriate for children.',
        memoSetAsAbove: 'as above',
        memoSetAudioRecordingNotSupported: 'Audio recording is not supported on your device.',
        memoSetAuthor: 'Author',
        memoSetBackgroundPhoto: 'Background Photo',
        memoSetCards: 'Cards',
        memoSetCardStyle: 'Card Style',
        memoSetCellPasteConfirmMessage: 'It looks like you are pasting multiple cells. What would you like to do?',
        memoSetCellPasteIntoMultipleCells: 'Paste into multiple cells',
        memoSetCellPasteIntoSingleCell: 'Paste into a single cell',
        memoSetClip: 'Clip',
        memoSetColumn: 'Column',
        memoSetColumnHeading: 'Column Heading',
        memoSetColumnPopulated: 'Column populated',
        memoSetColumnPosition: 'Column Position',
        memoSetColumns: 'Columns',
        memoSetConfirmPasteHeader: 'Confirm Paste',
        memoSetCongrats: 'Congratulations, you’re up to date for this memo set!',
        memoSetCongratsGroup: 'Congratulations, you’re up to date for the selected group!',
        memoSetCongratsGroups: 'Congratulations, you’re up to date for the selected groups!',
        memoSetCongratsQuestion: 'Congratulations, you’re up to date for the selected question!',
        memoSetCongratsQuestions: 'Congratulations, you’re up to date for the selected questions!',
        memoSetCongratsQuestionGroup: 'Congratulations, you’re up to date for the selected question and group!',
        memoSetCongratsQuestionGroups: 'Congratulations, you’re up to date for the selected question and groups!',
        memoSetCongratsQuestionsGroup: 'Congratulations, you’re up to date for the selected questions and group!',
        memoSetCongratsQuestionsGroups: 'Congratulations, you’re up to date for the selected questions and groups!',
        memoSetContinueReviewing: 'Continue',
        memoSetCopy: 'Copy',
        memoSetCopyAnyImage1: 'Copy any image you find on the web by right-clicking on the image and choosing ‘Copy image’.',
        memoSetCopyAnyImage2: 'Then paste the image into the box below.',
        memoSetCopyDown: 'Copy Down',
        memoSetCopyDownTooltip: 'Copy down',
        memoSetCopyMemoSet: 'Copy Memo Set',
        memoSetCorrect: 'Correct',
        memoSetCouldNotOpen: 'The memo set could not be opened.<br><br>Please try again later.',
        memoSetCoverImage: 'Cover Image',
        memoSetCurrentPosition: '(current position)',
        memoSetData: 'Data',
        memoSetDataCopied: 'Data copied',
        memoSetDataDeleted: 'Data deleted',
        memoSetDataPasted: 'Data pasted',
        memoSetDate: 'Date',
        memoSetDaysBeforeReview: 'Days Before Review',
        memoSetDeckEnglish: 'English (J, Q, K)',
        memoSetDeckFourColor: 'English - 4 Color',
        memoSetDeckGerman: 'German (B, D, K)',
        memoSetDefaultColumn1: 'Column 1',
        memoSetDefaultColumn2: 'Column 2',
        memoSetDefaultDescription: 'Enter any notes about the memo set here...',
        memoSetDefaultQuestion: 'What is the <toField> for *?',
        memoSetDefaultQuestionFromImage: 'What is the <toField> for<br><br>* ?',
        memoSetDefaultQuestionFromImageToSummaryImage: 'What is the summary image for<br><br>* ?',
        memoSetDefaultQuestionFromSummaryImage: 'What is the <toField> for this summary image?<br><br>*',
        memoSetDefaultQuestionToSummaryImage: 'What is the summary image for *?',
        memoSetDeleteColumn: 'Delete Column',
        memoSetDeleteFromRow: 'Delete From Row',
        memoSetDeleteGameResult: 'Delete Game Result',
        memoSetDeleteGameResultQuestion: 'Are you sure you want to delete the game result?',
        memoSetDeleteTestResult: 'Delete Test Result',
        memoSetDeleteTestResultQuestion: 'Are you sure you want to delete the test result?',
        memoSetDeleteToRow: 'Delete To Row',
        memoSetDeleteToRowPlaceholder: 'Enter row number between <delete from row> and <row count>...',
        memoSetDeleteImage: 'Delete Image',
        memoSetDeleteMemoSet: 'Delete Memo Set',
        memoSetDeleteMemoSetContent: 'Are you sure you want to delete the memo set *?',
        memoSetDeleteMemoSetShared1: 'Are you sure you want to delete the shared memo set *?',
        memoSetDeleteMemoSetShared2: 'You will no longer have access to this memo set.',
        memoSetDeleteRows: 'Delete Rows',
        memoSetDeleteText: 'Delete Text',
        memoSetDescription: 'Description',
        memoSetEditQuestion: 'Edit Question',
        memoSetEditText: 'Edit Text',
        memoSetEnterNewColumnHeading: 'Enter the new column heading...',
        memoSetEnterNewFolderName: 'Enter the new folder name...',
        memoSetEnterNewText: 'Enter text...',
        memoSetEnterNewTitle: 'Enter the new memo set title...',
        memoSetEnterRowNumberPlaceholder: 'Enter row number between 1 and <row count>...',
        memoSetEnterRowNumberFromZeroPlaceholder: 'Enter row number between 0 and <row count>...',
        memoSetEnterUsername: 'Enter username...',
        memoSetErase: 'Erase',
        memoSetExample: 'Example',
        memoSetExport: 'Export',
        memoSetExportSuccessful: 'Export Successful',
        memoSetExportSuccessfulDetail: 'The memo set’s data has been copied to your clipboard, and can now be pasted into a spreadsheet or text document.',
        memoSetExportUnsuccessful: 'Export Unsuccessful',
        memoSetExportUnsuccessfulDetail: 'Oops, something has gone wrong. The memo set’s data was not exported.',
        memoSetFirstRow: 'First Row',
        memoSetFirstValue: 'First Value',
        memoSetFlipHorizontal: 'Flip Horizontal',
        memoSetFlipVertical: 'Flip Vertical',
        memoSetFolder: 'Folder',
        memoSetFromColumn: 'From Column',
        memoSetGame: 'Game',
        memoSetGoToMemoSet: 'Go to Memo Set',
        memoSetGoToMyMemoSet: 'Go to My Memo Set',
        memoSetGoToNewCopy: 'Go to New Copy',
        memoSetGroup: 'Group',
        memoSetGroupColorTooltip: 'Set group color',
        memoSetGroups: 'Groups',
        memoSetImages: 'Images',
        memoSetIncludeRepeatedCards: 'Include Repeated Cards',
        memoSetIncorrect: 'Incorrect',
        memoSetInsertAfterRow: 'Insert After Row (optional)',
        memoSetInsertAfterRowPlaceholder: 'Enter row number between 0 and <row count>...',
        memoSetInvalidRowNumber: 'Invalid Row Number',
        memoSetInvalidRowNumberMessage: 'Please enter a number between 1 and <row count>.',
        memoSetLastRow: 'Last Row',
        memoSetLastValue: 'Last Value',
        memoSetLearn: 'Learn',
        memoSetLeft: 'Left',
        memoSetMemoSetAdded: 'Memo Set Added',
        memoSetMemoSetCopied: 'Memo Set Copied',
        memoSetMemoSetCreatedInFolder: '<new title> has been created in folder <folder name>.',
        memoSetMemoSetShared: 'Memo Set Shared',
        memoSetMemoSetSharedWithMessage: 'The memo set has been shared.',
        memoSetMostSignificantCard: 'Most Significant Card',
        memoSetMove: 'Move',
        memoSetMoveAfterRow: 'Move After Row',
        memoSetMoveColumn: 'Move Column',
        memoSetMoveRows: 'Move Rows',
        memoSetMoveRowsLastRowPlaceholder: 'Enter row number between <first row> and <row count>...',
        memoSetMyRating: 'My Rating',
        memoSetNewFolderLabel: 'New Folder',
        memoSetNewFolderItem: '(New Folder)',
        memoSetNewLineTooltip: 'New line',
        memoSetNewNamePre: 'Copy of ',
        memoSetNewNamePost: '',
        memoSetNext: 'Next',
        memoSetNextReviewText: 'Next review in <days> days',
        memoSetNextReviewText1Day: 'Next review in 1 day',
        memoSetNextRows: 'Next Rows',
        memoSetNoData: 'No data!',
        memoSetNoFutureReviews: 'No future reviews',
        memoSetNoGroup: 'No Group',
        memoSetNoGroups: 'No groups are selected!',
        memoSetNone: 'None',
        memoSetNoPhoto: 'No photo',
        memoSetNoQuestionsDefinedHdr: 'No questions are defined!',
        memoSetNoQuestionsDefined: 'Add a question to use the Learn feature.',
        memoSetNoQuestionsSelected: 'No questions are selected!',
        memoSetNoSelfShareMessage: 'You can’t share the memo set with yourself!',
        memoSetNoteForAnimatedImages: '<strong>Note:</strong> for animated images, first download them, then upload them using the button above.',
        memoSetNotes: 'Notes',
        memoSetNoValue: '(No value)',
        memoSetNumberOfQuestions: 'Number of Questions',
        memoSetNumberOfRows: 'Number of Rows',
        memoSetNumberOfRowsPlaceholder: 'Enter the number of rows to be added...',
        memoSetNumbers: 'Numbers',
        memoSetOops: 'Oops!',
        memoSetOpenOriginal: 'Open Original Memo Set',
        memoSetOptions: 'Options',
        memoSetOrderOfSuits: 'Order of Suits',
        memoSetOrderOfValues: 'Order of Values',
        memoSetOriginalAuthor: 'Original Author',
        memoSetOriginalUpdatedHeader: 'Original Memo Set Updated',
        memoSetOriginalUpdatedMessage: 'The original memo set has been updated since you added it.',
        memoSetPanLock: 'Pan Lock',
        memoSetPasteImageHere: 'Paste image here...',
        memoSetPopulateColumn: 'Populate Column',
        memoSetPosition: 'Position',
        memoSetPreview: 'Preview',
        memoSetPrevious: 'Previous',
        memoSetPreviousRows: 'Previous Rows',
        memoSetPrint: 'Print',
        memoSetPublic: 'Public',
        memoSetQuestion: 'Question',
        memoSetQuestionAsterisk: 'Use an asterisk (*) for the value in the "From" column.',
        memoSetQuestionColumnsMustBeDifferent: 'The From Column and To Column must be different.',
        memoSetQuestionConfirmDeleteHeader: 'Are you sure you want to delete this question?',
        memoSetQuestionConfirmDeleteMessage: 'Click <Delete> to delete the question, or <Cancel> to cancel.',
        memoSetQuestionExists: 'There is already a question for those columns.',
        memoSetQuestions: 'Questions',
        memoSetRemoveColor: 'Remove Color',
        memoSetResetLearning: 'Reset Learning',
        memoSetReset: 'Are you sure you want to reset your learning data for this memo set?',
        memoSetResetGroup: 'Are you sure you want to reset your learning data for the selected group?',
        memoSetResetGroups: 'Are you sure you want to reset your learning data for the selected groups?',
        memoSetResetQuestion: 'Are you sure you want to reset your learning data for the selected question?',
        memoSetResetQuestions: 'Are you sure you want to reset your learning data for the selected questions?',
        memoSetResetQuestionGroup: 'Are you sure you want to reset your learning data for the selected question and group?',
        memoSetResetQuestionGroups: 'Are you sure you want to reset your learning data for the selected question and groups?',
        memoSetResetQuestionsGroup: 'Are you sure you want to reset your learning data for the selected questions and group?',
        memoSetResetQuestionsGroups: 'Are you sure you want to reset your learning data for the selected questions and groups?',
        memoSetResetToDefault: 'Reset to Default',
        memoSetReviewCycle: 'Review Cycle',
        memoSetReviewCycleLabelDue: 'Due for review',
        memoSetReviewCycleLabelKnown: 'Known',
        memoSetReviewCycleLabelUnknown: 'Not known',
        memoSetReviewSchedule: 'Review Schedule',
        memoSetRight: 'Right',
        memoSetRow: 'Row',
        memoSetRowQuestion: 'Row Question',
        memoSetSelectAFolderForTheMemoSet: 'Select a folder for the memo set...',
        memoSetSelectAnswerColumn: 'Select the column that answers the question...',
        memoSetSelectAudioFile: 'Select Audio File',
        memoSetSelectBackgroundPhoto: 'Select a background photo:',
        memoSetSelectColumnToDelete: 'Select the column to be deleted...',
        memoSetSelectColumnToInsertBefore: 'Select the column to insert before...',
        memoSetSelectImageFile: 'Please select an image file.',
        memoSetSelectPhoto: 'Select Photo',
        memoSetSelectQuestionColumn: 'Select the column to be used in the question...',
        memoSetSelectTheColumnToBeMoved: 'Select the column to be moved...',
        memoSetSelectTheColumnToPopulate: 'Select the column to populate...',
        memoSetSelectTheDataType: 'Select the data type...',
        memoSetSelectTheNewPositionForTheColumn: 'Select the new position for the column...',
        memoSetSetGroupColor: 'Set Group Color',
        memoSetShare: 'Share',
        memoSetSendToBack: 'Send to Back',
        memoSetSharedWith: 'Shared With',
        memoSetShareMemoSet: 'Share Memo Set',
        memoSetSharePublicly: 'Share publicly',
        memoSetShareWithSpecificUser: 'Share with specific user',
        memoSetShowAnswer: 'Show Answer',
        memoSetShowOnHomePage: 'Active',
        memoSetShowPhotoOverviews: 'Include photo overviews in walkthrough',
        memoSetSortByColumn: 'Sort By Column',
        memoSetSortByColumnPlaceholder: 'Select the column to sort by...',
        memoSetSortDirection: 'Sort Direction',
        memoSetSortRows: 'Sort Rows',
        memoSetStartRecording: 'Start Recording',
        memoSetStay: 'Stay',
        memoSetStopRecording: 'Stop Recording',
        memoSetSummaryImage: 'Summary Image',
        memoSetTabData: 'Data',
        memoSetTabInfo: 'Info',
        memoSetTabLearn: 'Learn',
        memoSetTabQuestions: 'Questions',
        memoSetTest: 'Test',
        memoSetTestInstructions: 'You will see each question once. Your score and total time will be recorded.',
        memoSetTestMaximum: 'Maximum <max>',
        memoSetTestNumberOfQuestions: 'Number of questions',
        memoSetText: 'Text',
        memoSetTitle: 'Title',
        memoSetToColumn: 'To Column',
        memoSetUnableToAccessMicrophone: 'Unable to access microphone.',
        memoSetUpdates: 'Updates',
        memoSetUpdatesInstructions: 'Add a description of any updates to your shared memo set.',
        memoSetUploadingAudio: 'Uploading audio...',
        memoSetUploadingImage: 'Uploading image...',
        memoSetUploadingImages: 'Uploading <remaining> images...',
        memoSetUploadTakePhoto: 'Upload / Take Photo',
        memoSetUseBackgroundPhotos: 'Use background photos',
        memoSetUseGroups: 'Use groups',
        memoSetUserNotFoundMessage: 'No user was found with that username.',
        memoSetUseSummaryImages: 'Use summary images',
        memoSetViewQuestion: 'Question',
        memoSetWalkthrough: 'Walkthrough',
        memoSetZoomIn: 'Zoom In',
        memoSetZoomOut: 'Zoom Out',
        memoSetZoomToSelected: 'Zoom to Selected',
        memoSetsAddAFolder: 'Add a folder',
        memoSetsAddAMemoSet: 'Add a memo set',
        memoSetsColumnImage: 'Image',
        memoSetsColumnMemoSet: 'Memo Set',
        memoSetsColumnRows: 'Rows',
        memoSetsDefaultTitle: 'New memo set',
        memoSetsNewFolder: 'New Folder',
        navForum: 'Forum',
        navHome: 'Home',
        navInfoGuide: 'Guide',
        navInfoPrivacy: 'Privacy Policy',
        navInfoTerms: 'Terms of Use',
        navInfoTitle: 'Information',
        navLanguageTitle: 'Language',
        navMemoSets: 'My Memo Sets',
        navPublicMemoSets: 'Public Memo Sets',
        navSharedMemoSets: 'Shared With Me',
        navTutorials: 'Tutorials',
        navUserMyAccount: 'Account',
        navUserSignOut: 'Sign Out',
        publicAuthor: 'Author',
        publicNoMemoSets: 'No memo sets were found.',
        publicRating: 'Average Rating',
        recoverContactUs: 'Contact us',
        recoverError: 'An error has occurred.',
        recoverMessage: 'We’ll send a recovery link to your email address.',
        recoverNoAccount: 'No account was found for the email address.',
        recoverReturnToSignIn: 'Return to sign in',
        recoverSendRecoveryLink: 'Send recovery link',
        recoverSent: 'The recovery email has been sent.',
        recoverTitle: 'Can’t sign in?',
        register: 'Register',
        registerAlreadyRegistered: 'Already registered?',
        registerDisplayName: 'Display Name',
        registerDisplayNamePlaceholder: 'Display name...',
        registerDisplayNameNotAvailable: 'The display name is not available. Please choose another display name.',
        registerEmailNotAvailable: 'There is already an account for that email address.',
        registerNoAccess: 'MemQ is currently in beta release and only available to Pro account holders.',
        registerPrivacyPolicy: 'Privacy Policy',
        registerTermsMessage: 'I agree to the <Terms of Use> and <Privacy Policy>',
        registerTermsOfUse: 'Terms of Use',
        setDisplayName: 'Set Display Name',
        setDisplayNameInstructions: 'Welcome! Please choose your display name.',
        setDisplayNamePlaceholder: 'Enter display name...',
        signIn: 'Sign In',
        signInCantSignin: 'Can’t sign in?',
        signInError: 'The email address or password is incorrect.',
        signInNoAccount: 'No account?',
        signInUnknownError: 'An error has occurred.',
        unregisteredBetaRelease: 'MemQ is currently in beta release and not currently available to new users.',
        unregisteredExampleMemoSets: 'Example Memo Sets',
        unregisteredHeader: 'MemQ helps you learn.',
        unregisteredLine1: 'Use memory journeys and spaced repetition to learn any information you want.',
        unregisteredLine2: 'Create memo sets containing journeys, data and questions.',
        updating: 'Updating MemQ...'
      },
      touch: false,
      user: {},
      version: {
        codeVersion: 202409191619,
        dbVersion: 0
      },
      window: {
        height: window.innerHeight,
        width: window.innerWidth
      }
    }
  },
  computed: {
    mobile: function () {
      return this.window.width < 767
    },
    showSharedWithMe: function () {
      return this.sharedMemoSets.some(memoSet => !memoSet.status)
    }
  },
  created () {
    window.vmApp = this
    const self = this
    // Listen to app version
    db.doc('version/version')
      .onSnapshot(doc => {
        if (doc.exists && doc.data().version) {
          self.version.dbVersion = doc.data().version
        }
      }, error => { logError('created_version', error) }
      )
    // Listen to connection
    firebase.database().ref('.info/connected').on('value', snapshot => {
      self.connected = snapshot.val()
    })
    // Add window resize listener
    window.addEventListener('resize', function (event) {
      self.window.height = window.innerHeight
      self.window.width = window.innerWidth
    })
    firebase.auth().onAuthStateChanged(function (user) {
      // Detach user listeners
      if (self.detachUsersListener) {
        self.detachUsersListener()
        self.detachUsersListener = null
      }
      if (self.detachKnownDataListener) {
        self.detachKnownDataListener()
        self.detachKnownDataListener = null
      }
      if (self.detachMemoSetsListener) {
        self.detachMemoSetsListener()
        self.detachMemoSetsListener = null
      }
      if (self.detachSharedMemoSetsListener) {
        self.detachSharedMemoSetsListener()
        self.detachSharedMemoSetsListener = null
      }
      // Clear user data
      self.memoSets = null
      self.knownData = null
      self.sharedMemoSets = []
      self.statuses = {}
      self.history = null
      self.historyLoaded = false
      if (user) {
        self.ready = false
        if (!window.memq?.a) self.logSession(user.uid)
        // Listen to user data
        self.detachUsersListener = db.doc('users/' + user.uid)
          .onSnapshot(doc => {
            if (doc.exists) {
              // During beta, only allow users with a subscription to use the app
              const beta = true
              if (beta) {
                if (!doc.data().expiry || doc.data().expiry.seconds * 1000 < Date.now()) {
                  self.signOutClick()
                }
              }
              // Save previous language code
              let previousUiLanguage = self.user.uiLanguage || ''
              // Update user data
              self.user = doc.data()
              self.user.id = user.uid
              // Set admin flag for admin users
              if (self.user.admin) {
                window.memq = window.memq || {}
                window.memq.a = true
              }
              // Load text if we don't already have it
              if (self.user.uiLanguage !== previousUiLanguage) {
                self.loadText()
              } else {
                self.ready = true
                self.checkDisplayName()
              }
            } else {
              logError('App_onAuthStateChanged_0', 'user not found: ' + user.uid)
            }
          }, error => { logError('App_onAuthStateChanged_1', error) }
          )
        // Listen to memo sets data
        self.detachMemoSetsListener = db.collection('users/' + user.uid + '/memoSets')
          .onSnapshot(snapshot => {
            // Populate memoSets array
            self.memoSets = []
            snapshot.forEach(doc => {
              let memoSetIds = Object.keys(doc.data())
              memoSetIds.forEach(memoSetId => {
                let memoSet = doc.data()[memoSetId]
                memoSet.docId = doc.id
                memoSet.memoSetId = memoSetId
                self.memoSets.push(memoSet)
              })
            })
          }, error => { logError('App_onAuthStateChanged_2', error) }
          )
        // Listen to known data
        self.detachKnownDataListener = db.doc('users/' + user.uid + '/knownData/doc')
          .onSnapshot(doc => {
            self.knownData = doc.data() || {}
            self.historyDb = doc.data() ? doc.data().history : null
            self.historyLoaded = true
            // Remove history property from knownData object
            delete self.knownData.history
            self.calculateStatuses()
          }, error => { logError('App_onAuthStateChanged_3', error) }
          )
        // Listen to shared with me data
        self.detachSharedMemoSetsListener = db.collection('users/' + user.uid + '/sharedWithMe')
          .onSnapshot(snapshot => {
            self.sharedMemoSets = []
            snapshot.forEach(doc => {
              let memoSet = doc.data()
              memoSet.memoSetId = doc.id
              self.sharedMemoSets.push(memoSet)
            })
          }, error => { logError('App_onAuthStateChanged_4', error) }
          )
      } else {
        self.user = {
          uiLanguage: 'us'
        }
        window.memq = null
        self.loadText()
      }
    })
  },
  mounted () {
    // Initialize dropdown in menu
    $('.ui.dropdown').dropdown()
    // Add keydown listener
    window.addEventListener('keydown', this.windowKeyDown)
  },
  methods: {
    addLanguageClick: function () {
      this.languageRequested = ''
      this.canTranslate = false
      // Show Add Language modal
      $('.ui.modal.addLanguage')
        .modal({
          onApprove: this.addLanguageSubmit
        })
        .modal('show')
    },
    addLanguageSubmit: function () {
      // Write request to Firestore
      db.collection('languageRequests')
        .add({
          canTranslate: this.canTranslate,
          email: firebase.auth().currentUser.email,
          language: this.languageRequested,
          name: this.user.displayName,
          timestamp: firebase.firestore.FieldValue.serverTimestamp(),
          userId: this.user.id || ''
        })
        .then(() => {
          this.showLanguageRequestedToast(true)
        })
        .catch(error => {
          this.showLanguageRequestedToast(false)
          logError('App_addLanguageSubmit', error)
        })
    },
    calculateStatuses: function () {
      let adjustedBaseTimestamp, adjustedNextDue, adjustedNow, adjustedTimestamp, dueCount, extraDueCount, knownCount, memoSetKnownData, unknownCount
      // Cancel any pending timeout
      if (this.statusesTimeout !== null) {
        clearTimeout(this.statusesTimeout)
        this.statusesTimeout = null
      }
      // Exit if no knownData (e.g. if user is signed out)
      if (!this.knownData) return
      this.statuses = {}
      adjustedNow = Math.round(Date.now() / 10000)
      adjustedNextDue = Infinity
      // For each memo set
      Object.keys(this.knownData).forEach(memoSetId => {
        // Determine statuses from known data
        memoSetKnownData = this.knownData[memoSetId]
        adjustedBaseTimestamp = memoSetKnownData[2]
        unknownCount = memoSetKnownData[0]
        extraDueCount = memoSetKnownData.filter((adjustedOffset, index) => index > 2 && adjustedBaseTimestamp + adjustedOffset <= adjustedNow).length
        dueCount = memoSetKnownData[1] + extraDueCount
        knownCount = memoSetKnownData.length - 3 - extraDueCount
        this.statuses[memoSetId] = {
          due: dueCount,
          known: knownCount,
          unknown: unknownCount
        }
        // Check whether the next due timestamp for the memo set is the least so far
        for (let i = 3; i < memoSetKnownData.length; i++) {
          adjustedTimestamp = adjustedBaseTimestamp + memoSetKnownData[i]
          if (adjustedTimestamp <= adjustedNow) continue
          // Here, memoSetKnownData[i] is the closest future timestamp
          if (adjustedTimestamp < adjustedNextDue) {
            adjustedNextDue = adjustedTimestamp
          }
          break
        }
      })
      // Schedule the next calculation
      if (adjustedNextDue !== Infinity) {
        this.statusesTimeout = setTimeout(this.calculateStatuses, (adjustedNextDue - adjustedNow) * 10000)
      }
    },
    calculateHistory: function () {
      let dateString, firstDate, generateForTimestamp, historyDays, historyDaysAgo, historyForDb, historyStartDate, historyStartString, localHistoryDb, now, todayDate
      let historyCalculated
      if (this.historyDb) {
        // Make a copy of historyDb array
        localHistoryDb = this.historyDb.slice()
        historyStartString = localHistoryDb.shift()
        // Note: historyStartDate is in the user's time zone
        historyStartDate = new Date(
          parseInt(historyStartString.substring(0, 4), 0),
          parseInt(historyStartString.substring(5, 7), 0) - 1,
          parseInt(historyStartString.substring(8, 10), 0)
        )
        // Figure out how many days ago the history starts
        now = new Date()
        todayDate = new Date(
          now.getFullYear(),
          now.getMonth(),
          now.getDate()
        )
        historyDaysAgo = Math.round((todayDate - historyStartDate) / (24 * 60 * 60 * 1000))
        // If we have a full history to yesterday, exit
        if (
          this.history &&
          this.history.length === historyDaysAgo &&
          localHistoryDb.length / 3 === historyDaysAgo
        ) {
          return
        }
        // If history is in the future somehow, write the initial history again
        if (historyDaysAgo < 1) {
          this.writeInitialHistory()
        } else {
          // Determine number of days of history to calculate
          historyDays = Math.min(historyDaysAgo, 9)
          // Remove unneeded history data
          localHistoryDb.splice(0, 3 * (historyDaysAgo - 9))
          // Generate history for the last historyDays days
          // Note: we generate history for the end of the day, so use historyDays - 1
          this.history = []
          generateForTimestamp = todayDate.getTime() - ((historyDays - 1) * 24 * 60 * 60 * 1000)
          while (generateForTimestamp <= todayDate.getTime()) {
            // Use history if available
            if (localHistoryDb.length) {
              const thisDay = localHistoryDb.splice(0, 3)
              this.history.push({
                known: thisDay[0],
                due: thisDay[1],
                unknown: thisDay[2]
              })
            } else {
              // Calculate known figures for the day
              const adjustedGenerateFor = Math.round(generateForTimestamp / 10000)
              let knownTotal = 0
              let dueTotal = 0
              let unknownTotal = 0
              Object.keys(this.knownData).forEach(memoSetId => {
                // Check whether the memo set is hidden
                const memoSet = this.memoSets.find(memoSet => memoSet.memoSetId === memoSetId)
                if (memoSet && !memoSet.hide) {
                  const memoSetKnownData = this.knownData[memoSetId]
                  const adjustedBaseTimestamp = memoSetKnownData[2]
                  const unknownCount = memoSetKnownData[0]
                  const extraDueCount = memoSetKnownData.filter((adjustedOffset, index) => index > 2 && adjustedBaseTimestamp + adjustedOffset <= adjustedGenerateFor).length
                  const dueCount = memoSetKnownData[1] + extraDueCount
                  const knownCount = memoSetKnownData.length - 3 - extraDueCount
                  knownTotal += knownCount
                  dueTotal += dueCount
                  unknownTotal += unknownCount
                }
              })
              this.history.push({
                known: knownTotal,
                due: dueTotal,
                unknown: unknownTotal
              })
              historyCalculated = true
            }
            // Advance to next day
            generateForTimestamp += 24 * 60 * 60 * 1000
          }
          // Remove any zeros at the start of the history array
          while (
            this.history.length &&
            !this.history[0].known &&
            !this.history[0].due &&
            !this.history[0].unknown
          ) {
            this.history.shift()
          }
          // If we have new history
          if (this.history.length && historyCalculated) {
            // Prepare new history start date in yyyy-mm-dd format
            firstDate = new Date(todayDate)
            firstDate.setDate(firstDate.getDate() - this.history.length)
            dateString = firstDate.getFullYear().toString() + '-'
            if (firstDate.getMonth() + 1 < 10) dateString += '0'
            dateString += (firstDate.getMonth() + 1).toString() + '-'
            if (firstDate.getDate() < 10) dateString += '0'
            dateString += firstDate.getDate().toString()
            // Prepare historyForDb array
            historyForDb = [dateString]
            this.history.forEach(item => {
              historyForDb.push(item.known)
              historyForDb.push(item.due)
              historyForDb.push(item.unknown)
            })
            this.writeHistory(historyForDb)
          }
        }
      } else {
        this.writeInitialHistory()
      }
    },
    checkDisplayName: function () {
      if (this.user.displayName) {
        if (this.user.displayName.includes('CONVERTED_USER_') && !(window.memq && window.memq.skipDisplayName)) {
          this.showSetDisplayName()
        }
      }
    },
    checkHistory: function () {
      // If history has not yet loaded from database, wait for it
      if (!this.historyLoaded) {
        checkHistoryCount++
        if (checkHistoryCount < 10) {
          setTimeout(this.checkHistory, 200)
        } else if (checkHistoryCount < 20) {
          setTimeout(this.checkHistory, 1000)
        } else {
          setTimeout(this.checkHistory, 5000)
        }
        return
      }
      this.calculateHistory()
    },
    closeToasts: function () {
      $('.ui.toast').toast('close')
    },
    hideSidebar: function () {
      $('.ui.sidebar').sidebar('hide')
    },
    loadText: function () {
      const self = this
      // Detach previous text listener, if any
      if (this.detachTextListener) {
        this.detachTextListener()
        this.detachTextListener = null
      }
      if (this.user.uiLanguage === 'us') {
        this.text = Object.assign({}, this.textEnglish)
        this.ready = true
        self.checkDisplayName()
      } else {
        // Listen to text in user's language
        this.detachTextListener = db.doc('text/' + this.user.uiLanguage)
          .onSnapshot(doc => {
            // Start with English text
            self.text = Object.assign({}, self.textEnglish)
            // Apply user's language text
            self.text = Object.assign({}, self.text, doc.data() || {})
            self.ready = true
            self.checkDisplayName()
          }, error => { logError('App_loadText', error) }
          )
      }
    },
    logSession: function (userId) {
      db.collection('users/' + userId + '/sessions')
        .add({
          timestamp: firebase.firestore.FieldValue.serverTimestamp(),
          version: this.version && this.version.codeVersion ? this.version.codeVersion : 0
        })
        .catch(error => { logError('App_session', error) })
    },
    setDisplayNameEnter: function () {
      // Exit if modal is incomplete
      if (!this.displayName || this.displayNameUnavailable || this.saving) return
      this.setDisplayNameSubmit()
    },
    setDisplayNameSubmit: function () {
      this.updateUser()
      return false
    },
    setLanguage: function (countryCode) {
      if (this.user.id) {
        // Update users record
        db.doc('users/' + this.user.id)
          .update({
            uiLanguage: countryCode
          })
          .catch(error => { logError('App_setLanguage', error) })
      } else {
        this.user.uiLanguage = countryCode
        this.loadText()
      }
    },
    showLanguageRequestedToast: function (success) {
      this.closeToasts()
      this.$nextTick(() => {
        // Show toast
        $('body')
          .toast({
            compact: false,
            message: success ? this.text.appLanguageRequestedSuccess : this.text.appLanguageRequestedError,
            newestOnTop: true,
            showIcon: success ? 'green check' : 'red exclamation triangle',
            displayTime: 10000
          })
      })
    },
    showSetDisplayName: function () {
      // Show modal to set display name
      $('.ui.modal.setDisplayName')
        .modal({
          onApprove: this.setDisplayNameSubmit,
          closable: false
        })
        .modal('show')
    },
    showSidebar: function () {
      $('.ui.sidebar')
        .sidebar({
          context: '#app',
          dimPage: false,
          onHide: () => {
            // Revert history if not already done - this covers the case when the user closes the modal without using the back button
            if (window.history.state.sidebar) {
              window.history.back()
            }
          },
          onShow: () => {
            // Push to history so that back button closes the sidebar
            window.history.pushState({ sidebar: true }, '', window.location.href)
            // Hide sidebar on history entry change
            window.onpopstate = () => {
              $('.ui.sidebar').sidebar('hide')
            }
          }
        })
        .sidebar('setting', 'transition', 'overlay')
        .sidebar('setting', 'mobileTransition', 'overlay')
        .sidebar('show')
    },
    sidebarGo: function (to) {
      // Note: route to new page before hiding the sidebar so that window.history.state.sidebar isn't true when the sidebar is hidden
      // Route to selected page
      this.$router.push(to)
      // Close sidebar
      $('.ui.sidebar').sidebar('hide')
    },
    signOutClick: function () {
      const self = this
      firebase.auth().signOut()
        .then(function () {
          if (self.$route.path !== '/') {
            self.$router.push('/')
          }
        })
        .catch(error => { logError('App_signOutClick', error) })
    },
    updateUser () {
      // Note: this code was adapted from updateUser in Account.vue
      let updateUserObj = {
        displayName: this.displayName,
        timestamp: firebase.firestore.FieldValue.serverTimestamp(),
        userId: this.user.id
      }
      this.saving = true
      // Write updateUser document
      db.collection('updateUser')
        .add(updateUserObj)
        .then(docRef => {
          const docId = docRef.id
          this.detachUpdateUserListener = db.doc('updateUser/' + docId)
            .onSnapshot(doc => {
              const resultDisplayName = doc.data().resultDisplayName
              if (resultDisplayName) {
                this.detachUpdateUserListener()
                if (resultDisplayName === 'display name exists') {
                  this.displayNameUnavailable = true
                  this.saving = false
                }
                if (resultDisplayName === 'ok') {
                  this.saving = false
                  // Close modal
                  $('.ui.modal.setDisplayName').modal('hide')
                }
              }
            }, error => { logError('App_updateUser_1', error) }
            )
        })
        .catch(error => { logError('App_updateUser_2', error) })
    },
    windowKeyDown: function (event) {
      // Set Display Name modal
      if ($('.ui.modal.setDisplayName').modal('is active')) {
        // Handle Enter key
        if (event.keyCode === 13) {
          this.setDisplayNameEnter()
        }
      }
    },
    writeHistory: function (history) {
      // Use set with merge to create document if it doesn't exist
      db.doc('users/' + this.user.id + '/knownData/doc')
        .set({
          history: history
        }, { merge: true })
        .catch(error => { logError('App_writeHistory', error) })
    },
    writeInitialHistory: function () {
      this.history = []
      // Write a history record with zero values for yesterday
      let dateString, yesterday
      yesterday = new Date()
      yesterday.setDate(yesterday.getDate() - 1)
      dateString = yesterday.getFullYear().toString() + '-'
      if (yesterday.getMonth() + 1 < 10) dateString += '0'
      dateString += (yesterday.getMonth() + 1).toString() + '-'
      if (yesterday.getDate() < 10) dateString += '0'
      dateString += yesterday.getDate().toString()
      this.writeHistory([dateString, 0, 0, 0])
    }
  }
}
</script>

<style>
  body {
    background-color: #f9f9f9;
  }
  .innerWrapper {
    /* Giving the innerWrapper a small fixed height prevents an outer scrollbar */
    height: 100px;
    margin: 1rem 1.5rem;
  }
  .innerWrapper.mobile {
    margin: 1rem 0 0 0;
  }
  .message.standardColor {
    color: rgba(0, 0, 0, 0.87) !important;
  }
  .message.standardColor .header {
    color: rgba(0, 0, 0, 0.87) !important;
  }
  .yellow.message.standardColor {
    box-shadow: 0 0 0 1px #999 inset, 0 0 0 0 transparent !important;
  }
 </style>

<style scoped>
  #app {
    font-family: 'Avenir', Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #2c3e50;
  }
  #app, .pusher {
    height: 100%;
  }
  /* Wrapper around content */
  .wrapper {
    padding-top: 2.85rem;
  }
  /* Override Semantic UI style to prevent content becoming unscrollable after sidebar has been opened */
  .pushable > .pusher {
    overflow: auto;
  }
  /* Override Semantic UI style for iOS */
  html.ios, html.ios body {
    height: 100% !important;
  }
  /* Remove Semantic UI's space on right after icons */
  .ui.menu .item>i.icon {
    margin-right: 0;
  }
  /* Reduce padding on icon dropdowns */
  .ui.dropdown.item.narrow {
    padding-left: 0.8rem;
    padding-right: 0.8rem;
  }
  .noRightMargin {
    margin-right: 0 !important;
  }
  /* Override menu header style */
  .ui.dropdown .menu>.header {
    color: #8c8c8c;
    font-size: 110%;
    text-transform: none;
  }
  /* Position user menu to keep contents in the view area */
  .userMenu {
    left: auto !important;
    right: -2.7rem !important;
  }
  /* Avoid leaving menu items active after click */
  .ui.menu .ui.dropdown .menu>.active.item.notActive {
    background-color: transparent !important;
    font-weight: normal !important;
  }
  /* Still use hover style */
  .ui.menu .ui.dropdown .menu>.active.item.notActive:hover {
    background-color: rgba(0,0,0,.05) !important;
  }
  /* Active route style */
  .router-link-active {
    background-color: rgba(255,255,255,.15) !important;
  }
  /* Header bar */
  .ui.inverted.menu {
    background-color: #222222;
  }
  /* Add space before loader */
  .loader {
    margin: 1rem !important;
  }
  .desktopMenuRightMargin {
    margin-right: 1rem;
  }
  .moreOptionsItem {
    display: inline-block;
    width: 10rem;
  }
  .item.main {
    min-width: 7rem;
    text-align: center;
  }
  /* display:block to allow text to be centered */
  .ui.menu:not(.vertical) .item.main {
    display: block;
  }
  .navSignIn, .navRegister {
    border-radius: 0.3rem !important;
    font-size: 110% !important;
    font-weight: bold !important;
    margin-bottom: 8px !important;
    margin-right: 1rem;
    margin-top: 8px !important;
    padding-bottom: 0.8rem !important;
    padding-top: 0.8rem !important;
  }
  .ui.menu.fixed .item:first-child.navSignIn, .ui.menu.fixed .item:last-child.navSignIn {
    border-radius: 0.3rem !important;
  }
  .navRegister {
    background-color: #2185d0 !important;
  }
  .navRegister:hover {
    background-color: #1678c2 !important;
  }
  .loader {
    margin: 1.8rem !important;
  }
  .memq {
    border-radius: 0.3rem !important;
    padding-bottom: 0 !important;
    padding-top: 0 !important;
  }
  .memq img {
    width: 3.5rem !important;
  }
  .minHeightForUnregistered {
    min-height: 53px;
  }
  .setDisplayNameContent {
    min-height: 13rem;
  }
  .modalLabel {
    font-weight: bold;
    padding-bottom: 0 !important;
    padding-top: 1.65rem !important;
  }
  .ui.grid:not(.mobile) .modalLabel::after {
    content: ":";
  }
  .ui.grid {
    margin-bottom: 0.5rem;
  }
</style>
