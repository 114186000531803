<template>
  <div>
    <breadcrumbs :breadcrumbs="breadcrumbs" :connected="connected" :text="text"></breadcrumbs>
    <div class="innerWrapper">
      <h3>Account Details</h3>
      <form class="ui form" @submit.prevent="updateUser()">
        <div class="field">
          <label>{{ text.commonEmailAddress }}</label>
          <input type="email" name="email" v-model="email" :placeholder="text.commonEmailAddressPlaceholder" @keydown.enter.prevent @input="emailUnavailable = false; noAccess = false">
        </div>
        <div class="field">
          <label>{{ text.registerDisplayName }}</label>
          <input type="text" name="displayName" v-model="displayName" :placeholder="text.registerDisplayNamePlaceholder" @keydown.enter.prevent @input="displayNameUnavailable = false">
        </div>
        <div class="ui red message" v-show="emailUnavailable">
          <p>{{ text.registerEmailNotAvailable }}</p>
        </div>
        <div class="ui red message" v-show="displayNameUnavailable">
          <p>{{ text.registerDisplayNameNotAvailable }}</p>
        </div>
        <div class="ui small active inline loader" v-show="saving"></div>
        <button type="submit" class="ui primary button" v-show="!saving" :class="{disabled: !email || !emailValid || !displayName || displayNameUnavailable || emailUnavailable || (email === currentEmail && displayName === currentDisplayName)}">Save</button>
      </form>
      <!--
      <div class="ui divider"></div>
      <div>Your subscription expires on ...</div>
      <br>
      <p>Free accounts are limited to a total of 100 rows across all memo sets, and a maximum of 3 columns per memo set.</p>
      <p>To upgrade your account, click here.</p>
      <p>Account Type: Free</p>
      <ul>
        <li>Maximum 100 rows across all memo sets</li>
        <li>Maximum 3 columns per memo set</li>
      </ul>
      <div class="ui primary button">Upgrade</div>
      -->
    </div>
  </div>
</template>

<script>
import Breadcrumbs from '@/components/Breadcrumbs'

const firebase = window.firebase
const db = firebase.firestore()

function logError (errorCode, error) {
  console.log(errorCode)
  console.log(error)
}

export default {
  name: 'account',
  components: {
    Breadcrumbs
  },
  props: ['connected', 'mobile', 'text', 'touch', 'user', 'window'],
  data () {
    return {
      currentDisplayName: this.user.displayName,
      currentEmail: firebase.auth().currentUser.email,
      detachUpdateUserListener: null,
      displayName: this.user.displayName,
      displayNameUnavailable: false,
      email: firebase.auth().currentUser.email,
      emailUnavailable: false,
      saving: false
    }
  },
  created: function () {
    // TEMP - for debugging
    window.vmAccount = this
    // Scroll to top
    window.scrollTo(0, 0)
  },
  computed: {
    breadcrumbs: function () {
      return [
        {label: this.text.navHome, to: '/'},
        {label: this.text.navUserMyAccount}
      ]
    },
    emailValid: function () {
      // Function from https://stackoverflow.com/a/9204568
      let regex = /\S+@\S+\.\S+/
      return regex.test(this.email)
    }
  },
  methods: {
    updateUser () {
      let updateUserObj = {
        timestamp: firebase.firestore.FieldValue.serverTimestamp(),
        userId: this.user.id
      }
      if (this.email !== this.currentEmail) {
        updateUserObj.email = this.email
      }
      if (this.displayName !== this.currentDisplayName) {
        updateUserObj.displayName = this.displayName
      }
      this.saving = true
      // Write updateUser document
      db.collection('updateUser')
        .add(updateUserObj)
        .then(docRef => {
          const docId = docRef.id
          let resultEmailPending = !!updateUserObj.email
          let resultDisplayNamePending = !!updateUserObj.displayName
          this.detachUpdateUserListener = db.doc('updateUser/' + docId)
            .onSnapshot(doc => {
              const resultEmail = doc.data().resultEmail
              const resultDisplayName = doc.data().resultDisplayName
              // Detach listener if we have all the results
              if ((resultEmail || !resultEmailPending) && (resultDisplayName || !resultDisplayNamePending)) {
                this.detachUpdateUserListener()
              }
              if (resultEmailPending && resultEmail) {
                resultEmailPending = false
                if (resultEmail === 'email exists') {
                  this.emailUnavailable = true
                  if (!resultDisplayNamePending) {
                    this.saving = false
                  }
                }
                if (resultEmail === 'ok') {
                  const token = doc.data().token
                  if (token) {
                    firebase.auth()
                      .signInWithCustomToken(token)
                      .then(() => {
                        this.currentEmail = this.email
                        if (!resultDisplayNamePending) {
                          this.saving = false
                        }
                      })
                      .catch(error => {
                        logError('Account_updateUser_signIn', error)
                        const message = this.text.accountSignInError.replace('<email>', this.email)
                        alert(message)
                        this.$router.push('/sign-in')
                        window.location.reload()
                      })
                  }
                }
              }
              if (resultDisplayNamePending && resultDisplayName) {
                resultDisplayNamePending = false
                if (resultDisplayName === 'display name exists') {
                  this.displayNameUnavailable = true
                  if (!resultEmailPending) {
                    this.saving = false
                  }
                }
                if (resultDisplayName === 'ok') {
                  this.currentDisplayName = this.displayName
                  if (!resultEmailPending) {
                    this.saving = false
                  }
                }
              }
            }, error => { logError('Account_updateUser_1', error) }
            )
        })
        .catch(error => { logError('Account_updateUser_2', error) })
    }
  }
}
</script>

<style scoped>
  form {
    margin-top: 2rem;
  }
  button, div.loader {
    margin-top: 1rem !important;
  }
</style>
