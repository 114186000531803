<template>
  <div :class="{mobile: mobile}">
    <breadcrumbs :breadcrumbs="breadcrumbs" :connected="connected" :text="text"></breadcrumbs>
    <div class="innerWrapper">
      <h1>Admin</h1>
      <h3>User Info</h3>
      <h4 class="fieldLabel">Get user ID for user name:</h4>
      <div class="ui input">
        <input type="text" v-model="getUserIdUsername" placeholder="User name" @change="getUserIdForUsername()">
      </div>
      <br>
      <h4 class="fieldLabel">Get user ID for email:</h4>
      <div class="ui input">
        <input type="text" v-model="getUserIdEmail" placeholder="Email" @change="getUserIdForEmail()">
      </div>
      <br>
      <h4 class="fieldLabel" v-show="resultUserId || gettingUserIdForUsername || gettingUserIdForEmail">User ID:</h4>
      <span>{{ resultUserId }}</span>
      <div class="ui small active inline loader" v-show="gettingUserIdForUsername || gettingUserIdForEmail"></div>
      <h3>Sign In</h3>
      <h4 class="fieldLabel">User name:</h4>
      <div class="ui input">
        <input type="text" v-model="signInUsername" placeholder="User name" @change="signInAsUsername()">
      </div>
      <br>
      <h4 class="fieldLabel">Email:</h4>
      <div class="ui input">
        <input type="text" v-model="signInEmail" placeholder="Email" @change="signInAsEmail()">
      </div>
      <br>
      <h4 class="fieldLabel">User ID:</h4>
      <div class="ui input">
        <input type="text" v-model="signInUserId" placeholder="User ID" @change="signInAsUserId()">
      </div>
      <br>
      <h4 class="fieldLabel" v-show="signInResult">Result:</h4>
      <span>{{ signInResult }}</span>
      <div class="ui small active inline loader" v-show="signingIn" style="margin: 2rem"></div>
    </div>
    <br>
  </div>
</template>

<script>
import Breadcrumbs from '@/components/Breadcrumbs'

const firebase = window.firebase
const db = firebase.firestore()

function logError (errorCode, error) {
  console.log(errorCode)
  console.log(error)
}

export default {
  name: 'admin',
  components: {
    Breadcrumbs
  },
  data () {
    return {
      gettingUserIdForEmail: false,
      gettingUserIdForUsername: false,
      getUserIdEmail: '',
      getUserIdUsername: '',
      resultUserId: '',
      signInEmail: '',
      signInResult: '',
      signInUsername: '',
      signInUserId: '',
      signingIn: false,
      signingInUserId: ''
    }
  },
  computed: {
    breadcrumbs: function () {
      return [
        {label: this.text.navHome, to: '/'},
        {label: 'Admin'}
      ]
    }
  },
  created: function () {
    // Set variable to skip display name prompt
    window.memq = window.memq || {}
    window.memq.skipDisplayName = true
  },
  methods: {
    getUserIdForUsername: function () {
      let removeGetUserListener
      const username = this.signingIn ? this.signInUsername : this.getUserIdUsername
      if (!username) return
      const self = this
      this.resultUserId = ''
      this.getUserIdEmail = ''
      this.signInResult = ''
      // Write a request to get user details
      db.collection('getUser')
        .add({
          searchFor: username,
          timestamp: firebase.firestore.FieldValue.serverTimestamp(),
          userId: this.user.id
        })
        .then(docRef => {
          // Listen for the results
          removeGetUserListener = docRef.onSnapshot(doc => {
            if (doc.exists && doc.data().done) {
              // Remove the listener
              removeGetUserListener()
              // Delete the getUser document
              docRef.delete()
              self.gettingUserIdForUsername = false
              if (self.signingIn) {
                if (doc.data().returnUserId) {
                  self.signingInUserId = doc.data().returnUserId
                  self.signInAsUserId()
                } else {
                  self.signInResult = 'User not found'
                  self.signingIn = false
                }
              } else {
                self.resultUserId = doc.data().returnUserId || 'User not found'
              }
            }
          })
        })
        .catch(error => { logError('Admin_getUserIdForUsername', error) })
      if (!this.signingIn) {
        // Set flag to indicate that we're waiting
        this.gettingUserIdForUsername = true
      }
    },
    getUserIdForEmail: function () {
      let removeGetUserForEmailListener
      const email = this.signingIn ? this.signInEmail : this.getUserIdEmail
      if (!email) return
      const self = this
      this.resultUserId = ''
      this.getUserIdUsername = ''
      this.signInResult = ''
      // Write a request to get user details
      db.collection('getUserForEmail')
        .add({
          email: email,
          timestamp: firebase.firestore.FieldValue.serverTimestamp(),
          userId: this.user.id
        })
        .then(docRef => {
          // Listen for the results
          removeGetUserForEmailListener = docRef.onSnapshot(doc => {
            if (doc.exists && doc.data().done) {
              // Remove the listener
              removeGetUserForEmailListener()
              // Delete the getUserForEmail document
              docRef.delete()
              self.gettingUserIdForEmail = false
              if (self.signingIn) {
                // Error messages include spaces
                if (doc.data().returnUserId.includes(' ')) {
                  self.signInResult = doc.data().returnUserId
                  self.signingIn = false
                } else {
                  self.signingInUserId = doc.data().returnUserId
                  self.signInAsUserId()
                }
              } else {
                self.resultUserId = doc.data().returnUserId
              }
            }
          })
        })
        .catch(error => { logError('Admin_getUserIdForEmail', error) })
      if (!this.signingIn) {
        // Set flag to indicate that we're waiting
        this.gettingUserIdForEmail = true
      }
    },
    signInAsEmail: function () {
      if (!this.signInEmail) return
      this.signingIn = true
      this.signInResult = ''
      this.signInUsername = ''
      this.signInUserId = ''
      this.getUserIdForEmail()
    },
    signInAsUserId: function () {
      const userId = this.signingIn ? this.signingInUserId : this.signInUserId
      if (!userId) return
      this.signingIn = true
      this.signInResult = ''
      db.collection('adminSignin')
        .add({
          timestamp: firebase.firestore.FieldValue.serverTimestamp(),
          userId: userId
        })
        .then(docRef => {
          let unsubscribe = docRef.onSnapshot(doc => {
            if (doc.exists) {
              let token = doc.data().token
              if (token) {
                // Remove listener
                unsubscribe()
                // Remove adminSignin doc
                docRef.delete()
                  .catch(error => { logError('AdminSignin_signIn_1', error) })
                firebase.auth().signInWithCustomToken(token)
                  .then(() => {
                    // Route to Home page
                    this.$router.push('/')
                  })
                  .catch(error => { logError('AdminSignin_signIn_2', error) })
              }
            }
          })
        })
        .catch(error => { logError('AdminSignin_signIn_3', error) })
    },
    signInAsUsername: function () {
      if (!this.signInUsername) return
      this.signingIn = true
      this.signInResult = ''
      this.signInEmail = ''
      this.signInUserId = ''
      this.getUserIdForUsername()
    }
  },
  props: ['connected', 'mobile', 'text', 'user']
}
</script>

<style scoped>
  .fieldLabel {
    display: inline-block;
    margin-right: 1rem;
    margin-top: 1rem;
  }
  .fieldLabel {
    margin-left: 2rem;
    width: 16rem;
  }
  .mobile .fieldLabel {
    margin-left: 1rem;
    width: 8rem;
  }
  .ui.input {
    width: 18rem;
  }
  .mobile .ui.input {
    width: auto;
  }
</style>
