import Vue from 'vue'
import VueRouter from 'vue-router'
import Admin from '@/components/Admin'
import Account from '@/components/Account'
import ContactUs from '@/components/ContactUs'
import Home from '@/components/Home'
import MemoSet from '@/components/MemoSet'
import MemoSets from '@/components/MemoSets'
import PublicMemoSets from '@/components/PublicMemoSets'
import Recover from '@/components/Recover'
import Register from '@/components/Register'
import SharedMemoSets from '@/components/SharedMemoSets'
import SignIn from '@/components/SignIn'

Vue.use(VueRouter)

const firebase = window.firebase
const db = firebase.firestore()

// Listen to adminUsers
let adminUsers = {}
db.doc('adminUsers/adminUsers')
  .onSnapshot(doc => {
    adminUsers = doc.data()
  })

const requireAuth = (to, from, next) => {
  const firebase = window.firebase
  let user = firebase.auth().currentUser
  if (!user) {
    next({ name: 'Home' })
  } else {
    next()
  }
}

const requireAdmin = (to, from, next) => {
  const firebase = window.firebase
  let user = firebase.auth().currentUser
  if (user && adminUsers[user.uid]) {
    next()
  } else {
    next({ name: 'Home' })
  }
}

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  }, {
    path: '/index.html',
    name: 'HomeIndex',
    component: Home
  }, {
    path: '/account',
    name: 'Account',
    component: Account,
    beforeEnter: requireAuth
  }, {
    path: '/admin',
    name: 'Admin',
    component: Admin,
    beforeEnter: requireAdmin
  }, {
    path: '/contact-us',
    name: 'ContactUs',
    component: ContactUs
  }, {
    path: '/example-memo-sets/:memoSetId/:slug',
    name: 'ExampleMemoSet',
    component: MemoSet
  }, {
    path: '/memo-sets/:memoSetId/:slug',
    name: 'MemoSet',
    component: MemoSet,
    beforeEnter: requireAuth
  }, {
    path: '/memo-sets',
    name: 'MemoSets',
    component: MemoSets,
    beforeEnter: requireAuth
  }, {
    path: '/public-memo-sets/:memoSetId/:slug',
    name: 'PublicMemoSet',
    component: MemoSet,
    beforeEnter: requireAuth
  }, {
    path: '/public-memo-sets',
    name: 'PublicMemoSets',
    component: PublicMemoSets,
    beforeEnter: requireAuth
  }, {
    path: '/recover',
    name: 'Recover',
    component: Recover
  }, {
    path: '/register',
    name: 'Register',
    component: Register
  }, {
    path: '/shared-memo-sets/:memoSetId/:slug',
    name: 'SharedMemoSet',
    component: MemoSet,
    beforeEnter: requireAuth
  }, {
    path: '/shared-memo-sets',
    name: 'SharedMemoSets',
    component: SharedMemoSets,
    beforeEnter: requireAuth
  }, {
    path: '/sign-in',
    name: 'SignIn',
    component: SignIn
  }, {
    path: '/:catchAll(.*)',
    redirect: '/'
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
