<template>
  <div>
    <div class="innerWrapper">
      <div class="registerWrapper">
        <h2>{{ text.register }}</h2>
        <form class="ui form" @submit.prevent="register()">
          <div class="field">
            <label>{{ text.commonEmailAddress }}</label>
            <input type="email" name="email" v-model="email" :placeholder="text.commonEmailAddressPlaceholder" @input="emailUnavailable = false; noAccess = false">
          </div>
          <div class="field">
            <label>{{ text.registerDisplayName }}</label>
            <input type="text" name="displayName" v-model="displayName" :placeholder="text.registerDisplayNamePlaceholder" @input="displayNameUnavailable = false">
          </div>
          <div class="field">
            <label>{{ text.commonPassword }}</label>
            <input type="password" name="password" v-model="password" :placeholder="text.commonPasswordPlaceholder">
          </div>
          <div class="field">
            <div class="ui checkbox">
              <input type="checkbox" tabindex="0" class="hidden" v-model="termsAccepted">
              <label v-html="termsHtml"></label>
            </div>
          </div>
          <div class="ui red message" v-show="displayNameUnavailable">
            <p>{{ text.registerDisplayNameNotAvailable }}</p>
          </div>
          <div class="ui red message" v-show="emailUnavailable">
            <p>{{ text.registerEmailNotAvailable }}</p>
          </div>
          <div class="ui red message" v-show="noAccess">
            <p>{{ text.registerNoAccess }}</p>
          </div>
          <div class="ui small active inline loader" v-show="checking"></div>
          <button type="submit" class="ui primary button" :class="{disabled: !email || !emailValid || !password || !displayName || displayNameUnavailable || emailUnavailable || noAccess || !termsAccepted}" v-show="!checking">{{ text.register }}</button>
        </form>
        <div class="footer">
          <p v-show="!checking">{{ text.registerAlreadyRegistered }} <router-link to="/sign-in">{{ text.signIn }}</router-link></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const $ = window.$
const firebase = window.firebase
const db = firebase.firestore()

function logError (errorCode, error) {
  console.log(errorCode)
  console.log(error)
}

export default {
  name: 'register',
  props: ['mobile', 'text', 'touch', 'user', 'window'],
  data () {
    return {
      checking: false,
      displayName: '',
      displayNameUnavailable: false,
      email: '',
      emailUnavailable: false,
      noAccess: false,
      password: '',
      termsAccepted: false
    }
  },
  created: function () {
    // TEMP - for debugging
    window.vmRegister = this
    // Scroll to top
    window.scrollTo(0, 0)
  },
  mounted: function () {
    // Initialize checkbox
    $('.ui.checkbox').checkbox()
  },
  computed: {
    emailValid: function () {
      // Function from https://stackoverflow.com/a/9204568
      let regex = /\S+@\S+\.\S+/
      return regex.test(this.email)
    },
    termsHtml: function () {
      if (!this.text || !this.text.registerTermsMessage) return ''
      return this.text.registerTermsMessage
        .replace('<Terms of Use>', '<a href="https://artofmemory.com/legal/terms" target="_blank">' + this.text.registerTermsOfUse + '</a>')
        .replace('<Privacy Policy>', '<a href="https://artofmemory.com/legal/privacy" target="_blank">' + this.text.registerPrivacyPolicy + '</a>')
    }
  },
  methods: {
    createAccount () {
      // Write display name and language to newUsers document
      db.collection('newUsers')
        .add({
          displayName: this.displayName,
          email: this.email,
          timestamp: firebase.firestore.FieldValue.serverTimestamp(),
          uiLanguage: this.user.uiLanguage
        })
        .then(
          firebase
            .auth()
            .createUserWithEmailAndPassword(this.email, this.password)
            .then(() => {
              this.checking = false
              this.$router.push('/')
            })
            .catch(error => { logError('Register_createAccount_1', error) })
        )
        .catch(error => { logError('Register_createAccount_2', error) })
    },
    register () {
      let removeCheckRegistrationListener
      // Write a request to check whether the display name is available
      db.collection('checkRegistration')
        .add({
          displayName: this.displayName,
          email: this.email,
          timestamp: firebase.firestore.FieldValue.serverTimestamp()
        })
        .then(docRef => {
          // Listen for the results
          removeCheckRegistrationListener = docRef.onSnapshot(doc => {
            if (doc.exists && doc.data().result) {
              // Remove the listener
              removeCheckRegistrationListener()
              // Delete the checkRegistration document
              docRef.delete()
              // Handle the result
              switch (doc.data().result) {
                case 'display name exists':
                  this.displayNameUnavailable = true
                  this.checking = false
                  break
                case 'email exists':
                  this.emailUnavailable = true
                  this.checking = false
                  break
                case 'ok':
                  this.createAccount()
                  break
                case 'no access':
                  this.noAccess = true
                  this.checking = false
                  break
              }
            }
          })
        })
        .catch(error => { logError('Register_checkRegistration', error) })
      // Set flag to indicate that we're waiting
      this.checking = true
    }
  }
}
</script>

<style scoped>
  form {
    margin-top: 2rem;
  }
  .registerWrapper {
    max-width: 450px;
    margin: 3rem auto 3rem auto;
  }
  .ui.checkbox {
    margin-top: 0.5rem;
  }
  button, div.loader {
    margin-top: 1rem !important;
  }
  .footer {
    margin-top: 3rem;
    padding-bottom: 2rem;
  }
</style>
