<template>
  <div>
    <breadcrumbs :breadcrumbs="breadcrumbs" :connected="connected" :text="text"></breadcrumbs>
    <div class="innerWrapper" :class="{'mobile': mobile}">
      <div v-if="mobile" class="navigation">
        <div class="ui menu" :class="{'three item': showSharedMemoSets, 'two item': !showSharedMemoSets}" style="background-color: #f0f1f2">
          <router-link class="item noBorderRadius" to="memo-sets">{{ text.navMemoSets }}</router-link>
          <router-link class="item noBorderRadius" to="shared-memo-sets" v-show="showSharedMemoSets">{{ text.navSharedMemoSets }}</router-link>
          <router-link class="item noBorderRadius" to="public-memo-sets">{{ text.navPublicMemoSets }}</router-link>
        </div>
      </div>
      <h3 class="ui header summaryHeader">{{ text.homeSummary }}</h3>
      <div class="ui center aligned segment statisticsWrapper" :class="{'mobile': mobile}">
        <div class="ui statistics" :class="{'small': !mobile, 'tiny mobile': mobile}" style="display: block">
          <div class="purple statistic">
            <div class="value">
              <i class="table icon"></i>
              {{ summary.activeMemoSets }}
            </div>
            <div class="label">
              {{ text.homeActiveMemoSets }}
            </div>
          </div>
          <div class="green statistic" v-show="summary.itemsKnown">
            <div class="value">
              <i class="check icon"></i>
              {{ summary.itemsKnown }}
            </div>
            <div class="label">
              {{ text.homeItemsKnown }}
            </div>
          </div>
          <div class="orange statistic" v-show="summary.itemsDue">
            <div class="value">
              <i class="calendar outline icon"></i>
              {{ summary.itemsDue }}
            </div>
            <div class="label">
              {{ text.homeItemsDueForReview }}
            </div>
          </div>
          <div class="grey statistic" v-show="summary.itemsUnknown">
            <div class="value">
              <i class="x icon"></i>
              {{ summary.itemsUnknown }}
            </div>
            <div class="label">
              {{ text.homeItemsUnknown }}
            </div>
          </div>
          <div class="statistic" v-if="chartDays > 1">
            <div style="width: 140px">
              <div style="font-weight: bold; margin-left: 146px; margin-top: -30px; position: absolute" v-show="chartCursor !== -1">
                <table class="ui small unstackable table chartValues">
                  <thead>
                    <tr style="line-height: 2rem">
                      <td colspan="2">{{ chartDateString }}</td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr style="color: #767676">
                      <td>{{ text.homeChartUnknown }}:&nbsp;</td>
                      <td class="right aligned">{{ cursorValues.unknown }}</td>
                    </tr>
                    <tr style="color: #f2711c">
                      <td style="border-top: none">{{ text.homeChartDue }}:&nbsp;</td>
                      <td style="border-top: none" class="right aligned">{{ cursorValues.due }}</td>
                    </tr>
                    <tr style="color: #21ba45">
                      <td style="border-top: none">{{ text.homeChartKnown }}:&nbsp;</td>
                      <td style="border-top: none" class="right aligned">{{ cursorValues.known }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <svg viewBox="0 0 140 40" class="chart" @mousemove="chartMousemove($event)" @mouseleave="chartCursor = -1">
                <polygon
                  fill="#767676"
                  opacity="0.6"
                  :points="unknownPolygon"
                />
                <polygon
                  fill="#f2711c"
                  opacity="0.6"
                  :points="duePolygon"
                />
                <polygon
                  fill="#21ba45"
                  opacity="0.6"
                  :points="knownPolygon"
                />
                <polyline
                  fill="none"
                  stroke="#767676"
                  stroke-width="3"
                  :points="unknownLine"
                />
                <polyline
                  fill="none"
                  stroke="#f2711c"
                  stroke-width="3"
                  :points="dueLine"
                />
                <polyline
                  fill="none"
                  stroke="#21ba45"
                  stroke-width="3"
                  :points="knownLine"
                />
                <line
                  stroke="black"
                  stroke-width="1"
                  :x1="chartMargin"
                  :y1="chartHeight + chartMargin"
                  :x2="chartWidth + chartMargin"
                  :y2="chartHeight + chartMargin"
                />
                <g v-show="chartCursor > -1">
                  <ellipse
                    fill="#767676"
                    :cx="chartCursor / (chartDays - 1) * (chartWidth - chartMargin - chartMargin) + chartMargin + 3"
                    :cy="chartPointY['unknown']"
                    rx="3"
                    ry="3"
                  />
                  <ellipse
                    fill="#f2711c"
                    :cx="chartCursor / (chartDays - 1) * (chartWidth - chartMargin - chartMargin) + chartMargin + 3"
                    :cy="chartPointY['due']"
                    rx="3"
                    ry="3"
                  />
                  <ellipse
                    fill="#21ba45"
                    :cx="chartCursor / (chartDays - 1) * (chartWidth - chartMargin - chartMargin) + chartMargin + 3"
                    :cy="chartPointY['known']"
                    rx="3"
                    ry="3"
                  />
                </g>
              </svg>
            </div>
            <div class="label">
              {{ chartLabel }}
            </div>
          </div>
        </div>
      </div>
      <!-- Up to Date -->
      <div class="ui center aligned segment congratulations" v-show="summary.activeMemoSets && !reviewMemoSets.length">
        <div><i class="large green checkmark box icon"></i></div>
        <div>{{ text.homeUpToDate }}</div>
      </div>
      <!-- No memo sets -->
      <div class="ui yellow message standardColor noMemoSets" v-show="memoSets && !memoSets.length">
        <span v-html="text.homeNoMemoSets1"></span>
        <router-link to="memo-sets">{{ text.navMemoSets }}</router-link>
        <span v-html="text.homeNoMemoSets2"></span>
        <router-link to="public-memo-sets">{{ text.navPublicMemoSets }}</router-link>
        <span v-html="text.homeNoMemoSets3"></span>
      </div>
      <!-- No active memo sets -->
      <div class="ui yellow message standardColor noActiveMemoSets" v-show="memoSets && memoSets.length && !summary.activeMemoSets">
        <p v-html="text.homeNoActiveMemoSets1"></p>
        <p v-html="text.homeNoActiveMemoSets2"></p>
      </div>
      <!-- Ready to Learn -->
      <div v-show="reviewMemoSets.length">
        <button id="readyToLearnInfoButton" class="ui right floated circular icon button">
          <i class="info icon"></i>
        </button>
        <div class="ui wide popup top transition hidden">
          <p v-html="text.homeReadyToLearnInfo1"></p>
          <p v-html="text.homeReadyToLearnInfo2"></p>
        </div>
        <h3 class="ui header reviewMemoSetsHeader">{{ text.homeReadyToLearn }}</h3>
        <div class="tableWrapper" :class="{'mobile': mobile}">
          <table
            class="ui selectable unstackable table"
            :class="{'compact': mobile}"
          >
            <thead>
              <tr>
                <th class="two wide">{{ text.memoSetsColumnImage }}</th>
                <th class="six wide">{{ text.memoSetsColumnMemoSet }}</th>
                <th class="two wide">{{ text.homeDue }}</th>
                <th class="four wide">{{ text.commonStatus }}</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="memoSet in reviewMemoSets"
                :key="memoSet.memoSetId"
              >
                <td class="imgCell">
                  <router-link :to="'memo-sets/' + memoSet.memoSetId + '/' + memoSet.slug">
                    <div class="coverImageWrapper">
                      <img class="ui centered rounded image" :src="memoSet.coverImage">
                    </div>
                  </router-link>
                </td>
                <td>
                  <router-link :to="'memo-sets/' + memoSet.memoSetId + '/' + memoSet.slug">
                    {{ memoSet.title }}
                  </router-link>
                </td>
                <td class="locationNum">
                  <router-link :to="'memo-sets/' + memoSet.memoSetId + '/' + memoSet.slug">
                    <span :class="{'indent1': memoSet.statuses.due < 1000, 'indent2': memoSet.statuses.due < 100, 'indent3': memoSet.statuses.due < 10}">{{ memoSet.statuses.due }}</span>
                  </router-link>
                </td>
                <td>
                  <router-link :to="'memo-sets/' + memoSet.memoSetId + '/' + memoSet.slug">
                    <status-bar
                      :mobile="mobile"
                      page="Home"
                      :statuses="memoSet.statuses"
                      :window="window"
                      v-show="memoSet.statuses"
                    ></status-bar>
                  </router-link>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <br>
    </div>
  </div>
</template>

<script>
import Breadcrumbs from '@/components/Breadcrumbs'
import StatusBar from '@/components/StatusBar'
const $ = window.$
export default {
  name: 'home',
  components: {
    Breadcrumbs,
    StatusBar
  },
  data () {
    return {
      chartCursor: -1,
      chartHeight: 37,
      chartMargin: 3,
      chartWidth: 134,
      now: Date.now()
    }
  },
  computed: {
    breadcrumbs: function () {
      return [
        {label: this.text.navHome}
      ]
    },
    chartDateString: function () {
      const daysAgo = this.historicData.length - 1 - this.chartCursor
      let date = new Date()
      date.setDate(date.getDate() - daysAgo)
      return date.toLocaleDateString()
    },
    chartDays: function () {
      return this.historicData.length
    },
    chartLabel: function () {
      if (!this.text || !this.text.homeChartLabel) return ''
      return this.text.homeChartLabel.replace('<days>', this.chartDays)
    },
    chartPointY: function () {
      if (this.chartCursor === -1) return {}
      const item = this.historicData[this.chartCursor]
      const unknownY = this.chartHeight - ((item.unknown + item.due + item.known) / this.maxTotal * this.chartHeight) + this.chartMargin
      const dueY = this.chartHeight - ((item.due + item.known) / this.maxTotal * this.chartHeight) + this.chartMargin
      const knownY = this.chartHeight - (item.known / this.maxTotal * this.chartHeight) + this.chartMargin
      return {
        unknown: unknownY,
        due: dueY,
        known: knownY
      }
    },
    cursorValues: function () {
      if (this.chartCursor === -1) return {}
      return this.historicData[this.chartCursor]
    },
    dueLine: function () {
      const points = this.historicData.map((item, index) => {
        const chartX = index / (this.historicData.length - 1) * this.chartWidth + this.chartMargin
        const chartY = this.chartHeight - ((item.due + item.known) / this.maxTotal * this.chartHeight) + this.chartMargin
        return chartX + ',' + chartY
      })
      const dueLine = points.join(' ')
      return dueLine
    },
    duePolygon: function () {
      const reverseKnownLine = this.knownLine.split(' ').reverse().join(' ')
      return this.dueLine + ' ' + reverseKnownLine
    },
    historicData: function () {
      // Copy history array
      let historicData = this.history ? this.history.slice() : []
      // Append current data
      historicData.push({
        known: this.summary.itemsKnown,
        due: this.summary.itemsDue,
        unknown: this.summary.itemsUnknown
      })
      return historicData
    },
    knownLine: function () {
      const points = this.historicData.map((item, index) => {
        const chartX = index / (this.historicData.length - 1) * this.chartWidth + this.chartMargin
        const chartY = this.chartHeight - (item.known / this.maxTotal * this.chartHeight) + this.chartMargin
        return chartX + ',' + chartY
      })
      const knownLine = points.join(' ')
      return knownLine
    },
    knownPolygon: function () {
      const bottomRight = this.chartWidth + ',' + this.chartHeight + this.chartMargin
      const bottomLeft = this.chartMargin + ',' + this.chartHeight + this.chartMargin
      return this.knownLine + ' ' + bottomRight + ' ' + bottomLeft
    },
    maxTotal: function () {
      let maxTotal = 0
      this.historicData.forEach(item => {
        const itemTotal = item.known + item.due + item.unknown
        if (itemTotal > maxTotal) {
          maxTotal = itemTotal
        }
      })
      return maxTotal
    },
    reviewMemoSets: function () {
      if (!this.memoSets) return []
      if (!this.statuses) return []
      let reviewMemoSets = this.memoSets.filter(memoSet => !memoSet.hide).map(memoSet => {
        let statuses = Object.assign({}, this.statuses[memoSet.memoSetId] || {})
        statuses.speed = 'slow'
        return {
          coverImage: memoSet.coverImage,
          docId: memoSet.docId,
          memoSetId: memoSet.memoSetId,
          slug: memoSet.slug,
          statuses: statuses,
          title: memoSet.title
        }
      }).filter(memoSet => memoSet.statuses.due > 0 || memoSet.statuses.unknown > 0)
      // Sort memo sets with due items in ascending order of due items, then memo sets with unknown items in ascending order of unknown items
      reviewMemoSets.sort((a, b) => {
        if (a.statuses.due && b.statuses.due) {
          if (a.statuses.due === b.statuses.due) {
            return a.statuses.unknown - b.statuses.unknown
          } else {
            return a.statuses.due - b.statuses.due
          }
        }
        if (a.statuses.due && !b.statuses.due) {
          return -1
        }
        if (!a.statuses.due && b.statuses.due) {
          return 1
        }
        // nothing due
        return a.statuses.unknown - b.statuses.unknown
      })
      return reviewMemoSets
    },
    showSharedMemoSets: function () {
      return this.sharedMemoSets && this.sharedMemoSets.filter(memoSet => !memoSet.status).length
    },
    summary: function () {
      let summary = {
        activeMemoSets: 0,
        itemsKnown: 0,
        itemsDue: 0,
        itemsUnknown: 0
      }
      Object.keys(this.statuses).forEach(memoSetId => {
        // If the memo set is not hidden
        const memoSet = this.memoSets.find(memoSet => memoSet.memoSetId === memoSetId)
        if (memoSet && !memoSet.hide) {
          summary.activeMemoSets++
          summary.itemsKnown += this.statuses[memoSetId].known
          summary.itemsDue += this.statuses[memoSetId].due
          summary.itemsUnknown += this.statuses[memoSetId].unknown
        }
      })
      return summary
    },
    unknownLine: function () {
      const points = this.historicData.map((item, index) => {
        const chartX = index / (this.historicData.length - 1) * this.chartWidth + this.chartMargin
        const chartY = this.chartHeight - ((item.unknown + item.due + item.known) / this.maxTotal * this.chartHeight) + this.chartMargin
        return chartX + ',' + chartY
      })
      const unknownLine = points.join(' ')
      return unknownLine
    },
    unknownPolygon: function () {
      const reverseDueLine = this.dueLine.split(' ').reverse().join(' ')
      return this.unknownLine + ' ' + reverseDueLine
    }
  },
  created: function () {
    // TEMP - for debugging
    window.vmHome = this
    // Set memo set source - used by the MemoSet component
    window.memq = window.memq || {}
    window.memq.memoSetSource = 'Home'
    this.setPageTitle()
  },
  mounted: function () {
    this.$nextTick(() => {
      $('#readyToLearnInfoButton').popup({
        on: 'click',
        position: 'bottom right'
      })
    })
  },
  methods: {
    chartMousemove: function (event) {
      const day = Math.round(event.offsetX / (this.chartWidth / (this.chartDays - 1)))
      this.chartCursor = day
    },
    setPageTitle: function () {
      if (this.text && this.text.navHome) {
        document.title = this.text.navHome + ' - MemQ'
      }
    }
  },
  props: ['connected', 'history', 'memoSets', 'mobile', 'sharedMemoSets', 'statuses', 'text', 'touch', 'user', 'window'],
  watch: {
    text: function (newText) {
      this.setPageTitle()
    }
  }
}
</script>

<style scoped>
  .coverImageWrapper {
    max-width: 160px;
  }
  img {
    max-height: 70px !important;
    max-width: 160px !important;
  }
  /* Make table rows a minimum height */
  .imgCell {
    height: 80px;
  }
  .ui.table thead tr th {
    background-color: #395f7f;
    color: rgba(255, 255, 255, 0.87)
  }
  .ui.table tbody tr td.selectable, .ui.ui.selectable.table>tbody>tr {
    background-color: #ffffff;
  }
  .ui.table tbody tr td.selectable:hover, .ui.ui.selectable.table>tbody>tr:hover {
    background-color: #e6e9ef;
  }
  .noTopMargin {
    margin-top: 0 !important;
  }
  .noTopPadding {
    padding-top: 0 !important;
  }
  /* Move padding from td to td > a to allow anchor to work in entire cell */
  td {
    padding: 0 !important;
    overflow: hidden;
  }
  td > a {
    color: rgba(0,0,0,.87);
  }
  /* Make <a> within <td> fill the entire cell, as per https://stackoverflow.com/a/15801081 */
  td {
    overflow: hidden;
  }
  td > a {
    display: block;
    margin: -5em;
    padding: 5.4em;
  }
  .statisticsWrapper {
    background-color: #ffffff;
  }
  .statisticsWrapper.mobile {
    border-left: none;
    border-radius: 0;
    border-right: none;
  }
  /* Override Semantic's negative margins */
  .ui.statistics {
    margin-left: 0;
    margin-right: 0;
  }
  /* Style for statistics labels */
  .ui.statistics .statistic>.label {
    padding-top: 5px;
    text-transform: none;
  }
  .ui.statistics:not(.mobile) .statistic>.label {
    font-size: 115%;
  }
  /* Move navigation section up */
  .navigation {
    margin-top: -1rem;
  }
  .navigation a {
    background-color: #f1f2f3 !important;
    font-weight: 700 !important;
  }
  .navigation a:hover {
    background-color: #d1d2d4 !important;
  }
  /* Make border between navigation buttons darker */
  .ui.menu .item:before {
    background-color: silver;
  }
  /* Add space after wide icons */
  .table.icon, .calendar.icon {
    margin-right: 0.3rem !important;
  }
  .congratulations {
    background-color: #e5f9e7;
    box-shadow: 0 0 0 1px #1ebc30 inset, 0 0 0 0 transparent;
    color: #1aa62a;
    font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
    font-size: 1.14285714em;
    font-weight: 700;
  }
  .congratulations > div {
    display: inline-block;
  }
  .congratulations .icon {
    color: #1ebc30;
    font-size: 3em !important;
  }
  .summaryHeader {
    margin-top: 1rem;
  }
  /* Reduce size of images on mobile */
  .tableWrapper.mobile img {
    max-height: 60px !important;
    max-width: 100px !important;
  }
  /* Reduce minimum height of table rows on mobile */
  .tableWrapper.mobile .imgCell {
    height: 74px;
  }
  .reviewMemoSetsHeader {
    margin-top: 2rem !important;
  }
  /* Add left margin to headings on mobile */
  .innerWrapper.mobile h3 {
    margin-left: 1rem !important;
  }
  .noBorderRadius {
    border-radius: 0 !important;
  }
  .indent1 {
    margin-left: 0.5em;
  }
  .indent2 {
    margin-left: 1em;
  }
  .indent3 {
    margin-left: 1.5em;
  }
  /* Improve multi-line tab labels */
  .ui.menu .item {
    line-height: 1.2;
    padding: 0 0.8rem !important;
  }
  .chart {
    margin-bottom: -3px;
  }
  .chartValues {
    background-color: white;
    border: 1px solid silver;
    padding: 2px 5px 4px 5px;
  }
  #readyToLearnInfoButton {
     margin-top: -0.7rem;
     margin-right: 0.5rem;
  }
  .noActiveMemoSets, .noMemoSets {
    margin-top: 2rem;
  }
  .xnoMemoSets a {
    color: inherit;
  }
</style>
