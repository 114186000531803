<template>
  <div>
    <div class="innerWrapper" :class="{'mobile': mobile}">
      <!-- Non-mobile -->
      <div class="ui centered grid" v-if="!mobile">
        <div class="row">
          <div class="ten wide column">
            <div class="indent">
              <h1 v-if="text" class="unregisteredHeader">{{ text.unregisteredHeader }}</h1>
              <p class="unregisteredText">{{ text.unregisteredLine1 }}</p>
              <p class="unregisteredText">{{ text.unregisteredLine2 }}</p>
            </div>
          </div>
          <div class="six wide column">
            <img class="ui image unregisteredHeaderImage" src="/dodec.png">
          </div>
        </div>
      </div>
      <!-- Mobile -->
      <div v-if="mobile">
        <h1 v-if="text" class="unregisteredHeaderMobile">{{ text.unregisteredHeader }}</h1>
        <p class="unregisteredTextMobile">{{ text.unregisteredLine1 }}</p>
        <p class="unregisteredTextMobile">{{ text.unregisteredLine2 }}</p>
      </div>
      <div class="ui yellow message" style="color: rgba(0,0,0,.87); margin-top: 1.5rem">
        <div class="content">
          <p v-html="text.unregisteredBetaRelease"></p>
        </div>
      </div>
      <h2 class="indent examplesHeader">{{ text.unregisteredExampleMemoSets }}</h2>
      <div class="ui small active inline loader" v-show="!exampleMemoSets.length"></div>
      <div v-if="exampleMemoSets.length" class="examples">
        <div class="ui link divided items" v-show="exampleMemoSets.length">
          <router-link class="item" v-for="memoSet in exampleMemoSets" :key="memoSet.memoSetId" :to="'example-memo-sets/' + memoSet.memoSetId + '/' + memoSet.slug">
            <div class="ui small image">
              <img :src="memoSet.coverImage" style="margin-left: 0.5rem">
            </div>
            <div class="content" style="margin-left: 0.5rem">
              <div class="ui header">
                {{ memoSet.title }}
              </div>
              <div class="description" v-html="sanitize(memoSet.description)" v-show="memoSet.description"></div>
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const firebase = window.firebase
const db = firebase.firestore()

function logError (errorCode, error) {
  console.log(errorCode)
  console.log(error)
}

export default {
  name: 'home',
  components: {
  },
  data () {
    return {
      exampleMemoSets: []
    }
  },
  computed: {
  },
  beforeDestroy: function () {
    if (this.detachExamplesListener) {
      this.detachExamplesListener()
      this.detachExamplesListener = null
    }
  },
  created: function () {
    window.vmHome = this
    const self = this
    // Listen to default memo sets
    this.detachExamplesListener = db.doc('exampleMemoSets/examples')
      .onSnapshot(snapshot => {
        if (snapshot.data()) {
          self.exampleMemoSets = snapshot.data().memoSets
        } else {
          self.exampleMemoSets = []
        }
      }, error => { logError('UnregisteredHome_1', error) }
      )
    document.title = 'MemQ'
  },
  methods: {
    sanitize: function (html) {
      let sanitizedHtml
      if (!html) return ''
      sanitizedHtml = this.$sanitize(
        html,
        {
          allowedAttributes: {
            img: ['class', 'src']
          },
          allowedTags: ['b', 'br', 'div', 'i', 'img', 'strong'],
          allowedSchemesByTag: {
            img: ['data', 'http', 'https']
          }
        }
      )
      return sanitizedHtml
    }
  },
  props: ['mobile', 'text', 'touch', 'window']
}
</script>

<style scoped>
  .innerWrapper:not(.mobile) h1 {
    font-size: 250%;
  }
  .innerWrapper.mobile h1 {
    font-size: 200%;
  }
  .innerWrapper:not(.mobile) {
    margin-left: 5rem;
    margin-right: 5rem;
  }
  .innerWrapper.mobile {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }
  .rightLabel {
    display: inline-block;
    margin-top: 0;
    width: 9rem;
  }
  .rightLabel:first-child {
    margin-top: 0;
  }
  .ratingsCount {
    color: gray;
    margin-left: 1rem;
  }
  .largeInactiveStar {
    text-shadow: 0 -1px 0 gainsboro,-1px 0 0 silver,0 1px 0 silver,1px 0 0 silver !important;
  }
  .item:hover {
    background-color: #e6e9ef;
  }
  .item {
    padding-left: 1em !important;
    padding-right: 1em !important;
  }
  .item > .image {
    margin-left: 0 !important;
  }
  /* Adjust left and right margin on mobile */
  .innerWrapper.mobile .ui.items {
    margin-left: -1rem;
    margin-right: -1rem;
  }
  /* Reduce maximum height of images on mobile - matches Semantic's rule to override it */
  @media only screen and (max-width: 767.98px) {
    .ui.items:not(.unstackable)>.item>.image, .ui.items:not(.unstackable)>.item>.image>img {
      max-height: 80px !important;
    }
  }
  /* Give top padding to first memo set */
  .ui.divided.items>.item:first-child {
    padding-top: 1em !important;
  }
  /* Give bottom padding to last memo set */
    .ui.divided.items>.item:last-child {
    padding-bottom: 1em !important;
  }
  /* Prevent memo set title changing color on hover */
  div.ui.header {
    color: rgba(0,0,0,.87) !important;
  }
  .noBottomMargin {
    margin-bottom: 0 !important;
  }
  .description {
    margin-top: 1rem;
  }
  .ui.link.divided.items {
    background-color: white;
    border: 1px solid rgba(34,36,38,.15);
    border-radius: 0.28571429rem;
  }
  .innerWrapper:not(.mobile) .indent {
    margin-left: 1rem;
  }
  .examplesHeader {
    margin-top: 2rem;
  }
  .examples {
    margin-top: 2rem;
    padding-bottom: 0.5rem;
  }
  .unregisteredHeader {
    margin-bottom: 2rem;
    margin-top: 5rem;
  }
  .unregisteredHeaderMobile {
    margin-bottom: 2rem;
    margin-top: 3rem;
  }
  .unregisteredText {
    font-size: 120%;
  }
  .unregisteredTextMobile {
    font-size: 120%;
  }
  .unregisteredHeaderImage {
    margin-top: 3rem;
    width: 180px;
  }
  .loader {
    margin-top: 1rem !important;
    margin-left: 1rem !important;
  }
  a.item {
    min-height: 8rem !important;
  }
</style>
