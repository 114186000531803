// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import App from './App'
import router from './router'
import VueSanitize from 'vue-sanitize'

Vue.use(VueSanitize)

Vue.config.productionTip = false

const firebase = window.firebase

let app

// Wait for auth status to be available before creating the app, so that auth guards work properly
firebase.auth().onAuthStateChanged(() => {
  if (!app) {
    app = new Vue({
      router,
      render: h => h(App)
    }).$mount('#app')
  }
})
