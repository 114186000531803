<template>
  <div>
    <div class="innerWrapper">
      <div class="contactUsWrapper">
        <h2>{{ text.contactUs }}</h2>
        <p>{{ text.contactInfo }}</p>
        <form class="ui form" @submit.prevent="send()">
          <div class="field" v-show="!user.displayName">
            <label>{{ text.contactYourName }}</label>
            <input type="text" v-model="yourName" :placeholder="text.contactYourNamePlaceholder">
          </div>
          <div class="field" v-show="!user.displayName">
            <label>{{ text.commonEmailAddress }}</label>
            <input type="email" name="email" v-model="email" :placeholder="text.commonEmailAddressPlaceholder" @input="sent = false; errorVisible = false">
          </div>
          <div class="field">
            <label>{{ text.contactSubject }}</label>
            <input type="text" v-model="subject" :placeholder="text.contactSubjectPlaceholder" :class="{disabled: sending}" autocomplete="off" @input="sent = false; errorVisible = false">
          </div>
          <div class="field">
            <label>{{ text.contactMessage }}</label>
            <textarea v-model="message" :placeholder="text.contactMessagePlaceholder" :class="{disabled: sending}" @input="sent = false; errorVisible = false"></textarea>
          </div>
          <div class="ui red message" v-show="errorVisible">
            <p>{{ text.contactError }}</p>
          </div>
          <div class="ui green standardColor message" v-show="sent">
            <p><i class="large green check icon"></i>{{ text.contactSent }}</p>
          </div>
          <div class="ui small active inline loader" v-show="sending"></div>
          <button type="submit" class="ui primary button" :class="{disabled: !subject || !message || (!user.id && (!yourName || (!email || !emailValid)))}" v-show="!sending && !sent && !errorVisible">{{ text.contactSend }}</button>
        </form>
        <br>
      </div>
    </div>
  </div>
</template>

<script>
const firebase = window.firebase
const db = firebase.firestore()

function logError (errorCode, error) {
  console.log(errorCode)
  console.log(error)
}

export default {
  name: 'signIn',
  props: ['mobile', 'text', 'touch', 'user', 'window'],
  data () {
    return {
      email: '',
      errorVisible: false,
      message: '',
      sending: false,
      sent: false,
      subject: '',
      yourName: ''
    }
  },
  created: function () {
    // Scroll to top
    window.scrollTo(0, 0)
  },
  computed: {
    emailValid: function () {
      // Function from https://stackoverflow.com/a/9204568
      let regex = /\S+@\S+\.\S+/
      return regex.test(this.email)
    }
  },
  methods: {
    send () {
      this.sending = true
      const email = this.email || firebase.auth().currentUser.email
      const name = this.yourName || this.user.displayName
      const userId = this.user.id || ''
      // Write message to Firestore
      db.collection('contactUs')
        .add({
          email: email,
          message: this.message,
          name: name,
          subject: this.subject,
          timestamp: firebase.firestore.FieldValue.serverTimestamp(),
          userId: userId
        })
        .then(() => {
          this.sending = false
          this.sent = true
        })
        .catch(error => {
          this.sending = false
          this.errorVisible = true
          logError('ContactUs_send', error)
        })
    }
  }
}
</script>

<style scoped>
  form {
    margin-top: 2rem;
  }
  h2 {
    margin-bottom: 2rem;
  }
  .contactUsWrapper {
    max-width: 450px;
    margin: 3rem auto 3rem auto;
  }
  button, div.loader {
    margin-top: 1rem !important;
  }
  .footer {
    margin-top: 3rem;
    padding: 2rem;
  }
</style>
