<template>
  <div>
    <breadcrumbs :breadcrumbs="breadcrumbs" :connected="connected" :text="text"></breadcrumbs>
    <div class="innerWrapper" :class="{'mobile': mobile}">
      <div class="ui fluid icon input container" style="margin-bottom: 1rem">
        <input id="searchText" type="text" class="searchInput" v-model="searchText" :placeholder="text.commonSearchPlaceholder" autocomplete="off" autofocus="true" @input="searchInput()" @keydown.enter="searchEnter()">
        <i class="search icon"></i>
      </div>
      <div v-show="!searching || searchResults.length">
        <div class="ui orange message standardColor noResults" v-show="searchText && !searchResults.length">{{ text.publicNoMemoSets }}</div>
        <div v-if="!mobile" class="tableWrapper desktop">
          <table class="ui selectable unstackable table">
            <tbody>
              <tr
                v-for="memoSet in searchResults"
               :key="memoSet.memoSetId"
               class="top aligned"
              >
                <td class="publicTableLeft">
                  <router-link :to="'public-memo-sets/' + memoSet.memoSetId + '/' + memoSet.slug">
                    <div class="coverImageWrapper">
                      <img class="ui centered rounded image" :src="memoSet.coverImage">
                    </div>
                  </router-link>
                </td>
                <td class="publicTableCenter">
                  <router-link :to="'public-memo-sets/' + memoSet.memoSetId + '/' + memoSet.slug" class="standardColor">
                    <div class="ui header">{{ memoSet.title }}</div>
                    <div class="description" v-html="sanitize(memoSet.description)" v-show="memoSet.description"></div>
                  </router-link>
                </td>
                <td class="publicTableRight">
                  <router-link :to="'public-memo-sets/' + memoSet.memoSetId + '/' + memoSet.slug" class="standardColor">
                    <div>
                      <h4 class="rightLabel top aligned"><b>{{ text.publicAuthor }}:</b></h4>
                      <div class="authorName">{{ memoSet.ownerName }}</div>
                      <br>
                      <h4 class="rightLabel"><b>{{ text.commonRows }}:</b></h4>
                      <span>{{ memoSet.rowCount }}</span>
                      <br>
                      <h4 class="rightLabel"><b>{{ text.publicRating }}:</b></h4>
                      <div class="ui yellow star rating" style="position: relative; width: 7rem">
                        <div style="margin-top: -1rem; position: absolute">
                          <i class="star icon largeInactiveStar"></i>
                          <i class="star icon largeInactiveStar"></i>
                          <i class="star icon largeInactiveStar"></i>
                          <i class="star icon largeInactiveStar"></i>
                          <i class="star icon largeInactiveStar"></i>
                        </div>
                        <div style="margin-top: -1rem; position: absolute; overflow-x: hidden" :style="{width: memoSet.ratingWidth + 'rem'}">
                          <i class="star active icon"></i>
                          <i class="star active icon"></i>
                          <i class="star active icon"></i>
                          <i class="star active icon"></i>
                          <i class="star active icon"></i>
                        </div>
                      </div>
                      <span class="ratingsCount">({{ memoSet.ratingsCount }})</span>
                    </div>
                  </router-link>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div v-if="mobile" class="ui link divided items mobile">
          <router-link class="item" v-for="memoSet in searchResults" :key="memoSet.memoSetId" :to="'public-memo-sets/' + memoSet.memoSetId + '/' + memoSet.slug">
            <div class="content">
              <div class="ui rounded image" style="float: right">
                <img :src="memoSet.coverImage">
              </div>
              <div class="ui header noBottomMargin standardColor mobileTitle">{{ memoSet.title }}</div>
              <div class="description" v-html="sanitize(memoSet.description)" v-show="memoSet.description"></div>
              <div>
                <h4 class="rightLabel"><b>{{ text.publicAuthor }}:</b></h4>
                {{ memoSet.ownerName }}
                <br>
                <h4 class="rightLabel"><b>{{ text.commonRows }}:</b></h4>
                {{ memoSet.rowCount }}
                <br>
                <h4 class="rightLabel"><b>{{ text.publicRating }}:</b></h4>
                <div class="ui yellow star rating" style="position: relative; width: 7rem">
                  <div style="margin-top: -1rem; position: absolute">
                    <i class="star icon largeInactiveStar"></i>
                    <i class="star icon largeInactiveStar"></i>
                    <i class="star icon largeInactiveStar"></i>
                    <i class="star icon largeInactiveStar"></i>
                    <i class="star icon largeInactiveStar"></i>
                  </div>
                  <div style="margin-top: -1rem; position: absolute; overflow-x: hidden" :style="{width: memoSet.ratingWidth + 'rem'}">
                    <i class="star active icon"></i>
                    <i class="star active icon"></i>
                    <i class="star active icon"></i>
                    <i class="star active icon"></i>
                    <i class="star active icon"></i>
                  </div>
                </div>
                <span class="ratingsCount">({{ memoSet.ratingsCount }})</span>
              </div>
            </div>
          </router-link>
        </div>
      </div>
      <div class="ui small active inline loader" v-show="searching"></div>
      <br><br>
    </div>
  </div>
</template>

<script>
import Breadcrumbs from '@/components/Breadcrumbs'
const firebase = window.firebase
const db = firebase.firestore()

function logError (errorCode, error) {
  console.log(errorCode)
  console.log(error)
}

export default {
  name: 'public-memo-sets',
  components: {
    Breadcrumbs
  },
  data () {
    return {
      cachedMemoSets: {},
      lastSearchText: '',
      ratingsSummary: {},
      searching: false,
      searchResults: [],
      searchText: '',
      searchTimeout: null,
      topMemoSets: [],
      unsubscribeTop: null,
      userReady: false
    }
  },
  computed: {
    breadcrumbs: function () {
      return [
        {label: this.text.navHome, to: '/'},
        {label: this.text.navPublicMemoSets}
      ]
    },
    localSearchResults: function () {
      let results
      results = []
      Object.keys(this.cachedMemoSets).forEach(memoSetId => {
        const memoSet = this.cachedMemoSets[memoSetId]
        if (this.memoSetMatchesSearch(memoSet)) {
          results.push({
            coverImage: memoSet.coverImage,
            description: memoSet.description,
            memoSetId: memoSet.memoSetId,
            ownerId: memoSet.ownerId,
            ownerName: memoSet.ownerName,
            ratingScore: memoSet.ratingScore,
            ratingWidth: memoSet.ratingWidth,
            ratingsArray: memoSet.ratingsArray,
            ratingsCount: memoSet.ratingsCount,
            rowCount: memoSet.rowCount,
            slug: memoSet.slug,
            title: memoSet.title
          })
        }
      })
      // Sort by rating score descending, then title
      results.sort((a, b) => {
        if (a.ratingScore < b.ratingScore) return 1
        if (a.ratingScore > b.ratingScore) return -1
        if (a.title < b.title) return -1
        return 1
      })
      // Limit results to 40 memo sets
      if (results.length > 40) results.length = 40
      return results
    },
    searchWords: function () {
      let searchWords
      // Split search text into words
      searchWords = this.searchText.toLowerCase().split(' ')
      // Remove empty words
      searchWords = searchWords.filter(searchWord => searchWord)
      // Remove duplicate words
      searchWords = [...new Set(searchWords)]
      // Sort search words by length descending, then alphabetically
      searchWords.sort((a, b) => {
        if (a.length < b.length) return 1
        if (a.length > b.length) return -1
        if (a < b) return -1
        return 1
      })
      return searchWords
    }
  },
  created: function () {
    const self = this
    // TEMP - for debugging
    window.vm = this
    window.memq = window.memq || {}
    // Set memo set source - used by the MemoSet component
    window.memq.memoSetSource = 'PublicMemoSets'
    this.checkUserReady()
    // Set previous search text and results if available
    if (window.memq.memoSetSearchText) {
      this.searchText = window.memq.memoSetSearchText
      this.searchResults = window.memq.memoSetSearchResults
      this.cacheSearchResults()
    } else {
      this.searching = true
    }
    // Listen to default memo sets
    this.unsubscribeTop = db.doc('topMemoSets/top')
      .onSnapshot(snapshot => {
        self.topMemoSets = snapshot.data().memoSets
        if (self.searching && !self.searchText) {
          self.searchResults = self.topMemoSets
          self.cacheSearchResults()
          self.searching = false
        }
      }, error => { logError('PublicMemoSets_1', error) }
      )
    this.setPageTitle()
  },
  mounted: function () {
  },
  methods: {
    cacheSearchResults: function () {
      // Delete any cached search results that match the search text
      Object.keys(this.cachedMemoSets).forEach(memoSetId => {
        const memoSet = this.cachedMemoSets[memoSetId]
        if (this.memoSetMatchesSearch(memoSet)) {
          delete this.cachedMemoSets[memoSetId]
        }
      })
      // Add the new search results to the cache
      this.searchResults.forEach(result => {
        this.cachedMemoSets[result.memoSetId] = {
          coverImage: result.coverImage,
          description: result.description,
          memoSetId: result.memoSetId,
          ownerId: result.ownerId,
          ownerName: result.ownerName,
          ratingScore: result.ratingScore,
          ratingWidth: result.ratingWidth,
          ratingsArray: result.ratingsArray,
          ratingsCount: result.ratingsCount,
          rowCount: result.rowCount,
          slug: result.slug,
          title: result.title
        }
      })
    },
    cancelSearchTimeout: function () {
      if (this.searchTimeout) {
        clearTimeout(this.searchTimeout)
        this.searchTimeout = null
      }
    },
    checkUserReady: function () {
      // Redirect to Home page if the user data has loaded but the user isn't signed in
      if (this.user.uiLanguage && !this.user.folders) {
        this.$router.push('/')
      }
      if (this.user.folders) {
        this.userReady = true
      }
    },
    memoSetMatchesSearch: function (memoSet) {
      let match = true
      for (let i = 0; i < this.searchWords.length; i++) {
        let searchWord = this.searchWords[i]
        if (
          !memoSet.ownerName.toLowerCase().includes(searchWord) &&
          !memoSet.description.toLowerCase().includes(searchWord) &&
          !memoSet.title.toLowerCase().includes(searchWord)
        ) {
          match = false
          break
        }
      }
      return match
    },
    sanitize: function (html) {
      let sanitizedHtml
      if (!html) return ''
      sanitizedHtml = this.$sanitize(
        html,
        {
          allowedAttributes: {
            img: ['class', 'src']
          },
          allowedTags: ['b', 'br', 'div', 'i', 'img', 'strong'],
          allowedSchemesByTag: {
            img: ['data', 'http', 'https']
          }
        }
      )
      return sanitizedHtml
    },
    search: function () {
      const self = this
      this.cancelSearchTimeout()
      if (this.searchText) {
        if (this.searchText.trim() !== this.lastSearchText) {
          this.lastSearchText = this.searchText.trim()
          db.collection('memoSetSearch')
            .add({
              searchText: this.searchText,
              timestamp: firebase.firestore.FieldValue.serverTimestamp(),
              userId: this.user.id
            })
            .then(docRef => {
              // Listen to the memoSetSearch document
              const detachListener = db.doc('memoSetSearch/' + docRef.id)
                .onSnapshot(snapshot => {
                  if (snapshot.data().done) {
                    detachListener()
                    if (!self.searchTimeout) {
                      self.searchResults = snapshot.data().memoSets
                      self.cacheSearchResults()
                      self.searching = false
                      // Save search and search results
                      window.memq.memoSetSearchText = self.searchText
                      window.memq.memoSetSearchResults = self.searchResults
                    }
                    // Delete the memoSetSearch document
                    db.doc('memoSetSearch/' + docRef.id)
                      .delete()
                      .catch(error => { logError('PublicMemoSets_2', error) })
                  }
                }, error => { logError('PublicMemoSets_3', error) })
            })
            .catch(error => { logError('PublicMemoSets_4', error) })
        } else {
          // No real change to search text - show previous results
          this.searching = false
        }
      } else {
        this.searchResults = this.topMemoSets
        self.cacheSearchResults()
        this.searching = false
        this.lastSearchText = ''
        window.memq.memoSetSearchText = ''
      }
    },
    searchEnter: function () {
      // If there's a search timeout, just search now
      this.cancelSearchTimeout()
      this.search()
    },
    searchInput: function () {
      // Update this.searchText if not updated - needed on mobile, where value doesn't change with each keystroke
      if (this.searchText !== document.getElementById('searchText').value) {
        this.searchText = document.getElementById('searchText').value
      }
      this.cancelSearchTimeout()
      this.searchTimeout = setTimeout(this.search, 600)
      this.searching = true
      // Show local search results while waiting for server
      this.searchResults = this.localSearchResults.slice()
    },
    setPageTitle: function () {
      if (this.text && this.text.navPublicMemoSets) {
        document.title = this.text.navPublicMemoSets + ' - MemQ'
      }
    }
  },
  props: ['connected', 'mobile', 'text', 'touch', 'user', 'window'],
  watch: {
    text: function (newText) {
      this.setPageTitle()
    },
    user: function (newUser) {
      this.checkUserReady()
    }
  }
}
</script>

<style scoped>
  /* Add space below heading */
  h2 {
    margin-bottom: 1.5rem;
  }
  /* Move loading indictor down a little */
  .loader {
     margin-top: 0.6rem !important;
  }
  /* Indent loading indicator on mobile */
  .innerWrapper.mobile .loader {
    margin-left: 1rem;
  }
  /* Add margins to no search results message on mobile */
  .innerWrapper.mobile .noResults {
    margin-left: 1rem;
    margin-right: 1rem;
  }
  /* Copy seach box style from Semantic .ui.search .prompt */
  .searchInput {
    border-radius: 500rem !important;
  }
  .rightLabel {
    display: inline-block;
    margin-top: 0;
    width: 9rem;
  }
  .rightLabel:first-child {
    margin-top: 0;
  }
  .ratingsCount {
    color: gray;
    margin-left: 1rem;
  }
  .largeInactiveStar {
    text-shadow: 0 -1px 0 gainsboro,-1px 0 0 silver,0 1px 0 silver,1px 0 0 silver !important;
  }
  .item:hover {
    background-color: #e6e9ef;
  }
  .item {
    padding-left: 1em !important;
    padding-right: 1em !important;
  }
  .item > .image {
    margin-left: 0 !important;
  }
  .desktop img {
    max-height: 100px !important;
    max-width: 160px !important;
  }
  .mobile img {
    max-height: 80px !important;
    max-width: 130px !important;
    margin-bottom: 1rem;
    margin-left: 1rem;
  }
  /* Add left and right margin on mobile */
  .innerWrapper.mobile .ui.items {
    margin-left: 1rem;
    margin-right: 1rem;
  }
  /* Reduce maximum height of images on mobile - matches Semantic's rule to override it */
  @media only screen and (max-width: 767.98px) {
    .ui.items:not(.unstackable)>.item>.image, .ui.items:not(.unstackable)>.item>.image>img {
      max-height: 80px !important;
    }
  }
  /* Give top padding to first memo set */
  .ui.divided.items>.item:first-child {
    padding-top: 1em !important;
  }
  /* Give bottom padding to last memo set */
    .ui.divided.items>.item:last-child {
    padding-bottom: 1em !important;
  }
  .noBottomMargin {
    margin-bottom: 0 !important;
  }
  .mobile .description {
    margin-bottom: 1rem !important;
    margin-top: 1rem !important;
    word-break: break-word;
  }
  .ui.link.divided.items {
    background-color: white;
    border: 1px solid rgba(34,36,38,.15);
    border-radius: 0.28571429rem;
  }
  /* Override Semantic's minimal image rounding */
  .ui.items>.item>.image>img {
    border-radius: inherit;
  }
  .publicTableLeft {
    width: 186px;
  }
  .publicTableCenter {
    word-break: break-word;
  }
  .publicTableRight {
    width: 320px;
  }
  /* Important to prevent mobile memo set title changing cover on hover */
  .standardColor {
    color: rgba(0,0,0,.87) !important;
  }
  .authorName {
    display: inline-block;
    width: 11rem;
    word-break: break-word;
  }
  .mobileTitle {
     width: calc(100% - 150px);
  }
  /* Make <a> within <td> fill the entire cell, as per https://stackoverflow.com/a/15801081 */
  td {
    overflow: hidden;
  }
  td > a {
    display: block;
    margin: -5em;
    padding: 5.4em;
  }
</style>
