<template>
  <div>
    <breadcrumbs :breadcrumbs="breadcrumbs" :connected="connected" :text="text"></breadcrumbs>
    <div class="innerWrapper" :class="{'mobile': mobile}">
      <div class="ui link divided items">
        <router-link class="item" v-for="memoSet in visibleMemoSets" :key="memoSet.memoSetId" :to="'shared-memo-sets/' + memoSet.memoSetId + '/' + memoSet.slug">
          <div class="ui small image">
            <img :src="memoSet.coverImage">
          </div>
          <div class="content">
            <div class="ui grid">
              <div :class="{'eight wide column': !mobile, 'sixteen wide column': mobile}">
                <div class="ui header">
                  {{ memoSet.title }}
                </div>
                <div class="description" v-html="sanitize(memoSet.description)" v-show="memoSet.description !== text.memoSetDefaultDescription"></div>
              </div>
              <div :class="{'eight wide column': !mobile, 'sixteen wide column': mobile}">
                <div>
                  <div class="rightLabel"><b>{{ text.publicAuthor }}:</b></div>
                  {{ memoSet.ownerName }}
                  <br>
                  <div class="rightLabel"><b>{{ text.commonRows }}:</b></div>
                  {{ memoSet.rowCount }}
                </div>
              </div>
            </div>
          </div>
        </router-link>
      </div>
      <br><br>
    </div>
  </div>
</template>

<script>
import Breadcrumbs from '@/components/Breadcrumbs'

export default {
  name: 'shared-memo-sets',
  components: {
    Breadcrumbs
  },
  data () {
    return {
      ratingsSummary: {},
      userReady: false
    }
  },
  computed: {
    breadcrumbs: function () {
      return [
        {label: this.text.navHome, to: '/'},
        {label: this.text.navSharedMemoSets}
      ]
    },
    visibleMemoSets: function () {
      return this.sharedMemoSets.filter(memoSet => !memoSet.status)
    }
  },
  created: function () {
    // TEMP - for debugging
    window.vm = this
    window.memq = window.memq || {}
    // Set memo set source - used by the MemoSet component
    window.memq.memoSetSource = 'SharedMemoSets'
    this.checkUserReady()
    this.setPageTitle()
  },
  mounted: function () {
  },
  methods: {
    checkUserReady: function () {
      // Redirect to Home page if the user data has loaded but the user isn't signed in
      if (this.user.uiLanguage && !this.user.folders) {
        this.$router.push('/')
      }
      if (this.user.folders) {
        this.userReady = true
      }
    },
    sanitize: function (html) {
      let sanitizedHtml
      if (!html) return ''
      sanitizedHtml = this.$sanitize(
        html,
        {
          allowedAttributes: {
            img: ['class', 'src']
          },
          allowedTags: ['b', 'br', 'i', 'img'],
          allowedSchemesByTag: {
            img: ['data', 'http', 'https']
          }
        }
      )
      return sanitizedHtml
    },
    setPageTitle: function () {
      if (this.text && this.text.navSharedMemoSets) {
        document.title = this.text.navSharedMemoSets + ' - MemQ'
      }
    }
  },
  props: ['connected', 'mobile', 'sharedMemoSets', 'text', 'touch', 'user', 'window'],
  watch: {
    text: function (newText) {
      this.setPageTitle()
    },
    user: function (newUser) {
      this.checkUserReady()
    }
  }
}
</script>

<style scoped>
  /* Add space below heading */
  h2 {
    margin-bottom: 2.5rem;
  }
  .rightLabel {
    display: inline-block;
    margin-top: 1rem;
    width: 8rem;
  }
  .rightLabel:first-child {
    margin-top: 0;
  }
  .item:hover {
    background-color: #e6e9ef;
  }
  .item {
    padding-left: 1em !important;
    padding-right: 1em !important;
  }
  .item > .image {
    margin-left: 0 !important;
    width: 80px !important;
  }
  /* Add left and right margin on mobile */
  .innerWrapper.mobile .ui.items {
    margin-left: 1rem;
    margin-right: 1rem;
  }
  /* Reduce maximum height of images on mobile - matches Semantic's rule to override it */
  @media only screen and (max-width: 767.98px) {
    .ui.items:not(.unstackable)>.item>.image, .ui.items:not(.unstackable)>.item>.image>img {
      max-height: 80px !important;
    }
  }
  /* Give top padding to first memo set */
  .ui.divided.items>.item:first-child {
    padding-top: 1em !important;
  }
  /* Give bottom padding to last memo set */
    .ui.divided.items>.item:last-child {
    padding-bottom: 1em !important;
  }
  /* Prevent memo set title changing color on hover */
  div.ui.header {
    color: rgba(0,0,0,.87) !important;
  }
  .ui.link.divided.items {
    background-color: white;
    border: 1px solid rgba(34,36,38,.15);
    border-radius: 0.28571429rem;
  }
</style>
