<template>
  <div>
    <div v-if="reloading" class="innerWrapper">
      <div class="ui active inline loader reloading"></div>
      <p>{{ text.updating }}</p>
    </div>
    <div v-if="showReloadMessage" class="ui orange message standardColor reloadMessage">
      <div class="header standardColor">{{ text.homeUpdateHeader }}</div>
      <p>{{ text.homeUpdateMessage }}</p>
    </div>
    <registered-home
      v-if="user && user.id && !reloading"
      :connected="connected"
      :history="history"
      :memo-sets="memoSets"
      :mobile="mobile"
      :shared-memo-sets="sharedMemoSets"
      :statuses="statuses"
      :text="text"
      :touch="touch"
      :user="user"
      :window="window"
    ></registered-home>
    <unregistered-home
      v-if="user && !user.id && !reloading"
      :mobile="mobile"
      :text="text"
      :touch="touch"
      :window="window"
    ></unregistered-home>
  </div>
</template>

<script>
import RegisteredHome from '@/components/RegisteredHome'
import UnregisteredHome from '@/components/UnregisteredHome'
const firebase = window.firebase
const db = firebase.firestore()

function logError (errorCode, error) {
  console.log(errorCode)
  console.log(error)
}

export default {
  name: 'home',
  components: {
    RegisteredHome,
    UnregisteredHome
  },
  data () {
    return {
      reloading: false,
      showReloadMessage: false
    }
  },
  methods: {
    checkVersion: function () {
      // Exit if no user
      if (!this.user || !this.user.id) return
      // If code version is less than the version in the database, and the database version has the expected number of digits
      if (this.version && this.version.codeVersion < this.version.dbVersion && this.version.dbVersion.toString().length === 12) {
        // The app is out of date - read reload data
        db.doc('users/' + this.user.id + '/reload/doc')
          .get()
          .then(doc => {
            // If there's no document within the last 20 seconds
            if (!doc.exists || doc.data().timestamp < Date.now() - 20000) {
              // Write initial reload data
              db.doc('users/' + this.user.id + '/reload/doc')
                .set({
                  reloadCount: 0,
                  timestamp: Date.now()
                })
                .then(() => {
                  // Reload the app to update
                  this.reloading = true
                  setTimeout(() => {
                    window.location.reload()
                  }, 500)
                })
                .catch(error => {
                  logError('home_checkVersion_1', error)
                })
            } else if (doc.data().reloadCount > 0) {
              this.showReloadMessage = true
            } else {
              // Write updated reloaded data
              db.doc('users/' + this.user.id + '/reload/doc')
                .set({
                  reloadCount: doc.data().reloadCount + 1,
                  timestamp: Date.now()
                })
                .then(() => {
                  // Reload the app to update
                  this.reloading = true
                  setTimeout(() => {
                    window.location.reload()
                  }, 500)
                })
                .catch(error => {
                  logError('home_checkVersion_2', error)
                })
            }
          })
          .catch(error => {
            logError('home_checkVersion_3', error)
          })
      }
    }
  },
  created: function () {
    this.checkVersion()
  },
  mounted: function () {
    // Calculate history if required
    if (this.user && this.user.id && !this.reloading) {
      this.$emit('check-history')
    }
  },
  props: ['connected', 'history', 'memoSets', 'mobile', 'sharedMemoSets', 'statuses', 'text', 'touch', 'user', 'version', 'window'],
  watch: {
    'version.dbVersion': function (newVersion) {
      this.checkVersion()
    }
  }
}
</script>

<style scoped>
  .reloading {
    margin: 0.8rem 0 1rem 0.2rem !important;
  }
  .reloadMessage {
    margin-bottom: 0;
  }
</style>
