<template>
  <div class="breadcrumbWrapper">
    <div class="ui breadcrumb" style="width: 100%">
      <div class="ui basic orange label reconnecting" v-show="!connected">{{ text.commonReconnecting }}</div>
      <template v-for="(breadcrumb, index) in breadcrumbs">
        <router-link :to="breadcrumb.to" exact v-if="breadcrumb.to" :key="'bcLink' + index">{{ breadcrumb.label }}</router-link>
        <i class="right chevron icon divider" v-if="breadcrumb.to" :key="'bcDivider' + index"></i>
        <div class="active section" v-if="!breadcrumb.to" :key="'bcLabel' + index">{{ breadcrumb.label }}</div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: 'breadcrumbs',
  props: ['breadcrumbs', 'connected', 'text']
}
</script>

<style scoped>
  .breadcrumbWrapper {
    background-color: #e5e7e9;
    border-bottom: 1px solid lightgray;
    overflow-x: auto;
    padding-left: 1.5em;
    padding-right: 1.5em;
    white-space: nowrap;
  }
  /* Make breadcrumb scrollbar very narrow */
  .breadcrumbWrapper::-webkit-scrollbar {
    height: 2px;
  }
  @media only screen and (max-width: 767px) {
    .breadcrumbWrapper {
      padding-left: 1em;
      padding-right: 1em;
    }
  }
  .ui.breadcrumb {
    padding-top: 10px;
    padding-bottom: 8px;
  }
  .reconnecting {
    position: absolute;
    margin-top: -0.2rem;
    right: 10px;
  }
</style>
