<template>
  <div>
    <div class="innerWrapper">
      <div class="signInWrapper">
        <h2>{{ text.recoverTitle }}</h2>
        <form class="ui form" @submit.prevent="recover()">
          <p>{{ text.recoverMessage }}</p>
          <div class="field">
            <input id="email" type="email" name="email" v-model="email" :placeholder="text.commonEmailAddressPlaceholder" @input="noAccountError = false; unknownError = false; sent = false">
          </div>
          <div class="ui red message" v-show="noAccountError">
            <p>{{ text.recoverNoAccount }}</p>
          </div>
          <div class="ui red message" v-show="unknownError">
            <p>{{ text.recoverError }}</p>
          </div>
          <div class="ui green standardColor message" v-show="sent">
            <p><i class="large green check icon"></i>{{ text.recoverSent }}</p>
          </div>
          <div class="ui small active inline loader" v-show="sending"></div>
          <button type="submit" class="ui primary button" :class="{disabled: !email || !emailValid}" v-show="!sending && !sent && !noAccountError && !unknownError">{{ text.recoverSendRecoveryLink }}</button>
        </form>
        <div class="footer">
          <p><router-link to="sign-in">{{ text.recoverReturnToSignIn }}</router-link></p>
          <p><router-link to="contact-us">{{ text.recoverContactUs }}</router-link></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const firebase = window.firebase

function logError (errorCode, error) {
  console.log(errorCode)
  console.log(error)
}

export default {
  name: 'signIn',
  props: ['mobile', 'text', 'touch', 'user', 'window'],
  data () {
    return {
      email: '',
      noAccountError: false,
      sending: false,
      sent: false,
      unknownError: false
    }
  },
  created: function () {
    // TEMP - for debugging
    window.vmRecover = this
    // Scroll to top
    window.scrollTo(0, 0)
  },
  computed: {
    emailValid: function () {
      // Function from https://stackoverflow.com/a/9204568
      let regex = /\S+@\S+\.\S+/
      return regex.test(this.email)
    }
  },
  methods: {
    recover () {
      this.sending = true
      // Set language for Firebase email - see https://github.com/firebase/firebaseui-web/blob/master/LANGUAGES.md for the Firebase language codes
      switch (this.user.uiLanguage) {
        case 'cn':
          firebase.auth().languageCode = 'zh_cn'
          break
        case 'gb':
          firebase.auth().languageCode = 'en_gb'
          break
        case 'us':
          firebase.auth().languageCode = 'en'
          break
        case 'de':
        case 'es':
        case 'fr':
          firebase.auth().languageCode = this.user.uiLanguage
          break
      }
      firebase.auth().sendPasswordResetEmail(this.email)
        .then(() => {
          this.sending = false
          this.sent = true
          document.getElementById('email').blur()
        })
        .catch((error) => {
          if (error.code === 'auth/user-not-found') {
            this.noAccountError = true
          } else {
            this.unknownError = true
            logError('Recover_recover', error)
          }
          this.sending = false
        })
    }
  }
}
</script>

<style scoped>
  form {
    margin-top: 2rem;
  }
  .signInWrapper {
    max-width: 450px;
    margin: 3rem auto 3rem auto;
  }
  button, div.loader {
    margin-top: 1rem !important;
  }
  .footer {
    margin-top: 3rem;
    padding-bottom: 2rem;
  }
</style>
