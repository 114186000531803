<template>
  <div>
    <breadcrumbs v-if="memoSetType !== 'example'" class="noPrint" :breadcrumbs="breadcrumbs" :connected="connected" :text="text"></breadcrumbs>
    <div class="innerWrapper" :class="{'mobile': mobile}">
      <div v-if="memoSetType === 'example'" class="exampleSpacer"></div>
      <div class="ui small active inline loader memoSetLoader" v-show="!ready"></div>
      <div v-show="ready">
        <div v-if="memoSetType === 'example'" class="exampleHeader" :class="{'mobile': mobile}">
          <h3 class="exampleTitle" :class="{'mobile': mobile}">{{ title }}</h3>
        </div>
        <div v-if="memoSetType === 'public' || memoSetType === 'shared'" :class="{'mobile': mobile, 'publicHeaderWrapper': memoSetType !== 'regular'}">
          <h4 class="rightLabel noBottomMargin">{{ text.memoSetAuthor }}:</h4>
          <div class="sharedOwnerName">{{ ownerName }}</div>
          <br>
          <div v-if="user.id !== ownerId" style="display: inline-block; margin-top: 0.6rem">
            <h4 class="rightLabel">{{ text.memoSetMyRating }}:</h4>
            <div class="ui yellow star rating myRating" :data-rating="myRating" data-max-rating="5"></div>
          </div>
          <div v-if="user.id === ownerId" style="display: inline-block; margin-top: 0.6rem">
            <h4 class="rightLabel">{{ text.publicRating }}:</h4>
            <div class="ui yellow star rating" style="position: relative; width: 7rem">
              <div style="margin-top: -1rem; position: absolute">
                <i class="star icon largeInactiveStar"></i>
                <i class="star icon largeInactiveStar"></i>
                <i class="star icon largeInactiveStar"></i>
                <i class="star icon largeInactiveStar"></i>
                <i class="star icon largeInactiveStar"></i>
              </div>
              <div style="margin-top: -1rem; position: absolute; overflow-x: hidden" :style="{width: ratingsData.width + 'rem'}">
                <i class="star active icon"></i>
                <i class="star active icon"></i>
                <i class="star active icon"></i>
                <i class="star active icon"></i>
                <i class="star active icon"></i>
              </div>
            </div>
            <span class="ratingsCount">({{ ratingsData.count }})</span>
          </div>
          <div class="rightButtonWrapper" :class="{'mobile': mobile, 'noTabsText': !tabsText, 'publicRightButtonWrapper': memoSetType !== 'regular'}">
            <div class="ui one item menu multiLine">
              <a class="item addItem" @click="addToMyMemoSetsClick($event)">
                <i class="large plus icon" :class="{'compactTabsIcon': mobile, 'noRightMargin': !tabsText}"></i>
                <span :class="{'compactTabsText': mobile}" v-show="tabsText"> {{ text.commonAdd }}</span>
              </a>
            </div>
          </div>
          <!-- Delete button for shared memo set -->
          <div class="rightButtonWrapper publicRightButtonWrapper" :class="{'mobile': mobile, 'noTabsText': !tabsText}" v-if="memoSetType === 'shared'">
            <div class="ui one item menu multiLine" v-if="user.id !== ownerId">
              <a class="item delete" @click="removeSharedMemoSetClick($event)">
                <i class="large trash alternate icon" :class="{'compactTabsIcon': mobile, 'noRightMargin': !tabsText}"></i>
                <span :class="{'compactTabsText': mobile}" v-show="tabsText"> {{ text.commonDelete}}</span>
              </a>
            </div>
          </div>
        </div>
        <div class="tabsAndButtonWrapper noPrint" :class="{'mobile': mobile}">
          <div class="tabsWrapper" :class="{'mobile': mobile, 'noTabsText': !tabsText, 'threeTabs': memoSetType !== 'regular'}">
            <div class="ui menu mainTabs multiLine" :class="{'four item': memoSetType === 'regular', 'three item': memoSetType !== 'regular', 'mobile': mobile}">
              <a class="active item" data-tab="info">
                <i class="large info circle icon" :class="{'compactTabsIcon': mobile, 'noRightMargin': !tabsText}"></i>
                <span :class="{'compactTabsText': mobile}" v-show="tabsText">{{ text.memoSetTabInfo }}</span>
              </a>
              <a class="item" data-tab="data">
                <i class="large table icon" :class="{'compactTabsIcon': mobile, 'noRightMargin': !tabsText}"></i>
                <span :class="{'compactTabsText': mobile}" v-show="tabsText">{{ text.memoSetTabData }}</span>
              </a>
              <a class="item" data-tab="questions">
                <i class="large question icon" :class="{'compactTabsIcon': mobile, 'noRightMargin': !tabsText}"></i>
                <span :class="{'compactTabsText': mobile}" v-show="tabsText">{{ text.memoSetTabQuestions }}</span>
              </a>
              <a class="item" data-tab="learn" v-if="memoSetType === 'regular'">
                <i class="large graduation cap icon" :class="{'compactTabsIcon': mobile, 'noRightMargin': !tabsText}"></i>
                <span :class="{'compactTabsText': mobile}" v-show="tabsText">{{ text.memoSetTabLearn }}</span>
              </a>
            </div>
          </div>
          <div class="rightButtonWrapper" :class="{'mobile': mobile, 'noTabsText': !tabsText}">
            <div class="ui one item menu multiLine" v-show="walkthroughEnabled">
              <a class="item walkthroughItem" @click="walkthroughClick($event)">
                <i class="large walking icon" :class="{'compactTabsIcon': mobile, 'noRightMargin': !tabsText}"></i>
                <span :class="{'compactTabsText': mobile}" v-show="tabsText">{{ text.memoSetWalkthrough }}</span>
              </a>
            </div>
          </div>
        </div>
        <div class="ui active tab basic segment noBottomMargin noBottomPadding noTopMargin" data-tab="info">
          <div
            id="infoWrapper"
            class="infoWrapper"
            :class="{'mobile': mobile, 'thinScroll': mobile && touch}"
            :style="{maxHeight: infoWrapperMaxHeight + 'px'}"
          >
            <div class="ui grid infoGrid" :class="{'mobile': mobile}">
              <!-- Title -->
              <div
                v-if="memoSetType === 'regular' || memoSetType === 'shared'"
                class="noTopMargin noTopPadding"
                :class="{'one wide column labelColumn': !mobile, 'sixteen wide column': mobile}"
              >
                <h4 class="noTopMargin noTopPadding">{{ text.memoSetTitle }}<span v-show="!mobile">:</span></h4>
              </div>
              <div class="noTopPadding" v-if="memoSetType !== 'example'" :class="{'fifteen wide column mainColumn': !mobile && memoSetType !== 'public', 'sixteen wide column': mobile || memoSetType === 'public'}">
                <h4 v-if="memoSetType === 'public'" class="noTopMargin">{{ title }}</h4>
                <div v-if="memoSetType === 'shared'" class="noTopMargin">{{ title }}</div>
                <div
                  id="title"
                  v-if="memoSetType === 'regular'"
                  class="editable inlineEditable"
                  contenteditable
                  tabindex="0"
                  @blur="titleBlur($event)"
                  @focus="titleFocus($event)"
                  @input="titleInput($event)"
                  @keydown.esc="$event.target.blur()"
                >{{ title }}</div>
              </div>
              <!-- Cover Image -->
              <div
                v-if="memoSetType === 'regular' || memoSetType === 'shared'"
                class="noTopMargin noTopPadding"
                :class="{'one wide column labelColumn': !mobile, 'sixteen wide column': mobile}"
              >
                <h4 class="noTopMargin noTopPadding">{{ text.memoSetCoverImage }}<span v-show="!mobile">:</span></h4>
              </div>
              <div class="noTopPadding" :class="{'fifteen wide column mainColumn': !mobile && memoSetType !== 'public' && memoSetType !== 'example', 'sixteen wide column': mobile || memoSetType === 'public' || memoSetType === 'example'}">
                <div class="ui small active inline loader" v-show="!coverImageLoaded"></div>
                <img
                  v-show="coverImageLoaded"
                  class="ui rounded image coverImage"
                  :class="{coverImageEditable: memoSetType === 'regular'}"
                  :src="coverImageTemp || coverImage"
                  @click="coverImageClick()"
                  @load="coverImageLoaded = true"
                >
              </div>
              <!-- Description -->
              <div
                v-if="memoSetType !== 'public' && memoSetType !== 'example' && (memoSetType === 'regular' || description !== text.memoSetDefaultDescription)"
                class="noTopMargin noTopPadding"
                :class="{'one wide column labelColumn': !mobile, 'sixteen wide column': mobile}"
              >
                <h4 class="noTopMargin noTopPadding">{{ text.memoSetDescription }}<span v-show="!mobile">:</span></h4>
              </div>
              <div
                class="noTopMargin noTopPadding"
                :class="{'fifteen wide column mainColumn': !mobile && memoSetType !== 'public' && memoSetType !== 'example', 'sixteen wide column': mobile || memoSetType === 'public' || memoSetType === 'example'}"
                v-show="memoSetType === 'regular' || description !== text.memoSetDefaultDescription"
              >
                <div v-if="memoSetType !== 'regular'" v-html="sanitize(description)"></div>
                <div
                  v-if="memoSetType === 'regular'"
                  id="description"
                  class="editable inlineEditable"
                  :class="{descriptionPlaceholder: descriptionPlaceholder}"
                  contenteditable
                  @blur="descriptionBlur($event)"
                  @focus="descriptionFocus($event)"
                  @input="descriptionInput()"
                  @keydown.esc="$event.target.blur()"
                  v-html="sanitize(description)"
                ></div>
              </div>
              <!-- Options -->
              <div
                v-if="memoSetType === 'regular' && !printing"
                class="noTopMargin noTopPadding"
                :class="{'one wide column labelColumn': !mobile, 'sixteen wide column': mobile}"
              >
                <h4 class="noTopMargin noTopPadding">{{ text.memoSetOptions }}<span v-show="!mobile">:</span></h4>
              </div>
              <div v-if="memoSetType === 'regular' && !printing" class="noTopPadding" :class="{'fifteen wide column mainColumn': !mobile, 'sixteen wide column': mobile}" style="margin-bottom: 1rem">
                <div class="ui checkbox">
                  <input type="checkbox" name="useReviewGroups" v-model="options.useReviewGroups" @change="optionsChange('useReviewGroups')">
                  <label style="display: inline-block">{{ text.memoSetUseGroups }}</label>
                </div>
                <div class="optionsMarginTop">
                  <div class="ui checkbox">
                    <input type="checkbox" name="useBackgroundPhotos" v-model="options.useBackgroundPhotos" @change="optionsChange('useBackgroundPhotos')">
                    <label style="display: inline-block">{{ text.memoSetUseBackgroundPhotos }}</label>
                  </div>
                </div>
                <div class="optionsMarginTop optionsIndented" v-show="options.useBackgroundPhotos">
                  <div class="ui checkbox">
                    <input type="checkbox" name="usePhotoOverviews" v-model="options.usePhotoOverviews" @change="optionsChange('usePhotoOverviews')">
                    <label style="display: inline-block">{{ text.memoSetShowPhotoOverviews }}</label>
                  </div>
                </div>
                <div class="optionsMarginTop">
                  <div class="ui checkbox" :class="{disabled: options.useBackgroundPhotos}">
                    <input type="checkbox" name="useSummaryImages" v-model="options.useSummaryImages" @change="optionsChange('useSummaryImages')">
                    <label style="display: inline-block">{{ text.memoSetUseSummaryImages }}</label>
                  </div>
                </div>
                <div class="optionsMarginTop" v-show="questions.length">
                  <div class="ui checkbox">
                    <input type="checkbox" name="active" v-model="active" @change="activeChange()">
                    <label style="display: inline-block">{{ text.memoSetShowOnHomePage }}</label>
                  </div>
                </div>
                <div class="reviewScheduleDiv" v-show="questions.length">
                  <span class="reviewScheduleLink" @click="reviewScheduleClick()">{{ text.memoSetReviewSchedule }}</span>
                </div>
              </div>
              <!-- Sharing -->
              <div v-if="selfOwned && sharingArray.length" class="noPrint noTopMargin noTopPadding" :class="{'one wide column labelColumn': !mobile, 'sixteen wide column': mobile}">
                <h4 class="noTopMargin noTopPadding">{{ text.memoSetSharedWith }}<span v-show="!mobile">:</span></h4>
              </div>
              <div v-if="selfOwned && sharingArray.length" class="noPrint noTopMargin noTopPadding" :class="{'fifteen wide column mainColumn': !mobile, 'sixteen wide column': mobile}">
                <div v-for="share in sharingArray" :key="share.userId" class="ui label moveUp" :class="{green: share.userId === 'public'}">
                  <span v-if="share.userId === 'public'">{{ text.memoSetPublic }}</span>
                  <span v-if="share.userId !== 'public'">{{share.displayName}}</span>
                  <i class="delete icon" @click="unshareMemoSet(share.userId)"></i>
                </div>
              </div>
              <!-- Original Author -->
              <div v-if="memoSetType === 'regular' && !selfOwned" class="noTopMargin noTopPadding" :class="{'one wide column labelColumn': !mobile, 'sixteen wide column': mobile}">
                <h4 class="noTopMargin noTopPadding">{{ text.memoSetOriginalAuthor }}<span v-show="!mobile">:</span></h4>
              </div>
              <div v-if="memoSetType === 'regular' && !selfOwned" class="noTopMargin noTopPadding" :class="{'fifteen wide column mainColumn': !mobile, 'sixteen wide column': mobile}">
                {{ authorName }}
              </div>
              <!-- My Rating -->
              <div v-if="memoSetType === 'regular' && !selfOwned" class="noTopMargin noTopPadding" :class="{'one wide column labelColumn': !mobile, 'sixteen wide column': mobile}">
                <h4 class="noTopMargin noTopPadding">{{ text.memoSetMyRating }}<span v-show="!mobile">:</span></h4>
              </div>
              <div v-if="memoSetType === 'regular' && !selfOwned" class="noTopMargin noTopPadding" :class="{'fifteen wide column mainColumn': !mobile, 'sixteen wide column': mobile}">
                <div class="ui yellow star rating myRating" :data-rating="myRating" data-max-rating="5"></div>
              </div>
            </div>
            <!-- Notification of updates -->
            <div class="ui yellow message standardColor updatesWrapper" v-if="updatesNotificationVisible">
              <i class="close icon" @click="dismissUpdatesNotification()"></i>
              <h4 class="header" style="margin-bottom: 1rem">{{ text.memoSetOriginalUpdatedHeader }}</h4>
              <p>{{ text.memoSetOriginalUpdatedMessage }}</p>
              <div class="ui button" style="margin-top: 0.6rem" @click="openOriginalClick()">{{ text.memoSetOpenOriginal }}</div>
            </div>
            <!-- Buttons -->
            <div v-if="memoSetType === 'regular'" class="actionButtonsWrapper noPrint">
              <button id="copyMemoSetButton" class="ui button actionButton" @click="copyMemoSetClick()"><i class="copy icon"></i>{{ text.memoSetCopy }}</button>
              <button id="printMemoSetButton" class="ui button actionButton" :class="{'disabled loading': printing}" @click="printMemoSetClick()"><i class="print icon"></i>{{ text.memoSetPrint }}</button>
              <button v-if="selfOwned" id="shareMemoSetButton" class="ui button actionButton" @click="shareMemoSetClick()"><i class="share alternate icon"></i>{{ text.memoSetShare }}</button>
              <button id="exportButton" class="ui button actionButton" @click="exportDataClick()"><i class="download icon"></i>{{ text.memoSetExport }}</button>
              <button id="deleteMemoSetButton" class="ui button actionButton" @click="deleteMemoSetClick()"><i class="trash alternate icon"></i>{{ text.commonDelete}}</button>
            </div>
            <!-- Editable updates -->
            <div class="updatesWrapper" v-if="selfOwned && sharingArray.length">
              <h4>{{ text.memoSetUpdates }}</h4>
              <p>{{ text.memoSetUpdatesInstructions }}</p>
              <table class="ui celled unstackable table">
                <thead>
                  <tr>
                    <th class="collapsing dateMinWidth">{{ text.memoSetDate }}</th>
                    <th>{{ text.memoSetDescription }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(update, updateIndex) in updates" :key="updateIndex">
                    <td class="collapsing">
                      <input
                        v-if="selfOwned && sharingArray.length"
                        type="date"
                        :max="dateToday"
                        v-model="update.date"
                        class="updateDate"
                        :class="{emptyDate: update.date === ''}"
                        @blur="updatesDateBlur(updateIndex)"
                        @focus="dateToday = formattedCurrentDate; previousUpdateDate = update.date"
                      >
                    </td>
                    <td><div :contenteditable="selfOwned && sharingArray.length" @blur="updatesDescrBlur($event, updateIndex)" @input="updatesDescrInput($event)">{{ update.descr }}</div></td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!-- Read-only updates -->
            <div class="updatesWrapper" v-if="(memoSetType === 'public' || memoSetType === 'shared') && updates.length">
              <h4>{{ text.memoSetUpdates }}</h4>
              <table class="ui celled unstackable table">
                <thead>
                  <tr>
                    <th class="collapsing dateMinWidth">{{ text.memoSetDate }}</th>
                    <th>{{ text.memoSetDescription }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(update, updateIndex) in updates" :key="updateIndex">
                    <td class="collapsing">{{ update.date }}</td>
                    <td>{{ update.descr }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div id="dataTab" class="ui tab basic segment noBottomMargin noBottomPadding noTopPadding dataTab" :class="{dataTabPrinting: printing, verticallyChallenged: verticallyChallenged}" data-tab="data">
          <!-- Search/navigation/actions row -->
          <div class="searchRowWrapper noPrint" :class="{'mobile': mobile}" v-if="memoSetType === 'regular'">
            <!-- Search -->
            <div class="searchWrapper" :class="{'mobile': mobile, 'noTabsText': !tabsText}">
              <div class="ui fluid search">
                <div class="ui fluid icon input">
                  <input id="search" class="prompt" type="text" :placeholder="text.commonSearchPlaceholder">
                  <i class="search icon"></i>
                </div>
                <div class="results" :class="{'mobile': mobile, 'noTabsText': !tabsText}"></div>
              </div>
            </div>
            <!-- Navigation -->
            <div class="navigationWrapper" :class="{'mobile': mobile, 'noTabsText': !tabsText}">
              <button class="ui icon button goToRowButton" :class="{disabled: firstVisibleRow === 1}" v-show="tabsText" @click="firstRowsClick($event)">
                <i class="large double angle left icon largeIconInButton"></i>
              </button>
              <button class="ui icon button goToRowButton" :class="{disabled: firstVisibleRow === 1}" @click="previousRowsClick($event)">
                <i class="large angle left icon largeIconInButton"></i>
              </button>
              <div class="ui input goToRowInput">
                <input id="goToRow" type="text" v-model="goToRowVal" @change="goToRow()" @keyup.enter="goToRowEnter($event)" @keyup.esc="goToRowEnter($event)">
              </div>
              <button class="ui icon button goToRowButton" :class="{disabled: rows.length < firstVisibleRow + visibleRowsCount}" @click="nextRowsClick($event)">
                <i class="large angle right icon largeIconInButton"></i>
              </button>
              <button class="ui icon button goToRowButton" :class="{disabled: rows.length < firstVisibleRow + visibleRowsCount}" v-show="tabsText" @click="lastRowsClick($event)">
                <i class="large double angle right icon largeIconInButton"></i>
              </button>
            </div>
            <div class="actionsButtonWrapper" :class="{'mobile': mobile, 'noTabsText': !tabsText}">
              <div class="ui one item menu multiLine actionsButton">
                <a class="item">
                  <i class="large bars icon" :class="{'compactTabsIcon': mobile, 'noRightMargin': !tabsText}"></i>
                  <span :class="{'compactTabsText': mobile}" v-show="tabsText">{{ text.memoSetActions }}</span>
                </a>
              </div>
              <div class="ui popup top transition hidden">
                <div class="ui divided two column grid" :class="{desktopActionsWidth: !mobile, mobileActionsWidth: mobile}">
                  <div class="column">
                    <h4 class="ui header">{{ text.memoSetColumns }}<i class="disabled grey clockwise rotated content icon actionsIcon"></i></h4>
                    <div class="ui link list">
                      <a class="item" @click="addColumnClick()">{{ text.memoSetAddColumn }}</a>
                      <a class="item" :class="{disabled: !fields.length}" @click="deleteColumnClick()">{{ text.memoSetDeleteColumn }}</a>
                      <a class="item" :class="{disabled: fields.length < 2}" @click="moveColumnClick()">{{ text.memoSetMoveColumn }}</a>
                      <a class="item" :class="{disabled: !fields.length}" @click="populateColumnClick()">{{ text.memoSetPopulateColumn }}</a>
                    </div>
                  </div>
                  <div class="column">
                    <h4 class="ui header">{{ text.commonRows }}<i class="disabled grey content icon actionsIcon"></i></h4>
                    <div class="ui link list">
                      <a class="item" @click="addRowsClick()">{{ text.memoSetAddRows }}</a>
                      <a class="item" :class="{disabled: !rows.length}" @click="deleteRowsClick()">{{ text.memoSetDeleteRows }}</a>
                      <a class="item" :class="{disabled: rows.length < 2}" @click="moveRowsClick()">{{ text.memoSetMoveRows }}</a>
                      <a class="item" :class="{disabled: rows.length < 2}" @click="sortRowsClick()">{{ text.memoSetSortRows }}</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            id="tableWrapper"
            class="tableWrapper"
            :class="{mobile: mobile, printing: printing, readOnly: memoSetType !== 'regular', thinScroll: mobile && touch, verticallyChallenged: verticallyChallenged}"
            :style="{maxHeight: tableWrapperMaxHeight + 'px'}"
            v-if="printing || (activeTab === 'data' && modal !== 'summaryImage' && modal !== 'walkthrough')"
            @scroll.passive="tableWrapperScroll()"
          >
            <table v-if="fields" class="ui celled unstackable table memoSetTable">
              <thead>
                <tr class="middle aligned">
                  <th class="one wide disabled">{{ text.memoSetRow }}</th>
                  <th v-for="(field, fieldIndex) in fields" :key="field.fieldId" class="two wide noPadding dummyHeight outlineOnFocus" :class="{disabled: memoSetType !== 'regular'}">
                    <div
                        class="contenteditableHeading"
                        :contenteditable="memoSetType === 'regular'"
                        @blur="headingBlur($event, field, fieldIndex)"
                        @focus="headingFocus($event, field.fieldId)"
                        @input="headingInput($event)"
                        @keydown.esc="$event.target.blur()"
                    >{{ field.heading }}</div>
                  </th>
                  <th class="two wide disabled" v-show="options.useReviewGroups">{{ text.memoSetGroup }}</th>
                  <th class="one wide disabled photo" v-show="options.useBackgroundPhotos">{{ text.memoSetBackgroundPhoto }}</th>
                  <th class="one wide disabled photo" v-show="options.useSummaryImages">{{ text.memoSetSummaryImage }}</th>
                  <th class="four wide disabled">{{ text.memoSetNotes }}</th>
                  <th class="one wide disabled" v-if="memoSetType === 'regular' && questions.length">{{ text.commonStatus }}</th>
                </tr>
              </thead>
              <tbody>
                <!-- Previous Rows bar -->
                <tr v-show="firstVisibleRow > 1">
                  <td class="noPadding" :colspan="fields.length + 2 + (options.useBackgroundPhotos ? 1 : 0) + (options.useReviewGroups ? 1 : 0) + (options.useSummaryImages ? 1 : 0) + (memoSetType === 'regular' && questions.length ? 1 : 0)">
                    <div class="ui fluid button barButton" @click="previousRowsClick($event)"><span :style="{marginLeft: barButtonMargin + 'px'}">{{ text.memoSetPreviousRows }}</span></div>
                  </td>
                </tr>
                <tr class="top aligned" v-for="(row, visibleRowsIndex) in visibleRows" :key="row.rowId">
                  <!-- Row number -->
                  <td class="disabled middle aligned">
                    <span v-show="visibleRowsIndex + visibleRowsOffset + 1 < 10">&nbsp;</span>
                    <span v-show="visibleRowsIndex + visibleRowsOffset + 1 < 100">&nbsp;</span>
                    {{ visibleRowsIndex + visibleRowsOffset + 1 }}
                  </td>
                  <!-- Data fields -->
                  <td v-for="(field, fieldIndex) in fields" :key="field.fieldId" class="cellTd outlineOnFocus middle aligned" :class="{cardsCell: cardStyle(row.data[field.fieldId])}" :style="cardStyle(row.data[field.fieldId])" @click="cellClick($event)">
                    <div
                      class="cellButtons"
                      :class="{lightBackground: visibleRowsOffset && !visibleRowsIndex}"
                      v-if="!(mobile && touch)"
                      v-show="visibleRowsIndex + visibleRowsOffset === currentRow && field.fieldId === currentFieldId && !hideCellButtons"
                    >
                      <div class="ui compact icon buttons">
                        <div class="ui button" style="border-left: 1px solid silver" :data-position="visibleRowsIndex ? 'top center' : 'bottom center'" :data-tooltip="text.memoSetAddImageTooltip" @mousedown.prevent.stop="addCellImageClick(visibleRowsIndex + visibleRowsOffset, field.fieldId)">
                          <i class="image icon"></i>
                        </div>
                        <div class="ui button" :data-position="visibleRowsIndex ? 'top center' : 'bottom center'" :data-tooltip="text.memoSetAddAudioTooltip" @mousedown.prevent.stop="addCellAudioClick(visibleRowsIndex + visibleRowsOffset, field.fieldId)">
                          <i class="volume down icon"></i>
                        </div>
                        <div class="ui button" :class="{disabled: !currentFieldContent}" :data-position="visibleRowsIndex ? 'top center' : 'bottom center'" :data-tooltip="text.memoSetNewLineTooltip" @mousedown.prevent.stop="newLine(visibleRowsIndex + visibleRowsOffset, field.fieldId)">
                          <i class="clockwise rotated level down alternate icon"></i>
                        </div>
                        <div class="ui button" :class="{disabled: visibleRowsIndex + visibleRowsOffset === rows.length - 1}" :data-position="visibleRowsIndex ? 'top center' : 'bottom center'" :data-tooltip="text.memoSetCopyDownTooltip" @mousedown.stop="copyDownClick(visibleRowsIndex + visibleRowsOffset, field.fieldId)">
                          <i class="angle double down icon"></i>
                        </div>
                        <div class="ui button cellDeleteButton" :class="{disabled: !currentFieldContent}" :data-position="visibleRowsIndex ? 'top center' : 'bottom center'" :data-tooltip="text.commonDelete" @mousedown.prevent.stop="deleteCellContents(visibleRowsIndex + visibleRowsOffset, field.fieldId)">
                          <i class="trash alternate icon"></i>
                        </div>
                        <div class="ui button cellDoneButton" :data-position="visibleRowsIndex ? 'top center' : 'bottom center'" :data-tooltip="text.commonDone" @mousedown="$event.target.blur()">
                          <i class="check icon"></i>
                        </div>
                      </div>
                    </div>
                    <div
                      class="contenteditableCell tex2jax_process"
                      :contenteditable="memoSetType === 'regular'"
                      v-html="sanitize(row.data[field.fieldId])"
                      :id="field.fieldId + '_' + (visibleRowsIndex + visibleRowsOffset)"
                      :spellcheck="cardStyle(row.data[field.fieldId]) ? false : true"
                      @blur="rowFieldBlur($event, field.fieldId, visibleRowsIndex + visibleRowsOffset)"
                      @focus="rowFieldFocus($event, field.fieldId, visibleRowsIndex + visibleRowsOffset)"
                      @input="cellInput($event)"
                      @keydown.enter="rowFieldEnter($event, field.fieldId, visibleRowsIndex + visibleRowsOffset)"
                      @keydown.esc="$event.target.blur()"
                      @paste="rowFieldPaste($event, 'field', fieldIndex, visibleRowsIndex + visibleRowsOffset)"
                      ></div>
                  </td>
                  <!-- Review group -->
                  <td class="cellTd one wide noPadding dummyHeight outlineOnFocus relative middle aligned" v-show="options.useReviewGroups" @click="cellClick($event)">
                    <div
                      class="cellButtons"
                      :class="{lightBackground: visibleRowsOffset && !visibleRowsIndex}"
                      v-if="!(mobile && touch)"
                      v-show="visibleRowsIndex + visibleRowsOffset === currentRow && currentFieldId === 'reviewGroup' && !hideCellButtons"
                    >
                      <div class="ui compact icon buttons">
                        <div class="ui button" style="border-left: 1px solid silver" v-show="row.data.reviewGroup" :data-position="visibleRowsIndex ? 'top center' : 'bottom center'" :data-tooltip="text.memoSetGroupColorTooltip" @mousedown.stop="setGroupColorClick(visibleRowsIndex + visibleRowsOffset)">
                          <i class="square icon" :class="reviewGroupColors[row.data.reviewGroup || '']"></i>
                        </div>
                        <div class="ui button" :class="{disabled: visibleRowsIndex + visibleRowsOffset === rows.length - 1}" style="border-left: 1px solid silver" :data-position="visibleRowsIndex ? 'top center' : 'bottom center'" :data-tooltip="text.memoSetCopyDownTooltip" @mousedown.stop="copyDownClick(visibleRowsIndex + visibleRowsOffset, 'reviewGroup')">
                          <i class="angle double down icon"></i>
                        </div>
                        <div class="ui button cellDeleteButton" :class="{disabled: !currentFieldContent}" :data-position="visibleRowsIndex ? 'top center' : 'bottom center'" :data-tooltip="text.commonDelete" @mousedown.prevent.stop="deleteCellContents(visibleRowsIndex + visibleRowsOffset, 'reviewGroup')">
                          <i class="trash alternate icon"></i>
                        </div>
                        <div class="ui button cellDoneButton" :data-position="visibleRowsIndex ? 'top center' : 'bottom center'" :data-tooltip="text.commonDone" @mousedown="$event.target.blur()">
                          <i class="check icon"></i>
                        </div>
                      </div>
                    </div>
                      <div
                        :contenteditable="memoSetType === 'regular'"
                        :id="'reviewGroup_' + (visibleRowsIndex + visibleRowsOffset)"
                        spellcheck="false"
                        class="ui label reviewGroup"
                        :class="reviewGroupColors[row.data.reviewGroup || '']"
                        @blur="reviewGroupBlur($event, visibleRowsIndex + visibleRowsOffset)"
                        @focus="reviewGroupFocus($event, visibleRowsIndex + visibleRowsOffset)"
                        @input="cellInput($event)"
                        @keydown.enter="rowFieldEnter($event, 'reviewGroup', visibleRowsIndex + visibleRowsOffset)"
                        @keydown.esc="$event.target.blur()"
                        @paste="rowFieldPaste($event, 'group', -1, visibleRowsIndex + visibleRowsOffset)"
                      >{{ row.data.reviewGroup || '' }}</div>
                  </td>
                  <!-- Background photo -->
                  <td class="center aligned middle aligned imageCell" v-show="options.useBackgroundPhotos">
                    <div class="ui centered grid">
                      <div class="row noBottomPadding noTopPadding">
                        <img
                          v-if="row.data.photoId && (visibleRowsIndex + visibleRowsOffset === 0 || row.data.photoId !== rows[visibleRowsIndex + visibleRowsOffset - 1].data.photoId)"
                          class="photoThumbnail"
                          :class="{clickable: memoSetType === 'regular'}"
                          :style="{
                            height: photoThumbnailDimensions[photoIndexes[visibleRowsIndex + visibleRowsOffset]].height + 'px',
                            minWidth: photoThumbnailDimensions[photoIndexes[visibleRowsIndex + visibleRowsOffset]].width + 'px',
                            width: photoThumbnailDimensions[photoIndexes[visibleRowsIndex + visibleRowsOffset]].width + 'px'
                          }"
                          :src="photos[photoIndexes[visibleRowsIndex + visibleRowsOffset]].url"
                          @click="backgroundPhotoClick(visibleRowsIndex + visibleRowsOffset)"
                        >
                        <span
                          v-if="memoSetType === 'regular' && visibleRowsIndex + visibleRowsOffset && row.data.photoId && row.data.photoId === rows[visibleRowsIndex + visibleRowsOffset - 1].data.photoId"
                          class="asAbove asAboveLink"
                          @click="backgroundPhotoClick(visibleRowsIndex + visibleRowsOffset)"
                        >{{ text.memoSetAsAbove }}</span>
                        <span
                          v-if="memoSetType !== 'regular' && visibleRowsIndex + visibleRowsOffset && row.data.photoId && row.data.photoId === rows[visibleRowsIndex + visibleRowsOffset - 1].data.photoId"
                          class="asAbove"
                        >
                          {{ text.memoSetAsAbove }}
                        </span>
                        <div
                          v-if="memoSetType === 'regular' && !row.data.photoId"
                          class="ui large icon button noPrint"
                          @click="backgroundPhotoClick(visibleRowsIndex + visibleRowsOffset)"
                        >
                          <i class="plus icon"></i>
                        </div>
                      </div>
                    </div>
                  </td>
                  <!-- Summary image -->
                  <td class="center aligned middle aligned imageCell" v-show="options.useSummaryImages">
                    <div class="ui centered grid">
                      <div class="row noBottomPadding noTopPadding">
                        <div v-if="((options.useBackgroundPhotos && row.data.photoId) || (row.data.objects && row.data.objects.length)) && !thumbnails[visibleRowsIndex + visibleRowsOffset]" class="ui small active inline loader"></div>
                        <img
                          v-if="thumbnails[visibleRowsIndex + visibleRowsOffset]"
                          :src="thumbnails[visibleRowsIndex + visibleRowsOffset]"
                          class="summaryImageThumbnail"
                          :class="{clickable: memoSetType === 'regular', summaryImageThumbnailBorder: !row.data.photoId}"
                          @click="summaryImageClick(visibleRowsIndex + visibleRowsOffset)"
                        >
                        <div
                          v-if="memoSetType === 'regular' && !(options.useBackgroundPhotos && row.data.photoId) && !(row.data.objects && row.data.objects.length)"
                          class="ui large icon button noPrint"
                          data-content="Add a summary image"
                          @click="summaryImageClick(visibleRowsIndex + visibleRowsOffset)"
                        >
                          <i class="plus icon"></i>
                        </div>
                      </div>
                    </div>
                  </td>
                  <!-- Notes -->
                  <td class="cellTd outlineOnFocus middle aligned" @click="cellClick($event)">
                    <div
                      class="cellButtons"
                      :class="{lightBackground: visibleRowsOffset && !visibleRowsIndex}"
                      v-if="!(mobile && touch)"
                      v-show="visibleRowsIndex + visibleRowsOffset === currentRow && currentFieldId === 'notes'"
                    >
                      <div class="ui compact icon buttons">
                        <div class="ui button" style="border-left: 1px solid silver" :data-position="visibleRowsIndex ? 'top center' : 'bottom center'" :data-tooltip="text.memoSetAddImageTooltip" @mousedown.prevent.stop="addCellImageClick(visibleRowsIndex + visibleRowsOffset, 'notes')">
                          <i class="image icon"></i>
                        </div>
                        <div class="ui button" :class="{disabled: !currentFieldContent}" style="border-left: 1px solid silver" :data-position="visibleRowsIndex ? 'top center' : 'bottom center'" :data-tooltip="text.memoSetNewLineTooltip" @mousedown.prevent.stop="newLine(visibleRowsIndex + visibleRowsOffset, 'notes')">
                          <i class="clockwise rotated level down alternate icon"></i>
                        </div>
                        <div class="ui button" :class="{disabled: visibleRowsIndex + visibleRowsOffset === rows.length - 1}" :data-position="visibleRowsIndex ? 'top center' : 'bottom center'" :data-tooltip="text.memoSetCopyDownTooltip" @mousedown.stop="copyDownClick(visibleRowsIndex + visibleRowsOffset, 'notes')">
                          <i class="angle double down icon"></i>
                        </div>
                        <div class="ui button cellDeleteButton" :class="{disabled: !currentFieldContent}" :data-position="visibleRowsIndex ? 'top center' : 'bottom center'" :data-tooltip="text.commonDelete" @mousedown.prevent.stop="deleteCellContents(visibleRowsIndex + visibleRowsOffset, 'notes')">
                          <i class="trash alternate icon"></i>
                        </div>
                        <div class="ui button cellDoneButton" :data-position="visibleRowsIndex ? 'top center' : 'bottom center'" :data-tooltip="text.commonDone" @mousedown="$event.target.blur()">
                          <i class="check icon"></i>
                        </div>
                      </div>
                    </div>
                    <div
                      class="contenteditableCell tex2jax_process"
                      :contenteditable="memoSetType === 'regular'"
                      :id="'notes_' + (visibleRowsIndex + visibleRowsOffset)"
                      v-html="sanitize(row.data.notes || '')"
                      @blur="rowFieldBlur($event, 'notes', visibleRowsIndex + visibleRowsOffset)"
                      @focus="rowFieldFocus($event, 'notes', visibleRowsIndex + visibleRowsOffset)"
                      @input="cellInput($event)"
                      @keydown.esc="$event.target.blur()"
                      @keydown.enter="rowFieldEnter($event, 'notes', visibleRowsIndex + visibleRowsOffset)"
                      @paste="rowFieldPaste($event, 'notes', -1, visibleRowsIndex + visibleRowsOffset)">
                    </div>
                  </td>
                  <!-- Status -->
                  <td v-if="memoSetType === 'regular' && questions.length" class="center aligned middle aligned statusCell">
                    <div v-for="question in questions" :key="question.questionId" class="statusCellDiv">
                      <span v-if="(row.data[question.fromFieldId] || (question.fromFieldId === 's' && thumbnails[visibleRowsIndex + visibleRowsOffset])) && (row.data[question.toFieldId] || (question.toFieldId === 's' && thumbnails[visibleRowsIndex + visibleRowsOffset]))">
                        <!-- Labels with tooltips, for non-touch screens -->
                        <span v-if="!touch">
                          <a class="ui green icon label" v-if="row.data.questions && row.data.questions[question.questionId] && row.data.questions[question.questionId].reviewCycle && row.data.questions[question.questionId].reviewAfter > now" @click="rowQuestionClick(question.questionId, visibleRowsIndex + visibleRowsOffset)" data-position="top right" :data-tooltip="fieldsById[question.fromFieldId].heading + ' ⟶ ' + fieldsById[question.toFieldId].heading"><i class="check icon"></i></a>
                          <a class="ui orange icon label" v-if="row.data.questions && row.data.questions[question.questionId] && row.data.questions[question.questionId].reviewCycle && row.data.questions[question.questionId].reviewAfter <= now" @click="rowQuestionClick(question.questionId, visibleRowsIndex + visibleRowsOffset)" data-position="top right" :data-tooltip="fieldsById[question.fromFieldId].heading + ' ⟶ ' + fieldsById[question.toFieldId].heading"><i class="calendar outline icon"></i></a>
                          <a class="ui unknown icon label" v-if="!row.data.questions || !row.data.questions[question.questionId] || !row.data.questions[question.questionId].reviewCycle" @click="rowQuestionClick(question.questionId, visibleRowsIndex + visibleRowsOffset)" data-position="top right" :data-tooltip="fieldsById[question.fromFieldId].heading + ' ⟶ ' + fieldsById[question.toFieldId].heading"><i class="x icon"></i></a>
                        </span>
                        <!-- Labels without tooltips, for touch screens -->
                        <span v-if="touch">
                          <a class="ui green icon label" v-if="row.data.questions && row.data.questions[question.questionId] && row.data.questions[question.questionId].reviewCycle && row.data.questions[question.questionId].reviewAfter > now" @click="rowQuestionClick(question.questionId, visibleRowsIndex + visibleRowsOffset)"><i class="check icon"></i></a>
                          <a class="ui orange icon label" v-if="row.data.questions && row.data.questions[question.questionId] && row.data.questions[question.questionId].reviewCycle && row.data.questions[question.questionId].reviewAfter <= now" @click="rowQuestionClick(question.questionId, visibleRowsIndex + visibleRowsOffset)"><i class="calendar outline icon"></i></a>
                          <a class="ui unknown icon label" v-if="!row.data.questions || !row.data.questions[question.questionId] || !row.data.questions[question.questionId].reviewCycle" @click="rowQuestionClick(question.questionId, visibleRowsIndex + visibleRowsOffset)"><i class="x icon"></i></a>
                        </span>
                      </span>
                    </div>
                  </td>
                </tr>
                <!-- Next Rows bar -->
                <tr v-show="rows.length > firstVisibleRow + visibleRowsCount - 1">
                  <td class="noPadding" :colspan="fields.length + 2 + (options.useBackgroundPhotos ? 1 : 0) + (options.useReviewGroups ? 1 : 0) + (options.useSummaryImages ? 1 : 0) + (memoSetType === 'regular' && questions.length ? 1 : 0)">
                    <div class="ui fluid button barButton" @click="nextRowsClick($event)"><span :style="{marginLeft: barButtonMargin + 'px'}">{{ text.memoSetNextRows }}</span></div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="ui tab basic segment noBottomMargin noBottomPadding noTopMargin" data-tab="questions">
          <div v-if="activeTab === 'questions'">
            <div
              id="questionsWrapper"
              class="questionsWrapper"
              :class="{'mobile': mobile, 'thinScroll': mobile && touch}"
              :style="{maxHeight: questionsWrapperMaxHeight + 'px'}"
              v-show="questions.length"
            >
              <div class="ui segments">
                <div class="ui segment questionSegment" v-for="(question, questionIndex) in questions" :id="'question_' + question.questionId" :key="'question_' + question.questionId" @click="questionClick('edit', question, questionIndex)">
                  {{ fieldsById[question.fromFieldId].heading }}
                  <span style="margin: 0 0.8rem">&xrarr;</span>
                  {{ fieldsById[question.toFieldId].heading }}
                </div>
              </div>
            </div>
            <button
              v-if="memoSetType === 'regular' && addQuestionEnabled"
              class="ui primary button addQuestionButton"
              @click="questionClick('add')">
              <i class="plus icon"></i>
              {{ text.memoSetAddQuestion }}
            </button>
          </div>
        </div>
        <div class="ui tab basic segment learnMain" data-tab="learn">
          <div class="ui small active inline loader" v-show="!ready"></div>
          <div v-show="ready" style="margin-top: 0.4rem">
            <div class="ui orange message standardColor" v-show="!questions.length">
              <div class="header standardColor">
                {{ text.memoSetNoQuestionsDefinedHdr }}
              </div>
              <p style="margin-top: 1rem">{{ text.memoSetNoQuestionsDefined }}</p>
            </div>
            <div v-show="questions.length">
              <div class="resetWrapper" :class="{resetWrapperMobile: mobile}">
                <div
                  class="ui right floated icon button"
                  :class="{tiny: mobile}"
                  v-show="statuses.known || statuses.due"
                  @click="resetLearningClick()"
                >
                  <i class="undo alternate icon"></i>
                </div>
              </div>
              <status-bar
                :mobile="mobile"
                page="MemoSet"
                :statuses="statuses"
                :window="window"
                class="statusBar"
              ></status-bar>
              <div class="ui orange message standardColor noStatusBar" v-show="noStatusBarText">
                <span>{{ noStatusBarText }}</span>
              </div>
              <div class="ui grid learnGrid" :class="{'mobile': mobile}">
                <div class="noRightPadding noTopPadding" :class="{'eight wide column': !mobile && options.useReviewGroups, 'sixteen wide column': mobile || !options.useReviewGroups}">
                  <div class="ui segment learnSegment">
                    <h4 class="ui block header segmentHeader">{{ text.memoSetQuestions }}
                      <div
                        class="ui compact right floated button learnSelectAllButton"
                        :class="{disabled: !learn.questionIds.length}"
                        @click="selectAllQuestions(false)"
                      >{{ text.memoSetNone }}</div>
                      <div
                        class="ui compact right floated button learnSelectAllButton"
                        :class="{disabled: learn.questionIds.length === questions.length}"
                        @click="selectAllQuestions(true)"
                      >{{ text.memoSetAll }}</div>
                    </h4>
                    <div
                      class="ui form learnQuestionsWrapper"
                      :class="{'thinScroll': mobile && touch}"
                      :style="{maxHeight: learnWrapperMaxHeights[0] + 'px'}"
                    >
                      <div class="inline fields">
                        <div class="grouped fields noBottomMargin">
                          <div class="field" v-for="question in questions" :key="'learnQuestion_' + question.questionId">
                            <div class="ui checkbox learnQuestion" :id="'learnQuestion_' + question.questionId">
                              <input type="checkbox" name="learnQuestions" tabindex="0" class="hidden">
                              <label>{{ fieldsById[question.fromFieldId].heading }}<span style="margin: 0 0.4rem">&xrarr;</span>{{ fieldsById[question.toFieldId].heading }}</label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  v-if="options.useReviewGroups"
                  class="noRightPadding noTopPadding"
                  :class="{'eight wide column rightShift': !mobile, 'sixteen wide column': mobile}"
                >
                  <div class="ui segment learnSegment">
                    <h4 class="ui block header segmentHeader">{{ text.memoSetGroups }}
                      <div
                        class="ui compact right floated button learnSelectAllButton"
                        :class="{disabled: !learn.reviewGroupIds.length}"
                        @click="selectAllReviewGroups(false)"
                      >{{ text.memoSetNone }}</div>
                      <div
                        class="ui compact right floated button learnSelectAllButton"
                        :class="{disabled: learn.reviewGroupIds.length === reviewGroupsList.length}"
                        @click="selectAllReviewGroups(true)"
                      >{{ text.memoSetAll }}</div>
                    </h4>
                    <div
                      class="ui form learnReviewGroupsWrapper"
                      :class="{'thinScroll': mobile && touch}"
                      :style="{maxHeight: learnWrapperMaxHeights[1] + 'px'}"
                    >
                      <div class="inline fields">
                        <div class="grouped fields noBottomMargin">
                          <div class="field" v-for="(reviewGroup, reviewGroupIndex) in reviewGroupsList" :key="'learnReviewGroup_' + reviewGroupIndex">
                            <div class="ui checkbox learnReviewGroup" :id="'learnReviewGroup_' + reviewGroupIndex">
                              <input type="checkbox" name="learnReviewGroups" :value="question.questionId" tabindex="0" class="hidden">
                              <label><div class="ui label learnReviewGroupLabel" :class="reviewGroup ? reviewGroupColors[reviewGroup] : 'noGroup'">
                            {{ reviewGroup || text.memoSetNoGroup }}</div></label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="learnButtons" v-if="questions.length">
                <button class="ui large green button actionButton" :class="{disabled: !activeQuestions.length || !learnEnabled}" @click="learnClick()"><i class="icon graduation cap"></i>{{ text.memoSetLearn }}</button>
                <div class="ui large teal button actionButton" :class="{disabled: !activeQuestions.length}" @click="learnTestClick()"><i class="icon clock"></i>{{ text.memoSetTest }}</div>
                <div class="ui large orange button actionButton" :class="{disabled: !activeQuestions.length}" @click="learnGameClick()"><i class="icon gamepad"></i>{{ text.memoSetGame }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <br>
    <div class="ui modal addCellAudio" :class="modalClass">
      <div class="ui header">
        {{ text.memoSetAddAudio }}
      </div>
      <div class="content">
        <div class="ui small active inline loader modalLoader" v-show="addCellAudio.loading"></div>
        <div v-show="!addCellAudio.loading">
          <div v-show="addCellAudio.url">
            <div class="ui active dimmer" v-show="addCellAudio.uploading">
              <div class="ui text loader">{{ text.memoSetUploadingAudio }}</div>
            </div>
            <audio id="addCellAudio" controls :src="addCellAudio.url" style="outline: none"></audio>
            <button class="ui primary button resetAudioButton" @click="initAddCellAudio()">{{ text.commonChange }}</button>
          </div>
          <div v-show="!addCellAudio.url">
            <label for="cellAudioFile">
              <div class="ui button">
                <i class="large file audio outline icon"></i>
                {{ text.memoSetSelectAudioFile }}
              </div>
            </label>
            <input type="file" accept="audio/*" id="cellAudioFile" @change="cellAudioFileChange($event.target.files[0])">
            <div class="ui horizontal divider">
              {{ text.commonOr }}
            </div>
            <div class="ui button" :class="{disabled: !audioRecordingAvailable}" v-show="!addCellAudio.recording" @click="startRecordingClick()">
              <i class="large microphone icon"></i>
              {{ text.memoSetStartRecording }}
            </div>
            <div class="audioNotSupported" v-if="!audioRecordingAvailable">{{ text.memoSetAudioRecordingNotSupported }}</div>
            <div class="ui blue button" v-show="addCellAudio.recording" @click="stopRecordingClick()">
              <i class="large microphone slash icon"></i>
              {{ text.memoSetStopRecording }}
            </div>
            <div class="ui red icon message" v-if="addCellAudio.microphoneError">
              <i class="exclamation triangle icon"></i>
              <div class="content">
                <div class="header">{{ text.commonError }}</div>
                <p>{{ text.memoSetUnableToAccessMicrophone }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="actions">
        <div class="ui primary ok button" :class="{disabled: !addCellAudio.url}">{{ text.commonOK }}</div>
        <div class="ui cancel button">{{ text.commonCancel}}</div>
      </div>
    </div>
    <div class="ui modal addCellImage" :class="[modalClass, {fullHeight: verticallyChallenged}, {standard: !verticallyChallenged}]">
      <div class="modalFlexContainer">
        <div class="ui header">
          {{ text.memoSetAddImage }}
        </div>
        <div class="content">
          <div class="ui small active inline loader modalLoader" v-show="addCellImage.loading"></div>
          <div v-show="!addCellImage.loading" class="height100">
            <div v-show="addCellImage.url" class="height100">
              <div class="ui active dimmer" v-show="addCellImage.uploading">
                <div class="ui text loader">{{ text.memoSetUploadingImage }}</div>
              </div>
              <img class="ui rounded image previewImage" id="addCellImage" :src="addCellImage.url" @load="cellImageLoad()">
              <button class="ui primary button resetImageButton" @click="initAddCellImage()">{{ text.commonChange }}</button>
            </div>
            <div v-show="!addCellImage.url">
              <label for="cellImageFile">
                <div class="ui button">
                  <i class="camera icon"></i>
                  {{ text.memoSetUploadTakePhoto }}
                </div>
              </label>
              <input type="file" accept="image/*" id="cellImageFile" @change="cellImageFileChange($event.target.files[0])">
              <div class="ui horizontal divider">
                {{ text.commonOr }}
              </div>
              <p>
                {{ text.memoSetCopyAnyImage1 }}
              </p>
              <p>
                {{ text.memoSetCopyAnyImage2 }}
              </p>
              <!-- Using textarea rather than div to enable 'Paste' in the context menu -->
              <textarea
                class="pasteImageHere"
                :placeholder="text.memoSetPasteImageHere"
                tabindex="0"
                @keypress.prevent
                @paste.prevent="cellImagePaste($event)"
              ></textarea>
            </div>
          </div>
        </div>
        <div class="actions">
          <div class="ui primary ok button" :class="{disabled: !addCellImage.url}">{{ text.commonOK }}</div>
          <div class="ui cancel button">{{ text.commonCancel}}</div>
        </div>
      </div>
    </div>
    <div class="ui modal addColumn" :class="modalClass">
      <div class="ui header">
        {{ text.memoSetAddColumn }}
      </div>
      <div class="content">
        <div class="ui grid" :class="{'mobile': mobile}">
          <!-- Column Heading -->
          <div class="modalLabel modalLabelPadding" :class="{'four wide column': !mobile, 'sixteen wide column': mobile}">
            <span>{{ text.memoSetColumnHeading }}</span>
          </div>
          <div class="noTopPadding" :class="{'twelve wide column': !mobile, 'sixteen wide column': mobile}">
            <div class="ui fluid input">
              <input type="text" v-model="addColumn.columnHeading" :placeholder="text.memoSetEnterNewColumnHeading">
            </div>
          </div>
          <!-- Column Position -->
          <div class="modalLabel modalLabelPadding" :class="{'four wide column': !mobile, 'sixteen wide column': mobile}" v-show="fields.length">
            <span>{{ text.memoSetColumnPosition }}</span>
          </div>
          <div class="noTopPadding" :class="{'twelve wide column': !mobile, 'sixteen wide column': mobile}" v-show="fields.length">
            <div class="ui fluid selection dropdown addColumnColumnPosition">
              <input type="hidden" :value="addColumn.columnPosition">
              <i class="dropdown icon"></i>
              <div class="default text"></div>
              <div class="menu">
                <div class="item" v-for="(field, index) in fields" :key="index" :data-value="index">
                  {{ index + 1 }}
                </div>
                <div class="item" :data-value="fields.length">{{ fields.length + 1}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="actions">
        <div class="ui primary ok button" :class="{disabled: !addColumn.columnHeading}">{{ text.commonAdd }}</div>
        <button class="ui cancel button">{{ text.commonCancel}}</button>
      </div>
    </div>
    <div class="ui modal addRows" :class="[modalClass, {fullHeight: verticallyChallenged}, {standard: !verticallyChallenged}]">
      <div class="modalFlexContainer">
        <div class="ui header">
          {{ text.memoSetAddRows }}
        </div>
        <div class="content">
          <div class="ui grid" :class="{'mobile': mobile}">
            <!-- Number of Rows -->
            <div class="modalLabel modalLabelPadding" :class="{'four wide column': !mobile, 'sixteen wide column': mobile}">
              <span>{{ text.memoSetNumberOfRows }}</span>
            </div>
            <div class="noTopPadding" :class="{'twelve wide column': !mobile, 'sixteen wide column': mobile}">
              <div class="ui fluid input">
                <input type="text" v-model="addRows.numberOfRows" autocomplete="off" :placeholder="text.memoSetNumberOfRowsPlaceholder">
              </div>
            </div>
            <!-- Insert After Row -->
            <div v-show="rows.length" class="modalLabel modalLabelPadding" :class="{'four wide column': !mobile, 'sixteen wide column': mobile}">
              <span>{{ text.memoSetInsertAfterRow }}</span>
            </div>
            <div v-show="rows.length" class="noTopPadding" :class="{'twelve wide column': !mobile, 'sixteen wide column': mobile}">
              <div class="ui fluid input">
                <input type="text" v-model="addRows.insertAfterRow"  :placeholder="insertAfterRowPlaceholder" @input="applyMathjaxToRowDataTables()">
              </div>
            </div>
            <!-- Insert After Row preview -->
            <div v-if="!mobile && parseInt(addRows.insertAfterRow, 10) > 0 && parseInt(addRows.insertAfterRow, 10) <= rows.length" class="four wide column">
            </div>
            <div v-if="parseInt(addRows.insertAfterRow, 10) > 0 && parseInt(addRows.insertAfterRow, 10) <= rows.length" class="noTopPadding" :class="{'twelve wide column': !mobile, 'sixteen wide column': mobile}">
              <div class="row noBottomPadding rowDataTableWrapper" :class="{'noTopPadding': mobile, 'thinScroll': mobile && touch}">
                <table v-if="fields" class="ui celled unstackable table rowDataTable">
                  <thead>
                    <tr class="middle aligned">
                      <th v-for="field in fields" :key="field.fieldId" class="two wide">
                        {{ field.heading }}
                      </th>
                      <th class="three wide">{{ text.memoSetNotes }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr class="middle aligned">
                      <td v-for="field in fields" :key="field.fieldId" :class="{cardsCell: cardStyle(rows[parseInt(addRows.insertAfterRow, 10) - 1].data[field.fieldId])}" :style="cardStyle(rows[parseInt(addRows.insertAfterRow, 10) - 1].data[field.fieldId])">
                        <div v-html="sanitize(rows[parseInt(addRows.insertAfterRow, 10) - 1].data[field.fieldId])"></div>
                      </td>
                      <!-- Notes -->
                      <td>
                        <div v-html="sanitize(rows[parseInt(addRows.insertAfterRow, 10) - 1].data.notes)"></div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div class="actions">
          <div class="ui primary ok button" :class="{disabled: !(parseInt(addRows.numberOfRows, 10) > 0)}">{{ text.commonAdd }}</div>
          <button class="ui cancel button">{{ text.commonCancel}}</button>
        </div>
      </div>
    </div>
    <div class="ui modal backgroundPhoto" :class="[modalClass, {fullHeight: verticallyChallenged}, {standard: !verticallyChallenged}]">
      <div class="modalFlexContainer">
        <div class="ui header noBottomBorder">
          {{ text.memoSetBackgroundPhoto }}
          <div class="ui secondary pointing menu backgroundPhotoTabs">
            <a class="header item" data-tab="backgroundPhotoSelect" v-show="modalPhotos.length">{{ text.memoSetSelectPhoto }}</a>
            <a class="header item" data-tab="backgroundPhotoAdd">{{ text.memoSetAddPhoto }}</a>
          </div>
        </div>
        <div class="content backgroundPhotoContent" :class="{'thinScroll': mobile && touch}">
          <div class="ui dimmer" :class="{active: addPhoto.uploading}" v-show="addPhoto.uploading">
            <div class="ui text loader">{{ text.memoSetUploadingImage }}</div>
          </div>
          <!-- start Select Photo -->
          <div class="ui tab basic segment noTopPadding" data-tab="backgroundPhotoSelect">
            <table class="ui selectable unstackable basic table">
              <tbody>
                <tr class="backgroundPhotoNoneRow" :class="{positive: selectedPhotoIndex === -1}" @click="selectedPhotoIndex = -1">
                  <td class="two wide center aligned">
                    <span class="tick" v-show="selectedPhotoIndex === -1">&checkmark;</span>
                  </td>
                  <td class="six wide center aligned">
                    <span class="backgroundPhotoNone">{{ text.memoSetNoPhoto }}</span>
                  </td>
                  <td></td>
                </tr>
                <tr v-for="(photo, photoIndex) in modalPhotos" :key="photo.id" :class="{positive: photoIndex === selectedPhotoIndex}" @click="selectedPhotoIndex = photoIndex">
                  <td class="two wide center aligned">
                    <span class="tick" v-show="photoIndex === selectedPhotoIndex">&checkmark;</span>
                  </td>
                  <td class="six wide center aligned">
                    <img
                      :src="photo.url"
                      style="border-radius: .3125em"
                      :style="{
                        height: photoThumbnailDimensions[photoIndex].height + 'px',
                        minWidth: photoThumbnailDimensions[photoIndex].width + 'px',
                        width: photoThumbnailDimensions[photoIndex].width + 'px'
                      }"
                    >
                  </td>
                  <td class="right aligned">
                    <div class="ui icon button" style="margin-right: 1rem" v-show="(!photoUsage[photo.id] || (photoUsage[photo.id].firstRowIndex === rowIndex && photoUsage[photo.id].lastRowIndex === rowIndex)) && selectedPhotoIndex !== photoIndex" @click.stop="deletePhotoClick(photoIndex)"><i class="trash alternate icon"></i></div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <!-- start Add Photo -->
          <div class="ui tab basic segment noBottomMargin noBottomPadding noTopMargin noTopPadding height100" data-tab="backgroundPhotoAdd">
            <div class="ui small active inline loader modalLoader" v-show="addPhoto.loading"></div>
            <div v-show="!addPhoto.loading" class="height100">
              <div v-show="addPhoto.url" class="height100">
                <img class="ui rounded image previewImage" id="addPhotoImage" :src="addPhoto.url" @load="photoLoad()">
                <button class="ui blue button resetImageButton" @click="initAddPhoto()">{{ text.commonChange }}</button>
              </div>
              <div v-show="!addPhoto.url">
                <label for="photoFile">
                  <div class="ui button selectPhotoFile">
                    <i class="camera icon"></i>
                    {{ text.memoSetUploadTakePhoto }}
                  </div>
                </label>
                <input type="file" accept="image/*" id="photoFile" @change="addPhotoFileChange($event.target.files[0])">
                <div class="ui horizontal divider">
                  {{ text.commonOr }}
                </div>
                <p>
                  {{ text.memoSetCopyAnyImage1 }}
                </p>
                <p>
                  {{ text.memoSetCopyAnyImage2 }}
                </p>
                <!-- Using textarea rather than div to enable 'Paste' in the context menu -->
                <textarea
                  class="pasteImageHere"
                  :placeholder="text.memoSetPasteImageHere"
                  tabindex="0"
                  @keypress.prevent
                  @paste.prevent="photoPaste($event)"
                ></textarea>
              </div>
            </div>
          </div>
        </div>
        <div class="actions">
          <div class="subsequentRowsWrapper" :class="{mobile: mobile}">
            <span>{{ text.memoSetApplyToSubsequentRowsLeft }}</span>
            <div class="ui input subsequentRowsInput">
              <input type="text" v-model="applyToRows" autocomplete="off">
            </div>
            <span>{{ text.memoSetApplyToSubsequentRowsRight }}</span>
          </div>
          <div class="ui primary ok button" :class="{disabled: backgroundPhotoTab === 'backgroundPhotoAdd' && !addPhoto.url}">{{ text.commonOK }}</div>
          <button class="ui cancel button">{{ text.commonCancel}}</button>
        </div>
      </div>
    </div>
    <div class="ui modal copyDown" :class="modalClass">
      <div class="ui header">
        {{ text.memoSetCopyDown }}
      </div>
      <div class="content" v-if="modal === 'copyDown'">
        <div class="ui compact message">
          <div class="copyDownDisplayValue" v-html="copyDown.displayValue" v-show="copyDown.displayValue"></div>
          <div class="copyDownDisplayValue" v-show="!copyDown.displayValue">{{ text.memoSetNoValue }}</div>
        </div>
        <div style="margin-top: 1rem">
          <span>{{ text.memoSetApplyToSubsequentRowsLeft }}</span>
          <div class="ui input subsequentRowsInput">
            <input type="text" v-model="applyToRows" autocomplete="off">
          </div>
          <span>{{ text.memoSetApplyToSubsequentRowsRight }}</span>
        </div>
      </div>
      <div class="actions">
        <div class="ui primary ok button" :class="{disabled: !applyToRows || !(parseInt(applyToRows, 10) >= 1)}">{{ text.commonOK }}</div>
        <button class="ui cancel button">{{ text.commonCancel}}</button>
      </div>
    </div>
    <div class="ui modal copyMemoSet" :class="modalClass">
      <div class="ui header">
        <span v-if="memoSetType === 'regular'">{{ text.memoSetCopyMemoSet }}</span>
        <span v-if="memoSetType !== 'regular'">{{ text.memoSetAddToMyMemoSets }}</span>
      </div>
      <div class="content">
        <div class="ui grid" :class="{'mobile': mobile}">
          <!-- Title -->
          <div class="modalLabel modalLabelPadding" :class="{'four wide column': !mobile, 'sixteen wide column': mobile}">
            <span>{{ text.memoSetTitle }}</span>
          </div>
          <div class="noTopPadding" :class="{'twelve wide column': !mobile, 'sixteen wide column': mobile}">
            <div class="ui fluid input">
              <input type="text" v-model="copyMemoSet.newTitle" autocomplete="off" :placeholder="text.memoSetEnterNewTitle">
            </div>
          </div>
          <!-- Folder -->
          <div class="modalLabel modalLabelPadding" :class="{'four wide column': !mobile, 'sixteen wide column': mobile}">
            <span>{{ text.memoSetFolder }}</span>
          </div>
          <div class="noTopPadding" :class="{'twelve wide column': !mobile, 'sixteen wide column': mobile}">
            <div class="ui fluid selection dropdown copyMemoSetFolderId">
              <input type="hidden">
              <i class="dropdown icon"></i>
              <div class="default text">{{ text.memoSetSelectAFolderForTheMemoSet }}</div>
              <div class="menu">
                <div class="item" v-for="folder in user.folders" :key="folder.folderId" :data-value="folder.folderId">
                  {{ folder.folderName }}
                </div>
                <div class="item" data-value="new">
                  <span>{{ text.memoSetNewFolderItem }}</span>
                </div>
              </div>
            </div>
          </div>
          <!-- New Folder Name -->
          <div class="modalLabel modalLabelPadding" :class="{'four wide column': !mobile, 'sixteen wide column': mobile, visibilityHidden: copyMemoSet.folderId !== 'new'}">
            <span>{{ text.memoSetNewFolderLabel }}</span>
          </div>
          <div class="noTopPadding" :class="{'twelve wide column': !mobile, 'sixteen wide column': mobile, visibilityHidden: copyMemoSet.folderId !== 'new'}">
            <div class="ui fluid input">
              <input id="newFolderName" type="text" v-model="copyMemoSet.newFolderName" autocomplete="off" :placeholder="text.memoSetEnterNewFolderName">
            </div>
          </div>
        </div>
      </div>
      <div class="actions">
        <div class="ui primary ok button" :class="{disabled: !copyMemoSet.newTitle || copyMemoSet.folderId === '' || (copyMemoSet.folderId === 'new' && !copyMemoSet.newFolderName)}"><span v-show="memoSetType === 'regular'">{{ text.commonCopy }}</span><span v-show="memoSetType !== 'regular'">{{ text.commonAdd }}</span></div>
        <div class="ui cancel button">{{ text.commonCancel}}</div>
      </div>
    </div>
    <div class="ui modal deleteColumn" :class="modalClass">
      <div class="ui header">
        {{ text.memoSetDeleteColumn }}
      </div>
      <div class="content" v-if="fields">
        <div class="ui grid" :class="{'mobile': mobile}">
          <div class="modalLabel modalLabelPadding" :class="{'four wide column': !mobile, 'sixteen wide column': mobile}">
            <span>{{ text.memoSetDeleteColumn }}</span>
          </div>
          <div class="noTopPadding" :class="{'twelve wide column': !mobile, 'sixteen wide column': mobile}">
            <div class="ui fluid selection dropdown deleteColumnDeleteColumn">
              <input type="hidden">
              <i class="dropdown icon"></i>
              <div class="default text">{{ text.memoSetSelectColumnToDelete }}</div>
              <div class="menu">
                <div class="item" v-for="(field, index) in fields" :key="index" :data-value="index">
                  {{ field.heading }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="actions">
        <div class="ui red ok button" :class="{disabled: deleteColumn.deleteColumn === '-1'}">{{ text.commonDelete }}</div>
        <button class="ui cancel button">{{ text.commonCancel}}</button>
      </div>
    </div>
    <div class="ui small modal deleteGameResult" :class="modalClass">
      <div class="ui header">
        {{ text.memoSetDeleteGameResult }}
      </div>
      <div class="content">
        <p>{{ text.memoSetDeleteGameResultQuestion }}</p>
      </div>
      <div class="actions">
        <div class="ui red ok button">{{ text.commonDelete }}</div>
        <button class="ui cancel button">{{ text.commonCancel}}</button>
      </div>
    </div>
    <div class="ui modal deleteMemoSet" :class="modalClass">
      <div class="ui header">
        {{ text.memoSetDeleteMemoSet }}
      </div>
      <div class="content">
        <div v-html="modalHtml"></div>
      </div>
      <div class="actions">
        <div class="ui red ok button">{{ text.commonDelete }}</div>
        <button class="ui cancel button">{{ text.commonCancel}}</button>
      </div>
    </div>
    <div class="ui modal deleteRows" :class="[modalClass, {fullHeight: verticallyChallenged}, {standard: !verticallyChallenged}]">
      <div class="modalFlexContainer">
        <div class="ui header">
          {{ text.memoSetDeleteRows }}
        </div>
        <div class="content">
          <div class="ui grid" :class="{'mobile': mobile}">
            <!-- Delete From Row -->
            <div v-show="rows.length" class="modalLabel modalLabelPadding" :class="{'four wide column': !mobile, 'sixteen wide column': mobile}">
              <span>{{ text.memoSetDeleteFromRow }}</span>
            </div>
            <div class="noTopPadding" :class="{'twelve wide column': !mobile, 'sixteen wide column': mobile}">
              <div class="ui fluid input">
                <input type="text" v-model="deleteRows.deleteFromRow" :placeholder="enterRowNumberPlaceholder" @input="applyMathjaxToRowDataTables()">
              </div>
            </div>
            <!-- Delete From Row error message -->
            <div v-show="parseInt(deleteRows.deleteFromRow, 10) > rows.length && !mobile" class="noTopPadding four wide column"></div>
            <div v-show="parseInt(deleteRows.deleteFromRow, 10) > rows.length" class="noTopPadding" :class="{'twelve wide column': !mobile, 'sixteen wide column': mobile}">
              <div class="ui orange message standardColor">
                {{ invalidRowNumberMessage }}
              </div>
            </div>
            <!-- Delete From Row preview -->
            <div v-if="!mobile && parseInt(deleteRows.deleteFromRow, 10) > 0 && parseInt(deleteRows.deleteFromRow, 10) <= rows.length" class="four wide column">
            </div>
            <div v-if="parseInt(deleteRows.deleteFromRow, 10) > 0 && parseInt(deleteRows.deleteFromRow, 10) <= rows.length" class="noTopPadding" :class="{'twelve wide column': !mobile, 'sixteen wide column': mobile}">
              <div class="row noBottomPadding rowDataTableWrapper" :class="{'noTopPadding': mobile, 'thinScroll': mobile && touch}">
                <table v-if="fields" class="ui celled unstackable table rowDataTable">
                  <thead>
                    <tr class="middle aligned">
                      <th v-for="field in fields" :key="field.fieldId" class="two wide">
                        {{ field.heading }}
                      </th>
                      <th class="three wide">{{ text.memoSetNotes }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr class="middle aligned">
                      <td v-for="field in fields" :key="field.fieldId" :class="{cardsCell: cardStyle(rows[parseInt(deleteRows.deleteFromRow, 10) - 1].data[field.fieldId])}" :style="cardStyle(rows[parseInt(deleteRows.deleteFromRow, 10) - 1].data[field.fieldId])">
                        <div v-html="sanitize(rows[parseInt(deleteRows.deleteFromRow, 10) - 1].data[field.fieldId])"></div>
                      </td>
                      <!-- Notes -->
                      <td>
                        <div v-html="sanitize(rows[parseInt(deleteRows.deleteFromRow, 10) - 1].data.notes)"></div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <!-- Delete To Row -->
            <div v-show="rows.length" class="modalLabel modalLabelPadding" :class="{'four wide column': !mobile, 'sixteen wide column': mobile}">
              <span>{{ text.memoSetDeleteToRow }}</span>
            </div>
            <div v-show="rows.length" class="noTopPadding" :class="{'twelve wide column': !mobile, 'sixteen wide column': mobile}">
              <div class="ui fluid input">
                <input type="text" v-model="deleteRows.deleteToRow" :placeholder="deleteToRowPlaceholder">
              </div>
            </div>
            <!-- Delete To Row error message -->
            <div v-show="parseInt(deleteRows.deleteToRow, 10) > rows.length && !mobile" class="noTopPadding four wide column"></div>
            <div v-show="parseInt(deleteRows.deleteToRow, 10) > rows.length" class="noTopPadding" :class="{'twelve wide column': !mobile, 'sixteen wide column': mobile}">
              <div class="ui orange message standardColor">
                {{ invalidRowNumberMessage }}
              </div>
            </div>
            <!-- Delete To Row preview -->
            <div v-if="!mobile && parseInt(deleteRows.deleteToRow, 10) > 0 && parseInt(deleteRows.deleteToRow, 10) <= rows.length" class="four wide column">
            </div>
            <div v-if="parseInt(deleteRows.deleteToRow, 10) > 0 && parseInt(deleteRows.deleteToRow, 10) <= rows.length" class="noTopPadding" :class="{'twelve wide column': !mobile, 'sixteen wide column': mobile}">
              <div class="row noBottomPadding rowDataTableWrapper" :class="{'noTopPadding': mobile, 'thinScroll': mobile && touch}">
                <table v-if="fields" class="ui celled unstackable table rowDataTable">
                  <thead>
                    <tr class="middle aligned">
                      <th v-for="field in fields" :key="field.fieldId" class="two wide">
                        {{ field.heading }}
                      </th>
                      <th class="three wide">{{ text.memoSetNotes }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr class="middle aligned">
                      <td v-for="field in fields" :key="field.fieldId" :class="{cardsCell: cardStyle(rows[parseInt(deleteRows.deleteToRow, 10) - 1].data[field.fieldId])}" :style="cardStyle(rows[parseInt(deleteRows.deleteToRow, 10) - 1].data[field.fieldId])">
                        <div v-html="sanitize(rows[parseInt(deleteRows.deleteToRow, 10) - 1].data[field.fieldId])"></div>
                      </td>
                      <!-- Notes -->
                      <td>
                        <div v-html="sanitize(rows[parseInt(deleteRows.deleteToRow, 10) - 1].data.notes)"></div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div class="actions">
          <div class="ui red ok button" :class="{disabled: !deleteRowsValid}">{{ text.commonDelete }}</div>
          <button class="ui cancel button">{{ text.commonCancel}}</button>
        </div>
      </div>
    </div>
    <div class="ui small modal deleteTestResult" :class="modalClass">
      <div class="ui header">
        {{ text.memoSetDeleteTestResult }}
      </div>
      <div class="content">
        <p>{{ text.memoSetDeleteTestResultQuestion }}</p>
      </div>
      <div class="actions">
        <div class="ui red ok button">{{ text.commonDelete }}</div>
        <button class="ui cancel button">{{ text.commonCancel}}</button>
      </div>
    </div>
    <div class="ui modal exportData" :class="modalClass">
      <div class="ui header" v-if="exported">
        <i class="green check icon"></i>{{ text.memoSetExportSuccessful }}
      </div>
      <div class="ui header" v-if="!exported">
        <i class="red close icon"></i>{{ text.memoSetExportUnsuccessful }}
      </div>
      <div class="content">
        <p v-if="exported">{{ text.memoSetExportSuccessfulDetail }}</p>
        <p v-if="!exported">{{ text.memoSetExportUnsuccessfulDetail }}</p>
      </div>
      <div class="actions">
        <div class="ui primary ok button">{{ text.commonOK }}</div>
      </div>
    </div>
    <div class="ui modal invalidRowNumber" :class="modalClass">
      <div class="ui header">
        {{ text.memoSetInvalidRowNumber }}
      </div>
      <div class="content">
        {{ invalidRowNumberMessage }}
      </div>
      <div class="actions">
        <div class="ui primary ok button">{{ text.commonOK }}</div>
      </div>
    </div>
    <div class="ui modal learn" :class="[modalClass, {fullHeight: verticallyChallenged}, {standard: !verticallyChallenged}]">
      <div class="modalFlexContainer" v-if="modal === 'learn'">
        <div class="ui header">
          <span>{{ text.memoSetLearn }}</span>
          <button class="ui compact icon button closeLearnButton" @click="closeLearnModal()">
            <i class="close icon"></i>
          </button>
        </div>
        <div class="content learnContent" :class="{'thinScroll': mobile && touch}">
          <div class="ui green progress learnProgress" style="margin-bottom: 1.5rem">
            <div class="bar"></div>
          </div>
          <div class="ui green icon message learnMessage" v-show="learn.congratsMessage">
            <i class="green checkmark box icon"></i>
            <div class="content">{{ learn.congratsMessage }}</div>
          </div>
          <div v-if="!learn.congratsMessage">
            <div class="ui small active inline loader" v-show="!learn.reviewQueue || !learn.reviewQueue[0] || !learn.reviewQueue[0].ready"></div>
            <div v-if="learn.reviewQueue && learn.reviewQueue[0] && learn.reviewQueue[0].ready">
              <div class="learnQuestionHtml" v-html="learnQuestionHtml" v-show="!learn.congratsMessage"></div>
              <div v-if="learn.answerVisible">
                <div class="ui divider learnDivider"></div>
                <div class="ui compact green message answerMessage">
                  <div class="learnAnswerHtml" v-html="learnAnswerHtml"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="actions">
          <button class="ui right floated primary button" :class="{'right labeled icon': !mobile, 'disabled': !learn.reviewQueue || !learn.reviewQueue[0] || !learn.reviewQueue[0].ready}" v-show="!learn.congratsMessage && !learn.answerVisible" @click="showAnswerClick()">
            <i class="right arrow icon" v-if="!mobile"></i>
            {{ text.memoSetShowAnswer }}
          </button>
          <button class="ui right floated green button correctButton" :class="{'right labeled icon': !mobile}" v-show="!learn.congratsMessage && learn.answerVisible" @click="answerClick('correct')">
            <i class="up arrow icon" v-if="!mobile"></i>
            {{ text.memoSetCorrect }}
          </button>
          <button class="ui right floated red button incorrectButton" :class="{'right labeled icon': !mobile}" v-show="!learn.congratsMessage && learn.answerVisible" @click="answerClick('incorrect')">
            <i class="down arrow icon" v-if="!mobile"></i>
            {{ text.memoSetIncorrect }}
          </button>
          <button class="ui right floated primary button" v-show="learn.congratsMessage" @click="closeLearnModal()">
            {{ text.commonClose }}
          </button>
          <button class="ui right floated button" :class="{'left labeled icon': !mobile}" v-show="!learn.answerVisible && learn.previousReviewItem" @click="oopsClick()">
            <i class="left arrow icon" v-if="!mobile"></i>
            {{ text.memoSetOops }}
          </button>
        </div>
      </div>
    </div>
    <div class="ui modal learnGame" :class="[modalClass, {fullHeight: verticallyChallenged}, {standard: !verticallyChallenged}, {mobile: mobile}]">
      <div class="modalFlexContainer" v-if="modal === 'learnGame'">
        <div class="ui header">
          <span>{{ text.memoSetGame }}</span>
          <button class="ui compact icon button closeLearnButton" @click="closeLearnGameModal()">
            <i class="close icon"></i>
          </button>
        </div>
        <div
          id="gameArena"
          class="content learnContent"
          :class="{'thinScroll': mobile && touch}"
          style="overflow-x: hidden"
          :style="{overflowY: learn.gaming ? 'hidden' : 'auto'}"
        >
          <!-- Instructions -->
          <div v-show="!learn.gaming && !learn.gameCompleted">
            <p style="margin-top: 0.5rem">Answer the questions before they reach the bottom of the screen!</p>
          </div>
          <!-- Score/lives -->
          <div v-show="learn.gaming" class="ui grid">
            <div class="middle aligned row noTopPadding noBottomPadding">
              <div class="six wide column">
                <span class="gameLabel" :class="{mobile: mobile}">Score:</span>
                <span class="gameScore" :class="{mobile: mobile}">{{ learn.gameScore }}</span>
              </div>
              <div class="ten wide right aligned column">
                <span class="gameLabel" :class="{mobile: mobile}">Lives:</span>
                <div class="ui mini images gameLifeWrapper">
                  <img class="ui image gameLife" src="/brain.jpg" :class="{gameItemHidden: learn.gameLives < 1, mobile: mobile}">
                  <img class="ui image gameLife" src="/brain.jpg" :class="{gameItemHidden: learn.gameLives < 2, mobile: mobile}">
                  <img class="ui image gameLife" src="/brain.jpg" :class="{gameItemHidden: learn.gameLives < 3, mobile: mobile}">
                </div>
              </div>
            </div>
          </div>
          <!-- Start game button -->
          <button
            v-if="!learn.gaming && !learn.gameCompleted"
            class="ui compact orange button"
            style="margin-top: 2rem"
            @click="startGame()"
          >
            <span class="alignButtonLabel">Start</span><i class="large right arrow icon"></i>
          </button>
          <!-- Game results -->
          <div class="ui orange message learnMessage" v-if="learn.gameCompleted">
            <h2>Results</h2>
            <div class="ui one statistics" :class="{'tiny': mobile, 'small': !mobile}">
              <div class="green statistic">
                <div class="value">
                  {{ learn.gameScore }}
                </div>
                <div class="label">
                  Score
                </div>
              </div>
            </div>
          </div>
          <!-- Incorrect answers -->
          <div v-show="learn.gameCompleted && learn.incorrectAnswers.length">
            <h4 class="ui header learnModalHeader">Incorrect Answers</h4>
            <table class="ui unstackable table learnTable">
              <thead>
                <tr>
                  <th>Question</th>
                  <th>Answer</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(incorrectAnswer, index) in learn.incorrectAnswers" :key="index">
                  <td class="incorrectAnswersMathjax" v-html="incorrectAnswer.questionHtml"></td>
                  <td class="incorrectAnswersMathjax" v-html="incorrectAnswer.answerHtml"></td>
                </tr>
              </tbody>
            </table>
          </div>
          <!-- Previous results -->
          <div v-show="!learn.gaming && learn.gameResults.length">
            <h4 class="ui header learnModalHeader">Previous Results</h4>
            <table class="ui compact unstackable table learnTable">
              <thead>
                <tr>
                  <th>Date</th>
                  <th colspan="2">Score</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(gameResult, gameResultIndex) in learn.gameResults" :key="gameResult.timestamp">
                  <td>{{ gameResult.date }}</td>
                  <td>{{ gameResult.score }}</td>
                  <td class="right aligned">
                    <div class="ui compact icon button" style="margin-right: 1rem" @click="deleteGameResult(gameResult, gameResultIndex)"><i class="trash alternate icon"></i></div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <!-- Game -->
          <div v-show="learn.gaming">
            <div
              id="gameFallWrapper"
              class="gameFallWrapper"
              v-if="learn.gameQuestionExists"
              @animationend="gameFallEnd()"
            >
              <div
                class="gameSlowFallWrapper"
                :class="{ gameItemHidden: learn.gameFade }"
                @animationend.stop
              >
                <div
                  id="gameQuestion"
                  class="learnQuestionHtml gameQuestion gameMathjax"
                  v-html="learnQuestionHtml"
                  @animationend.stop
                ></div>
              </div>
            </div>
          </div>
        </div>
        <div class="actions thinScroll" style="overflow-y: auto">
          <button class="ui right floated primary button" @click="closeLearnGameModal()" v-show="!learn.gaming">
            {{ text.commonClose }}
          </button>
          <div class="ui buttons gameButtons" v-if="learn.gaming && learn.gameQuestionExists && !(mobile && learn.gameButtons.length === 4)">
            <button
              v-for="(gameButton, index) in learn.gameButtons"
              :id="'gameButton' + index"
              class="ui button gameButton gameMathjax"
              :class="{ green: (learn.gameButtonClicked > -1 || learn.fallEnded) && gameButton.isCorrect, red: index === learn.gameButtonClicked && !gameButton.isCorrect, gameItemHidden: learn.gameFade }"
              v-show="learn.gaming"
              v-html="gameButton.answerHtml"
              :key="index"
              @click="gameAnswerClick(index, gameButton.isCorrect)"
            ></button>
          </div>
          <div class="ui buttons gameButtonsRow1" v-if="learn.gaming && learn.gameQuestionExists && mobile && learn.gameButtons.length === 4">
            <button
              v-for="(gameButton, index) in gameButtonsRow1"
              :id="'gameButton' + index"
              class="ui button gameButton gameMathjax"
              :class="{ green: (learn.gameButtonClicked > -1 || learn.fallEnded) && gameButton.isCorrect, red: index === learn.gameButtonClicked && !gameButton.isCorrect, gameItemHidden: learn.gameFade }"
              v-show="learn.gaming"
              v-html="gameButton.answerHtml"
              :key="index"
              @click="gameAnswerClick(index, gameButton.isCorrect)"
            ></button>
          </div>
          <div class="ui buttons gameButtonsRow2" v-if="learn.gaming && learn.gameQuestionExists && mobile && learn.gameButtons.length === 4">
            <button
              v-for="(gameButton, index) in gameButtonsRow2"
              :id="'gameButton' + (index + 2)"
              class="ui button gameButton gameMathjax"
              :class="{ green: (learn.gameButtonClicked > -1 || learn.fallEnded) && gameButton.isCorrect, red: index + 2 === learn.gameButtonClicked && !gameButton.isCorrect, gameItemHidden: learn.gameFade }"
              v-show="learn.gaming"
              v-html="gameButton.answerHtml"
              :key="index + 2"
              @click="gameAnswerClick(index + 2, gameButton.isCorrect)"
            ></button>
          </div>
        </div>
      </div>
    </div>
    <div class="ui modal learnTest" :class="[modalClass, {fullHeight: verticallyChallenged}, {standard: !verticallyChallenged}]">
      <div class="modalFlexContainer" v-if="modal === 'learnTest'">
        <div class="ui header">
          <span>{{ text.memoSetTest }}</span>
          <button class="ui compact icon button closeLearnButton" @click="closeLearnTestModal()">
            <i class="close icon"></i>
          </button>
        </div>
        <div class="content learnContent" :class="{'thinScroll': mobile && touch}">
          <div v-show="!learn.testing && !learn.testCompleted">
            <p style="margin-top: 0.5rem">{{ text.memoSetTestInstructions }}</p>
            <h5 class="ui top attached header" style="background-color: whitesmoke; margin-top: 2rem; padding-top: 0.6rem; padding-bottom: 0.6rem">
              Options
            </h5>
            <div class="ui attached segment" style="padding-left: 2rem; padding-top: 1.2rem">
              <!-- Random order -->
              <div class="ui checkbox randomOrder" style="margin-bottom: 0.6rem">
                <input type="checkbox" name="testRandom" v-model="learn.testRandom">
                <label style="display: inline-block; font-weight: bold">Random order</label>
              </div>
              <br>
              <!-- Number of questions -->
              <span style="font-weight: bold; margin-right: 1rem">{{ text.memoSetTestNumberOfQuestions }}:</span>
              <div class="noTopPadding" style="display: inline-block; margin-right: 0.5rem">
                <div class="ui input" style="width: 4rem">
                  <input type="text" v-model="learn.testItems" autocomplete="off" style="padding-left: 0.6em; padding-right: 0.4em">
                </div>
              </div>
              <div :class="{'ui orange message standardColor': parseInt(learn.testItems, 10) > learn.reviewQueue.length}" xv-show="parseInt(learn.testItems, 10) > learn.reviewQueue.length" style="color: darkgray; display: inline-block; margin-top: 0; padding: 0.5em 1.5em">{{ testMaximumMessage }}</div>
            </div>
          </div>
          <div class="ui teal progress testProgress" v-show="learn.testing || learn.testCompleted" style="margin-bottom: 1.5rem">
            <div class="bar"></div>
          </div>
          <!-- Test results -->
          <div class="ui teal message learnMessage" v-if="learn.testCompleted">
            <h2>Results</h2>
            <div class="ui three statistics" :class="{'tiny': mobile, 'small': !mobile}">
              <div class="green statistic">
                <div class="value">
                  {{ learn.testScore }}
                </div>
                <div class="label">
                  {{ text.memoSetCorrect }}
                </div>
              </div>
              <div class="red statistic">
                <div class="value">
                  {{ learn.testTotal - learn.testScore }}
                </div>
                <div class="label">
                  {{ text.memoSetIncorrect }}
                </div>
              </div>
              <div class="statistic">
                <div class="value">
                  {{ learn.testDuration }}
                </div>
                <div class="label">
                  Time
                </div>
              </div>
            </div>
          </div>
          <!-- Incorrect answers -->
          <div v-show="learn.testCompleted && learn.incorrectAnswers.length">
            <h4 class="ui header learnModalHeader">Incorrect Answers</h4>
            <table class="ui unstackable table learnTable">
              <thead>
                <tr>
                  <th>Question</th>
                  <th>Answer</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(incorrectAnswer, index) in learn.incorrectAnswers" :key="index">
                  <td class="incorrectAnswersMathjax" v-html="incorrectAnswer.questionHtml"></td>
                  <td class="incorrectAnswersMathjax" v-html="incorrectAnswer.answerHtml"></td>
                </tr>
              </tbody>
            </table>
          </div>
          <!-- Start test button -->
          <button
            v-if="!learn.testing && !learn.testCompleted"
            class="ui compact teal button"
            :class="{disabled: !parseInt(learn.testItems, 10) || parseInt(learn.testItems, 10) > learn.reviewQueue.length}"
            style="margin-top: 2rem"
            @click="startTest()"
          >
            <span class="alignButtonLabel">Start</span><i class="large right arrow icon"></i>
          </button>
          <!-- Previous results -->
          <div v-show="!learn.testing && learn.testResults.length">
            <h4 class="ui header learnModalHeader">Previous Results</h4>
            <table class="ui compact unstackable table learnTable">
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Score</th>
                  <th colspan="2">Time</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(testResult, testResultIndex) in learn.testResults" :key="testResult.timestamp">
                  <td>{{ testResult.date }}</td>
                  <td>{{ testResult.score }}/{{ testResult.total }}</td>
                  <td>{{ testResult.duration }}</td>
                  <td class="right aligned">
                    <div class="ui compact icon button" style="margin-right: 1rem" @click="deleteTestResult(testResult, testResultIndex)"><i class="trash alternate icon"></i></div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div v-if="learn.testing && !learn.testCompleted">
            <div class="ui small active inline loader" v-show="!learn.reviewQueue || !learn.reviewQueue[0] || !learn.reviewQueue[0].ready"></div>
            <div v-if="learn.reviewQueue && learn.reviewQueue[0] && learn.reviewQueue[0].ready">
              <div class="learnQuestionHtml" v-html="learnQuestionHtml"></div>
              <div v-if="learn.answerVisible">
                <div class="ui divider learnDivider"></div>
                <div class="ui compact green message answerMessage">
                  <div class="learnAnswerHtml" v-html="learnAnswerHtml"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="actions">
          <button class="ui right floated primary button" :class="{'right labeled icon': !mobile, 'disabled': !learn.reviewQueue || !learn.reviewQueue[0] || !learn.reviewQueue[0].ready}" v-show="learn.testing && !learn.answerVisible" @click="showAnswerClick()">
            <i class="right arrow icon" v-if="!mobile"></i>
            {{ text.memoSetShowAnswer }}
          </button>
          <button class="ui right floated green button correctButton" :class="{'right labeled icon': !mobile}" v-show="learn.answerVisible" @click="answerClick('correct')">
            <i class="up arrow icon" v-if="!mobile"></i>
            {{ text.memoSetCorrect }}
          </button>
          <button class="ui right floated red button incorrectButton" :class="{'right labeled icon': !mobile}" v-show="learn.answerVisible" @click="answerClick('incorrect')">
            <i class="down arrow icon" v-if="!mobile"></i>
            {{ text.memoSetIncorrect }}
          </button>
          <button class="ui right floated primary button" v-show="!learn.testing" @click="closeLearnTestModal()">
            {{ text.commonClose }}
          </button>
          <button class="ui right floated button" :class="{'left labeled icon': !mobile}" v-show="!learn.answerVisible && learn.previousReviewItem" @click="oopsClick()">
            <i class="left arrow icon" v-if="!mobile"></i>
            {{ text.memoSetOops }}
          </button>
        </div>
      </div>
    </div>
    <div class="ui modal moveColumn" :class="modalClass">
      <div class="ui header">
        {{ text.memoSetMoveColumn }}
      </div>
      <div class="content">
        <div class="ui grid" :class="{'mobile': mobile}">
          <div class="modalLabel modalLabelPadding" :class="{'four wide column': !mobile, 'sixteen wide column': mobile}">
            <span>Column</span>
          </div>
          <div class="noTopPadding" :class="{'twelve wide column': !mobile, 'sixteen wide column': mobile}">
            <div class="ui fluid selection dropdown moveColumnColumnSeq">
              <input type="hidden">
              <i class="dropdown icon"></i>
              <div class="default text">{{ text.memoSetSelectTheColumnToBeMoved }}</div>
              <div class="menu">
                <div class="item" v-for="(field, index) in fields" :key="index" :data-value="index">
                  {{ field.heading }}
                </div>
              </div>
            </div>
          </div>
          <div class="modalLabel modalLabelPadding" :class="{'four wide column': !mobile, 'sixteen wide column': mobile}">
            <span>{{ text.memoSetPosition }}</span>
          </div>
          <div class="noTopPadding" :class="{'twelve wide column': !mobile, 'sixteen wide column': mobile}">
            <div class="ui fluid selection dropdown moveColumnPosition">
              <input type="hidden">
              <i class="dropdown icon"></i>
              <div class="default text">{{ text.memoSetSelectTheNewPositionForTheColumn }}</div>
              <div class="menu">
                <div class="item" v-for="(field, index) in fields" :key="index" :data-value="index + 1">
                  {{ index + 1 }}<span v-show="index === parseInt(moveColumn.columnSeq, 10)" class="currentPosition">{{ text.memoSetCurrentPosition }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="actions">
        <div class="ui primary ok button" :class="{disabled: moveColumn.columnSeq === '-1' || moveColumn.position === '-1'}">{{ text.memoSetMove }}</div>
        <div class="ui cancel button">{{ text.commonCancel}}</div>
      </div>
    </div>
    <div class="ui modal moveRows" :class="[modalClass, {fullHeight: verticallyChallenged}, {standard: !verticallyChallenged}]">
      <div class="modalFlexContainer">
        <div class="ui header">
          {{ text.memoSetMoveRows }}
        </div>
        <div class="content" :class="{thinScroll: mobile && touch}">
          <div class="ui grid" :class="{'mobile': mobile}">
            <!-- First Row -->
            <div class="modalLabel modalLabelPadding" :class="{'four wide column': !mobile, 'sixteen wide column': mobile}">
              <span>{{ text.memoSetFirstRow }}</span>
            </div>
            <div class="noTopPadding" :class="{'twelve wide column': !mobile, 'sixteen wide column': mobile}">
              <div class="ui fluid input">
                <input type="text" v-model="moveRows.firstRow" :placeholder="enterRowNumberPlaceholder" @input="applyMathjaxToRowDataTables()">
              </div>
            </div>
            <!-- First Row preview -->
            <div v-if="!mobile && parseInt(moveRows.firstRow, 10) > 0 && parseInt(moveRows.firstRow, 10) <= rows.length" class="four wide column">
            </div>
            <div v-if="parseInt(moveRows.firstRow, 10) > 0 && parseInt(moveRows.firstRow, 10) <= rows.length" class="noTopPadding" :class="{'twelve wide column': !mobile, 'sixteen wide column': mobile}">
              <div class="row noBottomPadding rowDataTableWrapper" :class="{'noTopPadding': mobile, 'thinScroll': mobile && touch}">
                <table v-if="fields" class="ui celled unstackable table rowDataTable">
                  <thead>
                    <tr class="middle aligned">
                      <th v-for="field in fields" :key="field.fieldId" class="two wide">
                        {{ field.heading }}
                      </th>
                      <th class="three wide">{{ text.memoSetNotes }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr class="middle aligned">
                      <td v-for="field in fields" :key="field.fieldId" :class="{cardsCell: cardStyle(rows[parseInt(moveRows.firstRow, 10) - 1].data[field.fieldId])}" :style="cardStyle(rows[parseInt(moveRows.firstRow, 10) - 1].data[field.fieldId])">
                        <div v-html="sanitize(rows[parseInt(moveRows.firstRow, 10) - 1].data[field.fieldId])"></div>
                      </td>
                      <!-- Notes -->
                      <td>
                        <div v-html="sanitize(rows[parseInt(moveRows.firstRow, 10) - 1].data.notes)"></div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <!-- Last Row -->
            <div class="modalLabel modalLabelPadding" :class="{'four wide column': !mobile, 'sixteen wide column': mobile}">
              <span>{{ text.memoSetLastRow }}</span>
            </div>
            <div class="noTopPadding" :class="{'twelve wide column': !mobile, 'sixteen wide column': mobile}">
              <div class="ui fluid input">
                <input type="text" v-model="moveRows.lastRow" :placeholder="moveRowsLastRowPlaceholder" @input="applyMathjaxToRowDataTables()">
              </div>
            </div>
            <!-- Last Row preview -->
            <div v-if="!mobile && parseInt(moveRows.lastRow, 10) > 0 && parseInt(moveRows.lastRow, 10) <= rows.length" class="four wide column">
            </div>
            <div v-if="parseInt(moveRows.lastRow, 10) > 0 && parseInt(moveRows.lastRow, 10) <= rows.length" class="noTopPadding" :class="{'twelve wide column': !mobile, 'sixteen wide column': mobile}">
              <div class="row noBottomPadding rowDataTableWrapper" :class="{'noTopPadding': mobile, 'thinScroll': mobile && touch}">
                <table v-if="fields" class="ui celled unstackable table rowDataTable">
                  <thead>
                    <tr class="middle aligned">
                      <th v-for="field in fields" :key="field.fieldId" class="two wide">
                        {{ field.heading }}
                      </th>
                      <th class="three wide">{{ text.memoSetNotes }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr class="middle aligned">
                      <td v-for="field in fields" :key="field.fieldId" :class="{cardsCell: cardStyle(rows[parseInt(moveRows.lastRow, 10) - 1].data[field.fieldId])}" :style="cardStyle(rows[parseInt(moveRows.lastRow, 10) - 1].data[field.fieldId])">
                        <div v-html="sanitize(rows[parseInt(moveRows.lastRow, 10) - 1].data[field.fieldId])"></div>
                      </td>
                      <!-- Notes -->
                      <td>
                        <div v-html="sanitize(rows[parseInt(moveRows.lastRow, 10) - 1].data.notes)"></div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <!-- Move After -->
            <div class="modalLabel modalLabelPadding" :class="{'four wide column': !mobile, 'sixteen wide column': mobile}">
              <span>{{ text.memoSetMoveAfterRow }}</span>
            </div>
            <div class="noTopPadding" :class="{'twelve wide column': !mobile, 'sixteen wide column': mobile}">
              <div class="ui fluid input">
                <input type="text" v-model="moveRows.moveAfter" :placeholder="enterRowNumberFromZeroPlaceholder" @input="applyMathjaxToRowDataTables()">
              </div>
            </div>
            <!-- Move After preview -->
            <div v-if="!mobile && parseInt(moveRows.moveAfter, 10) > 0 && parseInt(moveRows.moveAfter, 10) <= rows.length" class="four wide column">
            </div>
            <div v-if="parseInt(moveRows.moveAfter, 10) > 0 && parseInt(moveRows.moveAfter, 10) <= rows.length" class="noTopPadding" :class="{'twelve wide column': !mobile, 'sixteen wide column': mobile}">
              <div class="row noBottomPadding rowDataTableWrapper" :class="{'noTopPadding': mobile, 'thinScroll': mobile && touch}">
                <table v-if="fields" class="ui celled unstackable table rowDataTable">
                  <thead>
                    <tr class="middle aligned">
                      <th v-for="field in fields" :key="field.fieldId" class="two wide">
                        {{ field.heading }}
                      </th>
                      <th class="three wide">{{ text.memoSetNotes }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr class="middle aligned">
                      <td v-for="field in fields" :key="field.fieldId" :class="{cardsCell: cardStyle(rows[parseInt(moveRows.moveAfter, 10) - 1].data[field.fieldId])}" :style="cardStyle(rows[parseInt(moveRows.moveAfter, 10) - 1].data[field.fieldId])">
                        <div v-html="sanitize(rows[parseInt(moveRows.moveAfter, 10) - 1].data[field.fieldId])"></div>
                      </td>
                      <!-- Notes -->
                      <td>
                        <div v-html="sanitize(rows[parseInt(moveRows.moveAfter, 10) - 1].data.notes)"></div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div class="actions">
          <div class="ui primary ok button" :class="{disabled: !moveRowsValid}">Move</div>
          <div class="ui cancel button">{{ text.commonCancel}}</div>
        </div>
      </div>
    </div>
    <div class="ui modal populateColumn" :class="[modalClass, {fullHeight: verticallyChallenged}, {standard: !verticallyChallenged}]">
      <div class="modalFlexContainer">
        <div class="ui header">
          {{ text.memoSetPopulateColumn }}
        </div>
        <div class="content populateColumnContent" :class="{thinScroll: mobile && touch}">
          <div class="ui grid" :class="{'mobile': mobile}">
            <!-- Column -->
            <div class="modalLabel modalLabelPadding" :class="{'four wide column': !mobile, 'sixteen wide column': mobile}">
              <span>{{ text.memoSetColumn }}</span>
            </div>
            <div class="noTopPadding" :class="{'twelve wide column': !mobile, 'sixteen wide column': mobile}">
              <div class="ui fluid selection dropdown populateColumnColumn">
                <input type="hidden" :value="populateColumn.column">
                <i class="dropdown icon"></i>
                <div class="default text">{{ text.memoSetSelectTheColumnToPopulate }}</div>
                <div class="menu">
                  <div class="item" v-for="(field, index) in fields" :key="index" :data-value="index">
                    {{ field.heading }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- Warning message -->
          <div class="ui red icon message standardColor" v-if="populateColumnDataExists && !populateColumn.submitting">
            <i class="red exclamation triangle icon"></i>
            <p>Data in the <strong>{{ fields[populateColumn.column].heading }}</strong> column will be deleted.</p>
          </div>
            <!-- Data -->
          <div class="ui grid" :class="{'mobile': mobile}">
            <div class="modalLabel modalLabelPadding" :class="{'four wide column': !mobile, 'sixteen wide column': mobile}">
              <span>Data</span>
            </div>
            <div class="noTopPadding" :class="{'twelve wide column': !mobile, 'sixteen wide column': mobile}">
              <div class="ui fluid selection dropdown populateColumnDataSet">
                <input type="hidden">
                <i class="dropdown icon"></i>
                <div class="default text">{{ text.memoSetSelectTheDataType }}</div>
                <div class="menu">
                  <div class="item" data-value="c">{{ text.memoSetCards }}</div>
                  <div class="item" data-value="n">{{ text.memoSetNumbers }}</div>
                </div>
              </div>
            </div>
            <!-- Number of Cards -->
            <div class="modalLabel modalLabelPadding" :class="{'four wide column': !mobile, 'sixteen wide column': mobile}" v-show="populateColumn.dataSet === 'c'">
              <span>Number of Cards</span>
            </div>
            <div class="noTopPadding" :class="{'twelve wide column': !mobile, 'sixteen wide column': mobile}" v-show="populateColumn.dataSet === 'c'">
              <div class="ui fluid selection dropdown populateColumnNumberOfCards">
                <input type="hidden" :value="populateColumn.numberOfCards">
                <i class="dropdown icon"></i>
                <div class="default text"></div>
                <div class="menu">
                  <div class="item" data-value="1">1</div>
                  <div class="item" data-value="2">2</div>
                </div>
              </div>
            </div>
            <!-- Card Style -->
            <div class="modalLabel modalLabelPadding" :class="{'four wide column': !mobile, 'sixteen wide column': mobile}" v-show="populateColumn.dataSet === 'c'">
              <span>{{ text.memoSetCardStyle }}</span>
            </div>
            <div class="noTopPadding" :class="{'twelve wide column': !mobile, 'sixteen wide column': mobile}" v-show="populateColumn.dataSet === 'c'">
              <div class="ui fluid selection dropdown populateColumnCardSet">
                <input type="hidden" :value="populateColumn.cardSet">
                <i class="dropdown icon"></i>
                <div class="default text"></div>
                <div class="menu">
                  <div class="item" data-value="English">{{ text.memoSetDeckEnglish }}</div>
                  <div class="item" data-value="FourColor">{{ text.memoSetDeckFourColor }}</div>
                  <div class="item" data-value="German">{{ text.memoSetDeckGerman }}</div>
                </div>
              </div>
            </div>
            <!-- Order of Suits -->
            <div class="modalLabel modalLabelPadding" :class="{'four wide column': !mobile, 'sixteen wide column': mobile}" v-show="populateColumn.dataSet === 'c'">
              <span>{{ text.memoSetOrderOfSuits }}</span>
            </div>
            <div class="noTopPadding" :class="{'twelve wide column': !mobile, 'sixteen wide column': mobile}" v-show="populateColumn.dataSet === 'c'">
              <div class="ui fluid selection dropdown populateColumnSuits">
                <input type="hidden" :value="populateColumn.suits">
                <i class="dropdown icon"></i>
                <div class="default text"></div>
                <div class="menu" v-if="populateColumn.cardSet === 'FourColor'">
                  <div class="item" data-value="CDHS"><span class="suits"><span class="greenSuit">♣</span><span class="blueSuit">♦</span><span class="redSuit">♥</span>♠</span></div>
                  <div class="item" data-value="CDSH"><span class="suits"><span class="greenSuit">♣</span><span class="blueSuit">♦</span>♠<span class="redSuit">♥</span></span></div>
                  <div class="item" data-value="CHDS"><span class="suits"><span class="greenSuit">♣</span><span class="redSuit">♥</span>♠<span class="blueSuit">♦</span></span></div>
                  <div class="item" data-value="CHSD"><span class="suits"><span class="greenSuit">♣</span><span class="redSuit">♥</span>♠<span class="blueSuit">♦</span></span></div>
                  <div class="item" data-value="CSDH"><span class="suits"><span class="greenSuit">♣</span>♠<span class="blueSuit">♦</span><span class="redSuit">♥</span></span></div>
                  <div class="item" data-value="CSHD"><span class="suits"><span class="greenSuit">♣</span>♠<span class="redSuit">♥</span><span class="blueSuit">♦</span></span></div>
                  <div class="item" data-value="DCHS"><span class="suits"><span class="blueSuit">♦</span><span class="greenSuit">♣</span><span class="redSuit">♥</span>♠</span></div>
                  <div class="item" data-value="DCSH"><span class="suits"><span class="blueSuit">♦</span><span class="greenSuit">♣</span>♠<span class="redSuit">♥</span></span></div>
                  <div class="item" data-value="DHCS"><span class="suits"><span class="blueSuit">♦</span><span class="redSuit">♥</span><span class="greenSuit">♣</span>♠</span></div>
                  <div class="item" data-value="DHSC"><span class="suits"><span class="blueSuit">♦</span><span class="redSuit">♥</span>♠<span class="greenSuit">♣</span></span></div>
                  <div class="item" data-value="DSCH"><span class="suits"><span class="blueSuit">♦</span>♠<span class="greenSuit">♣</span><span class="redSuit">♥</span></span></div>
                  <div class="item" data-value="DSHC"><span class="suits"><span class="blueSuit">♦</span>♠<span class="redSuit">♥</span><span class="greenSuit">♣</span></span></div>
                  <div class="item" data-value="HCDS"><span class="suits"><span class="redSuit">♥</span><span class="greenSuit">♣</span><span class="blueSuit">♦</span>♠</span></div>
                  <div class="item" data-value="HCSD"><span class="suits"><span class="redSuit">♥</span><span class="greenSuit">♣</span>♠<span class="blueSuit">♦</span></span></div>
                  <div class="item" data-value="HDCS"><span class="suits"><span class="redSuit">♥</span><span class="blueSuit">♦</span><span class="greenSuit">♣</span>♠</span></div>
                  <div class="item" data-value="HDSC"><span class="suits"><span class="redSuit">♥</span><span class="blueSuit">♦</span>♠<span class="greenSuit">♣</span></span></div>
                  <div class="item" data-value="HSCD"><span class="suits"><span class="redSuit">♥</span>♠<span class="greenSuit">♣</span><span class="blueSuit">♦</span></span></div>
                  <div class="item" data-value="HSDC"><span class="suits"><span class="redSuit">♥</span>♠<span class="blueSuit">♦</span><span class="greenSuit">♣</span></span></div>
                  <div class="item" data-value="SCDH"><span class="suits">♠<span class="greenSuit">♣</span><span class="blueSuit">♦</span><span class="redSuit">♥</span></span></div>
                  <div class="item" data-value="SCHD"><span class="suits">♠<span class="greenSuit">♣</span><span class="redSuit">♥</span><span class="blueSuit">♦</span></span></div>
                  <div class="item" data-value="SDCH"><span class="suits">♠<span class="blueSuit">♦</span><span class="greenSuit">♣</span><span class="redSuit">♥</span></span></div>
                  <div class="item" data-value="SDHC"><span class="suits">♠<span class="blueSuit">♦</span><span class="redSuit">♥</span><span class="greenSuit">♣</span></span></div>
                  <div class="item" data-value="SHCD"><span class="suits">♠<span class="redSuit">♥</span><span class="greenSuit">♣</span><span class="blueSuit">♦</span></span></div>
                  <div class="item" data-value="SHDC"><span class="suits">♠<span class="redSuit">♥</span><span class="blueSuit">♦</span><span class="greenSuit">♣</span></span></div>
                </div>
                <div class="menu" v-if="populateColumn.cardSet !== 'FourColor'">
                  <div class="item" data-value="CDHS"><span class="suits">♣<span class="redSuit">♦♥</span>♠</span></div>
                  <div class="item" data-value="CDSH"><span class="suits">♣<span class="redSuit">♦</span>♠<span class="redSuit">♥</span></span></div>
                  <div class="item" data-value="CHDS"><span class="suits">♣<span class="redSuit">♥♦</span>♠</span></div>
                  <div class="item" data-value="CHSD"><span class="suits">♣<span class="redSuit">♥</span>♠<span class="redSuit">♦</span></span></div>
                  <div class="item" data-value="CSDH"><span class="suits">♣♠<span class="redSuit">♦♥</span></span></div>
                  <div class="item" data-value="CSHD"><span class="suits">♣♠<span class="redSuit">♥♦</span></span></div>
                  <div class="item" data-value="DCHS"><span class="suits"><span class="redSuit">♦</span>♣<span class="redSuit">♥</span>♠</span></div>
                  <div class="item" data-value="DCSH"><span class="suits"><span class="redSuit">♦</span>♣♠<span class="redSuit">♥</span></span></div>
                  <div class="item" data-value="DHCS"><span class="suits"><span class="redSuit">♦♥</span>♣♠</span></div>
                  <div class="item" data-value="DHSC"><span class="suits"><span class="redSuit">♦♥</span>♠♣</span></div>
                  <div class="item" data-value="DSCH"><span class="suits"><span class="redSuit">♦</span>♠♣<span class="redSuit">♥</span></span></div>
                  <div class="item" data-value="DSHC"><span class="suits"><span class="redSuit">♦</span>♠<span class="redSuit">♥</span>♣</span></div>
                  <div class="item" data-value="HCDS"><span class="suits"><span class="redSuit">♥</span>♣<span class="redSuit">♦</span>♠</span></div>
                  <div class="item" data-value="HCSD"><span class="suits"><span class="redSuit">♥</span>♣♠<span class="redSuit">♦</span></span></div>
                  <div class="item" data-value="HDCS"><span class="suits"><span class="redSuit">♥♦</span>♣♠</span></div>
                  <div class="item" data-value="HDSC"><span class="suits"><span class="redSuit">♥♦</span>♠♣</span></div>
                  <div class="item" data-value="HSCD"><span class="suits"><span class="redSuit">♥</span>♠♣<span class="redSuit">♦</span></span></div>
                  <div class="item" data-value="HSDC"><span class="suits"><span class="redSuit">♥</span>♠<span class="redSuit">♦</span>♣</span></div>
                  <div class="item" data-value="SCDH"><span class="suits">♠♣<span class="redSuit">♦♥</span></span></div>
                  <div class="item" data-value="SCHD"><span class="suits">♠♣<span class="redSuit">♥♦</span></span></div>
                  <div class="item" data-value="SDCH"><span class="suits">♠<span class="redSuit">♦</span>♣<span class="redSuit">♥</span></span></div>
                  <div class="item" data-value="SDHC"><span class="suits">♠<span class="redSuit">♦♥</span>♣</span></div>
                  <div class="item" data-value="SHCD"><span class="suits">♠<span class="redSuit">♥</span>♣<span class="redSuit">♦</span></span></div>
                  <div class="item" data-value="SHDC"><span class="suits">♠<span class="redSuit">♥♦</span>♣</span></div>
                </div>
              </div>
            </div>
            <!-- Order of Values -->
            <div class="modalLabel modalLabelPadding" :class="{'four wide column': !mobile, 'sixteen wide column': mobile}" v-show="populateColumn.dataSet === 'c'">
              <span>{{ text.memoSetOrderOfValues }}</span>
            </div>
            <div class="noTopPadding" :class="{'twelve wide column': !mobile, 'sixteen wide column': mobile}" v-show="populateColumn.dataSet === 'c'">
              <div class="ui fluid selection dropdown populateColumnValues">
                <input type="hidden" :value="populateColumn.values">
                <i class="dropdown icon"></i>
                <div class="default text"></div>
                <div class="menu">
                  <div class="item" data-value="A-K">A &ndash; K</div>
                  <div class="item" data-value="K-A">K &ndash; A</div>
                  <div class="item" data-value="2-A">2 &ndash; A</div>
                  <div class="item" data-value="A-2">A &ndash; 2</div>
                </div>
              </div>
            </div>
            <!-- Most Significant Card -->
            <div class="modalLabel modalLabelPadding" :class="{'four wide column': !mobile, 'sixteen wide column': mobile}" v-show="populateColumn.dataSet === 'c' && populateColumn.numberOfCards === '2'">
              <span>{{ text.memoSetMostSignificantCard }}</span>
            </div>
            <div class="noTopPadding" :class="{'twelve wide column': !mobile, 'sixteen wide column': mobile}" v-show="populateColumn.dataSet === 'c' && populateColumn.numberOfCards === '2'">
              <div class="ui fluid selection dropdown populateColumnSignificantCard">
                <input type="hidden" :value="populateColumn.significantCard">
                <i class="dropdown icon"></i>
                <div class="default text"></div>
                <div class="menu">
                  <div class="item" data-value="L">{{ text.memoSetLeft }}</div>
                  <div class="item" data-value="R">{{ text.memoSetRight }}</div>
                </div>
              </div>
            </div>
            <!-- Include Repeated Cards -->
            <div class="modalLabel modalLabelPadding" :class="{'four wide column': !mobile, 'sixteen wide column': mobile}" v-show="populateColumn.dataSet === 'c' && populateColumn.numberOfCards === '2'">
              <span>{{ text.memoSetIncludeRepeatedCards }}</span>
            </div>
            <div class="noTopPadding" :class="{'twelve wide column': !mobile, 'sixteen wide column': mobile}" v-show="populateColumn.dataSet === 'c' && populateColumn.numberOfCards === '2'">
              <div class="ui fluid selection dropdown populateColumnIncludeRepeated">
                <input type="hidden" :value="populateColumn.includeRepeated">
                <i class="dropdown icon"></i>
                <div class="default text"></div>
                <div class="menu">
                  <div class="item" data-value="Y">{{ text.commonYes }}</div>
                  <div class="item" data-value="N">{{ text.commonNo }}</div>
                </div>
              </div>
            </div>
            <!-- First Value -->
            <div class="modalLabel modalLabelPadding" :class="{'four wide column': !mobile, 'sixteen wide column': mobile}" v-if="populateColumn.dataSet === 'n'">
              <span>{{ text.memoSetFirstValue }}</span>
            </div>
            <div class="noTopPadding" :class="{'twelve wide column': !mobile, 'sixteen wide column': mobile}" v-if="populateColumn.dataSet === 'n'">
              <div
                class="populateColumnValue"
                contenteditable
                @input="populateColumnValueInput($event, 0)"
                @keydown.enter.stop
                v-html="populateColumn.originalNumValues[0]"
              ></div>
            </div>
            <!-- Last Value -->
            <div class="modalLabel modalLabelPadding" :class="{'four wide column': !mobile, 'sixteen wide column': mobile}" v-if="populateColumn.dataSet === 'n'">
              <span>{{ text.memoSetLastValue }}</span>
            </div>
            <div class="noTopPadding" :class="{'twelve wide column': !mobile, 'sixteen wide column': mobile}" v-if="populateColumn.dataSet === 'n'">
              <div
                class="populateColumnValue"
                contenteditable
                @input="populateColumnValueInput($event, 1)"
                @keydown.enter.stop
                v-html="populateColumn.originalNumValues[1]"
              ></div>
            </div>
            <!-- Numbers Preview -->
            <div class="modalLabel modalLabelPadding" :class="{'four wide column': !mobile, 'sixteen wide column': mobile}" v-show="populateColumn.dataSet === 'n'">
              <span>{{ text.memoSetPreview }}</span>
            </div>
            <div class="noTopPadding" :class="{'twelve wide column': !mobile, 'sixteen wide column': mobile}" v-show="populateColumn.dataSet === 'n'">
              <div class="populateColumnPreview" v-html="populateColumnPreviewHtml"></div>
            </div>
          </div>
        </div>
        <div class="actions">
          <div class="ui primary ok button" :class="{disabled: populateColumn.column === '' || populateColumn.dataSet === '' || (populateColumn.dataSet === 'n' && !populateColumnPreviewHtml)}">Populate</div>
          <button class="ui cancel button">{{ text.commonCancel}}</button>
        </div>
      </div>
    </div>
    <div class="ui modal question" :class="[modalClass, {fullHeight: verticallyChallenged}, {standard: !verticallyChallenged}]">
      <div class="modalFlexContainer">
        <div class="ui header">
          <!-- Delete button -->
          <div v-show="deleteQuestionVisible" class="ui right floated icon button deleteQuestionButton" @click="deleteQuestionClick()"><i class="trash alternate icon"></i></div>
          <span v-show="question.mode === 'add'">{{ text.memoSetAddQuestion }}</span>
          <span v-show="question.mode === 'edit' && memoSetType === 'regular'">{{ text.memoSetEditQuestion }}</span>
          <span v-show="question.mode === 'edit' && memoSetType !== 'regular'">{{ text.memoSetViewQuestion }}</span>
        </div>
        <div class="content questionContent" :class="{'thinScroll': mobile && touch}">
          <div class="ui grid" :class="{'mobile': mobile}">
            <!-- From Column -->
            <div class="modalLabel" :class="{'four wide column': !mobile, 'sixteen wide column': mobile, 'modalLabelPadding': memoSetType === 'regular'}">
              <span>{{ text.memoSetFromColumn }}</span>
            </div>
            <div :class="{'twelve wide column': !mobile, 'sixteen wide column': mobile, 'noTopPadding': memoSetType === 'regular' || mobile}">
              <div v-if="memoSetType !== 'regular' && question.fromFieldId">{{ fieldsById[question.fromFieldId].heading }}</div>
              <div v-if="memoSetType === 'regular'">
                <div class="ui fluid selection dropdown questionFromFieldId" :class="{'noPointerEvents': question.pendingDelete}">
                  <input type="hidden" :value="question.fromFieldId">
                  <i class="dropdown icon"></i>
                  <div class="default text">{{ text.memoSetSelectQuestionColumn }}</div>
                  <div class="menu">
                    <div class="item" v-for="field in fields" :key="field.fieldId" :data-value="field.fieldId">
                      {{ field.heading }}
                    </div>
                    <div class="item" data-value="s" v-show="options.useSummaryImages">
                      {{ text.memoSetSummaryImage }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- To Column -->
            <div class="modalLabel" :class="{'four wide column': !mobile, 'sixteen wide column': mobile, 'modalLabelPadding': memoSetType === 'regular'}">
              <span>{{ text.memoSetToColumn }}</span>
            </div>
            <div :class="{'twelve wide column': !mobile, 'sixteen wide column': mobile, 'noTopPadding': memoSetType === 'regular' || mobile}">
              <div v-if="memoSetType !== 'regular' && question.toFieldId">{{ fieldsById[question.toFieldId].heading }}</div>
              <div v-if="memoSetType === 'regular'">
                <div class="ui fluid selection dropdown questionToFieldId" :class="{'noPointerEvents': question.pendingDelete}">
                  <input type="hidden" :value="question.toFieldId">
                  <i class="dropdown icon"></i>
                  <div class="default text">{{ text.memoSetSelectAnswerColumn }}</div>
                  <div class="menu">
                    <div class="item" v-for="field in fields" :key="field.fieldId" :data-value="field.fieldId">
                      {{ field.heading }}
                    </div>
                    <div class="item" data-value="s" v-show="options.useSummaryImages">
                      {{ text.memoSetSummaryImage }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- Instructions -->
            <div v-show="questionStatus === 'ok' && !mobile" class="four wide column"></div>
            <div v-show="questionStatus === 'ok'" :class="{'twelve wide column': !mobile, 'sixteen wide column': mobile}">
              <div class="ui yellow message standardColor">{{ text.memoSetQuestionAsterisk }}</div>
            </div>
            <!-- Question -->
            <div v-show="questionStatus === 'ok'" class="modalLabel modalLabelQuestion" :class="{'four wide column': !mobile, 'sixteen wide column': mobile, 'modalLabelPadding': memoSetType === 'regular'}">
              <span>{{ text.memoSetQuestion }}</span>
            </div>
            <div v-show="questionStatus === 'ok'" :class="{'twelve wide column': !mobile, 'sixteen wide column': mobile, 'noTopPadding': memoSetType === 'regular' || mobile}">
              <div v-if="memoSetType !== 'regular' && question.originalQuestionText" v-html="question.originalQuestionText"></div>
              <div
                v-if="modal === 'question' && memoSetType === 'regular'"
                class="questionText"
                :contenteditable="!question.pendingDelete"
                @input="questionInput($event)"
                @keydown.enter.stop
                v-html="question.originalQuestionText"
              ></div>
            </div>
            <!-- Example -->
            <div v-show="questionStatus === 'ok'" class="modalLabel modalLabelPadding" :class="{'four wide column': !mobile, 'sixteen wide column': mobile}">
              <span>{{ text.memoSetExample }}</span>
            </div>
            <div v-show="questionStatus === 'ok'" class="noTopPadding" :class="{'twelve wide column': !mobile, 'sixteen wide column': mobile}">
              <div class="questionPreview" :class="{'questionPreviewShift': memoSetType !== 'regular' && !mobile}" v-html="questionPreviewHtml"></div>
            </div>
          </div>
          <!-- Error messages -->
          <transition name="questionMessageFadeIn">
            <div class="ui orange message standardColor" v-show="questionStatus === 'exists' && !question.pendingDelete">
              {{ text.memoSetQuestionExists }}
            </div>
          </transition>
          <transition name="questionMessageFadeIn">
            <div class="ui orange message standardColor" v-show="questionStatus === 'same column' && !question.pendingDelete">
              {{ text.memoSetQuestionColumnsMustBeDifferent }}
            </div>
          </transition>
          <transition name="questionMessageFadeIn">
            <div class="ui red icon message" v-show="question.pendingDelete">
              <i class="exclamation triangle icon"></i>
              <div class="content">
                <div class="header">
                  {{ text.memoSetQuestionConfirmDeleteHeader }}
                </div>
                <p v-html="questionConfirmDeleteMessage"></p>
              </div>
            </div>
          </transition>
        </div>
        <div class="actions">
          <div v-show="memoSetType === 'regular' && !question.pendingDelete" class="ui primary ok button" :class="{disabled: questionStatus !== 'ok'}">{{ text.commonOK }}</div>
          <div v-show="memoSetType === 'regular' && question.pendingDelete" class="ui red ok button">{{ text.commonDelete}}</div>
          <div v-show="memoSetType === 'regular'" class="ui cancel button">{{ text.commonCancel}}</div>
          <div v-show="memoSetType !== 'regular'" class="ui primary cancel button">{{ text.commonOK }}</div>
        </div>
      </div>
    </div>
    <div class="ui modal resetLearning" :class="modalClass">
      <div class="ui header">
        {{ text.memoSetResetLearning }}
      </div>
      <div class="content">
        {{ resetLearningMessage }}
      </div>
      <div class="actions">
        <div class="ui red ok button">{{ text.commonReset }}</div>
        <button class="ui cancel button">{{ text.commonCancel}}</button>
      </div>
    </div>
    <div class="ui modal reviewSchedule" :class="modalClass">
      <div class="ui header">
        {{ text.memoSetReviewSchedule }}
      </div>
      <div class="content">
        <div>
          <table class="ui celled compact unstackable table">
            <thead>
              <tr>
                <th>{{ text.memoSetReviewCycle }}</th>
                <th>{{ text.memoSetDaysBeforeReview }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(reviewAfterDays, index) in reviewScheduleDisplay" :key="index">
                <td class="reviewScheduleCycle">{{ index + 1 }}<span v-if="index === reviewScheduleDisplay.length - 1">+</span></td>
                <td>
                  <div class="ui input">
                    <input type="number" class="center aligned" v-model="reviewScheduleDisplay[index]" @change="reviewScheduleChanged(index)">
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <div class="ui button" @click="resetReviewSchedule()">{{ text.memoSetResetToDefault }}</div>
          <div class="ui button reviewScheduleButton" @click="adjustReviewSchedule(-0.2)">-20%</div>
          <div class="ui button reviewScheduleButton" @click="adjustReviewSchedule(0.25)">+25%</div>
        </div>
      </div>
      <div class="actions">
        <div class="ui primary ok button">{{ text.commonOK }}</div>
        <button class="ui cancel button">{{ text.commonCancel}}</button>
      </div>
    </div>
    <div class="ui modal rowFieldPasteConfirm" :class="modalClass">
      <div class="ui header">
        {{ text.memoSetConfirmPasteHeader }}
      </div>
      <div class="content">
        <p>{{ text.memoSetCellPasteConfirmMessage }}</p>
      </div>
      <div class="actions">
        <div class="ui primary button" @click="rowFieldPasteMultiple()">{{ text.memoSetCellPasteIntoMultipleCells }}</div>
        <div class="ui button" @click="rowFieldPasteSingle()">{{ text.memoSetCellPasteIntoSingleCell }}</div>
        <div class="ui cancel button">{{ text.commonCancel}}</div>
      </div>
    </div>
    <div class="ui modal rowQuestion" :class="[modalClass, {fullHeight: verticallyChallenged}, {standard: !verticallyChallenged}]">
      <div class="modalFlexContainer">
        <div class="ui header">
          {{ text.memoSetRowQuestion }}
        </div>
        <div class="content">
          <div class="ui grid" :class="{'mobile': mobile}">
            <div class="modalLabel" :class="{'four wide column': !mobile, 'sixteen wide column': mobile}">
              <span>{{ text.memoSetQuestion }}</span>
            </div>
            <div :class="{'twelve wide column': !mobile, 'sixteen wide column': mobile}">
              <div class="rowQuestionQuestion" v-html="rowQuestion.questionHtml"></div>
            </div>
            <div class="modalLabel" :class="{'four wide column': !mobile, 'sixteen wide column': mobile}">
              <span>{{ text.memoSetAnswer }}</span>
            </div>
            <div :class="{'twelve wide column': !mobile, 'sixteen wide column': mobile}">
              <div class="rowQuestionQuestion" v-html="rowQuestion.answerHtml"></div>
            </div>
            <!-- Status -->
            <div class="modalLabel" :class="{'four wide column': !mobile, 'sixteen wide column': mobile}">
              <span>{{ text.commonStatus }}</span>
            </div>
            <div :class="{'twelve wide column': !mobile, 'sixteen wide column': mobile}">
              <div class="ui green label" v-if="rowQuestion.status === 'known'"><i class="check icon"></i> {{ text.memoSetReviewCycleLabelKnown}}</div>
              <div class="ui orange label" v-if="rowQuestion.status === 'due'"><i class="calendar outline icon"></i> {{ text.memoSetReviewCycleLabelDue}}</div>
              <div class="ui unknown label" v-if="rowQuestion.status === 'unknown'"><i class="x icon"></i> {{ text.memoSetReviewCycleLabelUnknown}}</div>
              <span v-show="rowQuestion.status === 'known'" style="margin-left: 1.5rem">{{ nextReviewText }}</span>
            </div>
            <!-- Review cycle -->
            <div class="modalLabel" :class="{'four wide column': !mobile, 'sixteen wide column': mobile}">
              <span>{{ text.memoSetReviewCycle }}</span>
            </div>
            <div :class="{'twelve wide column': !mobile, 'sixteen wide column': mobile}">
              <div style="display: inline-block" v-for="reviewCycle in reviewCycles" :key="reviewCycle">
                <button
                  class="ui compact button reviewCycle"
                  :class="{
                    'green': rowQuestion.status === 'known' && reviewCycle === rowQuestion.reviewCycle,
                    'orange': rowQuestion.status === 'due' && reviewCycle === rowQuestion.reviewCycle,
                    'unknown': rowQuestion.status === 'unknown' && reviewCycle === 0,
                  }"
                  @click="reviewCycleClick(reviewCycle)"
                >
                  <span v-if="reviewCycle < reviewCycles.length - 2">{{ reviewCycle }}</span>
                  <span v-if="reviewCycle === reviewCycles.length - 2" class="reviewCycle7Margin">{{ reviewCycles.length - 2}}+</span>
                  <span v-if="reviewCycle === reviewCycles.length - 1" class="reviewCycleInfinityMargin"><i class="infinity icon"></i></span>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="actions">
          <div class="ui ok primary button">{{ text.commonOK }}</div>
          <div class="ui cancel button">{{ text.commonCancel}}</div>
        </div>
      </div>
    </div>
    <div class="ui modal setCoverImage" :class="[modalClass, {fullHeight: verticallyChallenged}, {standard: !verticallyChallenged}]">
      <div class="modalFlexContainer">
        <div class="ui header">
          {{ text.memoSetCoverImage }}
        </div>
        <div class="content" :class="{'thinScroll': mobile && touch}">
          <div class="ui small active inline loader modalLoader" v-show="setCoverImage.loading"></div>
          <div v-show="!setCoverImage.loading" class="height100">
            <div v-show="setCoverImage.url" class="height100">
              <div class="ui active dimmer" v-show="setCoverImage.uploading">
                <div class="ui text loader">{{ text.memoSetUploadingImage }}</div>
              </div>
              <img class="ui rounded image previewImage" id="setCoverImage" :src="setCoverImage.url" @load="setCoverImage.loading = false">
              <button class="ui primary button resetImageButton" @click="initSetCoverImage()">{{ text.commonChange }}</button>
            </div>
            <div v-show="!setCoverImage.url">
              <label for="coverImageFile">
                <div class="ui button">
                  <i class="camera icon"></i>
                  {{ text.memoSetUploadTakePhoto }}
                </div>
              </label>
              <input type="file" accept="image/*" id="coverImageFile" @change="setCoverImageFileChange($event.target.files[0])">
              <div class="ui horizontal divider">
                {{ text.commonOr }}
              </div>
              <p>
                {{ text.memoSetCopyAnyImage1 }}
              </p>
              <p>
                {{ text.memoSetCopyAnyImage2 }}
              </p>
              <!-- Using textarea rather than div to enable 'Paste' in the context menu -->
              <textarea
                class="pasteImageHere"
                :placeholder="text.memoSetPasteImageHere"
                tabindex="0"
                @keypress.prevent
                @paste.prevent="coverImagePaste($event)"
              ></textarea>
              <div v-show="options.useBackgroundPhotos && photos.length">
                <div class="ui horizontal divider">
                  {{ text.commonOr }}
                </div>
                <p>
                  {{ text.memoSetSelectBackgroundPhoto }}
                </p>
                <div class="ui small images">
                  <img
                    v-for="(photo, photoIndex) in photos" :key="photoIndex"
                    :src="photo.url"
                    class="coverImagePhoto"
                    @click="setCoverImagePhotoClick(photoIndex)"
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="actions">
          <div class="ui primary ok button" :class="{disabled: !setCoverImage.url}">{{ text.commonOK }}</div>
          <div class="ui cancel button">{{ text.commonCancel }}</div>
        </div>
      </div>
    </div>
    <div class="ui modal setGroupColor" :class="modalClass">
      <div class="ui header">
        {{ text.memoSetSetGroupColor }}
      </div>
      <div class="content">
        <div class="ui label reviewGroup" :class="setGroupColor.color">{{ setGroupColor.reviewGroup }}</div>
        <div class="ui divider" style="margin-bottom: 2rem"></div>
        <button class="ui red button groupColorButton" @click="setGroupColor.color = 'red'">&nbsp;</button>
        <button class="ui orange button groupColorButton" @click="setGroupColor.color = 'orange'">&nbsp;</button>
        <button class="ui yellow button groupColorButton" @click="setGroupColor.color = 'yellow'">&nbsp;</button>
        <button class="ui olive button groupColorButton" @click="setGroupColor.color = 'olive'">&nbsp;</button>
        <button class="ui green button groupColorButton" @click="setGroupColor.color = 'green'">&nbsp;</button>
        <button class="ui teal button groupColorButton" @click="setGroupColor.color = 'teal'">&nbsp;</button>
        <button class="ui blue button groupColorButton" @click="setGroupColor.color = 'blue'">&nbsp;</button>
        <button class="ui violet button groupColorButton" @click="setGroupColor.color = 'violet'">&nbsp;</button>
        <button class="ui purple button groupColorButton" @click="setGroupColor.color = 'purple'">&nbsp;</button>
        <button class="ui pink button groupColorButton" @click="setGroupColor.color = 'pink'">&nbsp;</button>
        <button class="ui brown button groupColorButton" @click="setGroupColor.color = 'brown'">&nbsp;</button>
        <button class="ui grey button groupColorButton" @click="setGroupColor.color = 'grey'">&nbsp;</button>
        <button class="ui black button groupColorButton" @click="setGroupColor.color = 'black'">&nbsp;</button>
      </div>
      <div class="actions">
        <div class="ui primary ok button">{{ text.commonOK }}</div>
        <button class="ui cancel button">{{ text.commonCancel}}</button>
      </div>
    </div>
    <div class="ui modal shareMemoSet" :class="modalClass">
      <div class="ui header">
        {{ text.memoSetShareMemoSet }}
      </div>
      <div class="content shareMemoSetContent">
        <div class="ui yellow message standardColor">
          {{ text.memoSetAppropriate }}
        </div>
        <div class="ui form" onsubmit="return false">
          <div class="field" v-show="share.showPublicCheckbox">
            <div class="ui checkbox" id="sharePublic" @click="share.public = !share.public">
              <input type="checkbox" tabindex="0" class="hidden">
              <label>{{ text.memoSetSharePublicly }}</label>
            </div>
          </div>
          <div v-show="!share.showPublicCheckbox" style="margin-bottom: 1.4rem">{{ text.memoSetAlreadyPublic }}</div>
          <div class="inline fields">
            <div class="field">
              <div class="ui checkbox" id="shareSpecific" @click="shareMemoSetSpecificClick()">
                <input type="checkbox" tabindex="0" class="hidden">
                <label>{{ text.memoSetShareWithSpecificUser }}</label>
              </div>
            </div>
            <div class="inline field">
              <input type="text" v-model="share.username" :placeholder="text.memoSetEnterUsername" autocomplete="off" style="width: 20em" @focus="shareMemoSetUsernameFocus()" @input="share.noUser = false; share.shareSelf = false">
            </div>
          </div>
          <div class="ui orange message standardColor" v-show="share.noUser">
            {{ text.memoSetUserNotFoundMessage }}
          </div>
          <div class="ui orange message standardColor" v-show="share.shareSelf">
            {{ text.memoSetNoSelfShareMessage }}
          </div>
        </div>
      </div>
      <div class="actions">
        <div class="ui green ok button" :class="{disabled: !(share.showPublicCheckbox && share.public) && !(share.specific && share.username) || share.noUser || share.shareSelf, 'loading disabled': share.checkingUser}">{{ text.memoSetShareMemoSet }}</div>
        <button class="ui cancel button">{{ text.commonCancel}}</button>
      </div>
    </div>
    <div class="ui modal sortRows" :class="modalClass">
      <div class="ui header">
        {{ text.memoSetSortRows }}
      </div>
      <div class="content" v-if="fields">
        <div class="ui grid" :class="{'mobile': mobile}">
          <div class="modalLabel modalLabelPadding" :class="{'four wide column': !mobile, 'sixteen wide column': mobile}">
            <span>{{ text.memoSetSortByColumn }}</span>
          </div>
          <div class="noTopPadding" :class="{'twelve wide column': !mobile, 'sixteen wide column': mobile}">
            <div class="ui fluid selection dropdown sortRowsSortByColumn">
              <input type="hidden">
              <i class="dropdown icon"></i>
              <div class="default text">{{ text.memoSetSortByColumnPlaceholder }}</div>
              <div class="menu">
                <div class="item" v-for="(field, index) in fields" :key="index" :data-value="index">
                  {{ field.heading }}
                </div>
                <div class="item" data-value="reviewGroup" v-if="options.useReviewGroups">
                  {{ text.memoSetGroup }}
                </div>
                <div class="item" data-value="notes">
                  {{ text.memoSetNotes }}
                </div>
              </div>
            </div>
          </div>
          <div class="modalLabel" :class="{'four wide column': !mobile, 'sixteen wide column': mobile}">
            <span>{{ text.memoSetSortDirection }}</span>
          </div>
          <div :class="{'twelve wide column': !mobile, 'sixteen wide column': mobile}">
            <div class="ui form">
              <div class="inline fields noFlexWrap">
                <div class="field">
                  <div class="ui radio checkbox checked">
                    <input type="radio" name="sortdir" checked="" tabindex="0" class="hidden" @change="sortRowsSetDirection('a')">
                    <label><i class="vertically flipped sort amount up icon"></i></label>
                  </div>
                </div>
                <div class="field" style="margin-left: 2rem">
                  <div class="ui radio checkbox">
                    <input type="radio" name="sortdir" tabindex="0" class="hidden" @change="sortRowsSetDirection('d')">
                    <label><i class="sort amount down icon"></i></label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="actions">
        <div class="ui primary ok button" :class="{disabled: sortRows.sortByColumn === '-1'}">Sort</div>
        <button class="ui cancel button">{{ text.commonCancel}}</button>
      </div>
    </div>
    <div class="ui modal fullscreen summaryImage" :class="[modalClass, {fullHeight: summaryImage.page === 'summary' || mobile, standard: summaryImage.page === 'add image' && !mobile, summaryImageAddText: summaryImage.page === 'add text' && !mobile, fullscreenMobileModal: mobile}]">
      <div class="modalFlexContainer" v-show="summaryImage.page === 'add image'">
        <div class="ui header">
          {{ text.memoSetAddImage }}
        </div>
        <div class="content">
          <div class="ui small centered active inline loader modalLoader" v-show="addImage.loading"></div>
          <div v-show="!addImage.loading" class="height100">
            <div v-show="addImage.url" class="height100">
              <img id="addImageImage" class="ui rounded image previewImage" :src="addImage.url" @load="imageLoad()">
              <button class="ui blue button resetImageButton" @click="initAddImage()">{{ text.commonChange }}</button>
            </div>
            <div v-show="!addImage.url">
              <label for="imageFile">
                <div class="ui button selectImageFile">
                  <i class="camera icon"></i>
                  {{ text.memoSetUploadTakePhoto }}
                </div>
              </label>
              <input type="file" accept="image/*" id="imageFile" @change="addImageFileChange($event.target.files[0])">
              <div class="ui horizontal divider">
                {{ text.commonOr }}
              </div>
              <p>
                {{ text.memoSetCopyAnyImage1 }}
              </p>
              <p>
                {{ text.memoSetCopyAnyImage2 }}
              </p>
              <div class="ui message">
                <p v-html="text.memoSetNoteForAnimatedImages"></p>
              </div>
              <!-- Using textarea rather than div to enable 'Paste' in the context menu -->
              <textarea
                class="pasteImageHere"
                :placeholder="text.memoSetPasteImageHere"
                tabindex="0"
                @keypress.prevent
                @paste.prevent="imagePaste($event)"
              ></textarea>
            </div>
          </div>
        </div>
        <div class="actions">
          <div class="ui primary ok button" :class="{disabled: !addImage.url}">{{ text.memoSetAddImage }}</div>
          <button class="ui cancel button">{{ text.commonCancel}}</button>
        </div>
      </div>
      <div class="modalFlexContainer" v-show="summaryImage.page === 'add text'">
        <div class="ui header">
          {{ text.memoSetAddText }}
        </div>
        <div class="content">
          <div class="ui grid" :class="{'mobile': mobile}">
            <!-- Text -->
            <div class="modalLabel modalLabelPadding" :class="{'four wide column': !mobile, 'sixteen wide column': mobile}">
              <span>{{ text.memoSetText }}</span>
            </div>
            <div class="noTopPadding" :class="{'twelve wide column': !mobile, 'sixteen wide column': mobile}">
              <div class="ui fluid input">
                <input
                  id="newText"
                  type="text"
                  v-model="summaryImage.newText"
                  autocomplete="off"
                  :placeholder="text.memoSetEnterNewText"
                >
              </div>
            </div>
          </div>
        </div>
        <div class="actions">
          <div class="ui primary ok button" :class="{disabled: !summaryImage.newText}">{{ text.memoSetAddText }}</div>
          <button class="ui cancel button">{{ text.commonCancel}}</button>
        </div>
      </div>
      <div class="content noBottomPadding" style="padding-top: 0 !important">
        <div id="summaryImageMaxWidth"></div>
      </div>
      <div class="content summaryImage" v-show="summaryImage.page === 'summary'">
        <div v-show="summaryImage.page === 'summary'">
          <div class="ui active dimmer" v-show="summaryImage.uploading">
            <div class="ui text loader">{{ summaryImageUploadingMessage }}</div>
          </div>
          <div
            class="summaryImageWrapper"
            :style="{
              cursor: rotatingObject ? 'crosshair' : (panning ? 'grabbing' : 'grab'),
              height: availableHeight + 'px',
              width: availableWidth + 'px',
            }"
            @mousedown="summaryImageMouseDown($event)"
            @mousemove="summaryImageMouseMove($event)"
            @touchstart.prevent="summaryImageTouchStart($event)"
            @touchmove.prevent="summaryImageTouchMove($event)"
          >
            <div
              class="summaryImageView"
              :style="{
                height: (photo.height * photoTransform.scale) + 'px',
                marginLeft: (photoTransform.translateX * photoTransform.scale + ((availableWidth - photoTransform.visibleWidth) / 2)) + 'px',
                marginTop: (photoTransform.translateY * photoTransform.scale + ((availableHeight - photoTransform.visibleHeight) / 2)) + 'px',
                transition: animateTransition ? 'all 0.6s ease' : '',
                width: (photo.width * photoTransform.scale) + 'px'
              }"
            >
              <!-- Background photo -->
              <img
                v-if="photo.url"
                :src="photo.url"
                style="width: 100%"
                @dragstart.prevent
              >
              <!-- Location rectangle -->
              <div
                v-if="photo.url"
                id="locationRectangle"
                class="locationRectangle"
                :style="{
                  border: locationRectangleBorder,
                  cursor: panLock || mode !== 'select' ? (panning ? 'grabbing' : 'grab') : (rotatingObject ? 'crosshair' : (rectangleActive ? 'move' : 'default')),
                  height: (locationRect.height / photo.height * 100) + '%',
                  left: (locationRect.left / photo.width * 100) + '%',
                  top: (locationRect.top / photo.height * 100) + '%',
                  width: (locationRect.width / photo.width * 100) + '%',
                  zIndex: locationZIndex
                }"
                @dragstart.prevent
                @mousedown="rectangleMouseDown($event)"
                @mouseenter="locationHover = true"
                @mouseleave="locationHover = false"
                @touchstart.prevent="rectangleTouchStart($event)"
              ></div>
              <!-- Rectangle for no photo -->
              <div
                v-if="!photo.url"
                class="noPhotoRectSummaryImage"
                :style="{
                  height: (locationRect.height / photo.height * 100) + '%',
                  left: (locationRect.left / photo.width * 100) + '%',
                  top: (locationRect.top / photo.height * 100) + '%',
                  width: (locationRect.width / photo.width * 100) + '%',
                }"
              ></div>
              <!-- Object images -->
              <img
                v-for="(obj, objectIndex) in objects"
                v-show="objectIndex !== activeObject || mode === 'select'"
                :id="'summaryImageObj_' + objectIndex"
                :key="'summaryImageObj_' + obj.key"
                :class="{objectActive: objectIndex === activeObject}"
                :style="{
                  cursor: panLock || mode !== 'select' ? (panning ? 'grabbing' : 'grab') : (rotatingObject ? 'crosshair' : (objectIndex === activeObject ? 'move' : 'default')),
                  height: (obj.height / photo.height * 100) + '%',
                  left: (obj.left / photo.width * 100) + '%',
                  transform:
                    'rotate(' + obj.angle + 'deg)' +
                    (obj.flipX ? ' scaleX(-1)' : '') +
                    (obj.flipY ? ' scaleY(-1)' : ''),
                  top: (obj.top / photo.height * 100) + '%',
                  width: (obj.width / photo.width * 100) + '%',
                  zIndex: (objectIndex + 1) * 2
                }"
                :src="obj.url"
                crossorigin="anonymous"
                @dragstart.prevent
                @mousedown="objectMouseDown(objectIndex, $event)"
                @mouseenter="objectMouseEnter(objectIndex)"
                @mouseleave="objectMouseLeave(objectIndex)"
                @touchstart.prevent="objectTouchStart(objectIndex, $event)"
              >
              <!-- Canvas for editing object images -->
              <canvas
                v-show="activeObject !== -1 && (mode === 'clip' || mode === 'draw' || mode === 'remove')"
                id="objectCanvas"
                style="z-index: 1000"
                :style="{
                  cursor: panning ? 'grabbing' : (panLock ? 'grab' : (mode === 'clip' ? 'crosshair' : (mode === 'draw' ? 'url(/editing/cursor60.png) 33 33, auto' : (mode === 'remove' ? (removing ? 'ns-resize' : 'crosshair') : '')))),
                  height: activeObject === -1 ? '' : (((objects[activeObject].height + (2 * summaryImageObjectCanvasPadding / photoTransform.scale)) / photo.height * 100) + '%'),
                  left: activeObject === -1 ? '' : (((objects[activeObject].left - (summaryImageObjectCanvasPadding / photoTransform.scale)) / photo.width * 100) + '%'),
                  padding: summaryImageObjectCanvasPadding + 'px',
                  top:  activeObject === -1 ? '' : (((objects[activeObject].top - (summaryImageObjectCanvasPadding / photoTransform.scale)) / photo.height * 100) + '%'),
                  transform: activeObject === -1 ? '' :
                    'rotate(' + objects[activeObject].angle + 'deg)' +
                    (objects[activeObject].flipX ? ' scaleX(-1)' : '') +
                    (objects[activeObject].flipY ? ' scaleY(-1)' : ''),
                  width: activeObject === -1 ? '' : (((objects[activeObject].width + (2 * summaryImageObjectCanvasPadding / photoTransform.scale)) / photo.width * 100) + '%'),
                }"
                @dragstart.prevent
                @mousedown.prevent="objectCanvasMouseDown($event)"
                @mouseleave.prevent="objectCanvasMouseLeave()"
                @mousemove.prevent="objectCanvasMouseMove($event)"
                @touchstart.prevent="objectCanvasTouchStart($event)"
                @touchmove.prevent="objectCanvasTouchMove($event)"
              >
              </canvas>
              <!-- Rectangle for object hover -->
              <div
                v-if="hoverObject !== -1"
                class="objectRectangle"
                :style="{
                  height: (objects[hoverObject].height / photo.height * 100) + '%',
                  left: (objects[hoverObject].left / photo.width * 100) + '%',
                  top: (objects[hoverObject].top / photo.height * 100) + '%',
                  transform: 'rotate(' + objects[hoverObject].angle + 'deg)',
                  width: (objects[hoverObject].width / photo.width * 100) + '%',
                }"
                @dragstart.prevent
              ></div>
              <!-- Rectangle for active object -->
              <div
                v-if="activeObject !== -1"
                class="objectRectangle"
                :style="{
                  height: (objects[activeObject].height / photo.height * 100) + '%',
                  left: (objects[activeObject].left / photo.width * 100) + '%',
                  top: (objects[activeObject].top / photo.height * 100) + '%',
                  transform: 'rotate(' + objects[activeObject].angle + 'deg)',
                  width: (objects[activeObject].width / photo.width * 100) + '%',
                }"
                @dragstart.prevent
              ></div>
            </div>
            <!-- Rectangle handles -->
            <div
              class="rectangleHandles"
              v-show="rectangleActive && !panLock"
            >
              <div
                style="cursor: nwse-resize"
                :style="{
                  transform: 'translate3d(' + ((photoTransform.translateX + locationRect.left) * photoTransform.scale + ((availableWidth - photoTransform.visibleWidth) / 2)) + 'px, ' + ((photoTransform.translateY + locationRect.top) * photoTransform.scale + ((availableHeight - photoTransform.visibleHeight) / 2)) + 'px, 0px) translate3d(-6px, -6px, 0px)',
                  transition: animateTransition ? 'transform 0.6s ease' : ''
                }"
                @dragstart.prevent
                @mousedown.stop="rectangleHandleMouseDown($event, 'left top')"
                @touchstart.prevent="rectangleHandleTouchStart($event, 'left top')"
              ></div>
              <div
                style="cursor: ns-resize"
                :style="{ transform: 'translate3d(' + ((photoTransform.translateX + locationRect.left) * photoTransform.scale + ((availableWidth - photoTransform.visibleWidth) / 2)) + 'px, ' + ((photoTransform.translateY + locationRect.top) * photoTransform.scale + ((availableHeight - photoTransform.visibleHeight) / 2)) + 'px, 0px) translate3d(-7px, -6px, 0px) translate3d(' + (locationRect.width * photoTransform.scale / 2) + 'px, 0px, 0px)', transition: animateTransition ? 'transform 0.6s ease' : '' }"
                @dragstart.prevent
                @mousedown.stop="rectangleHandleMouseDown($event, 'center top')"
                @touchstart.prevent="rectangleHandleTouchStart($event, 'center top')"
              ></div>
              <div
                style="cursor: nesw-resize"
                :style="{ transform: 'translate3d(' + ((photoTransform.translateX + locationRect.left) * photoTransform.scale + ((availableWidth - photoTransform.visibleWidth) / 2)) + 'px, ' + ((photoTransform.translateY + locationRect.top) * photoTransform.scale + ((availableHeight - photoTransform.visibleHeight) / 2)) + 'px, 0px) translate3d(-9px, -6px, 0px) translate3d(' + (locationRect.width * photoTransform.scale) + 'px, 0px, 0px)', transition: animateTransition ? 'transform 0.6s ease' : '' }"
                @dragstart.prevent
                @mousedown.stop="rectangleHandleMouseDown($event, 'right top')"
                @touchstart.prevent="rectangleHandleTouchStart($event, 'right top')"
              ></div>
              <div
                style="cursor: ew-resize"
                :style="{ transform: 'translate3d(' + ((photoTransform.translateX + locationRect.left) * photoTransform.scale + ((availableWidth - photoTransform.visibleWidth) / 2)) + 'px, ' + ((photoTransform.translateY + locationRect.top) * photoTransform.scale + ((availableHeight - photoTransform.visibleHeight) / 2)) + 'px, 0px) translate3d(-9px, -7px, 0px) translate3d(' + (locationRect.width * photoTransform.scale) + 'px, ' + (locationRect.height * photoTransform.scale / 2) + 'px, 0px)', transition: animateTransition ? 'transform 0.6s ease' : '' }"
                @dragstart.prevent
                @mousedown.stop="rectangleHandleMouseDown($event, 'right middle')"
                @touchstart.prevent="rectangleHandleTouchStart($event, 'right middle')"
              ></div>
              <div
                style="cursor: nwse-resize"
                :style="{ transform: 'translate3d(' + ((photoTransform.translateX + locationRect.left) * photoTransform.scale + ((availableWidth - photoTransform.visibleWidth) / 2)) + 'px, ' + ((photoTransform.translateY + locationRect.top) * photoTransform.scale + ((availableHeight - photoTransform.visibleHeight) / 2)) + 'px, 0px) translate3d(-9px, -9px, 0px) translate3d(' + (locationRect.width * photoTransform.scale) + 'px, ' + (locationRect.height * photoTransform.scale) + 'px, 0px)', transition: animateTransition ? 'transform 0.6s ease' : '' }"
                @dragstart.prevent
                @mousedown.stop="rectangleHandleMouseDown($event, 'right bottom')"
                @touchstart.prevent="rectangleHandleTouchStart($event, 'right bottom')"
              ></div>
              <div
                style="cursor: ns-resize"
                :style="{ transform: 'translate3d(' + ((photoTransform.translateX + locationRect.left) * photoTransform.scale + ((availableWidth - photoTransform.visibleWidth) / 2)) + 'px, ' + ((photoTransform.translateY + locationRect.top) * photoTransform.scale + ((availableHeight - photoTransform.visibleHeight) / 2)) + 'px, 0px) translate3d(-7px, -9px, 0px) translate3d(' + (locationRect.width * photoTransform.scale / 2) + 'px, ' + (locationRect.height * photoTransform.scale) + 'px, 0px)', transition: animateTransition ? 'transform 0.6s ease' : '' }"
                @dragstart.prevent
                @mousedown.stop="rectangleHandleMouseDown($event, 'center bottom')"
                @touchstart.prevent="rectangleHandleTouchStart($event, 'center bottom')"
              ></div>
              <div
                style="cursor: nesw-resize"
                :style="{ transform: 'translate3d(' + ((photoTransform.translateX + locationRect.left) * photoTransform.scale + ((availableWidth - photoTransform.visibleWidth) / 2)) + 'px, ' + ((photoTransform.translateY + locationRect.top) * photoTransform.scale + ((availableHeight - photoTransform.visibleHeight) / 2)) + 'px, 0px) translate3d(-6px, -9px, 0px) translate3d(0px, ' + (locationRect.height * photoTransform.scale) + 'px, 0px)', transition: animateTransition ? 'transform 0.6s ease' : '' }"
                @dragstart.prevent
                @mousedown.stop="rectangleHandleMouseDown($event, 'left bottom')"
                @touchstart.prevent="rectangleHandleTouchStart($event, 'left bottom')"
              ></div>
              <div
                style="cursor: ew-resize"
                :style="{ transform: 'translate3d(' + ((photoTransform.translateX + locationRect.left) * photoTransform.scale + ((availableWidth - photoTransform.visibleWidth) / 2)) + 'px, ' + ((photoTransform.translateY + locationRect.top) * photoTransform.scale + ((availableHeight - photoTransform.visibleHeight) / 2)) + 'px, 0px) translate3d(-6px, -7px, 0px) translate3d(0px, ' + (locationRect.height * photoTransform.scale / 2) + 'px, 0px)', transition: animateTransition ? 'transform 0.6s ease' : '' }"
                @dragstart.prevent
                @mousedown.stop="rectangleHandleMouseDown($event, 'left middle')"
                @touchstart.prevent="rectangleHandleTouchStart($event, 'left middle')"
              ></div>
            </div>
            <!-- Object handles -->
            <div v-if="activeObject !== -1 && mode === 'select' && !panLock">
              <div
                class="objectHandle"
                :style="{
                  cursor: objectCursorStyles[0],
                  transform:
                    'translate3d(' + (((objects[activeObject].left + photoTransform.translateX) * photoTransform.scale) + ((availableWidth - photoTransform.visibleWidth) / 2)) + 'px, ' + (((objects[activeObject].top + photoTransform.translateY) * photoTransform.scale) + ((availableHeight - photoTransform.visibleHeight) / 2)) + 'px, 0px)' +
                    ' translate3d(' + (objects[activeObject].width / 2 * photoTransform.scale) + 'px, ' + (objects[activeObject].height / 2 * photoTransform.scale) + 'px, 0px)' +
                    ' rotate(' + objects[activeObject].angle + 'deg)' +
                    ' translate3d(' + (-objects[activeObject].width / 2 * photoTransform.scale) + 'px, ' + (-objects[activeObject].height / 2 * photoTransform.scale) + 'px, 0px)' +
                    ' translate3d(-7px, -7px, 0px)',
                  transition: animateTransition ? 'transform 0.6s ease' : ''
                }"
                @dragstart.prevent
                @mousedown.stop="objectHandleMouseDown($event, 'left top')"
                @touchstart.prevent="objectHandleTouchStart($event, 'left top')"
              ></div>
              <div
                class="objectHandle"
                :style="{
                  cursor: objectCursorStyles[1],
                  transform:
                    'translate3d(' + (((objects[activeObject].left + photoTransform.translateX) * photoTransform.scale) + ((availableWidth - photoTransform.visibleWidth) / 2)) + 'px, ' + (((objects[activeObject].top + photoTransform.translateY) * photoTransform.scale) + ((availableHeight - photoTransform.visibleHeight) / 2)) + 'px, 0px)' +
                    ' translate3d(' + (objects[activeObject].width / 2 * photoTransform.scale) + 'px, ' + (objects[activeObject].height / 2 * photoTransform.scale) + 'px, 0px)' +
                    ' rotate(' + objects[activeObject].angle + 'deg)' +
                    ' translate3d(' + (-objects[activeObject].width / 2 * photoTransform.scale) + 'px, ' + (-objects[activeObject].height / 2 * photoTransform.scale) + 'px, 0px)' +
                    ' translate3d(-7px, -7px, 0px)' +
                    ' translate3d(' + (objects[activeObject].width * photoTransform.scale / 2) + 'px, 0px, 0px)',
                  transition: animateTransition ? 'transform 0.6s ease' : ''
                }"
                @dragstart.prevent
                @mousedown.stop="objectHandleMouseDown($event, 'center top')"
                @touchstart.prevent="objectHandleTouchStart($event, 'center top')"
              ></div>
              <div
                class="objectHandle"
                :style="{
                  cursor: objectCursorStyles[2],
                  transform:
                    'translate3d(' + (((objects[activeObject].left + photoTransform.translateX) * photoTransform.scale) + ((availableWidth - photoTransform.visibleWidth) / 2)) + 'px, ' + (((objects[activeObject].top + photoTransform.translateY) * photoTransform.scale) + ((availableHeight - photoTransform.visibleHeight) / 2)) + 'px, 0px)' +
                    ' translate3d(' + (objects[activeObject].width / 2 * photoTransform.scale) + 'px, ' + (objects[activeObject].height / 2 * photoTransform.scale) + 'px, 0px)' +
                    ' rotate(' + objects[activeObject].angle + 'deg)' +
                    ' translate3d(' + (-objects[activeObject].width / 2 * photoTransform.scale) + 'px, ' + (-objects[activeObject].height / 2 * photoTransform.scale) + 'px, 0px)' +
                    ' translate3d(-9px, -7px, 0px)' +
                    ' translate3d(' + (objects[activeObject].width * photoTransform.scale) + 'px, 0px, 0px)',
                  transition: animateTransition ? 'transform 0.6s ease' : ''
                }"
                @dragstart.prevent
                @mousedown.stop="objectHandleMouseDown($event, 'right top')"
                @touchstart.prevent="objectHandleTouchStart($event, 'right top')"
              ></div>
              <div
                class="objectHandle"
                :style="{
                  cursor: objectCursorStyles[3],
                  transform:
                    'translate3d(' + (((objects[activeObject].left + photoTransform.translateX) * photoTransform.scale) + ((availableWidth - photoTransform.visibleWidth) / 2)) + 'px, ' + (((objects[activeObject].top + photoTransform.translateY) * photoTransform.scale) + ((availableHeight - photoTransform.visibleHeight) / 2)) + 'px, 0px)' +
                    ' translate3d(' + (objects[activeObject].width / 2 * photoTransform.scale) + 'px, ' + (objects[activeObject].height / 2 * photoTransform.scale) + 'px, 0px)' +
                    ' rotate(' + objects[activeObject].angle + 'deg)' +
                    ' translate3d(' + (-objects[activeObject].width / 2 * photoTransform.scale) + 'px, ' + (-objects[activeObject].height / 2 * photoTransform.scale) + 'px, 0px)' +
                    ' translate3d(-9px, -7px, 0px)' +
                    ' translate3d(' + (objects[activeObject].width * photoTransform.scale) + 'px, ' + (objects[activeObject].height * photoTransform.scale / 2) + 'px, 0px)',
                  transition: animateTransition ? 'transform 0.6s ease' : ''
                }"
                @dragstart.prevent
                @mousedown.stop="objectHandleMouseDown($event, 'right middle')"
                @touchstart.prevent="objectHandleTouchStart($event, 'right middle')"
              ></div>
              <div
                class="objectHandle"
                :style="{
                  cursor: objectCursorStyles[4],
                  transform:
                    'translate3d(' + (((objects[activeObject].left + photoTransform.translateX) * photoTransform.scale) + ((availableWidth - photoTransform.visibleWidth) / 2)) + 'px, ' + (((objects[activeObject].top + photoTransform.translateY) * photoTransform.scale) + ((availableHeight - photoTransform.visibleHeight) / 2)) + 'px, 0px)' +
                    ' translate3d(' + (objects[activeObject].width / 2 * photoTransform.scale) + 'px, ' + (objects[activeObject].height / 2 * photoTransform.scale) + 'px, 0px)' +
                    ' rotate(' + objects[activeObject].angle + 'deg)' +
                    ' translate3d(' + (-objects[activeObject].width / 2 * photoTransform.scale) + 'px, ' + (-objects[activeObject].height / 2 * photoTransform.scale) + 'px, 0px)' +
                    ' translate3d(-9px, -9px, 0px)' +
                    ' translate3d(' + (objects[activeObject].width * photoTransform.scale) + 'px, ' + (objects[activeObject].height * photoTransform.scale) + 'px, 0px)',
                  transition: animateTransition ? 'transform 0.6s ease' : ''
                }"
                @dragstart.prevent
                @mousedown.stop="objectHandleMouseDown($event, 'right bottom')"
                @touchstart.prevent="objectHandleTouchStart($event, 'right bottom')"
              ></div>
              <div
                class="objectHandle"
                :style="{
                  cursor: objectCursorStyles[5],
                  transform:
                    'translate3d(' + (((objects[activeObject].left + photoTransform.translateX) * photoTransform.scale) + ((availableWidth - photoTransform.visibleWidth) / 2)) + 'px, ' + (((objects[activeObject].top + photoTransform.translateY) * photoTransform.scale) + ((availableHeight - photoTransform.visibleHeight) / 2)) + 'px, 0px)' +
                    ' translate3d(' + (objects[activeObject].width / 2 * photoTransform.scale) + 'px, ' + (objects[activeObject].height / 2 * photoTransform.scale) + 'px, 0px)' +
                    ' rotate(' + objects[activeObject].angle + 'deg)' +
                    ' translate3d(' + (-objects[activeObject].width / 2 * photoTransform.scale) + 'px, ' + (-objects[activeObject].height / 2 * photoTransform.scale) + 'px, 0px)' +
                    ' translate3d(-7px, -9px, 0px)' +
                    ' translate3d(' + (objects[activeObject].width * photoTransform.scale / 2) + 'px, ' + (objects[activeObject].height * photoTransform.scale) + 'px, 0px)',
                  transition: animateTransition ? 'transform 0.6s ease' : ''
                }"
                @dragstart.prevent
                @mousedown.stop="objectHandleMouseDown($event, 'center bottom')"
                @touchstart.prevent="objectHandleTouchStart($event, 'center bottom')"
              ></div>
              <div
                class="objectHandle"
                :style="{
                  cursor: objectCursorStyles[6],
                  transform:
                    'translate3d(' + (((objects[activeObject].left + photoTransform.translateX) * photoTransform.scale) + ((availableWidth - photoTransform.visibleWidth) / 2)) + 'px, ' + (((objects[activeObject].top + photoTransform.translateY) * photoTransform.scale) + ((availableHeight - photoTransform.visibleHeight) / 2)) + 'px, 0px)' +
                    ' translate3d(' + (objects[activeObject].width / 2 * photoTransform.scale) + 'px, ' + (objects[activeObject].height / 2 * photoTransform.scale) + 'px, 0px)' +
                    ' rotate(' + objects[activeObject].angle + 'deg)' +
                    ' translate3d(' + (-objects[activeObject].width / 2 * photoTransform.scale) + 'px, ' + (-objects[activeObject].height / 2 * photoTransform.scale) + 'px, 0px)' +
                    ' translate3d(-7px, -9px, 0px)' +
                    ' translate3d(0px, ' + (objects[activeObject].height * photoTransform.scale) + 'px, 0px)',
                  transition: animateTransition ? 'transform 0.6s ease' : ''
                }"
                @dragstart.prevent
                @mousedown.stop="objectHandleMouseDown($event, 'left bottom')"
                @touchstart.prevent="objectHandleTouchStart($event, 'left bottom')"
              ></div>
              <div
                class="objectHandle"
                :style="{
                  cursor: objectCursorStyles[7],
                  transform:
                    'translate3d(' + (((objects[activeObject].left + photoTransform.translateX) * photoTransform.scale) + ((availableWidth - photoTransform.visibleWidth) / 2)) + 'px, ' + (((objects[activeObject].top + photoTransform.translateY) * photoTransform.scale) + ((availableHeight - photoTransform.visibleHeight) / 2)) + 'px, 0px)' +
                    ' translate3d(' + (objects[activeObject].width / 2 * photoTransform.scale) + 'px, ' + (objects[activeObject].height / 2 * photoTransform.scale) + 'px, 0px)' +
                    ' rotate(' + objects[activeObject].angle + 'deg)' +
                    ' translate3d(' + (-objects[activeObject].width / 2 * photoTransform.scale) + 'px, ' + (-objects[activeObject].height / 2 * photoTransform.scale) + 'px, 0px)' +
                    ' translate3d(-7px, -7px, 0px)' +
                    ' translate3d(0px, ' + (objects[activeObject].height * photoTransform.scale / 2) + 'px, 0px)',
                  transition: animateTransition ? 'transform 0.6s ease' : ''
                }"
                @dragstart.prevent
                @mousedown.stop="objectHandleMouseDown($event, 'left middle')"
                @touchstart.prevent="objectHandleTouchStart($event, 'left middle')"
              ></div>
              <div
                class="objectHandle rotationHandle"
                :style="{
                  transform:
                    'translate3d(' + (((objects[activeObject].left + photoTransform.translateX) * photoTransform.scale) + ((availableWidth - photoTransform.visibleWidth) / 2)) + 'px, ' + (((objects[activeObject].top + photoTransform.translateY) * photoTransform.scale) + ((availableHeight - photoTransform.visibleHeight) / 2)) + 'px, 0px)' +
                    ' translate3d(' + (objects[activeObject].width / 2 * photoTransform.scale) + 'px, ' + (objects[activeObject].height / 2 * photoTransform.scale) + 'px, 0px)' +
                    ' rotate(' + objects[activeObject].angle + 'deg)' +
                    ' translate3d(' + (-objects[activeObject].width / 2 * photoTransform.scale) + 'px, ' + (-objects[activeObject].height / 2 * photoTransform.scale) + 'px, 0px)' +
                    ' translate3d(-7px, -7px, 0px)' +
                    ' translate3d(' + (objects[activeObject].width * photoTransform.scale / 2) + 'px, -40px, 0px)',
                  transition: animateTransition ? 'transform 0.6s ease' : ''
                }"
                @dragstart.prevent
                @mousedown.stop="objectRotationMouseDown($event)"
                @touchstart.prevent="objectRotationTouchStart($event)"
              ></div>
              <div
                class="objectRotationLine"
                v-if="activeObject !== -1 && mode === 'select' && !panLock"
                :style="{
                  transform:
                    'translate3d(' + (((objects[activeObject].left + photoTransform.translateX) * photoTransform.scale) + ((availableWidth - photoTransform.visibleWidth) / 2)) + 'px, ' + (((objects[activeObject].top + photoTransform.translateY) * photoTransform.scale) + ((availableHeight - photoTransform.visibleHeight) / 2)) + 'px, 0px)' +
                    ' translate3d(' + (objects[activeObject].width / 2 * photoTransform.scale) + 'px, ' + (objects[activeObject].height / 2 * photoTransform.scale) + 'px, 0px)' +
                    ' rotate(' + objects[activeObject].angle + 'deg)' +
                    ' translate3d(' + (-objects[activeObject].width / 2 * photoTransform.scale) + 'px, ' + (-objects[activeObject].height / 2 * photoTransform.scale) + 'px, 0px)' +
                    ' translate3d(' + (objects[activeObject].width * photoTransform.scale / 2) + 'px, -40px, 0px)',
                  transition: animateTransition ? 'transform 0.6s ease' : ''
                }"
                @dragstart.prevent
              ></div>
            </div>
          </div>
          <div :class="{'narrowButtons': !tabsText}" style="margin-top: 0.5rem">
            <!-- Pan Lock button with tooltip -->
            <button
              v-if="!touch"
              id="panLockButton"
              class="ui icon button summaryImageButton"
              :class="{active: panLock}"
              :data-tooltip="text.memoSetPanLock"
              data-position="top left"
              @click="panLockClick()"
            >
              <i :class="{'lock icon': panLock, 'unlock icon': !panLock}"></i>
            </button>
            <!-- Pan Lock button without tooltip -->
            <button
              v-if="touch"
              id="panLockButton"
              class="ui icon button summaryImageButton"
              :class="{active: panLock}"
              @click="panLockClick()"
            >
              <i :class="{'lock icon': panLock, 'unlock icon': !panLock}"></i>
            </button>
            <!-- Zoom In button with tooltip -->
            <button
              v-if="!touch"
              class="ui icon button summaryImageButton"
              :data-tooltip="text.memoSetZoomIn"
              @click="zoomIn(1.7, $event, null, true)"
            >
              <i class="zoom in icon"></i>
            </button>
            <!-- Zoom In button without tooltip -->
            <button
              v-if="touch"
              class="ui icon button summaryImageButton"
              @click="zoomIn(1.7, $event, null, true)"
            >
              <i class="zoom in icon"></i>
            </button>
            <!-- Zoom Out button with tooltip -->
            <div
              v-if="!touch"
              style="display: inline-block"
              :data-tooltip="text.memoSetZoomOut"
            >
              <button
                class="ui icon button summaryImageButton"
                :class="{disabled: photoTransform.visibleWidth === photo.width * photoTransform.scale && photoTransform.visibleHeight === photo.height * photoTransform.scale}"
                @click="zoomOut(1.7, $event, null, true)"
              >
                <i class="zoom out icon"></i>
              </button>
            </div>
            <!-- Zoom Out button without tooltip -->
            <button
              v-if="touch"
              class="ui icon button summaryImageButton"
              :class="{disabled: photoTransform.visibleWidth === photo.width * photoTransform.scale && photoTransform.visibleHeight === photo.height * photoTransform.scale}"
              @click="zoomOut(1.7, $event, null, true)"
            >
              <i class="zoom out icon"></i>
            </button>
            <!-- Zoom to Selected button with tooltip -->
            <button
              v-if="!touch"
              id="zoomToSelectedButton"
              class="ui icon button summaryImageButton"
              :data-tooltip="text.memoSetZoomToSelected"
              @click="zoomToSelected()"
            >
              <i class="expand icon"></i>
            </button>
            <!-- Zoom to Selected button without tooltip -->
            <button
              v-if="touch"
              id="zoomToSelectedButton"
              class="ui icon button summaryImageButton"
              @click="zoomToSelected()"
            >
              <i class="expand icon"></i>
            </button>
            <!-- Redo button with tooltip -->
            <div
              v-if="!touch"
              style="float: right"
              :data-tooltip="text.commonRedo"
              data-position="top right"
            >
              <button
                id="redoButton"
                class="ui icon button summaryImageButton"
                :class="{disabled: summaryImage.undoArrayIndex === summaryImage.undoArrayIndexMax}"
                @click="summaryImageRedoClick()"
              >
                <i class="redo alternate icon"></i>
              </button>
            </div>
            <!-- Redo button without tooltip -->
            <button
              v-if="touch"
              id="redoButton"
              class="ui right floated icon button summaryImageButton"
              :class="{disabled: summaryImage.undoArrayIndex === summaryImage.undoArrayIndexMax}"
              @click="summaryImageRedoClick()"
            >
              <i class="redo alternate icon"></i>
            </button>
            <!-- Undo button with tooltip -->
            <div
              v-if="!touch"
              style="float: right"
              :data-tooltip="text.commonUndo"
            >
              <button
                id="undoButton"
                class="ui icon button summaryImageButton"
                :class="{disabled: !summaryImage.undoArrayIndex}"
                @click="summaryImageUndoClick()"
              >
                <i class="undo alternate icon"></i>
              </button>
            </div>
            <!-- Undo button without tooltip -->
            <button
              v-if="touch"
              id="undoButton"
              class="ui right floated icon button summaryImageButton"
              :class="{disabled: !summaryImage.undoArrayIndex}"
              @click="summaryImageUndoClick()"
            >
              <i class="undo alternate icon"></i>
            </button>
          </div>
          <div class="thinScroll" style="margin-top: 0.5rem; overflow-x: auto; padding-bottom: 0.5rem; white-space: nowrap">
            <!-- Add Image button -->
            <button
              class="ui button summaryImageButton"
              @click="addImageClick($event)"
            >
              <i class="plus icon"></i>
              {{ text.memoSetAddImage }}
            </button>
            <!-- Add Text button -->
            <button
              class="ui button summaryImageButton"
              @click="addTextClick($event)"
            >
              <i class="plus icon"></i>
              {{ text.memoSetAddText }}
            </button>
            <!-- Send to back button -->
            <button
              class="ui button summaryImageButton"
              :class="{disabled: activeObject === -1}"
              v-show="activeObject !== -1 && activeObjectOverlap"
              @click="sendToBackClick($event)"
            >
              <i class="arrow down icon"></i>
              {{ text.memoSetSendToBack }}
            </button>
            <!-- Clip button -->
            <button
              id="clipButton"
              class="ui button summaryImageButton"
              :class="{ active: mode === 'clip' }"
              v-show="activeObject !== -1 && !objects[activeObject].text"
              @click="clipClick()"
            >
              <i class="cut icon"></i>
              {{ text.memoSetClip }}
            </button>
            <!-- Draw Transparent button -->
            <button
              id="drawTransparentButton"
              class="ui button summaryImageButton"
              :class="{ active: mode === 'draw' }"
              v-show="activeObject !== -1 && !objects[activeObject].text"
              @click="drawTransparentClick()"
            >
              <i class="eraser icon"></i>
              {{ text.memoSetErase }}
            </button>
            <!-- Remove Color button -->
            <button
              id="removeColorButton"
              class="ui button summaryImageButton"
              :class="{ active: mode === 'remove' }"
              v-show="activeObject !== -1 && !objects[activeObject].text"
              @click="removeColorClick()"
            >
              <i class="eye dropper icon"></i>
              {{ text.memoSetRemoveColor }}
            </button>
            <!-- Flip Horizontal button -->
            <button
              class="ui button summaryImageButton"
              v-show="activeObject !== -1 && !objects[activeObject].text"
              @click="flipClick($event, 'x')"
            >
              <i class="arrows alternate horizontal icon"></i>
              {{ text.memoSetFlipHorizontal }}
            </button>
            <!-- Flip Vertical button -->
            <button
              class="ui button summaryImageButton"
              v-show="activeObject !== -1 && !objects[activeObject].text"
              @click="flipClick($event, 'y')"
            >
              <i class="arrows alternate vertical icon"></i>
              {{ text.memoSetFlipVertical }}
            </button>
            <!-- Delete Image button -->
            <button
              id="deleteImageButton"
              class="ui button summaryImageButton"
              v-show="activeObject !== -1 && !objects[activeObject].text"
              @click="deleteImageClick()"
            >
              <i class="trash alternate icon"></i>
              {{ text.memoSetDeleteImage }}
            </button>
            <!-- Delete Text button -->
            <button
              class="ui button summaryImageButton"
              v-show="activeObject !== -1 && objects[activeObject].text"
              @click="deleteImageClick"
            >
            <i class="trash alternate icon"></i>
              {{ text.memoSetDeleteText }}
            </button>
          </div>
        </div>
      </div>
      <div class="actions" v-show="summaryImage.page === 'summary'">
        <div class="ui primary ok button">{{ text.commonOK }}</div>
        <button class="ui cancel button">{{ text.commonCancel}}</button>
      </div>
    </div>
    <div class="ui fullscreen modal walkthrough fullHeight fullscreenModal" :class="modalClass">
      <div class="content noPadding">
        <div id="walkthroughMaxWidth"></div>
        <button class="ui large compact icon button closeButton" @click="closeWalkthrough()">
          <i class="large close icon"></i>
        </button>
        <div
          class="walkthroughWrapper"
          :style="{
            height: availableHeight + 'px',
            width: availableWidth + 'px',
          }"
          v-if="modal === 'walkthrough'"
        >
          <div
            style="position: relative"
            :style="{
              opacity: photoOpacity,
              transition: animateTransition ? 'opacity 0.5s ease' : ''
            }"
          >
            <div
              class="walkthroughView"
              :style="{
                height: (photo.height * photoTransform.scale) + 'px',
                marginLeft: (photoTransform.translateX * photoTransform.scale + ((availableWidth - photoTransform.visibleWidth) / 2)) + 'px',
                marginTop: (photoTransform.translateY * photoTransform.scale + ((availableHeight - photoTransform.visibleHeight) / 2)) + 'px',
                transition: animateTransitionFast ? 'all 0.2s ease-out' : (animateTransition && !photoTransition ? 'all 0.7s cubic-bezier(0.37, 0, 0.63, 1)' : ''),
                width: (photo.width * photoTransform.scale) + 'px'
              }"
            >
              <!-- Background photo -->
              <img
                v-if="walkthrough.steps && walkthrough.steps[walkthrough.stepNum] && walkthrough.steps[walkthrough.stepNum].photo"
                :src="walkthrough.steps[walkthrough.stepNum].photo.url"
                style="width: 100%"
              >
              <!-- Location rectangle -->
              <div
                v-if="walkthrough.steps[walkthrough.stepNum].photo"
                class="walkthroughLocationRectangle"
                :style="{
                  height: (walkthrough.steps[walkthrough.stepNum].location.height / photo.height * 100) + '%',
                  left: (walkthrough.steps[walkthrough.stepNum].location.left / photo.width * 100) + '%',
                  top: (walkthrough.steps[walkthrough.stepNum].location.top / photo.height * 100) + '%',
                  width: (walkthrough.steps[walkthrough.stepNum].location.width / photo.width * 100) + '%'
                }"
              >
              </div>
              <!-- Rectangle for no photo -->
              <div
                v-if="!walkthrough.steps[walkthrough.stepNum].photo"
                class="noPhotoRectWalkthrough"
              ></div>
              <!-- Object images -->
              <div v-if="walkthrough.steps && walkthrough.steps[walkthrough.stepNum]" style="height: 100%; width: 100%">
                <img
                  v-for="obj in walkthroughDomObjects"
                  v-show="objectsVisible && walkthrough.steps[walkthrough.stepNum].objectKeys.includes(obj.key)"
                  :key="'walkthroughObj_' + obj.key"
                  :style="{
                    height: (obj.height / photo.height * 100) + '%',
                    left: (obj.left / photo.width * 100) + '%',
                    transform:
                      'rotate(' + obj.angle + 'deg)' +
                      (obj.flipX ? ' scaleX(-1)' : '') +
                      (obj.flipY ? ' scaleY(-1)' : ''),
                    top: (obj.top / photo.height * 100) + '%',
                    width: (obj.width / photo.width * 100) + '%',
                    zIndex: obj.sequence + 1
                  }"
                  :src="obj.url"
                  crossorigin="anonymous"
                >
              </div>
            </div>
          </div>
        </div>
        <div id="walkthroughData" class="ui grid walkthroughData" :class="{'dataHidden': !dataVisible || walkthrough.steps[walkthrough.stepNum].summary, 'mobile': mobile}">
          <div class="row noBottomPadding" :class="{'noTopPadding': mobile}">
            <table v-if="fields && walkthrough.steps[walkthrough.stepNum]" class="ui celled selectable unstackable table walkthroughDataTable">
              <thead>
                <tr class="middle aligned">
                  <th v-for="field in fields" :key="field.fieldId" class="two wide" :class="{hideField: mobile && !rows[walkthrough.steps[walkthrough.stepNum].rowIndex].data[field.fieldId]}">
                    {{ field.heading }}
                  </th>
                  <th class="three wide" :class="{hideField: mobile && !rows[walkthrough.steps[walkthrough.stepNum].rowIndex].data.notes}">{{ text.memoSetNotes }}</th>
                </tr>
              </thead>
              <tbody>
                <tr class="middle aligned" @click="walkthroughGoToRowClick()">
                  <td v-for="field in fields" :key="field.fieldId" :class="{cardsCell: cardStyle(rows[walkthrough.steps[walkthrough.stepNum].rowIndex].data[field.fieldId]), hideField: mobile && !rows[walkthrough.steps[walkthrough.stepNum].rowIndex].data[field.fieldId]}" :style="cardStyle(rows[walkthrough.steps[walkthrough.stepNum].rowIndex].data[field.fieldId])">
                    <div v-html="sanitize(rows[walkthrough.steps[walkthrough.stepNum].rowIndex].data[field.fieldId])"></div>
                  </td>
                  <!-- Notes -->
                  <td :class="{hideField: mobile && !rows[walkthrough.steps[walkthrough.stepNum].rowIndex].data.notes}">
                    <div v-html="sanitize(rows[walkthrough.steps[walkthrough.stepNum].rowIndex].data.notes)"></div>
                    <!-- Add a space if no content, to ensure row height is maintained -->
                    <span v-show="!sanitize(rows[walkthrough.steps[walkthrough.stepNum].rowIndex].data.notes)">&nbsp;</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="ui centered grid walkthroughGrid" :class="{'noPadding': mobile}">
          <div class="row">
            <button
              class="ui compact button"
              :class="{green: objectsVisible, icon: !walkthroughLabelsVisible, walkthroughButtonDesktop: walkthroughLabelsVisible, walkthroughButtonMid: !mobile && !walkthroughLabelsVisible, walkthroughButtonMobile: mobile}"
              v-show="walkthrough.objects.length"
              @click="showObjectsClick($event)"
              @touchstart.prevent="showObjectsClick($event)"
            >
              <i class="large eye icon"></i><span v-show="walkthroughLabelsVisible" class="alignButtonLabel">{{ text.memoSetImages }}</span>
            </button>
            <button
              class="ui compact button"
              :class="{green: dataVisible, icon: !walkthroughLabelsVisible, walkthroughButtonDesktop: walkthroughLabelsVisible, walkthroughButtonMid: !mobile && !walkthroughLabelsVisible, walkthroughButtonMobile: mobile, noImages: !walkthrough.objects.length}"
              @click="showDataClick($event)"
              @touchstart.prevent="showDataClick($event)"
            >
              <i class="large table icon"></i><span v-show="walkthroughLabelsVisible" class="alignButtonLabel">{{ text.memoSetData }}</span>
            </button>
            <div id="walkthroughSlider" class="ui teal labeled ticked slider" :class="{sliderMid: !mobile && !walkthroughLabelsVisible, sliderMobile: mobile, noImages: !walkthrough.objects.length}"></div>
            <button
              class="ui compact primary button"
              :class="{disabled: walkthrough.stepNum === 0, icon: !walkthroughLabelsVisible,walkthroughButtonDesktop: walkthroughLabelsVisible, walkthroughButtonMid: !mobile && !walkthroughLabelsVisible, walkthroughButtonMobile: mobile}"
              @click="walkthroughMove(-1, 'button', $event)"
              @touchstart.prevent="walkthroughMove(-1, 'button', $event)"
            >
              <i class="large left arrow icon"></i><span v-show="walkthroughLabelsVisible" class="alignButtonLabel">{{ text.memoSetPrevious }}</span>
            </button>
            <button
              class="ui compact primary button noRightMargin"
              :class="{disabled: walkthrough.stepNum === walkthrough.steps.length - 1, icon: !walkthroughLabelsVisible, walkthroughButtonDesktop: walkthroughLabelsVisible, walkthroughButtonMid: !mobile && !walkthroughLabelsVisible, walkthroughButtonMobile: mobile}"
              @click="walkthroughMove(1, 'button', $event)"
              @touchstart.prevent="walkthroughMove(1, 'button', $event)"
            >
              <span v-show="walkthroughLabelsVisible" class="alignButtonLabel">{{ text.memoSetNext }}</span><i class="large right arrow icon"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div v-if="mobile && touch" v-show="currentRow !== -1" class="ui segment mobileCellToolbar" @mousedown.prevent.stop>
      <div class="ui icon button" v-show="currentFieldId !== 'reviewGroup'" @mousedown.prevent.stop="addCellImageClick(currentRow, currentFieldId)">
        <i class="image icon"></i>
      </div>
      <div class="ui icon button" v-show="currentFieldId !== 'reviewGroup' && currentFieldId !== 'notes'" @mousedown.prevent.stop="addCellAudioClick(currentRow, currentFieldId)">
        <i class="volume down icon"></i>
      </div>
      <div class="ui icon button" v-show="currentFieldId !== 'reviewGroup'" :class="{disabled: !currentFieldContent}" @mousedown.prevent.stop="newLine(currentRow, currentFieldId)">
        <i class="clockwise rotated level down alternate icon"></i>
      </div>
      <div class="ui icon button" v-if="currentFieldId === 'reviewGroup' && currentRow !== -1 && rows[currentRow].data.reviewGroup" @mousedown.stop="setGroupColorClick(currentRow)">
        <i class="square icon" :class="reviewGroupColors[rows[currentRow].data.reviewGroup || '']"></i>
      </div>
      <div class="ui icon button" :class="{disabled: currentRow === rows.length - 1}" @mousedown.stop="copyDownClick(currentRow, currentFieldId)">
        <i class="angle double down icon"></i>
      </div>
      <div class="ui icon button" :class="{disabled: !currentFieldContent}" @mousedown.prevent.stop="deleteCellContents(currentRow, currentFieldId)">
        <i class="trash alternate icon"></i>
      </div>
      <div class="ui right floated icon button" @mousedown.stop="$event.target.blur()">
        <i class="check icon"></i>
      </div>
    </div>
    <div v-if="mobile && touch" v-show="editingTitle || editingDescription || editingHeading" class="ui segment mobileTextToolbar" @mousedown.prevent.stop>
      <div class="ui right floated icon button" @mousedown.stop="$event.target.blur()">
        <i class="check icon"></i>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumbs from '@/components/Breadcrumbs'
import StatusBar from '@/components/StatusBar'
import MemoSetMethods from '@/mixins/MemoSetMethods'
const $ = window.$
const firebase = window.firebase
const db = firebase.firestore()
const MagicWand = window.MagicWand
// Define cache control for uploaded objects
const cacheControl = 'public, max-age=2592000, immutable'
// Define radius of draw transparent circle, in pixels
const drawTransparentRadius = 30
// Define number of learn questions to be preloaded
const learnPreloadCount = 3
// Define duration for autosaving learning data
const learnTimeoutDuration = 10000
// Define initial duration for game, in seconds
const gameFallDuration = 20
// Define multipliers for game fall duration
const gameFactor1 = 0.92
const gameFactor2 = 0.96
// Define limits for uploaded files
const maxAudioDuration = 10000
const maxAudioSize = 2000000
const maxCellImageSize = 400
const maxCoverImageHeight = 1000
const maxCoverImageWidth = 2000
const maxImageSize = 3000
// Define size of background with no photo
const noPhotoSize = 1000
// Define number of rows to be stored in each Firestore document
const rowsPerDocument = 100

function extensionForFileType (fileType) {
  if (fileType === 'image/png') return '.png'
  if (fileType === 'image/jpeg') return '.jpg'
  if (fileType === 'image/gif') return '.gif'
  return ''
}

// Function based on https://stackoverflow.com/a/15013465
function extractImageUrlsFromHtml (html) {
  let decodedUrl, match, regex, urls
  regex = /<img[^>]+src="([^">]+)/g
  urls = []
  match = regex.exec(html)
  while (match) {
    // Replace &amp; with &, etc.
    decodedUrl = htmlDecode(match[1])
    urls.push(decodedUrl)
    match = regex.exec(html)
  }
  // Ignore data images
  urls = urls.filter(url => url.substring(0, 5) !== 'data:')
  return urls
}

function extractAudioUrlsFromHtml (html) {
  let decodedUrl, match, regex, urls
  regex = /<audio[^>]+src="([^">]+)/g
  urls = []
  match = regex.exec(html)
  while (match) {
    // Replace &amp; with &, etc.
    decodedUrl = htmlDecode(match[1])
    urls.push(decodedUrl)
    match = regex.exec(html)
  }
  return urls
}

function getCardNum (cardString, cardSet) {
  let base, value, valueString
  base = -1
  value = 0
  cardString = cardString.toUpperCase()
  switch (cardString.slice(-1)) {
    case 'C':
      base = 0
      break
    case 'D':
      base = 13
      break
    case 'H':
      base = 26
      break
    case 'S':
      base = 39
      break
  }
  if (base !== -1) {
    valueString = cardString.substring(0, cardString.length - 1)
    switch (valueString) {
      case 'A':
      case '1':
        value = 1
        break
      case 'B':
      case 'J':
        value = 11
        break
      case 'D':
      case 'Q':
        value = 12
        break
      case 'K':
        value = 13
        break
      default:
        if ((valueString.length === 1 && valueString >= '2' && valueString <= '9') || valueString === '10') {
          value = parseInt(valueString, 10)
        }
        break
    }
    if (value) {
      return base + value - 1
    }
  }
  return -1
}

// Function from https://stackoverflow.com/a/34064434
function htmlDecode (input) {
  var doc = new DOMParser().parseFromString(input, 'text/html')
  return doc.documentElement.textContent
}

// Function based on https://stackoverflow.com/a/7473638
function isCaretAtEnd (element) {
  let nextText, postRange, range
  range = window.getSelection().getRangeAt(0)
  postRange = document.createRange()
  postRange.selectNodeContents(element)
  postRange.setStart(range.endContainer, range.endOffset)
  nextText = postRange.cloneContents()
  return (nextText.textContent.length === 0)
}

function isImageFile (file) {
  // Note: file.type is blank on Android if there is no file extension
  return (
    file.type.substring(0, 6) === 'image/' ||
    !file.name.includes('.')
  )
}

function logError (errorCode, error) {
  console.log(errorCode)
  console.log(error)
}

function memoSetType (routePath) {
  let output
  if (routePath.substring(0, 10) === '/memo-sets') {
    output = 'regular'
  } else if (routePath.substring(0, 18) === '/example-memo-sets') {
    output = 'example'
  } else if (routePath.substring(0, 17) === '/public-memo-sets') {
    output = 'public'
  } else {
    output = 'shared'
  }
  return output
}

function moveCursorToEndOfContentEditable (element) {
  let range, selection
  // Move cursor to end of field
  range = document.createRange()
  range.selectNodeContents(element)
  range.collapse(false)
  selection = window.getSelection()
  selection.removeAllRanges()
  selection.addRange(range)
}

// Function from https://css-tricks.com/snippets/javascript/move-cursor-to-end-of-input/
function moveCursorToEndOfInput (el) {
  if (typeof el.selectionStart === 'number') {
    el.selectionStart = el.selectionEnd = el.value.length
  } else if (typeof el.createTextRange !== 'undefined') {
    el.focus()
    var range = el.createTextRange()
    range.collapse(false)
    range.select()
  }
}

// Function from https://stackoverflow.com/a/6691294
function pasteHtmlAtCaret (html) {
  let el, frag, lastNode, node, sel, range
  if (window.getSelection) {
    // IE9 and non-IE
    sel = window.getSelection()
    if (sel.getRangeAt && sel.rangeCount) {
      range = sel.getRangeAt(0)
      range.deleteContents()
      // Range.createContextualFragment() would be useful here but is
      // only relatively recently standardized and is not supported in
      // some browsers (IE9, for one)
      el = document.createElement('div')
      el.innerHTML = html
      frag = document.createDocumentFragment()
      while ((node = el.firstChild)) {
        lastNode = frag.appendChild(node)
      }
      range.insertNode(frag)
      // Preserve the selection
      if (lastNode) {
        range = range.cloneRange()
        range.setStartAfter(lastNode)
        range.collapse(true)
        sel.removeAllRanges()
        sel.addRange(range)
      }
    }
  } else if (document.selection && document.selection.type !== 'Control') {
    // IE < 9
    document.selection.createRange().pasteHTML(html)
  }
}

// Function from https://stackoverflow.com/questions/6139107/programmatically-select-text-in-a-contenteditable-html-element
function selectElementContents (el) {
  var range = document.createRange()
  range.selectNodeContents(el)
  var sel = window.getSelection()
  sel.removeAllRanges()
  sel.addRange(range)
}

// Function from https://stackoverflow.com/a/2450976
function shuffle(array) {
  let currentIndex = array.length,  randomIndex
  // While there remain elements to shuffle
  while (currentIndex > 0) {
    // Pick a remaining element
    randomIndex = Math.floor(Math.random() * currentIndex)
    currentIndex--
    // And swap it with the current element
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex], array[currentIndex]]
  }
  return array
}

export default {
  name: 'memo-set',
  components: {
    Breadcrumbs,
    StatusBar
  },
  mixins: [MemoSetMethods],
  data () {
    return {
      active: true,
      activeObject: -1,
      activeObjectOverlap: false,
      activeQuestions: [],
      activeTab: '',
      addColumn: {
        columnHeading: '',
        columnPosition: ''
      },
      addCellAudio: {
        file: null,
        loading: false,
        microphoneError: false,
        recording: false,
        stream: null,
        uploading: false,
        url: ''
      },
      addCellImage: {
        autoSubmit: false,
        file: null,
        loading: false,
        uploading: false,
        url: ''
      },
      addImage: {
        file: null,
        loading: false,
        url: ''
      },
      addPhoto: {
        file: null,
        loading: false,
        uploading: false,
        url: ''
      },
      addRows: {
        firestoreNewDocs: {},
        firestoreUpdateDocs: {},
        insertAfterRow: '',
        numberOfRows: ''
      },
      animateTransition: false,
      animateTransitionFast: false,
      applyToRows: '',
      audioRecorder: null,
      authorId: '',
      authorName: '',
      availableHeight: 0,
      availableWidth: 0,
      backCloses: '',
      backgroundPhotoTab: '',
      cachedSanitize: {},
      cachedSanitizeWithStyle: {},
      cellChanges: {
        cells: [],
        rowQuestions: [],
        rowsAdded: 0
      },
      clipping: false,
      clippingPath: [],
      copyDown: {
        displayValue: '',
        value: ''
      },
      copyMemoSet: {
        folderId: '',
        newFolderName: '',
        newMemoSetId: '',
        newTitle: '',
        slug: ''
      },
      coverImage: '',
      coverImageLoaded: false,
      coverImageTemp: '',
      createdAt: null,
      ctrlDown: false,
      currentFieldContent: '',
      currentFieldId: '',
      currentRow: -1,
      dataHeight: 0,
      dataVisible: false,
      dateToday: this.formattedCurrentDate(),
      deleteColumn: {
        deleteColumn: '-1'
      },
      deleteRows: {
        deleteFromRow: '-1',
        deleteToRow: '-1'
      },
      detachDataListener: null,
      detachMemoSetListener: null,
      detachPrivateListener: null,
      detachPublicListener: null,
      description: '',
      descriptionPlaceholder: false,
      docId: '',
      drawing: false,
      editCanvas: null,
      editingCell: false,
      editingDescription: false,
      editingHeading: false,
      editingTitle: false,
      exported: false,
      fieldId: '',
      fields: [],
      firstVisibleRow: 1,
      folderId: '',
      gameQuestionTranslateX: 0,
      gameQuestionTranslateY: 0,
      gameResults: {},
      goToRowVal: '1',
      groupColors: [],
      hideCellButtons: false,
      hoverObject: -1,
      images: {},
      infoWrapperMaxHeight: 0,
      initialLoad: true,
      isPublic: false,
      lastFieldId: '',
      lastObjectKey: 0,
      learn: {
        answerVisible: false,
        congratsMessage: '',
        fallDuration: gameFallDuration,
        fallEnded: false,
        firestoreUpdate: {},
        gameAnswers: {},
        gameButtons: [],
        gameButtonClicked: -1,
        gameCompleted: false,
        gameFade: false,
        gameLives: 3,
        gameQuestionExists: false,
        gameResults: [],
        gameScore: 0,
        gaming: false,
        incorrectAnswers: [],
        initialKnown: 0,
        previousReviewItem: null,
        questionIds: [],
        reviewGroupIds: [],
        reviewQueue: [],
        testCompleted: false,
        testDuration: '',
        testDurationSec: 0,
        testing: false,
        testItems: '0',
        testProgress: 0,
        testRandom: true,
        testResults: [],
        testScore: 0,
        testTimestamp: 0,
        testTotal: 0
      },
      learnEnabled: false,
      learnWrapperMaxHeights: [],
      locationHover: false,
      locationRect: {
        height: 0,
        left: 0,
        top: 0,
        width: 0
      },
      locationZIndexTrigger: 0,
      memoSetId: this.$route.params.memoSetId,
      memoSetType: memoSetType(this.$route.path),
      modal: '',
      modalHtml: '',
      modalPhotos: [],
      mode: '',
      moveColumn: {
        columnSeq: '0',
        position: '0'
      },
      moveRows: {
        firstRow: '',
        lastRow: '',
        moveAfter: ''
      },
      movingObject: false,
      myRating: 0,
      now: Date.now(),
      objectDirty: false,
      objects: [],
      objectsVisible: true,
      options: {
        useBackgroundPhotos: false,
        usePhotoOverviews: false,
        useReviewGroups: false,
        useSummaryImages: false
      },
      originalMemoSet: {
        sharing: {}
      },
      ownerId: '',
      ownerName: '',
      panLock: false,
      panning: false,
      pasteCells: {
        columns: 0,
        fieldIndex: 0,
        pasteData: [],
        pasteText: '',
        rowIndex: 0,
        rows: 0
      },
      photo: {},
      photoIndex: -1,
      photoOpacity: 1,
      photoRowIndexes: [],
      photos: [],
      photoTransition: false,
      pinching: false,
      pinchData: {
        centerXRelativeToPhoto: 0,
        centerYRelativeToPhoto: 0,
        distance: 0
      },
      pointerPosX: 0,
      pointerPosY: 0,
      populateColumn: {
        cardSet: 'English',
        column: '',
        dataSet: '',
        includeRepeated: 'N',
        numberOfCards: '1',
        numValues: ['1', '10'],
        originalNumValues: ['1', '10'],
        significantCard: 'L',
        submitting: false,
        suits: 'CDSH',
        values: 'A-K'
      },
      previousImageKeys: [],
      previousUpdateDate: '',
      printing: false,
      question: {
        deleteVisible: false,
        fromFieldId: '',
        mode: '',
        originalQuestionText: '',
        pendingDelete: false,
        questionId: '',
        questionIndex: 0,
        questionText: '',
        toFieldId: ''
      },
      questions: [],
      questionsWrapperMaxHeight: 0,
      questionText: '',
      ratingsArray: [],
      ready: false,
      rectangleActive: false,
      rectangleResizeHandle: '',
      removeContours: null,
      removeDefaultThreshold: 15,
      removeDownPoint: {
        x: 0,
        y: 0
      },
      removeImageData: null,
      removeMask: null,
      removePanning: false,
      removeThreshold: 0,
      removing: false,
      resetLearningMessage: '',
      resizingObject: false,
      resizingRectangle: false,
      reviewCycles: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
      reviewSchedule: [],
      reviewScheduleDefault: [1, 3, 8, 21, 55, 144, 377, 987],
      reviewScheduleDisplay: [],
      reviewScheduleExact: [],
      rotatingObject: false,
      rowIndex: 0,
      rowQuestion: {
        answerHtml: '',
        now: 0,
        origReviewCycle: 0,
        origStatus: '',
        questionHtml: '',
        questionId: '',
        reviewAfter: 0,
        reviewCycle: 0,
        rowIndex: 0,
        status: ''
      },
      rows: [],
      saveTableWrapperScrollLeft: 0,
      saveTableWrapperScrollTop: 0,
      selectedPhotoIndex: -1,
      setCoverImage: {
        file: null,
        loading: false,
        uploading: false,
        url: ''
      },
      setGroupColor: {
        color: '',
        reviewGroup: ''
      },
      share: {
        checkingUser: false,
        noUser: false,
        public: false,
        shareSelf: false,
        showPublicCheckbox: false,
        specific: false,
        userId: '',
        username: ''
      },
      sharing: {},
      showRect: {
        height: 0,
        left: 0,
        top: 0,
        width: 0
      },
      slug: this.$route.params.slug,
      sortRows: {
        sortByColumn: '-1',
        sortDirection: ''
      },
      statuses: {
        due: 0,
        known: 0,
        speed: '',
        unknown: 0
      },
      summaryImage: {
        backgroundPointerDownTimestamp: 0,
        before: {},
        // createdObjectUrls is an array of all object URLs created using window.URL.createObjectURL
        createdObjectUrls: [],
        firstImage: false,
        firstTouchActiveObject: 0,
        firstTouchClippingPath: [],
        firstTouchRectangleActive: false,
        firstTouchTimestamp: 0,
        imagesRemaining: 0,
        // neededObjectUrls is an array of object URLs created using window.URL.createObjectURL for images uploaded to Firebase storage - we need them for the thumbnails until the Firebase storage image has been downloaded
        neededObjectUrls: [],
        newText: '',
        page: '',
        undoArray: [],
        undoArrayIndex: 0,
        undoArrayIndexMax: 0,
        uploading: false,
        uploadingMessage: ''
      },
      tableWrapperMaxHeight: 0,
      tableWrapperScrollLeft: 0,
      testResults: {},
      thumbnails: [],
      timeouts: {
        audioRecorder: null,
        setTableWrapperScrollLeft: null,
        updateLearnData: null,
        updateNow: null
      },
      title: '',
      updates: [],
      updatesDismissed: null,
      walkthrough: {
        domObjectKeys: [],
        objects: [],
        rowClicked: false,
        stepNum: 0,
        steps: []
      },
      walkthroughDomObjectsTrigger: 0,
      walkthroughEnabledTrigger: 0
    }
  },
  computed: {
    activeVisible: function () {
      return this.memoSetType === 'regular' && this.questions.length
    },
    addQuestionEnabled: function () {
      let columnCount
      if (!this.fields) return false
      columnCount = this.fields.length
      if (this.options.useSummaryImages) columnCount += 1
      // Number of ordered pairs from n columns is n * (n - 1)
      return this.questions.length < columnCount * (columnCount - 1)
    },
    audioRecordingAvailable: function () {
      return (MediaRecorder && MediaRecorder.isTypeSupported('audio/webm'))
    },
    barButtonMargin: function () {
      return this.tableWrapperScrollLeft + (this.window.width / 2) - 70
    },
    breadcrumbs: function () {
      let label, to
      if (this.$route.path.substring(0, 10) === '/memo-sets') {
        label = this.text.navMemoSets
        to = '/memo-sets'
      } else if (this.$route.path.substring(0, 17) === '/public-memo-sets') {
        label = this.text.navPublicMemoSets
        to = '/public-memo-sets'
      } else if (this.$route.path.substring(0, 17) === '/shared-memo-sets') {
        label = this.text.navSharedMemoSets
        to = '/shared-memo-sets'
      }
      return [
        {label: this.text.navHome, to: '/'},
        {label: label, to: to},
        {label: this.title}
      ]
    },
    databaseDescription: function () {
      // Return empty string if the description is the default description
      let description
      if (this.description === this.text.memoSetDefaultDescription) {
        description = ''
      } else {
        description = this.description
      }
      return description
    },
    deleteRowsValid: function () {
      const deleteFromRow = parseInt(this.deleteRows.deleteFromRow, 10)
      const deleteToRow = parseInt(this.deleteRows.deleteToRow, 10)
      if (
        deleteFromRow > 0 &&
        deleteToRow > 0 &&
        deleteToRow >= deleteFromRow &&
        deleteToRow <= this.rows.length
      ) return true
      return false
    },
    deleteToRowPlaceholder: function () {
      if (!this.text || !this.text.memoSetDeleteToRowPlaceholder) return ''
      let fromValue = 1
      if (
        parseInt(this.deleteRows.deleteFromRow, 10) > 0 &&
        parseInt(this.deleteRows.deleteFromRow, 10) <= this.rows.length
      ) {
        fromValue = parseInt(this.deleteRows.deleteFromRow, 10)
      }
      return this.text.memoSetDeleteToRowPlaceholder.replace('<delete from row>', fromValue).replace('<row count>', this.rows.length)
    },
    deleteQuestionVisible: function () {
      return (
        this.question.mode === 'edit' &&
        this.memoSetType === 'regular' &&
        !this.question.pendingDelete &&
        this.question.fromFieldId === this.questions[this.question.questionIndex].fromFieldId &&
        this.question.toFieldId === this.questions[this.question.questionIndex].toFieldId
      )
    },
    enterRowNumberFromZeroPlaceholder: function () {
      if (!this.text || !this.text.memoSetEnterRowNumberFromZeroPlaceholder) return ''
      return this.text.memoSetEnterRowNumberFromZeroPlaceholder.replace('<row count>', this.rows.length)
    },
    enterRowNumberPlaceholder: function () {
      if (!this.text || !this.text.memoSetEnterRowNumberPlaceholder) return ''
      return this.text.memoSetEnterRowNumberPlaceholder.replace('<row count>', this.rows.length)
    },
    fieldsById: function () {
      let fieldsById = {}
      this.fields.forEach((field, fieldIndex) => {
        fieldsById[field.fieldId] = {
          fieldIndex: fieldIndex,
          heading: field.heading
        }
      })
      fieldsById['s'] = {
        fieldIndex: 999,
        heading: this.text.memoSetSummaryImage
      }
      return fieldsById
    },
    gameButtonsRow1: function () {
      let gameButtons
      gameButtons = this.learn.gameButtons.slice(0, 2)
      return gameButtons
    },
    gameButtonsRow2: function () {
      let gameButtons
      gameButtons = this.learn.gameButtons.slice(2, 4)
      return gameButtons
    },
    insertAfterRowPlaceholder: function () {
      if (!this.text || !this.text.memoSetInsertAfterRowPlaceholder) return ''
      return this.text.memoSetInsertAfterRowPlaceholder.replace('<row count>', this.rows.length)
    },
    invalidRowNumberMessage: function () {
      return this.text.memoSetInvalidRowNumberMessage.replace('<row count>', this.rows.length)
    },
    learnAnswerHtml: function () {
      let html
      if (!this.learn.reviewQueue.length) return ''
      html = this.learn.reviewQueue[0].answerHtml
      // Apply Mathjax after HTML is rendered
      this.$nextTick(() => {
        if (window.MathJax) {
          window.MathJax.typeset(['.learnAnswerHtml'])
        }
      })
      return this.sanitizeWithStyle(html)
    },
    learnQuestionHtml: function () {
      let html
      if (!this.learn.reviewQueue.length) return ''
      html = this.learn.reviewQueue[0].questionHtml
      // Apply Mathjax after HTML is rendered
      this.$nextTick(() => {
        if (window.MathJax) {
          window.MathJax.typeset(['.learnQuestionHtml'])
        }
      })
      return this.sanitizeWithStyle(html)
    },
    locationRectangleBorder: function () {
      let border
      if (this.locationHover && !this.rectangleActive && !this.panLock) {
        border = '3px solid #395f80'
      } else {
        border = '2px solid #395f80'
      }
      return border
    },
    locationZIndex: function () {
      let locationBoundingRect, locationElement, objBoundingRect, objElement, zIndex
      // Dummy dependency on locationZIndexTrigger
      if (this.locationZIndexTrigger === 'x') return 0
      if (this.rectangleActive) return 1000
      zIndex = 0
      locationElement = document.getElementById('locationRectangle')
      if (!locationElement) return 0
      locationBoundingRect = locationElement.getBoundingClientRect()
      // Loop through objects from the top to the bottom
      for (let objIndex = this.objects.length - 1; objIndex >= 0; objIndex--) {
        objElement = document.getElementById('summaryImageObj_' + objIndex)
        if (!objElement) return 0
        objBoundingRect = objElement.getBoundingClientRect()
        // If the object completely covers the location
        if (
          objBoundingRect.left <= locationBoundingRect.left &&
          objBoundingRect.right >= locationBoundingRect.right &&
          objBoundingRect.top <= locationBoundingRect.top &&
          objBoundingRect.bottom >= locationBoundingRect.bottom
        ) {
          // Set the location z-index just above the object's z-index
          zIndex = ((objIndex + 1) * 2) + 1
          break
        }
      }
      return zIndex
    },
    memoSetCreatedInFolderMessage: function () {
      if (!this.text || !this.text.memoSetMemoSetCreatedInFolder) return ''
      return this.text.memoSetMemoSetCreatedInFolder.replace('<new title>', '<strong>' + this.copyMemoSet.newTitle + '</strong>').replace('<folder name>', '<strong>' + this.copyMemoSet.newFolderName + '</strong>')
    },
    modalClass: function () {
      // Return a class unique to the component instance, so modals can be removed without affecting modals in the next component instance
      return this.memoSetType + '_' + this.memoSetId
    },
    moveRowsLastRowPlaceholder: function () {
      if (!this.text || !this.text.memoSetMoveRowsLastRowPlaceholder) return ''
      return this.text.memoSetMoveRowsLastRowPlaceholder.replace('<first row>', parseInt(this.moveRows.firstRow, 10) || '1').replace('<row count>', this.rows.length)
    },
    moveRowsValid: function () {
      const firstRow = parseInt(this.moveRows.firstRow, 10)
      const lastRow = parseInt(this.moveRows.lastRow, 10)
      const moveAfter = parseInt(this.moveRows.moveAfter, 10)
      if (
        !(
          firstRow > 0 &&
          firstRow <= lastRow &&
          lastRow <= this.rows.length &&
          moveAfter >= 0 &&
          moveAfter <= this.rows.length
        ) || (
          moveAfter >= firstRow - 1 &&
          moveAfter <= lastRow
        )
      ) return false
      return true
    },
    nextReviewText: function () {
      let days, reviewText
      reviewText = ''
      if (this.rowQuestion.reviewCycle === this.reviewCycles.length - 1) {
        return this.text.memoSetNoFutureReviews
      }
      if (this.rowQuestion.reviewCycle && this.rowQuestion.reviewCycle < this.reviewCycles.length - 1) {
        days = this.reviewCycleDurations[this.rowQuestion.reviewCycle]
        if (days < 4) {
          // Round to 1 decimal place
          days = Math.round(days * 10) / 10
        } else {
          // Round to integer
          days = Math.round(days)
        }
        if (!days) days = 0.1
        if (days === 1) {
          reviewText = this.text.memoSetNextReviewText1Day
        } else {
          reviewText = this.text.memoSetNextReviewText.replace('<days>', days)
        }
      }
      return reviewText
    },
    noStatusBarText: function () {
      let text = ''
      // If groups are enabled and there are no groups selected
      if (this.options.useReviewGroups && !this.learn.reviewGroupIds.length) {
        text = this.text.memoSetNoGroups
      }
      // If no questions are selected
      if (!this.learn.questionIds.length) {
        text = this.text.memoSetNoQuestionsSelected
      }
      // If there is no data for the selected questions/groups
      if (!text && !this.activeQuestions.length) {
        text = this.text.memoSetNoData
      }
      return text
    },
    objectCursorStyles: function () {
      let cursorIndexes, handleAngles, output
      // Exit if no active object
      if (this.activeObject === -1) return []
      // Set the initial handle angles
      handleAngles = [315, 0, 45, 90, 135, 180, 225, 270]
      // Add the object's angle
      handleAngles = handleAngles.map(angle => angle + this.objects[this.activeObject].angle)
      // Create array of indexes
      cursorIndexes = handleAngles.map(angle => Math.round(angle / 45) % 4)
      output = cursorIndexes.map(index => ['ns-resize', 'nesw-resize', 'ew-resize', 'nwse-resize'][index])
      return output
    },
    originalMemoSetId: function () {
      const appendedUserId = '_' + this.user.id
      const appendedUserIdPos = this.memoSetId.indexOf(appendedUserId)
      if (appendedUserIdPos === -1) {
        return this.memoSetId
      } else {
        return this.memoSetId.substring(0, appendedUserIdPos)
      }
    },
    photoIndexes: function () {
      // Return an array of photoIndex for each memo set row
      return this.rows.map(row => this.photos.findIndex(photo => photo.id === row.data.photoId))
    },
    photoThumbnailDimensions: function () {
      // Define maximum thumbnail dimensions
      const maxThumbnailWidth = 120
      const maxThumbnailHeight = 100
      let output = this.photos.map(photo => {
        let thumbnailHeight, thumbnailWidth
        if (photo.width / photo.height > maxThumbnailWidth / maxThumbnailHeight) {
          // Width is the limiting factor
          thumbnailWidth = maxThumbnailWidth
          thumbnailHeight = photo.height * thumbnailWidth / photo.width
        } else {
          // Height is the limiting factor
          thumbnailHeight = maxThumbnailHeight
          thumbnailWidth = photo.width * thumbnailHeight / photo.height
        }
        return {
          height: thumbnailHeight,
          width: thumbnailWidth
        }
      })
      return output
    },
    photoTransform: function () {
      // This computed function calculates the transform values for the background photo, given photo width/height, showRect left/top/width/height, and window width/height
      // Based on screen pixels: availableHeight, availableWidth, visibleHeight, visibleWidth
      // Based on photo pixels: newHeight, newWidth, showRectExpanded, translateX, translateY, this.photo
      let availableHeight, availableWidth, newHeight, newWidth, scale, showRectExpanded, translateX, translateY, visibleHeight, visibleWidth
      availableWidth = this.window.width - 200
      // Exit if not in a modal
      if (this.modal !== 'summaryImage' && this.modal !== 'walkthrough') return {}
      // Determine available width and height
      if (this.modal === 'summaryImage') {
        if (document.getElementById('summaryImageMaxWidth')) {
          availableWidth = document.getElementById('summaryImageMaxWidth').offsetWidth
        }
        availableHeight = this.window.height - 204
        if (this.mobile) availableHeight += 30
      }
      if (this.modal === 'walkthrough') {
        if (document.getElementById('walkthroughMaxWidth')) {
          availableWidth = document.getElementById('walkthroughMaxWidth').offsetWidth
        }
        availableHeight = this.window.height - 100
        if (!this.mobile) availableHeight += 10
        if (this.dataVisible) availableHeight -= this.dataHeight
      }
      showRectExpanded = {
        left: this.showRect.left,
        top: this.showRect.top,
        width: this.showRect.width,
        height: this.showRect.height
      }
      if (showRectExpanded.width / showRectExpanded.height > availableWidth / availableHeight) {
        // Width is the limiting factor
        scale = availableWidth / showRectExpanded.width
        // We can increase showRectExpanded.height up to availableHeight / scale
        newHeight = availableHeight / scale
        if (newHeight > this.photo.height) newHeight = this.photo.height
        // Adjust showRectExpanded.top
        showRectExpanded.top -= (newHeight - showRectExpanded.height) / 2
        showRectExpanded.height = newHeight
        if (showRectExpanded.top < 0) showRectExpanded.top = 0
        if (showRectExpanded.top + showRectExpanded.height > this.photo.height) showRectExpanded.top = this.photo.height - showRectExpanded.height
      } else {
        // Height is the limiting factor
        scale = availableHeight / showRectExpanded.height
        // We can increase showRectExpanded.width up to availableWidth / scale
        newWidth = availableWidth / scale
        if (newWidth > this.photo.width) newWidth = this.photo.width
        // Adjust showRectExpanded.left
        showRectExpanded.left -= (newWidth - showRectExpanded.width) / 2
        showRectExpanded.width = newWidth
        if (showRectExpanded.left < 0) showRectExpanded.left = 0
        if (showRectExpanded.left + showRectExpanded.width > this.photo.width) showRectExpanded.left = this.photo.width - showRectExpanded.width
      }
      visibleWidth = showRectExpanded.width * scale
      visibleHeight = showRectExpanded.height * scale
      translateX = -showRectExpanded.left
      translateY = -showRectExpanded.top
      if (this.modal === 'walkthrough') {
        const location = this.walkthrough.steps[this.walkthrough.stepNum].location
        if (location) {
          // Get the center of the location, in terms of the photo
          const locationCenterX = location.left + location.width / 2
          const locationCenterY = location.top + location.height / 2
          // Get the center of the screen, in terms of the photo
          const screenCenterX = -translateX + (visibleWidth / scale / 2)
          const screenCenterY = -translateY + (visibleHeight / scale / 2)
          // Adjust translateX, translateY by the difference, to center the location on the screen
          translateX += (screenCenterX - locationCenterX)
          translateY += (screenCenterY - locationCenterY)
        }
      }
      // Make available height and width accessible to the template
      this.availableHeight = availableHeight
      this.availableWidth = availableWidth
      return {
        scale: scale,
        translateX: translateX,
        translateY: translateY,
        visibleHeight: visibleHeight,
        visibleWidth: visibleWidth
      }
    },
    photoUsage: function () {
      let photoUsage, photoIds, photoIdsByRow
      photoIdsByRow = this.rows.map(row => row.data.photoId)
      // Get unique used photoIds
      photoIds = [...new Set(photoIdsByRow)].filter(photoId => photoId)
      photoUsage = {}
      photoIds.forEach(photoId => {
        photoUsage[photoId] = {
          firstRowIndex: photoIdsByRow.indexOf(photoId),
          lastRowIndex: photoIdsByRow.lastIndexOf(photoId)
        }
      })
      return photoUsage
    },
    populateColumnDataExists: function () {
      let fieldId
      if (this.populateColumn.column === '') return false
      fieldId = this.fields[this.populateColumn.column].fieldId
      return this.rows.some(row => row.data[fieldId])
    },
    populateColumnPreviewHtml: function () {
      let base, firstDigits, firstFormat, formattedValue, html, increment, lastDigits, lastFormat, numberOfValues
      if (this.populateColumn.dataSet !== 'n') return ''
      // For convenience
      const first = this.populateColumn.numValues[0]
      const last = this.populateColumn.numValues[1]
      // Handle simple numbers
      if (
        first === parseInt(first, 10).toString() &&
        last === parseInt(last, 10).toString()
      ) {
        numberOfValues = Math.abs(parseInt(last, 10) - parseInt(first, 10)) + 1
        // Populate a maximum of 10000 values
        if (numberOfValues <= 10000) {
          // Set increment to 1 if increasing, -1 if decreasing
          increment = parseInt(last, 10) > parseInt(first, 10) ? 1 : -1
          html = first
          for (let i = 1; i < 5; i++) {
            let thisValue = (parseInt(first, 10) + (i * increment)).toString()
            if (i < numberOfValues) {
              html += ', ' + thisValue
            }
          }
          if (numberOfValues === 6) {
            html += ', ' + last
          } else if (numberOfValues === 7) {
            html += ', ' + (parseInt(first, 10) + (5 * increment)).toString() + ', ' + last
          } else if (numberOfValues > 7) {
            html += ', ..., ' + last
          }
        }
      } else {
        // Handle formatted numbers
        // Replace all digits to check the formats match
        firstFormat = first.replace(/\d/g, '<digit>')
        lastFormat = last.replace(/\d/g, '<digit>')
        if (firstFormat === lastFormat) {
          // Extract the digits only
          firstDigits = first.replace(/\D/g, '')
          lastDigits = last.replace(/\D/g, '')
          // Set base to 2 (binary) if all digits are 0 or 1
          base = (firstDigits + lastDigits).replace(/0/g, '').replace(/1/g, '') === '' ? 2 : 10
          numberOfValues = Math.abs(parseInt(lastDigits, base) - parseInt(firstDigits, base)) + 1
          // Populate a maximum of 10000 values
          if (numberOfValues <= 10000) {
            // Set increment to 1 if increasing, -1 if decreasing
            increment = parseInt(lastDigits, base) > parseInt(firstDigits, base) ? 1 : -1
            html = '<div class="inlineBlock">' + first + '</div>'
            for (let i = 1; i < 5; i++) {
              if (i < numberOfValues) {
                let thisValue = (parseInt(firstDigits, base) + (i * increment)).toString(base)
                // Left pad with zeros
                thisValue = ('0'.repeat(firstDigits.length) + thisValue).slice(-firstDigits.length)
                // Add non-numeric formatting
                formattedValue = firstFormat
                for (let d = 0; d < firstDigits.length; d++) {
                  formattedValue = formattedValue.replace('<digit>', thisValue.substring(d, d + 1))
                }
                html += ', <div class="inlineBlock">' + formattedValue + '</div>'
              }
            }
            if (numberOfValues === 6) {
              html += ', ' + last
            } else if (numberOfValues > 6) {
              html += ', ..., <div class="inlineBlock">' + last + '</div>'
            }
          }
        }
      }
      return html
    },
    questionConfirmDeleteMessage: function () {
      if (!this.text || !this.text.memoSetQuestionConfirmDeleteMessage) return ''
      return this.text.memoSetQuestionConfirmDeleteMessage.replace('<Delete>', '<strong>' + this.text.commonDelete + '</strong>').replace('<Cancel>', '<strong>' + this.text.commonCancel + '</strong>')
    },
    questionPreviewHtml: function () {
      if (this.questionStatus !== 'ok') return ''
      let cardStyle, html, prevHtml, rowIndex
      // Find the first row with data in the from and to fields
      rowIndex = -1
      for (let i = 0; i < this.rows.length; i++) {
        if (this.rowHasData(this.rows[i], this.question.fromFieldId, this.question.toFieldId)) {
          rowIndex = i
          break
        }
      }
      // Replace new lines with <br>
      html = this.question.questionText.replace(/\r\n/g, '<br>')
      html = html.replace(/\n/g, '<br>')
      // Replace spaces at the start of a line with &nbsp; to allow indenting
      while (true) {
        prevHtml = html
        html = html.replace(/^((&nbsp;)*)\s/, '$1&nbsp;')
        html = html.replace(/(<br>(&nbsp;)*)\s/g, '$1&nbsp;')
        if (html === prevHtml) break
      }
      // If example row found
      if (rowIndex !== -1) {
        // If From Column is Summary Image
        if (this.question.fromFieldId === 's') {
          if (!this.thumbnails[rowIndex]) this.createThumbnail(rowIndex)
          html = html.replace('*', '<img src="' + this.thumbnails[rowIndex] + '">')
        } else {
          cardStyle = this.cardStyle(this.rows[rowIndex].data[this.question.fromFieldId])
          if (cardStyle) {
            html = html.replace('*', this.questionCardHtml(cardStyle))
          } else {
            html = html.replace('*', '<strong>' + this.rows[rowIndex].data[this.question.fromFieldId] + '</strong>')
          }
        }
      }
      // Apply Mathjax
      this.$nextTick(() => {
        if (window.MathJax) {
          window.MathJax.typeset(['.questionPreview'])
        }
      })
      return this.sanitizeWithStyle(html)
    },
    questionStatus: function () {
      let status
      if (this.modal !== 'question') return ''
      if (!this.question.fromFieldId || !this.question.toFieldId) {
        status = 'incomplete'
      } else if (this.question.fromFieldId === this.question.toFieldId) {
        status = 'same column'
      } else if (
        this.questions.findIndex(question =>
          question.fromFieldId === this.question.fromFieldId &&
          question.toFieldId === this.question.toFieldId &&
          question.questionId !== this.question.questionId
        ) !== -1
      ) {
        status = 'exists'
      } else {
        status = 'ok'
      }
      return status
    },
    ratingsData: function () {
      if (!this.ratingsArray) {
        return {
          count: 0,
          width: 0
        }
      }
      let ratingsData = {
        count: this.ratingsArray.reduce((a, b) => a + b, 0)
      }
      // Count total stars
      let totalStars = 0
      this.ratingsArray.forEach((stars, starsIndex) => {
        totalStars += stars * (starsIndex + 1)
      })
      // Calculate average rating
      let averageRating = this.ratingsCount ? totalStars / this.ratingsCount : 0
      // Calculate width of the ratings element
      let partialStar, completeStars, width
      // If no rating, return 0
      if (!averageRating) {
        ratingsData.width = 0
      } else {
        // Ensure that average rating is between 1 and 5
        averageRating = Math.max(1, averageRating)
        averageRating = Math.min(5, averageRating)
        // Find number of complete and partial stars
        completeStars = Math.floor(averageRating)
        partialStar = averageRating - completeStars
        // Set width for number of complete stars - numbers determined empirically
        // Note: this code should match that in memq-server.js
        if (completeStars === 1) width = 1.6
        if (completeStars === 2) width = 3.1
        if (completeStars === 3) width = 4.6
        if (completeStars === 4) width = 6.15
        if (completeStars === 5) width = 7.3
        // Add width for partial star
        width += partialStar * 1.14
        // Round to 2 decimal places
        width = Math.round(width * 100) / 100
        ratingsData.width = width
      }
      return ratingsData
    },
    ratingsCount: function () {
      if (!this.ratingsArray) return 0
      return this.ratingsArray.reduce((a, b) => a + b, 0)
    },
    reviewGroupColors: function () {
      let reviewGroupColors = {}
      const colors = ['teal', 'blue', 'violet', 'purple', 'pink', 'brown', 'orange', 'yellow', 'olive']
      this.uniqueReviewGroups.forEach((reviewGroup, index) => {
        const customColorItem = this.groupColors.find(item => item.label === reviewGroup)
        if (customColorItem) {
          reviewGroupColors[reviewGroup] = customColorItem.color
        } else {
          reviewGroupColors[reviewGroup] = colors[index % colors.length]
        }
      })
      reviewGroupColors[''] = 'invisible'
      return reviewGroupColors
    },
    reviewGroupsList: function () {
      // Get unique reviewGroup values
      let reviewGroups = [...new Set(this.rows.map(row => row.data.reviewGroup || ''))]
      // Sort alphabetically/numerically, with empty group at the end
      const { sortMode, staticText } = this.sortValues(reviewGroups)
      if (sortMode === 'Num') {
        reviewGroups.sort((a, b) => {
          let aVal = a.replace(staticText, '')
          let bVal = b.replace(staticText, '')
          // Sort blanks to the bottom
          if (aVal === '' && bVal === '') return 0
          if (aVal !== '' && bVal === '') return -1
          if (aVal === '' && bVal !== '') return 1
          if (parseFloat(aVal) === parseFloat(bVal)) return 0
          if (parseFloat(aVal) > parseFloat(bVal)) return 1
          return -1
        })
      } else {
        reviewGroups.sort((a, b) => {
          if (!a) return 1
          if (!b) return -1
          if (a.toLowerCase() < b.toLowerCase()) return -1
          return 1
        })
      }
      return reviewGroups
    },
    reviewCycleDurations: function () {
      let durations = this.reviewSchedule.slice()
      durations.unshift(0)
      durations.push(99999)
      return durations
    },
    selfOwned: function () {
      const appendedUserId = '_' + this.user.id
      const appendedUserIdPos = this.memoSetId.indexOf(appendedUserId)
      return this.memoSetType === 'regular' && appendedUserIdPos === -1
    },
    sharingArray: function () {
      let arr
      // Exit if no sharing object
      if (!this.sharing) return []
      arr = []
      Object.keys(this.sharing).forEach(userId => {
        arr.push({
          displayName: this.sharing[userId],
          userId: userId
        })
      })
      // Sort sharing array
      arr.sort((a, b) => {
        if (a.userId === 'public') return -1
        if (b.userId === 'public') return 1
        if (a.displayName < b.displayName) return -1
        return 1
      })
      return arr
    },
    summaryImageLocationRectWithMargin: function () {
      return this.locationRectWithMargin(0.15)
    },
    summaryImageObjectCanvasPadding: function () {
      if (this.mode === 'clip' || this.mode === 'draw') {
        return drawTransparentRadius
      } else {
        return 0
      }
    },
    summaryImageUploadingMessage: function () {
      let message
      if (!this.summaryImage.imagesRemaining) return ''
      if (this.summaryImage.imagesRemaining === 1) {
        message = this.text.memoSetUploadingImage
      } else {
        message = this.text.memoSetUploadingImages.replace('<remaining>', this.summaryImage.imagesRemaining.toString())
      }
      return message
    },
    tabsText: function () {
      return this.window.width > 500
    },
    testMaximumMessage: function () {
      let message
      if (!this.learn.reviewQueue) return ''
      message = this.text.memoSetTestMaximum.replace('<max>', this.learn.reviewQueue.length)
      return message
    },
    toggleDataKeyCode: function () {
      let keyCode = 68 // D for 'data'
      // In future, different key codes can be returned for different values of this.user.uiLanguage
      return keyCode
    },
    toggleObjectsKeyCode: function () {
      let keyCode = 73 // I for 'images'
      // In future, different key codes can be returned for different values of this.user.uiLanguage
      return keyCode
    },
    uniqueReviewGroups: function () {
      // Return an array of unique review groups, in order of appearance
      const uniqueGroups = [...new Set(this.rows.map(row => row.data.reviewGroup).filter(reviewGroup => reviewGroup))]
      return uniqueGroups
    },
    updatesNotificationVisible: function () {
      if (!this.createdAt) return false
      let showUpdatesSinceDate
      if (this.updatesDismissed) {
        // Add a day to the updates dismissed date, to err on the side of not showing the updates notification
        showUpdatesSinceDate = new Date((this.updatesDismissed.seconds + 60 * 60 * 24) * 1000)
      } else {
        showUpdatesSinceDate = new Date(this.createdAt.seconds * 1000)
      }
      // Format as yyyy-mm-dd
      const showUpdatesSince = showUpdatesSinceDate.toISOString().substring(0, 10)
      return this.memoSetType === 'regular' && !this.selfOwned && this.updates.length && this.updates.some(update => update.date > showUpdatesSince)
    },
    verticallyChallenged: function () {
      return this.window.height < 800
    },
    visibleRows: function () {
      // Return a filtered array containing visible rows
      let filteredRows
      // Filter rows to those visible
      filteredRows = this.rows.filter((row, rowIndex) => rowIndex + 1 >= this.firstVisibleRow && rowIndex + 1 < this.firstVisibleRow + this.visibleRowsCount)
      return filteredRows
    },
    visibleRowsCount: function () {
      // Show all rows if printing
      if (this.printing) return this.rows.length
      // Show all rows if there are 100 or fewer, otherwise show 50 rows
      if (this.rows.length > 50 && this.rows.length <= 100) {
        return this.rows.length
      } else {
        return 50
      }
    },
    visibleRowsOffset: function () {
      return this.firstVisibleRow - 1
    },
    walkthroughDomObjects: function () {
      // Dummy dependency on walkthroughDomObjectsTrigger
      if (this.walkthroughDomObjectsTrigger === 'x') return []
      return this.walkthrough.objects.filter(obj => {
        return this.walkthrough.domObjectKeys[obj.key]
      })
    },
    walkthroughEnabled: function () {
      // Dummy dependency on walkthroughEnabledTrigger
      if (this.walkthroughEnabledTrigger === 'x') return false
      return (
        this.options.useSummaryImages &&
        this.rows.some(row => (row.data.photoId && this.options.useBackgroundPhotos) || (row.data.objects && row.data.objects.length))
      )
    },
    walkthroughLabelsVisible: function () {
      // Show text on Walk Through button labels if the window is wide enough
      return (this.window.width >= 1000)
    },
    walkthroughLocationRectWithMargin: function () {
      return this.locationRectWithMargin(0.08)
    }
  },
  beforeDestroy: function () {
    // Close any toasts
    this.closeToasts()
    // Detach listeners
    if (this.detachDataListener) {
      this.detachDataListener()
      this.detachDataListener = null
    }
    if (this.detachMemoSetListener) {
      this.detachMemoSetListener()
      this.detachMemoSetListener = null
    }
    if (this.detachPrivateListener) {
      this.detachPrivateListener()
      this.detachPrivateListener = null
    }
    if (this.detachPublicListener) {
      this.detachPublicListener()
      this.detachPublicListener = null
    }
    // Clear all timeouts
    const timeoutIds = Object.keys(this.timeouts)
    timeoutIds.forEach(timeoutId => {
      if (this.timeouts[timeoutId]) {
        clearTimeout(this.timeouts[timeoutId])
      }
    })
  },
  created: function () {
    // TEMP - for debugging
    window.vm = this
    // Scroll to top
    window.scrollTo(0, 0)
    // If the memo sets data is available or not needed, we can load the memo set now
    if (this.memoSets || this.memoSetType === 'example') {
      this.initMemoSet()
    }
    // Add paste listener to body
    document.body.addEventListener('paste', this.pasteHandler)
    // Create playAudio function
    window.memq = window.memq || {}
    if (!window.memq.playAudio) {
      window.memq.playAudio = (event, audioId) => {
        document.getElementById(audioId).play()
        // Prevent focus moving to clicked element
        event.preventDefault()
      }
    }
  },
  destroyed: function () {
    // Remove window listeners
    window.removeEventListener('mouseup', this.windowPointerUp)
    window.removeEventListener('touchend', this.windowPointerUp)
    window.removeEventListener('keydown', this.windowKeyDown)
    window.removeEventListener('keyup', this.windowKeyUp)
    window.removeEventListener('wheel', this.windowWheel)
    document.body.removeEventListener('paste', this.pasteHandler)
    // Close any modals
    $('.ui.modal').modal('hide')
    // Remove modal divs
    $('.ui.modal.' + this.modalClass).remove()
  },
  methods: {
    activeChange: function () {
      let updateObj = {}
      this.closeToasts()
      if (this.active) {
        updateObj[this.memoSetId + '.hide'] = firebase.firestore.FieldValue.delete()
      } else {
        updateObj[this.memoSetId + '.hide'] = true
      }
      // Write to Firestore
      db.doc('users/' + this.user.id + '/memoSets/' + this.docId)
        .update(updateObj)
        .catch(error => { logError('MemoSet_activeChange', error) })
    },
    addCellAudioClick: function (rowIndex, fieldId) {
      this.closeToasts()
      // Save cell info
      this.rowIndex = rowIndex
      this.fieldId = fieldId
      // Initialize modal
      this.initAddCellAudio()
      this.modal = 'addCellAudio'
      $('.ui.modal.addCellAudio')
        .modal({
          autofocus: false,
          observeChanges: true,
          onApprove: this.addCellAudioSubmit,
          onHidden: this.revertPreventBackHistory,
          onHide: () => {
            this.resetModal()
            document.getElementById('addCellAudio').pause()
          },
          onVisible: this.prepareModalHistory
        })
        .modal('show')
    },
    addCellAudioSubmit: function () {
      let audioKey
      const self = this

      function addCellAudio (downloadUrl) {
        let audioHtml, brCount, brLength, remainder
        const goToElement = document.getElementById(self.fieldId + '_' + self.rowIndex)
        goToElement.focus()
        moveCursorToEndOfContentEditable(goToElement)
        // Prepare audio HTML
        audioHtml = '<audio id="' + audioKey + '" src="' + downloadUrl + '"></audio>'
        // Add play button HTML
        audioHtml += '<button contenteditable="false" id="play_' + audioKey + '" class="circular ui icon button"><i class="play icon"></i></button>'
        brCount = 0
        if (goToElement.innerHTML.slice(-4) === '<br>') {
          brCount++
          brLength = 4
        }
        if (goToElement.innerHTML.slice(-5) === '<br/>') {
          brCount++
          brLength = 5
        }
        if (goToElement.innerHTML.slice(-6) === '<br />') {
          brCount++
          brLength = 6
        }
        remainder = goToElement.innerHTML.slice(0, -brLength)
        if (remainder.slice(-4) === '<br>') {
          brCount++
        }
        if (remainder.slice(-5) === '<br/>') {
          brCount++
        }
        if (remainder.slice(-6) === '<br />') {
          brCount++
        }
        // Prepend <br> tags if there is text and there aren't already 2 <br> tags
        if (self.textOnly(goToElement.innerHTML)) {
          if (!brCount) audioHtml = '<br><br>' + audioHtml
          if (brCount === 1) audioHtml = '<br>' + audioHtml
        }
        pasteHtmlAtCaret(audioHtml)
        goToElement.blur()
        self.addCellAudio.uploading = false
        // Close modal
        $('.ui.modal.addCellAudio').modal('hide')
      }

      this.addCellAudio.uploading = true
      // Get a random key for the audio
      audioKey = this.randomId()
      // Upload the audio to Firebase Storage
      firebase.storage().ref('userAudio/' + this.user.id + '/' + audioKey).put(this.addCellAudio.file, {cacheControl: cacheControl})
        .then(function (snapshot) {
          return snapshot.ref.getDownloadURL()
        })
        .then(downloadUrl => {
          addCellAudio(downloadUrl)
        })
      // Prevent modal closing
      return false
    },
    addCellImageClick: function (rowIndex, fieldId) {
      this.closeToasts()
      // Save cell info
      this.rowIndex = rowIndex
      this.fieldId = fieldId
      // Initialize modal
      this.initAddCellImage()
      // Allow time for previous image to be cleared
      this.$nextTick(() => {
        this.modal = 'addCellImage'
        $('.ui.modal.addCellImage')
          .modal({
            autofocus: false,
            observeChanges: true,
            onApprove: this.addCellImageSubmit,
            onHidden: this.revertPreventBackHistory,
            onHide: this.resetModal,
            onVisible: this.prepareModalHistory
          })
          .modal('show')
      })
    },
    addCellImageEnter: function () {
      // Exit if modal is incomplete
      if (!this.addCellImage.url) return
      this.addCellImageSubmit()
    },
    addCellImageSubmit: function () {
      let canvas, ctx, height, imageDataUrl, imageKey, img, scaleFactor, tempCanvas, tempCtx, width
      const self = this

      function addCellImage (imageDownloadUrl) {
        let brCount, brLength, imgHtml, remainder
        const goToElement = document.getElementById(self.fieldId + '_' + self.rowIndex)
        goToElement.focus()
        moveCursorToEndOfContentEditable(goToElement)
        // Prepare image HTML
        imgHtml = '<img src="' + imageDownloadUrl + '">'
        brCount = 0
        if (goToElement.innerHTML.slice(-4) === '<br>') {
          brCount++
          brLength = 4
        }
        if (goToElement.innerHTML.slice(-5) === '<br/>') {
          brCount++
          brLength = 5
        }
        if (goToElement.innerHTML.slice(-6) === '<br />') {
          brCount++
          brLength = 6
        }
        remainder = goToElement.innerHTML.slice(0, -brLength)
        if (remainder.slice(-4) === '<br>') {
          brCount++
        }
        if (remainder.slice(-5) === '<br/>') {
          brCount++
        }
        if (remainder.slice(-6) === '<br />') {
          brCount++
        }
        // Prepend <br> tags if there is text and there aren't already 2 <br> tags
        if (self.textOnly(goToElement.innerHTML)) {
          if (!brCount) imgHtml = '<br><br>' + imgHtml
          if (brCount === 1) imgHtml = '<br>' + imgHtml
        }
        pasteHtmlAtCaret(imgHtml)
        goToElement.blur()
        self.addCellImage.uploading = false
        // Close modal
        $('.ui.modal.addCellImage').modal('hide')
      }

      this.addCellImage.uploading = true
      img = document.getElementById('addCellImage')
      // Get a random key for the image
      imageKey = this.randomId()
      // If image is jpg and isn't too large
      if (
        this.addCellImage.file.type === 'image/jpeg' &&
        img.naturalHeight <= maxCellImageSize &&
        img.naturalWidth <= maxCellImageSize
      ) {
        // Upload the image to Firebase Storage
        firebase.storage().ref('userImages/' + this.user.id + '/' + imageKey + '.jpg').put(this.addCellImage.file, {cacheControl: cacheControl})
          .then(function (snapshot) {
            return snapshot.ref.getDownloadURL()
          })
          .then(downloadUrl => {
            addCellImage(downloadUrl)
          })
      } else {
        scaleFactor = Math.min(maxCellImageSize / img.naturalWidth, maxCellImageSize / img.naturalHeight)
        scaleFactor = Math.min(scaleFactor, 1)
        // Limit scale down to half to preserve image quality
        scaleFactor = Math.max(scaleFactor, 0.5)
        tempCanvas = document.createElement('canvas')
        tempCanvas.width = img.naturalWidth * scaleFactor
        tempCanvas.height = img.naturalHeight * scaleFactor
        tempCtx = tempCanvas.getContext('2d')
        // Fill with white in case of transparent images
        tempCtx.fillStyle = 'white'
        tempCtx.fillRect(0, 0, tempCanvas.width, tempCanvas.height)
        tempCtx.drawImage(img, 0, 0, tempCanvas.width, tempCanvas.height)
        width = tempCanvas.width
        height = tempCanvas.height
        while (scaleFactor === 0.5) {
          scaleFactor = Math.min(maxCellImageSize / width, maxCellImageSize / height)
          scaleFactor = Math.max(scaleFactor, 0.5)
          tempCtx.drawImage(tempCanvas, 0, 0, width, height, 0, 0, width * scaleFactor, height * scaleFactor)
          width = width * scaleFactor
          height = height * scaleFactor
        }
        // Draw section of tempCanvas onto canvas
        canvas = document.createElement('canvas')
        canvas.width = width
        canvas.height = height
        ctx = canvas.getContext('2d')
        ctx.drawImage(tempCanvas, 0, 0, width, height, 0, 0, width, height)
        imageDataUrl = canvas.toDataURL('image/jpeg')
        // Upload the image to Firebase Storage
        firebase.storage().ref('userImages/' + this.user.id + '/' + imageKey + '.jpg').putString(imageDataUrl, 'data_url', {cacheControl: cacheControl})
          .then(function (snapshot) {
            return snapshot.ref.getDownloadURL()
          })
          .then(downloadUrl => {
            addCellImage(downloadUrl)
          })
      }
      // Prevent modal closing
      return false
    },
    addColumnClick: function () {
      // Hide Actions popup
      $('.actionsButton').popup('hide')
      this.$nextTick(() => {
        this.addColumn.columnHeading = ''
        this.addColumn.columnPosition = this.fields.length.toString()
        // Prepare Column Position dropdown
        $('.addColumnColumnPosition')
          .dropdown({
            onChange: value => {
              this.addColumn.columnPosition = value
            },
            showOnFocus: false
          })
          .dropdown('set selected', this.addColumn.columnPosition)
        this.modal = 'addColumn'
        $('.ui.modal.addColumn')
          .modal({
            onApprove: this.addColumnSubmit,
            onHidden: this.revertPreventBackHistory,
            onHide: this.resetModal,
            onVisible: this.prepareModalHistory
          })
          .modal('show')
      })
    },
    addColumnEnter: function () {
      // Exit if form is incomplete
      if (!this.addColumn.columnHeading) return
      // Hide modal
      $('.ui.modal.addColumn').modal('hide')
      // Submit form
      this.addColumnSubmit()
    },
    addColumnSubmit: function () {
      let fieldId, fieldIndex
      // Get next available field ID
      fieldIndex = 0
      while (true) {
        fieldIndex++
        fieldId = 'f' + fieldIndex.toString()
        if (!this.fields.filter(field => field.fieldId === fieldId).length) break
      }
      // Add field to the fields array
      this.fields.splice(parseInt(this.addColumn.columnPosition, 10), 0, {
        fieldId: fieldId,
        heading: this.addColumn.columnHeading
      })
      this.writeFields()
      this.initSearch()
    },
    addImageClick: function (event) {
      // Blur button
      if (event) event.target.blur()
      // End image editing
      if (this.mode === 'clip') this.endClipMode()
      if (this.mode === 'draw') this.endDrawMode()
      if (this.mode === 'remove') this.endRemoveMode()
      this.activeObject = -1
      // Switch to Add Image page
      this.summaryImage.page = 'add image'
      this.initAddImage()
    },
    addImageFileChange: function (file) {
      if (file) {
        // Check that file is an image
        if (isImageFile(file)) {
          this.addImage.url = window.URL.createObjectURL(file)
          this.addImage.file = file
          this.summaryImage.createdObjectUrls.push(this.addImage.url)
        } else {
          document.getElementById('imageFile').value = ''
          alert(this.text.memoSetSelectImageFile)
        }
      }
    },
    addObjectsForRow: function (row, rowIndex) {
      // Loop through objects for the row
      if (row.data.objects) {
        row.data.objects.forEach(obj => {
          this.lastObjectKey++
          this.objects.push({
            angle: obj.angle,
            flipX: !!obj.flipX,
            flipY: !!obj.flipY,
            height: obj.height,
            key: this.lastObjectKey,
            left: obj.left,
            rowIndex: rowIndex,
            sequence: obj.sequence,
            text: obj.text,
            top: obj.top,
            url: obj.url,
            width: obj.width
          })
        })
      }
    },
    addPhotoFileChange: function (file) {
      if (file) {
        // Check that file is an image
        if (isImageFile(file)) {
          // Show image in the modal
          this.addPhoto.url = window.URL.createObjectURL(file)
          this.addPhoto.file = file
        } else {
          document.getElementById('photoFile').value = ''
          alert(this.text.memoSetSelectImageFile)
        }
      }
    },
    addRowsAddRows: function (updateDb) {
      let addBelowRow, docId, docsWithCounts, firestoreNewDocs, firestoreUpdateDocs, newDocsRequired, newRow, newRows, newThumbnails, numberOfRows, rowsAvailable, rowId
      if (this.addRows.insertAfterRow !== '') {
        addBelowRow = parseInt(this.addRows.insertAfterRow, 10) - 1
        // Ensure addBelowRow is between -1 and the max row index
        if (addBelowRow < -1) addBelowRow = -1
        if (addBelowRow > this.rows.length - 1) addBelowRow = this.rows.length - 1
      } else {
        // Set addBelowRow to the max row index to add rows at the end
        addBelowRow = this.rows.length - 1
      }
      numberOfRows = parseInt(this.addRows.numberOfRows, 10)
      firestoreNewDocs = {}
      newRows = []
      newThumbnails = []
      // Create array of existing documents
      const uniqueDocIds = [...new Set(this.rows.map(row => row.docId))]
      docsWithCounts = uniqueDocIds.map(docId => {
        return {
          docId: docId,
          new: false,
          rowCount: this.rows.filter(row => row.docId === docId).length
        }
      })
      // Remove any full documents
      docsWithCounts = docsWithCounts.filter(docObj => docObj.rowCount < rowsPerDocument)
      // Calculate number of rows available in existing documents
      rowsAvailable = 0
      docsWithCounts.forEach(docObj => {
        if (docObj.rowCount < rowsPerDocument) {
          rowsAvailable += rowsPerDocument - docObj.rowCount
        }
      })
      // Calculate number of new documents required
      if (rowsAvailable >= numberOfRows) {
        newDocsRequired = 0
      } else {
        newDocsRequired = Math.ceil((numberOfRows - rowsAvailable) / rowsPerDocument)
      }
      // Add new documents to docsWithCounts
      for (let i = 0; i < newDocsRequired; i++) {
        docId = this.randomId()
        docsWithCounts.push({
          docId: docId,
          isNew: true,
          rowCount: 0
        })
        // Initialize document for Firestore write
        firestoreNewDocs[docId] = {}
        // Add userId and sharing, for security rules
        firestoreNewDocs[docId].userId = this.user.id
        if (this.sharing) {
          firestoreNewDocs[docId].sharing = this.sharing
        }
      }
      // Loop through rows being added
      for (let i = 0; i < numberOfRows; i++) {
        // Set docId for the new row
        docId = docsWithCounts[0].docId
        // Set a random row ID
        rowId = this.randomId()
        // Create a new row object
        newRow = {
          data: {
            seq: addBelowRow + i + 1
          },
          docId: docId,
          rowId: rowId
        }
        // If the row is on a new document
        if (docsWithCounts[0].isNew) {
          // Prepare Firestore object
          firestoreNewDocs[docId][rowId] = {
            seq: newRow.data.seq
          }
        } else {
          // The row is on an existing document - set seq to a dummy value so the Firestore document will be updated
          newRow.data.seq = -1
        }
        newRows.push(newRow)
        newThumbnails.push(null)
        // Increment rowCount for the doc
        docsWithCounts[0].rowCount++
        // Remove docId from docsWithCounts if document is full
        if (docsWithCounts[0].rowCount === rowsPerDocument) {
          docsWithCounts.shift()
        }
      }
      // Insert new rows
      this.rows.splice(addBelowRow + 1, 0, ...newRows)
      // Insert new thumbnails
      this.thumbnails.splice(addBelowRow + 1, 0, ...newThumbnails)
      // Check/update thumbnails and Mathjax
      this.prepareVisibleRows()
      // Prepare object for updates
      firestoreUpdateDocs = {}
      this.rows.forEach((row, rowIndex) => {
        // If seq has changed
        if (row.data.seq !== rowIndex) {
          this.$set(row.data, 'seq', rowIndex)
          // Create update object for the document if necessary
          if (!firestoreUpdateDocs[row.docId]) firestoreUpdateDocs[row.docId] = {}
          firestoreUpdateDocs[row.docId][row.rowId + '.seq'] = rowIndex
        }
      })
      if (updateDb) {
        const batch = db.batch()
        // Write new docs
        Object.keys(firestoreNewDocs).forEach(docId => {
          batch.set(db.doc('memoSets/' + this.memoSetId + '/rows/' + docId), firestoreNewDocs[docId])
        })
        // Write update docs
        Object.keys(firestoreUpdateDocs).forEach(docId => {
          batch.update(db.doc('memoSets/' + this.memoSetId + '/rows/' + docId), firestoreUpdateDocs[docId])
        })
        this.writeRowCount(batch)
        batch.commit()
          .catch(error => { logError('MemoSet_addRowsAddRows', error) })
        this.initSearch()
      } else {
        // Save Firestore data for use by populateColumnSubmit or rowFieldPasteMultiple
        this.addRows.firestoreNewDocs = firestoreNewDocs
        this.addRows.firestoreUpdateDocs = firestoreUpdateDocs
      }
    },
    addRowsClick: function () {
      // Hide Actions popup
      $('.actionsButton').popup('hide')
      this.$nextTick(() => {
        this.addRows.numberOfRows = ''
        this.addRows.insertAfterRow = ''
        this.modal = 'addRows'
        $('.ui.modal.addRows')
          .modal({
            onApprove: this.addRowsSubmit,
            onHidden: this.revertPreventBackHistory,
            onHide: this.resetModal,
            onVisible: this.prepareModalHistory
          })
          .modal('show')
      })
    },
    addRowsEnter: function () {
      // Check form is complete
      if (!(parseInt(this.addRows.numberOfRows, 10) > 0)) return
      // Hide modal
      $('.ui.modal.addRows').modal('hide')
      // Submit form
      this.addRowsSubmit()
    },
    addRowsSubmit: function () {
      // Add rows (writing to the database)
      this.addRowsAddRows(true)
      this.initSearch()
    },
    addSummaryImageUndoEntry: function (undoEntry) {
      // Add undo entry to undo array
      this.summaryImage.undoArray[this.summaryImage.undoArrayIndex] = undoEntry
      this.summaryImage.undoArrayIndex++
      this.summaryImage.undoArrayIndexMax = this.summaryImage.undoArrayIndex
    },
    addTextClick: function (event) {
      // Blur button
      if (event) event.target.blur()
      // End image editing
      if (this.mode === 'clip') this.endClipMode()
      if (this.mode === 'draw') this.endDrawMode()
      if (this.mode === 'remove') this.endRemoveMode()
      this.activeObject = -1
      this.summaryImage.newText = ''
      // Switch to Add Text page
      this.summaryImage.page = 'add text'
      this.$nextTick(() => {
        document.getElementById('newText').focus()
      })
    },
    addToMyMemoSetsClick: function () {
      this.copyMemoSet.newTitle = this.newMemoSetTitle(this.title)
      this.copyMemoSet.newFolderName = ''
      this.$nextTick(() => {
        $('.copyMemoSetFolderId')
          .dropdown({
            onChange: value => {
              this.copyMemoSet.folderId = value
              if (value === 'new') {
                this.$nextTick(() => {
                  document.getElementById('newFolderName').focus()
                })
              }
            },
            showOnFocus: false
          })
        // If there's only one folder, use it by default
        if (this.user.folders.length === 1) {
          this.copyMemoSet.folderId = this.user.folders[0].folderId
          $('.copyMemoSetFolderId').dropdown('set selected', this.copyMemoSet.folderId)
        } else {
          this.copyMemoSet.folderId = ''
          $('.copyMemoSetFolderId').dropdown('clear')
        }
        $('.ui.modal.copyMemoSet')
          .modal({
            onApprove: this.copyMemoSetSubmit
          })
          .modal('show')
      })
    },
    adjustUpdatesDummyRow: function () {
      // Add dummy row if required
      if (this.updates.every(update => update.date && update.descr)) {
        this.updates.push({
          date: '',
          descr: ''
        })
      }
    },
    adjustObjectPositionsForRow: function (row, oldPhotoId) {
      let location, newPhoto, oldPhoto, scaleFactor
      if (oldPhotoId) {
        oldPhoto = this.photos.find(photo => photo.id === oldPhotoId)
      }
      if (row.data.photoId) {
        newPhoto = this.modalPhotos.find(photo => photo.id === row.data.photoId)
      }
      // If background photo has been removed
      if (!newPhoto) {
        location = row.data.location
        scaleFactor = Math.min(noPhotoSize / location.width, noPhotoSize / location.height)
        if (row.data.objects) {
          row.data.objects.forEach(obj => {
            // Translate by location position
            obj.left -= location.left
            obj.top -= location.top
            // Scale to fit
            obj.left *= scaleFactor
            obj.top *= scaleFactor
            obj.width *= scaleFactor
            obj.height *= scaleFactor
          })
        }
      }
      // If background photo has been added
      if (!oldPhoto) {
        row.data.location = row.data.location || {}
        location = row.data.location
        location.left = 0
        location.top = 0
        location.width = Math.min(0.3 * newPhoto.width, 0.3 * newPhoto.height)
        location.height = location.width
        scaleFactor = Math.min(location.width / noPhotoSize, location.height / noPhotoSize)
        if (row.data.objects) {
          row.data.objects.forEach(obj => {
            obj.left *= scaleFactor
            obj.top *= scaleFactor
            obj.width *= scaleFactor
            obj.height *= scaleFactor
          })
        }
      }
      // If background photo has been changed
      if (newPhoto && oldPhoto) {
        scaleFactor = Math.min(newPhoto.width / oldPhoto.width, newPhoto.height / oldPhoto.height)
        location = row.data.location
        location.left *= scaleFactor
        location.top *= scaleFactor
        location.width *= scaleFactor
        location.height *= scaleFactor
        if (row.data.objects) {
          row.data.objects.forEach(obj => {
            obj.left *= scaleFactor
            obj.top *= scaleFactor
            obj.width *= scaleFactor
            obj.height *= scaleFactor
          })
        }
      }
    },
    adjustReviewSchedule: function (amount) {
      const factor = 1 + amount
      this.reviewScheduleExact = this.reviewScheduleExact.map(days => days * factor)
      // Round to 1 decimal place
      this.reviewScheduleDisplay = this.reviewScheduleExact.map(days => Math.round(days * 10) / 10)
    },
    answerClick: function (result) {
      const questionId = this.learn.reviewQueue[0].questionId
      // If testing, increment score for correct answer
      if (this.learn.testing && result === 'correct') {
        this.learn.testScore++
      }
      // If testing and incorrect, add question to incorrect answers array
      if ((this.learn.testing || this.learn.gaming) && result === 'incorrect') {
        this.learn.incorrectAnswers.push({
          questionHtml: this.learnQuestionHtml,
          answerHtml: this.learnAnswerHtml
        })
      }
      this.learn.reviewQueue[0].rowIndexes.forEach(rowIndex => {
        const row = this.rows[rowIndex]
        // Add row questions property if required
        if (!row.data.questions) {
          this.$set(row.data, 'questions', {})
        }
        // Add row question if required
        if (!row.data.questions[questionId]) {
          this.$set(row.data.questions, questionId, {
            reviewAfter: 0,
            reviewCycle: 0
          })
        }
        const question = row.data.questions[questionId]
        if (result === 'correct') {
          // If the item was unknown or due for review, increment its review cycle
          if (!question.reviewCycle || question.reviewAfter < Date.now()) {
            if (question.reviewCycle < this.reviewCycles.length - 2) {
              question.reviewCycle++
            }
          }
          question.reviewAfter = Date.now() + this.durationForReviewCycle(question.reviewCycle)
        } else {
          if (!this.learn.gaming) {
            question.reviewCycle = 0
            question.reviewAfter = Date.now() + this.durationForReviewCycle(0)
          }
        }
        // Update Firestore update object
        if (!this.learn.firestoreUpdate[row.docId]) this.learn.firestoreUpdate[row.docId] = {}
        this.learn.firestoreUpdate[row.docId][row.rowId + '.questions.' + questionId] = {
          reviewAfter: question.reviewAfter,
          reviewCycle: question.reviewCycle
        }
        // Update activeQuestions array
        const questionIndex = this.activeQuestions.findIndex(e => e.rowIndex === rowIndex && e.questionId === questionId)
        const questionObj = this.activeQuestions[questionIndex]
        questionObj.reviewAfter = question.reviewAfter
        questionObj.reviewCycle = question.reviewCycle
        // Update reviewQueue item
        this.learn.reviewQueue[0].reviewAfter = question.reviewAfter
        this.learn.reviewQueue[0].reviewCycle = question.reviewCycle
      })
      // Move the review item to the back of the queue
      const item = this.learn.reviewQueue.shift()
      this.learn.reviewQueue.push(item)
      // If learning, update progress bar
      if (this.modal === 'learn') {
        this.updateLearnProgress()
      }
      // If testing, update progress bar
      if (this.learn.testing) {
        this.learn.testProgress++
        this.updateTestProgress()
        if (this.learn.testProgress === this.learn.testTotal) {
          this.learn.testCompleted = true
          this.learn.testing = false
          // If this is the first time completing the test (as opposed to the user having clicked Oops)
          if (!this.learn.testDuration) {
            const durationSec = Math.round((Date.now() - this.learn.testTimestamp) / 1000)
            const minutes = Math.floor(durationSec / 60)
            const seconds = durationSec - 60 * minutes
            this.learn.testDuration = minutes + ':' + (seconds < 10 ? '0' : '') + seconds
          }
          // Apply Mathjax to incorrect answers
          this.$nextTick(() => {
            if (window.MathJax) {
              window.MathJax.typeset(['.incorrectAnswersMathjax'])
            }
          })
        }
      }
      if (this.learn.gaming) {
        this.sortReviewQueueForGame(false)
      }
      if (!this.learn.testing && !this.learn.gaming) {
        this.sortReviewQueue(false)
      }
      this.learn.answerVisible = false
      // Prepare another question
      if (this.learn.reviewQueue.length > learnPreloadCount && !this.learn.reviewQueue[learnPreloadCount - 1].ready) {
        this.prepareQuestion(learnPreloadCount - 1)
      }
      // Start timer if item is ready
      if (this.learn.reviewQueue[0].ready) {
        this.startTimerOnNextTick()
      }
    },
    applyMathjax: function () {
      this.$nextTick(() => {
        if (window.MathJax) {
          window.MathJax.typeset(['.tex2jax_process'])
        }
      })
    },
    applyMathjaxToRowDataTables: function () {
      this.$nextTick(() => {
        window.MathJax.typeset(['.rowDataTable'])
      })
    },
    backgroundPhotoClick: function (rowIndex) {
      if (this.memoSetType !== 'regular') return
      this.closeToasts()
      // Save row index
      this.rowIndex = rowIndex
      this.sortPhotos()
      this.modalPhotos = this.photos.slice()
      // Initialize modal
      this.initAddPhoto()
      this.applyToRows = ''
      this.selectedPhotoIndex = this.photoIndexes[rowIndex]
      // Set initial tab name
      this.backgroundPhotoTab = this.modalPhotos.length ? 'backgroundPhotoSelect' : 'backgroundPhotoAdd'
      // Allow time for previous image to be cleared
      this.$nextTick(() => {
        this.modal = 'backgroundPhoto'
        $('.ui.modal.backgroundPhoto')
          .modal(
            {
              autofocus: false,
              centered: false,
              onApprove: this.backgroundPhotoSubmit,
              onHidden: this.revertPreventBackHistory,
              onHide: this.resetModal,
              onVisible: this.prepareModalHistory
            }
          )
          .modal('show')
        // Set initial tab
        let initialTab = this.modalPhotos.length ? 'backgroundPhotoSelect' : 'backgroundPhotoAdd'
        $('.menu.backgroundPhotoTabs .item').tab('change tab', initialTab)
        if (initialTab === 'backgroundPhotoSelect') {
          // Scroll to current photo
          this.$nextTick(() => {
            document.querySelector('.modal.backgroundPhoto tr.positive').scrollIntoView({block: 'center'})
          })
        }
      })
    },
    backgroundPhotoEnter: function () {
      // Exit if modal is incomplete
      if (this.backgroundPhotoTab === 'backgroundPhotoAdd' && !this.addPhoto.url) return
      // Hide modal
      $('.ui.modal.backgroundPhoto').modal('hide')
      this.backgroundPhotoSubmit()
    },
    backgroundPhotoSubmit: function () {
      if (this.backgroundPhotoTab === 'backgroundPhotoAdd') {
        this.backgroundPhotoSubmitAdd()
        // Prevent modal closing
        return false
      }
      if (this.backgroundPhotoTab === 'backgroundPhotoSelect') {
        this.backgroundPhotoUpdate()
      }
    },
    backgroundPhotoSubmitAdd: function () {
      const self = this
      function addPhoto (downloadUrl, height, width) {
        const photoId = self.randomId()
        // Update photos array
        self.modalPhotos.push({
          height: height,
          id: photoId,
          url: downloadUrl,
          width: width
        })
        // Update row to use the new photo
        self.selectedPhotoIndex = self.modalPhotos.length - 1
        self.backgroundPhotoUpdate()
        // Close modal
        $('.ui.modal.backgroundPhoto').modal('hide')
      }
      let canvas, ctx, height, imageDataUrl, imageKey, img, scaleFactor, tempCanvas, tempCtx, width
      this.addPhoto.uploading = true
      img = document.getElementById('addPhotoImage')
      // Get a random key for the image
      imageKey = this.randomId()
      // Get photo dimensions
      height = img.naturalHeight
      width = img.naturalWidth
      // If image is jpg and isn't too large
      if (
        this.addPhoto.file.type === 'image/jpeg' &&
        img.naturalHeight <= maxImageSize &&
        img.naturalWidth <= maxImageSize
      ) {
        // Upload the image to Firebase Storage
        firebase.storage().ref('userImages/' + this.user.id + '/' + imageKey + '.jpg').put(this.addPhoto.file, {cacheControl: cacheControl})
          .then(function (snapshot) {
            return snapshot.ref.getDownloadURL()
          })
          .then(downloadUrl => {
            addPhoto(downloadUrl, height, width)
          })
      } else {
        scaleFactor = Math.min(maxImageSize / img.naturalWidth, maxImageSize / img.naturalHeight)
        scaleFactor = Math.min(scaleFactor, 1)
        // Limit scale down to half to preserve image quality
        scaleFactor = Math.max(scaleFactor, 0.5)
        tempCanvas = document.createElement('canvas')
        tempCanvas.width = img.naturalWidth * scaleFactor
        tempCanvas.height = img.naturalHeight * scaleFactor
        tempCtx = tempCanvas.getContext('2d')
        // Fill with white in case of transparent images
        tempCtx.fillStyle = 'white'
        tempCtx.fillRect(0, 0, tempCanvas.width, tempCanvas.height)
        tempCtx.drawImage(img, 0, 0, tempCanvas.width, tempCanvas.height)
        width = tempCanvas.width
        height = tempCanvas.height
        while (scaleFactor === 0.5) {
          scaleFactor = Math.min(maxImageSize / width, maxImageSize / height)
          scaleFactor = Math.max(scaleFactor, 0.5)
          tempCtx.drawImage(tempCanvas, 0, 0, width, height, 0, 0, width * scaleFactor, height * scaleFactor)
          width = width * scaleFactor
          height = height * scaleFactor
        }
        // Draw section of tempCanvas onto canvas
        canvas = document.createElement('canvas')
        canvas.width = width
        canvas.height = height
        ctx = canvas.getContext('2d')
        ctx.drawImage(tempCanvas, 0, 0, width, height, 0, 0, width, height)
        imageDataUrl = canvas.toDataURL('image/jpeg')
        // Upload the image to Firebase Storage
        firebase.storage().ref('userImages/' + this.user.id + '/' + imageKey + '.jpg').putString(imageDataUrl, 'data_url', {cacheControl: cacheControl})
          .then(function (snapshot) {
            return snapshot.ref.getDownloadURL()
          })
          .then(downloadUrl => {
            addPhoto(downloadUrl, height, width)
          })
      }
    },
    backgroundPhotoUpdate: function () {
      let firestoreUpdateDocs, fromRowIndex, oldPhotoId, photoId, row, toRowIndex
      firestoreUpdateDocs = {}
      // Determine rows to be updated
      fromRowIndex = this.rowIndex
      toRowIndex = this.rowIndex
      if (parseInt(this.applyToRows, 10) > 0) {
        toRowIndex = this.rowIndex + parseInt(this.applyToRows, 10)
        if (toRowIndex > this.rows.length - 1) toRowIndex = this.rows.length - 1
      }
      // If 'no photo' selected
      if (this.selectedPhotoIndex === -1) {
        for (let i = fromRowIndex; i <= toRowIndex; i++) {
          row = this.rows[i]
          oldPhotoId = row.data.photoId
          if (oldPhotoId) {
            this.$delete(row.data, 'photoId')
            // Update rectangle and object positions
            this.adjustObjectPositionsForRow(row, oldPhotoId)
            // Update firestoreUpdateDocs
            if (!firestoreUpdateDocs[row.docId]) firestoreUpdateDocs[row.docId] = {}
            firestoreUpdateDocs[row.docId][row.rowId + '.photoId'] = firebase.firestore.FieldValue.delete()
            firestoreUpdateDocs[row.docId][row.rowId + '.location'] = row.data.location
            if (row.data.objects) {
              firestoreUpdateDocs[row.docId][row.rowId + '.objects'] = row.data.objects
            }
          }
        }
      } else {
        photoId = this.modalPhotos[this.selectedPhotoIndex].id
        for (let i = fromRowIndex; i <= toRowIndex; i++) {
          row = this.rows[i]
          oldPhotoId = row.data.photoId
          if (oldPhotoId !== photoId) {
            row.data.photoId = photoId
            // Make update reactive
            this.$set(this.rows, i, row)
            // Update rectangle and object positions
            this.adjustObjectPositionsForRow(row, oldPhotoId)
            // Update firestoreUpdateDocs
            if (!firestoreUpdateDocs[row.docId]) firestoreUpdateDocs[row.docId] = {}
            firestoreUpdateDocs[row.docId][row.rowId + '.photoId'] = photoId
            firestoreUpdateDocs[row.docId][row.rowId + '.location'] = row.data.location
            if (row.data.objects) {
              firestoreUpdateDocs[row.docId][row.rowId + '.objects'] = row.data.objects
            }
          }
        }
      }
      const batch = db.batch()
      // Update photos array in Firestore, if changed
      if (this.modalPhotos.map(p => p.id).join() !== this.photos.map(p => p.id).join()) {
        this.photos = this.modalPhotos.slice()
        batch.update(db.doc('memoSets/' + this.memoSetId), {
          photos: this.photos
        })
        this.updateSharedSummaries(batch, {
          photos: this.photos
        })
      }
      // Update rows in Firestore
      Object.keys(firestoreUpdateDocs).forEach(docId => {
        batch.update(db.doc('memoSets/' + this.memoSetId + '/rows/' + docId), firestoreUpdateDocs[docId])
      })
      this.writeImageKeys(batch)
      batch.commit()
        .catch(error => { logError('MemoSet_backgroundPhotoUpdate', error) })
      // Update thumbnail for affected rows
      for (let i = fromRowIndex; i <= toRowIndex; i++) {
        this.createThumbnail(i)
      }
    },
    cancelUpdateNowTimeout: function () {
      if (this.timeouts.updateNow) {
        clearTimeout(this.timeouts.updateNow)
        this.timeouts.updateNow = null
      }
    },
    cardStyle: function (value) {
      let cardNum, cardNum1, cardNum2, cardSet, extension, style
      style = null
      if (value && value.slice(0, 1) === '(' && value.slice(-1) === ')') {
        // Remove parentheses
        value = value.slice(1, -1)
        // Trim leading and trailing spaces
        value = value.trim()
        // Set default card set and file extension
        cardSet = 'english'
        extension = 'svg'
        // Look for card type
        if (value.slice(0, 3) === 'de:') {
          cardSet = 'german'
          extension = 'png'
          value = value.slice(3).trim()
        }
        if (value.slice(0, 2) === 'e:') {
          value = value.slice(2).trim()
        }
        if (value.slice(0, 2) === '4:') {
          cardSet = 'four-color'
          value = value.slice(2).trim()
        }
        // Split by space, with filter to remove empty strings
        const values = value.split(' ').filter(v => v)
        if (values.length === 1) {
          cardNum = getCardNum(values[0], cardSet)
          if (cardNum !== -1) {
            style = {
              backgroundImage: 'url(/cards/' + cardSet + '/card' + cardNum + '.' + extension + ')',
              backgroundPosition: '10px 10px',
              backgroundRepeat: 'no-repeat',
              backgroundSize: '120px'
            }
          }
        }
        if (values.length === 2) {
          cardNum1 = getCardNum(values[0], cardSet)
          cardNum2 = getCardNum(values[1], cardSet)
          if (cardNum1 !== -1 && cardNum2 !== -1) {
            style = {
              backgroundImage: 'url(/cards/' + cardSet + '/card' + cardNum2 + '.' + extension + '), url(/cards/' + cardSet + '/card' + cardNum1 + '.' + extension + ')',
              backgroundPosition: '40px 10px, 4px 10px',
              backgroundRepeat: 'no-repeat, no-repeat',
              backgroundSize: '120px, 120px'
            }
          }
        }
      }
      return style
    },
    cellAudioFileChange: function (file) {
      if (file) {
        if (file.size > maxAudioSize) {
          alert('Maximum file size exceeded')
          this.initAddCellAudio()
        } else {
          this.addCellAudio.url = window.URL.createObjectURL(file)
          this.addCellAudio.file = file
        }
      }
    },
    cellClick: function (event) {
      let contentDiv
      if (this.memoSetType !== 'regular') return
      if (event.target.classList.contains('cellTd')) {
        contentDiv = event.target.querySelectorAll('div[contenteditable]')[0]
        contentDiv.focus()
        moveCursorToEndOfContentEditable(contentDiv)
      }
    },
    cellImageFileChange: function (file) {
      if (file) {
        // Check that file is an image
        if (isImageFile(file)) {
          // Show image in the modal
          this.addCellImage.url = window.URL.createObjectURL(file)
          this.addCellImage.file = file
        } else {
          this.initAddCellImage()
          alert(this.text.memoSetSelectImageFile)
        }
      }
    },
    cellImageLoad: function () {
      this.addCellImage.loading = false
      window.URL.revokeObjectURL(this.addCellImage.url)
      // If image was pasted directly onto cell, submit the modal
      if (this.addCellImage.autoSubmit) {
        this.addCellImage.autoSubmit = false
        this.addCellImageSubmit()
      }
    },
    cellImagePaste: function (event) {
      let items
      if (event.clipboardData) {
        items = event.clipboardData.items
        if (items) {
          for (let i = 0; i < items.length; i++) {
            if (items[i].type.substring(0, 6) === 'image/') {
              this.addCellImage.loading = true
              this.addCellImage.file = items[i].getAsFile()
              this.addCellImage.url = window.URL.createObjectURL(this.addCellImage.file)
              break
            }
          }
        }
      }
    },
    cellInput: function (event) {
      this.closeToasts()
      this.editingCell = true
      this.currentFieldContent = event.target.innerHTML
    },
    checkDataTabActive: function () {
      // Workaround for Data tab becoming inactive when screen size changes
      this.$nextTick(() => {
        const dataTab = document.getElementById('dataTab')
        if (this.activeTab === 'data' && !dataTab.classList.contains('active')) {
          dataTab.classList.add('active')
        }
      })
    },
    checkImagesThen: function (urls, next, args) {
      const self = this
      let imagesToLoad

      function imageLoaded () {
        imagesToLoad--
        if (!imagesToLoad) next(...args)
      }

      function checkImageLoaded (url) {
        // If the image has loaded
        if (self.images[url].loaded) {
          imageLoaded()
        } else {
          // Keep waiting
          setTimeout(checkImageLoaded, 100, url)
        }
      }

      imagesToLoad = 0
      urls.forEach(url => {
        // If the image is currently being loaded
        if (this.images[url] && !this.images[url].loaded) {
          imagesToLoad++
          // Wait for image to load
          setTimeout(checkImageLoaded, 100, url)
        }
        if (!this.images[url]) {
          imagesToLoad++
          this.$set(this.images, url, {
            image: new Image(),
            loaded: false
          })
          this.images[url].image.crossOrigin = 'Anonymous'
          this.images[url].image.onload = () => {
            this.images[url].loaded = true
            imageLoaded()
          }
          this.images[url].image.src = url
        }
      })
      // Call next function if all images are loaded
      if (!imagesToLoad) next(...args)
    },
    checkLearnItemReady: function (item) {
      const summaryImageRegex = /\[SummaryImage(\d+)\]/
      if (
        !item.ready &&
        !item.audioRemaining &&
        !item.imagesRemaining &&
        item.questionHtml &&
        !item.questionHtml.match(summaryImageRegex) &&
        item.answerHtml &&
        !item.answerHtml.match(summaryImageRegex) &&
        (
          !item.alternativeAnswerHtmls ||
          !item.alternativeAnswerHtmls.find(html => html.match(summaryImageRegex))
        )
      ) {
        this.setLearnItemReady(item)
      }
    },
    checkModalActive: function () {
      // Workaround for modal becoming inactive sometimes when screen size changes
      this.$nextTick(() => {
        if (this.modal && !$('.ui.modal.' + this.modal).modal('is active')) {
          $('.ui.modal.' + this.modal).modal('set active')
        }
      })
    },
    checkRowQuestions: function (rowIndex) {
      let questionsDeleted
      const row = this.rows[rowIndex]
      this.questions.forEach(question => {
        // If there is question data but no data for the question
        if (
          row.data.questions &&
          row.data.questions[question.questionId] &&
          !this.rowHasData(row, question.fromFieldId, question.toFieldId)
        ) {
          // Save the row question to cellChanges
          this.cellChanges.rowQuestions.push({
            rowIndex: rowIndex,
            questionId: question.questionId,
            questionData: row.data.questions[question.questionId]
          })
          // Remove question from the row
          this.$delete(row.data.questions, question.questionId)
          questionsDeleted = true
        }
      })
      return questionsDeleted
    },
    checkVisibleThumbnails: function () {
      // This function checks that thumbnails exist for all visible rows. If not, it creates them.
      for (let i = this.firstVisibleRow - 1; i < Math.min(this.firstVisibleRow - 1 + this.visibleRowsCount, this.rows.length); i++) {
        if (!this.thumbnails[i]) this.createThumbnail(i)
      }
    },
    clipArea: function () {
      let canvas, ctx, undoEntry
      canvas = document.getElementById('objectCanvas')
      ctx = canvas.getContext('2d')
      ctx.beginPath()
      for (let i = 0; i < this.clippingPath.length; i++) {
        let coords = this.clippingPath[i]
        if (i === 0) {
          ctx.moveTo(coords.x, coords.y)
        } else {
          ctx.lineTo(coords.x, coords.y)
        }
      }
      ctx.clearRect(0, 0, canvas.width, canvas.height)
      ctx.clip()
      // Draw image within clipping area
      ctx.drawImage(this.editCanvas, 0, 0)
      const obj = this.objects[this.activeObject]
      obj.url = canvas.toDataURL('image/png')
      this.clipping = false
      this.mode = 'select'
      this.removeClippingPathUndoEntries()
      // Add an undo entry for the clip
      undoEntry = {
        after: {
          url: obj.url
        },
        before: {
          url: this.summaryImage.before.url
        },
        changeType: 'clip',
        objIndex: this.activeObject
      }
      this.addSummaryImageUndoEntry(undoEntry)
    },
    clipClick: function () {
      let ctx, img
      // Blur button
      document.getElementById('clipButton').blur()
      if (this.mode === 'draw') this.endDrawMode()
      if (this.mode === 'remove') this.endRemoveMode()
      const obj = this.objects[this.activeObject]
      if (this.mode === 'clip') {
        this.endClipMode()
      } else {
        // Save the object's URL
        this.summaryImage.before = {
          url: obj.url
        }
        // Initialize editCanvas with the object image
        img = new Image()
        img.crossOrigin = 'Anonymous'
        img.onload = () => {
          this.editCanvas = document.createElement('canvas')
          this.editCanvas.width = img.naturalWidth
          this.editCanvas.height = img.naturalHeight
          ctx = this.editCanvas.getContext('2d')
          ctx.drawImage(img, 0, 0)
          this.drawObjectCanvas()
          this.mode = 'clip'
          this.clippingPath = []
          this.clipping = false
          this.panLock = false
        }
        img.src = obj.url
      }
    },
    clippingPathComplete: function (coords) {
      const snapPixels = 20
      let canvas, canvasDistanceFromStart, initialCoords, obj, pointCoords, screenDistanceFromStart
      canvas = document.getElementById('objectCanvas')
      obj = this.objects[this.activeObject]
      initialCoords = this.clippingPath[0]
      // Exit if no path yet
      if (!initialCoords) return false
      canvasDistanceFromStart = Math.sqrt(Math.pow(coords.x - initialCoords.x, 2) + Math.pow(coords.y - initialCoords.y, 2))
      screenDistanceFromStart = canvasDistanceFromStart * this.photoTransform.scale / canvas.width * obj.width
      // Exit if we're too far from the starting point
      if (screenDistanceFromStart > snapPixels) return false
      // Exit if we don't have at least 3 points in the clipping path
      if (this.clippingPath.length < 3) return false
      // Check whether the clipping path has gone far enough from the starting point to be valid
      for (let i = 0; i < this.clippingPath.length; i++) {
        pointCoords = this.clippingPath[i]
        canvasDistanceFromStart = Math.sqrt(Math.pow(pointCoords.x - initialCoords.x, 2) + Math.pow(pointCoords.y - initialCoords.y, 2))
        screenDistanceFromStart = canvasDistanceFromStart * this.photoTransform.scale / canvas.width * obj.width
        if (screenDistanceFromStart > snapPixels) return true
      }
      return false
    },
    clippingPointerDown: function (pointer) {
      let canvasCoords
      canvasCoords = this.getCanvasCoords(pointer.clientX, pointer.clientY)
      if (this.clippingPathComplete(canvasCoords)) {
        // Perform the clip
        this.clipArea()
      } else {
        this.clipping = true
        // If the previous undo entry has a clipping path, start with that
        if (
          this.summaryImage.undoArrayIndex &&
          this.summaryImage.undoArray[this.summaryImage.undoArrayIndex - 1].changeType === 'clippingPath'
        ) {
          this.clippingPath = this.summaryImage.undoArray[this.summaryImage.undoArrayIndex - 1].after.slice()
        } else {
          this.clippingPath = []
        }
        this.clippingPath.push({
          x: canvasCoords.x,
          y: canvasCoords.y
        })
        this.drawObjectCanvas()
        this.drawClippingPath(false)
      }
    },
    clippingPointerMove: function (pointer) {
      let canvasCoords, initialCoords, pathComplete
      canvasCoords = this.getCanvasCoords(pointer.clientX, pointer.clientY)
      if (this.clipping) {
        initialCoords = this.clippingPath[0]
        if (this.clippingPathComplete(canvasCoords)) {
          this.clipArea()
        } else {
          this.clippingPath.push({
            x: canvasCoords.x,
            y: canvasCoords.y
          })
          this.drawObjectCanvas()
          this.drawClippingPath(false)
        }
      } else {
        // If there is any clipping path, draw a line from the last point to the new point
        if (this.clippingPath.length) {
          initialCoords = this.clippingPath[0]
          if (this.clippingPathComplete(canvasCoords)) {
            canvasCoords = {
              x: initialCoords.x,
              y: initialCoords.y
            }
            pathComplete = true
          }
          this.drawObjectCanvas()
          this.drawClippingPath(pathComplete, canvasCoords)
        }
      }
    },
    clippingPointerUp: function () {
      let undoEntry
      // If the previous clipping path is just a point, remove it from the undo array
      if (
        this.summaryImage.undoArrayIndex &&
        this.summaryImage.undoArray[this.summaryImage.undoArrayIndex - 1].changeType === 'clippingPath' &&
        this.summaryImage.undoArray[this.summaryImage.undoArrayIndex - 1].after.length === 1
      ) {
        this.summaryImage.undoArray.splice(this.summaryImage.undoArrayIndex - 1, 1)
        this.summaryImage.undoArrayIndex--
      }
      undoEntry = {
        after: this.clippingPath.slice(),
        changeType: 'clippingPath'
      }
      this.addSummaryImageUndoEntry(undoEntry)
      this.drawObjectCanvas()
      this.drawClippingPath(false)
      this.clipping = false
    },
    closeLearnModal: function () {
      $('.ui.modal.learn').modal('hide')
    },
    closeLearnGameModal: function () {
      $('.ui.modal.learnGame').modal('hide')
    },
    closeLearnTestModal: function () {
      $('.ui.modal.learnTest').modal('hide')
    },
    closeToasts: function () {
      $('.ui.toast').toast('close')
    },
    closeWalkthrough: function () {
      $('.ui.modal.walkthrough').modal('hide')
    },
    convertSystemImages: function (cells) {
      for (let i = 0; i < cells.length; i++) {
        if (/^https:\/\/firebasestorage.*/.test(cells[i])) {
          // Wrap cell in image tag
          cells[i] = '<img src="' + cells[i] + '">'
          // If next cell is the thumbnail, discard it
          if (cells[i + 1] && /^https:\/\/firebasestorage.*_tn/.test(cells[i + 1])) {
            cells.splice(i + 1, 1)
          }
        }
      }
      return cells
    },
    copyDownClick: function (rowIndex, fieldId) {
      this.closeToasts()
      this.fieldId = fieldId
      this.rowIndex = rowIndex
      this.applyToRows = ''
      if (fieldId === 'reviewGroup') {
        this.copyDown.value = document.getElementById(fieldId + '_' + rowIndex).textContent
      } else {
        this.copyDown.value = this.sanitize(document.getElementById(fieldId + '_' + rowIndex).innerHTML)
      }
      this.copyDown.displayValue = this.copyDown.value
      if (fieldId === 'reviewGroup') {
        if (this.copyDown.value) {
          this.copyDown.displayValue = '<div class="ui label reviewGroup ' + this.reviewGroupColors[this.copyDown.value] + '">' + this.copyDown.value + '</div>'
        } else {
          this.copyDown.displayValue = '<div class="ui label reviewGroup noGroup">' + this.text.memoSetNoGroup + '</div>'
        }
      } else {
        const cardStyle = this.cardStyle(this.copyDown.value)
        if (cardStyle) {
          this.copyDown.displayValue = this.questionCardHtml(cardStyle)
        }
      }
      this.$nextTick(() => {
        this.modal = 'copyDown'
        $('.ui.modal.copyDown')
          .modal({
            onApprove: this.copyDownSubmit,
            onHidden: () => {
              this.modal = ''
              this.revertPreventBackHistory()
            },
            onVisible: this.prepareModalHistory
          })
          .modal('show')
        // Apply Mathjax
        if (fieldId !== 'reviewGroup') {
          this.$nextTick(() => {
            if (window.MathJax) {
              window.MathJax.typeset(['.copyDownDisplayValue'])
            }
          })
        }
      })
    },
    copyDownEnter: function () {
      // Exit if form is incomplete
      if (!this.applyToRows || !(parseInt(this.applyToRows, 10) >= 1)) return
      // Hide modal
      $('.ui.modal.copyDown').modal('hide')
      this.copyDownSubmit()
    },
    copyDownSubmit: function () {
      let firestoreUpdateDocs, firestoreValue, updateFromRowIndex, updateToRowIndex
      const batch = db.batch()
      const fieldId = this.fieldId
      this.cellChanges.cells = []
      this.cellChanges.rowQuestions = []
      this.cellChanges.rowsAdded = 0
      updateFromRowIndex = this.rowIndex + 1
      updateToRowIndex = updateFromRowIndex + parseInt(this.applyToRows, 10) - 1
      // Cap at the number of rows
      if (updateToRowIndex > this.rows.length - 1) updateToRowIndex = this.rows.length - 1
      firestoreUpdateDocs = {}
      for (let i = updateFromRowIndex; i < updateToRowIndex + 1; i++) {
        const row = this.rows[i]
        const previousValue = row.data[fieldId] === undefined ? '' : row.data[fieldId]
        if (this.copyDown.value !== previousValue) {
          this.cellChanges.cells.push({
            fieldId: fieldId,
            rowIndex: i,
            value: previousValue
          })
          if (this.copyDown.value === '') {
            this.$delete(row.data, fieldId)
            firestoreValue = firebase.firestore.FieldValue.delete()
          } else {
            this.$set(row.data, fieldId, this.copyDown.value)
            firestoreValue = this.copyDown.value
          }
          // Workaround for DOM not being updated properly when field is review group
          if (fieldId === 'reviewGroup') {
            const reviewGroupCell = document.getElementById('reviewGroup_' + i)
            if (reviewGroupCell) {
              reviewGroupCell.innerHTML = this.copyDown.value
            }
          }
          // Update Firestore object
          if (!firestoreUpdateDocs[row.docId]) firestoreUpdateDocs[row.docId] = {}
          firestoreUpdateDocs[row.docId][row.rowId + '.' + fieldId] = firestoreValue
        }
      }
      // Update Firestore
      Object.keys(firestoreUpdateDocs).forEach(docId => {
        batch.update(db.doc('memoSets/' + this.memoSetId + '/rows/' + docId), firestoreUpdateDocs[docId])
      })
      this.updateKnownData(batch, this.memoSetId)
      batch.commit()
        .then(() => {
          this.showCopiedDownToast()
        })
        .catch(error => { logError('MemoSet_copyDownSubmit', error) })
      // Apply Mathjax to changed data
      this.applyMathjax()
      // Update search data
      this.initSearch()
    },
    copyMemoSetClick: function () {
      document.getElementById('copyMemoSetButton').blur()
      this.closeToasts()
      // Initialize new name
      this.copyMemoSet.newTitle = this.text.memoSetNewNamePre + this.title + this.text.memoSetNewNamePost
      this.copyMemoSet.newFolderName = ''
      // Prepare Folder dropdown
      this.copyMemoSet.folderId = this.folderId
      $('.copyMemoSetFolderId')
        .dropdown({
          onChange: value => {
            this.copyMemoSet.folderId = value
            if (value === 'new') {
              this.$nextTick(() => {
                document.getElementById('newFolderName').focus()
              })
            }
          },
          showOnFocus: false
        })
        .dropdown('set selected', this.copyMemoSet.folderId)
      this.modal = 'copyMemoSet'
      $('.ui.modal.copyMemoSet')
        .modal({
          onApprove: this.copyMemoSetSubmit,
          onHidden: this.revertPreventBackHistory,
          onHide: () => {
            // Workaround for onHide firing multiple times
            if (this.modal === 'copyMemoSet') {
              this.resetModal()
            }
          },
          onVisible: this.prepareModalHistory
        })
        .modal('show')
    },
    copyMemoSetEnter: function () {
      // Exit if form is incomplete
      if (
        !this.copyMemoSet.newTitle ||
        this.copyMemoSet.folderId === '' ||
        (this.copyMemoSet.folderId === 'new' && !this.copyMemoSet.newFolderName)
      ) return
      // Hide modal
      $('.ui.modal.copyMemoSet').modal('hide')
      this.copyMemoSetSubmit()
    },
    copyMemoSetSubmit: function () {
      if (this.memoSetType === 'regular') {
        this.copyMemoSet.newMemoSetId = this.randomId()
      } else {
        this.copyMemoSet.newMemoSetId = this.memoSetId + '_' + this.user.id
        // If we already have a memo set with this ID
        if (this.memoSets.filter(memoSet => memoSet.memoSetId === this.copyMemoSet.newMemoSetId).length) {
          let i = 2
          while (true) {
            let trialMemoSetId = this.copyMemoSet.newMemoSetId + '_' + i
            // If the trial memo set ID is available
            if (!this.memoSets.filter(memoSet => memoSet.memoSetId === trialMemoSetId).length) {
              this.copyMemoSet.newMemoSetId = trialMemoSetId
              break
            }
            i++
          }
        }
      }
      this.copyMemoSetWrite()
    },
    copyMemoSetWrite: function () {
      let folderId, folders, firestoreNewDocs, memoSetObj, seq, updateObj
      const docId = this.firstAvailableDocId()
      this.copyMemoSet.slug = this.createMemoSetSlug(this.copyMemoSet.newTitle)
      const batch = db.batch()
      if (this.copyMemoSet.folderId === 'new') {
        // Create the new folder
        folderId = this.nextFolderId()
        folders = this.user.folders.slice()
        folders.push({
          folderId: folderId,
          folderName: this.copyMemoSet.newFolderName
        })
        batch.update(db.doc('users/' + this.user.id), {
          folders: folders
        })
        seq = 0
      } else {
        folderId = this.copyMemoSet.folderId
        // Look up folder name for new memo set
        this.copyMemoSet.newFolderName = this.user.folders.filter(folder => folder.folderId === folderId)[0].folderName
        // Set seq to 1 more than the highest seq in the folder
        seq = Math.max(...this.memoSets.filter(memoSet => memoSet.folderId === folderId).map(memoSet => memoSet.seq), -1) + 1
      }
      // Write memoSets document
      memoSetObj = {
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        description: this.description,
        fields: this.fields,
        options: this.options,
        photos: this.photos,
        questions: this.questions,
        sharing: {},
        userId: this.user.id
      }
      if (this.memoSetType !== 'regular') {
        memoSetObj.authorId = this.ownerId
        memoSetObj.authorName = this.ownerName
      }
      batch.set(db.doc('memoSets/' + this.copyMemoSet.newMemoSetId), memoSetObj)
      // Write memo set rows documents
      firestoreNewDocs = {}
      this.rows.forEach(row => {
        if (!firestoreNewDocs[row.docId]) firestoreNewDocs[row.docId] = { sharing: {}, userId: this.user.id }
        firestoreNewDocs[row.docId][row.rowId] = Object.assign({}, row.data)
        // Exclude learning data if copying someone else's memo set
        if (this.memoSetType !== 'regular') {
          delete firestoreNewDocs[row.docId][row.rowId].questions
        }
      })
      Object.keys(firestoreNewDocs).forEach(docId => {
        batch.set(db.doc('memoSets/' + this.copyMemoSet.newMemoSetId + '/rows/' + docId), firestoreNewDocs[docId])
      })
      // Write users/$userId/memoSets/$docId document
      updateObj = {}
      updateObj[this.copyMemoSet.newMemoSetId] = {
        coverImage: this.coverImage,
        folderId: folderId,
        rowCount: this.rows.length,
        seq: seq,
        slug: this.copyMemoSet.slug,
        title: this.copyMemoSet.newTitle
      }
      // If copying our own memo set, copy the hide status
      if (this.memoSetType === 'regular' && !this.active) {
        updateObj[this.copyMemoSet.newMemoSetId].hide = true
      }
      batch.set(db.doc('users/' + this.user.id + '/memoSets/' + docId), updateObj, { merge: true })
      // If copying our own memo set
      if (this.memoSetType === 'regular') {
        // Write known data for the new copy
        this.updateKnownData(batch, this.copyMemoSet.newMemoSetId)
      } else {
        this.writeInitialKnownData(batch, this.copyMemoSet.newMemoSetId)
      }
      batch.commit()
        .then(() => {
          if (this.memoSetType === 'regular') {
            this.showMemoSetCopiedToast()
          } else {
            this.showMemoSetAddedToast()
          }
        })
        .catch(error => { logError('MemoSet_copyMemoSetWrite', error) })
    },
    copyOfCanvas: function (canvas) {
      let tempCanvas, tempCtx
      tempCanvas = document.createElement('canvas')
      tempCanvas.height = canvas.height
      tempCanvas.width = canvas.width
      tempCtx = tempCanvas.getContext('2d')
      tempCtx.drawImage(canvas, 0, 0)
      return tempCanvas
    },
    coverImageClick: function () {
      if (this.memoSetType !== 'regular') return
      this.closeToasts()
      this.initSetCoverImage()
      this.setCoverImage.url = this.coverImage
      // Sort background photos according to use in rows
      this.sortPhotos()
      // Allow time for previous image to be cleared
      this.$nextTick(() => {
        this.modal = 'setCoverImage'
        $('.ui.modal.setCoverImage')
          .modal(
            {
              autofocus: false,
              observeChanges: true,
              onApprove: this.coverImageSubmit,
              onHidden: this.revertPreventBackHistory,
              onHide: this.resetModal,
              onVisible: this.prepareModalHistory
            }
          )
          .modal('show')
      })
    },
    coverImageEnter: function () {
      // Exit if modal is incomplete
      if (!this.setCoverImage.url) return
      this.coverImageSubmit()
    },
    coverImagePaste: function (event) {
      let i, items
      if (event.clipboardData) {
        items = event.clipboardData.items
        if (items) {
          for (i = 0; i < items.length; i++) {
            if (items[i].type.substring(0, 6) === 'image/') {
              this.setCoverImage.loading = true
              this.setCoverImage.file = items[i].getAsFile()
              this.setCoverImage.url = window.URL.createObjectURL(this.setCoverImage.file)
              break
            }
          }
        }
      }
    },
    coverImageSetTemp: function (downloadUrl) {
      // Put the blob or data URL into coverImageTemp, so we can use it for the cover image until the image has been downloaded
      this.coverImageTemp = this.setCoverImage.url
      // Download image
      const img = new Image()
      img.onload = () => {
        window.URL.revokeObjectURL(this.coverImageTemp)
        this.setCoverImageTemp = ''
      }
      img.src = downloadUrl
    },
    coverImageSubmit: function () {
      let canvas, ctx, height, imageDataUrl, imageKey, img, scaleFactor, tempCanvas, tempCtx, width
      const self = this
      // If the cover image hasn't changed, do nothing
      if (this.setCoverImage.url === this.coverImage) return
      // If the cover image is a background photo, no need to upload it
      if (this.photos.some(photo => photo.url === this.setCoverImage.url)) {
        this.coverImageUpdate(this.setCoverImage.url)
      } else {
        this.setCoverImage.uploading = true
        img = document.getElementById('setCoverImage')
        // Get a random key for the image
        imageKey = this.randomId()
        // If image is jpg and isn't too large
        if (
          this.setCoverImage.file.type === 'image/jpeg' &&
          img.naturalHeight <= maxCoverImageHeight &&
          img.naturalWidth <= maxCoverImageWidth
        ) {
          // Upload the image to Firebase Storage
          firebase.storage().ref('userImages/' + this.user.id + '/' + imageKey + '.jpg').put(this.setCoverImage.file, {cacheControl: cacheControl})
            .then(function (snapshot) {
              return snapshot.ref.getDownloadURL()
            })
            .then(downloadUrl => {
              self.coverImageSetTemp(downloadUrl)
              self.coverImageUpdate(downloadUrl)
            })
        } else {
          scaleFactor = Math.min(maxCoverImageWidth / img.naturalWidth, maxCoverImageHeight / img.naturalHeight)
          scaleFactor = Math.min(scaleFactor, 1)
          // Limit scale down to half to preserve image quality
          scaleFactor = Math.max(scaleFactor, 0.5)
          tempCanvas = document.createElement('canvas')
          tempCanvas.width = img.naturalWidth * scaleFactor
          tempCanvas.height = img.naturalHeight * scaleFactor
          tempCtx = tempCanvas.getContext('2d')
          // Fill with white in case of transparent images
          tempCtx.fillStyle = 'white'
          tempCtx.fillRect(0, 0, tempCanvas.width, tempCanvas.height)
          tempCtx.drawImage(img, 0, 0, tempCanvas.width, tempCanvas.height)
          width = tempCanvas.width
          height = tempCanvas.height
          while (scaleFactor === 0.5) {
            scaleFactor = Math.min(maxCoverImageWidth / width, maxCoverImageHeight / height)
            scaleFactor = Math.max(scaleFactor, 0.5)
            tempCtx.drawImage(tempCanvas, 0, 0, width, height, 0, 0, width * scaleFactor, height * scaleFactor)
            width = width * scaleFactor
            height = height * scaleFactor
          }
          // Draw section of tempCanvas onto canvas
          canvas = document.createElement('canvas')
          canvas.width = width
          canvas.height = height
          ctx = canvas.getContext('2d')
          ctx.drawImage(tempCanvas, 0, 0, width, height, 0, 0, width, height)
          imageDataUrl = canvas.toDataURL('image/jpeg')
          // Upload the image to Firebase Storage
          firebase.storage().ref('userImages/' + this.user.id + '/' + imageKey + '.jpg').putString(imageDataUrl, 'data_url', {cacheControl: cacheControl})
            .then(function (snapshot) {
              return snapshot.ref.getDownloadURL()
            })
            .then(downloadUrl => {
              self.coverImageSetTemp(downloadUrl)
              self.coverImageUpdate(downloadUrl)
            })
        }
      }
      // Prevent modal closing
      return false
    },
    coverImageUpdate: function (downloadUrl) {
      this.coverImageLoaded = false
      this.coverImage = downloadUrl
      // Update cover image in Firestore
      const batch = db.batch()
      let updateObj = {}
      updateObj[this.memoSetId + '.coverImage'] = this.coverImage
      batch.update(db.doc('users/' + this.user.id + '/memoSets/' + this.docId), updateObj)
      this.updateSharedSummaries(batch, {
        coverImage: this.coverImage
      })
      this.writeImageKeys(batch)
      batch.commit()
        .catch(error => { logError('MemoSet_coverImageUpdate', error) })
      this.setCoverImage.uploading = false
      // Close modal
      $('.ui.modal.setCoverImage').modal('hide')
    },
    createThumbnail: function (rowIndex) {
      let currentRow, locations, objects, photo, photoIndex, urls
      currentRow = this.rows[rowIndex]
      if (this.options.useBackgroundPhotos && currentRow.data.photoId) {
        // Initialize array of images to draw
        objects = []
        // Add background photo
        photoIndex = this.photoIndexes[rowIndex]
        photo = this.photos[photoIndex]
        objects.push({
          angle: 0,
          height: photo.height,
          left: 0,
          sequence: -1,
          top: 0,
          url: photo.url,
          width: photo.width
        })
        // Add the current row's object images
        if (currentRow.data.objects && currentRow.data.objects.length) {
          currentRow.data.objects.forEach(obj => {
            objects.push({
              angle: obj.angle,
              flipX: obj.flipX,
              flipY: obj.flipY,
              height: obj.height,
              left: obj.left,
              sequence: obj.sequence,
              top: obj.top,
              url: obj.tempUrl || obj.url,
              width: obj.width
            })
          })
        }
        // Create array of locations on the same photo, including rowIndex
        locations = []
        this.rows.forEach((row, rowIndex) => {
          if (row.data.photoId === currentRow.data.photoId) {
            locations.push({
              height: row.data.location.height,
              left: row.data.location.left,
              rowIndex: rowIndex,
              top: row.data.location.top,
              width: row.data.location.width
            })
          }
        })
        // Add the objects of any parent locations
        const parentLocations = locations.filter(parentLocation => this.isParentLocation(parentLocation, currentRow.data.location))
        parentLocations.forEach(parentLocation => {
          const parentLocationRow = this.rows[parentLocation.rowIndex]
          if (parentLocationRow.data.objects && parentLocationRow.data.objects.length) {
            parentLocationRow.data.objects.forEach(obj => {
              objects.push({
                angle: obj.angle,
                flipX: obj.flipX,
                flipY: obj.flipY,
                height: obj.height,
                left: obj.left,
                sequence: obj.sequence,
                top: obj.top,
                url: obj.tempUrl || obj.url,
                width: obj.width
              })
            })
          }
        })
        // Sort by sequence
        objects.sort((a, b) => {
          if (a.sequence < b.sequence) return -1
          return 1
        })
        urls = objects.map(obj => obj.url)
        this.checkImagesThen(urls, this.createThumbnailWithObjects, [rowIndex, objects])
      } else {
        // Initialize array of images to draw
        objects = []
        if (currentRow.data.objects && currentRow.data.objects.length) {
          currentRow.data.objects.forEach(obj => {
            objects.push({
              angle: obj.angle,
              flipX: obj.flipX,
              flipY: obj.flipY,
              height: obj.height,
              left: obj.left,
              sequence: obj.sequence,
              top: obj.top,
              url: obj.tempUrl || obj.url,
              width: obj.width
            })
          })
          // Sort by sequence
          objects.sort((a, b) => {
            if (a.sequence < b.sequence) return -1
            return 1
          })
          urls = objects.map(obj => obj.url)
          this.checkImagesThen(urls, this.createThumbnailWithObjects, [rowIndex, objects])
        } else {
          // No background, no objects
          this.$set(this.thumbnails, rowIndex, null)
        }
      }
    },
    createThumbnailsForLearnItem: function (item) {
      let htmls, regexMatch, rowIndexes, summaryImageRegex
      htmls = [item.questionHtml, item.answerHtml]
      if (item.alternativeAnswerHtmls) {
        htmls = htmls.concat(item.alternativeAnswerHtmls)
      }
      summaryImageRegex = /\[SummaryImage(\d+)\]/
      rowIndexes = []
      htmls.forEach(html => {
        regexMatch = html.match(summaryImageRegex)
        if (regexMatch) {
          // Push the capture group, which is the row index
          rowIndexes.push(parseInt(regexMatch[1], 0))
        }
      })
      // Remove duplicates from rowIndexes
      rowIndexes = [...new Set(rowIndexes)]
      // Create any summary image thumbnails required
      rowIndexes.forEach(rowIndex => {
        this.createThumbnail(rowIndex)
      })
    },
    createThumbnailWithObjects: function (rowIndex, objects) {
      const maxThumbnailWidth = 240
      const maxThumbnailHeight = 150
      let canvas, ctx, currentRow, location, scale, thumbnailHeight, thumbnailWidth
      // Initialize canvas
      canvas = document.createElement('canvas')
      ctx = canvas.getContext('2d')
      currentRow = this.rows[rowIndex]
      // Set canvas size according to location aspect
      if (currentRow.data.photoId) {
        location = currentRow.data.location
        if (location.width / location.height > maxThumbnailWidth / maxThumbnailHeight) {
          // Width is the limiting factor
          thumbnailWidth = maxThumbnailWidth
          thumbnailHeight = location.height * thumbnailWidth / location.width
        } else {
          // Height is the limiting factor
          thumbnailHeight = maxThumbnailHeight
          thumbnailWidth = location.width * thumbnailHeight / location.height
        }
        canvas.width = thumbnailWidth
        canvas.height = thumbnailHeight
      } else {
        canvas.width = maxThumbnailHeight
        canvas.height = maxThumbnailHeight
      }
      // Scale canvas
      if (currentRow.data.photoId) {
        scale = canvas.width / location.width
      } else {
        scale = canvas.width / noPhotoSize
      }
      ctx.scale(scale, scale)
      // Translate to location left top
      if (currentRow.data.photoId) {
        ctx.translate(-location.left, -location.top)
      }
      objects.forEach(obj => {
        ctx.save()
        ctx.translate(obj.left + obj.width / 2, obj.top + obj.height / 2)
        ctx.rotate(obj.angle * Math.PI / 180)
        // Flip canvas if object flipped
        if (obj.flipX) ctx.scale(-1, 1)
        if (obj.flipY) ctx.scale(1, -1)
        ctx.translate(-(obj.left + obj.width / 2), -(obj.top + obj.height / 2))
        ctx.drawImage(this.images[obj.url].image, obj.left, obj.top, obj.width, obj.height)
        ctx.restore()
      })
      this.$set(this.thumbnails, rowIndex, canvas.toDataURL())
      // If learning, update review items
      if ((this.modal === 'learn' || this.modal === 'learnGame' || this.modal === 'learnTest') && this.learn.reviewQueue) {
        this.learn.reviewQueue.forEach(item => {
          this.insertSummaryImage(item, rowIndex)
          this.checkLearnItemReady(item)
        })
      }
    },
    defaultQuestionText: function () {
      let firstRowWithData, fromImage, questionTemplate
      // For convenience
      const fromFieldId = this.question.fromFieldId
      const toFieldId = this.question.toFieldId
      if (this.questionStatus === 'ok') {
        if (fromFieldId === 's') {
          questionTemplate = this.text.memoSetDefaultQuestionFromSummaryImage
        } else {
          // Determine whether the first data for the from field contains an image
          firstRowWithData = this.rows.find(row => row.data[fromFieldId] && row.data[fromFieldId].replace(/<br ?\/?>/g, ''))
          fromImage = (firstRowWithData && firstRowWithData.data[fromFieldId].indexOf('<img ') !== -1)
          if (toFieldId === 's') {
            questionTemplate = fromImage ? this.text.memoSetDefaultQuestionFromImageToSummaryImage : this.text.memoSetDefaultQuestionToSummaryImage
          } else {
            questionTemplate = fromImage ? this.text.memoSetDefaultQuestionFromImage : this.text.memoSetDefaultQuestion
          }
        }
        // Replace field name tokens
        this.question.originalQuestionText = questionTemplate.replace('<fromField>', this.fieldsById[fromFieldId].heading).replace('<toField>', this.fieldsById[toFieldId].heading)
        this.question.questionText = this.question.originalQuestionText
      }
    },
    deleteCellContents: function (rowIndex, fieldId) {
      const cell = document.getElementById(fieldId + '_' + rowIndex)
      const row = this.rows[rowIndex]
      const previousValue = row.data[fieldId] === undefined ? '' : row.data[fieldId]
      this.cellChanges.cells = [{
        fieldId: fieldId,
        rowIndex: rowIndex,
        value: previousValue
      }]
      this.cellChanges.rowQuestions = []
      this.cellChanges.rowsAdded = 0
      cell.innerHTML = ''
      cell.blur()
      this.showDeletedToast()
    },
    deleteColumnClick: function () {
      // Hide Actions popup
      $('.actionsButton').popup('hide')
      this.$nextTick(() => {
        // Prepare Delete Column dropdown
        this.deleteColumn.deleteColumn = '-1'
        $('.deleteColumnDeleteColumn')
          .dropdown({
            onChange: value => {
              this.deleteColumn.deleteColumn = value
            },
            showOnFocus: false
          })
          .dropdown('clear')
        this.modal = 'deleteColumn'
        $('.ui.modal.deleteColumn')
          .modal({
            onApprove: this.deleteColumnSubmit,
            onHidden: this.revertPreventBackHistory,
            onHide: this.resetModal,
            onVisible: this.prepareModalHistory
          })
          .modal('show')
      })
    },
    deleteColumnEnter: function () {
      // Exit if form is incomplete
      if (this.deleteColumn.deleteColumn === '-1') return
      // Hide modal
      $('.ui.modal.deleteColumn').modal('hide')
      // Submit form
      this.deleteColumnSubmit()
    },
    deleteColumnSubmit: function () {
      this.deleteFieldOrQuestion(this.fields[this.deleteColumn.deleteColumn].fieldId, '')
      this.initSearch()
    },
    deleteFieldOrQuestion: function (fieldId, questionId) {
      let fieldIndex, lastQuestion, memoSetUpdateObj, questionIds, questionIndex, rowsToUpdate, rowUpdateObjs, selectedQuestionIndex
      const batch = db.batch()
      // Save count of selected questions
      const initialSelectedQuestionsCount = this.learn.questionIds.length
      // Initialize update objects
      memoSetUpdateObj = {}
      rowUpdateObjs = {}
      if (fieldId) {
        // Delete field from fields array
        fieldIndex = this.fields.findIndex(field => field.fieldId === fieldId)
        this.fields.splice(fieldIndex, 1)
        memoSetUpdateObj.fields = this.fields
        // Delete field from row data
        rowsToUpdate = this.rows.filter(row => row.data[fieldId])
        rowsToUpdate.forEach(row => {
          this.$delete(row.data, fieldId)
          if (!rowUpdateObjs[row.docId]) rowUpdateObjs[row.docId] = {}
          rowUpdateObjs[row.docId][row.rowId + '.' + fieldId] = firebase.firestore.FieldValue.delete()
        })
        // Find any questions involving the field
        questionIds = this.questions.filter(question => question.fromFieldId === fieldId || question.toFieldId === fieldId).map(question => question.questionId)
      } else {
        questionIds = [questionId]
      }
      // If there are questions to delete
      if (questionIds.length) {
        questionIds.forEach(questionId => {
          // Delete question from questions array
          questionIndex = this.questions.findIndex(question => question.questionId === questionId)
          this.questions.splice(questionIndex, 1)
          // Delete question from row data
          rowsToUpdate = this.rows.filter(row => row.data.questions && row.data.questions[questionId])
          rowsToUpdate.forEach(row => {
            if (!rowUpdateObjs[row.docId]) rowUpdateObjs[row.docId] = {}
            lastQuestion = Object.keys(row.data.questions).length === 1
            if (lastQuestion) {
              // Delete the entire questions object
              this.$delete(row.data, 'questions')
              rowUpdateObjs[row.docId][row.rowId + '.questions'] = firebase.firestore.FieldValue.delete()
            } else {
              // Delete the question
              this.$delete(row.data.questions, questionId)
              rowUpdateObjs[row.docId][row.rowId + '.questions'] = row.data.questions
            }
          })
          // Delete question from selected questions
          selectedQuestionIndex = this.learn.questionIds.indexOf(questionId)
          if (selectedQuestionIndex !== -1) {
            this.learn.questionIds.splice(selectedQuestionIndex, 1)
          }
        })
        memoSetUpdateObj.questions = this.questions
      }
      // Update memo set document
      batch.update(db.doc('memoSets/' + this.memoSetId), memoSetUpdateObj)
      // Update row documents
      Object.keys(rowUpdateObjs).forEach(docId => {
        batch.update(db.doc('memoSets/' + this.memoSetId + '/rows/' + docId), rowUpdateObjs[docId])
      })
      // Update selected questions, if changed
      if (this.learn.questionIds.length !== initialSelectedQuestionsCount) {
        this.writeSelectedQuestions(batch)
      }
      // Update known data
      this.updateKnownData(batch, this.memoSetId)
      batch.commit()
        .catch(error => { logError('MemoSet_deleteFieldOrQuestion', error) })
    },
    deleteGameResult: function (gameResult, gameResultIndex) {
      $('.ui.modal.deleteGameResult')
        .modal({
          allowMultiple: true,
          onApprove: () => {
            this.deleteGameResultSubmit(gameResult, gameResultIndex)
          }
        })
        .modal('show')
    },
    deleteGameResultSubmit: function (gameResult, gameResultIndex) {
      let concatGroups, concatQuestions
      concatQuestions = this.prepareConcatQuestions()
      concatGroups = this.prepareConcatGroups()
      // Remove game result from local data - note that this affects learn.gameResults, because that is a reference to gameResults
      this.gameResults[concatQuestions][concatGroups].splice(gameResultIndex, 1)
      // Remove game result from Firestore
      const path = 'gameResults.' + concatQuestions + '.' + concatGroups
      const updateObj = {}
      updateObj[path] = firebase.firestore.FieldValue.arrayRemove(gameResult)
      db.doc('memoSets/' + this.memoSetId)
        .update(updateObj)
        .catch(error => { logError('MemoSet_deleteGameResultSubmit', error) })
    },
    deleteImageClick: function () {
      let obj, undoEntry
      // Blur button
      document.getElementById('deleteImageButton').blur()
      if (this.mode === 'clip') this.endClipMode()
      if (this.mode === 'draw') this.endDrawMode()
      if (this.mode === 'remove') this.endRemoveMode()
      // Prepare undo entry
      obj = Object.assign({}, this.objects[this.activeObject])
      undoEntry = {
        changeType: 'object delete',
        obj: obj,
        objIndex: this.activeObject
      }
      this.addSummaryImageUndoEntry(undoEntry)
      // Delete active object
      this.objects.splice(this.activeObject, 1)
      this.activeObject = -1
    },
    deleteMemoSetClick: function () {
      document.getElementById('deleteMemoSetButton').blur()
      this.closeToasts()
      this.modalHtml = this.text.memoSetDeleteMemoSetContent.replace('*', '<strong>' + this.title + '</strong>')
      this.modal = 'deleteMemoSet'
      $('.ui.modal.deleteMemoSet')
        .modal({
          onApprove: this.deleteMemoSetSubmit,
          onHidden: this.revertPreventBackHistory,
          onHide: this.resetModal,
          onVisible: this.prepareModalHistory
        })
        .modal('show')
    },
    deleteMemoSetSubmit: function () {
      // Handle removing shared memo set
      if (this.memoSetType === 'shared') {
        this.removeSharedMemoSet()
        return
      }
      let firestoreDocs, updateObj
      const now = new Date()
      // Get date time in format yyyymmddhhmmss
      const dttmString = now.toISOString().replace('T', '').replace('Z', '').replace(/-/g, '').replace(/:/g, '').substring(0, 14)
      const deletedMemoSetId = this.memoSetId + '_del_' + dttmString
      const batch = db.batch()
      // Write summary data to deletedMemoSets
      batch.set(db.doc('deletedMemoSets/' + deletedMemoSetId), {
        coverImage: this.coverImage,
        createdAt: this.createdAt,
        deletedAt: firebase.firestore.FieldValue.serverTimestamp(),
        description: this.description,
        fields: this.fields,
        options: this.options,
        photos: this.photos,
        questions: this.questions,
        rowCount: this.rows.length,
        sharing: this.sharing,
        slug: this.slug,
        title: this.title,
        userId: this.user.id
      })
      // Delete the memoSets document
      batch.delete(db.doc('memoSets/' + this.memoSetId))
      // Move rows data to deletedMemoSets
      firestoreDocs = {}
      this.rows.forEach(row => {
        if (!firestoreDocs[row.docId]) firestoreDocs[row.docId] = { userId: this.user.id }
        firestoreDocs[row.docId][row.rowId] = Object.assign({}, row.data)
      })
      Object.keys(firestoreDocs).forEach(docId => {
        batch.set(db.doc('deletedMemoSets/' + deletedMemoSetId + '/rows/' + docId), firestoreDocs[docId])
        batch.delete(db.doc('memoSets/' + this.memoSetId + '/rows/' + docId))
      })
      // If this is the only memo set in the users/$userId/memoSets/$docId document
      const memoSetsThisDoc = this.memoSets.filter(memoSet => memoSet.docId === this.docId)
      if (memoSetsThisDoc.length === 1 && memoSetsThisDoc[0].memoSetId === this.memoSetId) {
        // Delete the entire users/$userId/memoSets/$docId document
        batch.delete(db.doc('users/' + this.user.id + '/memoSets/' + this.docId))
      } else {
        // Delete the field for the memo set in the users/$userId/memoSets/$docId document
        updateObj = {}
        updateObj[this.memoSetId] = firebase.firestore.FieldValue.delete()
        batch.update(db.doc('users/' + this.user.id + '/memoSets/' + this.docId), updateObj)
      }
      // Delete known data for the memo set
      this.deleteKnownData(batch, this.memoSetId)
      // Delete any memo set sharing
      this.sharingArray.forEach(share => {
        if (share.userId === 'public') {
          // Remove memo set from publicMemoSets
          batch.delete(db.doc('publicMemoSets/' + this.memoSetId))
        } else {
          // Remove memo set from share user's sharedWithMe data
          batch.delete(db.doc('users/' + share.userId + '/sharedWithMe/' + this.memoSetId))
        }
      })
      batch.commit()
        .catch(error => { logError('MemoSet_deleteMemoSetSubmit', error) })
    },
    deleteKnownData: function (batch, memoSetId) {
      let updateObj = {}
      updateObj[memoSetId] = firebase.firestore.FieldValue.delete()
      batch.set(db.doc('users/' + this.user.id + '/knownData/doc'), updateObj, { merge: true })
    },
    deletePhotoClick: function (photoIndex) {
      this.modalPhotos.splice(photoIndex, 1)
      // Decrement selectedPhotoIndex if the photo deleted is above the selected photo
      if (photoIndex < this.selectedPhotoIndex) this.selectedPhotoIndex--
    },
    deleteQuestionClick: function () {
      this.question.pendingDelete = true
      // Scroll to message
      this.$nextTick(() => {
        let questionContent = document.querySelector('.questionContent')
        questionContent.scroll({
          behavior: 'smooth',
          top: questionContent.scrollHeight
        })
      })
    },
    deleteRowsChangeFromRow: function () {
      // If deleteToRow is less than deleteFromRow, set it to the deleteFromRow value
      if (parseInt(this.deleteRows.deleteToRow, 10) < parseInt(this.deleteRows.deleteFromRow, 10)) {
        this.deleteRows.deleteToRow = this.deleteRows.deleteFromRow
      }
    },
    deleteRowsClick: function () {
      // Hide Actions popup
      $('.actionsButton').popup('hide')
      this.$nextTick(() => {
        this.deleteRows.deleteFromRow = ''
        this.deleteRows.deleteToRow = ''
        this.modal = 'deleteRows'
        $('.ui.modal.deleteRows')
          .modal({
            onApprove: this.deleteRowsSubmit,
            onHidden: this.revertPreventBackHistory,
            onHide: this.resetModal,
            onVisible: this.prepareModalHistory
          })
          .modal('show')
      })
    },
    deleteRowsEnter: function () {
      // Exit if form is incomplete or invalid
      if (this.deleteRowsValid) {
        // Hide modal
        $('.ui.modal.deleteRows').modal('hide')
        // Submit form
        this.deleteRowsSubmit()
      }
    },
    deleteRowsSubmit: function () {
      let deleteDocIds, deletedRowsCount, deleteFromRow, deleteToRow, docId, docIds, firestoreDeleteDocIds, firestoreUpdateDocs, rowId
      deleteFromRow = parseInt(this.deleteRows.deleteFromRow, 10) - 1
      deleteToRow = parseInt(this.deleteRows.deleteToRow, 10) - 1
      deletedRowsCount = deleteToRow - deleteFromRow + 1
      firestoreDeleteDocIds = []
      firestoreUpdateDocs = {}
      // Add rows being deleted to firestoreUpdateDocs
      for (let i = deleteFromRow; i <= deleteToRow; i++) {
        docId = this.rows[i].docId
        rowId = this.rows[i].rowId
        if (!firestoreUpdateDocs[docId]) firestoreUpdateDocs[docId] = {}
        firestoreUpdateDocs[docId][rowId] = firebase.firestore.FieldValue.delete()
      }
      // Save doc IDs with rows being deleted
      deleteDocIds = Object.keys(firestoreUpdateDocs)
      // Update seq values for subsequent rows
      for (let i = deleteToRow + 1; i < this.rows.length; i++) {
        docId = this.rows[i].docId
        rowId = this.rows[i].rowId
        this.rows[i].data.seq = i - deletedRowsCount
        if (!firestoreUpdateDocs[docId]) firestoreUpdateDocs[docId] = {}
        firestoreUpdateDocs[docId][rowId + '.seq'] = i - deletedRowsCount
      }
      this.rows.splice(deleteFromRow, deletedRowsCount)
      // Update thumbnails
      this.thumbnails.splice(deleteFromRow, deletedRowsCount)
      // Check/update thumbnails and Mathjax
      this.prepareVisibleRows()
      // If we're beyond the end of the remaining rows, go to the last page
      if (this.firstVisibleRow > this.rows.length) {
        this.lastRowsClick()
      }
      // If there are no rows left in a document, we want to delete the entire document
      deleteDocIds.forEach(docId => {
        if (!this.rows.filter(row => row.docId === docId).length) {
          firestoreDeleteDocIds.push(docId)
        }
      })
      // No need to update any documents being deleted
      firestoreDeleteDocIds.forEach(docId => {
        delete firestoreUpdateDocs[docId]
      })
      // Update Firestore
      const batch = db.batch()
      firestoreDeleteDocIds.forEach(docId => {
        batch.delete(db.doc('memoSets/' + this.memoSetId + '/rows/' + docId))
      })
      docIds = Object.keys(firestoreUpdateDocs)
      docIds.forEach(docId => {
        batch.update(db.doc('memoSets/' + this.memoSetId + '/rows/' + docId), firestoreUpdateDocs[docId])
      })
      this.writeRowCount(batch)
      this.updateKnownData(batch, this.memoSetId)
      batch.commit()
        .catch(error => { logError('MemoSet_deleteRowsSubmit', error) })
      this.initSearch()
    },
    deleteTestResult: function (testResult, testResultIndex) {
      $('.ui.modal.deleteTestResult')
        .modal({
          allowMultiple: true,
          onApprove: () => {
            this.deleteTestResultSubmit(testResult, testResultIndex)
          }
        })
        .modal('show')
    },
    deleteTestResultSubmit: function (testResult, testResultIndex) {
      let concatGroups, concatQuestions
      concatQuestions = this.prepareConcatQuestions()
      concatGroups = this.prepareConcatGroups()
      // Remove test result from local data - note that this affects learn.testResults, because that is a reference to testResults
      this.testResults[concatQuestions][concatGroups].splice(testResultIndex, 1)
      // Remove test result from Firestore
      const path = 'testResults.' + concatQuestions + '.' + concatGroups
      const updateObj = {}
      updateObj[path] = firebase.firestore.FieldValue.arrayRemove(testResult)
      db.doc('memoSets/' + this.memoSetId)
        .update(updateObj)
        .catch(error => { logError('MemoSet_deleteTestResultSubmit', error) })
    },
    descriptionBlur: function (event) {
      this.editingDescription = false
      this.setInfoWrapperHeight()
      const sanitizedInput = this.sanitize(event.target.innerHTML)
      if (sanitizedInput !== this.description) {
        this.description = sanitizedInput
        // If description is blank, set it to the default
        if (!this.description) this.description = this.text.memoSetDefaultDescription
        const batch = db.batch()
        // Write new description to Firestore
        batch.update(db.doc('memoSets/' + this.memoSetId), {
          description: this.databaseDescription
        })
        this.updateSharedSummaries(batch, {
          description: this.databaseDescription
        })
        batch.commit()
          .catch(error => { logError('MemoSet_descriptionBlur', error) })
        // Set placeholder flag if appropriate
        this.descriptionPlaceholder = (this.description === this.text.memoSetDefaultDescription)
      }
      // Update description element to remove any pasted HTML formatting
      event.target.innerHTML = this.description
    },
    descriptionFocus: function (event) {
      this.editingDescription = true
      this.setInfoWrapperHeight()
      if (this.description === this.text.memoSetDefaultDescription) {
        selectElementContents(event.target)
      }
    },
    descriptionInput: function () {
      this.closeToasts()
      this.descriptionPlaceholder = false
    },
    dismissUpdatesNotification: function () {
      $('.message.updatesWrapper').transition('fade')
      db.doc('memoSets/' + this.memoSetId)
        .update({
          updatesDismissed: firebase.firestore.FieldValue.serverTimestamp()
        })
        .catch(error => { logError('MemoSet_dismissUpdatesNotification', error) })
    },
    // cursorCoords is an optional argument containing the current cursor coordinates
    drawClippingPath: function (pathComplete, cursorCoords) {
      let canvas, ctx
      canvas = document.getElementById('objectCanvas')
      ctx = canvas.getContext('2d')
      ctx.beginPath()
      ctx.lineWidth = canvas.width / this.objects[this.activeObject].width
      for (let i = 0; i < this.clippingPath.length; i++) {
        let coords = this.clippingPath[i]
        if (i === 0) {
          ctx.moveTo(coords.x, coords.y)
        } else {
          ctx.lineTo(coords.x, coords.y)
        }
      }
      // Draw line to current cursor position if cursorCoords argument is provided
      if (cursorCoords) {
        ctx.lineTo(cursorCoords.x, cursorCoords.y)
      }
      // Draw closure line if clipping path is complete
      if (pathComplete) {
        ctx.lineTo(this.clippingPath[0].x, this.clippingPath[0].y)
        ctx.setLineDash([5 * ctx.lineWidth, 3 * ctx.lineWidth])
      }
      ctx.strokeStyle = 'red'
      ctx.stroke()
      ctx.lineWidth = 1
    },
    drawMove: function (pointer) {
      let canvas, canvasCoords, ctx, drawCtx, obj, prevCanvasCoords, radiusX, radiusY, theta
      canvas = document.getElementById('objectCanvas')
      ctx = canvas.getContext('2d')
      // Draw image from master canvas
      this.drawObjectCanvas()
      obj = this.objects[this.activeObject]
      // Draw transparent hole at cursor position
      canvasCoords = this.getCanvasCoords(pointer.clientX, pointer.clientY)
      radiusX = drawTransparentRadius / this.photoTransform.scale * canvas.width / obj.width
      radiusY = drawTransparentRadius / this.photoTransform.scale * canvas.height / obj.height
      ctx.beginPath()
      ctx.ellipse(canvasCoords.x, canvasCoords.y, radiusX, radiusY, 0, 0, 2 * Math.PI)
      ctx.globalCompositeOperation = 'destination-out'
      ctx.fill()
      if (this.drawing) {
        // For smoothness, connect the new point to the previous
        prevCanvasCoords = this.getCanvasCoords(this.pointerPosX, this.pointerPosY)
        ctx.beginPath()
        ctx.moveTo(prevCanvasCoords.x, prevCanvasCoords.y)
        ctx.lineTo(canvasCoords.x, canvasCoords.y)
        // Get angle between the previous point and the new point, relative to the horizontal
        theta = Math.atan2(canvasCoords.y - prevCanvasCoords.y, canvasCoords.x - prevCanvasCoords.x)
        // Calculate line width - similar to https://math.stackexchange.com/a/432907
        ctx.lineWidth = 2 * radiusX * radiusY / Math.sqrt(Math.pow(radiusX * Math.cos(theta), 2) + Math.pow(radiusY * Math.sin(theta), 2))
        ctx.stroke()
        ctx.lineWidth = 1
        // Save mouse position
        this.pointerPosX = pointer.clientX
        this.pointerPosY = pointer.clientY
        // Copy objectCanvas to the object's master canvas
        drawCtx = this.editCanvas.getContext('2d')
        drawCtx.clearRect(0, 0, this.editCanvas.width, this.editCanvas.height)
        drawCtx.drawImage(canvas, 0, 0)
      }
      ctx.globalCompositeOperation = 'source-over'
    },
    drawObjectCanvas: function () {
      let canvas, ctx
      canvas = document.getElementById('objectCanvas')
      canvas.width = this.editCanvas.width
      canvas.height = this.editCanvas.height
      ctx = canvas.getContext('2d')
      ctx.clearRect(0, 0, canvas.width, canvas.height)
      ctx.drawImage(this.editCanvas, 0, 0)
    },
    drawTransparentClick: function () {
      let ctx, img
      // Blur button
      document.getElementById('drawTransparentButton').blur()
      if (this.mode === 'clip') this.endClipMode()
      if (this.mode === 'remove') this.endRemoveMode()
      const obj = this.objects[this.activeObject]
      if (this.mode === 'draw') {
        this.endDrawMode()
      } else {
        // Save the object's URL
        this.summaryImage.before = {
          url: obj.url
        }
        // Initialize editCanvas with the object image
        img = new Image()
        img.crossOrigin = 'Anonymous'
        img.onload = () => {
          this.editCanvas = document.createElement('canvas')
          this.editCanvas.width = img.naturalWidth
          this.editCanvas.height = img.naturalHeight
          ctx = this.editCanvas.getContext('2d')
          ctx.drawImage(img, 0, 0)
          this.drawObjectCanvas()
          this.mode = 'draw'
          this.drawing = false
          this.panLock = false
        }
        img.src = obj.url
      }
    },
    durationForReviewCycle: function (reviewCycle) {
      let duration
      if (!reviewCycle) {
        // 2 minutes
        duration = 2 * 60 * 1000
      } else {
        duration = this.reviewCycleDurations[reviewCycle] * 24 * 60 * 60 * 1000
        // If not less than one day, subtract 1 hour - so user can work at a similar time of day
        if (duration >= 24 * 60 * 60 * 1000) {
          duration -= 60 * 60 * 1000
        }
      }
      // Randomize by up to 5% either up or down, so that items don't stay in the same order
      duration = duration * (0.95 + (0.1 * Math.random()))
      duration = Math.round(duration)
      return duration
    },
    enableAudio: function (inputHtml) {
      let audioId, audioIdEnd, audioIdStart, buttonPos, cursorPos, html
      cursorPos = 0
      html = inputHtml
      buttonPos = html.indexOf('<button contenteditable="false" id="play_', cursorPos)
      while (buttonPos !== -1) {
        audioIdStart = buttonPos + '<button contenteditable="false" id="play_'.length
        audioIdEnd = html.indexOf('"', audioIdStart)
        audioId = html.substring(audioIdStart, audioIdEnd)
        html = html.substring(0, audioIdEnd + 1) + ' onmousedown="memq.playAudio(event, \'' + audioId + '\')"' + html.substring(audioIdEnd + 1)
        cursorPos = audioIdEnd
        buttonPos = html.indexOf('<button contenteditable="false" id="play_', cursorPos)
      }
      return html
    },
    endClipMode: function () {
      this.mode = 'select'
      this.removeClippingPathUndoEntries()
      // Avoid image shake when switching from one edit mode to another
      this.animateTransition = false
    },
    endDrawing: function () {
      let undoEntry, wrapperRect
      undoEntry = {
        after: {
          canvas: this.copyOfCanvas(document.getElementById('objectCanvas'))
        },
        before: {
          canvas: this.copyOfCanvas(this.summaryImage.before.canvas)
        },
        changeType: 'draw',
        objIndex: this.activeObject
      }
      if (this.summaryImage.before.url) {
        undoEntry.before.url = this.summaryImage.before.url
        this.summaryImage.before = {}
      }
      this.addSummaryImageUndoEntry(undoEntry)
      this.drawing = false
      const summaryImageWrapper = document.getElementsByClassName('summaryImageWrapper')[0]
      if (summaryImageWrapper) {
        wrapperRect = summaryImageWrapper.getBoundingClientRect()
        // If drawing ended near edge of visible photo
        if (
          this.pointerPosX - wrapperRect.left < 80 ||
          wrapperRect.right - this.pointerPosX < 80 ||
          this.pointerPosY - wrapperRect.top < 80 ||
          wrapperRect.bottom - this.pointerPosY < 80
        ) {
          // Pan so that the last draw position is centered
          this.showRect.left -= ((wrapperRect.width / 2) - (this.pointerPosX - wrapperRect.left)) / this.photoTransform.scale
          this.showRect.top -= ((wrapperRect.height / 2) - (this.pointerPosY - wrapperRect.top)) / this.photoTransform.scale
          this.animateTransition = true
        }
      }
    },
    endDrawMode: function () {
      const obj = this.objects[this.activeObject]
      obj.url = this.editCanvas.toDataURL()
      this.mode = 'select'
      // Avoid image shake when switching from one edit mode to another
      this.animateTransition = false
    },
    endMovingObject: function () {
      let tempObj, undoEntry
      const obj = this.objects[this.activeObject]
      undoEntry = {
        after: {
          left: obj.left,
          sequence: this.activeObject,
          top: obj.top
        },
        before: {
          left: this.summaryImage.before.left,
          sequence: this.summaryImage.before.sequence,
          top: this.summaryImage.before.top
        },
        changeType: 'object move'
      }
      // If the object is in the same position and hasn't moved in front of overlapping objects, set it back to its original index
      if (undoEntry.after.left === undoEntry.before.left && undoEntry.after.top === undoEntry.before.top && !this.movedInFront()) {
        tempObj = this.objects.pop()
        this.objects.splice(this.summaryImage.before.sequence, 0, tempObj)
        this.activeObject = this.summaryImage.before.sequence
      } else {
        this.addSummaryImageUndoEntry(undoEntry)
      }
      this.movingObject = false
      // Check whether object overlaps other objects
      this.setActiveObjectOverlap()
    },
    endMovingRectangle: function () {
      let undoEntry
      undoEntry = {
        after: {
          left: this.locationRect.left,
          top: this.locationRect.top
        },
        before: {
          left: this.summaryImage.before.left,
          top: this.summaryImage.before.top
        },
        changeType: 'rectangle move'
      }
      // If the rectangle has moved
      if (undoEntry.after.left !== undoEntry.before.left || undoEntry.after.top !== undoEntry.before.top) {
        this.addSummaryImageUndoEntry(undoEntry)
      }
      this.movingRectangle = false
    },
    endRemoveMode: function () {
      const obj = this.objects[this.activeObject]
      obj.url = this.editCanvas.toDataURL()
      this.mode = 'select'
      // Avoid image shake when switching from one edit mode to another
      this.animateTransition = false
    },
    endResizingObject: function () {
      let undoEntry
      const obj = this.objects[this.activeObject]
      undoEntry = {
        after: {
          height: obj.height,
          left: obj.left,
          top: obj.top,
          width: obj.width
        },
        before: {
          height: this.summaryImage.before.height,
          left: this.summaryImage.before.left,
          top: this.summaryImage.before.top,
          width: this.summaryImage.before.width
        },
        changeType: 'object resize',
        objIndex: this.activeObject
      }
      // If the object has been resized
      if (
        undoEntry.after.height !== undoEntry.before.height ||
        undoEntry.after.left !== undoEntry.before.left ||
        undoEntry.after.top !== undoEntry.before.top ||
        undoEntry.after.width !== undoEntry.before.width
      ) {
        this.addSummaryImageUndoEntry(undoEntry)
      }
      this.resizingObject = false
    },
    endResizingRectangle: function () {
      let undoEntry
      undoEntry = {
        after: {
          height: this.locationRect.height,
          left: this.locationRect.left,
          top: this.locationRect.top,
          width: this.locationRect.width
        },
        before: {
          height: this.summaryImage.before.height,
          left: this.summaryImage.before.left,
          top: this.summaryImage.before.top,
          width: this.summaryImage.before.width
        },
        changeType: 'rectangle resize'
      }
      // If the rectangle has been resized
      if (
        undoEntry.after.height !== undoEntry.before.height ||
        undoEntry.after.left !== undoEntry.before.left ||
        undoEntry.after.top !== undoEntry.before.top ||
        undoEntry.after.width !== undoEntry.before.width
      ) {
        this.addSummaryImageUndoEntry(undoEntry)
      }
      this.resizingRectangle = false
    },
    endRotatingObject: function () {
      let undoEntry
      const obj = this.objects[this.activeObject]
      undoEntry = {
        after: { angle: obj.angle },
        before: { angle: this.summaryImage.before.angle },
        changeType: 'object rotate',
        objIndex: this.activeObject
      }
      // If the object has been rotated
      if (undoEntry.after.angle !== undoEntry.before.angle) {
        this.addSummaryImageUndoEntry(undoEntry)
      }
      this.rotatingObject = false
    },
    exportDataClick: function () {
      let data, textarea
      this.closeToasts()
      data = ''
      // Prepare column headings
      this.fields.forEach(field => {
        data += field.heading + '\t'
      })
      if (this.options.useReviewGroups) {
        data += this.text.memoSetGroup + '\t'
      }
      data += this.text.memoSetNotes + '\n'
      // Loop through rows
      this.rows.forEach(row => {
        // Loop through columns
        this.fields.forEach(field => {
          data += (row.data[field.fieldId] || '') + '\t'
        })
        if (this.options.useReviewGroups) {
          data += (row.data.reviewGroup || '') + '\t'
        }
        data += (row.data.notes || '') + '\n'
      })
      textarea = document.createElement('textarea')
      textarea.value = data
      document.body.appendChild(textarea)
      textarea.select()
      try {
        if (document.execCommand('copy')) {
          // Copied
          this.exported = true
        } else {
          // Copy failed
          this.exported = false
        }
      } catch (error) {
        // Copy failed
        logError('MemoSet_exportDataClick', error)
        this.exported = false
      }
      document.body.removeChild(textarea)
      this.modal = 'exportData'
      $('.ui.modal.exportData')
        .modal({
          autofocus: false,
          onApprove: this.exportDataSubmit,
          onHidden: this.revertPreventBackHistory,
          onHide: this.resetModal,
          onVisible: this.prepareModalHistory
        })
        .modal('show')
    },
    exportDataEnter: function () {
      // Hide modal
      $('.ui.modal.exportData').modal('hide')
      this.exportDataSubmit()
    },
    exportDataSubmit: function () {
    },
    firstRowsClick: function (event) {
      // Blur button
      event.target.blur()
      // Show first rows
      this.firstVisibleRow = 1
      this.goToRowVal = '1'
      this.scrollToTopLeft()
    },
    formattedCurrentDate: function () {
      let formattedDate, offsetMinutes, offsetNow
      offsetMinutes = new Date().getTimezoneOffset()
      offsetNow = Date.now() - offsetMinutes * 1000 * 60
      formattedDate = new Date(offsetNow).toISOString().substring(0, 10)
      return formattedDate
    },
    flipClick: function (event, axis) {
      let angleInRadians, flipAxis, flipField, obj, undoEntry
      if (this.mode === 'clip') this.endClipMode()
      if (this.mode === 'draw') this.endDrawMode()
      if (this.mode === 'remove') this.endRemoveMode()
      obj = this.objects[this.activeObject]
      angleInRadians = obj.angle * Math.PI / 180
      flipAxis = axis
      // If object is rotated more than 45 degrees, switch flip axis
      if (Math.abs(Math.sin(angleInRadians)) > 1 / Math.sqrt(2)) {
        flipAxis = flipAxis === 'x' ? 'y' : 'x'
      }
      flipField = 'flip' + flipAxis.toUpperCase()
      obj[flipField] = !obj[flipField]
      // Prepare undo entry
      undoEntry = {
        changeType: 'object flip',
        flipField: flipField,
        objIndex: this.activeObject
      }
      this.addSummaryImageUndoEntry(undoEntry)
      this.animateTransition = false
      event.target.blur()
    },
    gameAnswerClick: function (buttonIndex, isCorrect) {
      // Blur button
      document.getElementById('gameButton' + buttonIndex).blur()
      if (this.learn.gameButtonClicked === -1 && !this.learn.fallEnded) {
        this.learn.gameButtonClicked = buttonIndex
        if (isCorrect) {
          this.gameAnswerCorrect()
        } else {
          this.gameAnswerIncorrect()
        }
      }
    },
    gameAnswerCorrect: function () {
      this.learn.gameScore++
      this.gameExplodeQuestion()
      setTimeout(() => {
        this.answerClick('correct')
        this.$nextTick(this.gameNextQuestion)
      }, 2000)
    },
    gameAnswerIncorrect: function () {
      // Pause falling animation
      document.getElementById('gameFallWrapper').style.animationPlayState = 'paused'
      this.$nextTick(() => {
        this.learn.gameLives--
        // Shake screen - for some reason this needs to be wrapped in a timeout to take effect when the answer reaches the bottom
        setTimeout(() => {
          $('.modal.learnGame').transition('shake')
        }, 1)
        // Remove question
        this.learn.gameFade = true
        setTimeout(() => {
          this.answerClick('incorrect')
          if (this.learn.gameLives > 0) {
            this.$nextTick(this.gameNextQuestion)
          } else {
            this.learn.gaming = false
            this.learn.gameCompleted = true
            // Apply Mathjax to incorrect answers
            this.$nextTick(() => {
              if (window.MathJax) {
                window.MathJax.typeset(['.incorrectAnswersMathjax'])
              }
            })
          }
        }, 2000)
      })
    },
    gameExplodeQuestion: function () {
      // Pause falling animation
      document.getElementById('gameFallWrapper').style.animationPlayState = 'paused'
      // Start slow falling animation
      $('.gameSlowFallWrapper').addClass('slowFall')
      $('.gameQuestion').addClass('explode')
      this.learn.gameFade = true
    },
    gameFallEnd: function () {
      this.learn.fallEnded = true
      // Wait a tick, in case the user has clicked an answer at the same time
      this.$nextTick(() => {
        if (this.learn.gameButtonClicked === -1) {
          this.gameAnswerIncorrect()
        }
      })
    },
    gameNextQuestion: function () {
      this.learn.gameQuestionExists = false
      this.learn.gameButtonClicked = -1
      this.$nextTick(this.startFall)
    },
    getCanvasCoords: function (pointerPosX, pointerPosY) {
      let angleInRadians, canvas, obj, objCanvasX, objCanvasY, objCenterX, objCenterY, pointerRelativeToCenterX, pointerRelativeToCenterY, rotatedPointerRelativeToCenterX, rotatedPointerRelativeToCenterY, rotatedPointerRelativeToLeftTopX, rotatedPointerRelativeToLeftTopY
      obj = this.objects[this.activeObject]
      angleInRadians = obj.angle * Math.PI / 180
      // Find the center of the object
      canvas = document.getElementById('objectCanvas')
      objCenterX = (canvas.getBoundingClientRect().left + canvas.getBoundingClientRect().right) / 2
      objCenterY = (canvas.getBoundingClientRect().top + canvas.getBoundingClientRect().bottom) / 2
      pointerRelativeToCenterX = pointerPosX - objCenterX
      pointerRelativeToCenterY = pointerPosY - objCenterY
      // Rotate mouse position counterclockwise to adjust for object's rotation
      rotatedPointerRelativeToCenterX = pointerRelativeToCenterX * Math.cos(angleInRadians) + pointerRelativeToCenterY * Math.sin(angleInRadians)
      rotatedPointerRelativeToCenterY = pointerRelativeToCenterY * Math.cos(angleInRadians) - pointerRelativeToCenterX * Math.sin(angleInRadians)
      rotatedPointerRelativeToLeftTopX = rotatedPointerRelativeToCenterX + 0.5 * obj.width * this.photoTransform.scale
      rotatedPointerRelativeToLeftTopY = rotatedPointerRelativeToCenterY + 0.5 * obj.height * this.photoTransform.scale
      objCanvasX = rotatedPointerRelativeToLeftTopX / this.photoTransform.scale * canvas.width / obj.width
      objCanvasY = rotatedPointerRelativeToLeftTopY / this.photoTransform.scale * canvas.height / obj.height
      // Flip coordinates if object is flipped
      if (obj.flipX) objCanvasX = canvas.width - objCanvasX
      if (obj.flipY) objCanvasY = canvas.height - objCanvasY
      return { x: objCanvasX, y: objCanvasY }
    },
    getImageKeys: function () {
      function getImageKey (url) {
        if (url.substring(0, 6) !== 'https:') return ''
        const components = url.split('?')[0].split('%2F')
        const imageKey = components.slice(-1)[0]
        return imageKey
      }
      const imageUrls = []
      // Add cover image
      imageUrls.push(this.coverImage)
      // Add background photos
      this.photos.forEach(photo => {
        imageUrls.push(photo.url)
      })
      // Add images in columns
      this.rows.forEach(row => {
        // Add images in columns
        this.fields.forEach(field => {
          let cellHtml = row.data[field.fieldId]
          if (cellHtml) {
            while (true) {
              const imgPos = cellHtml.indexOf('<img src="')
              if (imgPos === -1) break
              const quotePos = cellHtml.indexOf('"', imgPos + 10)
              imageUrls.push(cellHtml.substring(imgPos + 10, quotePos))
              cellHtml = cellHtml.substring(quotePos)
            }
          }
        })
        // Add images in summary images
        if (row.data.objects) {
          row.data.objects.forEach(obj => {
            if (obj.url) imageUrls.push(obj.url)
          })
        }
      })
      const imageKeys = imageUrls.map(url => getImageKey(url)).filter(key => key).sort()
      return imageKeys
    },
    getLearnQuestionRowIndexes: function (question, origRowIndex) {
      let fromFieldId, questionId
      fromFieldId = question.fromFieldId
      questionId = question.questionId
      // If from column is summary image
      if (fromFieldId === 's') {
        // Return the original row index only
        return [origRowIndex]
      } else {
        // Get array of row indexes that are active for this question
        const activeRowIndexes = this.activeQuestions.filter(q => q.questionId === questionId)
          .map(q => q.rowIndex)
        // Get array of row indexes that have the same from field value and are in the active list
        return this.rows.map((row, rowIndex) => {
          return {
            fromFieldValue: row.data[fromFieldId],
            rowIndex: rowIndex
          }
        })
          .filter(row => row.fromFieldValue === this.rows[origRowIndex].data[fromFieldId] && activeRowIndexes.includes(row.rowIndex))
          .map(row => row.rowIndex)
          .sort()
      }
    },
    getPhotoById: function (photoId) {
      const filteredPhotos = this.photos.filter(photo => photo.id === photoId)
      if (filteredPhotos.length === 1) {
        return filteredPhotos[0]
      } else {
        return ''
      }
    },
    goToNewCopy: function () {
      this.$router.push('/memo-sets/' + this.copyMemoSet.newMemoSetId + '/' + this.copyMemoSet.slug)
    },
    goToRow: function () {
      this.closeToasts()
      if (parseInt(this.goToRowVal, 10) >= 1 && parseInt(this.goToRowVal, 10) <= this.rows.length) {
        this.firstVisibleRow = parseInt(this.goToRowVal, 10)
        this.goToRowVal = this.firstVisibleRow.toString()
        // Check/update thumbnails and Mathjax
        this.prepareVisibleRows()
        this.scrollToTopLeft()
      } else {
        this.modal = 'invalidRowNumber'
        $('.ui.modal.invalidRowNumber')
          .modal({
            onHidden: () => {
              const goToRowInput = document.getElementById('goToRow')
              goToRowInput.focus()
              moveCursorToEndOfInput(goToRowInput)
              this.revertPreventBackHistory()
            },
            onHide: this.resetModal,
            onVisible: this.prepareModalHistory
          })
          .modal('show')
        this.goToRowVal = this.firstVisibleRow.toString()
      }
    },
    goToRowEnter: function (event) {
      event.target.blur()
    },
    handlePosition: function (obj, handle) {
      let angleInRadians, handlePositionX, handlePositionY
      angleInRadians = obj.angle * Math.PI / 180
      if (handle === 'left top') {
        handlePositionX = -obj.width / 2 * Math.cos(angleInRadians) + obj.height / 2 * Math.sin(angleInRadians) + obj.left + obj.width / 2
        handlePositionY = -obj.width / 2 * Math.sin(angleInRadians) - obj.height / 2 * Math.cos(angleInRadians) + obj.top + obj.height / 2
      }
      if (handle === 'right bottom') {
        handlePositionX = obj.width / 2 * Math.cos(angleInRadians) - obj.height / 2 * Math.sin(angleInRadians) + obj.left + obj.width / 2
        handlePositionY = obj.width / 2 * Math.sin(angleInRadians) + obj.height / 2 * Math.cos(angleInRadians) + obj.top + obj.height / 2
      }
      if (handle === 'left bottom') {
        handlePositionX = -obj.width / 2 * Math.cos(angleInRadians) - obj.height / 2 * Math.sin(angleInRadians) + obj.left + obj.width / 2
        handlePositionY = -obj.width / 2 * Math.sin(angleInRadians) + obj.height / 2 * Math.cos(angleInRadians) + obj.top + obj.height / 2
      }
      if (handle === 'right top') {
        handlePositionX = obj.width / 2 * Math.cos(angleInRadians) + obj.height / 2 * Math.sin(angleInRadians) + obj.left + obj.width / 2
        handlePositionY = obj.width / 2 * Math.sin(angleInRadians) - obj.height / 2 * Math.cos(angleInRadians) + obj.top + obj.height / 2
      }
      return {
        x: handlePositionX,
        y: handlePositionY
      }
    },
    headingBlur: function (event, field, fieldIndex) {
      this.editingHeading = false
      this.setTableWrapperHeight()
      if (event.target.textContent && event.target.textContent !== field.heading) {
        field.heading = event.target.textContent
        this.fields[fieldIndex].heading = field.heading
        this.sortQuestions()
        this.writeFields()
      }
      // Update heading element to (1) remove any pasted HTML formatting (2) revert heading if changed to blank
      event.target.textContent = field.heading
      window.getSelection().removeAllRanges()
    },
    headingFocus: function (event, fieldId) {
      this.editingHeading = true
      this.setTableWrapperHeight()
      selectElementContents(event.target)
      this.lastFieldId = fieldId
    },
    headingInput: function (event) {
      this.closeToasts()
      const headingElement = event.target
      // If the heading includes br or div, Enter has probably been pressed
      if (headingElement.innerHTML.includes('<br>') || headingElement.innerHTML.includes('<div>')) {
        headingElement.blur()
      }
    },
    imageLoad: function () {
      this.addImage.loading = false
      this.summaryImageSubmit()
    },
    imagePaste: function (event) {
      // Note: the getAsFile method can be slow, and ideally it would be good to show the loading indicator and process the paste in a timeout, but it doesn't seem possible
      let items
      if (event.clipboardData) {
        items = event.clipboardData.items
        if (items) {
          for (let i = 0; i < items.length; i++) {
            if (items[i].type.substring(0, 6) === 'image/') {
              this.addImage.loading = true
              this.addImage.file = items[i].getAsFile()
              this.addImage.url = window.URL.createObjectURL(this.addImage.file)
              this.summaryImage.createdObjectUrls.push(this.addImage.url)
              break
            }
          }
        }
      }
    },
    initAddCellAudio: function () {
      this.addCellAudio.file = null
      this.addCellAudio.loading = false
      this.addCellAudio.microphoneError = false
      this.addCellAudio.recording = false
      this.addCellAudio.uploading = false
      this.addCellAudio.url = ''
      document.getElementById('cellAudioFile').value = ''
    },
    initAddCellImage: function () {
      this.addCellImage.file = null
      this.addCellImage.loading = false
      this.addCellImage.uploading = false
      this.addCellImage.url = ''
      document.getElementById('cellImageFile').value = ''
    },
    initAddImage: function () {
      this.addImage.file = null
      this.addImage.loading = false
      this.addImage.url = ''
      document.getElementById('imageFile').value = ''
    },
    initAddPhoto: function () {
      this.addPhoto.file = null
      this.addPhoto.loading = false
      this.addPhoto.uploading = false
      this.addPhoto.url = ''
      document.getElementById('photoFile').value = ''
    },
    initGameResults: function () {
      let concatGroups, concatQuestions
      concatQuestions = this.prepareConcatQuestions()
      concatGroups = this.prepareConcatGroups()
      this.learn.gameResults = []
      if (this.gameResults[concatQuestions] && this.gameResults[concatQuestions][concatGroups]) {
        this.learn.gameResults = this.gameResults[concatQuestions][concatGroups]
        // Sort latest to the top
        this.learn.gameResults.sort((a, b) => {
          if (a.timestamp < b.timestamp) return 1
          if (a.timestamp > b.timestamp) return -1
          return 0
        })
      }
    },
    initLearn: function () {
      // Wrap in nextTick to ensure checkboxes are rendered
      this.$nextTick(() => {
        // Prepare Questions checkboxes
        $('.ui.checkbox.learnQuestion').checkbox({
          onChange: this.setSelectedQuestions
        })
        // Prepare Review Group checkboxes
        $('.ui.checkbox.learnReviewGroup').checkbox({
          onChange: this.setSelectedReviewGroups
        })
        // If no questions selected, select all questions
        if (!this.learn.questionIds.length) {
          this.questions.forEach(question => {
            $('#learnQuestion_' + question.questionId).checkbox('set checked')
            this.learn.questionIds.push(question.questionId)
          })
        } else {
          // Tick selected questions
          this.learn.questionIds.forEach(questionId => {
            $('#learnQuestion_' + questionId).checkbox('set checked')
          })
        }
        // If no groups selected, select all groups
        if (this.options.useReviewGroups) {
          if (!this.learn.reviewGroupIds.length) {
            this.reviewGroupsList.forEach((reviewGroup, index) => {
              $('#learnReviewGroup_' + index).checkbox('set checked')
              this.learn.reviewGroupIds.push(index.toString())
            })
          } else {
            // Tick selected groups
            this.reviewGroupsList.forEach((reviewGroup, index) => {
              if (this.learn.reviewGroupIds.includes(index.toString())) {
                $('#learnReviewGroup_' + index.toString()).checkbox('set checked')
              } else {
                $('#learnReviewGroup_' + index.toString()).checkbox('set unchecked')
              }
            })
          }
        }
        this.prepareActiveQuestions()
        this.setStatuses('fast')
      })
    },
    initMemoSet: function () {
      // Exit if already initialized
      if (this.title) return
      if (this.memoSetType === 'regular') {
        // Find the memo set
        const matches = this.memoSets.filter(memoSet => memoSet.memoSetId === this.memoSetId)
        if (matches.length) {
          this.active = !matches[0].hide
          this.coverImage = matches[0].coverImage
          this.docId = matches[0].docId
          this.folderId = matches[0].folderId
          this.isPublic = matches[0].isPublic
          this.title = matches[0].title
          this.setPageTitle()
          // Read memo set detail
          this.readMemoSet()
        } else {
          // Memo set not found - redirect to Memo Sets page
          this.showCouldNotOpenToast()
          this.$router.push('/memo-sets')
        }
      }
      if (this.memoSetType === 'public' || this.memoSetType === 'example') {
        // Read memo set summary data
        db.doc('publicMemoSets/' + this.memoSetId)
          .get()
          .then(doc => {
            if (doc.exists) {
              this.coverImage = doc.data().coverImage
              this.description = doc.data().description
              this.ownerId = doc.data().ownerId
              this.ownerName = doc.data().ownerName
              this.ratingsArray = doc.data().ratingsArray
              this.title = doc.data().title
              this.setPageTitle()
            }
            this.readMemoSet()
          })
          .catch(error => {
            logError('MemoSet_initMemoSet_1', error)
            alert(this.text.memoSetCouldNotOpen)
            this.$router.push('/')
          })
      }
      if (this.memoSetType === 'shared') {
        // Read memo set summary data
        db.doc('users/' + this.user.id + '/sharedWithMe/' + this.memoSetId)
          .get()
          .then(doc => {
            if (doc.exists) {
              this.coverImage = doc.data().coverImage
              this.description = doc.data().description
              this.ownerId = doc.data().ownerId
              this.ownerName = doc.data().ownerName
              this.title = doc.data().title
              this.setPageTitle()
            }
            this.readMemoSet()
          })
          .catch(error => {
            logError('MemoSet_initMemoSet_2', error)
            this.showCouldNotOpenToast()
            this.$router.push('/memo-sets')
          })
      }
    },
    initSearch: function () {
      let rowHtml, searchFields, source, sourceItem
      // Create array of search fields
      searchFields = []
      this.fields.forEach(field => {
        searchFields.push(field.fieldId)
      })
      if (this.options.useReviewGroups) {
        searchFields.push('reviewGroup')
      }
      searchFields.push('notes')
      // Prepare search source data
      source = []
      if (this.rows.length) {
        this.rows.forEach((row, rowIndex) => {
          sourceItem = {
            rowNum: rowIndex + 1
          }
          rowHtml = '<table class="ui unstackable table" style="border: 0; zoom: 0.9"><tbody><tr>'
          this.fields.forEach(field => {
            const cardStyle = this.cardStyle(row.data[field.fieldId])
            if (cardStyle) {
              rowHtml += '<td class="three wide" style="height: 60px; ' +
                'background-image: ' + cardStyle.backgroundImage + '; ' +
                'background-position: ' + cardStyle.backgroundPosition + '; ' +
                'background-repeat: ' + cardStyle.backgroundRepeat + '; ' +
                'background-size: ' + cardStyle.backgroundSize +
                '"></td>'
            } else {
              rowHtml += '<td class="three wide">' + (row.data[field.fieldId] || '') + '</td>'
            }
            sourceItem[field.fieldId] = this.textOnly(row.data[field.fieldId] || '')
          })
          if (this.options.useReviewGroups) {
            if (row.data.reviewGroup) {
              rowHtml += '<td class="three wide"><div class="ui ' + this.reviewGroupColors[row.data.reviewGroup] + ' label">' + row.data.reviewGroup + '</div></td>'
            } else {
              rowHtml += '<td class="three wide"></td>'
            }
          }
          rowHtml += '<td class="three wide">' + (row.data.notes || '') + '</td>'
          sourceItem.reviewGroup = row.data.reviewGroup || ''
          sourceItem.notes = this.textOnly(row.data.notes || '')
          rowHtml += '</tr></tbody></table>'
          sourceItem.title = rowHtml
          source.push(sourceItem)
        })
      }
      $('.ui.search')
        .search({
          cache: false,
          onResultsAdd: html => {
            this.$nextTick(() => {
              if (window.MathJax) {
                window.MathJax.typeset(['.search .results'])
              }
            })
          },
          onSelect: (result, response) => {
            // Move to the selected item
            this.firstVisibleRow = result.rowNum
            this.prepareVisibleRows()
            // Set Go To Row value
            this.goToRowVal = this.firstVisibleRow.toString()
            this.scrollToTopLeft()
            // Clear search field and hide search results
            $('.ui.search')
              .search('set value', '')
              .search('hide results')
            this.$nextTick(() => {
              // Blur search field
              document.getElementById('search').blur()
              // Highlight the selected row
              $('#tableWrapper tbody tr').eq(1).transition({
                animation: 'glow',
                duration: '3s'
              })
            })
            return false
          },
          showNoResults: false,
          source: source,
          searchFields: searchFields
        })
    },
    initSetCoverImage: function () {
      this.setCoverImage.file = null
      this.setCoverImage.loading = false
      this.setCoverImage.uploading = false
      this.setCoverImage.url = ''
      document.getElementById('coverImageFile').value = ''
    },
    initTestResults: function () {
      let concatGroups, concatQuestions
      concatQuestions = this.prepareConcatQuestions()
      concatGroups = this.prepareConcatGroups()
      this.learn.testResults = []
      if (this.testResults[concatQuestions] && this.testResults[concatQuestions][concatGroups]) {
        this.learn.testResults = this.testResults[concatQuestions][concatGroups]
        // Sort latest to the top
        this.learn.testResults.sort((a, b) => {
          if (a.timestamp < b.timestamp) return 1
          if (a.timestamp > b.timestamp) return -1
          return 0
        })
      }
    },
    initUpdates: function () {
      // Sort updates by date
      this.updates.sort((a, b) => {
        // Sort rows with no date to the bottom
        if (a.date && !b.date) return -1
        if (!a.date && b.date) return 1
        // Sort earlier dates to the top
        if (a.date && b.date) {
          if (a.date < b.date) return -1
          if (a.date > b.date) return 1
          return 0
        }
        return 0
      })
      // If the user owns the memo set, add dummy row
      if (this.selfOwned) {
        this.adjustUpdatesDummyRow()
      }
    },
    insertSummaryImage: function (item, rowIndex) {
      if (item.questionHtml && item.questionHtml.includes('[SummaryImage' + rowIndex + ']')) {
        item.questionHtml = item.questionHtml.replace('[SummaryImage' + rowIndex + ']', '<img class="learnSummaryImage" src="' + this.thumbnails[rowIndex] + '">')
      }
      if (item.answerHtml && item.answerHtml.includes('[SummaryImage' + rowIndex + ']')) {
        item.answerHtml = item.answerHtml.replace('[SummaryImage' + rowIndex + ']', '<img class="learnSummaryImage" src="' + this.thumbnails[rowIndex] + '">')
      }
      if (item.alternativeAnswerHtmls) {
        for (let i = 0; i < item.alternativeAnswerHtmls.length; i++) {
          if (item.alternativeAnswerHtmls[i].includes('[SummaryImage' + rowIndex + ']')) {
            item.alternativeAnswerHtmls[i] = item.alternativeAnswerHtmls[i].replace('[SummaryImage' + rowIndex + ']', '<img class="learnSummaryImage" src="' + this.thumbnails[rowIndex] + '">')
          }
        }
      }
    },
    isParentLocation: function (parentLocation, location) {
      // A location is a parent location if it includes the center of the child location and has a significantly larger area
      return (
        parentLocation.left <= location.left + (location.width / 2) &&
        parentLocation.left + parentLocation.width >= location.left + (location.width / 2) &&
        parentLocation.top <= location.top + (location.height / 2) &&
        parentLocation.top + parentLocation.height >= location.top + (location.height / 2) &&
        parentLocation.width * parentLocation.height > 2 * location.width * location.height
      )
    },
    lastRowsClick: function (event) {
      // Blur button
      if (event) event.target.blur()
      // Show the last page
      if (this.rows.length) {
        this.firstVisibleRow = (Math.floor((this.rows.length - 1) / this.visibleRowsCount) * this.visibleRowsCount) + 1
      } else {
        this.firstVisibleRow = 1
      }
      this.goToRowVal = this.firstVisibleRow.toString()
      // Check/update thumbnails and Mathjax
      this.prepareVisibleRows()
      this.scrollToTopLeft()
    },
    learnClick: function () {
      let question
      const now = Date.now()
      this.saveLearnOptions()
      this.modal = 'learn'
      this.learn.congratsMessage = ''
      // Reset test statuses to ensure no test-related code runs
      this.learn.testing = false
      this.learn.testCompleted = false
      // Create review queue based on activeQuestions without duplicates
      this.learn.reviewQueue = this.activeQuestions
        .filter(activeQuestion => !activeQuestion.duplicate)
        .map(activeQuestion => {
          question = this.questions.find(q => q.questionId === activeQuestion.questionId)
          return {
            questionId: activeQuestion.questionId,
            reviewAfter: activeQuestion.reviewAfter,
            reviewCycle: activeQuestion.reviewCycle,
            rowIndex: activeQuestion.rowIndex,
            rowIndexes: this.getLearnQuestionRowIndexes(question, activeQuestion.rowIndex)
          }
        })
      this.sortReviewQueue(true)
      this.learn.initialKnown = this.learn.reviewQueue.filter(item => item.reviewCycle && item.reviewAfter > now).length
      $('.learnProgress')
        .progress({
          autoSuccess: false,
          showActivity: false
        })
      this.updateLearnProgress()
      // Prepare first questions
      for (let i = 0; i < Math.min(learnPreloadCount, this.learn.reviewQueue.length); i++) {
        this.prepareQuestion(i)
      }
      this.showLearnModal()
      this.timeouts.updateLearnData = setTimeout(this.updateLearnData, learnTimeoutDuration)
    },
    learnShowCongratulations: function () {
      let textKey
      // Prepare text key based on plurality of questions and groups
      textKey = 'memoSetCongrats'
      if (this.learn.questionIds.length !== this.questions.length) {
        textKey += 'Question'
        if (this.learn.questionIds.length > 1) textKey += 's'
      }
      if (this.options.useReviewGroups && this.learn.reviewGroupIds.length !== this.reviewGroupsList.length) {
        textKey += 'Group'
        if (this.learn.reviewGroupIds.length > 1) textKey += 's'
      }
      this.learn.congratsMessage = this.text[textKey]
    },
    learnGameClick: function () {
      let answer, existingAnswer, question
      this.initGameResults()
      this.modal = 'learnGame'
      this.learn.gaming = false
      this.learn.gameCompleted = false
      // Create review queue based on activeQuestions without duplicates
      this.learn.reviewQueue = this.activeQuestions
        .filter(activeQuestion => !activeQuestion.duplicate)
        .map(activeQuestion => {
          question = this.questions.find(q => q.questionId === activeQuestion.questionId)
          return {
            questionId: activeQuestion.questionId,
            reviewAfter: activeQuestion.reviewAfter,
            reviewCycle: activeQuestion.reviewCycle,
            rowIndex: activeQuestion.rowIndex,
            rowIndexes: this.getLearnQuestionRowIndexes(question, activeQuestion.rowIndex),
            toFieldId: question.toFieldId
          }
        })
      this.learn.gameAnswers = {}
      // For each item in the review queue
      this.learn.reviewQueue.forEach(item => {
        if (item.toFieldId === 's') {
          item.answer = '[SummaryImage' + item.rowIndex + ']'
        } else {
          // Concatenate all answers for the question
          answer = ''
          item.rowIndexes.forEach(rowIndex => {
            answer += '<' + this.rows[rowIndex].data[item.toFieldId] + '>|'
          })
          item.answer = answer
        }
        // Add answer to learn.gameAnswers
        if (!this.learn.gameAnswers[item.questionId]) {
          this.learn.gameAnswers[item.questionId] = []
        }
        // Check whether the answer is already in learn.gameAnswers
        existingAnswer = this.learn.gameAnswers[item.questionId].find(gameAnswer => gameAnswer.answer === item.answer)
        if (existingAnswer) {
          existingAnswer.frequency++
        } else {
          this.learn.gameAnswers[item.questionId].push({
            answer: item.answer,
            frequency: 1,
            rowIndex: item.rowIndex,
            rowIndexes: item.rowIndexes
          })
        }
      })
      this.showLearnGameModal()
    },
    learnTestClick: function () {
      let question
      this.initTestResults()
      this.modal = 'learnTest'
      this.learn.testCompleted = false
      this.learn.testing = false
      this.learn.testProgress = 0
      this.learn.testTimestamp = 0
      this.learn.incorrectAnswers = []
      this.$nextTick(() => {
        $('.ui.checkbox.randomOrder').checkbox()
      })
      // Create review queue based on activeQuestions without duplicates
      this.learn.reviewQueue = this.activeQuestions
        .filter(activeQuestion => !activeQuestion.duplicate)
        .map(activeQuestion => {
          question = this.questions.find(q => q.questionId === activeQuestion.questionId)
          return {
            questionId: activeQuestion.questionId,
            reviewAfter: activeQuestion.reviewAfter,
            reviewCycle: activeQuestion.reviewCycle,
            rowIndex: activeQuestion.rowIndex,
            rowIndexes: this.getLearnQuestionRowIndexes(question, activeQuestion.rowIndex)
          }
        })
      // If there are previous test results, default the number of test items to the last value used, unless it's more than the total
      if (this.learn.testResults.length && this.learn.testResults[0].total <= this.learn.reviewQueue.length) {
        this.learn.testItems = this.learn.testResults[0].total
      } else {
        this.learn.testItems = this.learn.reviewQueue.length
      }
      this.showLearnTestModal()
      this.timeouts.updateLearnData = setTimeout(this.updateLearnData, learnTimeoutDuration)
    },
    locationRectWithMargin: function (margin) {
      // Based on photo pixels: this.locationRect, this.photo, rectWithMargin
      // margin is the margin around location (relative to location width/height)
      let rectWithMargin
      rectWithMargin = {
        height: this.locationRect.height * (1 + margin * 2),
        left: this.locationRect.left - this.locationRect.width * margin,
        top: this.locationRect.top - this.locationRect.height * margin,
        width: this.locationRect.width * (1 + margin * 2)
      }
      // Adjust rectWithMargin if it is beyond the left side of the photo
      if (rectWithMargin.left < 0) {
        // Reduce width
        rectWithMargin.width += rectWithMargin.left
        rectWithMargin.left = 0
      }
      // Adjust rectWithMargin if it is beyond the top of the photo
      if (rectWithMargin.top < 0) {
        // Reduce height
        rectWithMargin.height += rectWithMargin.top
        rectWithMargin.top = 0
      }
      // Adjust rectWithMargin if it is beyond the right side of the photo
      if (rectWithMargin.left + rectWithMargin.width > this.photo.width) {
        rectWithMargin.width = this.photo.width - rectWithMargin.left
      }
      // Adjust rectWithMargin if it is beyond the bottom of the photo
      if (rectWithMargin.top + rectWithMargin.height > this.photo.height) {
        rectWithMargin.height = this.photo.height - rectWithMargin.top
      }
      return rectWithMargin
    },
    memoSetLoaded: function () {
      // Initialize checkboxes
      $('.ui.checkbox').checkbox()
      // Set statuses
      this.setStatuses('instant')
      this.ready = true
      // Initialize Learn tab
      if (this.activeTab === 'learn') {
        this.initLearn()
      }
      this.previousImageKeys = this.getImageKeys()
      if (this.initialLoad) {
        this.initialLoad = false
        // Switch to Data tab if viewing an example memo set
        if (this.memoSetType === 'example') {
          $('.menu.mainTabs .item').tab('change tab', 'data')
        }
        // Switch to Learn tab if previously on the Home page
        if (this.memoSetType === 'regular' && window.memq && window.memq.memoSetSource === 'Home') {
          $('.menu.mainTabs .item').tab('change tab', 'learn')
        }
        if (this.memoSetType === 'regular' && !this.activeTab) {
          // If the title is the default title, focus on it
          const defaultTitleLength = this.text.memoSetsDefaultTitle.length
          if (this.title.substring(0, defaultTitleLength) === this.text.memoSetsDefaultTitle &&
              (this.title.length === defaultTitleLength || this.title.substring(defaultTitleLength + 1, defaultTitleLength + 2) === '(')
          ) {
            setTimeout(() => {
              selectElementContents(document.getElementById('title'))
            }, 100)
          }
        }
      }
    },
    moveColumnClick: function () {
      // Hide Actions popup
      $('.actionsButton').popup('hide')
      this.moveColumn.columnSeq = '-1'
      this.moveColumn.position = '-1'
      // Prepare Column dropdown
      $('.moveColumnColumnSeq')
        .dropdown({
          onChange: value => {
            this.moveColumn.columnSeq = value
          },
          showOnFocus: false
        })
        .dropdown('clear')
      // Prepare Move After dropdown
      $('.moveColumnPosition')
        .dropdown({
          onChange: value => {
            this.moveColumn.position = value
          },
          showOnFocus: false
        })
        .dropdown('clear')
      this.modal = 'moveColumn'
      // Show modal
      $('.ui.modal.moveColumn')
        .modal({
          onApprove: this.moveColumnSubmit,
          onHidden: this.revertPreventBackHistory,
          onHide: this.resetModal,
          onVisible: this.prepareModalHistory
        })
        .modal('show')
    },
    moveColumnEnter: function () {
      // Exit if form is incomplete
      if (this.moveColumn.columnSeq === '-1' || this.moveColumn.position === '-1') return
      // Hide modal
      $('.ui.modal.moveColumn').modal('hide')
      // Submit form
      this.moveColumnSubmit()
    },
    moveColumnSubmit: function () {
      let columnSeq, toPosition
      columnSeq = parseInt(this.moveColumn.columnSeq, 10)
      toPosition = parseInt(this.moveColumn.position, 10) - 1
      this.fields.splice(toPosition, 0, this.fields.splice(columnSeq, 1)[0])
      this.writeFields()
      this.initSearch()
    },
    movedInFront: function () {
      let objElement, objRect1, objRect2
      // Get the bounding rectangle for the active object
      objRect1 = document.getElementById('summaryImageObj_' + this.activeObject).getBoundingClientRect()
      // Check whether there are any overlapping objects with index >= this.summaryImage.sequence
      for (var objIndex = this.summaryImage.before.sequence; objIndex < this.objects.length - 1; objIndex++) {
        objElement = document.getElementById('summaryImageObj_' + objIndex)
        objRect2 = objElement.getBoundingClientRect()
        if (!(
          objRect2.right < objRect1.left ||
          objRect2.left > objRect1.right ||
          objRect2.bottom < objRect1.top ||
          objRect2.top > objRect1.bottom
        )) {
          return true
        }
      }
      return false
    },
    moveObject: function (event) {
      // moveX, moveY, this.pointerPosX, this.pointerPosY are relative to the screen
      let moveX, moveY
      // Move object by the amount the pointer has moved
      moveX = event.clientX - this.pointerPosX
      moveY = event.clientY - this.pointerPosY
      this.objects[this.activeObject].left += moveX / this.photoTransform.scale
      this.objects[this.activeObject].top += moveY / this.photoTransform.scale
      // Set pointer position ready for next move
      this.pointerPosX = event.clientX
      this.pointerPosY = event.clientY
    },
    moveRectangle: function (pointer) {
      // adjustX, adjustY are relative to the photo
      // moveX, moveY, this.pointerPosX, this.pointerPosY are relative to the screen
      let adjustX, adjustY, moveX, moveY
      adjustX = 0
      adjustY = 0
      // Move location rectangle by the amount the pointer has moved
      moveX = pointer.clientX - this.pointerPosX
      moveY = pointer.clientY - this.pointerPosY
      this.locationRect.left += moveX / this.photoTransform.scale
      this.locationRect.top += moveY / this.photoTransform.scale
      // Prevent rectangle from going too far up
      if (this.locationRect.top < 0) {
        adjustY = -this.locationRect.top
        this.locationRect.top = 0
      }
      // Prevent rectangle from going too far left
      if (this.locationRect.left < 0) {
        adjustX = -this.locationRect.left
        this.locationRect.left = 0
      }
      // Prevent rectangle from going too far down
      if (this.locationRect.top + this.locationRect.height > this.photo.height) {
        adjustY = this.photo.height - (this.locationRect.top + this.locationRect.height)
        this.locationRect.top = this.photo.height - this.locationRect.height
      }
      // Prevent rectangle from going too far right
      if (this.locationRect.left + this.locationRect.width > this.photo.width) {
        adjustX = this.photo.width - (this.locationRect.left + this.locationRect.width)
        this.locationRect.left = this.photo.width - this.locationRect.width
      }
      // Set pointer position ready for next move, but adjust if attempting to move beyond edge of photo
      this.pointerPosX = pointer.clientX + (adjustX * this.photoTransform.scale)
      this.pointerPosY = pointer.clientY + (adjustY * this.photoTransform.scale)
    },
    moveRowsClick: function () {
      // Hide Actions popup
      $('.actionsButton').popup('hide')
      this.$nextTick(() => {
        this.moveRows.firstRow = ''
        this.moveRows.lastRow = ''
        this.moveRows.moveAfter = ''
        this.modal = 'moveRows'
        $('.ui.modal.moveRows')
          .modal({
            onApprove: this.moveRowsSubmit,
            onHidden: this.revertPreventBackHistory,
            onHide: this.resetModal,
            onVisible: this.prepareModalHistory
          })
          .modal('show')
      })
    },
    moveRowsEnter: function () {
      if (this.moveRowsValid) {
        // Hide modal
        $('.ui.modal.moveRows').modal('hide')
        // Submit form
        this.moveRowsSubmit()
      }
    },
    moveRowsSubmit: function () {
      let firestoreUpdateDocs, firstRow, lastRow, moveAfter, rowsBeingMoved, thumbnailsBeingMoved
      firstRow = parseInt(this.moveRows.firstRow, 10) - 1
      lastRow = parseInt(this.moveRows.lastRow, 10) - 1
      moveAfter = parseInt(this.moveRows.moveAfter, 10) - 1
      rowsBeingMoved = this.rows.splice(firstRow, lastRow - firstRow + 1)
      thumbnailsBeingMoved = this.thumbnails.splice(firstRow, lastRow - firstRow + 1)
      // Ensure that we move the same number of thumbnails as rows - in case thumbnails aren't defined
      thumbnailsBeingMoved.length = rowsBeingMoved.length
      // If moving rows further down, reduce moveAfter by the number of rows being moved
      if (moveAfter > lastRow) {
        moveAfter -= rowsBeingMoved.length
      }
      // Insert rows being moved
      this.rows.splice(moveAfter + 1, 0, ...rowsBeingMoved)
      // Update thumbnails
      this.thumbnails.splice(moveAfter + 1, 0, ...thumbnailsBeingMoved)
      // Check/update thumbnails and Mathjax
      this.prepareVisibleRows()
      // Update Firestore
      firestoreUpdateDocs = {}
      this.rows.forEach((row, rowIndex) => {
        // If seq has changed
        if (row.data.seq !== rowIndex) {
          this.$set(row.data, 'seq', rowIndex)
          // Create update object for the document if necessary
          if (!firestoreUpdateDocs[row.docId]) firestoreUpdateDocs[row.docId] = {}
          firestoreUpdateDocs[row.docId][row.rowId + '.seq'] = rowIndex
        }
      })
      const batch = db.batch()
      Object.keys(firestoreUpdateDocs).forEach(docId => {
        batch.update(db.doc('memoSets/' + this.memoSetId + '/rows/' + docId), firestoreUpdateDocs[docId])
      })
      batch.commit()
        .catch(error => { logError('MemoSet_moveRowsSubmit', error) })
      this.initSearch()
    },
    newLine: function (rowIndex, fieldId) {
      const cell = document.getElementById(fieldId + '_' + rowIndex)
      // Need to use 2 <br> tags if the cursor is at the end and the field does not already end in <br>
      if (isCaretAtEnd(cell) && cell.innerHTML.slice(-4) !== '<br>' && cell.innerHTML.slice(-6) !== '<br />') {
        pasteHtmlAtCaret('<br><br>')
      } else {
        pasteHtmlAtCaret('<br>')
      }
    },
    nextRowsClick: function (event) {
      // Blur button
      event.target.blur()
      // Show next rows
      this.firstVisibleRow += this.visibleRowsCount
      this.goToRowVal = this.firstVisibleRow.toString()
      // Check/update thumbnails and Mathjax
      this.prepareVisibleRows()
      this.scrollToTopLeft()
    },
    objectCanvasMouseDown: function (event) {
      // Do nothing if panning
      if (this.panLock) return
      if (this.mode === 'clip') {
        this.clippingPointerDown(event)
      }
      if (this.mode === 'draw') {
        this.startDrawing(event)
      }
      if (this.mode === 'remove') {
        this.removePointerDown(event)
      }
      // Stop propagation to prevent panning
      event.stopPropagation()
    },
    objectCanvasMouseLeave: function () {
      let canvas, ctx
      // If drawing, redraw the canvas to avoid leaving a transparent hole
      if (this.mode === 'draw' && !this.panLock) {
        canvas = document.getElementById('objectCanvas')
        ctx = canvas.getContext('2d')
        ctx.drawImage(this.editCanvas, 0, 0)
      }
      // If clipping, redraw the canvas to avoid leaving an unfinished line
      if (this.mode === 'clip' && !this.panLock) {
        this.drawObjectCanvas()
        this.drawClippingPath(false)
      }
    },
    objectCanvasMouseMove: function (event) {
      // Do nothing if panning
      if (this.panLock) return
      if (this.mode === 'clip') {
        this.clippingPointerMove(event)
        event.stopPropagation()
      }
      if (this.mode === 'draw') {
        this.drawMove(event)
        event.stopPropagation()
      }
    },
    objectCanvasTouchMove: function (event) {
      // Do nothing if panning
      if (this.panLock) return
      if (event.touches.length === 1) {
        if (this.mode === 'clip' && this.clipping) {
          this.clippingPointerMove(event.touches[0])
          event.stopPropagation()
        }
        if (this.mode === 'draw') {
          this.drawMove(event.touches[0])
          event.stopPropagation()
        }
        if (this.mode === 'remove' && this.removing) {
          this.removePointerMove(event.touches[0])
          event.stopPropagation()
        }
      }
    },
    objectCanvasTouchStart: function (event) {
      // Do nothing if panning
      if (this.panLock) return
      // If this is the first touch
      if (event.touches.length === 1) {
        this.summaryImage.firstTouchRectangleActive = false
        this.summaryImage.firstTouchActiveObject = this.activeObject
        this.summaryImage.firstTouchTimestamp = event.timeStamp
        if (this.mode === 'clip') {
          this.firstTouchClippingPath = this.clippingPath.slice()
          this.clippingPointerDown(event.touches[0])
        }
        if (this.mode === 'draw') {
          this.startDrawing(event.touches[0])
        }
        if (this.mode === 'remove') {
          this.removePointerDown(event.touches[0])
        }
        // Stop propagation to prevent panning
        event.stopPropagation()
      }
    },
    objectHandleMouseDown: function (event, handle) {
      this.objectResizeStart(event, handle)
    },
    objectHandleTouchStart: function (event, handle) {
      // If this is the first touch
      if (event.touches.length === 1) {
        this.objectResizeStart(event.touches[0], handle)
        event.stopPropagation()
      }
    },
    objectMouseDown: function (objectIndex, event) {
      if (this.mode === 'select' && !this.panLock) {
        this.objectPointerDown(objectIndex, event)
        // Prevent panning
        event.stopPropagation()
      }
    },
    objectMouseEnter: function (objectIndex) {
      if (this.mode === 'select' && !this.panLock && !this.movingRectangle && !this.resizingRectangle && !this.movingObject && !this.resizingObject && !this.rotatingObject) {
        this.hoverObject = objectIndex
      }
    },
    objectMouseLeave: function (objectIndex) {
      this.hoverObject = -1
    },
    objectPointerDown: function (objectIndex, pointer) {
      let thisObj
      // Save before state for undo array
      thisObj = this.objects[objectIndex]
      this.summaryImage.before = {
        left: thisObj.left,
        sequence: objectIndex,
        top: thisObj.top
      }
      // Move object to the top
      thisObj = this.objects.splice(objectIndex, 1)[0]
      this.objects.push(thisObj)
      this.activeObject = this.objects.length - 1
      // Reset hoverObject
      this.hoverObject = -1
      this.rectangleActive = false
      this.pointerPosX = pointer.clientX
      this.pointerPosY = pointer.clientY
      this.movingObject = true
      this.animateTransition = false
      // Check whether object overlaps other objects
      this.setActiveObjectOverlap()
    },
    objectRectWithMargin: function (objIndex) {
      // Based on photo pixels: objRect, this.photo, rectWithMargin
      // margin is the margin around location (relative to location width/height)
      let angleInRadians, centerX, centerY, margin, obj, rectWithMargin, rotatedLeft, rotatedHeight, rotatedTop, rotatedWidth
      margin = 60 / Math.min(this.photoTransform.visibleHeight, this.photoTransform.visibleWidth)
      if (objIndex === -1) {
        obj = this.locationRect
        angleInRadians = 0
      } else {
        obj = this.objects[objIndex]
        angleInRadians = obj.angle * Math.PI / 180
      }
      // Reduce margin if object is at an angle
      // Note - no real mathematical basis for this, I just wanted something that goes to 0 at 45 degrees
      margin = margin * Math.abs(Math.cos(angleInRadians * 2))
      // Find the center of the object
      centerX = obj.left + obj.width / 2
      centerY = obj.top + obj.height / 2
      // Account for object rotation
      rotatedHeight = Math.abs(Math.cos(angleInRadians)) * obj.height + Math.abs(Math.sin(angleInRadians)) * obj.width
      rotatedWidth = Math.abs(Math.cos(angleInRadians)) * obj.width + Math.abs(Math.sin(angleInRadians)) * obj.height
      rotatedLeft = centerX - rotatedWidth / 2
      rotatedTop = centerY - rotatedHeight / 2
      rectWithMargin = {
        height: rotatedHeight * (1 + margin * 2),
        left: rotatedLeft - rotatedWidth * margin,
        top: rotatedTop - rotatedHeight * margin,
        width: rotatedWidth * (1 + margin * 2)
      }
      // Adjust rectWithMargin if it is beyond the left side of the photo
      if (rectWithMargin.left < 0) {
        // Reduce width
        rectWithMargin.width += rectWithMargin.left
        rectWithMargin.left = 0
      }
      // Adjust rectWithMargin if it is beyond the top of the photo
      if (rectWithMargin.top < 0) {
        // Reduce height
        rectWithMargin.height += rectWithMargin.top
        rectWithMargin.top = 0
      }
      // Adjust rectWithMargin if it is beyond the right side of the photo
      if (rectWithMargin.left + rectWithMargin.width > this.photo.width) {
        rectWithMargin.width = this.photo.width - rectWithMargin.left
      }
      // Adjust rectWithMargin if it is beyond the bottom of the photo
      if (rectWithMargin.top + rectWithMargin.height > this.photo.height) {
        rectWithMargin.height = this.photo.height - rectWithMargin.top
      }
      return rectWithMargin
    },
    objectResizeStart: function (pointer, handle) {
      // Save before state for undo array
      const obj = this.objects[this.activeObject]
      this.summaryImage.before = {
        height: obj.height,
        left: obj.left,
        top: obj.top,
        width: obj.width
      }
      this.pointerPosX = pointer.clientX
      this.pointerPosY = pointer.clientY
      this.resizingObject = true
      this.objectResizeHandle = handle
      this.animateTransition = false
    },
    objectRotationMouseDown: function (event) {
      this.objectRotationStart(event)
    },
    objectRotationTouchStart: function (event, handle) {
      // If this is the first touch
      if (event.touches.length === 1) {
        this.objectRotationStart(event.touches[0], handle)
        event.stopPropagation()
      }
    },
    objectRotationStart: function (event) {
      // Save before state for undo array
      const obj = this.objects[this.activeObject]
      this.summaryImage.before = {
        angle: obj.angle
      }
      this.rotatingObject = true
      this.animateTransition = false
    },
    objectsChanged: function (originalObjects, currentObjects) {
      if (originalObjects.length !== currentObjects.length) return true
      // Return true if any element in originalObjects is not the same in currentObjects
      return originalObjects.some(origObj => {
        return !currentObjects.find(currObj => {
          let samePosition, sameSequence
          samePosition = (
            currObj.angle === origObj.angle &&
            !!currObj.flipX === !!origObj.flipX &&
            !!currObj.flipY === !!origObj.flipY &&
            currObj.height === origObj.height &&
            currObj.left === origObj.left &&
            currObj.top === origObj.top &&
            currObj.url === origObj.url &&
            currObj.width === origObj.width
          )
          if (this.overlappingObjects()) {
            sameSequence = currObj.sequence === origObj.sequence
            return samePosition && sameSequence
          } else {
            // No objects overlap - ignore sequence changes
            return samePosition
          }
        })
      })
    },
    objectTouchStart: function (objectIndex, event) {
      if (event.touches.length === 1) {
        if (this.mode === 'select' && !this.panLock) {
          this.summaryImage.firstTouchActiveObject = this.activeObject
          this.summaryImage.firstTouchRectangleActive = this.rectangleActive
          this.summaryImage.firstTouchTimestamp = event.timeStamp
          this.objectPointerDown(objectIndex, event.touches[0])
          // Prevent panning
          event.stopPropagation()
        }
      }
    },
    oopsClick: function () {
      // Find the previous review item in the review queue
      const index = this.learn.reviewQueue.findIndex(item => item.questionId === this.learn.previousReviewItem.questionId && item.rowIndex === this.learn.previousReviewItem.rowIndex)
      // Move the item to the front of the queue
      const item = this.learn.reviewQueue.splice(index, 1)[0]
      this.learn.reviewQueue.unshift(item)
      // Show answer
      this.learn.answerVisible = true
      this.learn.congratsMessage = ''
      if (this.modal === 'learnTest') {
        // If test was completed, uncomplete it
        if (this.learn.testCompleted) {
          this.learn.testCompleted = false
          this.learn.testing = true
        }
        // If testing, adjust score
        if (this.learn.testing) {
          // If there's a review cycle, the item was marked correct
          if (item.reviewCycle) {
            this.learn.testScore--
          } else {
            // Item was marked incorrect - remove from incorrect answers array
            this.learn.incorrectAnswers.pop()
          }
        }
      }
      // Update reviewAfter and reviewCycle for each rowIndex involved
      const questionId = this.learn.reviewQueue[0].questionId
      this.learn.reviewQueue[0].rowIndexes.forEach(rowIndex => {
        const row = this.rows[rowIndex]
        const question = row.data.questions[questionId]
        // Revert the previous item's reviewAfter and reviewCycle
        question.reviewAfter = this.learn.previousReviewItem.reviewAfter
        question.reviewCycle = this.learn.previousReviewItem.reviewCycle
        // Update Firestore update object
        if (!this.learn.firestoreUpdate[row.docId]) this.learn.firestoreUpdate[row.docId] = {}
        this.learn.firestoreUpdate[row.docId][row.rowId + '.questions.' + questionId] = {
          reviewAfter: question.reviewAfter,
          reviewCycle: question.reviewCycle
        }
        // Update activeQuestions array
        const questionIndex = this.activeQuestions.findIndex(e => e.rowIndex === rowIndex && e.questionId === questionId)
        const questionObj = this.activeQuestions[questionIndex]
        questionObj.reviewAfter = question.reviewAfter
        questionObj.reviewCycle = question.reviewCycle
      })
      // If learning, update progress bar
      if (this.modal === 'learn') {
        this.updateLearnProgress()
      }
      // If testing, update progress bar
      if (this.modal === 'learnTest') {
        if (this.learn.testing) {
          this.learn.testProgress--
          this.updateTestProgress()
          this.learn.testCompleted = false
          this.learn.testing = true
        }
      }
    },
    openOriginalClick: function () {
      if (this.originalMemoSet.sharing.public) {
        this.$router.push('/public-memo-sets/' + this.originalMemoSetId + '/' + this.slug)
      } else {
        this.$router.push('/shared-memo-sets/' + this.originalMemoSetId + '/' + this.slug)
      }
    },
    optionsChange: function (option) {
      this.closeToasts()
      // If the background photos option has changed
      if (option === 'useBackgroundPhotos') {
        if (this.options.useBackgroundPhotos) {
          // Include photo overviews by default
          this.options.usePhotoOverviews = true
          // Use summary images - required with background photos
          this.options.useSummaryImages = true
        }
        // Clear the thumbnails
        this.thumbnails = []
      }
      // Update Firestore
      db.doc('memoSets/' + this.memoSetId)
        .update({
          options: this.options
        })
        .catch(error => { logError('MemoSet_optionsChange', error) })
      // Refresh search if review groups option changed
      if (option === 'useReviewGroups') {
        this.initSearch()
      }
    },
    overlappingObjects: function () {
      return this.objects.some(obj => {
        return this.objects.some(obj2 => {
          // Ignore the same object
          if (obj.sequence === obj2.sequence) return false
          // Return true if the objects overlap
          return !(
            obj.left + obj.width < obj2.left ||
            obj.left > obj2.left + obj2.width ||
            obj.top + obj.height < obj2.top ||
            obj.top > obj2.top + obj2.height
          )
        })
      })
    },
    panLockClick: function () {
      // Blur button
      document.getElementById('panLockButton').blur()
      this.panLock = !this.panLock
    },
    pasteHandler: function (event) {
      if (this.modal === 'addCellImage') {
        event.preventDefault()
        this.cellImagePaste(event)
      }
      if (this.modal === 'backgroundPhoto') {
        if (this.backgroundPhotoTab === 'backgroundPhotoAdd') {
          event.preventDefault()
          this.photoPaste(event)
        }
      }
      if (this.modal === 'setCoverImage') {
        if (!this.setCoverImage.url) {
          event.preventDefault()
          this.coverImagePaste(event)
        }
      }
      if (this.modal === 'summaryImage') {
        if (this.summaryImage.page === 'summary') {
          event.preventDefault()
          this.summaryImagePaste(event)
        }
        if (this.summaryImage.page === 'add image') {
          event.preventDefault()
          this.imagePaste(event)
        }
      }
    },
    photoLoad: function () {
      this.addPhoto.loading = false
      window.URL.revokeObjectURL(this.addPhoto.url)
    },
    photoPaste: function (event) {
      let i, items
      if (event.clipboardData) {
        items = event.clipboardData.items
        if (items) {
          for (i = 0; i < items.length; i++) {
            if (items[i].type.substring(0, 6) === 'image/') {
              this.addPhoto.loading = true
              this.addPhoto.file = items[i].getAsFile()
              this.addPhoto.url = window.URL.createObjectURL(this.addPhoto.file)
              break
            }
          }
        }
      }
    },
    populateColumnClick: function () {
      let fieldIndex
      // Hide Actions popup
      $('.actionsButton').popup('hide')
      this.populateColumn.submitting = false
      // Set column to the last column touched, if there was one
      fieldIndex = this.fields.findIndex(e => e.fieldId === this.lastFieldId)
      if (fieldIndex !== -1) {
        this.populateColumn.column = fieldIndex.toString()
      } else {
        // If only one column, use that
        if (this.fields.length === 1) {
          this.populateColumn.column = '0'
        }
      }
      // Prepare dropdowns
      $('.populateColumnColumn')
        .dropdown({
          onChange: value => {
            this.populateColumn.column = value
          },
          showOnFocus: false
        })
        .dropdown('set selected', this.populateColumn.column)
      $('.populateColumnDataSet').dropdown({
        onChange: value => {
          this.populateColumn.dataSet = value
          this.populateColumnInit()
        },
        showOnFocus: false
      })
      $('.populateColumnNumberOfCards').dropdown({
        onChange: value => {
          this.populateColumn.numberOfCards = value
        },
        showOnFocus: false
      })
      $('.populateColumnCardSet').dropdown({
        onChange: value => {
          this.populateColumn.cardSet = value
          this.$nextTick(() => {
            $('.populateColumnSuits').dropdown('refresh').dropdown('set selected', this.populateColumn.suits)
          })
        },
        showOnFocus: false
      })
      $('.populateColumnSuits').dropdown({
        onChange: value => {
          this.populateColumn.suits = value
        },
        showOnFocus: false
      })
      $('.populateColumnValues').dropdown({
        onChange: value => {
          this.populateColumn.values = value
        },
        showOnFocus: false
      })
      $('.populateColumnSignificantCard').dropdown({
        onChange: value => {
          this.populateColumn.significantCard = value
        },
        showOnFocus: false
      })
      $('.populateColumnIncludeRepeated').dropdown({
        onChange: value => {
          this.populateColumn.includeRepeated = value
        },
        showOnFocus: false
      })
      this.modal = 'populateColumn'
      $('.ui.modal.populateColumn')
        .modal({
          autofocus: false,
          onApprove: this.populateColumnSubmit,
          onHidden: this.revertPreventBackHistory,
          onHide: this.resetModal,
          onVisible: this.prepareModalHistory
        })
        .modal('show')
      this.$nextTick(() => {
        $('.populateColumnContent')[0].scrollTop = 0
      })
    },
    populateColumnEnter: function () {
      // Exit if form is incomplete or invalid
      if (
        this.populateColumn.column === '' ||
        this.populateColumn.dataSet === '' || (
          this.populateColumn.dataSet === 'n' && !this.populateColumnPreviewHtml
        )
      ) return
      // Hide modal
      $('.ui.modal.populateColumn').modal('hide')
      // Submit form
      this.populateColumnSubmit()
    },
    populateColumnInit: function () {
      // If numbers, reset default first and last values
      // Note that the value contenteditable divs are refreshed by v-if
      if (this.populateColumn.dataSet.substring(0, 1) === 'n') {
        this.$set(this.populateColumn.numValues, 0, this.populateColumn.originalNumValues[0])
        this.$set(this.populateColumn.numValues, 1, this.populateColumn.originalNumValues[1])
      }
    },
    populateColumnSubmit: function () {
      const self = this
      let base, cardSetPrefix, columnData, fieldId, firestoreNewDocs, firestoreUpdateDocs, firstDigits, firstFormat, formattedValue, increment, lastDigits, numberOfValues, suits, values
      this.cellChanges.cells = []
      this.cellChanges.rowQuestions = []
      this.cellChanges.rowsAdded = 0
      columnData = []
      this.populateColumn.submitting = true
      // Cards
      if (this.populateColumn.dataSet === 'c') {
        suits = this.populateColumn.suits.split('')
        switch (this.populateColumn.values) {
          case 'A-K':
            values = ['A', '2', '3', '4', '5', '6', '7', '8', '9', '10', 'J', 'Q', 'K']
            break
          case 'K-A':
            values = ['K', 'Q', 'J', '10', '9', '8', '7', '6', '5', '4', '3', '2', 'A']
            break
          case '2-A':
            values = ['2', '3', '4', '5', '6', '7', '8', '9', '10', 'J', 'Q', 'K', 'A']
            break
          case 'A-2':
            values = ['A', 'K', 'Q', 'J', '10', '9', '8', '7', '6', '5', '4', '3', '2']
            break
        }
        // Set prefix for card set
        if (this.populateColumn.cardSet === 'English') cardSetPrefix = ''
        if (this.populateColumn.cardSet === 'FourColor') cardSetPrefix = '4: '
        if (this.populateColumn.cardSet === 'German') cardSetPrefix = 'de: '
        if (this.populateColumn.numberOfCards === '1') {
          suits.forEach(function (suit) {
            values.forEach(function (value) {
              columnData.push('(' + cardSetPrefix + value + suit + ')')
            })
          })
        }
        if (this.populateColumn.numberOfCards === '2') {
          suits.forEach(function (suit1) {
            values.forEach(function (value1) {
              suits.forEach(function (suit2) {
                values.forEach(function (value2) {
                  if (value1 + suit1 !== value2 + suit2 || self.populateColumn.includeRepeated === 'Y') {
                    if (self.populateColumn.significantCard === 'L') {
                      columnData.push('(' + cardSetPrefix + value1 + suit1 + ' ' + value2 + suit2 + ')')
                    } else {
                      columnData.push('(' + cardSetPrefix + value2 + suit2 + ' ' + value1 + suit1 + ')')
                    }
                  }
                })
              })
            })
          })
        }
      }
      // Numbers
      if (this.populateColumn.dataSet === 'n') {
        // For convenience
        const first = this.populateColumn.numValues[0]
        const last = this.populateColumn.numValues[1]
        // Handle simple numbers
        if (
          first === parseInt(first, 10).toString() &&
          last === parseInt(last, 10).toString()
        ) {
          numberOfValues = Math.abs(parseInt(last, 10) - parseInt(first, 10)) + 1
          // Set increment to 1 if increasing, -1 if decreasing
          increment = parseInt(last, 10) > parseInt(first, 10) ? 1 : -1
          for (let i = 0; i < numberOfValues; i++) {
            let thisValue = (parseInt(first, 10) + (i * increment)).toString()
            columnData.push(thisValue)
          }
        } else {
          // Handle formatted numbers
          firstFormat = first.replace(/\d/g, '<digit>')
          // Extract the digits only
          firstDigits = first.replace(/\D/g, '')
          lastDigits = last.replace(/\D/g, '')
          // Set base to 2 (binary) if all digits are 0 or 1
          base = (firstDigits + lastDigits).replace(/0/g, '').replace(/1/g, '') === '' ? 2 : 10
          numberOfValues = Math.abs(parseInt(lastDigits, base) - parseInt(firstDigits, base)) + 1
          // Set increment to 1 if increasing, -1 if decreasing
          increment = parseInt(lastDigits, base) > parseInt(firstDigits, base) ? 1 : -1
          for (let i = 0; i < numberOfValues; i++) {
            let thisValue = (parseInt(firstDigits, base) + (i * increment)).toString(base)
            // Left pad with zeros
            thisValue = ('0'.repeat(firstDigits.length) + thisValue).slice(-firstDigits.length)
            // Add non-numeric formatting
            formattedValue = firstFormat
            for (let d = 0; d < firstDigits.length; d++) {
              formattedValue = formattedValue.replace('<digit>', thisValue.substring(d, d + 1))
            }
            columnData.push(formattedValue)
          }
        }
      }
      // Insert rows at the bottom if required
      if (columnData.length > this.rows.length) {
        this.addRows.numberOfRows = columnData.length - this.rows.length
        this.addRows.insertAfterRow = ''
        // Add rows without updating Firestore
        this.addRowsAddRows(false)
        this.cellChanges.rowsAdded = this.addRows.numberOfRows
        // For convenience
        firestoreNewDocs = this.addRows.firestoreNewDocs
        firestoreUpdateDocs = this.addRows.firestoreUpdateDocs
      } else {
        // No rows to add
        firestoreNewDocs = {}
        firestoreUpdateDocs = {}
      }
      // Update the column data
      fieldId = this.fields[parseInt(this.populateColumn.column, 10)].fieldId
      for (let i = 0; i < columnData.length; i++) {
        const row = this.rows[i]
        // If this is not for a row that has just been added
        if (i < this.rows.length - this.cellChanges.rowsAdded) {
          // Push the change onto cellChanges for undo
          const previousValue = row.data[fieldId] === undefined ? '' : row.data[fieldId]
          this.cellChanges.cells.push({
            fieldId: fieldId,
            rowIndex: i,
            value: previousValue
          })
        }
        this.$set(row.data, fieldId, columnData[i])
        // Update Firestore object
        if (firestoreNewDocs[row.docId]) {
          firestoreNewDocs[row.docId][row.rowId][fieldId] = columnData[i]
        } else {
          if (!firestoreUpdateDocs[row.docId]) firestoreUpdateDocs[row.docId] = {}
          firestoreUpdateDocs[row.docId][row.rowId + '.' + fieldId] = columnData[i]
        }
        // Delete any row questions with missing field values
        if (this.checkRowQuestions(i)) {
          // Apply questions data to firestoreNewDocs or firestoreUpdateDocs
          if (firestoreNewDocs[row.docId]) {
            firestoreNewDocs[row.docId][row.rowId + '.questions'] = row.data.questions
          } else {
            if (!firestoreUpdateDocs[row.docId]) firestoreUpdateDocs[row.docId] = {}
            firestoreUpdateDocs[row.docId][row.rowId + '.questions'] = row.data.questions
          }
        }
      }
      // Update Firestore
      const batch = db.batch()
      // Write new docs
      Object.keys(firestoreNewDocs).forEach(docId => {
        batch.set(db.doc('memoSets/' + this.memoSetId + '/rows/' + docId), firestoreNewDocs[docId])
      })
      // Write update docs
      Object.keys(firestoreUpdateDocs).forEach(docId => {
        batch.update(db.doc('memoSets/' + this.memoSetId + '/rows/' + docId), firestoreUpdateDocs[docId])
      })
      if (this.cellChanges.rowsAdded) {
        this.writeRowCount(batch)
      }
      this.updateKnownData(batch, this.memoSetId)
      batch.commit()
        .catch(error => { logError('MemoSet_populateColumnSubmit', error) })
      this.initSearch()
      this.showPopulateColumnToast()
    },
    populateColumnValueInput: function (event, valueIndex) {
      this.$set(this.populateColumn.numValues, valueIndex, event.target.innerHTML)
    },
    prepareActiveQuestions: function () {
      let reviewGroupIndex, selectedQuestions, selectedReviewGroupNames
      this.activeQuestions = []
      // If review groups are used, prepare array of selected review group names
      if (this.options.useReviewGroups) {
        selectedReviewGroupNames = []
        this.learn.reviewGroupIds.forEach(reviewGroupIndexString => {
          reviewGroupIndex = parseInt(reviewGroupIndexString, 10)
          selectedReviewGroupNames.push(this.reviewGroupsList[reviewGroupIndex])
        })
      }
      // For each selected question
      selectedQuestions = this.questions.filter(question => this.learn.questionIds.includes(question.questionId))
      selectedQuestions.forEach(question => {
        this.rows.forEach((row, rowIndex) => {
          // If review groups not used, or row is in selected review groups
          if (!this.options.useReviewGroups || selectedReviewGroupNames.includes(row.data.reviewGroup || '')) {
            if (this.rowHasData(row, question.fromFieldId, question.toFieldId)) {
              this.activeQuestions.push({
                questionId: question.questionId,
                reviewAfter: row.data.questions && row.data.questions[question.questionId] ? row.data.questions[question.questionId].reviewAfter : 0,
                reviewCycle: row.data.questions && row.data.questions[question.questionId] ? row.data.questions[question.questionId].reviewCycle : 0,
                rowIndex: rowIndex
              })
            }
          }
        })
      })
      // Flag duplicate questions
      this.activeQuestions.forEach(activeQuestion => {
        const question = this.questions.filter(q => q.questionId === activeQuestion.questionId)[0]
        const fromFieldId = question.fromFieldId
        // If from column is not summary image
        if (fromFieldId !== 's') {
          if (
            this.activeQuestions.some(activeQuestion2 => {
              return activeQuestion2.questionId === activeQuestion.questionId &&
                this.rows[activeQuestion2.rowIndex].data[fromFieldId] === this.rows[activeQuestion.rowIndex].data[fromFieldId] &&
                (
                  activeQuestion2.reviewAfter < activeQuestion.reviewAfter ||
                  (activeQuestion2.reviewAfter === activeQuestion.reviewAfter && activeQuestion2.rowIndex < activeQuestion.rowIndex)
                )
            })
          ) activeQuestion.duplicate = true
        }
      })
    },
    prepareConcatGroups: function () {
      if (!this.options.useReviewGroups) return '(None)'
      // Prepare concatenated string of groups
      let concatGroups = ''
      this.learn.reviewGroupIds.forEach(reviewGroupId => {
        const reviewGroupIndex = parseInt(reviewGroupId, 10)
        if (reviewGroupIndex < this.reviewGroupsList.length) {
          let reviewGroupName = this.reviewGroupsList[reviewGroupIndex]
          // Replace dots and slash with -
          reviewGroupName = reviewGroupName.replace(/\./g, '-').replace(/\//g, '-')
          if (concatGroups) concatGroups += '|'
          concatGroups += reviewGroupName
        }
      })
      if (!concatGroups) concatGroups = '(None)'
      return concatGroups
    },
    prepareConcatQuestions: function () {
      let concatQuestions, question
      // Prepare concatenated string of questions
      concatQuestions = ''
      this.learn.questionIds.forEach(questionId => {
        question = this.questions.find(question => question.questionId === questionId)
        if (concatQuestions) concatQuestions += '|'
        concatQuestions += question.fromFieldId + '>' + question.toFieldId
      })
      return concatQuestions
    },
    prepareModalHistory: function () {
      this.backCloses = 'modal'
      this.preventBack()
    },
    prepareObjects: function () {
      this.objects = []
      this.photoRowIndexes = []
      this.lastObjectKey = 0
      if (this.photo.id) {
        // Loop through memo set rows
        // Note - this loop is faster than using array filter and forEach
        this.rows.forEach((row, rowIndex) => {
          if (row.data.photoId === this.photo.id) {
            this.addObjectsForRow(row, rowIndex)
            this.photoRowIndexes.push(rowIndex)
          }
        })
      } else {
        this.addObjectsForRow(this.rows[this.rowIndex], this.rowIndex)
      }
      // Sort objects by sequence
      this.objects.sort((a, b) => {
        if (a.sequence < b.sequence) return -1
        return 1
      })
    },
    prepareQuestion: function (index) {
      let alternativeAnswer, alternativeAnswerHtml, alternativeAnswersNeeded, answerHtml, audioUrls, cumulativeFrequency, frequencyTotal, imageUrls, otherAnswers, questionHtml, randomPos
      const item = this.learn.reviewQueue[index]
      // Find the question template
      const question = this.questions.filter(q => q.questionId === item.questionId)[0]
      // Replace new lines with <br>
      questionHtml = question.questionText.replace(/\r\n/g, '<br>')
      questionHtml = questionHtml.replace(/\n/g, '<br>')
      // Replace spaces at the start of a line with &nbsp; to allow indenting
      while (true) {
        const prevQuestionHtml = questionHtml
        questionHtml = questionHtml.replace(/^((&nbsp;)*)\s/, '$1&nbsp;')
        questionHtml = questionHtml.replace(/(<br>(&nbsp;)*)\s/g, '$1&nbsp;')
        if (questionHtml === prevQuestionHtml) break
      }
      questionHtml = questionHtml.replace('*', this.prepareQuestionElement(question.fromFieldId, item.rowIndex))
      answerHtml = ''
      item.rowIndexes.forEach(rowIndex => {
        answerHtml += '<p>' + this.prepareQuestionElement(question.toFieldId, rowIndex) + '</p>'
      })
      item.questionHtml = questionHtml
      item.answerHtml = answerHtml
      if (this.modal === 'learnGame') {
        otherAnswers = this.learn.gameAnswers[item.questionId].filter(a => a.answer !== item.answer)
        item.alternativeAnswerHtmls = []
        alternativeAnswersNeeded = Math.min(3, otherAnswers.length)
        while (alternativeAnswersNeeded) {
          // Sum the frequency values in the remaining answers
          frequencyTotal = otherAnswers.reduce((a, b) => a + b.frequency, 0)
          randomPos = Math.floor(Math.random() * frequencyTotal)
          cumulativeFrequency = 0
          for (let i = 0; i < otherAnswers.length; i++) {
            cumulativeFrequency += otherAnswers[i].frequency
            if (cumulativeFrequency >= randomPos) {
              alternativeAnswer = otherAnswers[i]
              break
            }
          }
          alternativeAnswerHtml = ''
          alternativeAnswer.rowIndexes.forEach(rowIndex => {
            alternativeAnswerHtml += '<p>' + this.prepareQuestionElement(question.toFieldId, rowIndex) + '</p>'
          })
          item.alternativeAnswerHtmls.push(alternativeAnswerHtml)
          alternativeAnswersNeeded--
          // Remove answer from otherAnswers
          otherAnswers = otherAnswers.filter(a => a.answer !== alternativeAnswer.answer)
        }
      }
      // Extract any images used in the question and answer(s)
      imageUrls = extractImageUrlsFromHtml(questionHtml).concat(extractImageUrlsFromHtml(answerHtml))
      if (this.modal === 'learnGame') {
        item.alternativeAnswerHtmls.forEach(html => {
          imageUrls = imageUrls.concat(extractImageUrlsFromHtml(html))
        })
      }
      item.imagesRemaining = imageUrls.length
      if (imageUrls.length) {
        // Load the images
        imageUrls.forEach(url => {
          let img = new Image()
          img.onload = () => {
            item.imagesRemaining--
            this.checkLearnItemReady(item)
          }
          img.src = url
        })
      }
      // Extract any audio used in the question and answer
      audioUrls = extractAudioUrlsFromHtml(questionHtml).concat(extractAudioUrlsFromHtml(answerHtml))
      item.audioRemaining = audioUrls.length
      if (audioUrls.length) {
        // Load the audio
        audioUrls.forEach(url => {
          let audio = new Audio()
          audio.oncanplaythrough = () => {
            item.audioRemaining--
            this.checkLearnItemReady(item)
          }
          audio.src = url
        })
      }
      this.createThumbnailsForLearnItem(item)
      this.checkLearnItemReady(item)
    },
    prepareQuestionElement: function (fieldId, rowIndex) {
      let html
      // If the column is the summary image
      if (fieldId === 's') {
        // If the summary image thumbnail exists
        if (this.thumbnails[rowIndex]) {
          html = '<img class="learnSummaryImage" src="' + this.thumbnails[rowIndex] + '">'
        } else {
          html = '[SummaryImage' + rowIndex + ']'
        }
      } else {
        const fieldValue = this.rows[rowIndex].data[fieldId]
        const cardStyle = this.cardStyle(fieldValue)
        if (cardStyle) {
          html = this.questionCardHtml(cardStyle)
        } else {
          html = '<strong>' + fieldValue + '</strong>'
          // Add autoplay to audio tags
          html = html.replace('<audio', '<audio autoplay')
        }
      }
      return html
    },
    prepareTextUrl: function (text) {
      let canvas, canvasNew, ctx, ctxNew, imgData
      const canvasHeight = 100
      const radius = 10
      const marginX = 15
      function findRightEdge () {
        let k
        for (let x = canvas.width - 1; x > 0; x--) {
          for (let y = 0; y < canvasHeight; y++) {
            k = (y * canvas.width + x) * 4
            if (imgData.data[k] !== 255 || imgData.data[k + 1] !== 255 || imgData.data[k + 2] !== 255) {
              return x
            }
          }
        }
        return 100
      }
      canvas = document.createElement('canvas')
      canvas.width = 1500
      canvas.height = canvasHeight
      ctx = canvas.getContext('2d')
      // Fill with white background
      ctx.fillStyle = 'white'
      ctx.fillRect(0, 0, canvas.width, canvas.height)
      ctx.font = '80px sans-serif'
      ctx.fillStyle = 'black'
      ctx.fillText(text, marginX, 80)
      imgData = ctx.getImageData(0, 0, canvas.width, canvas.height)
      const widthNew = findRightEdge() + marginX
      // Draw the content to a new canvas
      canvasNew = document.createElement('canvas')
      canvasNew.width = widthNew
      canvasNew.height = canvas.height
      ctxNew = canvasNew.getContext('2d')
      // Create path for rounded corners
      ctxNew.beginPath()
      ctxNew.moveTo(radius, 0)
      ctxNew.lineTo(widthNew - radius, 0)
      ctxNew.quadraticCurveTo(widthNew, 0, widthNew, radius)
      ctxNew.lineTo(widthNew, canvasHeight - radius)
      ctxNew.quadraticCurveTo(widthNew, canvasHeight, widthNew - radius, canvasHeight)
      ctxNew.lineTo(radius, canvasHeight)
      ctxNew.quadraticCurveTo(0, canvasHeight, 0, 0 + canvasHeight - radius)
      ctxNew.lineTo(0, radius)
      ctxNew.quadraticCurveTo(0, 0, radius, 0)
      ctxNew.closePath()
      // Clip for rounded corners
      ctxNew.clip()
      ctxNew.drawImage(canvas, 0, 0, widthNew, canvasHeight, 0, 0, widthNew, canvasHeight)
      const textUrl = canvasNew.toDataURL('image/png')
      return { textUrl, width: widthNew, height: canvasHeight }
    },
    prepareTextUrls: function () {
      this.rows.forEach(row => {
        if (row.data.objects) {
          row.data.objects.forEach(obj => {
            if (obj.text) {
              obj.url = this.prepareTextUrl(obj.text).textUrl
            }
          })
        }
      })
    },
    prepareVisibleRows: function () {
      this.checkVisibleThumbnails()
      this.applyMathjax()
    },
    prepareWalkthrough: function () {
      this.walkthrough.objects = []
      this.walkthrough.domObjectKeys = []
      this.lastObjectKey = 0
      const rowObjectKeys = []
      // Prepare arrays of row indexes and objects for each photo
      const photoObjects = {}
      const photoRowIndexes = {}
      this.rows.forEach((row, rowIndex) => {
        rowObjectKeys[rowIndex] = []
        if (row.data.photoId) {
          // Create photoObjects and photoRowIndexes entry if not already there
          if (!photoObjects[row.data.photoId]) {
            photoObjects[row.data.photoId] = []
            photoRowIndexes[row.data.photoId] = []
          }
          photoRowIndexes[row.data.photoId].push(rowIndex)
          // Loop through row objects
          if (row.data.objects) {
            row.data.objects.forEach(obj => {
              this.lastObjectKey++
              const objObj = {
                angle: obj.angle,
                flipX: obj.flipX,
                flipY: obj.flipY,
                height: obj.height,
                key: this.lastObjectKey,
                left: obj.left,
                rowIndex: rowIndex,
                sequence: obj.sequence,
                top: obj.top,
                url: obj.url,
                width: obj.width
              }
              // Push object to photoObjects item
              photoObjects[row.data.photoId].push(objObj)
              // Push object to walkthrough.objects
              this.walkthrough.objects.push(objObj)
            })
          }
        } else {
          // Loop through row objects
          if (row.data.objects) {
            row.data.objects.forEach(obj => {
              // Push object to walkthrough.objects
              this.lastObjectKey++
              this.walkthrough.objects.push({
                angle: obj.angle,
                flipX: obj.flipX,
                flipY: obj.flipY,
                height: obj.height,
                key: this.lastObjectKey,
                left: obj.left,
                rowIndex: rowIndex,
                sequence: obj.sequence,
                top: obj.top,
                url: obj.url,
                width: obj.width
              })
              rowObjectKeys[rowIndex].push(this.lastObjectKey)
            })
          }
        }
      })
      // Loop through photos
      const photoIds = Object.keys(photoObjects)
      photoIds.forEach(photoId => {
        // Add objects to their own row
        photoObjects[photoId].forEach(obj => {
          rowObjectKeys[obj.rowIndex].push(obj.key)
        })
        // Create array of locations, including rowIndex
        const locations = photoRowIndexes[photoId].map(rowIndex => {
          return {
            height: this.rows[rowIndex].data.location.height,
            left: this.rows[rowIndex].data.location.left,
            rowIndex: rowIndex,
            top: this.rows[rowIndex].data.location.top,
            width: this.rows[rowIndex].data.location.width
          }
        })
        locations.forEach(location => {
          // Add the objects of any parent locations
          const parentLocations = locations.filter(parentLocation => this.isParentLocation(parentLocation, location))
          parentLocations.forEach(parentLocation => {
            if (rowObjectKeys[parentLocation.rowIndex].length) {
              rowObjectKeys[location.rowIndex] = rowObjectKeys[location.rowIndex].concat(rowObjectKeys[parentLocation.rowIndex])
              // Remove any duplicates
              rowObjectKeys[location.rowIndex] = [...new Set(rowObjectKeys[location.rowIndex])]
            }
          })
        })
      })
      const photos = {}
      photoIds.forEach(photoId => {
        const photoIndex = this.photos.findIndex(photo => photo.id === photoId)
        photos[photoId] = {
          photoId: photoId,
          url: this.photos[photoIndex].url
        }
      })
      // Prepare walk through steps
      this.walkthrough.steps = []
      this.rows.forEach((row, rowIndex) => {
        // If there is a summary image (i.e. background photo or objects)
        if (row.data.photoId || (row.data.objects && row.data.objects.length)) {
          // If there is a background photo
          if (row.data.photoId) {
            this.walkthrough.steps.push({
              location: row.data.location,
              objectKeys: rowObjectKeys[rowIndex],
              photo: photos[row.data.photoId],
              rowIndex: rowIndex
            })
          } else {
            // No background photo, only objects
            this.walkthrough.steps.push({
              objectKeys: rowObjectKeys[rowIndex],
              rowIndex: rowIndex
            })
          }
        }
      })
      if (this.options.usePhotoOverviews) {
        this.walkthroughAddPhotoOverviews()
      }
      this.walkthrough.stepNum = 0
      this.animateTransition = false
      // Show objects initially
      this.objectsVisible = true
      // Show data table initially
      this.dataVisible = true
      this.$nextTick(this.setDataHeight)
      // Prepare slider
      $('#walkthroughSlider').slider({
        min: 0,
        max: this.walkthrough.steps.length - 1,
        onMove: value => {
          if (value !== this.walkthrough.stepNum) {
            this.walkthroughMove(value - this.walkthrough.stepNum, 'slider')
          }
        }
      })
      this.prepareWalkthroughStep({})
      this.modal = 'walkthrough'
      $('.ui.modal.walkthrough')
        .modal(
          {
            autofocus: false,
            centered: false,
            onHidden: this.revertPreventBackHistory,
            onHide: () => {
              // Workaround for onHide firing when other modals are closed
              if ($('.ui.modal.walkthrough').modal('is active')) {
                // Show data table
                this.modal = ''
                this.prepareVisibleRows()
                this.setTableWrapperHeight()
                if (!this.walkthrough.rowClicked) {
                  this.revertTableWrapperScrollPos()
                }
                this.walkthrough.rowClicked = false
              }
            },
            onVisible: this.prepareModalHistory
          }
        )
        .modal('show')
    },
    prepareWalkthroughStep: function (options) {
      let step
      step = this.walkthrough.steps[this.walkthrough.stepNum]
      // If there is a background photo
      if (step.photo) {
        this.photo = this.photos.find(photo => photo.id === step.photo.photoId)
        this.locationRect = {
          height: step.location.height,
          left: step.location.left,
          top: step.location.top,
          width: step.location.width
        }
        this.showRect = {
          left: this.walkthroughLocationRectWithMargin.left,
          top: this.walkthroughLocationRectWithMargin.top,
          width: this.walkthroughLocationRectWithMargin.width,
          height: this.walkthroughLocationRectWithMargin.height
        }
      } else {
        // No background photo
        this.photo = {
          height: noPhotoSize,
          width: noPhotoSize
        }
        this.locationRect = {
          height: noPhotoSize,
          left: 0,
          top: 0,
          width: noPhotoSize
        }
        // Show the entire dummy photo
        this.showRect = {
          left: 0,
          top: 0,
          width: this.photo.width,
          height: this.photo.height
        }
      }
      // Set walkthrough.domObjectKeys for any objects needed for the current step, and 3 steps either side
      const loadObjectsFromStep = Math.max(this.walkthrough.stepNum - 3, 0)
      const loadObjectsToStep = Math.min(this.walkthrough.stepNum + 3, this.walkthrough.steps.length - 1)
      let requiredObjectKeys = []
      for (let i = loadObjectsFromStep; i < loadObjectsToStep + 1; i++) {
        this.walkthrough.steps[i].objectKeys.forEach(objectKey => {
          requiredObjectKeys.push(objectKey)
        })
      }
      // Remove duplicates
      requiredObjectKeys = [...new Set(requiredObjectKeys)]
      let updateWalkthroughDomObjects = false
      requiredObjectKeys.forEach(objectKey => {
        if (!this.walkthrough.domObjectKeys[objectKey]) {
          this.walkthrough.domObjectKeys[objectKey] = true
          updateWalkthroughDomObjects = true
        }
      })
      if (updateWalkthroughDomObjects) {
        this.walkthroughDomObjectsTrigger++
      }
      this.photoTransition = options.photoChange
      this.setDataHeight()
      // Apply Mathjax
      this.$nextTick(() => {
        if (window.MathJax) {
          window.MathJax.typeset(['.walkthroughDataTable'])
        }
      })
    },
    preventBack: function () {
      // Push to history to prevent back button going back
      window.history.pushState({ preventBack: true }, '', window.location.href)
      // Hide modal on history entry change
      window.onpopstate = () => {
        switch (this.backCloses) {
          case 'modal':
            // Hide modals
            $('.ui.modal').modal('hide')
            break
          case 'popup':
            // Hide Actions menu
            $('.actionsButton').popup('hide')
            break
        }
        this.backCloses = ''
      }
    },
    previousRowsClick: function (event) {
      // Blur button
      event.target.blur()
      // Show previous rows
      this.firstVisibleRow -= this.visibleRowsCount
      if (this.firstVisibleRow < 1) this.firstVisibleRow = 1
      this.goToRowVal = this.firstVisibleRow.toString()
      // Check/update thumbnails and Mathjax
      this.prepareVisibleRows()
      this.scrollToTopLeft()
    },
    printMemoSetClick: function () {
      const self = this
      let firstVisibleRow, imageUrls, photo, remaining
      function imageLoaded () {
        remaining--
        if (!remaining) {
          self.$nextTick(() => {
            window.print()
            // Revert first visible row number and printing flag
            self.firstVisibleRow = firstVisibleRow
            self.printing = false
          })
        }
      }
      document.getElementById('printMemoSetButton').blur()
      this.closeToasts()
      // Save first visible row number
      firstVisibleRow = this.firstVisibleRow
      this.firstVisibleRow = 1
      // Set printing flag - this makes all rows visible
      this.printing = true
      // Check/update thumbnails and Mathjax
      this.prepareVisibleRows()
      // Prepare array of images that need to be loaded
      imageUrls = []
      // Add card images
      for (let i = 0; i < 52; i++) {
        imageUrls.push('/cards/english/card' + i + '.svg')
      }
      // Add images in columns
      let cellHtml, imgPos, quotePos
      this.fields.forEach(field => {
        this.rows.forEach(row => {
          cellHtml = row.data[field.fieldId]
          if (cellHtml) {
            while (true) {
              imgPos = cellHtml.indexOf('<img src="')
              if (imgPos === -1) break
              quotePos = cellHtml.indexOf('"', imgPos + 10)
              imageUrls.push(cellHtml.substring(imgPos + 10, quotePos).replace(/&amp;/g, '&'))
              cellHtml = cellHtml.substring(quotePos)
            }
          }
        })
      })
      // Add background photo images
      if (this.options.useBackgroundPhotos) {
        Object.keys(this.photoUsage).forEach(photoId => {
          photo = this.photos.find(photo => photo.id === photoId)
          if (photo) imageUrls.push(photo.url)
        })
      }
      // Add images for summary images
      if (this.options.useSummaryImages) {
        this.rows.forEach(row => {
          if (row.data.objects) {
            row.data.objects.forEach(obj => {
              if (obj.url) imageUrls.push(obj.url)
            })
          }
        })
      }
      // Keep unique image URLs
      imageUrls = [...new Set(imageUrls)]
      // Check/load images
      remaining = imageUrls.length
      imageUrls.forEach(imageUrl => {
        let img = new Image()
        img.onload = imageLoaded
        img.onerror = imageLoaded
        img.src = imageUrl
      })
    },
    questionCardHtml: function (cardStyle) {
      let backgroundPosition, height, width
      // Adjust card styles for questions
      // If single card
      if (cardStyle.backgroundPosition === '10px 10px') {
        backgroundPosition = '0 0'
        height = cardStyle.backgroundImage.includes('german') ? '181px' : '170px'
        width = '124px'
      } else {
        // Two cards
        backgroundPosition = '40px 0, 4px 0'
        height = '174px'
        width = '164px'
      }
      const html = '<div style="background-image: ' + cardStyle.backgroundImage + '; background-position: ' + backgroundPosition + '; background-repeat: ' + cardStyle.backgroundRepeat + '; background-size: ' + cardStyle.backgroundSize + '; display: inline-block; height: ' + height + '; width: ' + width + '"></div>'
      return html
    },
    questionClick: function (mode, question, questionIndex) {
      this.question.mode = mode
      if (this.question.mode === 'edit') {
        this.question.questionId = question.questionId
        this.question.questionIndex = questionIndex
        this.question.originalQuestionText = question.questionText
        this.question.questionText = question.questionText
        this.question.fromFieldId = question.fromFieldId
        this.question.toFieldId = question.toFieldId
      } else {
        this.question.questionId = ''
        this.question.originalQuestionText = ''
        this.question.questionText = ''
        this.question.fromFieldId = ''
        this.question.toFieldId = ''
      }
      this.question.pendingDelete = false
      // Prepare From Column dropdown
      $('.questionFromFieldId')
        .dropdown({
          onChange: value => {
            this.question.fromFieldId = value
            this.defaultQuestionText()
          },
          showOnFocus: false
        })
      // Prepare To Column dropdown
      $('.questionToFieldId')
        .dropdown({
          onChange: value => {
            this.question.toFieldId = value
            this.defaultQuestionText()
          },
          showOnFocus: false
        })
      // Default dropdown values
      if (this.question.mode === 'edit') {
        $('.questionFromFieldId').dropdown('set selected', this.question.fromFieldId)
        $('.questionToFieldId').dropdown('set selected', this.question.toFieldId)
      } else {
        $('.questionFromFieldId').dropdown('clear')
        $('.questionToFieldId').dropdown('clear')
      }
      // Show modal
      this.modal = 'question'
      $('.ui.modal.question')
        .modal({
          autofocus: false,
          onApprove: this.questionSubmit,
          onHidden: () => {
            this.modal = ''
            this.revertPreventBackHistory()
          },
          onVisible: this.prepareModalHistory
        })
        .modal('show')
    },
    questionEnter: function () {
      // Do nothing if there's an error or the question is pending deletion
      if (this.questionStatus !== 'ok' || this.question.pendingDelete) return
      // Submit form
      this.questionSubmit()
      // Hide modal
      $('.ui.modal.question').modal('hide')
    },
    questionInput: function (event) {
      this.question.questionText = event.target.innerHTML
      // Remove trailing &nbsp;
      this.question.questionText = this.question.questionText.replace(/(&nbsp;\s*)+$/, '')
      // Remove trailing whitespace
      this.question.questionText = this.question.questionText.replace(/\s+$/, '')
    },
    questionSubmit: function () {
      let questionId
      const batch = db.batch()
      if (this.question.pendingDelete) {
        this.deleteFieldOrQuestion('', this.question.questionId)
      } else {
        if (this.question.mode === 'add') {
          let questionIndex
          // Get next available question ID
          questionIndex = 0
          while (true) {
            questionIndex++
            questionId = 'q' + questionIndex.toString()
            if (!this.questions.filter(question => question.questionId === questionId).length) break
          }
          this.questions.push({
            fromFieldId: this.question.fromFieldId,
            questionId: questionId,
            questionText: this.question.questionText,
            toFieldId: this.question.toFieldId
          })
        } else {
          let question = this.questions[this.question.questionIndex]
          question.fromFieldId = this.question.fromFieldId
          question.toFieldId = this.question.toFieldId
          question.questionText = this.question.questionText
          questionId = this.question.questionId
        }
        this.sortQuestions()
        // Reset questions selected for learning
        this.learn.questionIds = []
        this.$nextTick(() => {
          // Highlight the new question
          $('#question_' + questionId).transition({
            animation: 'questionGlow',
            duration: '3s'
          })
        })
        // Update Firestore
        this.writeQuestions(batch)
        if (this.question.mode === 'add') {
          this.updateKnownData(batch, this.memoSetId)
        }
        batch.commit()
          .catch(error => { logError('MemoSet_questionSubmit', error) })
      }
    },
    randomId: function () {
      return db.collection('dummy').doc().id
    },
    readMemoSet: function () {
      const self = this
      let headerDataLoaded, rowsDataLoaded, rowsRef
      if (
        (['public', 'shared'].includes(this.memoSetType) && this.user.id !== this.ownerId) ||
        (this.memoSetType === 'regular' && !this.selfOwned)
      ) {
        db.doc('memoSets/' + this.originalMemoSetId + '/userRatings/' + this.user.id)
          .get()
          .then(doc => {
            if (doc.exists) {
              this.myRating = doc.data().rating
            } else {
              this.myRating = 0
            }
            self.$nextTick(() => {
              // Initialize rating
              $('.ui.rating.myRating').rating({
                clearable: true,
                onRate: value => {
                  const previousRating = this.myRating
                  this.myRating = value
                  if (value) {
                    // Write rating to Firestore
                    db.doc('memoSets/' + self.originalMemoSetId + '/userRatings/' + self.user.id)
                      .set({
                        rating: value
                      })
                      .then(() => {
                        // Write request for server to update memo set rating
                        db.collection('userRatingChange')
                          .add({
                            memoSetId: self.originalMemoSetId,
                            newRating: value,
                            oldRating: previousRating,
                            timestamp: firebase.firestore.FieldValue.serverTimestamp(),
                            userId: self.user.id
                          })
                          .catch(error => { logError('MemoSet_readMemoSet_1', error) })
                      })
                      .catch(error => { logError('MemoSet_readMemoSet_2', error) })
                  } else {
                    // Rating has been removed
                    db.doc('memoSets/' + self.originalMemoSetId + '/userRatings/' + self.user.id)
                      .delete()
                      .then(() => {
                        // Write request for server to update memo set rating
                        db.collection('userRatingChange')
                          .add({
                            memoSetId: self.originalMemoSetId,
                            newRating: value,
                            oldRating: previousRating,
                            timestamp: firebase.firestore.FieldValue.serverTimestamp(),
                            userId: self.user.id
                          })
                          .catch(error => { logError('MemoSet_readMemoSet_3', error) })
                      })
                      .catch(error => { logError('MemoSet_readMemoSet_4', error) })
                  }
                }
              })
            })
          })
          .catch(error => {
            logError('MemoSet_readMemoSet_5', error)
            this.showCouldNotOpenToast()
            this.$router.push('/memo-sets')
          })
      }
      // Read original memo set
      if (this.memoSetType === 'regular' && !this.selfOwned) {
        db.doc('memoSets/' + this.originalMemoSetId)
          .get()
          .then(doc => {
            if (doc.exists) {
              this.updates = doc.data().updates || []
              this.originalMemoSet.sharing = doc.data().sharing
            }
          })
          .catch(error => { logError('MemoSet_readMemoSet_original', error) })
      }
      // Listen to memo set header data
      this.detachMemoSetListener = db.collection('memoSets').doc(this.memoSetId)
        .onSnapshot(doc => {
          // If memo set has been deleted
          if (doc.exists && doc.data().deleted) {
            // Redirect to Memo Sets page
            self.$router.push('/memo-sets')
          }
          if (!headerDataLoaded || !doc.metadata.hasPendingWrites) {
            if (doc.exists) {
              self.authorId = doc.data().authorId || ''
              self.authorName = doc.data().authorName || ''
              self.createdAt = doc.data().createdAt
              self.description = doc.data().description || this.text.memoSetDefaultDescription
              self.descriptionPlaceholder = (self.description === self.text.memoSetDefaultDescription)
              self.fields = doc.data().fields
              self.gameResults = doc.data().gameResults || {}
              self.groupColors = doc.data().groupColors || []
              self.learn.questionIds = doc.data().selectedQuestionIds || []
              self.learn.reviewGroupIds = doc.data().options.useReviewGroups ? (doc.data().selectedReviewGroupIds || []) : []
              self.options = doc.data().options
              self.photos = doc.data().photos
              self.questions = doc.data().questions
              self.reviewSchedule = doc.data().reviewSchedule || self.reviewScheduleDefault
              self.sharing = doc.data().sharing || {}
              self.testResults = doc.data().testResults || {}
              self.updatesDismissed = doc.data().updatesDismissed
              if (self.selfOwned || self.memoSetType === 'public' || self.memoSetType === 'shared') {
                self.updates = doc.data().updates || []
                self.initUpdates()
              }
              self.sortQuestions()
              headerDataLoaded = true
              if (rowsDataLoaded) self.memoSetLoaded()
            } else {
              // Redirect to Memo Sets page
              self.$router.push('/memo-sets')
            }
          }
        }, error => {
          logError('MemoSet_readMemoSet_6', error)
          this.showCouldNotOpenToast()
          this.$router.push('/memo-sets')
        })
      // Listen to memo set rows data
      if (this.memoSetType === 'regular') rowsRef = db.collection('memoSets/' + this.memoSetId + '/rows').where('userId', '==', this.user.id)
      if (this.memoSetType === 'public' || this.memoSetType === 'example') rowsRef = db.collection('memoSets/' + this.memoSetId + '/rows').where('sharing.public', '==', true)
      if (this.memoSetType === 'shared') rowsRef = db.collection('memoSets/' + this.memoSetId + '/rows').where('sharing.' + this.user.id, '==', this.user.displayName)
      this.detachDataListener = rowsRef
        .onSnapshot(snapshot => {
          let pendingWrites = false
          snapshot.docChanges().forEach(change => {
            if (change.doc.metadata.hasPendingWrites) {
              pendingWrites = true
            }
          })
          if (!rowsDataLoaded || !pendingWrites) {
            self.rows = []
            snapshot.forEach(doc => {
              Object.keys(doc.data()).forEach(rowId => {
                // Ignore userId and sharing fields
                if (rowId !== 'userId' && rowId !== 'sharing') {
                  self.rows.push({
                    data: doc.data()[rowId],
                    docId: doc.id,
                    rowId: rowId
                  })
                }
              })
            })
            // Sort rows by seq
            self.rows.sort((a, b) => {
              if (a.data.seq < b.data.seq) return -1
              return 1
            })
            self.prepareTextUrls()
            rowsDataLoaded = true
            if (headerDataLoaded) self.memoSetLoaded()
            self.initSearch()
          }
        }, error => { logError('MemoSet_readMemoSet_7', error) })
    },
    rectangleHandleMouseDown: function (event, handle) {
      this.rectangleResizeStart(event, handle)
    },
    rectangleHandleTouchStart: function (event, handle) {
      // If this is the first touch
      if (event.touches.length === 1) {
        this.rectangleResizeStart(event.touches[0], handle)
        event.stopPropagation()
      }
    },
    rectangleResizeStart: function (pointer, handle) {
      // Save before state for undo array
      this.summaryImage.before = {
        height: this.locationRect.height,
        left: this.locationRect.left,
        top: this.locationRect.top,
        width: this.locationRect.width
      }
      this.pointerPosX = pointer.clientX
      this.pointerPosY = pointer.clientY
      this.resizingRectangle = true
      this.rectangleResizeHandle = handle
      this.animateTransition = false
    },
    rectangleMouseDown: function (event) {
      if (this.mode === 'select' && !this.panLock) {
        this.rectanglePointerDown(event)
        // Prevent panning
        event.stopPropagation()
      }
    },
    rectanglePointerDown: function (pointer) {
      // Save before state for undo array
      this.summaryImage.before = {
        left: this.locationRect.left,
        top: this.locationRect.top
      }
      this.activeObject = -1
      this.rectangleActive = true
      this.pointerPosX = pointer.clientX
      this.pointerPosY = pointer.clientY
      this.movingRectangle = true
      this.animateTransition = false
    },
    rectangleTouchStart: function (event) {
      if (event.touches.length === 1) {
        if (this.mode === 'select' && !this.panLock) {
          this.summaryImage.firstTouchActiveObject = this.activeObject
          this.summaryImage.firstTouchRectangleActive = this.rectangleActive
          this.summaryImage.firstTouchTimestamp = event.timeStamp
          this.rectanglePointerDown(event.touches[0])
          // Prevent panning
          event.stopPropagation()
        }
      }
    },
    removeClippingPathUndoEntries () {
      for (let i = this.summaryImage.undoArrayIndex - 1; i >= 0; i--) {
        if (this.summaryImage.undoArray[i].changeType === 'clippingPath') {
          this.summaryImage.undoArray.splice(i, 1)
          this.summaryImage.undoArrayIndex--
        }
      }
    },
    removeColor: function () {
      let ctx, imgData, undoEntry
      const mask = this.removeMask
      ctx = this.editCanvas.getContext('2d')
      imgData = ctx.getImageData(0, 0, this.editCanvas.width, this.editCanvas.height)
      // Make pixels within the mask transparent
      for (let y = mask.bounds.minY; y <= mask.bounds.maxY; y++) {
        for (let x = mask.bounds.minX; x <= mask.bounds.maxX; x++) {
          if (mask.data[y * mask.width + x] === 0) continue
          let k = (y * this.editCanvas.width + x) * 4
          imgData.data[k] = 0
          imgData.data[k + 1] = 0
          imgData.data[k + 2] = 0
          imgData.data[k + 3] = 0
        }
      }
      ctx.putImageData(imgData, 0, 0)
      this.drawObjectCanvas()
      undoEntry = {
        after: {
          canvas: this.copyOfCanvas(document.getElementById('objectCanvas'))
        },
        before: {
          canvas: this.copyOfCanvas(this.summaryImage.before.canvas)
        },
        changeType: 'remove',
        objIndex: this.activeObject
      }
      if (this.summaryImage.before.url) {
        undoEntry.before.url = this.summaryImage.before.url
        this.summaryImage.before = {}
      }
      this.addSummaryImageUndoEntry(undoEntry)
      this.removing = false
    },
    removeColorClick: function () {
      let ctx, img
      // Blur button
      document.getElementById('removeColorButton').blur()
      const obj = this.objects[this.activeObject]
      if (this.mode === 'clip') this.endClipMode()
      if (this.mode === 'draw') this.endDrawMode()
      if (this.mode === 'remove') {
        this.endRemoveMode()
      } else {
        // Save the object's URL
        this.summaryImage.before = {
          url: obj.url
        }
        // Initialize editCanvas with the object image
        img = new Image()
        img.crossOrigin = 'Anonymous'
        img.onload = () => {
          this.editCanvas = document.createElement('canvas')
          this.editCanvas.width = img.naturalWidth
          this.editCanvas.height = img.naturalHeight
          ctx = this.editCanvas.getContext('2d')
          ctx.drawImage(img, 0, 0)
          this.drawObjectCanvas()
          this.removeImageData = ctx.getImageData(0, 0, this.editCanvas.width, this.editCanvas.height)
          this.mode = 'remove'
          this.removing = false
          this.panLock = false
        }
        img.src = obj.url
      }
    },
    removeDrawPath: function () {
      let image
      const canvas = document.getElementById('objectCanvas')
      const ctx = canvas.getContext('2d')
      // Draw image from master canvas
      this.drawObjectCanvas()
      // Use Magic Wand to create mask
      image = {
        data: this.removeImageData.data,
        width: canvas.width,
        height: canvas.height,
        bytes: 4
      }
      this.removeMask = MagicWand.floodFill(image, this.removeDownPoint.x, this.removeDownPoint.y, this.removeThreshold, null, true)
      this.removeContours = MagicWand.traceContours(this.removeMask)
      // Draw path
      ctx.lineWidth = this.editCanvas.width / this.objects[this.activeObject].width
      ctx.beginPath()
      this.removeContours.forEach(contour => {
        ctx.moveTo(contour.points[0].x, contour.points[0].y)
        contour.points.forEach(point => {
          ctx.lineTo(point.x, point.y)
        })
      })
      ctx.strokeStyle = 'red'
      ctx.stroke()
      ctx.lineWidth = 1
    },
    removePointerDown: function (pointer) {
      let ctx, pixel
      // Save mouse position
      this.pointerPosX = pointer.clientX
      this.pointerPosY = pointer.clientY
      // Determine pointer down point on the canvas - rounded to integer coordinates
      this.removeDownPoint = this.getCanvasCoords(this.pointerPosX, this.pointerPosY)
      this.removeDownPoint.x = Math.round(this.removeDownPoint.x)
      this.removeDownPoint.y = Math.round(this.removeDownPoint.y)
      ctx = this.editCanvas.getContext('2d')
      pixel = ctx.getImageData(this.removeDownPoint.x, this.removeDownPoint.y, 1, 1)
      // If the point clicked is not transparent
      if (pixel.data[3]) {
        // Save intial canvas
        this.summaryImage.before.canvas = this.copyOfCanvas(this.editCanvas)
        this.removeThreshold = this.removeDefaultThreshold
        this.removing = true
        this.removeDrawPath()
      }
    },
    removePointerMove: function (pointer) {
      let deltaY
      deltaY = pointer.clientY - this.pointerPosY
      if (deltaY < 0) {
        deltaY /= 5
      } else {
        deltaY /= 3
      }
      this.removeThreshold = this.removeDefaultThreshold + deltaY
      // Constrain threshold between 1 and 255
      this.removeThreshold = Math.max(Math.min(this.removeThreshold, 255), 1)
      this.removeDrawPath()
    },
    removeSharedMemoSet: function () {
      const self = this
      // Update the shared memo set status
      db.doc('users/' + this.user.id + '/sharedWithMe/' + this.originalMemoSetId)
        .update({
          status: 'deleted'
        })
        .then(() => {
          // If there are other visible shared memo sets
          if (this.sharedMemoSets.some(memoSet => memoSet.memoSetId !== this.originalMemoSetId && !memoSet.status)) {
            self.$router.push('/shared-memo-sets')
          } else {
            self.$router.push('/memo-sets')
          }
        })
        .catch(error => { logError('MemoSet_removeSharedMemoSet', error) })
    },
    removeSharedMemoSetClick: function () {
      this.modalHtml = '<p>' + this.text.memoSetDeleteMemoSetShared1.replace('*', '<strong>' + this.title + '</strong>') + '</p>'
      this.modalHtml += '<p>' + this.text.memoSetDeleteMemoSetShared2 + '</p>'
      $('.ui.modal.deleteMemoSet')
        .modal({
          onApprove: this.deleteMemoSetSubmit
        })
        .modal('show')
    },
    resetLearningClick: function () {
      let textKey
      // Prepare text key depending on plurality of questions and groups
      textKey = 'memoSetReset'
      if (this.learn.questionIds.length !== this.questions.length) {
        textKey += 'Question'
        if (this.learn.questionIds.length > 1) textKey += 's'
      }
      if (this.options.useReviewGroups && this.learn.reviewGroupIds.length !== this.reviewGroupsList.length) {
        textKey += 'Group'
        if (this.learn.reviewGroupIds.length > 1) textKey += 's'
      }
      this.resetLearningMessage = this.text[textKey]
      this.modal = 'resetLearning'
      $('.ui.modal.resetLearning')
        .modal({
          onApprove: this.resetLearningSubmit,
          onHidden: this.revertPreventBackHistory,
          onHide: this.resetModal,
          onVisible: this.prepareModalHistory
        })
        .modal('show')
    },
    resetLearningSubmit: function () {
      let firestoreUpdate, questionsToReset
      firestoreUpdate = {}
      questionsToReset = this.activeQuestions.filter(question => question.reviewCycle || question.reviewAfter)
      questionsToReset.forEach(question => {
        const row = this.rows[question.rowIndex]
        this.$delete(row.data.questions, question.questionId)
        // Update Firestore update object
        if (!firestoreUpdate[row.docId]) firestoreUpdate[row.docId] = {}
        firestoreUpdate[row.docId][row.rowId + '.questions.' + question.questionId] = firebase.firestore.FieldValue.delete()
      })
      // Update Firestore
      const batch = db.batch()
      Object.keys(firestoreUpdate).forEach(docId => {
        batch.update(db.doc('memoSets/' + this.memoSetId + '/rows/' + docId), firestoreUpdate[docId])
      })
      this.updateKnownData(batch, this.memoSetId)
      batch.commit()
        .catch(error => { logError('MemoSet_resetLearningSubmit', error) })
      this.prepareActiveQuestions()
      this.setStatuses('slow')
    },
    resetModal: function () {
      // Workaround for modals being left with inline "display: block !important" on mobile after the modal is closed
      const modal = this.modal
      setTimeout(() => {
        const modals = document.getElementsByClassName('ui modal ' + modal)
        if (modals.length) {
          modals[0].style.removeProperty('display')
        }
      }, 500)
      // Reset modal value
      this.modal = ''
    },
    resetReviewSchedule: function (amount) {
      this.reviewScheduleDisplay = this.reviewScheduleDefault.slice()
      this.reviewScheduleExact = this.reviewScheduleDefault.slice()
    },
    resizeObject: function (event) {
      // minObjectSize is the minimum size of the object, in screen pixels
      const minObjectSize = 40
      // adjustX, adjustY, handlePosAfterResize, handlePosBeforeResize, rotatedAdjustX, rotatedAdjustY are relative to the photo
      // diagonalDistance, moveX, moveY, rotatedMoveX, rotatedMoveY, this.pointerPosX, this.pointerPosY are relative to the screen
      let adjustX, adjustY, angleInRadians, angleToHandleInRadians, aspect, diagonalDistance, fixedHandle, handlePosAfterResize, handlePosBeforeResize, moveX, moveY, obj, rotatedAdjustX, rotatedAdjustY, rotatedMoveX, rotatedMoveY
      obj = this.objects[this.activeObject]
      moveX = event.clientX - this.pointerPosX
      moveY = event.clientY - this.pointerPosY
      // Rotate movement anticlockwise by the object's angle
      angleInRadians = obj.angle * Math.PI / 180
      rotatedMoveX = moveX * Math.cos(angleInRadians) + moveY * Math.sin(angleInRadians)
      rotatedMoveY = -moveX * Math.sin(angleInRadians) + moveY * Math.cos(angleInRadians)
      adjustX = 0
      adjustY = 0
      // Determine a fixed handle based on which handle is dragged
      switch (this.objectResizeHandle) {
        case 'right middle':
        case 'right bottom':
        case 'center bottom':
          fixedHandle = 'left top'
          break
        case 'left middle':
        case 'left top':
        case 'center top':
          fixedHandle = 'right bottom'
          break
        case 'left bottom':
          fixedHandle = 'right top'
          break
        case 'right top':
          fixedHandle = 'left bottom'
      }
      // Get position of fixed handle before resize
      handlePosBeforeResize = this.handlePosition(obj, fixedHandle)
      switch (this.objectResizeHandle) {
        case 'center top':
          obj.height -= rotatedMoveY / this.photoTransform.scale
          // If the object is too short, adjust it
          if (obj.height < minObjectSize / this.photoTransform.scale) {
            adjustY = obj.height - minObjectSize / this.photoTransform.scale
            obj.height = minObjectSize / this.photoTransform.scale
          }
          break
        case 'right middle':
          obj.width += rotatedMoveX / this.photoTransform.scale
          // If the object is too narrow, adjust it
          if (obj.width < minObjectSize / this.photoTransform.scale) {
            adjustX = minObjectSize / this.photoTransform.scale - obj.width
            obj.width = minObjectSize / this.photoTransform.scale
          }
          break
        case 'center bottom':
          obj.height += rotatedMoveY / this.photoTransform.scale
          // If the object is too short, adjust it
          if (obj.height < minObjectSize / this.photoTransform.scale) {
            adjustY = minObjectSize / this.photoTransform.scale - obj.height
            obj.height = minObjectSize / this.photoTransform.scale
          }
          break
        case 'left middle':
          obj.width -= rotatedMoveX / this.photoTransform.scale
          // If the object is too narrow, adjust it
          if (obj.width < minObjectSize / this.photoTransform.scale) {
            adjustX = obj.width - minObjectSize / this.photoTransform.scale
            obj.width = minObjectSize / this.photoTransform.scale
          }
          break
        case 'right top':
          // angleToHandleInRadians is the angle from the center of the object to the handle, measured clockwise from the y-axis
          angleToHandleInRadians = Math.atan(obj.width / obj.height)
          // Get component of movement along the direction from the center to the handle
          diagonalDistance = rotatedMoveX * Math.sin(angleToHandleInRadians) - rotatedMoveY * Math.cos(angleToHandleInRadians)
          obj.width += diagonalDistance * Math.sin(angleToHandleInRadians) / this.photoTransform.scale
          obj.height += diagonalDistance * Math.cos(angleToHandleInRadians) / this.photoTransform.scale
          // Adjust object if too small
          aspect = obj.width / obj.height
          if (aspect < 1) {
            // If the object is too narrow, adjust it
            if (obj.width < minObjectSize / this.photoTransform.scale) {
              adjustX = minObjectSize / this.photoTransform.scale - obj.width
              adjustY = -adjustX / aspect
              obj.width = minObjectSize / this.photoTransform.scale
              obj.height = obj.width / aspect
            }
          } else {
            // If the object is too short, adjust it
            if (obj.height < minObjectSize / this.photoTransform.scale) {
              adjustY = obj.height - minObjectSize / this.photoTransform.scale
              adjustX = -adjustY * aspect
              obj.height = minObjectSize / this.photoTransform.scale
              obj.width = obj.height * aspect
            }
          }
          break
        case 'right bottom':
          // angleToHandleInRadians is the angle from the center of the object to the handle, measured clockwise from the x-axis
          angleToHandleInRadians = Math.atan(obj.height / obj.width)
          // Get component of movement along the direction from the center to the handle
          diagonalDistance = rotatedMoveX * Math.cos(angleToHandleInRadians) + rotatedMoveY * Math.sin(angleToHandleInRadians)
          obj.width += diagonalDistance * Math.cos(angleToHandleInRadians) / this.photoTransform.scale
          obj.height += diagonalDistance * Math.sin(angleToHandleInRadians) / this.photoTransform.scale
          // Adjust object if too small
          aspect = obj.width / obj.height
          if (aspect < 1) {
            // If the object is too narrow, adjust it
            if (obj.width < minObjectSize / this.photoTransform.scale) {
              adjustX = minObjectSize / this.photoTransform.scale - obj.width
              adjustY = adjustX / aspect
              obj.width = minObjectSize / this.photoTransform.scale
              obj.height = obj.width / aspect
            }
          } else {
            // If the object is too short, adjust it
            if (obj.height < minObjectSize / this.photoTransform.scale) {
              adjustY = minObjectSize / this.photoTransform.scale - obj.height
              adjustX = adjustY * aspect
              obj.height = minObjectSize / this.photoTransform.scale
              obj.width = obj.height * aspect
            }
          }
          break
        case 'left bottom':
          // angleToHandleInRadians is the angle from the center of the object to the handle, measured clockwise from the negative y-axis
          angleToHandleInRadians = Math.atan(obj.width / obj.height)
          // Get component of movement along the direction from the center to the handle
          diagonalDistance = -rotatedMoveX * Math.sin(angleToHandleInRadians) + rotatedMoveY * Math.cos(angleToHandleInRadians)
          obj.width += diagonalDistance * Math.sin(angleToHandleInRadians) / this.photoTransform.scale
          obj.height += diagonalDistance * Math.cos(angleToHandleInRadians) / this.photoTransform.scale
          // Adjust object if too small
          aspect = obj.width / obj.height
          if (aspect < 1) {
            // If the object is too narrow, adjust it
            if (obj.width < minObjectSize / this.photoTransform.scale) {
              adjustX = obj.width - minObjectSize / this.photoTransform.scale
              adjustY = -adjustX / aspect
              obj.width = minObjectSize / this.photoTransform.scale
              obj.height = obj.width / aspect
            }
          } else {
            // If the object is too short, adjust it
            if (obj.height < minObjectSize / this.photoTransform.scale) {
              adjustY = minObjectSize / this.photoTransform.scale - obj.height
              adjustX = -adjustY * aspect
              obj.height = minObjectSize / this.photoTransform.scale
              obj.width = obj.height * aspect
            }
          }
          break
        case 'left top':
          // angleToHandleInRadians is the angle from the center of the object to the handle, measured clockwise from the negaive x-axis
          angleToHandleInRadians = Math.atan(obj.height / obj.width)
          // Get component of movement along the direction from the center to the handle
          diagonalDistance = -rotatedMoveX * Math.cos(angleToHandleInRadians) - rotatedMoveY * Math.sin(angleToHandleInRadians)
          obj.width += diagonalDistance * Math.cos(angleToHandleInRadians) / this.photoTransform.scale
          obj.height += diagonalDistance * Math.sin(angleToHandleInRadians) / this.photoTransform.scale
          // Adjust object if too small
          aspect = obj.width / obj.height
          if (aspect < 1) {
            // If the object is too narrow, adjust it
            if (obj.width < minObjectSize / this.photoTransform.scale) {
              adjustX = obj.width - minObjectSize / this.photoTransform.scale
              adjustY = adjustX / aspect
              obj.width = minObjectSize / this.photoTransform.scale
              obj.height = obj.width / aspect
            }
          } else {
            // If the object is too short, adjust it
            if (obj.height < minObjectSize / this.photoTransform.scale) {
              adjustY = obj.height - minObjectSize / this.photoTransform.scale
              adjustX = adjustY * aspect
              obj.height = minObjectSize / this.photoTransform.scale
              obj.width = obj.height * aspect
            }
          }
          break
      }
      handlePosAfterResize = this.handlePosition(obj, fixedHandle)
      // Adjust left and top to keep fixed handle in position
      obj.left += handlePosBeforeResize.x - handlePosAfterResize.x
      obj.top += handlePosBeforeResize.y - handlePosAfterResize.y
      // Rotate adjustments clockwise by the object's angle
      rotatedAdjustX = adjustX * Math.cos(angleInRadians) - adjustY * Math.sin(angleInRadians)
      rotatedAdjustY = adjustX * Math.sin(angleInRadians) + adjustY * Math.cos(angleInRadians)
      // Set mouse position ready for next mousemove, but adjust if attempting to make object too small
      this.pointerPosX = event.clientX + (rotatedAdjustX * this.photoTransform.scale)
      this.pointerPosY = event.clientY + (rotatedAdjustY * this.photoTransform.scale)
    },
    resizeRectangle: function (event) {
      // minRectangleSize is the minimum size of the location rectangle, in screen pixels
      const minRectangleSize = 40
      // adjustX, adjustY are relative to the photo
      // moveX, moveY, this.pointerPosX, this.pointerPosY are relative to the screen
      let adjustX, adjustY, moveX, moveY, previousValue
      moveX = event.clientX - this.pointerPosX
      moveY = event.clientY - this.pointerPosY
      adjustX = 0
      adjustY = 0
      if (this.rectangleResizeHandle.indexOf('left') !== -1) {
        // Set previousValue to the rectangle's right position
        previousValue = this.locationRect.left + this.locationRect.width
        this.locationRect.left += moveX / this.photoTransform.scale
        this.locationRect.width -= moveX / this.photoTransform.scale
        // If the rectangle is too narrow, adjust it
        if (this.locationRect.width < minRectangleSize / this.photoTransform.scale) {
          this.locationRect.width = minRectangleSize / this.photoTransform.scale
          adjustX = (previousValue - this.locationRect.width) - this.locationRect.left
          this.locationRect.left = previousValue - this.locationRect.width
        }
        // Constrain the rectangle to the left side of the photo
        if (this.locationRect.left < 0) {
          adjustX = 0 - this.locationRect.left
          this.locationRect.width += this.locationRect.left
          this.locationRect.left = 0
        }
      }
      if (this.rectangleResizeHandle.indexOf('top') !== -1) {
        // Set previousValue to the rectangle's bottom position
        previousValue = this.locationRect.top + this.locationRect.height
        this.locationRect.top += moveY / this.photoTransform.scale
        this.locationRect.height -= moveY / this.photoTransform.scale
        // If the rectangle is too short, adjust it
        if (this.locationRect.height < minRectangleSize / this.photoTransform.scale) {
          this.locationRect.height = minRectangleSize / this.photoTransform.scale
          adjustY = (previousValue - this.locationRect.height) - this.locationRect.top
          this.locationRect.top = previousValue - this.locationRect.height
        }
        // Constrain the rectangle to the top of the photo
        if (this.locationRect.top < 0) {
          adjustY = 0 - this.locationRect.top
          this.locationRect.height += this.locationRect.top
          this.locationRect.top = 0
        }
      }
      if (this.rectangleResizeHandle.indexOf('right') !== -1) {
        this.locationRect.width += moveX / this.photoTransform.scale
        // If the rectangle is too narrow, adjust it
        if (this.locationRect.width < minRectangleSize / this.photoTransform.scale) {
          adjustX = minRectangleSize / this.photoTransform.scale - this.locationRect.width
          this.locationRect.width = minRectangleSize / this.photoTransform.scale
        }
        // Constrain the rectangle to the right side of the photo
        if (this.locationRect.left + this.locationRect.width > this.photo.width) {
          adjustX = this.photo.width - (this.locationRect.left + this.locationRect.width)
          this.locationRect.width = this.photo.width - this.locationRect.left
        }
      }
      if (this.rectangleResizeHandle.indexOf('bottom') !== -1) {
        this.locationRect.height += moveY / this.photoTransform.scale
        // If the rectangle is too short, adjust it
        if (this.locationRect.height < minRectangleSize / this.photoTransform.scale) {
          adjustY = minRectangleSize / this.photoTransform.scale - this.locationRect.height
          this.locationRect.height = minRectangleSize / this.photoTransform.scale
        }
        // Constrain the rectangle to the bottom of the photo
        if (this.locationRect.top + this.locationRect.height > this.photo.height) {
          adjustY = this.photo.height - (this.locationRect.top + this.locationRect.height)
          this.locationRect.height = this.photo.height - this.locationRect.top
        }
      }
      // Set pointer position ready for next move, but adjust if attempting to resize beyond limits
      this.pointerPosX = event.clientX + (adjustX * this.photoTransform.scale)
      this.pointerPosY = event.clientY + (adjustY * this.photoTransform.scale)
    },
    revertPreventBackHistory: function () {
      // Revert history if not already done
      if (window.history.state && window.history.state.preventBack) {
        window.history.back()
      }
    },
    revertTableWrapperScrollPos: function () {
      this.$nextTick(() => {
        const tableWrapper = document.getElementById('tableWrapper')
        if (tableWrapper) {
          tableWrapper.scrollLeft = this.saveTableWrapperScrollLeft
          tableWrapper.scrollTop = this.saveTableWrapperScrollTop
        }
      })
    },
    reviewCycleClick: function (reviewCycle) {
      // Blur button
      document.activeElement.blur()
      if (this.rowQuestion.reviewCycle !== reviewCycle) {
        this.rowQuestion.reviewCycle = reviewCycle
        if (reviewCycle) {
          if (reviewCycle === this.rowQuestion.origReviewCycle) {
            this.rowQuestion.status = this.rowQuestion.origStatus
          } else {
            this.rowQuestion.status = 'known'
          }
        } else {
          this.rowQuestion.status = 'unknown'
        }
      }
    },
    reviewGroupBlur: function (event, rowIndex) {
      let oldValue, selectedGroupNames, updateObj
      const row = this.rows[rowIndex]
      const enteredValue = event.target.textContent.trim()
      const allGroupsSelected = this.learn.reviewGroupIds.length === this.reviewGroupsList.length
      // If there was any previous value or a value has been entered
      if (row.data.reviewGroup !== undefined || enteredValue !== '') {
        if (enteredValue !== row.data.reviewGroup) {
          selectedGroupNames = this.learn.reviewGroupIds.map(groupId => this.reviewGroupsList[parseInt(groupId, 10)])
          oldValue = row.data.reviewGroup || ''
          this.$set(row.data, 'reviewGroup', enteredValue)
          // If all groups were selected
          if (allGroupsSelected) {
            // Reset selected groups - all groups will be selected when the Learn tab is activated
            this.learn.reviewGroupIds = []
          } else {
            // If the old group no longer exists
            if (!this.reviewGroupsList.includes(oldValue)) {
              // Remove old value from selected groups
              selectedGroupNames = selectedGroupNames.filter(groupName => groupName !== oldValue)
            }
            this.learn.reviewGroupIds = selectedGroupNames.map(groupName => this.reviewGroupsList.indexOf(groupName).toString())
          }
          // Update Firestore
          updateObj = {}
          updateObj[row.rowId + '.reviewGroup'] = row.data.reviewGroup
          db.doc('memoSets/' + this.memoSetId + '/rows/' + row.docId)
            .update(updateObj)
            .catch(error => { logError('MemoSet_reviewGroupBlur', error) })
          this.initSearch()
        }
      }
      // Update reviewGroup element to remove any pasted HTML formatting
      event.target.textContent = row.data.reviewGroup
      this.currentFieldId = ''
      this.currentRow = -1
      this.setTableWrapperHeight()
    },
    reviewGroupFocus: function (event, rowIndex) {
      this.currentFieldId = 'reviewGroup'
      this.currentRow = rowIndex
      this.setTableWrapperHeight()
      this.currentFieldContent = event.target.innerHTML
    },
    reviewScheduleChanged: function (index) {
      const daysNumber = parseFloat(this.reviewScheduleDisplay[index])
      // If not a number or zero
      if (!daysNumber) {
        // Revert to previous value
        this.reviewScheduleDisplay[index] = Math.round(this.reviewScheduleExact[index] * 10) / 10
        // Force update to display value
        this.$forceUpdate()
      } else {
        // Replace string with number
        this.reviewScheduleDisplay[index] = daysNumber
        // Copy the change to the exact array
        this.reviewScheduleExact[index] = this.reviewScheduleDisplay[index]
      }
    },
    reviewScheduleClick: function () {
      this.closeToasts()
      // Copy review schedule values to temp array for modal
      this.reviewScheduleExact = this.reviewSchedule.slice()
      // Round to 1 decimal place for display
      this.reviewScheduleDisplay = this.reviewScheduleExact.map(days => Math.round(days * 10) / 10)
      // Show modal
      this.modal = 'reviewSchedule'
      $('.ui.modal.reviewSchedule')
        .modal({
          autofocus: false,
          onApprove: this.reviewScheduleSubmit,
          onHidden: this.revertPreventBackHistory,
          onHide: this.resetModal,
          onVisible: this.prepareModalHistory
        })
        .modal('show')
    },
    reviewScheduleEnter: function () {
      // Hide modal
      $('.ui.modal.reviewSchedule').modal('hide')
      this.reviewScheduleSubmit()
    },
    reviewScheduleSubmit: function () {
      const firestoreUpdate = {}
      // Save the previous review schedule
      const previousReviewSchedule = this.reviewSchedule.slice()
      // Round slightly to remove tiny floating point errors
      this.reviewSchedule = this.reviewScheduleExact.map(days => Math.round(days * 1000000) / 1000000)
      // Exit if review schedule hasn't changed
      if (this.reviewSchedule.toString() === previousReviewSchedule.toString()) return
      // Adjust items with a non-zero, non-max review cycle
      this.rows.forEach((row, rowIndex) => {
        if (row.data.questions) {
          const questionIds = Object.keys(row.data.questions)
          questionIds.forEach(questionId => {
            const question = row.data.questions[questionId]
            // If the schedule has changed for the item's review cycle
            if (
              question.reviewCycle > 0 &&
              question.reviewCycle <= this.reviewSchedule.length &&
              this.reviewSchedule[question.reviewCycle - 1] !== previousReviewSchedule[question.reviewCycle - 1]
            ) {
              const change = this.reviewSchedule[question.reviewCycle - 1] - previousReviewSchedule[question.reviewCycle - 1]
              question.reviewAfter += change * 24 * 60 * 60 * 1000
              // Update firestoreUpdate object
              if (!firestoreUpdate[row.docId]) firestoreUpdate[row.docId] = {}
              firestoreUpdate[row.docId][row.rowId + '.questions.' + questionId] = {
                reviewAfter: question.reviewAfter,
                reviewCycle: question.reviewCycle
              }
            }
          })
        }
      })
      const batch = db.batch()
      // Update review after values in Firestore
      const updateDocIds = Object.keys(firestoreUpdate)
      if (updateDocIds.length) {
        updateDocIds.forEach(docId => {
          batch.update(db.doc('memoSets/' + this.memoSetId + '/rows/' + docId), firestoreUpdate[docId])
        })
        this.updateKnownData(batch, this.memoSetId)
      }
      // Update review schedule in Firestore
      if (this.reviewSchedule.toString() === this.reviewScheduleDefault.toString()) {
        batch.update(db.doc('memoSets/' + this.memoSetId), {
          reviewSchedule: firebase.firestore.FieldValue.delete()
        })
      } else {
        batch.update(db.doc('memoSets/' + this.memoSetId), {
          reviewSchedule: this.reviewSchedule
        })
      }
      batch.commit()
        .catch(error => { logError('MemoSet_reviewScheduleSubmit', error) })
    },
    rotateObject: function (event) {
      let centerX, centerY, img
      img = document.querySelector('.objectActive')
      centerX = (img.getBoundingClientRect().left + img.getBoundingClientRect().right) / 2
      centerY = (img.getBoundingClientRect().top + img.getBoundingClientRect().bottom) / 2
      // Calculate angle in degrees between vertical and line from center of object to mouse position
      this.objects[this.activeObject].angle = Math.atan((event.clientX - centerX) / (centerY - event.clientY)) * 180 / Math.PI
      // Add 180 degrees if mouse position is below center of object
      if (event.clientY > centerY) {
        this.objects[this.activeObject].angle += 180
      }
    },
    rowFieldBlur: function (event, fieldId, rowIndex) {
      let updateObj, valueAdded, valueDeleted
      const row = this.rows[rowIndex]
      const enteredValue = this.sanitize(event.target.innerHTML)
      const previousValue = row.data[fieldId] === undefined ? '' : row.data[fieldId]
      if (enteredValue !== '' || previousValue !== '') {
        if (enteredValue !== previousValue) {
          const batch = db.batch()
          updateObj = {}
          valueAdded = previousValue === ''
          valueDeleted = enteredValue === ''
          if (valueDeleted) {
            this.$delete(row.data, fieldId)
            updateObj[row.rowId + '.' + fieldId] = firebase.firestore.FieldValue.delete()
            // Delete row questions using the field
            if (this.checkRowQuestions(rowIndex)) {
              updateObj[row.rowId + '.questions'] = row.data.questions
            }
          } else {
            this.$set(row.data, fieldId, enteredValue)
            updateObj[row.rowId + '.' + fieldId] = row.data[fieldId]
          }
          // Update Firestore
          batch.update(db.doc('memoSets/' + this.memoSetId + '/rows/' + row.docId), updateObj)
          // If value has been added or deleted, update known data
          if (valueAdded || valueDeleted) {
            this.updateKnownData(batch, this.memoSetId)
          }
          this.writeImageKeys(batch)
          batch.commit()
            .catch(error => { logError('MemoSet_rowFieldBlur', error) })
          this.initSearch()
        }
        // Apply Mathjax if required
        const hasMath = /\$.*\$/.test(this.rows[rowIndex].data[fieldId])
        if (hasMath) {
          this.$nextTick(() => {
            if (window.MathJax) {
              window.MathJax.typeset([event.target])
            }
          })
        }
      }
      // Update element to remove any pasted HTML formatting or trailing whitespace
      event.target.innerHTML = enteredValue
      // Reset currentFieldId and currentRow to hide the toolbar
      this.currentFieldId = ''
      this.currentRow = -1
      this.setTableWrapperHeight()
      // Reset editingCell flag
      this.editingCell = false
    },
    rowFieldEnter: function (event, fieldId, rowIndex) {
      // If Shift or Ctrl is pressed, and field is not Review Group
      if ((event.ctrlKey || event.shiftKey) && fieldId !== 'reviewGroup') {
        // Add a <br> tag if Ctrl is pressed (the default action adds it if Shift is pressed)
        if (event.ctrlKey) {
          // Need to use 2 <br> tags if the cursor is at the end and the field does not already end in <br>
          if (isCaretAtEnd(event.target) && event.target.innerHTML.slice(-4) !== '<br>' && event.target.innerHTML.slice(-6) !== '<br />') {
            pasteHtmlAtCaret('<br><br>')
          } else {
            pasteHtmlAtCaret('<br>')
          }
        }
      } else {
        event.preventDefault()
        // If the next row's cell is blank
        if (rowIndex !== this.rows.length - 1 && !this.rows[rowIndex + 1].data[fieldId]) {
          // If we're on the last visible row, move down
          if (rowIndex === this.firstVisibleRow + this.visibleRowsCount - 2) {
            this.firstVisibleRow++
            this.goToRowVal = this.firstVisibleRow.toString()
          }
          // Focus on the next value
          this.$nextTick(() => {
            const nextCell = document.getElementById(fieldId + '_' + (rowIndex + 1))
            nextCell.focus()
            moveCursorToEndOfContentEditable(nextCell)
          })
        } else {
          // Blur cell
          event.target.blur()
          this.currentFieldId = ''
          this.currentRow = -1
          this.setTableWrapperHeight()
        }
      }
    },
    rowFieldFocus: function (event, fieldId, rowIndex) {
      this.currentFieldId = fieldId
      this.currentRow = rowIndex
      this.setTableWrapperHeight()
      this.lastFieldId = fieldId
      // Refresh value for Mathjaxed cells
      if (
        this.rows[rowIndex].data[fieldId] &&
        event.target.innerHTML !== this.rows[rowIndex].data[fieldId]
      ) {
        event.target.innerHTML = this.rows[rowIndex].data[fieldId]
        // If the user has clicked on a MathJax element, move cursor position to the end
        // Notes: window.getSelection().rangeCount is occasionally 0 - see https://stackoverflow.com/q/22935320
        // If the rangeCount is 1, window.getSelection().getRangeAt(0).startOffset is the caret position
        // For some reason, if the user clicks on the row number, then a MathJax element, startOffset is 3, so move the cursor to the end in that case too
        if (!window.getSelection().rangeCount || !window.getSelection().getRangeAt(0).startOffset || window.getSelection().getRangeAt(0).startOffset === 3) {
          moveCursorToEndOfContentEditable(event.target)
        }
      }
      this.currentFieldContent = event.target.innerHTML
    },
    rowFieldPaste: function (event, source, fieldIndex, rowIndex) {
      let cells, columnsAvailable, columnsPasted, pasteLines, pasteText
      this.closeToasts()
      pasteText = event.clipboardData.getData('text')
      if (!pasteText) {
        // Do nothing for Review Group cell
        if (source === 'group') return
        // Set fieldId
        if (source === 'field') {
          this.fieldId = this.fields[fieldIndex].fieldId
        }
        if (source === 'notes') {
          this.fieldId = 'notes'
        }
        // Save cell info
        this.rowIndex = rowIndex
        // Prepare for addCellImage modal
        this.initAddCellImage()
        // Extract image from clipboard
        this.cellImagePaste(event)
        if (this.addCellImage.url) {
          // Show addCellImage modal
          $('.ui.modal.addCellImage')
            .modal({
              autofocus: false,
              observeChanges: true,
              onApprove: this.addCellImageSubmit,
              onHidden: this.revertPreventBackHistory,
              onVisible: this.prepareModalHistory
            })
            .modal('show')
          this.addCellImage.autoSubmit = true
          // Prevent default paste operation
          event.preventDefault()
        }
        return
      }
      // If multiple cells have been pasted
      if (pasteText.indexOf('\n') !== -1 || pasteText.indexOf('\t') !== -1) {
        // Determine equivalent fieldIndex for Group and Notes columns
        if (source === 'group') {
          fieldIndex = this.fields.length
        }
        if (source === 'notes') {
          fieldIndex = this.fields.length
          if (this.options.useReviewGroups) fieldIndex++
        }
        // Determine columns available for pasting
        columnsAvailable = this.fields.length - fieldIndex
        // Add one for Group column if used
        if (this.options.useReviewGroups) columnsAvailable++
        // Add one for Notes column if no Background Photo, Summary Image columns
        if (!this.options.useBackgroundPhotos && !this.options.useSummaryImages) columnsAvailable++
        // Split pasted text by line
        if (pasteText.indexOf('\r\n') !== -1) {
          pasteLines = pasteText.split('\r\n')
        } else {
          pasteLines = pasteText.split('\n')
        }
        // Ignore blank lines
        pasteLines = pasteLines.filter(line => line !== '')
        // Determine number of columns pasted
        this.pasteCells.pasteData = []
        columnsPasted = 0
        // Loop through pasted lines
        for (let i = 0; i < pasteLines.length; i++) {
          cells = pasteLines[i].split('\t')
          // Handle images copied from Systems module
          cells = this.convertSystemImages(cells)
          cells = cells.map(cell => {
            // Remove initial and final quotes, if present (spreadsheets add quotes with multi)
            if (cell.substring(0, 1) === '"' && cell.slice(-1) === '"') {
              cell = cell.slice(1, -1)
            }
            // Replace any remaining line breaks with <br> tags
            return cell.replace(/\n/g, '<br>')
          })
          if (cells.length > columnsPasted) {
            columnsPasted = cells.length
          }
          this.pasteCells.pasteData.push(cells)
        }
        this.pasteCells.columns = Math.min(columnsPasted, columnsAvailable)
        this.pasteCells.rows = pasteLines.length
        // If we have no more than one text item pasted, use the default paste
        if (this.pasteCells.columns < 2 && this.pasteCells.rows < 2) {
          return
        }
        // Blur field pasted into
        event.target.blur()
        this.pasteCells.fieldIndex = fieldIndex
        this.pasteCells.rowIndex = rowIndex
        this.pasteCells.pasteText = pasteText
        // Prevent default paste operation
        event.preventDefault()
        // Show paste confirmation modal
        this.modal = 'rowFieldPasteConfirm'
        $('.ui.modal.rowFieldPasteConfirm')
          .modal({
            onHidden: this.revertPreventBackHistory,
            onHide: this.resetModal,
            onVisible: this.prepareModalHistory
          })
          .modal('show')
      }
    },
    rowFieldPasteSingle: function () {
      $('.ui.modal.rowFieldPasteConfirm').modal('hide')
      let cell, cellValue, fieldId
      // Determine fieldId
      if (this.pasteCells.fieldIndex < this.fields.length) {
        fieldId = this.fields[this.pasteCells.fieldIndex].fieldId
      } else {
        if (this.pasteCells.fieldIndex === this.fields.length && this.options.useReviewGroups) {
          fieldId = 'reviewGroup'
        } else {
          fieldId = 'notes'
        }
      }
      cell = document.getElementById(fieldId + '_' + this.pasteCells.rowIndex)
      const row = this.rows[this.pasteCells.rowIndex]
      const previousValue = row.data[fieldId] === undefined ? '' : row.data[fieldId]
      this.cellChanges.cells = [{
        fieldId: fieldId,
        rowIndex: this.pasteCells.rowIndex,
        value: previousValue
      }]
      this.cellChanges.rowQuestions = []
      this.cellChanges.rowsAdded = 0
      cellValue = this.sanitize(this.pasteCells.pasteText)
      // Replace line breaks with <br> tags
      cellValue = cellValue.replace(/\r\n/g, '<br>')
      cellValue = cellValue.replace(/\n/g, '<br>')
      // Replace tab with space
      cellValue = cellValue.replace(/\t/g, ' ')
      cell.focus()
      this.$nextTick(() => {
        cell.innerHTML = cellValue
        cell.blur()
        this.showPastedToast()
      })
    },
    rowFieldPasteMultiple: function () {
      $('.ui.modal.rowFieldPasteConfirm').modal('hide')
      let fieldId, firestoreNewDocs, firestoreUpdateDocs, firestoreValue, rowsAvailable, valueDeleted
      const batch = db.batch()
      this.cellChanges.cells = []
      this.cellChanges.rowQuestions = []
      this.cellChanges.rowsAdded = 0
      // Add rows at the bottom if required
      rowsAvailable = this.rows.length - this.pasteCells.rowIndex
      if (this.pasteCells.rows > rowsAvailable) {
        this.addRows.numberOfRows = this.pasteCells.rows - rowsAvailable
        this.addRows.insertAfterRow = ''
        this.cellChanges.rowsAdded = this.addRows.numberOfRows
        // Add rows without updating Firestore
        this.addRowsAddRows(false)
        // For convenience
        firestoreNewDocs = this.addRows.firestoreNewDocs
        firestoreUpdateDocs = this.addRows.firestoreUpdateDocs
      } else {
        // No rows to add
        firestoreNewDocs = {}
        firestoreUpdateDocs = {}
      }
      for (let i = 0; i < this.pasteCells.rows; i++) {
        const row = this.rows[this.pasteCells.rowIndex + i]
        for (let j = 0; j < this.pasteCells.columns; j++) {
          if (this.pasteCells.fieldIndex + j < this.fields.length) {
            fieldId = this.fields[this.pasteCells.fieldIndex + j].fieldId
          } else {
            if (this.pasteCells.fieldIndex + j === this.fields.length && this.options.useReviewGroups) {
              fieldId = 'reviewGroup'
            } else {
              fieldId = 'notes'
            }
          }
          const cellValue = this.sanitize(this.pasteCells.pasteData[i][j])
          const previousValue = row.data[fieldId] === undefined ? '' : row.data[fieldId]
          if (cellValue !== previousValue) {
            // If this is not for a row that has just been added
            if (this.pasteCells.rowIndex + i < this.rows.length - this.cellChanges.rowsAdded) {
              // Push the change onto cellChanges for undo
              this.cellChanges.cells.push({
                fieldId: fieldId,
                rowIndex: this.pasteCells.rowIndex + i,
                value: previousValue
              })
            }
            valueDeleted = cellValue === ''
            if (valueDeleted) {
              this.$delete(row.data, fieldId)
              firestoreValue = firebase.firestore.FieldValue.delete()
            } else {
              this.$set(row.data, fieldId, cellValue)
              firestoreValue = cellValue
            }
            // Update Firestore object
            if (firestoreNewDocs[row.docId]) {
              firestoreNewDocs[row.docId][row.rowId][fieldId] = firestoreValue
            } else {
              if (!firestoreUpdateDocs[row.docId]) firestoreUpdateDocs[row.docId] = {}
              firestoreUpdateDocs[row.docId][row.rowId + '.' + fieldId] = firestoreValue
            }
          }
        }
        // Delete any row questions with missing field values
        if (this.checkRowQuestions(this.pasteCells.rowIndex + i)) {
          // Apply questions data to firestoreNewDocs or firestoreUpdateDocs
          if (firestoreNewDocs[row.docId]) {
            firestoreNewDocs[row.docId][row.rowId + '.questions'] = row.data.questions
          } else {
            if (!firestoreUpdateDocs[row.docId]) firestoreUpdateDocs[row.docId] = {}
            firestoreUpdateDocs[row.docId][row.rowId + '.questions'] = row.data.questions
          }
        }
      }
      // Write new docs
      Object.keys(firestoreNewDocs).forEach(docId => {
        batch.set(db.doc('memoSets/' + this.memoSetId + '/rows/' + docId), firestoreNewDocs[docId])
      })
      // Write update docs
      Object.keys(firestoreUpdateDocs).forEach(docId => {
        batch.update(db.doc('memoSets/' + this.memoSetId + '/rows/' + docId), firestoreUpdateDocs[docId])
      })
      if (this.cellChanges.rowsAdded) {
        this.writeRowCount(batch)
      }
      this.updateKnownData(batch, this.memoSetId)
      batch.commit()
        .catch(error => { logError('MemoSet_rowFieldPasteMultiple', error) })
      // Apply Mathjax to pasted data
      this.applyMathjax()
      // Update search data
      this.initSearch()
      this.showPastedToast()
    },
    rowHasData: function (row, fromFieldId, toFieldId) {
      // Return boolean indicating whether the row has data for the fieldIds provided
      let rowHasData = true
      if (fromFieldId === 's') {
        if (!row.data.photoId && (!row.data.objects || !row.data.objects.length)) rowHasData = false
      } else {
        if (!row.data[fromFieldId] || !row.data[fromFieldId].replace(/<br ?\/?>/g, '')) rowHasData = false
      }
      if (toFieldId === 's') {
        if (!row.data.photoId && (!row.data.objects || !row.data.objects.length)) rowHasData = false
      } else {
        if (!row.data[toFieldId] || !row.data[toFieldId].replace(/<br ?\/?>/g, '')) rowHasData = false
      }
      return rowHasData
    },
    rowQuestionClick: function (questionId, rowIndex) {
      let cardStyle, html, prevHtml, question
      const row = this.rows[rowIndex]
      this.rowQuestion.now = this.now
      this.rowQuestion.questionId = questionId
      this.rowQuestion.rowIndex = rowIndex
      // Set reviewAfter, reviewCycle and status for the row question
      this.rowQuestion.reviewCycle = 0
      if (row.data.questions && row.data.questions[questionId]) {
        this.rowQuestion.reviewAfter = row.data.questions[questionId].reviewAfter
        this.rowQuestion.reviewCycle = row.data.questions[questionId].reviewCycle
        if (row.data.questions[questionId].reviewCycle) {
          if (row.data.questions[questionId].reviewAfter > this.rowQuestion.now) {
            this.rowQuestion.status = 'known'
          } else {
            this.rowQuestion.status = 'due'
          }
        } else {
          this.rowQuestion.status = 'unknown'
        }
      } else {
        this.rowQuestion.reviewAfter = 0
        this.rowQuestion.reviewCycle = 0
        this.rowQuestion.status = 'unknown'
      }
      this.rowQuestion.origReviewCycle = this.rowQuestion.reviewCycle
      this.rowQuestion.origStatus = this.rowQuestion.status
      // Prepare question HTML
      question = this.questions.filter(q => q.questionId === questionId)[0]
      // Replace new lines with <br>
      html = question.questionText.replace(/\r\n/g, '<br>')
      html = html.replace(/\n/g, '<br>')
      // Replace spaces at the start of a line with &nbsp; to allow indenting
      while (true) {
        prevHtml = html
        html = html.replace(/^((&nbsp;)*)\s/, '$1&nbsp;')
        html = html.replace(/(<br>(&nbsp;)*)\s/g, '$1&nbsp;')
        if (html === prevHtml) break
      }
      // If From Column is Summary Image
      if (question.fromFieldId === 's') {
        if (!this.thumbnails[rowIndex]) this.createThumbnail(rowIndex)
        html = html.replace('*', '<img src="' + this.thumbnails[rowIndex] + '">')
      } else {
        cardStyle = this.cardStyle(row.data[question.fromFieldId])
        if (cardStyle) {
          html = html.replace('*', this.questionCardHtml(cardStyle))
        } else {
          html = html.replace('*', '<strong>' + row.data[question.fromFieldId] + '</strong>')
        }
      }
      this.rowQuestion.questionHtml = html
      // Prepare answer HTML
      // If To Column is Summary Image
      if (question.toFieldId === 's') {
        if (!this.thumbnails[rowIndex]) this.createThumbnail(rowIndex)
        html = '<img src="' + this.thumbnails[rowIndex] + '">'
      } else {
        html = ''
        cardStyle = this.cardStyle(row.data[question.toFieldId])
        if (cardStyle) {
          html += this.questionCardHtml(cardStyle) + '<br>'
        } else {
          html += '<strong>' + row.data[question.toFieldId] + '</strong><br>'
        }
      }
      this.rowQuestion.answerHtml = html
      // Apply Mathjax
      this.$nextTick(() => {
        if (window.MathJax) {
          window.MathJax.typeset(['.rowQuestionQuestion', '.rowQuestionAnswer'])
        }
      })
      this.modal = 'rowQuestion'
      $('.ui.modal.rowQuestion')
        .modal({
          autofocus: false,
          onApprove: this.rowQuestionSubmit,
          onHidden: this.revertPreventBackHistory,
          onHide: this.resetModal,
          onVisible: this.prepareModalHistory
        })
        .modal('show')
    },
    rowQuestionSubmit: function () {
      const questionId = this.rowQuestion.questionId
      const row = this.rows[this.rowQuestion.rowIndex]
      const reviewCycle = this.rowQuestion.reviewCycle
      // If review cycle has changed
      if (reviewCycle !== this.rowQuestion.origReviewCycle) {
        // Add row questions property if required
        if (!row.data.questions) {
          this.$set(row.data, 'questions', {})
        }
        // Add row question if required
        if (!row.data.questions[questionId]) {
          this.$set(row.data.questions, questionId, {
            reviewAfter: 0,
            reviewCycle: 0
          })
        }
        const question = row.data.questions[questionId]
        if (reviewCycle) {
          question.reviewCycle = reviewCycle
          question.reviewAfter = Date.now() + this.durationForReviewCycle(reviewCycle)
        } else {
          this.$delete(row.data.questions, questionId)
        }
        // Update Firestore
        const batch = db.batch()
        const firestoreUpdate = {}
        if (reviewCycle) {
          firestoreUpdate[row.rowId + '.questions.' + questionId] = {
            reviewAfter: question.reviewAfter,
            reviewCycle: question.reviewCycle
          }
        } else {
          firestoreUpdate[row.rowId + '.questions.' + questionId] = firebase.firestore.FieldValue.delete()
        }
        batch.update(db.doc('memoSets/' + this.memoSetId + '/rows/' + row.docId), firestoreUpdate)
        this.updateKnownData(batch, this.memoSetId)
        batch.commit()
          .catch(error => { logError('MemoSet_rowQuestionSubmit', error) })
      }
    },
    sanitize: function (html) {
      if (!html) return ''
      // Return cached value if present
      if (this.cachedSanitize[html]) return this.cachedSanitize[html]
      let sanitizedHtml
      // Attempt to replace bold text in span with <strong> tag
      let bolderPos = html.indexOf('<span style="font-weight: bolder;">')
      while (bolderPos !== -1) {
        const nextSpan = html.indexOf('<span', bolderPos + 1)
        const nextEndSpan = html.indexOf('</span>', bolderPos + 1)
        // Do nothing if there's no end span, or there's a nested span
        if (
          nextEndSpan === -1 ||
          (nextSpan !== -1 && nextSpan < nextEndSpan)
        ) {
          break
        }
        html = html.slice(0, nextEndSpan) + '</strong>' + html.slice(nextEndSpan + 7)
        html = html.replace('<span style="font-weight: bolder;">', '<strong>')
        bolderPos = html.indexOf('<span style="font-weight: bolder;">')
      }
      sanitizedHtml = this.$sanitize(
        html,
        {
          allowedAttributes: {
            audio: ['id', 'src'],
            button: ['class', 'contenteditable', 'id'],
            i: ['class'],
            img: ['class', 'src']
          },
          allowedTags: ['audio', 'b', 'br', 'button', 'div', 'i', 'img', 'span', 'strong'],
          allowedSchemesByTag: {
            img: ['data', 'http', 'https']
          }
        }
      )
      // Remove unnecessary wrapper div
      if (sanitizedHtml.substring(0, 5) === '<div>' && sanitizedHtml.slice(-6) === '</div>') {
        // Note - may have <div>a</div><div>b</div>
        const nextDivPos = sanitizedHtml.indexOf('<div', 5)
        const nextEndDivPos = sanitizedHtml.indexOf('</div', 5)
        if (nextDivPos === -1 || nextDivPos < nextEndDivPos) {
          sanitizedHtml = sanitizedHtml.slice(5, -6)
        }
      }
      // Remove empty divs
      sanitizedHtml = sanitizedHtml.replace(/<div><\/div>/g, '')
      // Replace non-breaking spaces with spaces
      const nonBreakingSpace = ' '
      const regex = new RegExp(nonBreakingSpace, 'g')
      sanitizedHtml = sanitizedHtml.replace(/&nbsp;/g, ' ').replace(regex, ' ')
      // Replace <br /> with <br>
      sanitizedHtml = sanitizedHtml.replace(/<br \/>/g, '<br>')
      // Remove trailing whitespace
      sanitizedHtml = sanitizedHtml.replace(/\s+$/, '')
      // Remove trailing <div><br></div>
      sanitizedHtml = sanitizedHtml.replace(/(<div>(<br>)*<\/div>\s*)+$/, '')
      // Remove trailing <br> or <br /> tags
      sanitizedHtml = sanitizedHtml.replace(/(<br>\s*)+$/, '')
      // Enable audio play buttons
      sanitizedHtml = this.enableAudio(sanitizedHtml)
      this.cachedSanitize[html] = sanitizedHtml
      return sanitizedHtml
    },
    sanitizeTextOnly: function (html) {
      let sanitizedHtml
      sanitizedHtml = this.$sanitize(html, {
        allowedTags: []
      })
      return sanitizedHtml
    },
    sanitizeWithStyle: function (html) {
      // Return cached value if present
      if (this.cachedSanitizeWithStyle[html]) return this.cachedSanitizeWithStyle[html]
      let sanitizedHtml
      if (!html) return ''
      sanitizedHtml = this.$sanitize(
        html,
        {
          allowedAttributes: {
            audio: ['autoplay', 'id', 'src'],
            button: ['class', 'contenteditable', 'id'],
            div: ['class', 'style'],
            i: ['class'],
            img: ['class', 'src'],
            span: ['class', 'style']
          },
          allowedTags: ['audio', 'b', 'br', 'button', 'div', 'i', 'img', 'p', 'span', 'strong'],
          allowedSchemesByTag: {
            img: ['data', 'http', 'https']
          }
        }
      )
      // Remove empty divs
      sanitizedHtml = sanitizedHtml.replace(/<div><\/div>/g, '')
      // Remove trailing <br> or <br /> tags
      sanitizedHtml = sanitizedHtml.replace(/<br \/>/g, '<br>').replace(/(<br>\s*)+$/, '')
      // Remove trailing whitespace
      sanitizedHtml = sanitizedHtml.replace(/\s+$/, '')
      // Enable audio play buttons
      sanitizedHtml = this.enableAudio(sanitizedHtml)
      this.cachedSanitizeWithStyle[html] = sanitizedHtml
      return sanitizedHtml
    },
    saveLearnOptions: function () {
      let updateObj
      updateObj = {
        selectedQuestionIds: this.learn.questionIds,
        selectedReviewGroupIds: this.learn.reviewGroupIds
      }
      if (!this.options.useReviewGroups) {
        updateObj.selectedReviewGroupIds = firebase.firestore.FieldValue.delete()
      }
      db.doc('memoSets/' + this.memoSetId)
        .update(updateObj)
        .catch(error => { logError('MemoSet_saveLearnOptions', error) })
    },
    saveTableWrapperScrollPos: function () {
      const tableWrapper = document.getElementById('tableWrapper')
      if (tableWrapper) {
        this.saveTableWrapperScrollLeft = tableWrapper.scrollLeft
        this.saveTableWrapperScrollTop = tableWrapper.scrollTop
      }
    },
    scrollToTopLeft: function () {
      const tableWrapper = document.getElementById('tableWrapper')
      tableWrapper.scrollLeft = 0
      tableWrapper.scrollTop = 0
    },
    selectAllQuestions: function (select) {
      const checkboxAction = select ? 'set checked' : 'set unchecked'
      this.questions.forEach(question => {
        $('#learnQuestion_' + question.questionId).checkbox(checkboxAction)
      })
      this.setSelectedQuestions()
    },
    selectAllReviewGroups: function (select) {
      const checkboxAction = select ? 'set checked' : 'set unchecked'
      this.reviewGroupsList.forEach((reviewGroup, reviewGroupIndex) => {
        $('#learnReviewGroup_' + reviewGroupIndex).checkbox(checkboxAction)
      })
      this.setSelectedReviewGroups()
    },
    sendToBackClick: function (event) {
      let obj, undoEntry
      // Blur button
      event.target.blur()
      if (this.mode === 'clip') this.endClipMode()
      if (this.mode === 'draw') this.endDrawMode()
      if (this.mode === 'remove') this.endRemoveMode()
      // Prepare undo entry
      obj = this.objects[this.activeObject]
      undoEntry = {
        before: { sequence: this.activeObject },
        changeType: 'send to back'
      }
      this.addSummaryImageUndoEntry(undoEntry)
      // Move active object to bottom
      obj = this.objects.splice(this.activeObject, 1)[0]
      this.objects.unshift(obj)
      this.activeObject = -1
      this.setLocationZIndex()
    },
    setActiveObjectOverlap: function () {
      // Allow time for DOM to render
      this.$nextTick(() => {
        let obj1, objRect1, objRect2
        obj1 = document.getElementById('summaryImageObj_' + this.activeObject)
        if (obj1) {
          objRect1 = document.getElementById('summaryImageObj_' + this.activeObject).getBoundingClientRect()
          // Return true if any other objects overlap the active object
          this.activeObjectOverlap = this.objects.some((obj, objIndex) => {
            objRect2 = document.getElementById('summaryImageObj_' + objIndex).getBoundingClientRect()
            return objIndex !== this.activeObject &&
              !(
                objRect2.right < objRect1.left ||
                objRect2.left > objRect1.right ||
                objRect2.bottom < objRect1.top ||
                objRect2.top > objRect1.bottom
              )
          })
        }
      })
    },
    setCoverImageFileChange: function (file) {
      if (file) {
        // Check that file is an image
        if (isImageFile(file)) {
          // Show image in the modal
          this.setCoverImage.url = window.URL.createObjectURL(file)
          this.setCoverImage.file = file
        } else {
          this.initSetCoverImage()
          alert(this.text.memoSetSelectImageFile)
        }
      }
    },
    setCoverImagePhotoClick: function (photoIndex) {
      this.setCoverImage.url = this.photos[photoIndex].url
    },
    setDataHeight: function () {
      // Exit if no walkthroughData element
      if (!document.getElementById('walkthroughData')) return
      if (this.walkthrough.steps[this.walkthrough.stepNum].summary) {
        this.dataHeight = 0
      } else {
        this.dataHeight = document.getElementById('walkthroughData').offsetHeight
      }
    },
    setGroupColorClick: function (rowIndex) {
      const reviewGroup = this.rows[rowIndex].data.reviewGroup
      if (reviewGroup) {
        this.setGroupColor.reviewGroup = reviewGroup
        this.setGroupColor.color = this.reviewGroupColors[reviewGroup]
        this.modal = 'setGroupColor'
        $('.ui.modal.setGroupColor')
          .modal({
            onApprove: this.setGroupColorSubmit,
            onHidden: () => {
              this.modal = ''
              this.revertPreventBackHistory()
            },
            onVisible: this.prepareModalHistory
          })
          .modal('show')
      }
    },
    setGroupColorEnter: function () {
      // Hide modal
      $('.ui.modal.setGroupColor').modal('hide')
      // Submit form
      this.setGroupColorSubmit()
    },
    setGroupColorSubmit: function () {
      // Find previous color for the label, if any
      const index = this.groupColors.findIndex(item => item.label === this.setGroupColor.reviewGroup)
      if (index !== -1) {
        const previousColor = this.groupColors[index].color
        // Do nothing if the color hasn't changed
        if (this.setGroupColor.color === previousColor) return
        this.groupColors[index].color = this.setGroupColor.color
        this.groupColors[index].label = this.setGroupColor.reviewGroup
      } else {
        this.groupColors.push({
          color: this.setGroupColor.color,
          label: this.setGroupColor.reviewGroup
        })
      }
      // Update Firestore
      db.doc('memoSets/' + this.memoSetId)
        .update({
          groupColors: this.groupColors
        })
        .catch(error => { logError('MemoSet_setGroupColorSubmit', error) })
    },
    setImageDataUrlForBlob: function (blob) {
      // NOTE: THIS FUNCTION IS NOT CURRENTLY USED, BUT IT CONTAINS THE CODE REQUIRED TO CONVERT A BLOB/FILE IMAGE INTO A DATA URL.
      let blobUrl, img
      blobUrl = window.URL.createObjectURL(blob)
      // Convert the image being added (which uses a blob URL) to a data URL via canvas
      img = new Image()
      img.crossOrigin = 'Anonymous'
      img.onload = () => {
        let canvas, ctx, scaleFactor
        canvas = document.createElement('canvas')
        // Scale image down if required
        scaleFactor = Math.min(maxImageSize / img.naturalWidth, maxImageSize / img.naturalHeight, 1)
        canvas.height = img.naturalHeight * scaleFactor
        canvas.width = img.naturalWidth * scaleFactor
        ctx = canvas.getContext('2d')
        // Fill with white in case of transparent images
        ctx.fillStyle = 'white'
        ctx.fillRect(0, 0, canvas.width, canvas.height)
        ctx.drawImage(img, 0, 0, canvas.width, canvas.height)
        this.addImage.url = canvas.toDataURL('image/jpeg')
        // Revoke the blob URL
        window.URL.revokeObjectURL(blobUrl)
      }
      img.src = blobUrl
    },
    setInfoWrapperHeight: function () {
      this.$nextTick(() => {
        let height
        height = this.window.height - 155
        // Increase height slightly if mobile
        if (this.mobile) height += 17
        // Allow space for mobile toolbar
        if (this.mobile && this.touch && (this.editingTitle || this.editingDescription)) {
          height -= 45
        }
        // Ensure table height is not negative
        if (height < 0) height = 0
        this.infoWrapperMaxHeight = height
      })
    },
    setLearnEnabled: function (value) {
      const now = Date.now()
      if (value) {
        this.learnEnabled = true
      } else if (value === false) {
        this.learnEnabled = false
      } else {
        // Set to true if there are unknown or due questions
        this.learnEnabled = this.activeQuestions.filter(q => !q.reviewCycle || q.reviewAfter <= now).length
      }
    },
    setLearnItemReady: function (item) {
      // Find the item's index
      const index = this.learn.reviewQueue.findIndex(item2 => item2.questionId === item.questionId && item2.rowIndex === item.rowIndex)
      item.ready = true
      // Make it reactive
      this.$set(this.learn.reviewQueue, index, this.learn.reviewQueue[index])
      if (this.modal === 'learnTest') {
        if (index === 0) {
          this.startTimerOnNextTick()
        }
      }
      if (this.modal === 'learnGame') {
        if (index === 0) {
          this.startFall()
        }
      }
    },
    setLearnWrapperHeights: function () {
      this.$nextTick(() => {
        let questionsHeight, reviewGroupsHeight, totalHeight
        totalHeight = this.window.height - 320
        // Reduce height slightly if mobile
        if (this.mobile) totalHeight -= 40
        // Ensure total height is not negative
        if (totalHeight < 0) totalHeight = 0
        if (this.mobile) {
          questionsHeight = totalHeight
          // If using review groups
          if (this.options.useReviewGroups) {
            // Allocate height between questions and review groups
            questionsHeight = totalHeight * (this.questions.length / (this.questions.length + this.reviewGroupsList.length))
            if (questionsHeight < totalHeight * 0.35) questionsHeight = totalHeight * 0.35
            if (questionsHeight > totalHeight * 0.65) questionsHeight = totalHeight * 0.65
            questionsHeight = Math.round(questionsHeight)
          }
          reviewGroupsHeight = totalHeight - questionsHeight
        } else {
          questionsHeight = totalHeight
          reviewGroupsHeight = totalHeight
        }
        this.learnWrapperMaxHeights = [questionsHeight, reviewGroupsHeight]
      })
    },
    setLocationZIndex: function () {
      // Wait for objects and location to render, then trigger locationZIndex computed function
      this.$nextTick(() => {
        this.locationZIndexTrigger++
      })
    },
    setPageTitle: function () {
      document.title = this.title + ' - MemQ'
    },
    setQuestionsWrapperHeight: function () {
      this.$nextTick(() => {
        let height
        height = this.window.height - 230
        // Increase height slightly if mobile
        if (this.mobile) height += 17
        // Ensure table height is not negative
        if (height < 0) height = 0
        this.questionsWrapperMaxHeight = height
      })
    },
    setSelectedQuestions: function () {
      this.learn.questionIds = []
      this.questions.forEach(question => {
        if ($('#learnQuestion_' + question.questionId).checkbox('is checked')) {
          this.learn.questionIds.push(question.questionId)
        }
      })
      this.prepareActiveQuestions()
      this.setStatuses('fast')
    },
    setSelectedReviewGroups: function () {
      this.learn.reviewGroupIds = []
      this.reviewGroupsList.forEach((reviewGroup, reviewGroupIndex) => {
        if ($('#learnReviewGroup_' + reviewGroupIndex).checkbox('is checked')) {
          this.learn.reviewGroupIds.push(reviewGroupIndex.toString())
        }
      })
      this.prepareActiveQuestions()
      this.setStatuses('fast')
    },
    setStatuses: function (speed) {
      let due, known, unknown
      const now = Date.now()
      known = this.activeQuestions.filter(question => question.reviewCycle && question.reviewAfter > now).length
      due = this.activeQuestions.filter(question => question.reviewCycle && question.reviewAfter <= now).length
      unknown = this.activeQuestions.filter(question => !question.reviewCycle).length
      this.statuses = {
        due: due,
        known: known,
        unknown: unknown,
        speed: speed
      }
      // Set learnEnabled according to whether there are any due or unknown questions
      this.setLearnEnabled(!!(due + unknown))
    },
    setTableWrapperHeight: function () {
      this.$nextTick(() => {
        let height, tableTop
        const tableWrapper = document.getElementById('tableWrapper')
        if (tableWrapper) {
          tableTop = tableWrapper.getBoundingClientRect().top
          height = this.window.height - tableTop - 4
          // Reduce height slightly if not mobile
          if (!this.mobile) height -= 12
          // Allow space for mobile toolbar
          if (this.mobile && this.touch && (this.editingHeading || this.currentRow !== -1)) {
            height -= 45
          }
          // Ensure table height is not negative
          if (height < 0) height = 0
          this.tableWrapperMaxHeight = height
        }
      })
    },
    setTableWrapperScrollLeft: function () {
      if (document.getElementById('tableWrapper')) {
        this.tableWrapperScrollLeft = document.getElementById('tableWrapper').scrollLeft
      }
    },
    shareMemoSetClick: function () {
      document.getElementById('shareMemoSetButton').blur()
      this.closeToasts()
      // Reset form fields
      this.share.public = false
      $('#sharePublic').checkbox('uncheck')
      this.share.specific = false
      $('#shareSpecific').checkbox('uncheck')
      this.share.username = ''
      this.share.noUser = false
      this.share.shareSelf = false
      this.share.showPublicCheckbox = !this.sharing.public
      // Show modal
      this.modal = 'shareMemoSet'
      $('.ui.modal.shareMemoSet')
        .modal({
          onApprove: this.shareMemoSetSubmit,
          onHidden: this.revertPreventBackHistory,
          onHide: this.resetModal,
          onVisible: this.prepareModalHistory
        })
        .modal('show')
    },
    shareMemoSetEnter: function () {
      // Exit if form is incomplete
      if (!(this.share.showPublicCheckbox && this.share.public) && !(this.share.specific && this.share.username)) return
      // Submit form
      this.shareMemoSetSubmit()
    },
    shareMemoSetSpecificClick: function () {
      this.share.specific = !this.share.specific
      if (!this.share.specific) {
        this.share.username = ''
      }
    },
    shareMemoSetSubmit: function () {
      const self = this
      let removeGetUserListener
      if (this.share.specific && this.share.username) {
        if (this.share.username.toLowerCase() === this.user.displayName.toLowerCase()) {
          self.share.shareSelf = true
        } else {
        // Write a request to get user details
          db.collection('getUser')
            .add({
              searchFor: this.share.username,
              timestamp: firebase.firestore.FieldValue.serverTimestamp(),
              userId: this.user.id
            })
            .then(docRef => {
              // Listen for the results
              removeGetUserListener = docRef.onSnapshot(doc => {
                if (doc.exists && doc.data().done) {
                  // Remove the listener
                  removeGetUserListener()
                  // Delete the getUser document
                  docRef.delete()
                  self.share.checkingUser = false
                  self.share.userId = doc.data().returnUserId
                  if (self.share.userId) {
                    if (self.share.userId === this.user.id) {
                      self.share.shareSelf = true
                    } else {
                      const batch = db.batch()
                      self.$set(self.sharing, self.share.userId, doc.data().returnDisplayName)
                      self.writeSharedSummary(batch, self.share.userId)
                      if (this.share.showPublicCheckbox && this.share.public) {
                        this.$set(this.sharing, 'public', true)
                        this.writeSharedSummary(batch, 'public')
                      }
                      // Update sharing on memoSets document
                      batch.update(db.doc('memoSets/' + this.memoSetId), {
                        sharing: this.sharing
                      })
                      // Update sharing on rows documents
                      const uniqueDocIds = [...new Set(this.rows.map(row => row.docId))]
                      uniqueDocIds.forEach(docId => {
                        batch.update(db.doc('memoSets/' + this.memoSetId + '/rows/' + docId), {
                          sharing: this.sharing
                        })
                      })
                      // Update isPublic at header level
                      if (!this.isPublic && this.share.showPublicCheckbox && this.share.public) {
                        let updateObj = {}
                        updateObj[this.memoSetId + '.isPublic'] = true
                        batch.update(db.doc('users/' + this.user.id + '/memoSets/' + this.docId), updateObj)
                      }
                      batch.commit()
                        .catch(error => { logError('MemoSet_shareMemoSetSubmit_1', error) })
                      // Hide modal
                      $('.ui.modal.shareMemoSet').modal('hide')
                      self.showMemoSetSharedToast()
                    }
                  } else {
                    self.share.noUser = true
                  }
                }
              })
            })
            .catch(error => { logError('MemoSet_shareMemoSetSubmit_2', error) })
          // Set flag to indicate that we're waiting
          this.share.checkingUser = true
        }
        // Return false to prevent the modal from automatically closing
        // The modal is closed manually above once we have received the getUser response
        return false
      } else {
        if (this.share.showPublicCheckbox && this.share.public) {
          const batch = db.batch()
          this.$set(this.sharing, 'public', true)
          this.writeSharedSummary(batch, 'public')
          // Update sharing on memoSets document
          batch.update(db.doc('memoSets/' + this.memoSetId), {
            sharing: this.sharing
          })
          // Update sharing on rows documents
          const uniqueDocIds = [...new Set(this.rows.map(row => row.docId))]
          uniqueDocIds.forEach(docId => {
            batch.update(db.doc('memoSets/' + this.memoSetId + '/rows/' + docId), {
              sharing: this.sharing
            })
          })
          // Update isPublic at header level
          let updateObj = {}
          updateObj[this.memoSetId + '.isPublic'] = true
          batch.update(db.doc('users/' + this.user.id + '/memoSets/' + this.docId), updateObj)
          batch.commit()
            .catch(error => { logError('MemoSet_shareMemoSetSubmit_3', error) })
        }
      }
      // Hide modal manually - needed when the user pressed Enter to submit
      $('.ui.modal.shareMemoSet').modal('hide')
    },
    shareMemoSetUsernameFocus: function () {
      $('#shareSpecific').checkbox('check')
      this.share.specific = true
    },
    showAnswerClick: function () {
      // Do nothing if the question isn't ready
      if (!this.learn.reviewQueue[0].ready) return
      this.learn.answerVisible = true
      this.learn.previousReviewItem = Object.assign({}, this.learn.reviewQueue[0])
      // Scroll to answer
      this.$nextTick(() => {
        let learnContent = document.querySelector('.learnContent')
        learnContent.scroll({
          behavior: 'smooth',
          top: learnContent.scrollHeight
        })
      })
    },
    showCopiedDownToast: function () {
      this.closeToasts()
      this.$nextTick(() => {
        // Show toast
        $('body')
          .toast({
            closeIcon: true,
            compact: false,
            message: this.text.memoSetDataCopied,
            newestOnTop: true,
            showIcon: 'green check',
            showProgress: 'bottom',
            displayTime: 10000,
            classActions: 'basic left',
            actions: [{
              text: '<i class="undo alternate icon"></i> Undo',
              class: 'primary toastIndent',
              click: this.undoCellChanges
            }]
          })
      })
    },
    showCouldNotOpenToast: function () {
      this.closeToasts()
      this.$nextTick(() => {
        // Show toast
        $('body')
          .toast({
            closeIcon: true,
            compact: false,
            class: 'error couldNotOpen',
            message: this.text.memoSetCouldNotOpen,
            newestOnTop: true,
            showIcon: 'exclamation triangle',
            showProgress: 'bottom',
            displayTime: 10000
          })
      })
    },
    showDataClick: function (event) {
      // Blur button
      if (event) event.target.blur()
      // Toggle data visibility
      this.dataVisible = !this.dataVisible
      this.setDataHeight()
      this.animateTransition = false
      this.animateTransitionFast = true
      setTimeout(() => {
        this.animateTransitionFast = false
      }, 300)
      this.photoTransition = false
    },
    showDeletedToast: function () {
      this.closeToasts()
      this.$nextTick(() => {
        // Show toast
        $('body')
          .toast({
            closeIcon: true,
            compact: false,
            message: this.text.memoSetDataDeleted,
            newestOnTop: true,
            showIcon: 'green check',
            showProgress: 'bottom',
            displayTime: 10000,
            classActions: 'basic left',
            actions: [{
              text: '<i class="undo alternate icon"></i> Undo',
              class: 'primary toastIndent',
              click: this.undoCellChanges
            }]
          })
      })
    },
    showLearnModal: function () {
      this.learn.answerVisible = false
      this.learn.previousReviewItem = null
      // Show learn modal
      $('.ui.modal.learn')
        .modal({
          autofocus: false,
          closable: false,
          onHidden: () => {
            this.modal = ''
            // Remove review queue to prevent any audio from continuing to play
            this.learn.reviewQueue = []
            this.revertPreventBackHistory()
            // Update status bar after a delay
            setTimeout(() => {
              this.setStatuses('slow')
            }, 500)
            // Update learning data in Firestore
            this.updateLearnData(true)
          },
          onHide: () => {
            this.setLearnEnabled()
          },
          onVisible: () => {
            this.prepareModalHistory()
          }
        })
        .modal('show')
    },
    showLearnGameModal: function () {
      this.learn.answerVisible = false
      this.learn.previousReviewItem = null
      // Show learnGame modal
      $('.ui.modal.learnGame')
        .modal({
          autofocus: false,
          closable: false,
          onHidden: () => {
            this.modal = ''
            this.learn.gaming = false
            // Remove review queue to prevent any audio from continuing to play
            this.learn.reviewQueue = []
            this.revertPreventBackHistory()
            // Update status bar after a delay
            setTimeout(() => {
              this.setStatuses('slow')
            }, 500)
            // Update learning data in Firestore
            this.updateLearnData(true)
          },
          onHide: () => {
            this.setLearnEnabled()
          },
          onVisible: () => {
            this.prepareModalHistory()
          }
        })
        .modal('show')
    },
    showLearnTestModal: function () {
      this.learn.answerVisible = false
      this.learn.previousReviewItem = null
      // Show learnTest modal
      $('.ui.modal.learnTest')
        .modal({
          autofocus: false,
          closable: false,
          onHidden: () => {
            this.modal = ''
            this.learn.testing = false
            // Remove review queue to prevent any audio from continuing to play
            this.learn.reviewQueue = []
            this.revertPreventBackHistory()
            // Update status bar after a delay
            setTimeout(() => {
              this.setStatuses('slow')
            }, 500)
            // Update learning data in Firestore
            this.updateLearnData(true)
          },
          onHide: () => {
            this.setLearnEnabled()
          },
          onVisible: () => {
            this.prepareModalHistory()
          }
        })
        .modal('show')
    },
    showMemoSetAddedToast: function () {
      this.closeToasts()
      this.$nextTick(() => {
        // Show toast
        $('body')
          .toast({
            closeIcon: true,
            compact: false,
            title: this.text.memoSetMemoSetAdded,
            message: this.memoSetCreatedInFolderMessage,
            newestOnTop: true,
            showIcon: 'green check',
            showProgress: 'bottom',
            displayTime: 0,
            classActions: 'basic left',
            actions: [{
              text: this.text.memoSetGoToMemoSet,
              class: 'primary toastButton toastButtonLeft',
              click: this.goToNewCopy
            }, {
              text: this.text.memoSetStay,
              class: 'toastButton toastButtonRight'
            }]
          })
      })
    },
    showMemoSetCopiedToast: function () {
      this.closeToasts()
      this.$nextTick(() => {
        // Show toast
        $('body')
          .toast({
            closeIcon: true,
            compact: false,
            title: this.text.memoSetMemoSetCopied,
            message: this.memoSetCreatedInFolderMessage,
            newestOnTop: true,
            showIcon: 'green check',
            showProgress: 'bottom',
            displayTime: 0,
            classActions: 'basic left',
            actions: [{
              text: this.text.memoSetGoToNewCopy,
              class: 'primary toastButton toastButtonLeft',
              click: this.goToNewCopy
            }, {
              text: this.text.memoSetStay,
              class: 'toastButton toastButtonRight'
            }]
          })
      })
    },
    showMemoSetSharedToast: function () {
      this.closeToasts()
      this.$nextTick(() => {
        // Show toast
        $('body')
          .toast({
            closeIcon: true,
            compact: false,
            title: this.text.memoSetMemoSetShared,
            message: this.text.memoSetMemoSetSharedWithMessage,
            newestOnTop: true,
            showIcon: 'green check',
            showProgress: 'bottom',
            displayTime: 10000,
            classActions: 'basic left',
            actions: [{
              text: '<i class="undo alternate icon"></i> Undo',
              class: 'primary toastIndent',
              click: this.undoShareMemoSet
            }]
          })
      })
    },
    showObjectsClick: function (event) {
      // Blur button
      if (event) event.target.blur()
      // Toggle objects visibility
      this.objectsVisible = !this.objectsVisible
    },
    showPastedToast: function () {
      this.closeToasts()
      this.$nextTick(() => {
        // Show toast
        $('body')
          .toast({
            closeIcon: true,
            compact: false,
            message: this.text.memoSetDataPasted,
            newestOnTop: true,
            showIcon: 'green check',
            showProgress: 'bottom',
            displayTime: 10000,
            classActions: 'basic left',
            actions: [{
              text: '<i class="undo alternate icon"></i> Undo',
              class: 'primary toastIndent',
              click: this.undoCellChanges
            }]
          })
      })
    },
    showPopulateColumnToast: function () {
      this.closeToasts()
      this.$nextTick(() => {
        // Show toast
        $('body')
          .toast({
            closeIcon: true,
            compact: false,
            message: this.text.memoSetColumnPopulated,
            newestOnTop: true,
            showIcon: 'green check',
            showProgress: 'bottom',
            displayTime: 10000,
            classActions: 'basic left',
            actions: [{
              text: '<i class="undo alternate icon"></i> Undo',
              class: 'primary toastIndent',
              click: this.undoCellChanges
            }]
          })
      })
    },
    sortPhotos: function () {
      let aFirstRow, bFirstRow
      // Sort the photos array according to order used within the memo set
      this.photos.sort((a, b) => {
        aFirstRow = this.photoUsage[a.id] ? this.photoUsage[a.id].firstRowIndex : 999999
        bFirstRow = this.photoUsage[b.id] ? this.photoUsage[b.id].firstRowIndex : 999999
        return aFirstRow - bFirstRow
      })
    },
    sortQuestions: function () {
      // Sort questions by from column index, to column index
      this.questions.sort((a, b) => {
        if (this.fieldsById[a.fromFieldId].fieldIndex < this.fieldsById[b.fromFieldId].fieldIndex) return -1
        if (this.fieldsById[a.fromFieldId].fieldIndex > this.fieldsById[b.fromFieldId].fieldIndex) return 1
        if (this.fieldsById[a.toFieldId].fieldIndex < this.fieldsById[b.toFieldId].fieldIndex) return -1
        if (this.fieldsById[a.toFieldId].fieldIndex > this.fieldsById[b.toFieldId].fieldIndex) return 1
        return 1
      })
    },
    sortReviewQueue: function (initialSort) {
      let preservedCount, preservedItems
      const now = Date.now()
      const reviewQueue = this.learn.reviewQueue
      preservedCount = 0
      preservedItems = []
      // Give an initial shuffle
      if (initialSort) {
        shuffle(reviewQueue)
      }
      if (!initialSort) {
        // Preserve up to learnPreloadCount items
        preservedCount = Math.min(learnPreloadCount, reviewQueue.length)
        preservedItems = reviewQueue.splice(0, preservedCount)
      }
      // Sort active questions by priority
      reviewQueue.sort((a, b) => {
        // Sort unknown items before known items
        if ((!a.reviewCycle || a.reviewAfter <= now) && (b.reviewCycle && b.reviewAfter > now)) return -1
        if ((a.reviewCycle && a.reviewAfter > now) && (!b.reviewCycle || b.reviewAfter <= now)) return 1
        // If both items are known
        if (a.reviewCycle && a.reviewAfter > now && b.reviewCycle && b.reviewAfter > now) {
          // Sort by reviewAfter
          if (a.reviewAfter < b.reviewAfter) return -1
          if (a.reviewAfter > b.reviewAfter) return 1
          return 0
        }
        // If both items are unknown
        if ((!a.reviewCycle || a.reviewAfter <= now) && (!b.reviewCycle || b.reviewAfter <= now)) {
          // Sort higher review cycle first
          if (a.reviewCycle > b.reviewCycle) return -1
          if (a.reviewCycle < b.reviewCycle) return 1
          // If only one is due for review, sort it first
          if (a.reviewAfter && a.reviewAfter <= now && (!b.reviewAfter || b.reviewAfter > now)) return -1
          if (b.reviewAfter && b.reviewAfter <= now && (!a.reviewAfter || a.reviewAfter > now)) return 1
          return 0
        }
      })
      // Prepend the preserved items
      reviewQueue.splice(0, 0, ...preservedItems)
      // If there are no unknown items in the first preservedCount + 1 items, everything must be known
      const firstItems = reviewQueue.slice(0, preservedCount + 1)
      if (firstItems.every(item => item.reviewCycle && item.reviewAfter > now)) {
        this.learnShowCongratulations()
      }
    },
    sortReviewQueueForGame: function (initialSort) {
      let preservedCount, preservedItems, reviewQueue
      reviewQueue = this.learn.reviewQueue
      preservedCount = 0
      preservedItems = []
      if (!initialSort) {
        // Preserve up to learnPreloadCount items
        preservedCount = Math.min(learnPreloadCount, reviewQueue.length)
        preservedItems = reviewQueue.splice(0, preservedCount)
      }
      // Sort review queue randomly
      shuffle(reviewQueue)
      // Prepend the preserved items
      reviewQueue.splice(0, 0, ...preservedItems)
    },
    sortReviewQueueForTest: function () {
      const reviewQueue = this.learn.reviewQueue
      if (this.learn.testRandom) {
        // Sort review queue randomly
        shuffle(reviewQueue)
      } else {
        // Sort review queue by question, row
        reviewQueue.sort((a, b) => {
          const questionIndexA = this.questions.findIndex(q => q.questionId === a.questionId)
          const questionIndexB = this.questions.findIndex(q => q.questionId === b.questionId)
          if (questionIndexA < questionIndexB) return -1
          if (questionIndexA > questionIndexB) return 1
          if (a.rowIndex < b.rowIndex) return -1
          if (a.rowIndex > b.rowIndex) return 1
          return 0
        })
      }
    },
    sortRowsClick: function () {
      let fieldIndex
      // Hide Actions popup
      $('.actionsButton').popup('hide')
      this.$nextTick(() => {
        // Prepare Sort By Column dropdown
        // Set column to the last column touched, if there was one
        fieldIndex = this.fields.findIndex(e => e.fieldId === this.lastFieldId)
        if (fieldIndex !== -1) {
          this.sortRows.sortByColumn = fieldIndex.toString()
        } else {
          // If only one column, use that
          if (this.fields.length === 1) {
            this.sortRows.sortByColumn = '0'
          }
        }
        $('.sortRowsSortByColumn')
          .dropdown({
            onChange: value => {
              this.sortRows.sortByColumn = value
            },
            showOnFocus: false
          })
          .dropdown('set selected', this.sortRows.sortByColumn)
        // Initialize sort direction
        this.sortRows.sortDirection = 'a'
        this.modal = 'sortRows'
        $('.ui.modal.sortRows')
          .modal({
            onApprove: this.sortRowsSubmit,
            onHidden: this.revertPreventBackHistory,
            onHide: this.resetModal,
            onVisible: this.prepareModalHistory
          })
          .modal('show')
      })
    },
    sortRowsEnter: function () {
      // Exit if form is incomplete
      if (this.sortRows.sortByColumn === '-1') return
      // Hide modal
      $('.ui.modal.sortRows').modal('hide')
      // Submit form
      this.sortRowsSubmit()
    },
    sortRowsSetDirection: function (direction) {
      this.sortRows.sortDirection = direction
    },
    sortRowsSubmit: function () {
      let directionFactor, fieldId, firestoreUpdateDocs
      directionFactor = this.sortRows.sortDirection === 'a' ? 1 : -1
      if (this.sortRows.sortByColumn === 'reviewGroup' || this.sortRows.sortByColumn === 'notes') {
        fieldId = this.sortRows.sortByColumn
      } else {
        fieldId = this.fields[parseInt(this.sortRows.sortByColumn, 10)].fieldId
      }
      // Determine whether data is numeric
      const { sortMode, staticText } = this.sortValues(this.rows.map(row => row.data[fieldId]))
      if (sortMode === 'Num') {
        this.rows.sort((a, b) => {
          let aVal = a.data[fieldId] ? a.data[fieldId].replace(/<br ?\/?>/g, '').replace(staticText, '') : ''
          let bVal = b.data[fieldId] ? b.data[fieldId].replace(/<br ?\/?>/g, '').replace(staticText, '') : ''
          // Sort blanks to the bottom
          if (aVal === '' && bVal === '') return 0
          if (aVal !== '' && bVal === '') return -1
          if (aVal === '' && bVal !== '') return 1
          if (parseFloat(aVal) === parseFloat(bVal)) return 0
          if (parseFloat(aVal) > parseFloat(bVal)) return 1 * directionFactor
          return -1 * directionFactor
        })
      } else {
        this.rows.sort((a, b) => {
          let aVal = a.data[fieldId] ? a.data[fieldId].replace(/<br ?\/?>/g, '') : ''
          let bVal = b.data[fieldId] ? b.data[fieldId].replace(/<br ?\/?>/g, '') : ''
          // Sort blanks to the bottom
          if (!aVal && !bVal) return 0
          if (aVal && !bVal) return -1
          if (!aVal && bVal) return 1
          if (aVal === bVal) return 0
          if (aVal > bVal) return 1 * directionFactor
          return -1 * directionFactor
        })
      }
      this.sortThumbnails()
      // Update Firestore
      firestoreUpdateDocs = {}
      this.rows.forEach((row, rowIndex) => {
        // If seq has changed
        if (row.data.seq !== rowIndex) {
          this.$set(row.data, 'seq', rowIndex)
          // Create update object for the document if necessary
          if (!firestoreUpdateDocs[row.docId]) firestoreUpdateDocs[row.docId] = {}
          firestoreUpdateDocs[row.docId][row.rowId + '.seq'] = rowIndex
        }
      })
      const batch = db.batch()
      Object.keys(firestoreUpdateDocs).forEach(docId => {
        batch.update(db.doc('memoSets/' + this.memoSetId + '/rows/' + docId), firestoreUpdateDocs[docId])
      })
      batch.commit()
        .catch(error => { logError('MemoSet_sortRowsSubmit', error) })
      // Check/update thumbnails and Mathjax
      this.prepareVisibleRows()
      this.scrollToTopLeft()
      this.initSearch()
    },
    sortThumbnails: function () {
      // Create a working array of objects with sequence number
      let workingArray = []
      this.rows.forEach((row, rowIndex) => {
        workingArray[rowIndex] = {
          seq: rowIndex,
          thumbnail: null
        }
      })
      // Add thumbnails to the working array
      this.thumbnails.forEach((thumbnail, index) => {
        workingArray[index].thumbnail = thumbnail
      })
      // Add newSeq to the working array - the index of the corresponding row
      this.rows.forEach((row, rowIndex) => {
        workingArray[row.data.seq].newSeq = rowIndex
      })
      // Sort the working array by newSeq
      workingArray.sort((a, b) => a.newSeq - b.newSeq)
      // Update thumbnails array
      workingArray.forEach((item, index) => {
        this.$set(this.thumbnails, index, item.thumbnail)
      })
    },
    sortValues: function (inputArray) {
      // Takes an array, and returns sortMode (Text or Num) and the static text, if any
      let leftText, possibleStaticText, rightText, sortMode, staticText, strippedArray, value
      // Strip <br> tags
      strippedArray = inputArray.map(e => e ? e.replace(/<br ?\/?>/g, '') : '')
      // Default sort mode to text
      sortMode = 'Text'
      staticText = ''
      leftText = ''
      rightText = ''
      // Find the first non-blank value
      value = strippedArray.find(e => e)
      if (value) {
        // Find the text to the left of the first number
        for (let j = 0; j < value.length; j++) {
          const char = value.substring(j, j + 1)
          if (char >= '0' && char <= '9') {
            // Numeric
            break
          } else {
            leftText += char
          }
        }
        // Find the text to the right of the last number
        for (let j = value.length - 1; j >= 0; j--) {
          const char = value.substring(j, j + 1)
          if (char >= '0' && char <= '9') {
            // Numeric
            break
          } else {
            rightText = char + rightText
          }
        }
        // If there is at most one of text on the left and text on the right
        if (!leftText || !rightText) {
          possibleStaticText = leftText + rightText
          // Check whether all array values are numeric without the text
          if (strippedArray.every(arrayValue => {
            const arrayValueWithoutText = arrayValue.replace(possibleStaticText, '')
            if (!arrayValueWithoutText || $.isNumeric(arrayValueWithoutText)) return true
            return false
          })) {
            staticText = possibleStaticText
            sortMode = 'Num'
          }
        }
      }
      return { sortMode, staticText }
    },
    startDrawing: function (pointer) {
      let canvasCoords, ctx, obj, radiusX, radiusY
      obj = this.objects[this.activeObject]
      // Save intial canvas
      this.summaryImage.before.canvas = this.copyOfCanvas(this.editCanvas)
      ctx = this.editCanvas.getContext('2d')
      // Draw transparent hole at cursor position
      canvasCoords = this.getCanvasCoords(pointer.clientX, pointer.clientY)
      radiusX = drawTransparentRadius / this.photoTransform.scale * this.editCanvas.width / obj.width
      radiusY = drawTransparentRadius / this.photoTransform.scale * this.editCanvas.height / obj.height
      ctx.beginPath()
      ctx.ellipse(canvasCoords.x, canvasCoords.y, radiusX, radiusY, 0, 0, 2 * Math.PI)
      ctx.globalCompositeOperation = 'destination-out'
      ctx.fill()
      this.drawObjectCanvas()
      ctx.globalCompositeOperation = 'source-over'
      // Save mouse position
      this.pointerPosX = pointer.clientX
      this.pointerPosY = pointer.clientY
      this.drawing = true
    },
    startFall: function () {
      if (this.modal !== 'learnGame') return
      this.learn.gameFade = false
      this.learn.fallEnded = false
      this.learn.gameQuestionExists = true
      this.learn.gameButtons = [{
        answerHtml: this.learn.reviewQueue[0].answerHtml,
        isCorrect: true
      }]
      this.learn.reviewQueue[0].alternativeAnswerHtmls.forEach(alternativeAnswerHtml => {
        this.learn.gameButtons.push({
          answerHtml: alternativeAnswerHtml,
          isCorrect: false
        })
      })
      // Sort buttons randomly
      shuffle(this.learn.gameButtons)
      // Apply Mathjax to question and game buttons
      this.$nextTick(() => {
        if (window.MathJax) {
          window.MathJax.typeset(['.gameMathjax'])
        }
      })
      this.$nextTick(() => {
        let arena, arenaRect, fromTranslateX, fromTranslateY, gameQuestion, gameQuestionRect, toSlowFallTranslateX, toSlowFallTranslateY, toTranslateX, toTranslateY
        arena = document.getElementById('gameArena')
        gameQuestion = document.getElementById('gameQuestion')
        // Exit if game elements are not present
        if (!arena || !gameQuestion) return
        arenaRect = arena.getBoundingClientRect()
        gameQuestionRect = gameQuestion.getBoundingClientRect()
        // Set random x positions - allowing 48px for left and right margins
        fromTranslateX = Math.random() * (arenaRect.width - gameQuestionRect.width - 48)
        toTranslateX = Math.random() * (arenaRect.width - gameQuestionRect.width - 48)
        // Ensure x positions are not negative
        fromTranslateX = Math.max(0, fromTranslateX)
        toTranslateX = Math.max(0, toTranslateX)
        // Set y positions
        fromTranslateY = 0
        toTranslateY = arenaRect.height - gameQuestionRect.height - (gameQuestionRect.top - arenaRect.top)
        // Ensure y position is not negative
        toTranslateY = Math.max(0, toTranslateY)
        $('.gameFallWrapper').removeClass('fall')
        document.documentElement.style.setProperty('--fall-transform-start', 'translate(' + fromTranslateX + 'px, ' + fromTranslateY + 'px)')
        document.documentElement.style.setProperty('--fall-transform-end', 'translate(' + toTranslateX + 'px, ' + toTranslateY + 'px)')
        document.documentElement.style.setProperty('--fall-duration', this.learn.fallDuration + 's')
        // Calculate slow fall transform values
        toSlowFallTranslateX = (toTranslateX - fromTranslateX) / this.learn.fallDuration / 2
        toSlowFallTranslateY = (toTranslateY - fromTranslateY) / this.learn.fallDuration / 2
        document.documentElement.style.setProperty('--slowFall-transform-end', 'translate(' + toSlowFallTranslateX + 'px, ' + toSlowFallTranslateY + 'px)')
        // Reduce fall duration for next question
        if (this.learn.fallDuration > 15) {
          this.learn.fallDuration *= gameFactor1
        } else {
          this.learn.fallDuration *= gameFactor2
        }
        this.$nextTick(() => {
          $('.gameFallWrapper').addClass('fall')
        })
      })
    },
    startGame: function () {
      this.saveLearnOptions()
      this.sortReviewQueueForGame(true)
      // Prepare first questions
      for (let i = 0; i < Math.min(learnPreloadCount, this.learn.reviewQueue.length); i++) {
        this.prepareQuestion(i)
      }
      this.learn.fallDuration = gameFallDuration
      this.learn.gameCompleted = false
      this.learn.gameFade = false
      this.learn.gameLives = 3
      this.learn.gameScore = 0
      this.learn.incorrectAnswers = []
      this.learn.gaming = true
      this.learn.gameButtonClicked = -1
      // If the first item is ready, start the question falling
      if (this.learn.reviewQueue[0].ready) {
        this.startFall()
      }
    },
    startRecording: function (stream) {
      const self = this
      let blob, now
      this.addCellAudio.stream = stream
      this.audioRecorder = new MediaRecorder(stream, { mimeType: 'audio/webm' })
      this.audioRecorder.addEventListener('dataavailable', event => {
        if (event.data.size) {
          blob = new Blob([event.data])
          now = Date.now()
          self.addCellAudio.url = window.URL.createObjectURL(blob)
          self.addCellAudio.file = new File(
            [blob],
            this.user.id + '_' + now + '.webm', {
              lastModified: now,
              type: 'audio/webm'
            }
          )
        }
        self.addCellAudio.recording = false
      })
      this.audioRecorder.start()
      this.addCellAudio.recording = true
      this.timeouts.audioRecorder = setTimeout(this.stopRecordingClick, maxAudioDuration)
    },
    startRecordingClick: function () {
      this.addCellAudio.microphoneError = false
      window.navigator.mediaDevices.getUserMedia({ audio: true, video: false })
        .then(this.startRecording)
        .catch(error => {
          logError('startRecordingClick', error)
          this.addCellAudio.microphoneError = true
        })
    },
    startTimerOnNextTick: function () {
      this.$nextTick(() => {
        // If testing, start test timer
        if (this.modal === 'learnTest') {
          if (this.learn.testing && !this.learn.testTimestamp) {
            this.learn.testTimestamp = Date.now()
          }
        }
      })
    },
    startTest: function () {
      this.saveLearnOptions()
      this.learn.testing = true
      this.learn.testDuration = ''
      this.learn.testProgress = 0
      this.learn.testScore = 0
      this.learn.testTotal = parseInt(this.learn.testItems, 10)
      this.learn.testTimestamp = 0
      this.learn.incorrectAnswers = []
      $('.testProgress')
        .progress({
          autoSuccess: false,
          showActivity: false
        })
        .progress('set total', this.learn.testTotal)
      this.updateTestProgress()
      this.sortReviewQueueForTest()
      // Prepare first questions
      for (let i = 0; i < Math.min(learnPreloadCount, this.learn.reviewQueue.length); i++) {
        this.prepareQuestion(i)
      }
      // If the first item is ready, start the timer
      if (this.learn.reviewQueue.length && this.learn.reviewQueue[0].ready) {
        this.startTimerOnNextTick()
      }
    },
    stopRecordingClick: function () {
      this.audioRecorder.stop()
      // Stop the track to remove recording indicator from browser tab
      this.addCellAudio.stream.getTracks()[0].stop()
      // Clear audio recorder timeout
      if (this.timeouts.audioRecorder) {
        clearTimeout(this.timeouts.audioRecorder)
        this.timeouts.audioRecorder = null
      }
    },
    summaryImageClick: function (rowIndex) {
      if (this.memoSetType !== 'regular') return
      let photoId, row
      this.closeToasts()
      this.saveTableWrapperScrollPos()
      this.rowIndex = rowIndex
      row = this.rows[rowIndex]
      if (this.options.useBackgroundPhotos) {
        photoId = row.data.photoId
      }
      if (photoId) {
        this.photoIndex = this.photos.findIndex(photo => photo.id === photoId)
        this.photo = this.photos[this.photoIndex]
        this.locationRect = {
          height: row.data.location.height,
          left: row.data.location.left,
          top: row.data.location.top,
          width: row.data.location.width
        }
      } else {
        this.photoIndex = -1
        this.photo = {
          height: noPhotoSize,
          width: noPhotoSize
        }
        this.locationRect = {
          height: noPhotoSize,
          left: 0,
          top: 0,
          width: noPhotoSize
        }
      }
      this.rectangleActive = false
      // If there is a photo and any objects, start zoomed to the location
      if (photoId && row.data.objects && row.data.objects.length) {
        this.showRect = {
          left: this.summaryImageLocationRectWithMargin.left,
          top: this.summaryImageLocationRectWithMargin.top,
          width: this.summaryImageLocationRectWithMargin.width,
          height: this.summaryImageLocationRectWithMargin.height
        }
      } else {
        // Show the entire photo
        this.showRect = {
          left: 0,
          top: 0,
          width: this.photo.width,
          height: this.photo.height
        }
        // Select the location rectangle if there is a photo
        if (photoId) {
          this.rectangleActive = true
        }
      }
      this.prepareObjects()
      this.summaryImage.createdObjectUrls = []
      this.summaryImage.neededObjectUrls = []
      // Initialize mode
      this.mode = 'select'
      this.movingRectangle = false
      this.panning = false
      this.resizingRectangle = false
      this.activeObject = -1
      this.movingObject = false
      this.resizingObject = false
      this.rotatingObject = false
      this.summaryImage.undoArray = []
      this.summaryImage.undoArrayIndex = 0
      this.summaryImage.undoArrayIndexMax = 0
      // If there's a photo or objects
      if (photoId || (row.data.objects && row.data.objects.length)) {
        // Start on the summary image
        this.summaryImage.page = 'summary'
        this.summaryImage.firstImage = false
      } else {
        // Start on Add Image
        this.summaryImage.firstImage = true
        this.addImageClick()
      }
      // Wrap in nextTick to avoid flashing previous summary image
      this.$nextTick(() => {
        this.modal = 'summaryImage'
        $('.ui.modal.summaryImage')
          .modal(
            {
              autofocus: false,
              centered: false,
              closable: false,
              onApprove: this.summaryImageSubmit,
              onDeny: this.summaryImageDeny,
              onHidden: this.revertPreventBackHistory,
              onHide: () => {
                // Workaround for onHide firing when other modals are closed
                if (this.modal === 'summaryImage') {
                  // Revoke any object URLs we don't need to keep
                  this.summaryImage.createdObjectUrls.filter(url => !this.summaryImage.neededObjectUrls.includes(url)).forEach(url => {
                    window.URL.revokeObjectURL(url)
                  })
                  // Show data table
                  this.modal = ''
                  this.prepareVisibleRows()
                  this.setTableWrapperHeight()
                  this.revertTableWrapperScrollPos()
                }
              },
              onVisible: this.prepareModalHistory
            }
          )
          .modal('show')
        this.setLocationZIndex()
        this.animateTransition = false
      })
    },
    summaryImageCropToVisible: function (obj) {
      let canvas, canvasNew, ctx, ctxNew, img, imgData, xMax, xMin, yMax, yMin
      function findLeftEdge () {
        let k
        for (let x = 0; x < canvas.width; x++) {
          for (let y = 0; y < canvas.height; y++) {
            k = (y * canvas.width + x) * 4
            if (imgData.data[k] + imgData.data[k + 1] + imgData.data[k + 2] + imgData.data[k + 3] !== 0) {
              return x
            }
          }
        }
        return canvas.width + 1
      }
      function findRightEdge () {
        let k
        for (let x = canvas.width - 1; x > xMin; x--) {
          for (let y = 0; y < canvas.height; y++) {
            k = (y * canvas.width + x) * 4
            if (imgData.data[k] + imgData.data[k + 1] + imgData.data[k + 2] + imgData.data[k + 3] !== 0) {
              return x
            }
          }
        }
        return xMin
      }
      function findTopEdge () {
        let k
        for (let y = 0; y < canvas.height; y++) {
          for (let x = 0; x < canvas.width; x++) {
            k = (y * canvas.width + x) * 4
            if (imgData.data[k] + imgData.data[k + 1] + imgData.data[k + 2] + imgData.data[k + 3] !== 0) {
              return y
            }
          }
        }
        // Shouldn't get here
        return 0
      }
      function findBottomEdge () {
        let k
        for (let y = canvas.height - 1; y > yMin; y--) {
          for (let x = 0; x < canvas.width; x++) {
            k = (y * canvas.width + x) * 4
            if (imgData.data[k] + imgData.data[k + 1] + imgData.data[k + 2] + imgData.data[k + 3] !== 0) {
              return y
            }
          }
        }
        return yMin
      }
      // Initialize editCanvas with the object image
      img = new Image()
      img.crossOrigin = 'Anonymous'
      img.onload = () => {
        canvas = document.createElement('canvas')
        canvas.width = img.naturalWidth
        canvas.height = img.naturalHeight
        ctx = canvas.getContext('2d')
        ctx.drawImage(img, 0, 0)
        imgData = ctx.getImageData(0, 0, canvas.width, canvas.height)
        xMin = findLeftEdge()
        // If there are any non-transparent pixels
        if (xMin !== canvas.width + 1) {
          xMax = findRightEdge()
          yMin = findTopEdge()
          yMax = findBottomEdge()
          // If any edges are transparent
          if (xMin || yMin || xMax < canvas.width - 1 || yMax < canvas.height - 1) {
            // Draw the non-transparent area onto a new canvas
            canvasNew = document.createElement('canvas')
            canvasNew.width = xMax - xMin
            canvasNew.height = yMax - yMin
            ctxNew = canvasNew.getContext('2d')
            ctxNew.drawImage(canvas, xMin, yMin, xMax - xMin, yMax - yMin, 0, 0, canvasNew.width, canvasNew.height)
            // Update the object
            obj.url = canvasNew.toDataURL('image/png')
            let prevHeight = obj.height
            let prevWidth = obj.width
            obj.height *= (yMax - yMin) / canvas.height
            obj.width *= (xMax - xMin) / canvas.width
            obj.left += xMin * (prevHeight / canvas.height)
            obj.top += yMin * (prevWidth / canvas.width)
          }
          this.summaryImageUploadImage(obj)
        }
      }
      img.src = obj.url
    },
    summaryImageDeny: function () {
      // If on Add Image page, return to Summary page, unless we went straight to Add Image
      if (this.summaryImage.page === 'add image') {
        if (!this.summaryImage.firstImage) {
          this.summaryImage.page = 'summary'
          // Workaround for modal becoming inactive when page is changed
          this.$nextTick(() => {
            $('.ui.modal.summaryImage').modal('set active')
          })
          return false
        }
      }
      // If on Add Text page, return to Summary page
      if (this.summaryImage.page === 'add text') {
        this.summaryImage.page = 'summary'
        // Workaround for modal becoming inactive when page is changed
        this.$nextTick(() => {
          $('.ui.modal.summaryImage').modal('set active')
        })
        return false
      }
    },
    summaryImageMouseDown: function (event) {
      this.pointerPosX = event.clientX
      this.pointerPosY = event.clientY
      this.panning = true
      this.summaryImage.backgroundPointerDownTimestamp = event.timeStamp
    },
    summaryImageMouseMove: function (event) {
      if (this.panning) this.summaryImagePanPhoto(event)
      if (this.movingRectangle) this.moveRectangle(event)
      if (this.resizingRectangle) this.resizeRectangle(event)
      if (this.movingObject) this.moveObject(event)
      if (this.resizingObject) this.resizeObject(event)
      if (this.rotatingObject) this.rotateObject(event)
      if (this.removing) this.removePointerMove(event)
    },
    summaryImagePanPhoto: function (event) {
      // centerX, centerY are relative to the photo
      // moveX, moveY, this.pointerPosX, this.pointerPosY are relative to the screen
      let centerX, centerY, moveX, moveY
      // Move showRect to the center of the visible part of the photo - this makes pan work properly for the non-limiting dimension
      centerX = -this.photoTransform.translateX + 0.5 * this.photoTransform.visibleWidth / this.photoTransform.scale
      centerY = -this.photoTransform.translateY + 0.5 * this.photoTransform.visibleHeight / this.photoTransform.scale
      this.showRect.left = centerX - this.showRect.width / 2
      this.showRect.top = centerY - this.showRect.height / 2
      // Move showRect by the amount the pointer has moved
      moveX = event.clientX - this.pointerPosX
      moveY = event.clientY - this.pointerPosY
      this.showRect.left -= moveX / this.photoTransform.scale
      this.showRect.top -= moveY / this.photoTransform.scale
      // Prevent pan from going too far up
      if (this.showRect.top < 0) {
        this.showRect.top = 0
      }
      // Prevent pan from going too far left
      if (this.showRect.left < 0) {
        this.showRect.left = 0
      }
      // Prevent pan from going too far down
      if (this.showRect.top + this.showRect.height > this.photo.height) {
        this.showRect.top = this.photo.height - this.showRect.height
      }
      // Prevent pan from going too far right
      if (this.showRect.left + this.showRect.width > this.photo.width) {
        this.showRect.left = this.photo.width - this.showRect.width
      }
      this.pointerPosX = event.clientX
      this.pointerPosY = event.clientY
      this.animateTransition = false
    },
    summaryImagePaste: function (event) {
      if (event.clipboardData) {
        const items = event.clipboardData.items
        if (items) {
          for (let i = 0; i < items.length; i++) {
            if (items[i].type.substring(0, 6) === 'image/') {
              this.addImage.loading = true
              this.addImage.file = items[i].getAsFile()
              this.addImage.url = window.URL.createObjectURL(this.addImage.file)
              this.summaryImage.createdObjectUrls.push(this.addImage.url)
              // End image editing
              if (this.mode === 'clip') this.endClipMode()
              if (this.mode === 'draw') this.endDrawMode()
              if (this.mode === 'remove') this.endRemoveMode()
              this.activeObject = -1
              // Switch to Add Image page
              this.summaryImage.page = 'add image'
              break
            }
          }
        }
      }
    },
    summaryImageRedoClick: function (event) {
      let obj, tempObj, undoEntry
      // Blur button
      document.getElementById('redoButton').blur()
      undoEntry = this.summaryImage.undoArray[this.summaryImage.undoArrayIndex]
      switch (undoEntry.changeType) {
        case 'clip':
          obj = this.objects[undoEntry.objIndex]
          obj.url = undoEntry.after.url
          break
        case 'clippingPath':
          this.clippingPath = undoEntry.after
          this.drawObjectCanvas()
          this.drawClippingPath(false)
          break
        case 'draw':
          obj = this.objects[undoEntry.objIndex]
          // If redoing during drawing
          if (this.mode === 'draw') {
            // Update the object canvas
            this.editCanvas = undoEntry.after.canvas
            this.drawObjectCanvas()
          } else {
            // Update the object's URL
            obj.url = undoEntry.after.canvas.toDataURL()
          }
          break
        case 'object add':
          this.objects.push(Object.assign({}, undoEntry.obj))
          break
        case 'object flip':
          obj = this.objects[undoEntry.objIndex]
          obj[undoEntry.flipField] = !obj[undoEntry.flipField]
          // Deselect any location or objects
          this.activeObject = -1
          this.rectangleActive = false
          break
        case 'object delete':
          this.objects.splice(undoEntry.objIndex, 1)
          // Deselect any location or objects
          this.activeObject = -1
          this.rectangleActive = false
          break
        case 'object move':
          obj = this.objects[undoEntry.before.sequence]
          obj.left = undoEntry.after.left
          obj.top = undoEntry.after.top
          tempObj = this.objects.splice(undoEntry.before.sequence, 1)[0]
          this.objects.push(tempObj)
          // Deselect any location or objects
          this.activeObject = -1
          this.rectangleActive = false
          this.setLocationZIndex()
          break
        case 'object resize':
          obj = this.objects[undoEntry.objIndex]
          obj.height = undoEntry.after.height
          obj.left = undoEntry.after.left
          obj.top = undoEntry.after.top
          obj.width = undoEntry.after.width
          // Deselect any location or objects
          this.activeObject = -1
          this.rectangleActive = false
          this.setLocationZIndex()
          break
        case 'object rotate':
          obj = this.objects[undoEntry.objIndex]
          obj.angle = undoEntry.after.angle
          // Deselect any location or objects
          this.activeObject = -1
          this.rectangleActive = false
          this.setLocationZIndex()
          break
        case 'rectangle move':
          this.locationRect.left = undoEntry.after.left
          this.locationRect.top = undoEntry.after.top
          // Deselect any location or objects
          this.activeObject = -1
          this.rectangleActive = false
          this.setLocationZIndex()
          break
        case 'rectangle resize':
          this.locationRect.height = undoEntry.after.height
          this.locationRect.left = undoEntry.after.left
          this.locationRect.top = undoEntry.after.top
          this.locationRect.width = undoEntry.after.width
          // Deselect any location or objects
          this.activeObject = -1
          this.rectangleActive = false
          this.setLocationZIndex()
          break
        case 'remove':
          obj = this.objects[undoEntry.objIndex]
          // If redoing in remove mode
          if (this.mode === 'remove') {
            // Update the object canvas
            this.editCanvas = undoEntry.after.canvas
            this.drawObjectCanvas()
          } else {
            // Update the object's URL
            obj.url = undoEntry.after.canvas.toDataURL()
          }
          break
        case 'send to back':
          tempObj = this.objects.splice(undoEntry.before.sequence, 1)[0]
          this.objects.unshift(tempObj)
          // Deselect any location or objects
          this.activeObject = -1
          this.rectangleActive = false
          this.setLocationZIndex()
          break
      }
      this.summaryImage.undoArrayIndex++
      // Disable animation
      this.animateTransition = false
    },
    summaryImageSubmit: function () {
      let backgroundHeight, backgroundWidth, imageElement, imageHeight, imageScaleFactor, imageWidth, left, newObj, objHeight, objWidth, textUrl, textUrlObj, top, undoEntry
      switch (this.summaryImage.page) {
        case 'add image':
          // If there's a background photo or other objects
          if (this.rows[this.rowIndex].data.photoId || this.objects.length) {
            // Set object to 50% of the limiting dimension
            imageScaleFactor = 0.5
          } else {
            // Set object to 100% of the limiting dimension
            imageScaleFactor = 1
          }
          // Calculate dimensions of the object to be added
          backgroundWidth = this.rows[this.rowIndex].data.photoId ? this.locationRect.width : noPhotoSize
          backgroundHeight = this.rows[this.rowIndex].data.photoId ? this.locationRect.height : noPhotoSize
          imageElement = document.getElementById('addImageImage')
          imageWidth = imageElement.naturalWidth
          imageHeight = imageElement.naturalHeight
          if (imageWidth / imageHeight > backgroundWidth / backgroundHeight) {
            // Width is the limiting dimension
            objWidth = backgroundWidth * imageScaleFactor
            objHeight = objWidth * imageHeight / imageWidth
          } else {
            // Height is the limiting dimension
            objHeight = backgroundHeight * imageScaleFactor
            objWidth = objHeight * imageWidth / imageHeight
          }
          // Determine left and top to position object centrally
          left = (this.rows[this.rowIndex].data.photoId ? this.locationRect.left : 0) + ((backgroundWidth - objWidth) / 2)
          top = (this.rows[this.rowIndex].data.photoId ? this.locationRect.top : 0) + ((backgroundHeight - objHeight) / 2)
          this.lastObjectKey++
          newObj = {
            angle: 0,
            file: this.addImage.file,
            flipX: false,
            flipY: false,
            height: objHeight,
            imageElement: imageElement.cloneNode(false),
            key: this.lastObjectKey,
            left: left,
            naturalHeight: imageHeight,
            naturalWidth: imageWidth,
            rowIndex: this.rowIndex,
            top: top,
            url: this.addImage.url,
            width: objWidth
          }
          this.objects.push(newObj)
          this.summaryImage.page = 'summary'
          this.summaryImage.firstImage = false
          // Zoom to the location
          this.rectangleActive = true
          this.zoomToSelected()
          // Select new object
          this.mode = 'select'
          this.rectangleActive = false
          this.hoverObject = -1
          this.activeObject = this.objects.length - 1
          // Prepare undo entry
          undoEntry = {
            changeType: 'object add',
            obj: Object.assign({}, newObj)
          }
          this.addSummaryImageUndoEntry(undoEntry)
          // Workaround for modal becoming inactive when page is changed
          this.$nextTick(() => {
            $('.ui.modal.summaryImage').modal('set active')
          })
          // Return false to prevent closing the modal
          return false
        case 'add text':
          textUrlObj = this.prepareTextUrl(this.summaryImage.newText)
          textUrl = textUrlObj.textUrl
          imageWidth = textUrlObj.width
          imageHeight = textUrlObj.height
          // Set object to 70% of the width
          imageScaleFactor = 0.7
          // Calculate dimensions of the object to be added
          backgroundWidth = this.rows[this.rowIndex].data.photoId ? this.locationRect.width : noPhotoSize
          backgroundHeight = this.rows[this.rowIndex].data.photoId ? this.locationRect.height : noPhotoSize
          objWidth = backgroundWidth * imageScaleFactor
          objHeight = objWidth * imageHeight / imageWidth
          // Determine left and top to position object centrally
          left = (this.rows[this.rowIndex].data.photoId ? this.locationRect.left : 0) + ((backgroundWidth - objWidth) / 2)
          top = (this.rows[this.rowIndex].data.photoId ? this.locationRect.top : 0) + ((backgroundHeight - objHeight) / 2)
          this.lastObjectKey++
          newObj = {
            angle: 0,
            flipX: false,
            flipY: false,
            height: objHeight,
            key: this.lastObjectKey,
            left: left,
            naturalHeight: imageHeight,
            naturalWidth: imageWidth, 
            rowIndex: this.rowIndex,
            text: this.summaryImage.newText,
            top: top,
            url: textUrl,
            width: objWidth
          }
          this.objects.push(newObj)
          this.summaryImage.page = 'summary'
          // Zoom to the location
          this.rectangleActive = true
          this.zoomToSelected()
          // Select new object
          this.mode = 'select'
          this.rectangleActive = false
          this.hoverObject = -1
          this.activeObject = this.objects.length - 1
          // Prepare undo entry
          undoEntry = {
            changeType: 'object add',
            obj: Object.assign({}, newObj)
          }
          this.addSummaryImageUndoEntry(undoEntry)
          // Workaround for modal becoming inactive when page is changed
          this.$nextTick(() => {
            $('.ui.modal.summaryImage').modal('set active')
          })
          // Return false to prevent closing the modal
          return false
        case 'summary':
          // End editing
          if (this.mode === 'clip') this.endClipMode()
          if (this.mode === 'draw') this.endDrawMode()
          if (this.mode === 'remove') this.endRemoveMode()
          // Deselect objects and location
          this.activeObject = -1
          this.rectangleActive = false
          // Update object sequences
          this.objects.forEach((obj, objIndex) => {
            obj.sequence = objIndex
          })
          // Move objects to their containing location, if appropriate
          if (this.photo.id) {
            this.objects.forEach(obj => {
              let primaryRowIndex = -1
              let primaryRowLocationScore = 0
              this.photoRowIndexes.forEach(rowIndex => {
                const location = this.rows[rowIndex].data.location
                // Calculate a location score for the object, being the overlapping area divided by the location area
                const overlapLeft = Math.max(obj.left, location.left)
                const overlapRight = Math.min(obj.left + obj.width, location.left + location.width)
                const overlapTop = Math.max(obj.top, location.top)
                const overlapBottom = Math.min(obj.top + obj.height, location.top + location.height)
                const unionLeft = Math.min(obj.left, location.left)
                const unionRight = Math.max(obj.left + obj.width, location.left + location.width)
                const unionTop = Math.min(obj.top, location.top)
                const unionBottom = Math.max(obj.top + obj.height, location.top + location.height)
                let locationScore = 0
                if (overlapRight > overlapLeft && overlapBottom > overlapTop) {
                  locationScore = ((overlapRight - overlapLeft) * (overlapBottom - overlapTop)) / ((unionRight - unionLeft) * (unionBottom - unionTop))
                  // If this is the best location score so far, assign the object to the location
                  if (locationScore > primaryRowLocationScore) {
                    primaryRowIndex = rowIndex
                    primaryRowLocationScore = locationScore
                  }
                }
              })
              // Move the object to its primary row
              if (primaryRowIndex !== -1) {
                obj.rowIndex = primaryRowIndex
              }
            })
          }
          // Check for new or edited objects - new object URLs start with 'blob:', and edited object URLs start with 'data:'
          this.summaryImage.imagesRemaining = 0
          this.objects.filter(obj => obj.url.substring(0, 5) !== 'https' && !obj.text).forEach(obj => {
            this.summaryImage.imagesRemaining++
            if (obj.url.substring(0, 5) === 'data:') {
              this.summaryImageCropToVisible(obj)
            } else {
              this.summaryImageUploadImage(obj)
            }
          })
          // If there are images to upload
          if (this.summaryImage.imagesRemaining) {
            this.summaryImage.uploading = true
            // Prevent modal closing
            return false
          } else {
            this.summaryImageUpdateRows()
          }
          break
      }
    },
    summaryImageTouchMove: function (event) {
      let centerXRelativeToScreen, centerYRelativeToScreen, distance, touch0, touch1
      if (event.touches.length === 1) {
        touch0 = event.touches[0]
        if (this.panning) this.summaryImagePanPhoto(touch0)
        if (this.movingRectangle) this.moveRectangle(touch0)
        if (this.movingObject) this.moveObject(touch0)
        if (this.resizingObject) this.resizeObject(touch0)
        if (this.resizingRectangle) this.resizeRectangle(touch0)
        if (this.rotatingObject) this.rotateObject(touch0)
      }
      if (event.touches.length === 2) {
        touch0 = event.touches[0]
        touch1 = event.touches[1]
        if (this.pinching) {
          distance = Math.sqrt(Math.pow(touch0.clientX - touch1.clientX, 2) + Math.pow(touch0.clientY - touch1.clientY, 2))
          let zoomFactor = distance / this.pinchData.distance
          if (zoomFactor > 1) {
            this.zoomIn(zoomFactor, null, { x: this.pinchData.centerXRelativeToPhoto, y: this.pinchData.centerYRelativeToPhoto }, false)
          }
          if (zoomFactor < 1) {
            this.zoomOut(1 / zoomFactor, null, { x: this.pinchData.centerXRelativeToPhoto, y: this.pinchData.centerYRelativeToPhoto }, false)
          }
          centerXRelativeToScreen = (touch0.clientX + touch1.clientX) / 2
          centerYRelativeToScreen = (touch0.clientY + touch1.clientY) / 2
          this.summaryImagePanPhoto({ clientX: centerXRelativeToScreen, clientY: centerYRelativeToScreen })
          this.pinchData.distance = distance
        }
      }
    },
    summaryImageTouchStart: function (event) {
      let centerXRelativeToPhoto, centerXRelativeToScreen, centerXRelativeToVisiblePhoto, centerYRelativeToPhoto, centerYRelativeToScreen, centerYRelativeToVisiblePhoto, obj, summaryImageRect, tempObj, touch0, touch1
      if (event.touches.length === 1) {
        touch0 = event.touches[0]
        this.pointerPosX = touch0.clientX
        this.pointerPosY = touch0.clientY
        this.panning = true
        this.animateTransition = false
        this.summaryImage.backgroundPointerDownTimestamp = event.timeStamp
      }
      if (event.touches.length === 2) {
        if (event.timeStamp - this.summaryImage.firstTouchTimestamp < 300) {
          // Revert any object selection/move
          if (this.movingObject) {
            obj = this.objects[this.activeObject]
            obj.left = this.summaryImage.before.left
            obj.top = this.summaryImage.before.top
            tempObj = this.objects.pop()
            this.objects.splice(this.summaryImage.before.sequence, 0, tempObj)
            this.setLocationZIndex()
            this.movingObject = false
          }
          // Revert any rectangle move
          if (this.movingRectangle) {
            this.locationRect.left = this.summaryImage.before.left
            this.locationRect.top = this.summaryImage.before.top
            this.setLocationZIndex()
            this.movingRectangle = false
          }
          // Revert any object editing
          if (this.mode === 'clip') {
            // Remove the last touch point from the clipping path
            this.clippingPath = this.firstTouchClippingPath.slice()
            this.drawObjectCanvas()
            this.drawClippingPath(false)
            this.clipping = false
          }
          if (this.mode === 'draw') {
            this.editCanvas = this.copyOfCanvas(this.summaryImage.before.canvas)
            this.drawObjectCanvas()
            this.drawing = false
          }
          if (this.mode === 'remove') {
            this.editCanvas = this.copyOfCanvas(this.summaryImage.before.canvas)
            this.drawObjectCanvas()
            this.removing = false
          }
          this.rectangleActive = this.summaryImage.firstTouchRectangleActive
          this.activeObject = this.summaryImage.firstTouchActiveObject
        }
        touch0 = event.touches[0]
        touch1 = event.touches[1]
        this.panning = false
        this.pinching = true
        this.animateTransition = false
        centerXRelativeToScreen = (touch0.clientX + touch1.clientX) / 2
        centerYRelativeToScreen = (touch0.clientY + touch1.clientY) / 2
        this.pointerPosX = centerXRelativeToScreen
        this.pointerPosY = centerYRelativeToScreen
        // Get center of touches relative to the visible photo
        summaryImageRect = $('.summaryImageWrapper')[0].getBoundingClientRect()
        centerXRelativeToVisiblePhoto = (touch0.clientX + touch1.clientX) / 2 - summaryImageRect.left
        centerYRelativeToVisiblePhoto = (touch0.clientY + touch1.clientY) / 2 - summaryImageRect.top
        // Calculate position of mouse relative to the photo
        centerXRelativeToPhoto = -this.photoTransform.translateX + centerXRelativeToVisiblePhoto / this.photoTransform.scale
        centerYRelativeToPhoto = -this.photoTransform.translateY + centerYRelativeToVisiblePhoto / this.photoTransform.scale
        this.pinchData = {
          centerXRelativeToPhoto: centerXRelativeToPhoto,
          centerYRelativeToPhoto: centerYRelativeToPhoto,
          distance: Math.sqrt(Math.pow(touch0.clientX - touch1.clientX, 2) + Math.pow(touch0.clientY - touch1.clientY, 2))
        }
      }
    },
    summaryImageUndoClick: function (event) {
      let obj, tempObj, undoEntry
      // Blur button
      document.getElementById('undoButton').blur()
      this.summaryImage.undoArrayIndex--
      undoEntry = this.summaryImage.undoArray[this.summaryImage.undoArrayIndex]
      // Exit clip mode if undoing a non-clippingPath change
      if (this.mode === 'clip' && undoEntry.changeType !== 'clippingPath') {
        this.mode = 'select'
        // Don't allow the clippingPath changes to be redone
        this.summaryImage.undoArrayIndexMax = this.summaryImage.undoArrayIndex + 1
      }
      // Exit draw mode if undoing a non-draw change
      if (this.mode === 'draw' && undoEntry.changeType !== 'draw') {
        this.mode = 'select'
      }
      // Exit remove mode if undoing a non-remove change
      if (this.mode === 'remove' && undoEntry.changeType !== 'remove') {
        this.mode = 'select'
      }
      switch (undoEntry.changeType) {
        case 'clip':
          obj = this.objects[undoEntry.objIndex]
          obj.url = undoEntry.before.url
          break
        case 'clippingPath':
          if (
            this.summaryImage.undoArray[this.summaryImage.undoArrayIndex - 1] &&
            this.summaryImage.undoArray[this.summaryImage.undoArrayIndex - 1].changeType === 'clippingPath'
          ) {
            this.clippingPath = this.summaryImage.undoArray[this.summaryImage.undoArrayIndex - 1].after
          } else {
            this.clippingPath = []
          }
          this.drawObjectCanvas()
          this.drawClippingPath(false)
          break
        case 'draw':
          obj = this.objects[undoEntry.objIndex]
          // If undoing during drawing
          if (this.mode === 'draw') {
            // Update the object canvas
            this.editCanvas = undoEntry.before.canvas
            this.drawObjectCanvas()
          } else {
            // Update the object's URL
            if (undoEntry.before.url) {
              obj.url = undoEntry.before.url
            } else {
              obj.url = undoEntry.before.canvas.toDataURL()
            }
          }
          break
        case 'object add':
          this.objects.pop()
          // Deselect any location or objects
          this.activeObject = -1
          this.rectangleActive = false
          // If we were hovering over the object being deleted, reset hoverObject
          if (this.hoverObject === this.objects.length) {
            this.hoverObject = -1
          }
          this.setLocationZIndex()
          break
        case 'object flip':
          obj = this.objects[undoEntry.objIndex]
          obj[undoEntry.flipField] = !obj[undoEntry.flipField]
          // Deselect any location or objects
          this.activeObject = -1
          this.rectangleActive = false
          break
        case 'object delete':
          obj = Object.assign({}, undoEntry.obj)
          this.objects.splice(undoEntry.objIndex, 0, obj)
          // Deselect any location or objects
          this.activeObject = -1
          this.rectangleActive = false
          this.setLocationZIndex()
          break
        case 'object move':
          obj = this.objects[undoEntry.after.sequence]
          obj.left = undoEntry.before.left
          obj.top = undoEntry.before.top
          tempObj = this.objects.pop()
          this.objects.splice(undoEntry.before.sequence, 0, tempObj)
          // Deselect any location or objects
          this.activeObject = -1
          this.rectangleActive = false
          this.setLocationZIndex()
          break
        case 'object resize':
          obj = this.objects[undoEntry.objIndex]
          obj.height = undoEntry.before.height
          obj.left = undoEntry.before.left
          obj.top = undoEntry.before.top
          obj.width = undoEntry.before.width
          // Deselect any location or objects
          this.activeObject = -1
          this.rectangleActive = false
          this.setLocationZIndex()
          break
        case 'object rotate':
          obj = this.objects[undoEntry.objIndex]
          obj.angle = undoEntry.before.angle
          // Deselect any location or objects
          this.activeObject = -1
          this.rectangleActive = false
          this.setLocationZIndex()
          break
        case 'rectangle move':
          this.locationRect.left = undoEntry.before.left
          this.locationRect.top = undoEntry.before.top
          // Deselect any location or objects
          this.activeObject = -1
          this.rectangleActive = false
          this.setLocationZIndex()
          break
        case 'rectangle resize':
          this.locationRect.height = undoEntry.before.height
          this.locationRect.left = undoEntry.before.left
          this.locationRect.top = undoEntry.before.top
          this.locationRect.width = undoEntry.before.width
          // Deselect any location or objects
          this.activeObject = -1
          this.rectangleActive = false
          this.setLocationZIndex()
          break
        case 'remove':
          obj = this.objects[undoEntry.objIndex]
          // If undoing in remove mode
          if (this.mode === 'remove') {
            // Update the object canvas
            this.editCanvas = undoEntry.before.canvas
            this.drawObjectCanvas()
          } else {
            // Update the object's URL
            if (undoEntry.before.url) {
              obj.url = undoEntry.before.url
            } else {
              obj.url = undoEntry.before.canvas.toDataURL()
            }
          }
          break
        case 'send to back':
          tempObj = this.objects.shift()
          this.objects.splice(undoEntry.before.sequence, 0, tempObj)
          // Deselect any location or objects
          this.activeObject = -1
          this.rectangleActive = false
          this.setLocationZIndex()
          break
      }
      // Disable animation
      this.animateTransition = false
    },
    summaryImageUpdateRows: function () {
      const self = this
      let allCurrentObjects, currentObjectsForRow, docId, firestoreObj, firestoreObjectsForRow, location, rowId, rowUpdateObjs
      function handleRowObjectChanges (rowIndex, currentObjects) {
        let originalObjects
        // Check whether any objects have changed on the row
        originalObjects = self.rows[rowIndex].data.objects || []
        if (self.objectsChanged(originalObjects, currentObjects)) {
          // Update objects for the row
          self.rows[rowIndex].data.objects = currentObjects
          // Prepare for Firestore update
          docId = self.rows[rowIndex].docId
          rowId = self.rows[rowIndex].rowId
          // We don't want to write rowIndex or tempUrl to Firestore
          firestoreObjectsForRow = currentObjects.map(obj => {
            firestoreObj = {
              angle: obj.angle,
              flipX: obj.flipX,
              flipY: obj.flipY,
              height: obj.height,
              left: obj.left,
              sequence: obj.sequence,
              top: obj.top,
              width: obj.width
            }
            // Include text for text objects, and url for other objects
            if (obj.text) {
              firestoreObj.text = obj.text
            } else {
              firestoreObj.url = obj.url
            }
            return firestoreObj
          })
          if (!rowUpdateObjs[docId]) rowUpdateObjs[docId] = {}
          rowUpdateObjs[docId][rowId + '.objects'] = firestoreObjectsForRow
        }
      }
      rowUpdateObjs = {}
      // Update location rectangle
      location = this.rows[this.rowIndex].data.location
      if (location) {
        if (
          location.left !== this.locationRect.left ||
          location.top !== this.locationRect.top ||
          location.width !== this.locationRect.width ||
          location.height !== this.locationRect.height
        ) {
          location.left = this.locationRect.left
          location.top = this.locationRect.top
          location.width = this.locationRect.width
          location.height = this.locationRect.height
          docId = this.rows[this.rowIndex].docId
          rowId = this.rows[this.rowIndex].rowId
          rowUpdateObjs[docId] = {}
          rowUpdateObjs[docId][rowId + '.location'] = this.locationRect
        }
      }
      // Create array of objects containing only properties used in the rows data
      allCurrentObjects = this.objects.map(obj => {
        const returnObj = {
          angle: obj.angle,
          flipX: obj.flipX,
          flipY: obj.flipY,
          height: obj.height,
          left: obj.left,
          rowIndex: obj.rowIndex,
          sequence: obj.sequence,
          tempUrl: obj.tempUrl || '',
          top: obj.top,
          url: obj.pendingUrl || obj.url,
          width: obj.width
        }
        // Include text for text objects
        if (obj.text) {
          returnObj.text = obj.text
        }
        return returnObj
      })
      if (this.photo.id) {
        // Loop through all rows using the photo
        this.photoRowIndexes.forEach(rowIndex => {
          currentObjectsForRow = allCurrentObjects.filter(obj => obj.rowIndex === rowIndex)
          handleRowObjectChanges(rowIndex, currentObjectsForRow)
        })
        // Update thumbnails for all rows using the photo
        this.photoRowIndexes.forEach(rowIndex => {
          this.createThumbnail(rowIndex)
        })
      } else {
        handleRowObjectChanges(this.rowIndex, allCurrentObjects)
        // Update thumbnail for the row
        this.createThumbnail(this.rowIndex)
      }
      // Update row documents
      if (Object.keys(rowUpdateObjs)) {
        const batch = db.batch()
        Object.keys(rowUpdateObjs).forEach(docId => {
          batch.update(db.doc('memoSets/' + this.memoSetId + '/rows/' + docId), rowUpdateObjs[docId])
        })
        this.writeImageKeys(batch)
        batch.commit()
          .then(() => {
            if (this.summaryImage.uploading) {
              this.summaryImage.uploading = false
              // Close modal
              $('.ui.modal.summaryImage').modal('hide')
            }
          })
          .catch(error => { logError('MemoSet_summaryImageSubmit', error) })
      }
      // Trigger reevaluation of walkthroughEnabled
      this.walkthroughEnabledTrigger++
    },
    summaryImageUploadImage: function (obj) {
      let canvas, ctx, fileType, height, imageDataUrl, imageKey, img, scaleFactor, tempCanvas, tempCtx, width
      // If the user has uploaded or pasted an image with no edits, the URL is a blob, and we have the file in obj.file
      // If the user has edited a file, the URL is a data URL
      // Get a random key for the image
      imageKey = this.randomId()
      // If we have a new, unedited image file
      if (obj.url.substring(0, 5) === 'blob:') {
        // We need to keep the object URL until the image has been uploaded and downloaded from Firebase storage
        this.summaryImage.neededObjectUrls.push(obj.url)
        // If image isn't too large
        if (obj.naturalHeight <= maxImageSize && obj.naturalWidth <= maxImageSize) {
          // Upload the image to Firebase Storage
          firebase.storage().ref('userImages/' + this.user.id + '/' + imageKey + extensionForFileType(obj.file.type)).put(obj.file, {cacheControl: cacheControl})
            .then(function (snapshot) {
              return snapshot.ref.getDownloadURL()
            })
            .then(downloadUrl => {
              // Put the blob URL into tempUrl, so we can use it for the thumbnail until the image has been downloaded
              obj.tempUrl = obj.url
              obj.url = downloadUrl
              // Download image
              img = new Image()
              img.onload = () => {
                // Find the row object
                this.rows.forEach((row, rowIndex) => {
                  if (row.data.objects) {
                    row.data.objects.forEach(rowObj => {
                      if (rowObj.tempUrl === obj.tempUrl) {
                        // Update the row object's URL to the download URL, and remove the temp URL
                        rowObj.url = downloadUrl
                        rowObj.tempUrl = ''
                        window.URL.revokeObjectURL(obj.tempUrl)
                      }
                    })
                  }
                })
              }
              img.src = downloadUrl
              this.summaryImage.imagesRemaining--
              if (!this.summaryImage.imagesRemaining) this.summaryImageUpdateRows()
            })
        } else {
          // Large image - reduce size via canvas
          img = obj.imageElement
          scaleFactor = Math.min(maxImageSize / img.naturalWidth, maxImageSize / img.naturalHeight)
          scaleFactor = Math.min(scaleFactor, 1)
          // Limit scale down to half to preserve image quality
          scaleFactor = Math.max(scaleFactor, 0.5)
          tempCanvas = document.createElement('canvas')
          tempCanvas.width = img.naturalWidth * scaleFactor
          tempCanvas.height = img.naturalHeight * scaleFactor
          tempCtx = tempCanvas.getContext('2d')
          // Fill with white in case of transparent images
          tempCtx.fillStyle = 'white'
          tempCtx.fillRect(0, 0, tempCanvas.width, tempCanvas.height)
          tempCtx.drawImage(img, 0, 0, tempCanvas.width, tempCanvas.height)
          width = tempCanvas.width
          height = tempCanvas.height
          while (scaleFactor === 0.5) {
            scaleFactor = Math.min(maxImageSize / width, maxImageSize / height)
            scaleFactor = Math.max(scaleFactor, 0.5)
            tempCtx.drawImage(tempCanvas, 0, 0, width, height, 0, 0, width * scaleFactor, height * scaleFactor)
            width = width * scaleFactor
            height = height * scaleFactor
          }
          // Draw section of tempCanvas onto canvas
          canvas = document.createElement('canvas')
          canvas.width = width
          canvas.height = height
          ctx = canvas.getContext('2d')
          ctx.drawImage(tempCanvas, 0, 0, width, height, 0, 0, width, height)
          imageDataUrl = canvas.toDataURL('image/jpeg')
          fileType = 'image/jpeg'
        }
      } else {
        imageDataUrl = obj.url
        fileType = 'image/png'
      }
      if (imageDataUrl) {
        // Upload the image to Firebase Storage
        firebase.storage().ref('userImages/' + this.user.id + '/' + imageKey + extensionForFileType(fileType)).putString(imageDataUrl, 'data_url', {cacheControl: cacheControl})
          .then(function (snapshot) {
            return snapshot.ref.getDownloadURL()
          })
          .then(downloadUrl => {
            // Put the data URL into tempUrl, so we can use it for the thumbnail until the image has been downloaded
            obj.tempUrl = obj.url
            obj.url = downloadUrl
            // Download image
            img = new Image()
            img.onload = () => {
              // Find the row object
              this.rows.forEach((row, rowIndex) => {
                if (row.data.objects) {
                  row.data.objects.forEach(rowObj => {
                    if (rowObj.tempUrl === obj.tempUrl) {
                      // Update the row object's URL to the download URL, and remove the temp URL
                      rowObj.url = downloadUrl
                      rowObj.tempUrl = ''
                    }
                  })
                }
              })
            }
            img.src = downloadUrl
            this.summaryImage.imagesRemaining--
            if (!this.summaryImage.imagesRemaining) this.summaryImageUpdateRows()
          })
      }
    },
    tableWrapperScroll: function () {
      if (this.timeouts.setTableWrapperScrollLeft) {
        clearTimeout(this.timeouts.setTableWrapperScrollLeft)
        this.timeouts.setTableWrapperScrollLeft = null
      }
      this.timeouts.setTableWrapperScrollLeft = setTimeout(this.setTableWrapperScrollLeft, 100)
    },
    textOnly: function (html) {
      let text
      if (!html) return ''
      // Replace line break with space
      text = html.replace(/<br ?\/?>/g, ' ')
      // Remove all HTML tags
      text = this.$sanitize(
        text,
        {
          allowedAttributes: [],
          allowedTags: []
        }
      )
      return text
    },
    titleBlur: function (event) {
      let slug, updateObj
      this.editingTitle = false
      this.setInfoWrapperHeight()
      // If no title, revert to previous value
      if (!event.target.textContent.trim()) {
        event.target.textContent = this.title
        return
      }
      // If title has changed
      if (event.target.textContent.trim() !== this.title) {
        this.title = event.target.textContent.trim()
        this.setPageTitle()
        slug = this.createMemoSetSlug(this.title)
        // Update Firestore
        updateObj = {}
        updateObj[this.memoSetId + '.slug'] = slug
        updateObj[this.memoSetId + '.title'] = this.title
        const batch = db.batch()
        batch.update(db.doc('users/' + this.user.id + '/memoSets/' + this.docId), updateObj)
        this.updateSharedSummaries(batch, {
          slug: slug,
          title: this.title
        })
        batch.commit()
          .catch(error => { logError('MemoSet_titleBlur', error) })
      }
      // Remove any non-text content
      event.target.textContent = event.target.textContent
    },
    titleFocus: function (event) {
      // If the title is the default title
      const defaultTitleLength = this.text.memoSetsDefaultTitle.length
      if (this.title.substring(0, defaultTitleLength) === this.text.memoSetsDefaultTitle &&
          (this.title.length === defaultTitleLength || this.title.substring(defaultTitleLength + 1, defaultTitleLength + 2) === '(')
      ) {
        // Select the entire title
        selectElementContents(event.target)
      }
      this.editingTitle = true
      this.setInfoWrapperHeight()
    },
    titleInput: function (event) {
      this.closeToasts()
      const titleElement = event.target
      // In Firefox, typing the first space appends ' <br>'
      if (titleElement.innerHTML.slice(-5) === ' <br>') {
        // Replace the final ' <br>' with &nbsp;
        titleElement.innerHTML = titleElement.innerHTML.slice(0, -5) + '&nbsp;'
        moveCursorToEndOfContentEditable(titleElement)
      }
      // If the title includes br or div, Enter has probably been pressed
      // Note: on iPad, the innerHTML is <br> when all characters have been deleted
      if ((titleElement.innerHTML.includes('<br>') && titleElement.innerHTML !== '<br>') || titleElement.innerHTML.includes('<div>')) {
        titleElement.blur()
      }
    },
    undoCellChanges: function () {
      let deleteDocIds, deleteFromRow, firestoreDeleteDocIds, firestoreUpdateDocs, firestoreValue
      const batch = db.batch()
      firestoreUpdateDocs = {}
      deleteDocIds = []
      firestoreDeleteDocIds = []
      this.cellChanges.cells.forEach(item => {
        const row = this.rows[item.rowIndex]
        if (item.value === '') {
          this.$delete(row.data, item.fieldId)
          firestoreValue = firebase.firestore.FieldValue.delete()
          // Note: not necessary to check row questions here, because row questions cannot be created between the change and the undo
        } else {
          this.$set(row.data, item.fieldId, item.value)
          firestoreValue = item.value
        }
        // Workaround for DOM not being updated properly when field is review group
        if (item.fieldId === 'reviewGroup') {
          document.getElementById('reviewGroup_' + item.rowIndex).innerHTML = item.value
        }
        // Update Firestore object
        if (!firestoreUpdateDocs[row.docId]) firestoreUpdateDocs[row.docId] = {}
        firestoreUpdateDocs[row.docId][row.rowId + '.' + item.fieldId] = firestoreValue
      })
      // Add back any deleted row questions
      this.cellChanges.rowQuestions.forEach(rowQuestion => {
        const row = this.rows[rowQuestion.rowIndex]
        this.$set(row.data.questions, rowQuestion.questionId, rowQuestion.questionData)
        firestoreUpdateDocs[row.docId][row.rowId + '.questions.' + rowQuestion.questionId] = rowQuestion.questionData
      })
      // Delete any added rows
      deleteFromRow = this.rows.length - this.cellChanges.rowsAdded
      if (this.cellChanges.rowsAdded) {
        for (let i = deleteFromRow; i < this.rows.length; i++) {
          const docId = this.rows[i].docId
          const rowId = this.rows[i].rowId
          if (!firestoreUpdateDocs[docId]) firestoreUpdateDocs[docId] = {}
          firestoreUpdateDocs[docId][rowId] = firebase.firestore.FieldValue.delete()
          // Push doc ID to deleteDocIds if not already there
          if (!deleteDocIds.includes(docId)) deleteDocIds.push(docId)
        }
        // If there are no rows left in a document, we want to delete the entire document
        deleteDocIds.forEach(docId => {
          if (!this.rows.filter(row => row.docId === docId).length) {
            firestoreDeleteDocIds.push(docId)
          }
        })
        // No need to update any documents being deleted
        firestoreDeleteDocIds.forEach(docId => {
          delete firestoreUpdateDocs[docId]
        })
      }
      this.rows.splice(deleteFromRow, this.cellChanges.rowsAdded)
      // Update thumbnails
      this.thumbnails.splice(deleteFromRow, this.cellChanges.rowsAdded)
      // Update Firestore
      Object.keys(firestoreUpdateDocs).forEach(docId => {
        batch.update(db.doc('memoSets/' + this.memoSetId + '/rows/' + docId), firestoreUpdateDocs[docId])
      })
      firestoreDeleteDocIds.forEach(docId => {
        batch.delete(db.doc('memoSets/' + this.memoSetId + '/rows/' + docId))
      })
      if (this.cellChanges.rowsAdded) {
        this.writeRowCount(batch)
      }
      this.updateKnownData(batch, this.memoSetId)
      batch.commit()
        .catch(error => { logError('MemoSet_undoCellChanges', error) })
      this.applyMathjax()
      this.initSearch()
    },
    undoDeleteCellContents: function () {
      const cell = document.getElementById(this.undoCellChange.fieldId + '_' + this.undoCellChange.rowIndex)
      this.hideCellButtons = true
      cell.focus()
      this.$nextTick(() => {
        cell.innerHTML = this.undoCellChange.data
        cell.blur()
        this.hideCellButtons = false
      })
    },
    undoShareMemoSet: function () {
      if (this.share.public) {
        this.unshareMemoSet('public')
      }
      if (this.share.specific && this.share.userId) {
        this.unshareMemoSet(this.share.userId)
      }
    },
    unshareMemoSet: function (shareUserId) {
      const batch = db.batch()
      this.closeToasts()
      // Remove property from sharing object
      this.$delete(this.sharing, shareUserId)
      // Update sharing on memoSets document
      batch.update(db.doc('memoSets/' + this.memoSetId), {
        sharing: this.sharing
      })
      // Update sharing on rows documents
      const uniqueDocIds = [...new Set(this.rows.map(row => row.docId))]
      uniqueDocIds.forEach(docId => {
        batch.update(db.doc('memoSets/' + this.memoSetId + '/rows/' + docId), {
          sharing: this.sharing
        })
      })
      if (shareUserId === 'public') {
        // Remove memo set from publicMemoSets
        batch.delete(db.doc('publicMemoSets/' + this.memoSetId))
        // Unset isPublic at header level
        let updateObj = {}
        updateObj[this.memoSetId + '.isPublic'] = firebase.firestore.FieldValue.delete()
        batch.update(db.doc('users/' + this.user.id + '/memoSets/' + this.docId), updateObj)
      } else {
        // Remove memo set from share user's sharedWithMe data
        batch.delete(db.doc('users/' + shareUserId + '/sharedWithMe/' + this.memoSetId))
      }
      batch.commit()
        .catch(error => { logError('MemoSet_unshareMemoSet', error) })
    },
    updateKnownData: function (batch, memoSetId) {
      let adjustedDueTimes, dueQuestions, knownData, knownQuestions, setObj, unknownQuestions
      const now = Date.now()
      // Prepare array of all question instances
      let allQuestions = []
      this.questions.forEach(question => {
        this.rows.forEach((row, rowIndex) => {
          if (this.rowHasData(row, question.fromFieldId, question.toFieldId)) {
            allQuestions.push({
              questionId: question.questionId,
              reviewAfter: row.data.questions && row.data.questions[question.questionId] ? row.data.questions[question.questionId].reviewAfter : 0,
              reviewCycle: row.data.questions && row.data.questions[question.questionId] ? row.data.questions[question.questionId].reviewCycle : 0,
              rowIndex: rowIndex
            })
          }
        })
      })
      // If there are no question instances
      if (!allQuestions.length) {
        this.deleteKnownData(batch, memoSetId)
      } else {
        knownQuestions = allQuestions.filter(question => question.reviewCycle && question.reviewAfter > now)
        dueQuestions = allQuestions.filter(question => question.reviewCycle && question.reviewAfter <= now)
        unknownQuestions = allQuestions.filter(question => !question.reviewCycle)
        knownData = [unknownQuestions.length, dueQuestions.length, Math.round(now / 10000)]
        // Concatenate array of due times
        adjustedDueTimes = knownQuestions.map(question => Math.round((question.reviewAfter - now) / 10000))
        // Sort numerically, ascending
        adjustedDueTimes.sort((a, b) => a - b)
        knownData = knownData.concat(adjustedDueTimes)
        setObj = {}
        setObj[memoSetId] = knownData
        batch.set(db.doc('users/' + this.user.id + '/knownData/doc'), setObj, { merge: true })
      }
    },
    updateLearnData: function (exitingLearn) {
      let updateGameResults, updateLearnData, updateTestResults
      // Cancel the timeout
      if (this.timeouts.updateLearnData) {
        clearTimeout(this.timeouts.updateLearnData)
        this.timeouts.updateLearnData = null
      }
      updateLearnData = Object.keys(this.learn.firestoreUpdate).length
      updateTestResults = exitingLearn && this.learn.testCompleted
      updateGameResults = exitingLearn && this.learn.gameCompleted
      if (updateLearnData || updateTestResults || updateGameResults) {
        const batch = db.batch()
        if (updateLearnData) {
          // Update Firestore based on learn.firestoreUpdate
          Object.keys(this.learn.firestoreUpdate).forEach(docId => {
            batch.update(db.doc('memoSets/' + this.memoSetId + '/rows/' + docId), this.learn.firestoreUpdate[docId])
          })
          // Reset Firestore update data
          this.learn.firestoreUpdate = {}
          this.updateKnownData(batch, this.memoSetId)
        }
        if (updateTestResults) {
          this.writeTestResults(batch)
        }
        if (updateGameResults) {
          this.writeGameResults(batch)
        }
        batch.commit()
          .catch(error => { logError('MemoSet_updateLearnData', error) })
      }
      if (!exitingLearn) {
        this.timeouts.updateLearnData = setTimeout(this.updateLearnData, learnTimeoutDuration)
      }
    },
    updateLearnProgress: function () {
      let known
      const now = Date.now()
      // Update initial known value, if we now have fewer known items than previously
      known = this.learn.reviewQueue.filter(item => item.reviewCycle && item.reviewAfter > now).length
      if (known < this.learn.initialKnown) {
        this.learn.initialKnown = known
      }
      $('.learnProgress')
        .progress({ showActivity: false })
        .progress('set total', this.learn.reviewQueue.length - this.learn.initialKnown)
        .progress('set progress', known - this.learn.initialKnown)
    },
    updateNow: function () {
      this.cancelUpdateNowTimeout()
      // Update the now value
      this.now = Date.now()
      this.timeouts.updateNow = setTimeout(this.updateNow, 60000)
    },
    updateSharedSummaries: function (batch, updateObj) {
      const keys = Object.keys(this.sharing)
      keys.forEach(shareUserId => {
        if (shareUserId === 'public') {
          batch.update(db.doc('publicMemoSets/' + this.memoSetId), updateObj)
        } else {
          batch.update(db.doc('users/' + shareUserId + '/sharedWithMe/' + this.memoSetId), updateObj)
        }
      })
    },
    updatesDateBlur: function (updateIndex) {
      // If date value has changed
      if (this.updates[updateIndex].date !== this.previousUpdateDate) {
        this.adjustUpdatesDummyRow()
        // Write to Firestore
        const batch = db.batch()
        const tempUpdates = this.updates.filter(update => update.date || update.descr)
        batch.update(db.doc('memoSets/' + this.memoSetId), {
          updates: tempUpdates
        })
        this.updateSharedSummaries(batch, {
          updates: tempUpdates
        })
        batch.commit()
          .catch(error => { logError('MemoSet_updatesDateBlur', error) })
      }
    },
    updatesDescrBlur: function (event, updateIndex) {
      // Save previous value
      const previousValue = this.updates[updateIndex].descr
      const sanitizedInput = this.sanitizeTextOnly(event.target.innerHTML)
      if (sanitizedInput !== this.updates[updateIndex].descr) {
        this.updates[updateIndex].descr = sanitizedInput
        event.target.innerHTML = sanitizedInput
        this.adjustUpdatesDummyRow()
        // Write to Firestore
        const batch = db.batch()
        const tempUpdates = this.updates.filter(update => update.date || update.descr)
        batch.update(db.doc('memoSets/' + this.memoSetId), {
          updates: tempUpdates
        })
        this.updateSharedSummaries(batch, {
          updates: tempUpdates
        })
        batch.commit()
          .catch(error => { logError('MemoSet_updatesDescrBlur', error) })
      } else {
        // No real change - revert to previous value to remove redundant HTML
        event.target.innerHTML = previousValue
      }
    },
    updatesDescrInput: function (event) {
      const element = event.target
      if ((element.innerHTML.includes('<br>') && element.innerHTML !== '<br>') || element.innerHTML.includes('<div>')) {
        element.blur()
      }
    },
    updateTestProgress: function () {
      $('.testProgress')
        .progress('set progress', this.learn.testProgress)
    },
    walkthroughAddPhotoOverviews: function () {
      let prevPhotoId = ''
      for (let i = 0; i < this.walkthrough.steps.length; i++) {
        const step = this.walkthrough.steps[i]
        // Skip if no photo
        if (!step.photo || !step.photo.photoId) {
          prevPhotoId = ''
          continue
        }
        // Skip if same photo as previous
        if (step.photo.photoId === prevPhotoId) continue
        // If next step has the same photo
        if (i < this.walkthrough.steps.length - 1) {
          const nextStep = this.walkthrough.steps[i + 1]
          if (nextStep.photo && nextStep.photo.photoId === step.photo.photoId) {
            const photo = this.getPhotoById(step.photo.photoId)
            if (!photo) continue
            // Prepare overview object
            const overview = {
              location: {
                height: photo.height,
                left: 0,
                top: 0,
                width: photo.width
              },
              objectKeys: [],
              photo: {
                photoId: step.photo.photoId,
                url: step.photo.url
              },
              rowIndex: step.rowIndex,
              summary: true
            }
            // Insert overview before step i
            this.walkthrough.steps.splice(i, 0, overview)
          }
        }
        prevPhotoId = step.photo.photoId
      }
    },
    walkthroughClick: function (event) {
      // Blur button
      event.target.blur()
      this.closeToasts()
      this.saveTableWrapperScrollPos()
      this.prepareWalkthrough()
    },
    walkthroughGoToRowClick: function () {
      this.firstVisibleRow = this.walkthrough.steps[this.walkthrough.stepNum].rowIndex + 1
      this.goToRowVal = this.firstVisibleRow.toString()
      this.walkthrough.rowClicked = true
      this.closeWalkthrough()
      if (this.activeTab !== 'data') {
        $('.menu.mainTabs .item').tab('change tab', 'data')
      }
    },
    walkthroughMove: function (delta, source, event) {
      let photoChange, step
      // Blur button
      if (event) event.target.blur()
      // Exit if no available step
      if (this.walkthrough.stepNum + delta < 0 || this.walkthrough.stepNum + delta > this.walkthrough.steps.length - 1) return
      this.walkthrough.stepNum += delta
      step = this.walkthrough.steps[this.walkthrough.stepNum]
      if (!step.photo || !this.walkthrough.steps[this.walkthrough.stepNum - delta].photo) {
        photoChange = true
      } else {
        photoChange = step.photo.photoId !== this.walkthrough.steps[this.walkthrough.stepNum - delta].photo.photoId
      }
      if (photoChange) {
        this.photoOpacity = 0
        this.animateTransition = false
      } else {
        this.animateTransition = true
        this.photoTransition = false
      }
      this.$nextTick(() => {
        this.animateTransition = true
        this.photoOpacity = 1
        this.prepareWalkthroughStep({
          photoChange: photoChange
        })
      })
      // Update slider
      if (source === 'button' || source === 'key') {
        $('.ui.slider').slider('set value', this.walkthrough.stepNum)
      }
    },
    windowKeyDown: function (event) {
      // Add Cell Image modal
      if ($('.ui.modal.addCellImage').modal('is active')) {
        // Handle Enter key
        if (event.keyCode === 13) {
          this.addCellImageEnter()
        }
      }
      // Add Column modal
      if ($('.ui.modal.addColumn').modal('is active')) {
        // Handle Enter key
        if (event.keyCode === 13) {
          this.addColumnEnter()
        }
      }
      // Add Rows modal
      if ($('.ui.modal.addRows').modal('is active')) {
        // Handle Enter key
        if (event.keyCode === 13) {
          this.addRowsEnter()
        }
      }
      // Background Photo modal
      if ($('.ui.modal.backgroundPhoto').modal('is active')) {
        // Handle Enter key
        if (event.keyCode === 13) {
          this.backgroundPhotoEnter()
        }
      }
      // Copy Down modal
      if ($('.ui.modal.copyDown').modal('is active')) {
        // Handle Enter key
        if (event.keyCode === 13) {
          this.copyDownEnter()
        }
      }
      // Copy Memo Set modal
      if ($('.ui.modal.copyMemoSet').modal('is active')) {
        // Handle Enter key
        if (event.keyCode === 13) {
          this.copyMemoSetEnter()
        }
      }
      // Delete Column modal
      if ($('.ui.modal.deleteColumn').modal('is active')) {
        // Handle Enter key
        if (event.keyCode === 13) {
          this.deleteColumnEnter()
        }
      }
      // Delete Memo Set modal
      if ($('.ui.modal.deleteMemoSet').modal('is active')) {
        // Handle Enter key
        if (event.keyCode === 13) {
          // Prevent modal from closing
          event.preventDefault()
        }
      }
      // Delete Rows modal
      if ($('.ui.modal.deleteRows').modal('is active')) {
        // Handle Enter key
        if (event.keyCode === 13) {
          this.deleteRowsEnter()
        }
      }
      // Export Data modal
      if ($('.ui.modal.exportData').modal('is active')) {
        // Handle Enter key
        if (event.keyCode === 13) {
          this.exportDataEnter()
        }
      }
      // Invalid Row Number modal
      if ($('.ui.modal.invalidRowNumber').modal('is active')) {
        // Handle Enter key
        if (event.keyCode === 13) {
          $('.ui.modal.invalidRowNumber').modal('hide')
        }
      }
      // Learn modal
      if ($('.ui.modal.learn').modal('is active')) {
        // Handle right arrow key
        if (event.keyCode === 39) {
          if (!this.learn.congratsMessage && !this.learn.answerVisible) {
            this.showAnswerClick()
            event.preventDefault()
          }
        }
        // Handle left arrow key
        if (event.keyCode === 37) {
          if (!this.learn.answerVisible && this.learn.previousReviewItem) {
            this.oopsClick()
            event.preventDefault()
          }
        }
        // Handle up arrow key
        if (event.keyCode === 38) {
          if (this.learn.answerVisible) {
            this.answerClick('correct')
            event.preventDefault()
          }
        }
        // Handle down arrow key
        if (event.keyCode === 40) {
          if (this.learn.answerVisible) {
            this.answerClick('incorrect')
            event.preventDefault()
          }
        }
        // Handle Enter key
        if (event.keyCode === 13) {
          if (this.learn.congratsMessage) {
            this.closeLearnModal()
          }
        }
      }
      // Learn Test modal
      if ($('.ui.modal.learnTest').modal('is active')) {
        // Handle right arrow key
        if (event.keyCode === 39) {
          if (this.learn.testing && !this.learn.answerVisible) {
            this.showAnswerClick()
            event.preventDefault()
          }
          if (
            !this.learn.testing &&
            !this.learn.testCompleted &&
            parseInt(this.learn.testItems, 10) &&
            parseInt(this.learn.testItems, 10) <= this.learn.reviewQueue.length
          ) {
            this.startTest()
            event.preventDefault()
          }
        }
        // Handle left arrow key
        if (event.keyCode === 37) {
          if (
            (this.learn.testing || this.learn.testCompleted) &&
            !this.learn.answerVisible &&
            this.learn.previousReviewItem
          ) {
            this.oopsClick()
            event.preventDefault()
          }
        }
        // Handle up arrow key
        if (event.keyCode === 38) {
          if (this.learn.testing && this.learn.answerVisible) {
            this.answerClick('correct')
            event.preventDefault()
          }
        }
        // Handle down arrow key
        if (event.keyCode === 40) {
          if (this.learn.testing && this.learn.answerVisible) {
            this.answerClick('incorrect')
            event.preventDefault()
          }
        }
        // Handle Enter key
        if (event.keyCode === 13) {
          if (!this.learn.testing) {
            // If in an input field, start the test
            if (document.activeElement.tagName === 'INPUT') {
              this.startTest()
              event.preventDefault()
            } else {
              this.closeLearnTestModal()
              event.preventDefault()
            }
          }
        }
      }
      // Move Columns modal
      if ($('.ui.modal.moveColumn').modal('is active')) {
        // Handle Enter key
        if (event.keyCode === 13) {
          this.moveColumnEnter()
        }
      }
      // Move Rows modal
      if ($('.ui.modal.moveRows').modal('is active')) {
        // Handle Enter key
        if (event.keyCode === 13) {
          this.moveRowsEnter()
        }
      }
      // Populate Column modal
      if ($('.ui.modal.populateColumn').modal('is active')) {
        // Handle Enter key
        if (event.keyCode === 13) {
          this.populateColumnEnter()
        }
      }
      // Question modal
      if ($('.ui.modal.question').modal('is active')) {
        // Handle Enter key
        if (event.keyCode === 13) {
          this.questionEnter()
        }
        // Handle Escape key
        if (event.keyCode === 27) {
          $('.ui.modal.question').modal('hide')
        }
      }
      // Review Schedule modal
      if ($('.ui.modal.reviewSchedule').modal('is active')) {
        // Handle Enter key
        if (event.keyCode === 13) {
          this.reviewScheduleEnter()
        }
      }

      // Row Field Paste Confirm modal
      if ($('.ui.modal.rowFieldPasteConfirm').modal('is active')) {
        // Handle Enter key
        if (event.keyCode === 13) {
          this.rowFieldPasteMultiple()
        }
      }
      // Row Question modal
      if ($('.ui.modal.rowQuestion').modal('is active')) {
        // Handle Enter key
        if (event.keyCode === 13) {
          $('.ui.modal.rowQuestion').modal('hide')
          this.rowQuestionSubmit()
        }
      }
      // Set Cover Image modal
      if ($('.ui.modal.setCoverImage').modal('is active')) {
        // Handle Enter key
        if (event.keyCode === 13) {
          this.coverImageEnter()
        }
      }
      // Set Cover Image modal
      if ($('.ui.modal.setGroupColor').modal('is active')) {
        // Handle Enter key
        if (event.keyCode === 13) {
          this.setGroupColorEnter()
        }
      }
      // Share Memo Set modal
      if ($('.ui.modal.shareMemoSet').modal('is active')) {
        // Handle Enter key
        if (event.keyCode === 13) {
          this.shareMemoSetEnter()
        }
      }
      // Sort Rows modal
      if ($('.ui.modal.sortRows').modal('is active')) {
        // Handle Enter key
        if (event.keyCode === 13) {
          this.sortRowsEnter()
        }
      }
      // Summary Image modal
      if (this.modal === 'summaryImage') {
        // If Escape pressed
        if (event.keyCode === 27) {
          if (this.summaryImage.page === 'summary') {
            $('.ui.modal.summaryImage').modal('hide')
          } else {
            this.summaryImage.page = 'summary'
          }
        }
        // If Delete pressed
        if (event.keyCode === 46) {
          if (this.summaryImage.page === 'summary' && this.mode === 'select' && this.activeObject !== -1) {
            this.deleteImageClick()
          }
        }
        // If Ctrl-Z pressed
        if (event.keyCode === 90 && this.ctrlDown && this.summaryImage.undoArrayIndex) {
          this.summaryImageUndoClick()
        }
        // If Ctrl-Y pressed
        if (event.keyCode === 89 && this.ctrlDown && this.summaryImage.undoArrayIndex < this.summaryImage.undoArrayIndexMax) {
          this.summaryImageRedoClick()
        }
        // If Enter pressed
        if (event.keyCode === 13) {
          if (this.summaryImage.page === 'add text') {
            if (this.summaryImage.newText) {
              this.summaryImageSubmit()
            }
          }
        }
      }
      // Walk Through modal
      if (this.modal === 'walkthrough') {
        // If Enter or Escape pressed
        if (event.keyCode === 13 || event.keyCode === 27) {
          this.closeWalkthrough()
          event.preventDefault()
        }
        // Right arrow key
        if (event.keyCode === 39) {
          // Move through the walk through, unless the focus is on the slider, in which case the slider has already handled it
          if (document.activeElement.id !== 'walkthroughSlider') {
            this.walkthroughMove(1, 'key')
          }
        }
        // Left arrow key
        if (event.keyCode === 37) {
          // Move through the walk through, unless the focus is on the slider, in which case the slider has already handled it
          if (document.activeElement.id !== 'walkthroughSlider') {
            this.walkthroughMove(-1, 'key')
          }
        }
        // Space or toggle-objects key
        if (event.keyCode === 32 || event.keyCode === this.toggleObjectsKeyCode) {
          this.showObjectsClick()
          event.preventDefault()
        }
        // Toggle-data key
        if (event.keyCode === this.toggleDataKeyCode) {
          this.showDataClick()
          event.preventDefault()
        }
      }
      // If Ctrl pressed
      if (event.keyCode === 17) {
        this.ctrlDown = true
      }
    },
    windowKeyUp: function (event) {
      // If Ctrl unpressed
      if (event.keyCode === 17) {
        this.ctrlDown = false
      }
    },
    windowPointerUp: function (event) {
      // If the user clicked briefly on the summary image background
      if (
        this.mode === 'select' &&
        !this.panLock &&
        this.summaryImage.backgroundPointerDownTimestamp &&
        event.timeStamp - this.summaryImage.backgroundPointerDownTimestamp < 300
      ) {
        // Deselect all objects
        this.rectangleActive = false
        this.activeObject = -1
      }
      this.summaryImage.backgroundPointerDownTimestamp = 0
      this.panning = false
      if (this.clipping) this.clippingPointerUp()
      if (this.drawing) this.endDrawing()
      if (this.removing) this.removeColor()
      if (this.movingRectangle) this.endMovingRectangle()
      if (this.movingObject) this.endMovingObject()
      if (this.resizingObject) this.endResizingObject()
      if (this.resizingRectangle) this.endResizingRectangle()
      if (this.rotatingObject) this.endRotatingObject()
    },
    windowWheel: function (event) {
      let mouseX, mouseXRelativeToVisiblePhoto, mouseY, mouseYRelativeToVisiblePhoto, summaryImageRect, summaryImageWrapper
      if (this.modal === 'summaryImage' && this.summaryImage.page === 'summary') {
        // Get mouse position relative to the visible photo
        summaryImageWrapper = $('.summaryImageWrapper')[0]
        if (summaryImageWrapper) {
          summaryImageRect = summaryImageWrapper.getBoundingClientRect()
          mouseXRelativeToVisiblePhoto = event.clientX - summaryImageRect.left
          mouseYRelativeToVisiblePhoto = event.clientY - summaryImageRect.top
          // Calculate position of mouse relative to the photo
          mouseX = -this.photoTransform.translateX + mouseXRelativeToVisiblePhoto / this.photoTransform.scale
          mouseY = -this.photoTransform.translateY + mouseYRelativeToVisiblePhoto / this.photoTransform.scale
          if (event.deltaY < 0) {
            this.zoomIn(1.2, null, {x: mouseX, y: mouseY}, true)
          }
          if (event.deltaY > 0) {
            this.zoomOut(1.2, null, {x: mouseX, y: mouseY}, true)
          }
          event.preventDefault()
        }
      }
    },
    writeFields: function () {
      db.doc('memoSets/' + this.memoSetId)
        .update({
          fields: this.fields
        })
        .catch(error => { logError('MemoSet_writeFields', error) })
    },
    writeGameResults: function (batch) {
      let concatGroups, concatQuestions
      concatQuestions = this.prepareConcatQuestions()
      concatGroups = this.prepareConcatGroups()
      // Add game result to local results
      const gameResult = {
        date: this.formattedCurrentDate(),
        score: this.learn.gameScore,
        timestamp: Date.now()
      }
      if (!this.gameResults[concatQuestions]) {
        this.gameResults[concatQuestions] = {}
      }
      if (!this.gameResults[concatQuestions][concatGroups]) {
        this.gameResults[concatQuestions][concatGroups] = []
      }
      this.gameResults[concatQuestions][concatGroups].push(gameResult)
      // Use arrayUnion to update Firestore array
      const path = 'gameResults.' + concatQuestions + '.' + concatGroups
      const updateObj = {}
      updateObj[path] = firebase.firestore.FieldValue.arrayUnion(gameResult)
      batch.update(db.doc('memoSets/' + this.memoSetId), updateObj)
    },
    writeImageKeys: function (batch) {
      const imageKeys = this.getImageKeys()
      // If image keys have changed
      if (
        imageKeys.length !== this.previousImageKeys.length ||
        imageKeys.toString() !== this.previousImageKeys.toString()
      ) {
        batch.set(db.doc('imageKeys/' + this.memoSetId), {
          imageKeys: imageKeys,
          userId: this.user.id
        })
        this.previousImageKeys = imageKeys
      }
    },
    writeInitialKnownData: function (batch, memoSetId) {
      let knownData, questionCount, setObj
      const now = Date.now()
      // Count question instances
      questionCount = 0
      this.questions.forEach(question => {
        this.rows.forEach(row => {
          if (this.rowHasData(row, question.fromFieldId, question.toFieldId)) {
            questionCount++
          }
        })
      })
      // If there are question instances
      if (questionCount) {
        knownData = [questionCount, 0, Math.round(now / 10000)]
        setObj = {}
        setObj[memoSetId] = knownData
        batch.set(db.doc('users/' + this.user.id + '/knownData/doc'), setObj, { merge: true })
      }
    },
    writeQuestions: function (batch) {
      batch.update(db.doc('memoSets/' + this.memoSetId), {
        questions: this.questions
      })
    },
    writeRowCount: function (batch) {
      let updateObj = {}
      updateObj[this.memoSetId + '.rowCount'] = this.rows.length
      batch.update(db.doc('users/' + this.user.id + '/memoSets/' + this.docId), updateObj)
      this.updateSharedSummaries(batch, {
        rowCount: this.rows.length
      })
    },
    writeSelectedQuestions: function (batch) {
      batch.update(db.doc('memoSets/' + this.memoSetId), {
        selectedQuestionIds: this.learn.questionIds
      })
    },
    writeSharedSummary: function (batch, shareUserId) {
      const summary = {
        coverImage: this.coverImage,
        description: this.databaseDescription,
        rowCount: this.rows.length,
        ownerId: this.user.id,
        ownerName: this.user.displayName,
        slug: this.slug,
        title: this.title
      }
      if (shareUserId === 'public') {
        batch.set(db.doc('publicMemoSets/' + this.memoSetId), summary)
      } else {
        batch.set(db.doc('users/' + shareUserId + '/sharedWithMe/' + this.memoSetId), summary)
      }
    },
    writeTestResults: function (batch) {
      let concatGroups, concatQuestions
      concatQuestions = this.prepareConcatQuestions()
      concatGroups = this.prepareConcatGroups()
      // Add test result to local results
      const testResult = {
        date: this.formattedCurrentDate(),
        duration: this.learn.testDuration,
        score: this.learn.testScore,
        total: this.learn.testTotal,
        timestamp: Date.now()
      }
      if (!this.testResults[concatQuestions]) {
        this.testResults[concatQuestions] = {}
      }
      if (!this.testResults[concatQuestions][concatGroups]) {
        this.testResults[concatQuestions][concatGroups] = []
      }
      this.testResults[concatQuestions][concatGroups].push(testResult)
      // Use arrayUnion to update Firestore array
      const path = 'testResults.' + concatQuestions + '.' + concatGroups
      const updateObj = {}
      updateObj[path] = firebase.firestore.FieldValue.arrayUnion(testResult)
      batch.update(db.doc('memoSets/' + this.memoSetId), updateObj)
    },
    zoomIn: function (zoomFactor, event, mousePos, animate) {
      // centerX, centerY, mousePos are relative to the photo
      let centerX, centerY
      // Blur button
      if (event) event.target.blur()
      // Calculate center of showRect relative to the photo
      centerX = -this.photoTransform.translateX + 0.5 * this.photoTransform.visibleWidth / this.photoTransform.scale
      centerY = -this.photoTransform.translateY + 0.5 * this.photoTransform.visibleHeight / this.photoTransform.scale
      this.showRect.width /= zoomFactor
      this.showRect.height /= zoomFactor
      this.showRect.left = centerX - this.showRect.width / 2
      this.showRect.top = centerY - this.showRect.height / 2
      // If zooming with mouse wheel, adjust to maintain section of photo under cursor
      if (mousePos) {
        this.showRect.left += (mousePos.x - centerX) * (zoomFactor - 1) / zoomFactor
        this.showRect.top += (mousePos.y - centerY) * (zoomFactor - 1) / zoomFactor
        // Keep showRect within bounds
        if (this.showRect.left < 0) this.showRect.left = 0
        if (this.showRect.top < 0) this.showRect.top = 0
        if (this.showRect.left + this.showRect.width > this.photo.width) this.showRect.left = this.photo.width - this.showRect.width
        if (this.showRect.top + this.showRect.height > this.photo.height) this.showRect.top = this.photo.height - this.showRect.height
      }
      this.animateTransition = animate
    },
    zoomOut: function (zoomFactor, event, mousePos, animate) {
      // centerX, centerY, mousePos are relative to the photo
      let centerX, centerY, previousHeight, previousWidth
      // Blur button
      if (event) event.target.blur()
      // Calculate center of showRect relative to the photo
      centerX = -this.photoTransform.translateX + 0.5 * this.photoTransform.visibleWidth / this.photoTransform.scale
      centerY = -this.photoTransform.translateY + 0.5 * this.photoTransform.visibleHeight / this.photoTransform.scale
      previousWidth = this.showRect.width
      previousHeight = this.showRect.height
      this.showRect.width *= zoomFactor
      this.showRect.height *= zoomFactor
      this.showRect.left -= (this.showRect.width - previousWidth) / 2
      this.showRect.top -= (this.showRect.height - previousHeight) / 2
      // If zooming with mouse wheel, adjust to maintain section of photo under cursor
      if (mousePos) {
        this.showRect.left -= (mousePos.x - centerX) * (zoomFactor - 1)
        this.showRect.top -= (mousePos.y - centerY) * (zoomFactor - 1)
      }
      // Adjust showRect if it is beyond the left side of the photo
      if (this.showRect.left < 0) {
        // Reduce width
        this.showRect.width += this.showRect.left
        this.showRect.left = 0
      }
      // Adjust showRect if it is beyond the top of the photo
      if (this.showRect.top < 0) {
        // Reduce height
        this.showRect.height += this.showRect.top
        this.showRect.top = 0
      }
      // Adjust showRect if it is beyond the right side of the photo
      if (this.showRect.left + this.showRect.width > this.photo.width) {
        this.showRect.width = this.photo.width - this.showRect.left
      }
      // Adjust showRect if it is beyond the bottom of the photo
      if (this.showRect.top + this.showRect.height > this.photo.height) {
        this.showRect.height = this.photo.height - this.showRect.top
      }
      this.animateTransition = animate
    },
    zoomToSelected: function () {
      // Blur button
      document.getElementById('zoomToSelectedButton').blur()
      this.animateTransition = true
      if (this.rectangleActive) {
        let objRect = this.objectRectWithMargin(this.activeObject, 0.1)
        this.showRect = {
          left: objRect.left,
          top: objRect.top,
          width: objRect.width,
          height: objRect.height
        }
      } else if (this.activeObject !== -1) {
        let objRect = this.objectRectWithMargin(this.activeObject, 0.1)
        this.showRect = {
          left: objRect.left,
          top: objRect.top,
          width: objRect.width,
          height: objRect.height
        }
      } else {
        // Show entire photo
        this.showRect = {
          left: 0,
          top: 0,
          width: this.photo.width,
          height: this.photo.height
        }
      }
    }
  },
  mounted: function () {
    this.setInfoWrapperHeight()
    // Initialize main tabs
    $('.menu.mainTabs .item').tab({
      onVisible: tabPath => {
        this.activeTab = tabPath
        if (tabPath === 'info') {
          this.setInfoWrapperHeight()
          this.cancelUpdateNowTimeout()
        }
        if (tabPath === 'data') {
          // Initialize visible summary image thumbnails and Mathjax
          this.prepareVisibleRows()
          this.setTableWrapperHeight()
          this.updateNow()
        }
        if (tabPath === 'questions') {
          this.setQuestionsWrapperHeight()
          this.cancelUpdateNowTimeout()
        }
        if (tabPath === 'learn' && this.ready) {
          this.setLearnWrapperHeights()
          this.initLearn()
          this.updateNow()
        }
        this.closeToasts()
      }
    })
    // Initialize tabs on backgroundPhoto modal
    $('.menu.backgroundPhotoTabs .item').tab({
      onVisible: tabPath => {
        this.backgroundPhotoTab = tabPath
      }
    })
    this.$nextTick(function () {
      $('.actionsButton').popup({
        delay: {
          hide: 1000000
        },
        inline: true,
        hoverable: true,
        on: 'click',
        onHidden: () => {
          this.revertPreventBackHistory()
        },
        onVisible: () => {
          this.closeToasts()
          this.backCloses = 'popup'
          this.preventBack()
        },
        position: 'bottom right'
      })
    })
    // Calculate history if required
    if (this.memoSetType === 'regular') {
      this.$emit('check-history')
    }
    // Add event listeners
    window.addEventListener('mouseup', this.windowPointerUp)
    window.addEventListener('touchend', this.windowPointerUp)
    window.addEventListener('keydown', this.windowKeyDown)
    window.addEventListener('keyup', this.windowKeyUp)
    window.addEventListener('wheel', this.windowWheel, {passive: false})
  },
  props: ['connected', 'memoSets', 'mobile', 'sharedMemoSets', 'text', 'touch', 'user', 'window'],
  watch: {
    memoSets: function (newMemoSets) {
      this.initMemoSet()
    },
    user: function (newUser) {
      if (!newUser.id) {
        this.$router.push('/')
      }
    },
    'window.height': function (newHeight, oldHeight) {
      this.checkModalActive()
      this.checkDataTabActive()
      this.animateTransition = false
      if (this.modal === 'walkthrough') {
        this.setDataHeight()
      }
      this.setInfoWrapperHeight()
      if (this.activeTab === 'data' && this.mobile) {
        // Blur active element if virtual keyboard closed
        if (this.touch && newHeight - oldHeight > 100) {
          document.activeElement.blur()
        }
      }
      this.setTableWrapperHeight()
      this.setQuestionsWrapperHeight()
      this.setLearnWrapperHeights()
    },
    'window.width': function (newWidth) {
      this.checkModalActive()
      this.checkDataTabActive()
      this.animateTransition = false
      if (this.modal === 'walkthrough') {
        this.setDataHeight()
      }
      // Note - changing width may change mobile value
      this.setInfoWrapperHeight()
      this.setTableWrapperHeight()
      this.setQuestionsWrapperHeight()
      this.setLearnWrapperHeights()
    }
  }
}
</script>

<style>
  :root {
    --fall-transform-start: translate(0, 0);
    --fall-transform-end: translate(0, 0);
    --fall-duration: '10s';
    --slowFall-transform-end: translate(0, 0);
  }
  /* Reduce padding around modal - needed for full-screen on mobile */
  .ui.dimmer {
    padding: 0 !important;
  }
  /* Prevent scrollbar on walk through modal */
  .ui.dimmer.modals {
    overflow: hidden !important;
  }
  /* Set slider color */
  .ui.slider:not(.disabled) .inner .thumb {
    background-color: #00b5ad !important;
  }
  .ui.slider:not(.disabled) .inner .thumb:hover {
    background-color: #009c95 !important;
  }
  .ui.table.memoSetTable {
    margin-top: 0;
  }
  /* Limit image size in data table, walk through data table, and search results */
  .memoSetTable div img, .walkthroughData img, .search .results img {
    border-radius: 0.3125em !important;
    margin-left: 4px;
    margin-right: 4px;
    max-height: 100px;
    max-width: 120px !important;
  }
  /* Limit image size in question preview */
  .ui.tab[data-tab="questions"] img {
    max-width: 240px;
    max-height: 150px;
  }
  /* Prevent bold font in search results */
  .ui.search > .results .result .title {
    font-weight: normal;
  }
  /* Prevent padding around Groups labels */
  .ui.multiple.dropdown.reviewGroups a.ui.label {
    padding: 0;
  }
  /* Override hover style changes to make label look unclickable */
  .ui.multiple.dropdown.reviewGroups a.ui.label:hover {
    background-color: #e8e8e8;
    border-color: #e0e0e0;
    color: rgba(0,0,0,.6);
  }
  /* Inner labels in Groups dropdown */
  .ui.multiple.dropdown.reviewGroups a.ui.label div.ui.label {
    /* Make label look unclickable */
    cursor: default;
    margin-right: 0;
  }
  /* Adjust padding around delete icon for Groups labels */
  .ui.multiple.dropdown.reviewGroups i.delete.icon {
    margin: 0;
    padding: 0.5em 0.8em 1.5em 0.7em;
  }
  /* Adjust hover style for Groups delete icon */
  .ui.multiple.dropdown.reviewGroups i.delete.icon:hover {
    background-color: #d0d0d0;
    border-bottom-right-radius: .28571429rem;
    border-top-right-radius: .28571429rem;
  }
  /* Set max size for images on Learn modals and question preview */
  .ui.modal.learn img, .ui.modal.learnTest img, .questionPreview img {
    max-height: 48vh;
    max-width: 100%;
  }
  /* Increase size of summary image on Learn modal slightly */
  .ui.modal.learn img.learnSummaryImage {
    height: 200px;
  }
  /* Set max size for images on Game modal */
    .ui.modal.learnGame img {
    max-height: 40vh;
    max-width: 60%;
  }
  /* Decrease size of summary image on Game modal for mobile */
  .ui.modal.learnGame.mobile img.learnSummaryImage {
    height: 100px;
  }
  /* Hide slider labels */
  .ui.labeled.slider>.labels .label {
    color: transparent;
    pointer-events: none;
    user-select: none;
  }
  /* Reduce height of slider ticks */
  .ui.labeled.ticked.slider>.labels .label:after {
    height: 1em;
    top: 108%;
  }
  /* Limit image size in modals */
  .ui.modal.addRows img, .ui.modal.deleteRows img, .ui.modal.moveRows img {
    max-height: 80px;
    max-width: 120px;
  }
  /* Show/hide elements for printable version */
  @media print {
    .noPrint, div.ui.popup.noPrint {
      visibility: hidden !important;
    }
    .dataTab {
      display: block !important;
    }
    .infoWrapper, .tableWrapper {
      max-height: initial !important;
      overflow: initial;
      scroll-behavior: smooth;
    }
    .tableWrapper {
      -webkit-print-color-adjust: exact;
      print-color-adjust: exact;
    }
    .innerWrapper {
      margin-top: -9rem;
    }
    .postOptionsWrapper {
      margin-top: -5rem;
    }
  }
  /* questionGlow animation is same as glow but with background #f3f4f5 */
  .transition.questionGlow {
    animation-duration: 2s;
    animation-timing-function: cubic-bezier(.19,1,.22,1)
  }
  .transition.questionGlow {
    animation-name: questionGlow;
  }
  @keyframes questionGlow {
    0% {background-color: #f3f4f5}
    30% {background-color: #fff6cd}
    100% {background-color: #f3f4f5}
  }
  .inlineBlock {
    display: inline-block !important;
  }
  .ui.label.noGroup {
    background-color: silver;
  }
  .ui.toast {
    padding: 1.5rem 2rem 1rem 1.5rem;
  }
  /* Fix icon position for toast with action buttons */
  .ui.toast:not(.vertical):not(.centered):not(.center)>.centered.icon {
    top: 2.2rem !important;
  }
  /* Add space below header */
  .ui.toast>.content>.header {
    margin-bottom: 1rem;
  }
  .ui.toast-container .toast-box :not(.comment):not(.card) .actions {
    margin-top: 1rem;
  }
  /* Indent toast action button */
  .toastIndent {
    margin-left: 3rem !important;
    margin-right: 4rem !important;
  }
  .toastButton {
    margin-bottom: 0.4rem !important;
    margin-left: 0.8rem !important;
  }
  .toastButtonLeft {
    margin-left: 3rem !important;
  }
  .toastButtonRight {
    margin-right: 0.8rem !important;
  }
  /* Adjust toast close icon position */
  .ui.toast-container .toast-box .ui.toast:not(.vertical)>.close.icon:not(.left) {
    right: 0.5em !important;
  }
  .ui.toast-container .toast-box .ui.toast:not(.vertical)>.close.icon {
    top: 0.5em !important;
  }
  .couldNotOpen {
    padding-bottom: 1.5rem !important;
  }
  .summaryImageAddText {
    margin-top: 200px;
  }
</style>

<style scoped>
  /* Undo innerWrapper margin on mobile */
  .tabsAndButtonWrapper.mobile {
    margin: -1rem 4px 0 4px;
  }
  .tabsWrapper {
    display: inline-block;
    width: calc((100% - 0.8rem) * 0.8);
    max-width: 640px;
  }
  .tabsWrapper.threeTabs {
    max-width: 520px;
    width: calc((100% - 0.8rem) * 0.75);
  }
  .tabsWrapper.noTabsText {
    width: calc(100% - 0.8rem - 3rem);
  }
  /* Reduce right margin for narrow icons */
  .question.icon, .walking.icon {
    margin-right: 4px !important;
  }
  .question.icon.noRightMargin, .walking.icon.noRightMargin {
    margin-right: 0 !important;
  }
  .compactTabsText {
    font-size: 94%;
  }
  .compactTabsIcon {
    font-size: 1.25em !important;
  }
  /* Improve multi-line tab labels */
  .ui.menu.multiLine .item {
    line-height: 1.1;
    padding: 0 0.8rem !important;
  }
  /* Increase height of tabs if not mobile */
  .ui.menu.mainTabs:not(.mobile) {
    height: 3rem;
  }
  /* Inactive tabs */
  .ui.menu.mainTabs .item:not(.active) {
    background-color: #dcdfe4;
  }
  /* Active tab */
  .ui.menu.mainTabs .active.item {
    background-color: #ffffff;
  }
  /* Hovered tab */
  .ui.link.menu.mainTabs .item:not(.active):hover, .ui.menu.mainTabs .dropdown.item:not(.active):hover, .ui.menu.mainTabs .link.item:not(.active):hover, .ui.menu.mainTabs a.item:not(.active):hover {
    background-color: #e9ecf1;
  }
  /* Walk Through button */
  .rightButtonWrapper {
    float: right;
    width: calc((min(100%, 760px) - 0.8rem) * 0.2);
  }
  .rightButtonWrapper.noTabsText {
    width: 3rem;
  }
  .tabsAndButtonWrapper.threeTabs .rightButtonWrapper {
    width: calc((100% - 0.8rem) * 0.25);
  }
  .rightButtonWrapper:not(.mobile) .ui.menu {
    height: 2.8571428rem;
  }
  .rightButtonWrapper .ui.menu {
    border: 0;
    box-shadow: none;
  }
  .rightButtonWrapper .ui.item.menu .item {
    border-radius: .28571429rem;
    font-weight: 700;
  }
  .rightButtonWrapper .ui.item.menu .item.walkthroughItem {
    background-color: #00b5ad !important;
    color: #ddf5f4;
  }
  .rightButtonWrapper .ui.item.menu .item.walkthroughItem:hover {
    background-color: #009c95 !important;
  }
  .rightButtonWrapper .ui.item.menu .item.addItem {
    background-color: #2185d0 !important;
    color: #fff;
  }
  .rightButtonWrapper .ui.item.menu .item.addItem:hover {
    background-color: #1678c2 !important;
  }
  .rightButtonWrapper .ui.item.menu .item.delete {
    background-color: #e0e1e2 !important;
    color: rgba(0,0,0,.6);
  }
  .rightButtonWrapper .ui.item.menu .item.delete:hover {
    background-color: #cacbcd !important;
    color: rgba(0,0,0,.8);
  }
  .searchRowWrapper.mobile {
    margin-left: 4px;
  }
  .searchWrapper {
    display: inline-block;
    width: calc(100% - 16.5rem - 2.5rem - ((min(100%, 760px) - 0.8rem) * 0.2) - 2rem);
  }
  .searchWrapper.mobile {
    width: calc(100% - 16.5rem - 1.5rem - ((min(100%, 760px) - 0.8rem) * 0.2) - 1rem);
  }
  .searchWrapper.noTabsText {
    /* 100% - (navigationWrapper width) - (navigationWrapper left margin) - (actionsButtonWrapper width) - (a little extra to account for the gap between elements) */
    width: calc(100% - 10.5rem - 1rem - 3rem - 12px);
  }
  .navigationWrapper {
    display: inline-block;
    margin-left: 2.5rem;
    width: 16.5rem;
  }
  .navigationWrapper.mobile {
    margin-left: 1.5rem;
  }
  .navigationWrapper.noTabsText {
    margin-left: 1rem;
    width: 10.5rem;
  }
  /* Actions button */
  .actionsButtonWrapper {
    float: right;
    /* same width as rightButtonWrapper */
    width: calc((min(100%, 760px) - 0.8rem) * 0.2);
  }
  .actionsButtonWrapper.noTabsText {
    width: 3rem;
  }
  .actionsButtonWrapper.mobile {
    margin-right: 4px;
  }
  .actionsButtonWrapper .ui.item.menu .item {
    background-color: #2185d0 !important;
    border-radius: .28571429rem;
    color: white;
    font-weight: 500;
  }
  .actionsButtonWrapper .ui.item.menu .item:hover {
    background-color: #1678c2 !important;
  }
  .actionsButton {
    border: 0;
    margin: 0;
  }
  .dataTab .ui.grid.mobile {
    margin-left: calc(-2rem + 4px);
    margin-right: calc(-2rem + 4px);
  }
  div[contenteditable] {
    outline: none;
  }
  .editable {
    border: 2px solid transparent;
    border-radius: 4px;
    margin-top: -7px;
    padding: 5px 8px;
  }
  .editable:hover {
    background-color: #fff;
    border: 2px solid #eee;
  }
  .editable:active, .editable:focus {
    background-color: #fff;
    border: 2px solid lightsteelblue !important;
    cursor: auto !important;
  }
  .ui.selectable.table > tbody > tr:hover {
    cursor: pointer;
  }
  /* Remove side padding from tab content */
  .ui.tab.segment {
    padding-left: 0;
    padding-right: 0;
  }
  .inlineEditable {
    margin-left: -0.7rem;
  }
  .descriptionPlaceholder {
    color: gray !important;
  }
  .actionButton {
    /* Add bottom margin in case buttons wrap on narrow screens */
    margin-bottom: 0.6rem;
    margin-right: 0.6rem;
    min-width: 8rem;
  }
  .noMargin {
    margin: 0 !important;
  }
  .locationRectangle {
    border: 2px solid #395f80;
    outline: 20000px solid rgba(0, 0, 0, 0.35);
  }
  .rectangleHandles > div {
    border: 2px solid #395f80;
    height: 16px;
    width: 16px;
    z-index: 1001;
  }
  .objectHandle {
    border: 1px solid red;
    height: 16px;
    transform-origin: left top;
    width: 16px;
    z-index: 1001;
  }
  .rotationHandle {
    cursor: crosshair;
  }
  .objectRotationLine {
    border-left: 1px solid red;
    height: 40px;
    pointer-events: none;
    transform-origin: left top;
    width: 2px;
    z-index: 1000;
  }
  .summaryImageWrapper {
    position: relative;
  }
  .summaryImageWrapper, .walkthroughWrapper {
    overflow: hidden;
  }
  .walkthroughWrapper {
    background-color: black;
  }
  .summaryImageWrapper div, .summaryImageWrapper img, .summaryImageWrapper canvas, .walkthroughWrapper div, .walkthroughWrapper img {
    position: absolute;
  }
  .ui.modal.fullHeight {
    bottom: 0 !important;
    margin: 1rem auto !important;
    top: 0 !important;
  }
  /* Full screen mobile modal - includes many classes to be more specific than standard Semantic UI rules */
  .ui.modal.fullscreen.fullscreenMobileModal {
    border-radius: 0;
    left: 0;
    margin: 0px !important;
    width: 100% !important;
  }
  /* Full screen modal - includes many classes to be more specific than standard Semantic UI rules */
  .ui.modal.fullscreen.fullscreenModal {
    border-radius: 0;
    left: 0;
    margin: 0px !important;
    width: 100% !important;
  }
  .ui.modal.standard {
    height: 82%;
  }

  .cellTd {
    height: 80px;
    padding: 0 !important;
    position: relative;
  }
  .noBackgroundItem {
    background-color: inherit !important;
    cursor: inherit !important;
  }
  .pasteImageHere {
    background-color: transparent !important;
    border: 2px dashed silver !important;
    border-radius: 6px;
    color: transparent;
    cursor: pointer;
    height: 6rem;
    outline: none;
    padding: 1rem 1.5rem;
    resize: none;
    width: 100%;
  }
  .pasteImageHere:focus {
    background-color: whitesmoke !important;
  }
  .modalLabel {
    font-weight: bold;
    padding-bottom: 0.65rem !important;
  }
  .modalLabelQuestion {
    margin-bottom: 1rem;
  }
  .modalLabelPadding {
    padding-top: 0.65rem !important;
  }
  .ui.grid:not(.mobile) .modalLabel::after {
    content: ":";
  }
  .selectPhotoFile {
    margin: 0.6rem 0 1rem 0.6rem;
  }
  /* Hide standard file input - replaced with label */
  input[type="file"] {
    display: none;
  }
  .summaryImageThumbnail {
    border-radius: .3125em;
  }
  .summaryImageThumbnail.clickable {
    cursor: pointer;
  }
  .photoThumbnail {
    border-radius: .3125em;
  }
  .photoThumbnail.clickable {
    cursor: pointer;
  }
  .asAbove {
    color: gray;
  }
  .asAboveLink {
    border-radius: 6px;
    cursor: pointer;
    padding: 0.6rem 1rem;
  }
  .asAboveLink:hover {
    background-color: #e0e1e2;
    color: rgba(0,0,0,.87);
  }
  /* Make positive row brighter, like background on hover */
  .ui.table tr.positive {
    background: #f7ffe6!important;
  }
  .tick {
    color: green;
    font-size: 200%;
    font-weight: bold;
  }
  .backgroundPhotoNoneRow {
    height: 60px;
  }
  .backgroundPhotoNone {
    color: rgba(0,0,0,.87);
    font-weight: bold;
  }
  .backgroundPhotoTabs {
    margin-top: 1rem !important;
  }
  .noBottomBorder {
    border-bottom: 0 !important;
  }
  .noBottomMargin {
    margin-bottom: 0 !important;
  }
  .noTopMargin {
    margin-top: 0 !important;
  }
  .noBottomPadding {
    padding-bottom: 0 !important;
  }
  .noTopPadding {
    padding-top: 0 !important;
  }
  .content.backgroundPhotoContent {
    padding-top: 0;
    padding-bottom: 0;
  }
  .noPhotoRectSummaryImage {
    background-color: white;
    border: 2px dashed gainsboro;
    pointer-events: none;
    z-index: 0;
  }
  .noPhotoRectWalkthrough {
    background-color: white;
    height: 100%;
    pointer-events: none;
    width: 100%;
    z-index: 0;
  }
  /* Allow loader to appear in modal */
  .ui.loader.modalLoader:before {
    border: .2em solid rgba(0,0,0,.1) !important;
  }
  .ui.loader.modalLoader:after {
    border-color: #767676 transparent transparent !important;
  }
  .closeButton {
    margin-right: 0;
    position: absolute;
    right: 0;
    z-index: 10000;
  }
  .closeLearnButton {
    background-color: transparent !important;
    float: right;
    font-size: 100% !important;
    margin-right: 0;
    padding: 0 !important;
  }
  .noRightMargin {
    margin-right: 0 !important;
  }
  .walkthroughButtonMid {
    width: 6rem;
  }
  .walkthroughButtonDesktop {
    width: 9rem;
  }
  .walkthroughButtonMobile {
    width: 23%;
  }
  .walkthroughButtonMobile.noImages {
    margin-right: 26%;
  }
  #walkthroughSlider .inner .track-fill {
    background-color: #00b5ad;
  }
  #walkthroughSlider {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
    padding-bottom: 6px;
    padding-top: 6px;
    width: calc(100% - 40rem);
  }
  #walkthroughSlider.noImages {
    width: calc(100% - 31rem);
  }
  #walkthroughSlider.sliderMid {
    width: calc(100% - 28rem);
  }
  #walkthroughSlider.sliderMid.noImages {
    width: calc(100% - 22rem);
  }
  #walkthroughSlider.sliderMobile {
    /* Hide data table below slider during transitions */
    background-color: white;
    margin-top: -3rem;
    position: absolute;
    width: 95%;
  }
  .noPadding {
    padding: 0 !important;
  }
  /* Override high-specifity Semantic UI style */
  @media screen and (max-width: 767.98px) {
    .ui.modal > .content.noPadding {
      padding: 0 !important;
    }
    .ui.modal > .content.noBottomPadding {
      padding-bottom: 0 !important;
    }
  }
  .noLeftPadding {
    padding-left: 0 !important;
  }
  .walkthroughLocationRectangle {
    border: 2px solid #395f80;
    outline: 20000px solid rgba(0, 0, 0, 0.5);
    z-index: 1000;
  }
  .dummyHeight {
    height: 1px;
  }
  td.outlineOnFocus:focus-within {
    outline: 2px solid lightsteelblue;
  }
  th.outlineOnFocus:focus-within {
    background-color: #617f99 !important;
  }
  .contenteditableHeading {
    padding: 13px 8px;
    width: 100%;
  }
  /* Make disabled text a little stronger than standard */
  td.disabled {
    color: rgba(40,40,40,.5) !important;
  }
  th.disabled {
    color: rgba(255, 255, 255, 0.6) !important;
  }
  .cellButtons {
    position: absolute;
    top: -2.5rem;
    z-index: 2;
  }
  .cellButtons .button {
    border: 1px solid silver;
    margin: 0;
  }
  .cellButtons.lightBackground .button {
    background-color: whitesmoke;
  }
  .contenteditableCell {
    padding: 6px 8px;
    overflow: hidden;
    position: relative;
    width: 100%;
  }
  .infoWrapper, .learnWrapper, .questionsWrapper {
    overflow: auto;
  }
  .infoWrapper.mobile, .learnWrapper.mobile {
    margin-left: 4px;
    margin-right: 4px;
  }
  .questionsWrapper.mobile {
    margin-left: 1rem;
    margin-right: 1rem;
  }
  .infoGrid {
    margin: 0.6rem 0 0 0 !important;
  }
  .segment.verticallyChallenged {
    margin-top: 6px !important;
  }
  .tableWrapper {
    border: 1px solid gainsboro;
    border-radius: .28571429rem;
    margin-top: 1rem;
    overflow: auto;
  }
  .tableWrapper.mobile {
    margin-left: 4px;
    margin-right: 4px;
  }
  .tableWrapper.printing {
    overflow: hidden;
  }
  .tableWrapper.verticallyChallenged {
    margin-top: 5px;
  }
  .tableWrapper thead th {
    position: sticky;
    top: 0;
    z-index: 2;
  }
  .tableWrapper .one.wide {
    min-width: 80px;
  }
  .tableWrapper .two.wide, .tableWrapper .one.wide.photo {
    min-width: 160px;
  }
  .tableWrapper .four.wide {
    min-width: 320px;
  }
  .tableWrapper.readOnly {
    margin-top: 0;
  }
  .thinScroll::-webkit-scrollbar {
    height: 5px;
    width: 5px;
  }
  .walkthroughDataTable .two.wide {
    min-width: 100px;
  }
  .walkthroughDataTable .three.wide {
    min-width: 200px;
  }
  .ui.grid.walkthroughData {
    margin: 0.5rem 1rem 0 1rem !important;
  }
  .ui.grid.walkthroughData.mobile {
    margin: 0 !important;
  }
  /* Fix bug with unnecessary vertical scrollbar appearing on memo set table */
  .ui.grid {
    margin-bottom: 0;
    margin-top: 0;
  }
  .goToRowButton {
    margin: 0 !important;
    padding-left: 0.4rem !important;
    padding-right: 0.4rem !important;
  }
  .goToRowInput {
    margin-left: 2px;
    margin-right: 2px;
    width: 4rem;
  }
  .goToRowInput input {
    padding-left: 0.6em;
    padding-right: 0.4em;
  }
  .ui.icon.button > .icon.largeIconInButton {
    margin-bottom: -3px !important;
    margin-top: -6px !important;
  }
  .desktopActionsWidth {
    margin: 0 !important;
    width: 350px !important;
  }
  .mobileActionsWidth {
    width: 88vw !important;
  }
  .actionsIcon {
    font-size: 1.25em !important;
    margin-left: 0.8rem !important;
    margin-right: 0 !important;
  }
  /* Make item font for Actions menu slightly darker */
  .ui.link.list .item, .ui.link.list .item a:not(.ui), .ui.link.list a.item {
    color: rgba(0,0,0,.6);
    font-weight: bold;
  }
  /* Keep standard link color for Actions menu on hover */
  .ui.link.list .item:hover, .ui.link.list .item a:not(.ui):hover, .ui.link.list a.item:hover {
    color: rgba(0,0,0,.87);
  }
  /* Increase vertical space between Actions menu items slightly */
  .ui.list .list>.item, .ui.list>.item, ol.ui.list li, ul.ui.list li {
    padding: .4em 0;
  }
  /* Fix color for Actions menu checkbox labels - this needs !important to override Semantic UI's label style */
  .checkboxLabel {
    color: rgba(0,0,0,.6) !important;
  }
  .checkboxLabel:hover {
    color: rgba(0,0,0,.87) !important;
  }
  .ui.checkbox.disabled > .checkboxLabel:hover {
    color: rgba(0,0,0,.6) !important;
  }
  .rowNum {
    color: silver !important;
  }
  .reviewGroup {
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
  }
  .ui.label.reviewGroup.invisible {
    background-color: transparent;
  }
  .cardsCell {
    /* Allow enough height for card value and suit to be visible */
    height: 3.5rem;
    transition: none !important;
  }
  .cardsCell:not(:focus-within) {
    color: transparent;
  }
  .cardsCell:focus-within {
    background-image: none !important;
  }
  .redSuit {
    color: #db2828;
  }
  .blueSuit {
    color: #0000d4;
  }
  .greenSuit {
    color: #408000;
  }
  .suits {
    font-size: 140% !important;
  }
  .questionDropdown {
    width: 16rem;
  }
  .questionSegment {
    background-color: #e6e9ef;
  }
  .questionSegment:hover {
    background-color: whitesmoke;
    cursor: pointer;
  }
  .segmentHeader {
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    border-left: 0 !important;
    border-right: 0 !important;
    border-top: 0 !important;
    margin-bottom: 0;
    margin-top: 0;
  }
  .learnSegment {
    border-color: #999;
    padding: 3px 0 0 0;
  }
  .questionWrapper {
    margin-left: 1rem !important;
  }
  .coverImage {
    margin-bottom: 1rem;
    max-height: 200px;
  }
  .coverImageEditable {
    border: 2px solid transparent;
    cursor: pointer;
  }
  .coverImageEditable:hover {
    border: 2px solid #ddd;
  }
  .summaryImageThumbnailBorder {
    border: 1px dashed gainsboro;
  }
  .ui.compact.green.message {
    font-size: 120%;
  }
  /* Use margin-top in rem, to avoid differences for different font size */
  .answerMessage {
    margin-top: 1.5rem;
  }
  .resetWrapper {
    float: right;
    margin-top: -0.5rem;
  }
  .resetWrapperMobile {
    margin-top: -0.3rem;
  }
  .statusBar {
    margin-bottom: 1rem;
    margin-left: 0.5rem;
    width: calc(100% - 4rem);
  }
  .ui.modal.walkthrough > .content {
    height: 100%;
    /* Prevent data table extending below modal during transition */
    overflow: hidden;
    position: relative;
  }
  .walkthroughData {
    overflow-x: auto;
  }
  /* Reduce height of walk through data scrollbar */
  .walkthroughData::-webkit-scrollbar {
    height: 5px;
  }
  .walkthroughGrid {
    /* Set background color to white to hide data table during transition */
    background-color: white;
    bottom: 0;
    margin-left: 0 !important;
    padding: 0 1rem 1rem 1rem;
    position: absolute;
    width: 100%;
  }
  .dataHidden {
    margin-top: -100rem;
    visibility: hidden;
  }
  .hideField {
    display: none;
  }
  .navigationWidth {
    width: calc(5% + 19rem) !important;
  }
  .navigationWidth.mobile {
    width: calc(15rem) !important
  }
  .searchWidth {
    padding-right: 0 !important;
    width: calc(100% - 5% - 19rem) !important;
  }
  .searchWidth.mobile {
    width: calc(100% - 15rem) !important;
  }
  /* Increase width of search results */
  .ui.fluid.search .results {
    width: 88vw;
  }
  .ui.fluid.search .results.noTabsText {
    width: 93vw;
  }
  /* Remove border from memo set table - border is on tableWrapper instead so the top border doesn't disappear on scroll */
  .ui.table.memoSetTable {
    border: 0;
  }
  .ui.fluid.dropdown.reviewGroups {
    margin: 1em;
    width: calc(100% - 2em) !important;
  }
  .noStatusBar {
    margin: -3.4rem 0 0 1rem;
    padding: 0.5em 1.5em;
    position: absolute;
  }
  .learnSelectAllButton {
    margin-top: -0.4rem;
  }
  /* Fix width in modal dropdowns */
  .ui.dropdown > .text {
    width: 100%;
  }
  .message.learnMessage {
    color: rgba(0,0,0,0.87) !important;
    margin-bottom: 1.5rem;
  }
  .noSidePadding {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  /* Add specificity to override Semantic style */
  .ui.modal > .content.noSidePadding {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .moveUp {
    margin-top: -1rem;
  }
  /* Override semantic's h4:first-child having margin-top: 0 */
  h4:first-child {
    margin-top: calc(2rem - .1428571428571429em);
  }
  .displayNone {
    display: none;
  }
  .rightLabel {
    display: inline-block;
    font-weight: bold;
    margin-top: 1rem;
    width: 9rem;
  }
  .rightLabel:first-child {
    margin-top: 0;
  }
  /* Move loading indicator down slightly on desktop */
  .innerWrapper:not(.mobile) .memoSetLoader {
    margin-top: 0.5rem !important;
  }
  /* Indent loading indicator on mobile */
  .innerWrapper.mobile .memoSetLoader {
    margin-left: 1rem !important;
  }
  .visibilityHidden {
    visibility: hidden;
  }
  /* Set label column width on Info tab */
  .ui.tab.segment[data-tab="info"] .one.wide.column.labelColumn {
    width: 160px !important;
  }
  .ui.tab.segment[data-tab="info"] .fifteen.wide.column.mainColumn {
    width: calc(100% - 160px) !important;
  }
  .dataTabPrinting {
    margin-top: -9rem;
  }
  .subsequentRowsWrapper {
    float: left;
    margin-left: 1rem;
  }
  .subsequentRowsWrapper.mobile {
    float: none;
    margin-bottom: 0.8rem;
    text-align: left;
  }
  .subsequentRowsInput {
    margin-left: 0.4rem;
    margin-right: 0.4rem;
    width: 5rem;
  }
  .addQuestionButton {
    margin-top: 1.4rem;
  }
  /* Indent Add Question button on mobile */
  .innerWrapper.mobile .addQuestionButton {
    margin-left: 1rem;
  }
  .learnButton {
    margin-top: 0.2rem;
  }
  .learnGrid {
    margin-right: 1rem;
  }
  .learnGrid.mobile {
    margin-right: 0;
  }
  .learnQuestion, .learnReviewGroup {
    border-radius: .28571429rem;
    cursor: pointer;
    padding: 0.4rem 0.8rem;
  }
  .learnQuestion:hover, .learnReviewGroup:hover {
    background-color: #e0e1e2;
  }
  .learnQuestionsWrapper {
    overflow: auto;
    padding-left: 1rem;
    padding-top: 0.8rem;
  }
  .learnReviewGroupsWrapper {
    overflow: auto;
    padding-left: 1rem;
    padding-top: 0.8rem;
  }
  .learnReviewGroupsWrapper .field {
    margin-bottom: 0.4em !important;
  }
  .learnQuestionsWrapper .field {
    margin-bottom: 0.4em !important;
  }
  .learnReviewGroupLabel {
    margin-bottom: -3px;
    margin-top: -3px;
  }
  .noRightPadding {
    padding-right: 0 !important;
  }
  .rightShift {
    margin-left: 1rem;
    margin-right: -1rem;
  }
  .questionText {
    background-color: #fff;
    border: 1px solid rgba(34,36,38,.15);
    border-radius: .28571429rem;
    min-height: 4rem;
    padding: .6em 1em;
  }
  .questionText:hover {
    border-color: rgba(34,36,38,.35);
  }
  .questionText:focus {
    border-color: #96c8da;
  }
  .questionPreview {
    background-color: #ebeced;
    border: 1px solid rgba(34,36,38,.15);
    border-radius: .28571429rem;
    min-height: 4rem;
    padding: .6em 1em;
  }
  .questionPreviewShift {
    margin-left: -1rem;
  }
  .actionButtonsWrapper {
    margin-bottom: 1.4rem;
    margin-left: 1rem;
    margin-top: 1rem;
  }
  .learnMain {
    margin-bottom: 0 !important;
    margin-top: 0;
  }
  .innerWrapper.mobile .learnMain {
    margin-left: 1rem;
    margin-right: 1rem;
  }
  /* Override Semantic's header properties for Delete Question button */
  .deleteQuestionButton {
    /* Reset font-size - changed by header */
    font-size: 1rem !important;
    /* Move button up slightly */
    margin-top: -0.5rem;
    /* Reset padding-top - changed by header */
    padding-top: 0.785714em !important;
  }
  /* Override Semantic's padding-right: 2.25rem for narrow screens */
  @media only screen and (max-width: 767.98px) {
    .ui.modal>.header {
      padding-right: 1.5rem !important;
    }
  }
  @media only screen and (max-width: 991.98px) {
    .ui.modal>.header {
      padding-right: 1.5rem !important;
  }
    }
  .questionMessageFadeIn-enter-active {
    transition: opacity 0.4s;
  }
  .questionMessageFadeIn-fade-leave-active {
    transition: none;
  }
  .questionMessageFadeIn-enter, .questionMessageFadeIn-leave-to {
    opacity: 0;
  }
  .publicHeaderWrapper {
    margin: 0 0 0.5rem 1rem;
  }
  .publicHeaderWrapper.mobile {
    margin: -0.2rem 4px 1rem 1.3rem;
  }
  .ratingsCount {
    color: gray;
    margin-left: 1rem;
  }
  .largeInactiveStar {
    cursor: default !important;
    text-shadow: 0 -1px 0 gainsboro,-1px 0 0 silver,0 1px 0 silver,1px 0 0 silver !important;
  }
  /* Styles for delete question confirmation message */
  .red.message .content {
    color: rgba(0,0,0,.87) !important;
    min-height: 0;
  }
  .red.message .header {
    color: rgba(0,0,0,.87) !important;
  }
  .red.message p {
    margin-top: 0.5rem;
  }
  .noPointerEvents {
    pointer-events: none;
  }
  .barButton {
    border-radius: 0;
    text-align: left;
  }
  /* Give addColumn modal space for dropdown */
  .modal.addColumn .content {
    min-height: 20rem;
  }
  /* Give deleteColumn modal space for dropdown */
  .modal.deleteColumn .content {
    min-height: 17rem;
  }
  /* Give moveColumn modal space for dropdown */
  .modal.moveColumn .content {
    min-height: 20rem;
  }
  /* Give sortRows modal space for dropdown */
  .modal.sortRows .content {
    min-height: 17rem;
  }
  .currentPosition {
    color: gray;
    margin-left: 0.5rem;
  }
  .rowDataTable .two.wide {
    min-width: 100px;
  }
  .rowDataTable .three.wide {
    min-width: 200px;
  }
  .rowDataTableWrapper {
    overflow-x: auto;
  }
  .rowDataTable td {
    background-color: whitesmoke !important;
  }
  /* Avoid collapsing preview rows if no data */
  .rowDataTable tbody tr {
    height: 3em;
  }
  .populateColumnValue {
    border: 1px solid rgba(34,36,38,.15);
    border-radius: .28571429rem;
    min-height: 5rem;
    padding: .6em 1em;
  }
  .populateColumnValue:hover {
    border-color: rgba(34,36,38,.35);
  }
  .populateColumnValue:focus {
    border-color: #96c8da;
  }
  .populateColumnPreview {
    background-color: whitesmoke;
    border: 1px solid rgba(34,36,38,.15);
    border-radius: .28571429rem;
    min-height: 5rem;
    padding: .6em 1em;
  }
  .icon.summaryImageButton {
    font-size: 140%;
    padding: 0.4em 0.8em !important;
  }
  .narrowButtons .icon.summaryImageButton {
    padding: 0.4em 0.5em !important;
  }
  /* Make images on Summary Image and Walk Through modals unselectable */
  .modal.summaryImage img, .modal.walkthrough img {
    user-select: none;
  }
  .summaryImageButton.active {
    background-color: #a0a1a2 !important;
  }
  .summaryImageView {
    overflow: hidden;
  }
  .walkthroughView {
    background-color: white;
    overflow: hidden;
  }
  .ui.table>thead>tr>th {
    background-color: #395f7f;
    color: rgba(255, 255, 255, 0.87);
  }
  .ui.modal, .ui.modal>.content {
    background-color: #fbfbf9;
  }
  .ui.modal>.header {
    background-color: #f9f9f9;
  }
  .ui.block.header {
    background-color: #395f7f;
    color: rgb(255,255,255,.87);
  }
  .objectRectangle {
    border: 1px solid red;
    pointer-events: none;
    z-index: 1000;
  }
  .resetAudioButton, .resetImageButton {
    display: block;
    margin-top: 1rem;
  }
  .ui.horizontal.divider {
    /* Increase spacing around divider */
    margin: 1.5rem 0;
  }
  .ui.divider.learnDivider {
    margin: 1.4rem 0 0 0;
  }
  .coverImagePhoto:hover {
    cursor: pointer;
    outline: 2px solid #21ba45;
  }
  .publicRightButtonWrapper {
    margin-left: 1rem;
    margin-top: -1.5rem;
  }
  .sharedRightButtonWrapper {
    margin: -2rem 0 4px 1rem;
  }
  .sharedOwnerName {
    display: inline-block;
    max-width: calc(100% - 16.5rem);
    vertical-align: top;
  }
  .exampleSpacer {
    height: 1rem;
  }
  .exampleHeader {
    margin-top: 0.5rem;
  }
  .exampleHeader.mobile {
    margin-left: 1rem;
  }
  .exampleTitle {
    margin-bottom: 1.2rem;
  }
  .exampleTitle.mobile {
    margin-bottom: 2.2rem;
  }
  .optionsIndented {
    margin-left: 2rem;
  }
  .optionsMarginTop {
    margin-top: 0.8rem;
  }
  .unknown.label, .unknown.button {
    background-color: #aaa;
    color: white;
  }
  a.unknown.label:hover {
    background-color: #999;
    color: white;
  }
  .button.reviewCycle {
    margin-bottom: 4px;
  }
  .reviewCycle7Margin {
    margin-left: -0.3em;
    margin-right: -0.3em;
  }
  .reviewCycleInfinityMargin {
    margin-left: -0.4em;
    margin-right: -0.4em;
  }
  .alignButtonLabel {
    vertical-align: text-top;
  }
  .modalFlexContainer {
    display: flex;
    flex-flow: column;
    height: 100%;
  }
  /* Apply standard modal styles to modals with flex container */
  .modalFlexContainer>.header {
    background-color: #f9f9f9;
    border-bottom: 1px solid rgba(34,36,38,.15);
    box-shadow: none;
    color: rgba(0,0,0,.85);
    font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
    margin: 0;
    padding: 1.25rem 1.5rem;
    border-top-left-radius: .28571429rem;
    border-top-right-radius: .28571429rem;
  }
  .modalFlexContainer>.content {
    flex: 1 0 100px;
    line-height: 1.4;
    overflow: auto;
    padding: 1rem 1.5rem;
  }
  .modalFlexContainer>.actions {
    background: #f9fafb;
    border-bottom-left-radius: .28571429rem;
    border-bottom-right-radius: .28571429rem;
    border-top: 1px solid rgba(34,36,38,.15);
    padding: 1rem;
    text-align: right;
  }
  .modalFlexContainer>.actions>.button {
    margin-bottom: 0;
  }
  .ui.modal.learn .content.learnContent {
    font-size: 115%;
    padding-top: 1.5rem;
  }
  .ui.menu {
    min-height: 2.7142857em;
  }
  .statusCell {
    padding-left: 4px !important;
    padding-right: 4px !important;
    white-space: nowrap;
  }
  .statusCellDiv {
     display: inline-block;
     width: 2.5rem;
  }
  .noFlexWrap {
    flex-wrap: nowrap !important;
  }
  .content.summaryImage {
    border-radius: .28571429rem;
    padding-bottom: 0 !important;
  }
  .correctButton, .incorrectButton {
    min-width: 8rem;
  }
  .correctButton.icon, .incorrectButton.icon {
    min-width: 10rem;
  }
  .cellDeleteButton {
    border-top-right-radius: .28571429rem !important;
    border-bottom-right-radius: .28571429rem !important;
  }
  .cellDoneButton {
    border-radius: .28571429rem !important;
    margin-left: 8px !important;
  }
  .mobileCellToolbar, .mobileTextToolbar {
     bottom: 0;
     margin: 0;
     padding-bottom: 4px;
     padding-top: 4px;
     position: absolute;
     width: 100%;
  }
  .relative {
    position: relative;
  }
  /* Override Semantic's opacity for disabled buttons */
  .cellButtons .ui.buttons .ui.disabled.button {
    background-color: #f8f8f9 !important;
    color: #a6a6a6;
    opacity: 1 !important;
  }
  .bottomAligned {
    vertical-align: bottom !important;
  }
  .previewImage {
    max-height: calc(100% - 4rem);
    margin: auto;
  }
  .height100 {
    height: 100%;
  }
  .audioNotSupported {
    margin-left: 0.5rem;
    margin-top: 1rem;
  }
  .shareMemoSetContent {
    min-height: 17rem;
    padding-bottom: 0.8rem !important;
  }
  .groupColorButton {
    margin-bottom: 4px;
  }
  .imageCell {
    padding: 6px !important;
  }
  .updatesWrapper {
    margin-bottom: 2rem !important;
    margin-left: 1rem;
    margin-right: 1rem;
  }
  .dateMinWidth {
    min-width: 8rem;
  }
  .updateDate {
    border: 0;
  }
  .emptyDate:not(:focus) {
    color: lightgray;
  }
  .emptyDate:not(:focus)::-webkit-calendar-picker-indicator {
    filter: invert(0.8);
  }
  .learnTable th {
    background-color: lightslategray !important;
    padding-bottom: 0.5em !important;
    padding-top: 0.5em !important;
  }
  .learnModalHeader {
    color: rgba(0,0,0,.87) !important;
    margin-top: 1.5rem !important;
  }
  /* Override progress bar minimum width */
  .ui.progress .bar {
    min-width: 0 !important;
  }
  .learnButtons {
    margin-top: 0.8rem;
  }
  .ui.statistics .statistic>.label {
    padding-top: 5px;
    text-transform: none;
  }
  .gameQuestion {
    animation-timing-function: cubic-bezier(.13,1.09,.71,.96);
  }
  @keyframes explode {
    0% {
        opacity: 1;
        transform: scale(1);
        transform-origin: center center;
    }
    100% {
      opacity: 0;
      transform: scale(3);
      transform-origin: center center;
    }
  }
  .explode {
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-name: explode;
  }
  @keyframes fall {
    0% {
      transform: var(--fall-transform-start);
    }
    100% {
      transform: var(--fall-transform-end);
    }
  }
  .fall {
    animation-duration: var(--fall-duration);
    animation-fill-mode: forwards;
    animation-name: fall;
    animation-timing-function: linear;
  }
  @keyframes slowFall {
    0% {
      transform: translate(0px, 0px);
    }
    100% {
      transform: var(--slowFall-transform-end);
    }
  }
  .slowFall {
    animation-duration: 1s;
    animation-fill-mode: forward;
    animation-name: slowFall;
    animation-timing-function: ease-in;
  }
  .gameLifeWrapper {
    display: inline-block;
    vertical-align: middle;
  }
  .gameLife {
    margin-bottom: 0 !important;
    margin-top: 0 !important;
    transition-delay: 1s;
    transition-duration: 0.8s;
    transition-property: opacity;
  }
  .gameLife.mobile {
    width: 25px !important;
  }
  .gameLabel {
    font-size: 150%;
    font-weight: bold;
    margin-right: 0.8rem;
    vertical-align: middle;
  }
  .gameLabel.mobile {
    font-size: 120%;
  }
  .gameScore {
    color: #f2711c;
    font-size: 200%;
    font-weight: bold;
    vertical-align: middle;
  }
  .gameScore.mobile {
    font-size: 150%;
  }
  .gameButtons, .gameButtonsRow1, .gameButtonsRow2 {
    margin-bottom: 0 !important;
    width: 100%;
  }

  .gameButtonsRow2 {
    margin-top: 6px;
  }
  .gameButton {
    border-radius: .28571429rem !important;
    margin-left: 3px !important;
    margin-right: 3px !important;
    transition-delay: 1.3s;
    transition-duration: 0.5s;
    transition-property: opacity;
    /* Width needs to be some small value - buttons will expand but cannot collapse */
    width: 10% !important;
  }
  .gameItemHidden {
    opacity: 0;
  }
  .gameQuestion {
    display: inline-block;
    transition-delay: 1.3s;
    transition-duration: 0.5s;
    transition-property: opacity;
  }
  .gameSlowFallWrapper {
    transition-delay: 1.3s;
    transition-duration: 0.5s;
    transition-property: opacity;
  }
  .reviewScheduleDiv {
    margin-top: 1rem;
  }
  .reviewScheduleLink {
    color: #4183c4;
    cursor: pointer;
  }
  .reviewScheduleButton {
    margin-left: 0.75rem;
  }
  .reviewScheduleCycle {
    padding-left: 2rem !important;
  }
</style>
