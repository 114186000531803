<template>
  <div class="wrapper" :class="{invisible: !total}">
    <div
      class="known bar left"
      :class="{
        fast: statuses.speed === 'fast',
        slow: statuses.speed === 'slow',
        right: !statuses.due && !statuses.unknown
      }"
      :style="{width: percents.known + '%'}"
    >
      <div class="text" v-show="display.known">
        <i class="check icon" v-show="showIcon.known"></i>{{ display.known }}
      </div>
    </div>
    <div
      class="due bar"
      :class="{
        fast: statuses.speed === 'fast',
        slow: statuses.speed === 'slow',
        left: !statuses.known,
        right: !statuses.unknown
      }"
      :style="{width: percents.due + '%'}"
    >
      <div class="text" v-show="display.due">
        <i class="calendar outline icon" v-show="showIcon.due"></i>{{ display.due }}
      </div>
    </div>
    <div
      class="unknown bar right"
      :class="{
        fast: statuses.speed === 'fast',
        slow: statuses.speed === 'slow',
        left: !statuses.known && !statuses.due
      }"
      :style="{width: percents.unknown + '%'}"
    >
      <div class="text" v-show="display.unknown">
        <i class="x icon" v-show="showIcon.unknown"></i>{{ display.unknown }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'status-bar',
  props: ['mobile', 'page', 'statuses', 'window'],
  data () {
    return {
      display: {
        known: -1,
        due: -1,
        unknown: -1
      },
      numbersTransition: {
        // Note: duration in milliseconds should match the bar transition time
        duration: 0,
        from: {
          known: 0,
          due: 0,
          unknown: 0
        },
        startTime: 0,
        timeoutDuration: 50,
        to: {
          known: 0,
          due: 0,
          unknown: 0
        }
      }
    }
  },
  created: function () {
    // For debugging
    window.vmsb = this
    // Display initial figures
    this.display.known = this.statuses.known
    this.display.due = this.statuses.due
    this.display.unknown = this.statuses.unknown
  },
  computed: {
    percents: function () {
      let percents
      if (this.total) {
        percents = {
          known: this.statuses.known / this.total * 100,
          due: this.statuses.due / this.total * 100,
          unknown: this.statuses.unknown / this.total * 100
        }
      } else {
        percents = {}
      }
      return percents
    },
    showIcon: function () {
      let items, showIcon, width, widthNeeded
      if (!this.total) return {}
      showIcon = {}
      items = ['due', 'known', 'unknown']
      items.forEach(item => {
        width = this.statusBarWidth * this.display[item] / this.total
        // Need enough width for the number of digits plus 3. Each character is approximately 8px wide
        widthNeeded = (Math.ceil(Math.log10(this.display[item])) + 3) * 8
        showIcon[item] = (width >= widthNeeded)
      })
      return showIcon
    },
    statusBarWidth: function () {
      let columnWidth, innerWrapperMargin, padding, tableWidth, width
      // Assume 1rem = 16px - may not be true, but doesn't matter too much, as this is only used to determine when the status bar icons appear
      const rem = 16
      if (this.page === 'Home') {
        tableWidth = this.window.width - (this.mobile ? (2 * rem) : (3 * rem))
        columnWidth = tableWidth * 4 / 14
        padding = 2 * 0.78571429 * rem
        width = columnWidth - padding
      }
      if (this.page === 'MemoSet') {
        innerWrapperMargin = this.mobile ? (2 * rem) : (3 * rem)
        width = this.window.width - innerWrapperMargin - (5 * rem)
      }
      if (this.page === 'MemoSets') {
        tableWidth = this.window.width - (this.mobile ? (2 * 4) : (3 * rem))
        columnWidth = tableWidth * 4 / 14
        padding = 2 * 0.78571429 * rem
        width = columnWidth - padding
      }
      // Be conservative, particularly for narrow screens
      if (this.window.width < 500) {
        width *= 0.5
      } else if (this.window.width < 700) {
        width *= 0.6
      } else {
        width *= 0.9
      }
      return width
    },
    total: function () {
      return this.statuses.known + this.statuses.due + this.statuses.unknown
    }
  },
  methods: {
    updateDisplay: function () {
      this.numbersTransition.frame++
      const now = Date.now()
      const fractionDone = (now - this.numbersTransition.startTime) / this.numbersTransition.duration
      if (fractionDone >= 1) {
        this.display.known = this.numbersTransition.to.known
        this.display.due = this.numbersTransition.to.due
        this.display.unknown = this.numbersTransition.to.unknown
      } else {
        this.display.known = Math.round(this.numbersTransition.from.known + (fractionDone * (this.numbersTransition.to.known - this.numbersTransition.from.known)))
        this.display.due = Math.round(this.numbersTransition.from.due + (fractionDone * (this.numbersTransition.to.due - this.numbersTransition.from.due)))
        this.display.unknown = Math.round(this.numbersTransition.from.unknown + (fractionDone * (this.numbersTransition.to.unknown - this.numbersTransition.from.unknown)))
        setTimeout(this.updateDisplay, this.numbersTransition.timeoutDuration)
      }
    }
  },
  watch: {
    statuses: function (newStatuses, oldStatuses) {
      this.numbersTransition.from = oldStatuses
      this.numbersTransition.to = newStatuses
      this.numbersTransition.startTime = Date.now()
      this.numbersTransition.frame = 0
      switch (newStatuses.speed) {
        case 'fast':
          this.numbersTransition.duration = 500
          break
        case 'slow':
          this.numbersTransition.duration = 1500
          break
        default:
          this.numbersTransition.duration = 1
          break
      }
      // If status bar is appearing, use minimum duration
      if (oldStatuses.known + oldStatuses.due + oldStatuses.unknown === 0) {
        this.numbersTransition.duration = 1
      }
      // If status bar is not disappearing, update the numbers
      if (newStatuses.known + newStatuses.due + newStatuses.unknown > 0) {
        this.updateDisplay()
      }
    }
  }
}
</script>

<style scoped>
  .invisible {
    opacity: 0;
  }
  .bar {
    display: inline-block;
    height: 1.75em;
    line-height: 1;
    position: relative;
  }
  .bar.fast {
    transition: width 0.5s ease-in-out;
  }
  .bar.slow {
    transition: width 1.5s ease-in-out;
  }
  .known {
    background-color: rgb(33, 186, 69);
  }
  .due {
    background-color: rgb(242, 113, 28);
  }
  .unknown {
    background-color: #aaa;
  }
  .left {
    border-bottom-left-radius: .28571429rem;
    border-top-left-radius: .28571429rem;
  }
  .right {
    border-bottom-right-radius: .28571429rem;
    border-top-right-radius: .28571429rem;
  }
  .text {
    color: rgba(255,255,255,.8);
    font-weight: bold;
    margin-top: -0.5em;
    position: absolute;
    text-align: center;
    top: 50%;
    white-space: nowrap;
    width: 100%;
  }
  .wrapper {
    margin-right: 5px;
    margin-top: 2px;
  }
</style>
